import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";

import {
  listCompanys,
  companyByBusiness,
  companyByGroup,
} from "../graphql/custom-queries";
import captureError from "../utils/capture-error";
import useSWR from "swr";

const fetchByGroup = async (group) => {
  return await listAll(
    graphqlOperation(companyByGroup, { group: group }),
    "companyByGroup"
  );
};

const fetchByBusiness = async (businessId) => {
  return await listAll(
    graphqlOperation(companyByBusiness, { orgBusinessId: businessId }),
    "companyByBusiness"
  );
};

const fetchByScan = async () => {
  console.log("Fetching companies by scan");
  return await listAll(graphqlOperation(listCompanys), "listCompanys");
};

const fetch = async (userGroup, businessId) => {
  try {
    let fetcher = fetchByScan;
    let fetcherInput;
    if (businessId) {
      fetcher = fetchByBusiness;
      fetcherInput = businessId;
    }
    if (userGroup) {
      fetcher = fetchByGroup;
      fetcherInput = userGroup;
    }
    const companies = await fetcher(fetcherInput);
    const orderedCompanies = [...companies].sort((c1, c2) => {
      return (
        (c1.orderNumberDealer === null) - (c2.orderNumberDealer === null) ||
        c1.orderNumberDealer - c2.orderNumberDealer
      );
    });
    if (businessId) {
      console.log("Mapping  orgCategories to actual categories");
      return orderedCompanies.map((c) => {
        return { ...c, categories: c.categoriesByOrg };
      });
    }
    return orderedCompanies;
  } catch (e) {
    console.error("Get companies failed", e);
    captureError("Get companies failed", "GET_COMPANIES_FAILED", e);
  }
};

export function useFetchCompanies(userGroup, businessId, companyId) {
  const condition = userGroup ?? businessId;
  const { data, error, isLoading, mutate } = useSWR(
    condition ? ["companies", condition] : null,
    () => fetch(userGroup, businessId),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    selectedCompany: data?.find((c) => c.id === companyId),
    companies: data ?? [],
    companiesLoading: isLoading,
    companiesError: error,
    companiesMutate: mutate,
  };
}

export function useContextFetchCompanies() {
  const { user, business, company } = useContext(AppContext);
  return useFetchCompanies(user?.group, business?.id, company?.id);
}

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function Bulb({ children, ...props }) {
  const theme = useTheme();

  return (
    <Box
      width="16px"
      height="16px"
      borderRadius="100%"
      bgcolor={theme.palette.primary.dark}
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="red"
      {...props}
    >
      {children}
    </Box>
  );
}

export default Bulb;

import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import defaultAdditionalServices from "../shared/additional-services";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";

import {
  additionalServiceByOrganization,
  additionalServiceByGroup,
} from "../graphql/queries";
import listAll from "../utils/list-all";
import captureError from "../utils/capture-error";

const fetchByGroup = async (group) => {
  try {
    const additionalServices = await listAll(
      graphqlOperation(additionalServiceByGroup, { group: group }),
      "additionalServiceByGroup"
    );
    const additionalServicesCombined = [
      ...additionalServices,
      ...defaultAdditionalServices,
    ];
    return additionalServicesCombined;
  } catch (e) {
    console.error("fetchByOrganization failed", e);
    captureError(
      "Get AdditionalServices failed",
      "GET_SERVICES_GROUP_FAILED",
      e
    );
  }
};

const fetchByOrganization = async (organizationId, orgBusinessId) => {
  try {
    // All services that either belong to Organization or to the current Business
    const filter = {
      or: [{ orgBusinessId: { attributeExists: false } }],
    };
    if (orgBusinessId) {
      filter.or.push({ orgBusinessId: { eq: orgBusinessId } });
    }
    return await listAll(
      graphqlOperation(additionalServiceByOrganization, {
        organizationId: organizationId,
        filter: filter,
      }),
      "additionalServiceByOrganization"
    );
  } catch (e) {
    console.error("fetchByOrganization failed", e);
    captureError("Get AdditionalServices failed", "GET_SERVICES_ORG_FAILED", e);
  }
};

export function useFetchAdditionalServices() {
  const { business, group, user } = useContext(AppContext);

  let key = null;
  let fetcher = null;
  if (group) {
    key = ["additionalServiceByGroup", group];
    fetcher = () => fetchByGroup(group);
  }

  if (user?.organizationId) {
    key = ["additionalServiceByOrganization", business?.id];
    fetcher = () => fetchByOrganization(user?.organizationId, business?.id);
  }

  const { data, error, isLoading, mutate } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const servicesWithKeys = useMemo(() => {
    return data
      ? data
          .filter((service) => !service?._removed)
          .map((service) => {
            return { ...service, key: service.id };
          })
      : [];
  }, [data]);

  return {
    additionalServices: servicesWithKeys,
    additionalServicesLoading: isLoading,
    additionalServicesError: error,
    additionalServicesMutate: mutate,
  };
}

import { ReservationVehicle } from "~/graphql/API";

export const settingsPreviewVehicleData: ReservationVehicle = {
  id: "settings-preview-reservation-vehicle",
  name: "Audi A4",
  price: 15000,
  tax: 25.5,
  registrationPlate: "XXX-123",
  deductible: 6000,
  includedDistance: null,
  powerType: "B95E10",
  mileage: "123456",
  startMileage: "123456",
  endMileage: "123600",
  damages: null,
  hideExport: null,
  createdAt: null,
  addedAt: null,
  categoryName: "B-ECMR",
  __typename: "ReservationVehicle",
};

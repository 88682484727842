import {
  Avatar,
  Button,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FINflag from "~/assets/flags/fin.png";
import SWEflag from "~//assets/flags/swe.png";
import ENGflag from "~/assets/flags/eng.png";
import { useState } from "react";

export default function SelectContractLanguage({
  selectedLanguage,
  setSelectedLanguage,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={
          <Avatar
            src={Eflags[selectedLanguage]}
            sx={{
              boxShadow: theme.shadows[3],
              border: "1px solid lightgrey",
              height: 36,
              width: 36,
            }}
          />
        }
        fullWidth
        sx={{ padding: 0, paddingRight: "15px", textTransform: "none" }}
      >
        <Stack spacing={1} width={"100%"} direction={"row"}>
          <Typography variant="caption">Sopimuksen kieli:</Typography>
          <Typography variant="caption">
            {Elanguages[selectedLanguage]}
          </Typography>
        </Stack>
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: 99999 }}
      >
        {Object.keys(Elanguages).map((language) => (
          <MenuItem
            key={language}
            onClick={() => handleSelectLanguage(language)}
          >
            <ListItemAvatar>
              <Avatar
                src={Eflags[language]}
                sx={{
                  boxShadow: theme.shadows[3],
                  border: "1px solid lightgrey",
                }}
              />
            </ListItemAvatar>
            <ListItemText>
              <Typography>{Elanguages[language]}</Typography>
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const Eflags = {
  FIN: FINflag,
  SWE: SWEflag,
  ENG: ENGflag,
};

const Elanguages = {
  FIN: "Suomi",
  SWE: "Ruotsi",
  ENG: "Englanti",
};

/* 
  {languages.map((language) => (
            <MenuItem key={language.name} onClick={handleClose}>
              <Typography>{language.name}</Typography>
            </MenuItem>
          ))}

*/

import {
  Grid,
  CircularProgress,
  TextField,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";

import { centsToLocalString } from "../../../shared/money";
import { Euro } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { useContext, useMemo } from "react";
import { AppContext } from "../../../AppContext";
import { useReservationContext } from "../ReservationContext";
import { useGetOfferPriceByIds } from "../useGetOfferPriceByIds";

const LoadablePrice = ({ price, isLoading, quantity }) => {
  if (isLoading) {
    return <CircularProgress style={{ height: "1em", width: "1em" }} />;
  } else if (price == null) {
    return "-";
  } else if (quantity > 1) {
    return (
      centsToLocalString(price * quantity) +
      " (" +
      quantity +
      "x" +
      centsToLocalString(price) +
      ") €"
    );
  } else {
    return centsToLocalString(price) + " €";
  }
};

export default function ReservationPriceField({
  isDefaultPriceLoading,
  enableQuantity,
  vehicleQuantity,
  service,
  offer,
  priceModifier,
  ...props
}) {
  const { USER_ADMIN_CORPORATE } = useContext(AppContext);
  const { values } = useFormikContext();

  // Price is multiplied if quantity is set (enableQuantity) or vehicleQuantity is given
  const getQuantity = () => {
    if (enableQuantity) {
      if (service?.quantity) {
        return service.quantity;
      }
      if (values["vehicleQuantity-" + offer?.id] > 1) {
        return values["vehicleQuantity-" + offer?.id];
      }
      return 1;
    }

    return 1;
  };

  const pricePlaceHolder = () => {
    // if pricefield is cleared, show the default price as placeholder
    if (offer || service) {
      const item = offer || service;
      const listingPrice =
        item?.listingPrice && centsToLocalString(item?.listingPrice);

      const price = item?.price && centsToLocalString(item?.price);

      if (item?.modifiedPrice === "" || !item?.modifiedPrice) {
        return listingPrice ?? price;
      } else {
        return item?.modifiedPrice;
      }
    }
  };
  const id = offer?.id ?? service?.key;
  const price = offer?.modifiedPrice ?? service?.modifiedPrice;
  return (
    <>
      <ListPrice
        offerId={offer?.id}
        serviceId={service?.key ?? service?.id}
        getQuantity={getQuantity}
        visibility={!USER_ADMIN_CORPORATE}
      />
      <TextField
        disabled={USER_ADMIN_CORPORATE}
        {...props}
        placeholder={pricePlaceHolder()}
        value={price ?? ""}
        onChange={(e) => {
          priceModifier(id, e.target.value);
        }}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ display: "flex" }}>
                <Euro sx={{ fontSize: "18px", cursor: "pointer" }} />
              </Box>
            </InputAdornment>
          ),
        }}
        fullWidth
        size="small"
        data-cy="reservationDialogPriceField"
      />
    </>
  );
}

function ListPrice(props) {
  const { getQuantity, visibility, offerId, serviceId } = props;

  const { startTime, returnTime, queryResource, vehicleIds } =
    useReservationContext();
  const { offerPrices, offerPricesLoading } = useGetOfferPriceByIds(
    vehicleIds,
    startTime,
    returnTime,
    queryResource
  );

  const offerPrice =
    offerPrices?.vehicleOfferPrices &&
    offerPrices?.vehicleOfferPrices.find((o) => o?.id === offerId)?.price;
  const servicePrice =
    offerPrices?.serviceOfferPrices &&
    offerPrices?.serviceOfferPrices.find((o) => o?.key === serviceId)
      ?.offerPrice;

  const getPrice = () => {
    if (offerId) {
      return offerPrice;
    }
    if (serviceId) {
      return servicePrice;
    }
  };
  if (visibility)
    return (
      <Grid item>
        <Typography variant="caption">Listahinta: </Typography>
        <Typography variant="caption">
          <LoadablePrice
            price={getPrice()}
            isLoading={offerPricesLoading}
            quantity={getQuantity()}
          />
        </Typography>
      </Grid>
    );
}

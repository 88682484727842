import parsePhoneNumberFromString, {
  AsYouType,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";

export const validatePhoneNumber = (value) => {
  if (value === "" || value === null || value === undefined) {
    return true;
  }

  const inputValue = value ?? "";
  const asYouType = new AsYouType();
  asYouType.input(inputValue);
  const countryCode = asYouType.getCountry();

  const isPossible = isPossiblePhoneNumber(inputValue, countryCode ?? "FI");
  const isValid = isValidPhoneNumber(inputValue, countryCode ?? "FI");
  if (isValid && isPossible) {
    const parsedNumber = parsePhoneNumberFromString(inputValue, {
      defaultCountry: "FI",
    });
    return parsedNumber.number;
  } else {
    return null;
  }
};

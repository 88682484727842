import { useState, useEffect, useRef, forwardRef } from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

const truncateText = (text, charAmount) => {
  if (!charAmount) return text;
  if (text.length <= charAmount) return text;
  return text.slice(0, charAmount) + "...";
};

const StyledBox = styled(Box)(({ theme, lineclamp }) => ({
  overflow: lineclamp ? "hidden" : "initial",
  textOverflow: "ellipsis",
  display: lineclamp ? "-webkit-box" : "initial",
  WebkitLineClamp: lineclamp || 1,
  WebkitBoxOrient: "vertical",
  whiteSpace: "pre-wrap",
}));

const TruncatedBox = forwardRef(({ isTruncated, ...props }, ref) => (
  <StyledBox
    ref={ref}
    style={{ cursor: isTruncated ? "pointer" : "initial" }}
    {...props}
  />
));

export default function TruncatedTypography({
  text,
  variant,
  align,
  color,
  style,
  tooltipProps,
  boxProps,
  lineclamp,
  charAmount,
}) {
  const [open, setOpen] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef();

  const handleMouseEnter = () => {
    if (isTruncated) setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const checkIfTruncated = () => {
    if (textRef.current) {
      const { scrollHeight, clientHeight } = textRef.current;
      setIsTruncated(scrollHeight > clientHeight);
    }
  };

  useEffect(() => {
    checkIfTruncated();
    window.addEventListener("resize", checkIfTruncated);
    return () => window.removeEventListener("resize", checkIfTruncated);
  }, [text]);

  const truncatedText = truncateText(text, charAmount);

  return (
    <TruncatedBox
      ref={textRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      lineclamp={lineclamp}
      isTruncated={isTruncated}
      {...boxProps}
    >
      <Tooltip
        open={open}
        title={text}
        arrow
        placement="top"
        disableFocusListener
        disableTouchListener
        disableHoverListener
        {...tooltipProps}
      >
        <Typography variant={variant} align={align} color={color} style={style}>
          {truncatedText}
        </Typography>
      </Tooltip>
    </TruncatedBox>
  );
}

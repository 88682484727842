import { useContext, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { ReservationContext } from "../../../../../contextProviders/ReservationContext";
import { translatedFields } from "../../../../../formik/fields";
import ExternalUserSelectStartLocation from "../../ExternalUsersSelectStartLocation";
import ReservationDatePicker from "../../ReservationDatePicker";
import ExternalUserSelectReturnLocation from "../../ExternalUserSelectReturnLocation";
import ExternalUserSelectVehicleClass from "../../SelectVehicleClass";
import SelectAdditionalServices from "../../SelectAdditionalServices";
import SelectedOfferCard from "../../SelectedOfferCard";
import SelectedAdditionalServiceCard from "../../SelectedAdditionalService";
import { getTotalPrice } from "../../../../../utils/utilities";
import { AppContext } from "../../../../../../../AppContext";
import { addHours, isValid } from "date-fns";

export default function DesktopView(props) {
  const { serviceProvider, setServiceProvider } = props;
  const { values } = useFormikContext();
  const [startLocationOrgBusinessId, setStartLocationOrgBusinessId] =
    useState(null);
  const { selectedLanguage } = useContext(ReservationContext);

  const totalPrice = getTotalPrice({
    additionalServices: values.additionalServices,
    reservationVehicles: values.wildCardVehicles,
  });
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const deliveryBuffer = handleDeliveryBufferHours(serviceProvider, values);

  return (
    <motion.div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: mobileViewPort ? "space-between" : "flex-start",
        height: "100%",
      }}
      initial={{ opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 100 },
        opacity: { duration: 0.4 },
      }}
    >
      <Grid
        container
        direction={"row"}
        sx={{
          padding: mobileViewPort ? "10px" : "20px",
        }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        spacing={1}
      >
        <Grid item xs={12}>
          <SelectServiceProvider
            serviceProvider={serviceProvider}
            setServiceProvider={setServiceProvider}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ExternalUserSelectStartLocation
            serviceProvider={serviceProvider}
            startLocationOrgBusinessId={startLocationOrgBusinessId}
            setStartLocationOrgBusinessId={setStartLocationOrgBusinessId}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ReservationDatePicker
            fieldName={"startTime"}
            cutoffDate={deliveryBuffer}
            serviceProvider={serviceProvider}
            secondaryFieldName={"returnTime"}
            disabled={!values?.startLocation}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ExternalUserSelectReturnLocation
            serviceProvider={serviceProvider}
            startLocationOrgBusinessId={startLocationOrgBusinessId}
            setStartLocationOrgBusinessId={setStartLocationOrgBusinessId}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ReservationDatePicker
            fieldName={"returnTime"}
            cutoffDate={values?.startTime ? values?.startTime : deliveryBuffer}
            disabled={!isValid(values?.startTime)}
            secondaryFieldName={"startTime"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ExternalUserSelectVehicleClass />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <SelectAdditionalServices />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction={"row"}
          height={"100%"}
          marginTop={"10px"}
        >
          <Grid item xs={12} sm={12} md={6}>
            <SelectedOfferCard field={"wildCardVehicles"} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            container
            direction={"column"}
            minHeight={"100%"}
            justifyContent={"space-between"}
          >
            <List>
              {values.additionalServices.map((service, index) => (
                <SelectedAdditionalServiceCard key={index} service={service} />
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        marginBottom={!mobileViewPort && "20px"}
      >
        <Typography
          sx={{
            fontSize: "1.1rem",
          }}
        >
          <b>
            {translatedFields[selectedLanguage]?.totalPrice}: {totalPrice}€{" "}
          </b>
        </Typography>
      </Box>
    </motion.div>
  );
}

function SelectServiceProvider({ serviceProvider, setServiceProvider }) {
  const { selectedLanguage } = useContext(ReservationContext);
  const { user } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();

  const availableBusinesses =
    user?.externalBusiness?.availableOrgBusinessIds?.map((businessId) =>
      user?.businessByOrg?.items?.find((business) => business.id === businessId)
    );

  if (!availableBusinesses) return null;

  return (
    <Box sx={{ width: { sm: "49.4%", xs: "100%" } }}>
      <FormControl fullWidth>
        <InputLabel id="select-service-provider-label">
          {translatedFields[selectedLanguage].selectProvider}
        </InputLabel>
        <Select
          labelId="select-service-provider-label"
          id="select-service-provider"
          size="small"
          label={translatedFields[selectedLanguage].selectProvider}
          value={serviceProvider ?? ""}
        >
          {availableBusinesses.map((business) => (
            <MenuItem
              key={business.id}
              value={business}
              onClick={() => {
                setFieldValue("startLocation", null);
                setFieldValue("startLocationID", null);
                setFieldValue("returnLocation", null);
                setFieldValue("returnLocationID", null);
                setFieldValue("companyId", null);
                setFieldValue("orgBusinessId", business.id);
                setFieldValue("startTime", null);
                setFieldValue("returnTime", null);
                setServiceProvider(business);
              }}
            >
              {business.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const handleDeliveryBufferHours = (
  serviceProvider,

  values
) => {
  const getDeliveryBuffer = serviceProvider?.companies?.items.find(
    (company) => company.id === values?.companyId
  )?.deliveryBufferHours;
  const deliveryBuffer = getDeliveryBuffer ?? 1;

  const bufferDate = addHours(new Date(), deliveryBuffer);
  bufferDate.setMinutes(0);
  return bufferDate;
};

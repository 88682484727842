export const defaultHanselCustomers = [
  { id: "A10029", name: "2M-IT Oy", VAT: "2859795-3" },
  { id: "844001", name: "Aalto-yliopisto", VAT: "2228357-4" },
  {
    id: "A3260",
    name: "Ab Kristinestads näringslivscentral-Kristiinankaupungin elinkeinokeskus Oy",
    VAT: "2373314-3",
  },
  {
    id: "A3281",
    name: "Ab Pedersöre Värme - Pedersöre Lämpö Oy",
    VAT: "2056968-3",
  },
  { id: "A3141", name: "Ab Stormossen Oy", VAT: "0586634-8" },
  { id: "A10273", name: "Akaan kaupunki", VAT: "2050864-5" },
  { id: "A12052", name: "Akaan seurakunta", VAT: "2053947-6" },
  { id: "925001", name: "A-Kruunu Oy", VAT: "1736841-8" },
  { id: "A10274", name: "Alajärven kaupunki", VAT: "0177619-3" },
  { id: "A11743", name: "Alajärven seurakunta", VAT: "0177622-2" },
  { id: "A10275", name: "Alavieskan kunta", VAT: "0184674-5" },
  { id: "A10720", name: "Alavieskan seurakunta", VAT: "0184676-1" },
  { id: "A11905", name: "Alavuden Jäähalli Oy", VAT: "2099769-6" },
  { id: "A10276", name: "Alavuden kaupunki", VAT: "0177736-4" },
  { id: "A3256", name: "Alavuden Kehitys Oy", VAT: "1588204-2" },
  { id: "A11146", name: "Alavuden Lämpö Oy", VAT: "2659951-7" },
  { id: "A10721", name: "Alavuden seurakunta", VAT: "0177738-0" },
  { id: "60017534", name: "Alavuden Vuokra-asunnot Oy", VAT: "0645465-2" },
  { id: "A3153", name: "Alerte Ab Oy", VAT: "2775851-6" },
  { id: "A11778", name: "Alva Rauhalahti Oy", VAT: "1030790-3" },
  { id: "A11409", name: "Alva Sähköverkko Oy", VAT: "1998118-7" },
  { id: "A11159", name: "Alva-yhtiöt Oy", VAT: "1071200-1" },
  { id: "A3433", name: "Ammattiopisto Spesia Oy", VAT: "2811092-2" },
  { id: "A3333", name: "Arktikum-palvelu Oy", VAT: "0895205-7" },
  { id: "60026592", name: "As. Oy Faarinkujanrivi", VAT: "0608431-3" },
  { id: "60026596", name: "As. Oy Pöytyän Sankarinrivi", VAT: "0838599-6" },
  { id: "A12076", name: "Asentopuulaaki Oy", VAT: "0807505-8" },
  { id: "60026358", name: "Asikkalan Elinkeino Oy", VAT: "2621737-1" },
  { id: "A10277", name: "Asikkalan kunta", VAT: "0145208-4" },
  { id: "A10725", name: "Asikkalan seurakunta", VAT: "0145307-9" },
  { id: "A10050", name: "Asikkalatalot Oy", VAT: "1056780-1" },
  { id: "A10278", name: "Askolan kunta", VAT: "9000162-0" },
  { id: "A10726", name: "Askolan seurakunta", VAT: "0100049-1" },
  {
    id: "701001",
    name: "Asumisen rahoitus- ja kehittämiskeskus",
    VAT: "0948320-5",
  },
  {
    id: "60026593",
    name: "Asunto Oy Karinaisten Faarinhovi",
    VAT: "1922665-9",
  },
  { id: "60017749", name: "Asunto Oy Kihuna", VAT: "0759980-2" },
  { id: "60026053", name: "Asunto Oy Kiviöntaito", VAT: "0669325-8" },
  { id: "60026595", name: "Asunto Oy Pöytyän Riihiniitty", VAT: "0650045-2" },
  { id: "60026597", name: "Asunto Oy Talonpojankartano", VAT: "0541741-1" },
  {
    id: "60017824",
    name: "Asunto-osakeyhtiö Kihniön Ohtolanpää",
    VAT: "0660350-7",
  },
  { id: "A11393", name: "A-Tulkkaus Oy", VAT: "2103384-8" },
  { id: "A10279", name: "Auran kunta", VAT: "0132103-3" },
  { id: "A10727", name: "Auran seurakunta", VAT: "0132107-6" },
  { id: "A3042", name: "Aurinkovuoren Vesi Oy", VAT: "2625343-6" },
  { id: "822001", name: "Baltic Connector Oy", VAT: "2716791-9" },
  {
    id: "60024284",
    name: "Biopankkien Osuuskunta Suomi - FINBB",
    VAT: "2853268-2",
  },
  { id: "A10728", name: "Borgå Domkapitel", VAT: "0631605-7" },
  { id: "60025214", name: "Brändö Kommun", VAT: "0204999-1" },
  { id: "A11774", name: "Brändö-Kumlinge församling", VAT: "2000709-0" },
  { id: "971001", name: "Business Finland Oy", VAT: "2725690-3" },
  {
    id: "974001",
    name: "Business Finland Venture Capital Oy",
    VAT: "2592216-7",
  },
  { id: "A3807", name: "Careeria Oy", VAT: "2918298-7" },
  { id: "865001", name: "Centria-ammattikorkeakoulu Oy", VAT: "1097805-3" },
  { id: "680001", name: "Certia Oy", VAT: "2327422-3" },
  {
    id: "60026333",
    name: "CMI - Martti Ahtisaari Peace Foundation sr",
    VAT: "3187418-1",
  },
  { id: "A12173", name: "Coxa Oy", VAT: "1648705-2" },
  {
    id: "982001",
    name: "CSC-Tieteen tietotekniikan keskus Oy",
    VAT: "0920632-0",
  },
  { id: "980001", name: "Cultura-säätiö sr", VAT: "2521650-2" },
  { id: "A11367", name: "Cursor Oy", VAT: "0727178-6" },
  { id: "A10056", name: "Dalsbostäder Oy Ab", VAT: "1055110-5" },
  { id: "866001", name: "Diakonia-ammattikorkeakoulu Oy", VAT: "0115776-3" },
  { id: "308001", name: "Digi- ja väestötietovirasto", VAT: "0245437-2" },
  { id: "939001", name: "DigiFinland Oy", VAT: "2859355-7" },
  { id: "60026356", name: "DigiHelsinki Oy", VAT: "3300938-6" },
  { id: "60025211", name: "Eckerö kommun", VAT: "0144682-1" },
  { id: "60026184", name: "Eckerö-Hammarlands församling", VAT: "3233119-7" },
  { id: "A3113", name: "Eduro-säätiö sr", VAT: "1508035-7" },
  { id: "110002", name: "Eduskunnan kanslia", VAT: "0245977-1" },
  {
    id: "110004",
    name: "Eduskunnan oikeusasiamiehen kanslia",
    VAT: "0245977-1",
  },
  { id: "A11369", name: "Ekokumppanit Oy", VAT: "1801748-4" },
  {
    id: "380016",
    name: "Elinkeino-, liikenne- ja ympäristökeskusten sekä työ- ja elinkeinotoimistojen kehittämis- ja hallintokeskus",
    VAT: "2296962-1",
  },
  { id: "966001", name: "Eläketurvakeskus", VAT: "0116415-4" },
  { id: "A11231", name: "EMMA-taidemuseosäätiö sr", VAT: "1784691-8" },
  { id: "500013", name: "Energiavirasto", VAT: "1738354-6" },
  { id: "60026340", name: "ENF Infra Oy", VAT: "3211213-8" },
  { id: "A11928", name: "Enonkosken kunta", VAT: "0163687-9" },
  { id: "A10280", name: "Enontekiön kunta", VAT: "0190662-1" },
  { id: "A10735", name: "Enontekiön seurakunta", VAT: "0190664-8" },
  { id: "60018145", name: "Esko Systems Oy", VAT: "3104530-8" },
  {
    id: "A10655",
    name: "Eskoon Sosiaalipalvelujen kuntayhtymä",
    VAT: "0283116-6",
  },
  { id: "A11373", name: "Espoo Catering Oy", VAT: "2716134-9" },
  { id: "A10057", name: "Espoon Asunnot Oy", VAT: "1565281-0" },
  {
    id: "A10739",
    name: "Espoon Hiippakunnan Tuomiokapituli",
    VAT: "1861003-4",
  },
  { id: "60024702", name: "Espoon kaupunki", VAT: "0101263-6" },
  {
    id: "A10251",
    name: "Espoon seudun koulutuskuntayhtymä Omnia",
    VAT: "0502454-6",
  },
  { id: "A10737", name: "Espoon Seurakuntayhtymä", VAT: "0200562-2" },
  { id: "60025826", name: "Etelä-Karjalan hyvinvointialue", VAT: "3221313-1" },
  { id: "A3239", name: "Etelä-Karjalan Jätehuolto Oy", VAT: "1056645-4" },
  {
    id: "A10235",
    name: "Etelä-Karjalan Koulutuskuntayhtymä",
    VAT: "1027740-9",
  },
  { id: "155051", name: "Etelä-Karjalan käräjäoikeus", VAT: "3006157-6" },
  { id: "A12059", name: "Etelä-Karjalan liitto", VAT: "0869462-5" },
  {
    id: "60025626",
    name: "Etelä-Karjalan Sairaalaparkki Oy",
    VAT: "2307032-7",
  },
  {
    id: "380008",
    name: "Etelä-Pohjanmaan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "60025831",
    name: "Etelä-Pohjanmaan hyvinvointialue",
    VAT: "3221323-8",
  },
  { id: "155052", name: "Etelä-Pohjanmaan käräjäoikeus", VAT: "3006157-6" },
  {
    id: "A10589",
    name: "Etelä-Pohjanmaan liitto kuntayhtymä",
    VAT: "0955281-3",
  },
  {
    id: "60017854",
    name: "Etelä-Pohjanmaan Musiikkiopiston Kannatusosakeyhtiö",
    VAT: "0216679-2",
  },
  {
    id: "3800031",
    name: "Etelä-Pohjanmaan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  {
    id: "380014",
    name: "Etelä-Savon elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025827", name: "Etelä-Savon hyvinvointialue", VAT: "3221315-8" },
  {
    id: "A10250",
    name: "Etelä-Savon kampuskiinteistöt kuntayhtymä",
    VAT: "1013321-0",
  },
  { id: "A10614", name: "Etelä-Savon Koulutus Oy", VAT: "2249317-6" },
  { id: "155053", name: "Etelä-Savon käräjäoikeus", VAT: "3006157-6" },
  { id: "A10590", name: "Etelä-Savon Maakuntaliitto", VAT: "0215839-7" },
  {
    id: "3800027",
    name: "Etelä-Savon työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "360001", name: "Etelä-Suomen aluehallintovirasto", VAT: "1094544-6" },
  {
    id: "150494",
    name: "Etelä-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  { id: "A10283", name: "Eurajoen kunta", VAT: "0132322-3" },
  { id: "A10745", name: "Eurajoen seurakunta", VAT: "0132325-8" },
  { id: "A10282", name: "Euran kunta", VAT: "0132239-4" },
  { id: "A10744", name: "Euran Seurakunta", VAT: "0242490-7" },
  {
    id: "60026135",
    name: "Euroopan hybridiuhkien torjunnan osaamiskeskus",
    VAT: "2841395-8",
  },
  { id: "981101", name: "Euroopan kemikaalivirasto (ECHA)", VAT: "2139942-8" },
  { id: "A10284", name: "Evijärven kunta", VAT: "0177804-1" },
  { id: "A10746", name: "Evijärven seurakunta", VAT: "0177807-6" },
  {
    id: "A3259",
    name: "Fastighets Ab Kristinestads bostäder Kiinteistö Oy",
    VAT: "0854858-0",
  },
  {
    id: "A3280",
    name: "Fastighets Ab Pedersöre Bostäder Kiinteistö Oy",
    VAT: "0181945-6",
  },
  { id: "A12174", name: "Fimlab Laboratoriot Oy", VAT: "2392519-6" },
  { id: "951002", name: "Finanssivalvonta", VAT: "0202248-1" },
  { id: "943001", name: "Finavia Oyj", VAT: "2302570-2" },
  { id: "60025992", name: "FinnHEMS Lentopalvelut Oy", VAT: "0810819-8" },
  { id: "A11382", name: "FinnHEMS Oy", VAT: "2347340-9" },
  { id: "941001", name: "Finnpilot Pilotage Oy", VAT: "2375854-3" },
  { id: "970001", name: "Finnvera Oyj", VAT: "1484332-4 " },
  { id: "60025207", name: "Finströms kommun", VAT: "0205003-6" },
  { id: "959001", name: "Fintraffic Lennonvarmistus Oy", VAT: "2767840-1" },
  {
    id: "812002",
    name: "Fintraffic Meriliikenteenohjaus Oy",
    VAT: "2945246-5",
  },
  { id: "949001", name: "Fintraffic Raide Oy", VAT: "2512095-7" },
  { id: "812003", name: "Fintraffic Tie Oy", VAT: "2945247-3" },
  { id: "A11720", name: "Forssa-asunnot Oy", VAT: "0936791-7" },
  { id: "A10285", name: "Forssan kaupunki", VAT: "0145626-1" },
  {
    id: "A3680",
    name: "Forssan seudun hyvinvointikuntayhtymä (FSHKY)",
    VAT: "0214295-0",
  },
  { id: "A10748", name: "Forssan seurakunta", VAT: "0145639-2" },
  { id: "A3084", name: "Forssan Yrityskehitys Oy", VAT: "1046929-1" },
  { id: "859001", name: "Funidata Oy", VAT: "2753912-9" },
  { id: "60026369", name: "Gasgrid Finland Oy", VAT: "3007894-1" },
  { id: "504001", name: "Geologian Tutkimuskeskus", VAT: "0244680-7" },
  { id: "60025208", name: "Geta kommun", VAT: "0205012-4" },
  { id: "867001", name: "Haaga-Helia ammattikorkeakoulu Oy", VAT: "2029188-8" },
  { id: "A10286", name: "Haapajärven kaupunki", VAT: "0209756-3" },
  { id: "A10750", name: "Haapajärven seurakunta", VAT: "0184786-0" },
  { id: "A10287", name: "Haapaveden kaupunki", VAT: "0184872-4" },
  { id: "A10751", name: "Haapaveden seurakunta", VAT: "0184875-9" },
  {
    id: "60026726",
    name: "Haapaveden Teollisuuskiinteistöt Oy",
    VAT: "2662125-1",
  },
  { id: "A10288", name: "Hailuodon kunta", VAT: "0184918-8" },
  { id: "A12062", name: "Hailuodon seurakunta", VAT: "0184921-7" },
  { id: "A10289", name: "Halsuan kunta", VAT: "0177826-0" },
  { id: "A10060", name: "Hamina-Asunnot Oy", VAT: "1764846-0" },
  { id: "A10708", name: "HaminaKotka Satama Oy", VAT: "2380744-8" },
  { id: "A10290", name: "Haminan kaupunki", VAT: "0242496-6" },
  { id: "60017881", name: "Haminan ortodoksinen seurakunta", VAT: "0242498-2" },
  { id: "A10756", name: "Haminan seurakunta", VAT: "2352833-4" },
  { id: "60017497", name: "HAMK Akatemia Oy", VAT: "3007351-8" },
  { id: "60024727", name: "Hammarland kommun", VAT: "0205014-0" },
  { id: "A10292", name: "Hangon kaupunki", VAT: "0103166-9" },
  {
    id: "A11961",
    name: "Hangö Kyrkliga Samfällighet-Hangon Seurakuntayhtymä",
    VAT: "0200888-2",
  },
  { id: "A10291", name: "Hankasalmen kunta", VAT: "0174035-0" },
  { id: "A10758", name: "Hankasalmen seurakunta", VAT: "0174038-5" },
  { id: "988001", name: "Hansel Oy", VAT: "0988084-1" },
  { id: "A10293", name: "Harjavallan kaupunki", VAT: "0132585-1" },
  { id: "A11962", name: "Harjavallan seurakunta", VAT: "0132588-6" },
  { id: "A10294", name: "Hartolan kunta", VAT: "0163734-5" },
  { id: "A3416", name: "Hattula seurakunta", VAT: "0145806-4" },
  { id: "A10295", name: "Hattulan kunta", VAT: "0145801-3" },
  { id: "962001", name: "Haus Kehittämiskeskus Oy", VAT: "1778388-1" },
  { id: "A10297", name: "Hausjärven kunta", VAT: "0145997-2" },
  { id: "A3394", name: "Hausjärven seurakunta", VAT: "0145999-9" },
  { id: "60017821", name: "Hausjärven Talo Oy", VAT: "0781386-4" },
  { id: "A10298", name: "Heinolan kaupunki", VAT: "1068892-9" },
  { id: "A10762", name: "Heinolan seurakunta", VAT: "2047678-6" },
  { id: "A10062", name: "Heinolan vuokra-asunnot Oy", VAT: "1033628-8" },
  { id: "A10299", name: "Heinäveden kunta", VAT: "0164308-3" },
  { id: "A11929", name: "Heinäveden seurakunta", VAT: "0164312-0" },
  { id: "A12092", name: "Helsingin Asumisoikeus Oy", VAT: "0912770-2" },
  { id: "660032", name: "Helsingin eurooppalainen koulu", VAT: "2769790-1" },
  { id: "155031", name: "Helsingin hallinto-oikeus", VAT: "3006157-6" },
  {
    id: "A10763",
    name: "Helsingin Hiippakunnan Tuomiokapituli",
    VAT: "0246829-9",
  },
  { id: "155024", name: "Helsingin hovioikeus", VAT: "3006157-6" },
  { id: "60025743", name: "Helsingin Innovaatiopalvelut Oy", VAT: "2398508-4" },
  { id: "A10073", name: "Helsingin kaupungin asunnot Oy", VAT: "2379058-6" },
  { id: "155054", name: "Helsingin käräjäoikeus", VAT: "3006157-6" },
  { id: "A3444", name: "Helsingin ortodoksinen seurakunta", VAT: "0116502-6" },
  { id: "215101", name: "Helsingin poliisilaitos", VAT: "0245432-1" },
  {
    id: "650011",
    name: "Helsingin Ranskalais-Suomalainen koulu",
    VAT: "0245912-2",
  },
  { id: "A10709", name: "Helsingin Satama Oy", VAT: "2630555-8" },
  {
    id: "A10640",
    name: "Helsingin seudun liikenne -kuntayhtymä",
    VAT: "2274586-3",
  },
  {
    id: "A10765",
    name: "Helsingin Seurakuntayhtymä - Helsingfors Kyrkliga Samfällighet",
    VAT: "0201242-7",
  },
  { id: "861001", name: "Helsingin Yliopisto", VAT: "0313471-7" },
  {
    id: "857001",
    name: "Helsingin yliopiston kiinteistöpalvelut Oy",
    VAT: "2838529-9",
  },
  {
    id: "A3401",
    name: "Helsinki, Kasvatuksen ja koulutuksen toimiala",
    VAT: "0201256-6",
  },
  {
    id: "A12108",
    name: "Helsinki, Kaupunginkanslia ja yleishallinto",
    VAT: "0201256-6",
  },
  {
    id: "A3389",
    name: "Helsinki, Kaupunkiympäristön toimiala",
    VAT: "0201256-6",
  },
  {
    id: "A3388",
    name: "Helsinki, Kulttuurin ja vapaa-ajan toimiala",
    VAT: "0201256-6",
  },
  {
    id: "A11875",
    name: "Helsinki, Liikennelaitos -liikelaitos",
    VAT: "0201256-6",
  },
  {
    id: "A3402",
    name: "Helsinki, Palvelukeskus -liikelaitos",
    VAT: "0201256-6",
  },
  { id: "A11834", name: "Helsinki, Pelastuslaitos", VAT: "0201256-6" },
  {
    id: "A3522",
    name: "Helsinki, Sosiaali- ja terveystoimiala",
    VAT: "0201256-6",
  },
  { id: "A11878", name: "Helsinki, Stara-liikelaitos", VAT: "0201256-6" },
  {
    id: "A12163",
    name: "Helsinki, Taloushallintopalveluliikelaitos",
    VAT: "0201256-6",
  },
  { id: "A12164", name: "Helsinki, Tarkastusvirasto", VAT: "0201256-6" },
  { id: "A11777", name: "Helsinki, Työterveysliikelaitos", VAT: "0201256-6" },
  { id: "60017931", name: "Hiedanrannan Kehitys Oy", VAT: "3002666-6" },
  { id: "60017932", name: "Hiedanranta Ky", VAT: "3107122-3" },
  { id: "A11909", name: "Himos-Infra Oy", VAT: "1543389-6" },
  { id: "A10301", name: "Hirvensalmen kunta", VAT: "0164384-1" },
  { id: "A11963", name: "Hirvensalmen seurakunta", VAT: "0164387-6" },
  { id: "A10302", name: "Hollolan kunta", VAT: "0146248-5" },
  { id: "A10771", name: "Hollolan seurakunta", VAT: "0146255-7" },
  { id: "A3442", name: "Hollolan Tilapalvelu Oy", VAT: "2840811-2" },
  { id: "60026073", name: "Honkainfra Oy", VAT: "3105352-7" },
  { id: "60026241", name: "HPK Palvelut Oy", VAT: "3259263-7" },
  { id: "60017739", name: "HSY, Jätehuolto", VAT: "2274241-9" },
  { id: "60017738", name: "HSY, Ohjaus- ja kehittäminen", VAT: "2274241-9" },
  { id: "60017741", name: "HSY, Seutu- ja ympäristötieto", VAT: "2274241-9" },
  { id: "60017485", name: "HSY, Tukipalvelut", VAT: "2274241-9" },
  { id: "60017740", name: "HSY, Vesihuolto", VAT: "2274241-9" },
  { id: "A10304", name: "Huittisten kaupunki", VAT: "0203762-4" },
  { id: "A10773", name: "Huittisten seurakunta", VAT: "0132847-0" },
  { id: "60026434", name: "Hulmin Huolto Oy", VAT: "0505992-5" },
  { id: "A10305", name: "Humppilan kunta", VAT: "0146456-0" },
  { id: "A13001", name: "Humppilan seurakunta", VAT: "0146457-9" },
  { id: "500012", name: "Huoltovarmuuskeskus", VAT: "0202419-6" },
  { id: "930001", name: "Huoltovarmuusrahasto", VAT: "" },
  { id: "A10076", name: "HUS Kiinteistöt Oy", VAT: "0821653-5" },
  { id: "A10694", name: "HUS-yhtymä", VAT: "1567535-0" },
  { id: "A10619", name: "Hyria koulutus Oy", VAT: "2250205-2" },
  { id: "A3076", name: "Hyria säätiö sr", VAT: "0213798-3" },
  { id: "A10306", name: "Hyrynsalmen kunta", VAT: "0185075-2" },
  { id: "A10775", name: "Hyrynsalmen seurakunta", VAT: "0185078-7" },
  { id: "A10307", name: "Hyvinkään kaupunki", VAT: "0125866-0" },
  { id: "A10776", name: "Hyvinkään seurakunta", VAT: "0125876-7" },
  { id: "A10620", name: "Hämeen Ammatti-Instituutti Oy", VAT: "2627679-3" },
  { id: "869001", name: "Hämeen ammattikorkeakoulu Oy", VAT: "2617489-3" },
  {
    id: "380002",
    name: "Hämeen elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "A3130", name: "Hämeen Maakuntaliitto, kuntayhtymä", VAT: "0826048-0" },
  { id: "224102", name: "Hämeen poliisilaitos", VAT: "2288666-6" },
  { id: "3800024", name: "Hämeen työ- ja elinkeinotoimisto", VAT: "2296962-1" },
  { id: "A10309", name: "Hämeenkyrön kunta", VAT: "0132947-3" },
  { id: "A13018", name: "Hämeenkyrön seurakunta", VAT: "0132952-9" },
  {
    id: "A3438",
    name: "Hämeenlinnan Erityisasuntosäätiö sr",
    VAT: "0648130-0",
  },
  { id: "155033", name: "Hämeenlinnan hallinto-oikeus", VAT: "3006157-6" },
  { id: "60024698", name: "Hämeenlinnan Jäähalli Oy", VAT: "0896410-3" },
  { id: "A3126", name: "Hämeenlinnan Kaupungin Teatteri Oy", VAT: "0205308-5" },
  { id: "60024703", name: "Hämeenlinnan kaupunki", VAT: "0146921-4" },
  { id: "A3127", name: "Hämeenlinnan Liikuntahallit Oy", VAT: "1972415-6" },
  {
    id: "60017882",
    name: "Hämeenlinnan ortodoksinen seurakunta",
    VAT: "0146925-7",
  },
  { id: "60024699", name: "Hämeenlinnan Palloiluhalli Oy", VAT: "1037204-3" },
  { id: "60017826", name: "Hämeenlinnan Pysäköinti Oy", VAT: "2196076-4" },
  { id: "A3129", name: "Hämeenlinnan Seudun Vesi Oy", VAT: "1711549-6" },
  { id: "A12061", name: "Hämeenlinnan seurakuntayhtymä", VAT: "1788883-5" },
  { id: "248001", name: "Hätäkeskuslaitos", VAT: "2409452-3" },
  { id: "60000288", name: "Högskolan på Åland", VAT: "0145076-7" },
  { id: "A10311", name: "Iin kunta", VAT: "2054621-1" },
  { id: "A10778", name: "Iin seurakunta", VAT: "0185161-6" },
  { id: "A10312", name: "Iisalmen kaupunki", VAT: "9086071-6" },
  {
    id: "60017896",
    name: "Iisalmen ortodoksinen seurakunta",
    VAT: "0207727-1",
  },
  { id: "A10313", name: "Iitin kunta", VAT: "0158766-7" },
  { id: "A10779", name: "Iitin seurakunta", VAT: "0158768-3" },
  { id: "A10083", name: "Iitin Vuokratalot Oy", VAT: "0474791-0" },
  { id: "A10314", name: "Ikaalisten kaupunki", VAT: "0203797-4" },
  { id: "A13013", name: "Ikaalisten seurakunta", VAT: "0133047-4" },
  { id: "A10315", name: "Ilmajoen kunta", VAT: "0178008-8" },
  { id: "A10780", name: "Ilmajoen seurakunta", VAT: "0177992-5" },
  { id: "494001", name: "Ilmatieteen laitos", VAT: "0244664-7" },
  { id: "A11930", name: "Ilomantsin Ev.Lut.Seurakunta", VAT: "0242661-1" },
  { id: "A10316", name: "Ilomantsin kunta", VAT: "0167589-4" },
  {
    id: "60017893",
    name: "Ilomantsin ortodoksinen seurakunta",
    VAT: "0242663-8",
  },
  { id: "A10317", name: "Imatran kaupunki", VAT: "0159216-7" },
  {
    id: "A11949",
    name: "Imatran Kiinteistö- ja Aluepalvelu Oy",
    VAT: "2448706-0",
  },
  { id: "A11403", name: "Imatran Seudun Kehitysyhtiö Oy", VAT: "0494547-4" },
  { id: "A10781", name: "Imatran seurakunta", VAT: "0159225-5" },
  { id: "A11404", name: "Imatran Toimitilat Oy", VAT: "2448694-9" },
  { id: "A10085", name: "Inarin Kiinteistöt Oy", VAT: "2508024-6" },
  { id: "A10318", name: "Inarin kunta", VAT: "0190758-7" },
  { id: "A11285", name: "Inarin Lapin Vesi Oy", VAT: "0644614-2" },
  { id: "60017457", name: "Inarin Vuokra-asunnot Oy", VAT: "2498854-3" },
  { id: "A3092", name: "Inergia Lämpö Oy", VAT: "1577920-5" },
  { id: "A12225", name: "Inergia Oy", VAT: "0775446-2" },
  { id: "A10783", name: "Ingå Församling", VAT: "0126291-8" },
  { id: "A10319", name: "Ingå kommun", VAT: "0126293-4" },
  {
    id: "509001",
    name: "Innovaatiorahoituskeskus Business Finland",
    VAT: "0512696-4",
  },
  {
    id: "989001",
    name: "Integrated Carbon Observation System European Research Infrastructure Consortium",
    VAT: "2726851-7",
  },
  { id: "A11406", name: "Into Seinäjoki Oy", VAT: "1637389-9" },
  { id: "60012671", name: "ISLAB hyvinvointiyhtymä", VAT: "2126106-6" },
  { id: "A10320", name: "Isojoen kunta", VAT: "0178071-5" },
  { id: "A10784", name: "Isojoen seurakunta", VAT: "0178073-1" },
  { id: "A10086", name: "Isonkyrön Asuntovuokraus Oy", VAT: "0844231-3" },
  { id: "A10321", name: "Isonkyrön kunta", VAT: "0178131-2" },
  { id: "A10785", name: "Isonkyrön seurakunta", VAT: "0178134-7" },
  { id: "A10024", name: "Istekki Oy", VAT: "2292633-0" },
  { id: "A10644", name: "Itä-Lapin Kuntayhtymä", VAT: "1044651-6" },
  { id: "A10266", name: "Itä-Savon koulutuskuntayhtymä", VAT: "0207390-8" },
  { id: "360003", name: "Itä-Suomen aluehallintovirasto", VAT: "1094822-5" },
  { id: "155035", name: "Itä-Suomen hallinto-oikeus", VAT: "3006157-6" },
  { id: "155023", name: "Itä-Suomen hovioikeus", VAT: "3006157-6" },
  { id: "A10622", name: "Itä-Suomen Liikuntaopisto Oy", VAT: "0167924-6" },
  {
    id: "150497",
    name: "Itä-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  { id: "224105", name: "Itä-Suomen poliisilaitos", VAT: "2288666-6" },
  { id: "831001", name: "Itä-Suomen yliopisto", VAT: "2285733-9" },
  { id: "60025836", name: "Itä-Uudenmaan hyvinvointialue", VAT: "3221339-3" },
  { id: "155055", name: "Itä-Uudenmaan käräjäoikeus", VAT: "3006157-6" },
  { id: "240102", name: "Itä-Uudenmaan poliisilaitos", VAT: "2288666-6" },
  { id: "A10471", name: "Jakobstads stad", VAT: "0209242-0" },
  { id: "A3299", name: "Janakkalan Asunnot Oy", VAT: "0682797-3" },
  { id: "A3843", name: "Janakkalan Elsa ja Pauli Kodit Oy", VAT: "2902670-6" },
  { id: "A10323", name: "Janakkalan kunta", VAT: "0147510-4" },
  { id: "A10788", name: "Janakkalan seurakunta", VAT: "0147516-3" },
  {
    id: "60026056",
    name: "Jatkuvan oppimisen ja työllisyyden palvelukeskus",
    VAT: "2769790-1",
  },
  {
    id: "A10658",
    name: "JIK-peruspalveluliikelaitoskuntayhtymä",
    VAT: "2220682-7",
  },
  {
    id: "A10790",
    name: "Joensuun Ev. -Lut. Seurakuntayhtymä",
    VAT: "0168185-6",
  },
  { id: "A10324", name: "Joensuun kaupunki", VAT: "0242746-2" },
  { id: "A3060", name: "Joensuun ortodoksinen seurakunta", VAT: "0168203-1" },
  { id: "A3121", name: "Joki ICT Oy", VAT: "1068473-3" },
  { id: "A10238", name: "Jokilaaksojen koulutuskuntayhtymä", VAT: "0210010-1" },
  { id: "A10325", name: "Jokioisten kunta", VAT: "0147645-7" },
  { id: "A13008", name: "Jokioisten seurakunta", VAT: "0147667-6" },
  { id: "A11749", name: "Jomala Församling", VAT: "0205021-2" },
  { id: "60025220", name: "Jomala kommun", VAT: "0205023-9" },
  { id: "A10326", name: "Joroisten kunta", VAT: "0207112-8" },
  { id: "A10794", name: "Joroisten seurakunta", VAT: "0207113-6" },
  { id: "A10327", name: "Joutsan kunta", VAT: "0174108-9" },
  { id: "A10796", name: "Joutsan seurakunta", VAT: "0174118-5" },
  { id: "A10329", name: "Juuan kunta", VAT: "0168654-2" },
  { id: "A11931", name: "Juuan seurakunta", VAT: "0168660-6" },
  { id: "A10330", name: "Juupajoen kunta", VAT: "0147705-4" },
  { id: "A10331", name: "Juvan kunta", VAT: "0164551-3" },
  { id: "A11966", name: "Juvan seurakunta", VAT: "0164556-4" },
  { id: "870001", name: "Jyväskylän Ammattikorkeakoulu Oy", VAT: "1006550-2" },
  { id: "60024704", name: "Jyväskylän kaupunki", VAT: "0174666-4" },
  {
    id: "A10239",
    name: "Jyväskylän koulutuskuntayhtymä Gradia",
    VAT: "0208201-1",
  },
  {
    id: "60017884",
    name: "Jyväskylän ortodoksinen seurakunta",
    VAT: "0174683-2",
  },
  {
    id: "A11413",
    name: "Jyväskylän Seudun kehittämisyhtiö Jykes Oy",
    VAT: "1036087-8",
  },
  { id: "A10799", name: "Jyväskylän seurakunta", VAT: "0208301-4" },
  { id: "A11160", name: "Jyväskylän Voima Oy", VAT: "2033847-1" },
  { id: "A10089", name: "Jyväskylän Vuokra-asunnot Oy", VAT: "0879243-6" },
  { id: "60017909", name: "Jyväskylän yliopisto", VAT: "0245894-7" },
  { id: "A10333", name: "Jämijärven kunta", VAT: "0133127-4" },
  {
    id: "60026576",
    name: "Jämsän Ateria- ja Puhtauspalvelut Oy",
    VAT: "3325511-4",
  },
  { id: "A10334", name: "Jämsän kaupunki", VAT: "0175622-1" },
  { id: "A12060", name: "Jämsän seurakunta", VAT: "1636878-2" },
  { id: "A10090", name: "Jämsän Yrityskiinteistöt Oy", VAT: "1862383-9" },
  { id: "A10335", name: "Järvenpään kaupunki", VAT: "0126541-4" },
  { id: "60017444", name: "Järvenpään Mestariasunnot Oy", VAT: "0726386-6" },
  { id: "A10800", name: "Järvenpään seurakunta", VAT: "0202913-0" },
  { id: "A10025", name: "Järvinet Oy", VAT: "1788352-4" },
  { id: "A11417", name: "Järvi-Pohjanmaan Yrityspalvelu Oy", VAT: "0932480-2" },
  { id: "A10240", name: "Järviseudun Koulutuskuntayhtymä", VAT: "1807931-9" },
  { id: "60024311", name: "Järviseudun Työterveys Oy", VAT: "2951894-6" },
  { id: "A13002", name: "Jätekukko Oy", VAT: "1710559-7" },
  {
    id: "888001",
    name: "Kaakkois-Suomen Ammattikorkeakoulu Oy",
    VAT: "2472908-2",
  },
  {
    id: "380015",
    name: "Kaakkois-Suomen elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "150496",
    name: "Kaakkois-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  {
    id: "60017891",
    name: "Kaakkois-Suomen ortodoksinen seurakunta",
    VAT: "0162203-9",
  },
  { id: "224103", name: "Kaakkois-Suomen poliisilaitos", VAT: "2288666-6" },
  { id: "A10026", name: "Kaakkois-Suomen Tieto Oy", VAT: "2369983-8" },
  {
    id: "3800026",
    name: "Kaakkois-Suomen työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A11360", name: "Kaarea Kunnossapito Oy", VAT: "2701289-6" },
  { id: "A3831", name: "Kaarea Oy", VAT: "2925981-7" },
  { id: "A10336", name: "Kaarinan kaupunki", VAT: "0133226-9" },
  { id: "A10337", name: "Kaavin kunta", VAT: "0170664-6" },
  { id: "60025716", name: "Kaiku24 Oy", VAT: "3192900-7" },
  {
    id: "380010",
    name: "Kainuun elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "A3250", name: "Kainuun Etu Oy", VAT: "0784050-4" },
  { id: "60025834", name: "Kainuun hyvinvointialue", VAT: "3221331-8" },
  { id: "A10645", name: "Kainuun Jätehuollon kuntayhtymä", VAT: "1744356-1" },
  { id: "155056", name: "Kainuun käräjäoikeus", VAT: "3006157-6" },
  { id: "A10591", name: "Kainuun liitto", VAT: "2496992-4" },
  {
    id: "3800034",
    name: "Kainuun työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A3754", name: "Kainuunmeren Työterveys Oy", VAT: "2787365-3" },
  { id: "871001", name: "Kajaanin Ammattikorkeakoulu Oy", VAT: "2553600-4" },
  { id: "60024705", name: "Kajaanin kaupunki", VAT: "0214958-9" },
  { id: "A10804", name: "Kajaanin seurakunta", VAT: "0209859-0" },
  { id: "A10339", name: "Kalajoen kaupunki", VAT: "0185924-7" },
  { id: "A3858", name: "Kalajoen Kuitu Oy", VAT: "2960926-6" },
  { id: "A3165", name: "Kalajoen Lämpö Oy", VAT: "0185891-4" },
  { id: "A10805", name: "Kalajoen seurakunta", VAT: "0185929-8" },
  { id: "A10340", name: "Kangasalan Kaupunki", VAT: "1923299-5" },
  { id: "A13014", name: "Kangasalan seurakunta", VAT: "0205424-8" },
  { id: "A10341", name: "Kangasniemen kunta", VAT: "0164690-3" },
  { id: "A10808", name: "Kangasniemen seurakunta", VAT: "0164698-9" },
  { id: "A10342", name: "Kankaanpään kaupunki", VAT: "0133596-1" },
  { id: "A10809", name: "Kankaanpään seurakunta", VAT: "0133601-6" },
  { id: "A10343", name: "Kannonkosken kunta", VAT: "0175798-8" },
  { id: "A10344", name: "Kannuksen kaupunki", VAT: "0178455-6" },
  { id: "A10811", name: "Kannuksen seurakunta", VAT: "0178456-4" },
  {
    id: "600018",
    name: "Kansallinen audiovisuaalinen instituutti",
    VAT: "0245872-8",
  },
  {
    id: "6600023",
    name: "Kansallinen koulutuksen arviointikeskus",
    VAT: "2769790-1",
  },
  { id: "603001", name: "Kansallisarkisto", VAT: "0245885-9" },
  { id: "986001", name: "Kansallisgalleria", VAT: "0800570-3" },
  { id: "967", name: "Kansaneläkelaitos", VAT: "0246246-0" },
  { id: "60025822", name: "Kanta-Hämeen hyvinvointialue", VAT: "3221307-8" },
  { id: "155057", name: "Kanta-Hämeen käräjäoikeus", VAT: "3006157-6" },
  { id: "A3445", name: "Kanta-Hämeen Sairaskotisäätiö sr", VAT: "0653924-2" },
  { id: "872001", name: "Karelia Ammattikorkeakoulu Oy", VAT: "2454377-1" },
  { id: "A10345", name: "Karijoen kunta", VAT: "0178498-6" },
  { id: "A11694", name: "Karijoen seurakunta", VAT: "0178502-2" },
  { id: "A10347", name: "Karkkilan kaupunki", VAT: "0127046-7" },
  { id: "A10816", name: "Karkkilan seurakunta", VAT: "0127051-2" },
  { id: "A10348", name: "Karstulan kunta", VAT: "9094917-1" },
  {
    id: "60026494",
    name: "Karstulan Seudun Ravitsemuspalvelut KARSERA Oy",
    VAT: "3333466-4",
  },
  { id: "A10818", name: "Karstulan seurakunta", VAT: "9094920-0" },
  { id: "A10350", name: "Karvian kunta", VAT: "0133735-0" },
  { id: "60024304", name: "KAS asunnot Oy", VAT: "1656611-3" },
  { id: "A10351", name: "Kaskisten kaupunki Kaskö stad", VAT: "0208787-5" },
  {
    id: "A11752",
    name: "Kaskisten seurakunta Kaskö Församling",
    VAT: "0208788-3",
  },
  { id: "A10352", name: "Kauhajoen kaupunki", VAT: "0178718-3" },
  { id: "A11753", name: "Kauhajoen seurakunta", VAT: "0178722-0" },
  { id: "A11286", name: "Kauhajoen Vesihuolto Oy", VAT: "0178645-5" },
  { id: "60026557", name: "Kauhavan Hyvinvointikeskus Oy", VAT: "3088505-7" },
  { id: "60026558", name: "Kauhavan Jäähalli Oy", VAT: "0908135-3" },
  { id: "A11162", name: "Kauhavan Kaukolämpö Oy", VAT: "0355682-1" },
  { id: "A10353", name: "Kauhavan kaupunki", VAT: "0208852-8" },
  { id: "A10820", name: "Kauhavan seurakunta", VAT: "0208859-5" },
  { id: "60017498", name: "Kauhavan Vesi Oy", VAT: "1627902-6" },
  { id: "A10100", name: "Kauhavan Vuokra-asunnot Oy", VAT: "0178914-6" },
  { id: "60026561", name: "Kauhavan Yrityskiinteistöt Oy", VAT: "0305601-8" },
  { id: "A10354", name: "Kauniaisten kaupunki", VAT: "0203026-2" },
  { id: "A3122", name: "Kauniaisten seurakuntayhtymä", VAT: "0203033-4" },
  { id: "A11163", name: "Kausalan Lämpö Oy", VAT: "0211787-8" },
  { id: "A11424", name: "Kaustisen Kehitys Oy", VAT: "0310431-5" },
  { id: "A10355", name: "Kaustisen kunta", VAT: "0178981-6" },
  { id: "A11425", name: "Kehittämisyhtiö Karstulanseutu Oy", VAT: "0776042-4" },
  { id: "60026419", name: "Kehitys Oy Kurittula", VAT: "1784938-5" },
  { id: "A10356", name: "Keiteleen kunta", VAT: "0170773-7" },
  { id: "A10358", name: "Kemijärven kaupunki", VAT: "0191717-9" },
  { id: "A11429", name: "Kemijärven Kehitys Oy", VAT: "0347641-8" },
  { id: "A3345", name: "Kemijärven lämpö ja vesi Oy", VAT: "0210506-8" },
  { id: "A10823", name: "Kemijärven seurakunta", VAT: "0191719-5" },
  { id: "A11431", name: "Kemin Digipolis Oy", VAT: "0935441-1" },
  { id: "A11166", name: "Kemin Energia ja Vesi Oy", VAT: "1571467-1" },
  { id: "A10357", name: "Kemin kaupunki", VAT: "0210427-6" },
  { id: "60024317", name: "Kemin Keilatalo Oy", VAT: "0665746-8" },
  { id: "A10711", name: "Kemin Satama Oy", VAT: "2446426-4" },
  { id: "A10822", name: "Kemin seurakunta", VAT: "0210428-4" },
  {
    id: "60024321",
    name: "Kemin Sotainvalidien palveluasuntosäätiö sr",
    VAT: "1082630-8",
  },
  { id: "A11433", name: "Kemin Teollisuuskylä Oy", VAT: "0526558-5" },
  { id: "A10359", name: "Keminmaan kunta", VAT: "0210469-8" },
  { id: "A10824", name: "Keminmaan seurakunta", VAT: "0191263-4" },
  { id: "A10101", name: "Keminmaan Vuokra-asunnot Oy", VAT: "0191297-6" },
  {
    id: "A10241",
    name: "Kemi-Tornionlaakson koulutuskuntayhtymä Lappia",
    VAT: "2109309-0",
  },
  { id: "A10361", name: "Kempeleen kunta", VAT: "0186002-9" },
  { id: "A10825", name: "Kempeleen seurakunta", VAT: "0186008-8" },
  {
    id: "A10826",
    name: "Keravan Evankelisluterilainen seurakunta",
    VAT: "0127491-9",
  },
  { id: "A10362", name: "Keravan kaupunki", VAT: "0127485-5" },
  {
    id: "60017748",
    name: "Keskinäinen Kiinteistö Oy Sevettijärven palvelukeskus",
    VAT: "2193880-7",
  },
  {
    id: "60025833",
    name: "Keski-Pohjanmaan hyvinvointialue",
    VAT: "3221325-4",
  },
  { id: "A10242", name: "Keski-Pohjanmaan Koulutusyhtymä", VAT: "0208916-8" },
  {
    id: "A10592",
    name: "Keski-Pohjanmaan liitto - Förbundet för Mellersta Österbotten kuntayhtymä",
    VAT: "0959806-1",
  },
  {
    id: "A10697",
    name: "Keski-Pohjanmaan sosiaali- ja terveyspalvelukuntayhtymä",
    VAT: "0216462-3",
  },
  {
    id: "A10661",
    name: "Keski-Satakunnan sosiaali- ja terveydenhuollon kuntayhtymä",
    VAT: "0203738-4",
  },
  {
    id: "A3240",
    name: "Keski-Savon Jätehuolto liikelaitoskuntayhtymä",
    VAT: "2367681-2",
  },
  { id: "60025233", name: "Keski-Savon Teollisuuskylä Oy", VAT: "0398652-2" },
  { id: "A3327", name: "Keski-Savon Vesi Oy", VAT: "2444779-8" },
  {
    id: "380006",
    name: "Keski-Suomen elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025830", name: "Keski-Suomen hyvinvointialue", VAT: "3221318-2" },
  { id: "155058", name: "Keski-Suomen käräjäoikeus", VAT: "3006157-6" },
  { id: "A3026", name: "Keski-Suomen liitto", VAT: "0830155-3" },
  {
    id: "3800030",
    name: "Keski-Suomen työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "60025838", name: "Keski-Uudenmaan hyvinvointialue", VAT: "3221340-6" },
  {
    id: "A10243",
    name: "Keski-Uudenmaan koulutuskuntayhtymä",
    VAT: "0213834-5",
  },
  { id: "A3330", name: "Keski-Uudenmaan Työterveys Oy", VAT: "2841282-4" },
  { id: "216001", name: "Keskusrikospoliisi", VAT: "0245434-8" },
  {
    id: "60026554",
    name: "Keurusseudun Ateria- ja tukipalvelut Oy",
    VAT: "3333796-1",
  },
  { id: "A10363", name: "Keuruun kaupunki", VAT: "0208388-2" },
  { id: "A10829", name: "Keuruun seurakunta", VAT: "0208394-6" },
  { id: "A11272", name: "Keva", VAT: "0119343-0" },
  { id: "A10041", name: "Kiertokaari Oy", VAT: "2650662-4" },
  { id: "60024301", name: "Kiertokapula Oy", VAT: "0919068-0" },
  { id: "A10364", name: "Kihniön kunta", VAT: "0133862-8" },
  { id: "A12048", name: "Kihniön seurakunta", VAT: "0133866-0" },
  { id: "60026384", name: "Kiint. Oy Telkäntie 1", VAT: "0248688-4" },
  { id: "A10047", name: "Kiinteistö Oy Ahmahaka", VAT: "0185066-4" },
  {
    id: "963012",
    name: "Kiinteistö Oy Alavuden Virastotalo",
    VAT: "0649605-1",
  },
  { id: "A11821", name: "Kiinteistö Oy Alavus Fasadi", VAT: "1950964-0" },
  { id: "A12119", name: "Kiinteistö Oy Auroranlinna", VAT: "0689784-0" },
  { id: "987001", name: "Kiinteistö Oy Biomedicum Helsinki", VAT: "1076730-1" },
  {
    id: "A3041",
    name: "Kiinteistö Oy Enontekiön kunnan asunnot",
    VAT: "1575855-1",
  },
  { id: "60026727", name: "Kiinteistö Oy Haapa-asunnot", VAT: "1574207-4" },
  {
    id: "963013",
    name: "Kiinteistö Oy Helsingin Kulttuuritalo",
    VAT: "1013214-6",
  },
  { id: "60026370", name: "Kiinteistö Oy HUS-Asunnot", VAT: "0946130-5" },
  {
    id: "60026054",
    name: "Kiinteistö Oy Hyrynsalmen Virastotalo",
    VAT: "0543065-9",
  },
  {
    id: "A10104",
    name: "Kiinteistö Oy Hyvinkään Sairaalanmäki",
    VAT: "1608696-1",
  },
  {
    id: "963023",
    name: "Kiinteistö Oy Hämeenlinnan Poliisitalo",
    VAT: "2334057-7",
  },
  { id: "A11408", name: "Kiinteistö Oy Itätuuli", VAT: "0360849-2" },
  { id: "60026310", name: "Kiinteistö Oy Jermu", VAT: "0583751-2" },
  { id: "60026329", name: "Kiinteistö Oy Juvan Puulaakso", VAT: "1637675-8" },
  { id: "A10146", name: "Kiinteistö Oy Jämsänmäki", VAT: "0422497-6" },
  { id: "A10092", name: "Kiinteistö Oy Järvenneito", VAT: "0872739-4" },
  { id: "60026754", name: "Kiinteistö Oy Kajaanin Pietari", VAT: "0185799-7" },
  {
    id: "A3151",
    name: "Kiinteistö Oy Kalajoen vuokra-asunnot",
    VAT: "1055124-4",
  },
  {
    id: "963005",
    name: "Kiinteistö Oy Karttulan Valtiontalo",
    VAT: "0643286-7",
  },
  {
    id: "60026562",
    name: "Kiinteistö Oy Kauhavan Tietotalo",
    VAT: "1512027-5",
  },
  {
    id: "60026435",
    name: "Kiinteistö Oy Keravan ja Tuusulan Paloasema",
    VAT: "2664817-1",
  },
  {
    id: "60017825",
    name: "Kiinteistö Oy Kihniön Suutarinpiha",
    VAT: "0909962-5",
  },
  {
    id: "A3665",
    name: "Kiinteistö Oy Kiuruveden Kiurunkulma",
    VAT: "0720848-8",
  },
  {
    id: "21007768",
    name: "Kiinteistö Oy Kuhmon kulttuuritalo",
    VAT: "0868857-5",
  },
  {
    id: "60026421",
    name: "Kiinteistö Oy Kuhmon Terva-Asunnot",
    VAT: "1071357-3",
  },
  {
    id: "A10152",
    name: "Kiinteistö Oy Kuusamon Vuokratalot",
    VAT: "0663137-2",
  },
  { id: "60026598", name: "Kiinteistö Oy Kyrön Tapiola", VAT: "0212992-4" },
  {
    id: "60026371",
    name: "Kiinteistö Oy Laihian Vuokratalot",
    VAT: "0990649-0",
  },
  { id: "60002458", name: "Kiinteistö Oy Lappi Areena", VAT: "1769291-2" },
  {
    id: "60026720",
    name: "Kiinteistö Oy Lehdokkitien Virastotalo",
    VAT: "0467821-4",
  },
  {
    id: "A10147",
    name: "Kiinteistö Oy Loimaan Vuokra-asunnot",
    VAT: "1075661-0",
  },
  { id: "A10118", name: "Kiinteistö Oy Lumijoen Kartano", VAT: "0593372-3" },
  {
    id: "963015",
    name: "Kiinteistö Oy Mansikkalan Virastotalo",
    VAT: "0735050-5",
  },
  {
    id: "963019",
    name: "Kiinteistö Oy Mäntyharjun Virastokeskus",
    VAT: "0570810-4",
  },
  { id: "60017750", name: "Kiinteistö Oy Mäyrä", VAT: "0190234-8" },
  { id: "A10173", name: "Kiinteistö Oy Nikkarinkruunu", VAT: "0845722-4" },
  { id: "A10121", name: "Kiinteistö Oy Paltamon Kiehinen", VAT: "0580859-4" },
  { id: "60026495", name: "Kiinteistö Oy Perhon Kartano", VAT: "0727653-6" },
  {
    id: "A3386",
    name: "Kiinteistö Oy Pielaveden Vuokratalot",
    VAT: "0879629-3",
  },
  {
    id: "A10180",
    name: "Kiinteistö Oy Pikipruukki Fastighets Ab",
    VAT: "0421696-8",
  },
  {
    id: "60026599",
    name: "Kiinteistö Oy Pöytyän Yritystalo",
    VAT: "0812621-4",
  },
  { id: "963010", name: "Kiinteistö Oy Ratamestarinkatu 9", VAT: "0121513-4" },
  { id: "60026563", name: "Kiinteistö Oy Riihi", VAT: "1460538-5" },
  {
    id: "A10129",
    name: "Kiinteistö Oy Riihikallion lähipalvelukeskus",
    VAT: "0131596-0",
  },
  {
    id: "60025916",
    name: "Kiinteistö Oy Riihimäen Jäähalli",
    VAT: "2540914-4",
  },
  {
    id: "60025917",
    name: "Kiinteistö Oy Riihimäen Kalevanrinne",
    VAT: "0905552-6",
  },
  {
    id: "60025918",
    name: "Kiinteistö Oy Riihimäen Paloasema",
    VAT: "2531989-4",
  },
  {
    id: "60025935",
    name: "Kiinteistö Oy Riihimäen Sepänkatu 5",
    VAT: "2960091-5",
  },
  {
    id: "60025919",
    name: "Kiinteistö Oy Riihimäen Yritystalo",
    VAT: "0703560-1",
  },
  { id: "963004", name: "Kiinteistö Oy Röyttä", VAT: "0193615-0" },
  { id: "60026351", name: "Kiinteistö Oy Sotkanmaa", VAT: "1796681-3" },
  {
    id: "A10137",
    name: "Kiinteistö Oy Suomussalmen Vuokratalot",
    VAT: "1071533-3",
  },
  {
    id: "A3341",
    name: "Kiinteistö Oy Suonenjoen Vuokratalot",
    VAT: "0507259-9",
  },
  { id: "60026721", name: "Kiinteistö Oy Tiedepuisto", VAT: "0625800-2" },
  { id: "963001", name: "Kiinteistö Oy Turun Matintalo", VAT: "0716169-1" },
  { id: "A11822", name: "Kiinteistö Oy Tuurin Tietotalo", VAT: "1635512-1" },
  { id: "A10224", name: "Kiinteistö Oy Vaalan Niska", VAT: "1065491-3" },
  {
    id: "963022",
    name: "Kiinteistö Oy Valkeakosken Virastotalo",
    VAT: "0157510-5",
  },
  {
    id: "60026722",
    name: "Kiinteistö Oy Vantaan Maakotkantie 10",
    VAT: "0801033-4",
  },
  {
    id: "963008",
    name: "Kiinteistö Oy Viitasaaren Virastokeskus",
    VAT: "0539176-8",
  },
  {
    id: "60017930",
    name: "Kiinteistö Oy Ylöjärven Työtuvat",
    VAT: "1927314-8",
  },
  { id: "A11370", name: "Kiinteistökehitys InLike Oy", VAT: "1571435-6" },
  {
    id: "60026423",
    name: "Kiinteistöosakeyhtiö Alastaro-Koti",
    VAT: "0608415-3",
  },
  {
    id: "938001",
    name: "Kiinteistöosakeyhtiö Helsingin Mannerheimintie 13a",
    VAT: "1962625-7",
  },
  { id: "A3032", name: "Kiinteistöosakeyhtiö Keskiväli", VAT: "0165786-4" },
  { id: "A11438", name: "Kiljavan Sairaala Oy", VAT: "2841999-7" },
  { id: "519001", name: "Kilpailu- ja kuluttajavirasto", VAT: "2502067-3" },
  {
    id: "A10832",
    name: "Kimitoöns församling-Kemiönsaaren seurakunta",
    VAT: "2218662-7",
  },
  { id: "A10360", name: "Kimitoöns kommun", VAT: "0133833-7" },
  { id: "60024324", name: "Kimitoöns Värme Ab", VAT: "0635294-7" },
  { id: "A10367", name: "Kinnulan kunta", VAT: "0242816-6" },
  { id: "A10833", name: "Kinnulan seurakunta", VAT: "0176113-5" },
  { id: "A10368", name: "Kirkkonummen kunta", VAT: "0203107-0" },
  { id: "A10835", name: "Kirkkonummen Seurakuntayhtymä", VAT: "0203109-7" },
  { id: "60026493", name: "Kirkkonummen Vuokra-asunnot Oy", VAT: "0990655-4" },
  { id: "A3089", name: "Kirkon eläkerahasto", VAT: "2721508-7" },
  { id: "A10839", name: "Kirkon Keskusrahasto", VAT: "0118950-3" },
  { id: "A11239", name: "Kirkon Ulkomaanapu sr", VAT: "0998454-4" },
  { id: "60017802", name: "Kirnujen Asunnot Oy", VAT: "2396574-5" },
  { id: "A10369", name: "Kiteen kaupunki", VAT: "0168900-6" },
  { id: "A3173", name: "Kiteen Lämpö Oy", VAT: "0593562-2" },
  { id: "A11933", name: "Kiteen seurakunta", VAT: "0168910-2" },
  { id: "A10370", name: "Kittilän kunta", VAT: "0191406-6" },
  { id: "A10840", name: "Kittilän seurakunta", VAT: "0191410-3" },
  { id: "A10371", name: "Kiuruveden kaupunki", VAT: "0170843-0" },
  { id: "A10841", name: "Kiuruveden seurakunta", VAT: "0170850-2" },
  { id: "A10372", name: "Kivijärven kunta", VAT: "0176150-6" },
  { id: "50002018", name: "KL-Kuntahankinnat Oy", VAT: "2159215-6" },
  { id: "A11171", name: "Koillis-Lapin Sähkö Oy", VAT: "1680113-6" },
  {
    id: "A11441",
    name: "Koillis-Suomen kehittämisyhtiö Naturpolis Oy",
    VAT: "1940705-4",
  },
  { id: "A10373", name: "Kokemäen kaupunki", VAT: "0203925-9" },
  { id: "A11971", name: "Kokemäen seurakunta", VAT: "0134198-2" },
  { id: "60026505", name: "Kokkaamo Ateriapalvelut Oy", VAT: "3329087-6" },
  { id: "60024706", name: "Kokkolan kaupunki", VAT: "0179377-8" },
  { id: "A10712", name: "Kokkolan Satama Oy", VAT: "2642612-2" },
  { id: "A10842", name: "Kokkolan Seurakuntayhtymä", VAT: "0179225-7" },
  { id: "A11443", name: "Kokkolan Talohoito Oy", VAT: "0198172-8" },
  { id: "A11804", name: "Kokkolanseudun Kehitys Oy", VAT: "1798904-6" },
  { id: "A10375", name: "Kolarin kunta", VAT: "0191528-8" },
  { id: "A10846", name: "Kolarin seurakunta", VAT: "0191530-9" },
  { id: "60025215", name: "Kommunalstyrelsen i Föglö", VAT: "0282394-0" },
  { id: "150008", name: "Konkurssiasiamiehen toimisto", VAT: "2302414-4" },
  { id: "60024325", name: "Konneveden kaukolämpö Oy", VAT: "2665504-8" },
  { id: "A10376", name: "Konneveden kunta", VAT: "0176227-7" },
  { id: "A10847", name: "Konneveden seurakunta", VAT: "0176231-4" },
  { id: "60024697", name: "Konneveden Vuokra-asunnot Oy", VAT: "0609402-8" },
  { id: "A11947", name: "Kontiolahden Ev.Lut.Seurakunta", VAT: "0169051-7" },
  { id: "A10377", name: "Kontiolahden kunta", VAT: "0169048-8" },
  {
    id: "60024329",
    name: "Korkeasaaren eläintarhan säätiö sr",
    VAT: "2864471-3",
  },
  { id: "155012", name: "Korkein hallinto-oikeus", VAT: "3006157-6" },
  { id: "155011", name: "Korkein oikeus", VAT: "3006157-6" },
  { id: "A3176", name: "Korpelan Voima kuntayhtymä", VAT: "0178464-4" },
  { id: "A10433", name: "Korsholms kommun", VAT: "0181101-6" },
  { id: "A10848", name: "Korsholms Kyrkliga Samfällighet", VAT: "0209174-3" },
  { id: "A10850", name: "Korsnäs Församling", VAT: "0179697-9" },
  { id: "A10378", name: "Korsnäs kommun", VAT: "0179699-5" },
  { id: "A10379", name: "Kosken Tl kunta", VAT: "0213007-9" },
  { id: "A11444", name: "Kotikulman Kiinteistöpalvelut Oy", VAT: "0205776-4" },
  { id: "600004", name: "Kotimaisten kielten keskus", VAT: "0245872-8" },
  { id: "A10853", name: "Kotka-Kymin seurakunta", VAT: "2765919-8" },
  { id: "A10143", name: "Kotkan Asunnot Oy", VAT: "0159932-6" },
  { id: "A3088", name: "Kotkan Julkiset Kiinteistöt Oy", VAT: "2615544-9" },
  { id: "A11446", name: "Kotkan Kaupunginteatteri Oy", VAT: "0159898-0" },
  { id: "60024707", name: "Kotkan kaupunki", VAT: "0160225-7" },
  { id: "60017885", name: "Kotkan ortodoksinen seurakunta", VAT: "0160236-1" },
  {
    id: "A10244",
    name: "Kotkan-Haminan seudun koulutuskuntayhtymä",
    VAT: "1958694-5",
  },
  {
    id: "A10257",
    name: "Koulutuskeskus Salpaus -kuntayhtymä",
    VAT: "0993644-6",
  },
  { id: "A10258", name: "Koulutuskuntayhtymä Brahe", VAT: "0210287-9" },
  { id: "A10253", name: "Koulutuskuntayhtymä OSAO", VAT: "0992445-3" },
  { id: "A10245", name: "Koulutuskuntayhtymä Tavastia", VAT: "0205303-4" },
  { id: "60026018", name: "Kouvolan Ammattiopisto Oy", VAT: "3213129-1" },
  { id: "A10145", name: "Kouvolan Asunnot Oy", VAT: "0512766-8" },
  { id: "A11447", name: "Kouvolan Innovation Oy", VAT: "2221859-6" },
  { id: "A10381", name: "Kouvolan kaupunki", VAT: "0161075-9" },
  { id: "A10856", name: "Kouvolan seurakuntayhtymä", VAT: "2218327-4" },
  { id: "A12118", name: "Kouvolan Vesi Oy", VAT: "2125434-1" },
  { id: "60018149", name: "Kouvolan Yritystilat Oy", VAT: "2413977-9" },
  { id: "60025936", name: "KOy Riihimäen Lykka", VAT: "2756321-7" },
  { id: "200005", name: "Kriisinhallintakeskus, CMCFinland", VAT: "0245992-3" },
  {
    id: "A10858",
    name: "Kristiinankaupungin Suomalainen seurakunta",
    VAT: "0370334-2",
  },
  { id: "A10382", name: "Kristinestad stad", VAT: "0216509-5" },
  {
    id: "A10860",
    name: "Kristinestads Kyrkliga Samfällighet",
    VAT: "0398523-9",
  },
  { id: "A10861", name: "Kristinestads svenska församling", VAT: "2026993-9" },
  {
    id: "A10862",
    name: "Kronoby församling- Kruunupyyn seurakunta",
    VAT: "0209024-9",
  },
  { id: "A10383", name: "Kronoby kommun", VAT: "0180065-9" },
  { id: "A10627", name: "KSAK Oy", VAT: "0832600-5" },
  { id: "A10385", name: "Kuhmoisten kunta", VAT: "0176357-9" },
  { id: "A10384", name: "Kuhmon kaupunki", VAT: "0186204-0" },
  { id: "A10864", name: "Kuhmon seurakunta", VAT: "0186209-1" },
  { id: "60026418", name: "Kuhmon VesiEnergia Oy", VAT: "2662183-3" },
  { id: "60026422", name: "Kuhmon Yrityssampo Oy", VAT: "0421023-2" },
  { id: "500005", name: "Kuluttajariitalautakunta", VAT: "1567175-5" },
  { id: "60025216", name: "Kumlinge kommun", VAT: "0205030-0" },
  {
    id: "60026233",
    name: "Kunta- ja hyvinvointialuetyönantajat KT",
    VAT: "3222663-7",
  },
  { id: "A10663", name: "Kuntayhtymä Kaksineuvoinen", VAT: "2071277-7" },
  { id: "A11541", name: "Kuntec Oy", VAT: "2434427-0" },
  { id: "A11454", name: "Kuntien Hetapalvelut Oy", VAT: "2642777-4" },
  { id: "A3033", name: "Kuntien Takauskeskus", VAT: "1075583-7" },
  { id: "A11176", name: "Kuopion Energia Oy", VAT: "2064700-4" },
  {
    id: "A10867",
    name: "Kuopion evankelis-luterilainen seurakuntayhtymä",
    VAT: "0171444-3",
  },
  {
    id: "A13038",
    name: "Kuopion Hiippakunnan Tuomiokapituli",
    VAT: "0207847-7",
  },
  { id: "60024708", name: "Kuopion kaupunki", VAT: "0171450-7" },
  { id: "60017887", name: "Kuopion ortodoksinen seurakunta", VAT: "0171467-0" },
  { id: "A3530", name: "Kuopion Pysäköinti Oy", VAT: "2064442-8" },
  { id: "60025348", name: "Kuopion Sähköverkko Oy", VAT: "2654781-3" },
  { id: "A12014", name: "Kuopion Vesi Oy", VAT: "3095280-3" },
  {
    id: "60024347",
    name: "Kuopion yliopistollisen sairaalan tutkimussäätiö sr",
    VAT: "1044760-7",
  },
  { id: "A10387", name: "Kuortaneen kunta", VAT: "0180117-6" },
  { id: "A10872", name: "Kuortaneen seurakunta", VAT: "0180121-3" },
  { id: "A10388", name: "Kurikan kaupunki", VAT: "0209046-8" },
  { id: "A10873", name: "Kurikan seurakunta", VAT: "0180244-3" },
  { id: "A10389", name: "Kustavin kunta", VAT: "0134349-4" },
  { id: "A10874", name: "Kustavin seurakunta", VAT: "0134351-5" },
  {
    id: "A10680",
    name: "Kust-Österbottens samkommun för social- och primärhälsovård",
    VAT: "2223170-7",
  },
  { id: "A10390", name: "Kuusamon kaupunki", VAT: "0186418-5" },
  { id: "A10875", name: "Kuusamon seurakunta", VAT: "0186424-9" },
  {
    id: "A10664",
    name: "Kuusiokuntien sosiaali- ja terveyskuntayhtymä",
    VAT: "1983230-9",
  },
  { id: "A3292", name: "Kvarnen samkommun", VAT: "0209021-4" },
  { id: "A11290", name: "Kymen Vesi Oy", VAT: "2081160-1" },
  { id: "60025825", name: "Kymenlaakson hyvinvointialue", VAT: "3221311-5" },
  {
    id: "60025365",
    name: "Kymenlaakson hyvinvointialueen tuki- ja kiinteistöpalvelut Oy",
    VAT: "3144631-2",
  },
  { id: "A3206", name: "Kymenlaakson Jäte Oy", VAT: "1093000-9" },
  { id: "155059", name: "Kymenlaakson käräjäoikeus", VAT: "3006157-6" },
  { id: "A13032", name: "Kymenlaakson liitto", VAT: "0206714-5" },
  { id: "A11291", name: "Kymenlaakson Vesi Oy", VAT: "0633232-1" },
  { id: "A11456", name: "Kymijoen Ravintopalvelut Oy", VAT: "1635108-3" },
  { id: "A10391", name: "Kyyjärven kunta", VAT: "0176410-9" },
  { id: "A10880", name: "Kyyjärven seurakunta", VAT: "0176413-3" },
  { id: "A10665", name: "Kårkulla samkommun", VAT: "0204197-3" },
  { id: "A10392", name: "Kärkölän kunta", VAT: "0148268-9" },
  { id: "A10393", name: "Kärsämäen kunta", VAT: "0186511-0" },
  { id: "A10884", name: "Kärsämäen seurakunta", VAT: "0186515-3" },
  { id: "60025217", name: "Kökar kommun", VAT: "0205032-7" },
  { id: "874001", name: "Lab-ammattikorkeakoulu Oy", VAT: "2630644-6" },
  { id: "A10395", name: "Lahden kaupunki", VAT: "0149669-3" },
  { id: "60017888", name: "Lahden ortodoksinen seurakunta", VAT: "0149701-2" },
  { id: "A10886", name: "Lahden Seurakuntayhtymä", VAT: "0205520-8" },
  { id: "A11698", name: "Lahden Talot Oy", VAT: "1108275-0" },
  { id: "A11699", name: "Lahti Aqua Oy", VAT: "0950563-8" },
  { id: "A10396", name: "Laihian kunta", VAT: "0180451-0" },
  { id: "A3178", name: "Laihian Nuuka Lämpö Oy", VAT: "1485516-5" },
  { id: "A11755", name: "Laihian seurakunta", VAT: "0180460-9" },
  { id: "A10397", name: "Laitilan kaupunki", VAT: "0134480-9" },
  { id: "A10888", name: "Laitilan seurakunta", VAT: "0134543-0" },
  { id: "A13033", name: "Laitilan Terveyskoti Oy", VAT: "0561213-7" },
  { id: "A11460", name: "Lakeuden Etappi Oy", VAT: "1087873-0" },
  {
    id: "60026198",
    name: "Lamminniemen Hyvinvointikeskus Oy",
    VAT: "2560530-5",
  },
  { id: "360006", name: "Lapin aluehallintovirasto", VAT: "0245428-4" },
  { id: "60024299", name: "Lapin Alueteatteriyhdistys ry", VAT: "1508491-3" },
  { id: "875001", name: "Lapin ammattikorkeakoulu Oy", VAT: "2528792-5" },
  {
    id: "380011",
    name: "Lapin elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025835", name: "Lapin hyvinvointialue", VAT: "3221332-6" },
  { id: "A10648", name: "Lapin Jätehuolto kuntayhtymä", VAT: "2008197-9" },
  { id: "155060", name: "Lapin käräjäoikeus", VAT: "3006157-6" },
  { id: "60024318", name: "Lapin liitto", VAT: "0937073-7" },
  { id: "240121", name: "Lapin poliisilaitos", VAT: "2288666-6" },
  { id: "3800035", name: "Lapin työ- ja elinkeinotoimisto", VAT: "2296962-1" },
  { id: "833001", name: "Lapin Yliopisto", VAT: "0292800-5" },
  { id: "A10398", name: "Lapinjärven kunta", VAT: "0203135-3" },
  { id: "A10158", name: "Lapinlahden Kaskihovi Oy", VAT: "0885683-1" },
  { id: "A10399", name: "Lapinlahden kunta", VAT: "0172127-2" },
  { id: "A3368", name: "Lapinlahden Vesi Oy", VAT: "2417176-0" },
  { id: "A10028", name: "LapIT Oy", VAT: "1637268-5" },
  { id: "A10400", name: "Lappajärven kunta", VAT: "0180516-9" },
  { id: "A11756", name: "Lappajärven seurakunta", VAT: "0180518-5" },
  { id: "A12136", name: "Lappeenrannan Asuntopalvelu Oy", VAT: "0433221-3" },
  { id: "A11179", name: "Lappeenrannan Energia Oy", VAT: "1796558-9" },
  { id: "A11180", name: "Lappeenrannan Energiaverkot Oy", VAT: "1995405-8" },
  { id: "A12138", name: "Lappeenrannan kaupunki", VAT: "0162193-3" },
  { id: "A3053", name: "Lappeenrannan Lentoasema Oy", VAT: "2733410-7" },
  { id: "A11181", name: "Lappeenrannan Lämpövoima Oy", VAT: "0161983-3" },
  { id: "A10892", name: "Lappeenrannan Seurakuntayhtymä", VAT: "0245512-1" },
  { id: "60024717", name: "Lappeenrannan Toimitilat Oy", VAT: "0215703-6" },
  {
    id: "60017808",
    name: "Lappeenrannan-Lahden teknillinen yliopisto LUT",
    VAT: "0245904-2",
  },
  { id: "A3114", name: "Lappica Oy", VAT: "2791111-1" },
  { id: "150015", name: "Lapsiasiavaltuutetun toimisto", VAT: "0245974-7" },
  {
    id: "A10898",
    name: "Lapuan Hiippakunnan Tuomiokapituli",
    VAT: "0245876-0",
  },
  { id: "A10402", name: "Lapuan kaupunki", VAT: "0209113-7" },
  { id: "A10901", name: "Lapuan Tuomiokapitulin rahasto T", VAT: "0209121-7" },
  { id: "A10899", name: "Lapuan tuomiokirkkoseurakunta", VAT: "0180698-9" },
  { id: "A11758", name: "Larsmo Församling", VAT: "0180856-2" },
  { id: "A10419", name: "Larsmo kommun", VAT: "0180857-0" },
  { id: "A3301", name: "Laukaan Kehitysyhtiö Oy", VAT: "0509126-3" },
  { id: "A10403", name: "Laukaan kunta", VAT: "0176478-2" },
  { id: "A10902", name: "Laukaan seurakunta", VAT: "0176513-6" },
  { id: "A3078", name: "Laukaan Vesihuolto Oy", VAT: "2722020-6" },
  { id: "876001", name: "Laurea-ammattikorkeakoulu Oy", VAT: "1046216-1" },
  { id: "947001", name: "Leijona Catering Oy", VAT: "2449777-9" },
  { id: "A10405", name: "Lemin kunta", VAT: "0162576-6" },
  { id: "A11759", name: "Lemland-Lumparlands Församling", VAT: "0205035-1" },
  { id: "60025212", name: "Lemlands kommun", VAT: "0205034-3" },
  { id: "A3379", name: "Lempäälän Energia Oy", VAT: "0150764-7" },
  { id: "A3278", name: "Lempäälän Kehitys Oy", VAT: "1558681-7" },
  {
    id: "A10160",
    name: "Lempäälän Kiinteistöhuolto ja -tekniikka LEKITEK Oy",
    VAT: "0282724-7",
  },
  { id: "A10406", name: "Lempäälän kunta", VAT: "0150783-1" },
  { id: "A3057", name: "Lempäälän Lämpö Oy", VAT: "0150718-8" },
  { id: "A13012", name: "Lempäälän seurakunta", VAT: "0150791-1" },
  { id: "60017857", name: "Lempäälän Vesi Oy", VAT: "3096224-3" },
  { id: "A3056", name: "Lempäälän Vuokrakodit Oy", VAT: "0512440-8" },
  { id: "A10407", name: "Leppävirran kunta", VAT: "0172231-2" },
  { id: "A10909", name: "Leppävirran seurakunta", VAT: "0172233-9" },
  { id: "A10408", name: "Lestijärven kunta", VAT: "0180774-6" },
  { id: "A10410", name: "Liedon kaupunki", VAT: "0134698-6" },
  { id: "A10911", name: "Liedon seurakunta", VAT: "0134702-2" },
  { id: "A10409", name: "Lieksan kaupunki", VAT: "0169321-6" },
  { id: "60018146", name: "Lieksan Kehitys Oy LieKe", VAT: "2856925-8" },
  { id: "A10910", name: "Lieksan seurakunta", VAT: "0169427-8" },
  { id: "450001", name: "Liikenne- ja Viestintäministeriö", VAT: "0244632-1" },
  { id: "480001", name: "Liikenne- ja viestintävirasto", VAT: "2924753-3" },
  { id: "948001", name: "Liikenneturva", VAT: "0201619-6" },
  {
    id: "812001",
    name: "Liikenteenohjausyhtiö Fintraffic Oy",
    VAT: "2942108-7",
  },
  { id: "A10411", name: "Limingan kunta", VAT: "0186553-2" },
  { id: "A12063", name: "Limingan seurakunta", VAT: "0186557-5" },
  { id: "A3423", name: "Linnan Kehitys Oy", VAT: "0832624-0" },
  { id: "A10163", name: "Linnankosken Asunnot Oy", VAT: "2396575-3" },
  { id: "A10412", name: "Liperin kunta", VAT: "0169583-6" },
  { id: "A11936", name: "Liperin seurakunta", VAT: "0207637-5" },
  { id: "A10413", name: "Lohjan kaupunki", VAT: "1068322-0" },
  { id: "60017443", name: "Lohjan Liikuntakeskus Oy", VAT: "0128283-9" },
  { id: "A3432", name: "Lohjan seurakunta", VAT: "2225043-0" },
  { id: "A3052", name: "Lohjan Vuokra-asunnot Oy", VAT: "0864408-9" },
  { id: "A3182", name: "Loimaan Kaukolämpö Oy", VAT: "0313531-4" },
  { id: "A10414", name: "Loimaan kaupunki", VAT: "1927453-8" },
  { id: "A10914", name: "LOIMAAN SEURAKUNTA", VAT: "1926328-1" },
  { id: "A3104", name: "Loimi-Hämeen Jätehuolto Oy", VAT: "1027700-3" },
  { id: "A11466", name: "Loimijoen Kuntapalvelut Oy", VAT: "2300018-5" },
  { id: "A10415", name: "Lopen kunta", VAT: "0150919-1" },
  { id: "60017449", name: "Lopen seurakunta", VAT: "0150920-4" },
  {
    id: "A10246",
    name: "Lounais-Hämeen koulutuskuntayhtymä",
    VAT: "0626288-8",
  },
  {
    id: "360002",
    name: "Lounais-Suomen aluehallintovirasto",
    VAT: "1095237-1",
  },
  { id: "A10046", name: "Lounais-Suomen Jätehuolto Oy", VAT: "1868393-8" },
  {
    id: "A10247",
    name: "Lounais-Suomen koulutuskuntayhtymä",
    VAT: "0204023-3",
  },
  {
    id: "150495",
    name: "Lounais-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  { id: "224101", name: "Lounais-Suomen poliisilaitos", VAT: "2288666-6" },
  { id: "A10416", name: "Loviisan kaupunki", VAT: "0203263-9" },
  { id: "A10713", name: "Loviisan Satama Oy", VAT: "2779170-3" },
  { id: "A10915", name: "Loviisanseudun seurakuntayhtymä", VAT: "2296297-2" },
  { id: "A10417", name: "Luhangan kunta", VAT: "0176592-9" },
  {
    id: "A10248",
    name: "Luksia, Länsi-Uudenmaan koulutuskuntayhtymä",
    VAT: "0203167-9",
  },
  { id: "A10418", name: "Lumijoen kunta", VAT: "0186580-7" },
  { id: "A12064", name: "Lumijoen seurakunta", VAT: "0186582-3" },
  { id: "A11294", name: "Lumijoen Vesi Oy", VAT: "0186579-4" },
  { id: "60025213", name: "Lumparlands kommun", VAT: "0205038-6" },
  { id: "410001", name: "Luonnonvarakeskus", VAT: "0244629-2" },
  { id: "A3025", name: "Luumäen Asunnot Oy", VAT: "0612155-9" },
  { id: "A3024", name: "Luumäen Energia Oy", VAT: "2663432-7" },
  { id: "A10420", name: "Luumäen kunta", VAT: "0162631-2" },
  { id: "A11981", name: "Luumäen seurakunta", VAT: "0212093-8" },
  {
    id: "360004",
    name: "Länsi- ja Sisä-Suomen aluehallintovirasto",
    VAT: "2284140-9",
  },
  {
    id: "150498",
    name: "Länsi- ja Sisä-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  { id: "A11469", name: "Länsimetro Oy", VAT: "2124310-8" },
  { id: "A10629", name: "Länsirannikon Koulutus Oy", VAT: "2245018-4" },
  { id: "60025837", name: "Länsi-Uudenmaan hyvinvointialue", VAT: "3221347-3" },
  { id: "155061", name: "Länsi-Uudenmaan käräjäoikeus", VAT: "3006157-6" },
  { id: "240106", name: "Länsi-Uudenmaan poliisilaitos", VAT: "2288666-6" },
  {
    id: "558005",
    name: "Lääkealan turvallisuus- ja kehittämiskeskus",
    VAT: "0921536-6",
  },
  { id: "400001", name: "Maa- ja Metsätalousministeriö", VAT: "0245958-7" },
  { id: "200002", name: "Maahanmuuttovirasto", VAT: "1019953-5" },
  { id: "964001", name: "Maakuntien tilakeskus Oy", VAT: "2809806-2" },
  { id: "402021", name: "Maanmittauslaitos", VAT: "0245954-4" },
  { id: "931001", name: "Maatalouden Interventiorahasto", VAT: "0994911-6" },
  { id: "969001", name: "Maatalousyrittäjien Eläkelaitos", VAT: "0101319-6" },
  { id: "910001", name: "Maatilatalouden Kehittämisrahasto", VAT: "1106498-2" },
  { id: "60017407", name: "Makumaakarit Oy", VAT: "1936461-3" },
  { id: "A10924", name: "Malax Ev.Luth. Församling", VAT: "0180946-9" },
  { id: "A10423", name: "Malax kommun", VAT: "0180948-5" },
  { id: "A12043", name: "Malax Kyrkliga Samfällighet", VAT: "0498681-5" },
  { id: "A10926", name: "Mariehamns Församling", VAT: "0205067-7" },
  { id: "A3383", name: "Mariehamns stad", VAT: "0205071-4" },
  { id: "155043", name: "Markkinaoikeus", VAT: "3006157-6" },
  { id: "60017464", name: "Martinkosken seurakunta", VAT: "2938732-5" },
  { id: "A10424", name: "Marttilan kunta", VAT: "0135086-2" },
  { id: "A10425", name: "Maskun kunta", VAT: "0204064-7" },
  { id: "A10928", name: "Maskun seurakunta", VAT: "0135124-0" },
  { id: "A3071", name: "Maskun Vesihuolto Oy", VAT: "2727230-5" },
  { id: "A3801", name: "Meidän IT ja talous Oy", VAT: "2945110-4" },
  { id: "A10630", name: "Mercuria kauppaoppilaitos Oy", VAT: "0503417-0" },
  {
    id: "660011",
    name: "Merenkulun turvallisuuskoulutuskeskus",
    VAT: "1110826-0",
  },
  { id: "A10426", name: "Merijärven kunta", VAT: "0186588-2" },
  { id: "A10427", name: "Merikarvian kunta", VAT: "0135202-4" },
  { id: "A10932", name: "Merikarvian seurakunta", VAT: "0135203-2" },
  {
    id: "A10650",
    name: "Meri-Lapin kuntapalvelut liikelaitoskuntayhtymä",
    VAT: "2264173-0",
  },
  { id: "60026405", name: "Meri-Lapin kuntapalvelut Oy", VAT: "3313879-4" },
  { id: "975001", name: "Merimiespalvelutoimisto", VAT: "0242520-5" },
  { id: "60025383", name: "Mestar Kuopio Oy", VAT: "3167564-1" },
  { id: "A12228", name: "Mestaritoiminta Oy", VAT: "2119604-9" },
  { id: "877001", name: "Metropolia Ammattikorkeakoulu Oy", VAT: "2094551-1" },
  { id: "441001", name: "Metsähallitus", VAT: "0116726-7" },
  { id: "441020", name: "Metsähallitus Metsätalous Oy", VAT: "2752751-5" },
  { id: "A3447", name: "Mico Botnia Oy Ab", VAT: "2876122-3" },
  { id: "A10428", name: "Miehikkälän kunta", VAT: "0162675-0" },
  {
    id: "A10935",
    name: "Mikkelin Hiippakunnan Tuomiokapituli",
    VAT: "0218712-0",
  },
  { id: "60024709", name: "Mikkelin kaupunki", VAT: "0165116-3" },
  { id: "A12254", name: "Mikkelin kehitysyhtiö Miksei Oy", VAT: "0600598-8" },
  { id: "A10939", name: "Mikkelin tuomiokirkkoseurakunta", VAT: "1634754-3" },
  { id: "A11475", name: "Millespakka Oy", VAT: "1067730-6" },
  { id: "A3806", name: "Monetra Keski-Suomi Oy", VAT: "2930116-5" },
  { id: "A11476", name: "Monetra Oulu Oy", VAT: "2454638-2" },
  { id: "A3805", name: "Monetra Oy", VAT: "2930159-5" },
  { id: "A3808", name: "Monetra Pirkanmaa Oy", VAT: "2930111-4" },
  { id: "A3804", name: "Monetra Pohjois-Savo Oy", VAT: "2054342-4" },
  { id: "972001", name: "Motiva Oy", VAT: "1629255-4" },
  {
    id: "A10941",
    name: "Muhoksen Evankelis-Luterilainen seurakunta",
    VAT: "0186650-0",
  },
  { id: "A10430", name: "Muhoksen kunta", VAT: "0186646-3" },
  { id: "A10431", name: "Multian kunta", VAT: "0208471-1" },
  { id: "A11760", name: "Multian seurakunta", VAT: "0176624-3" },
  { id: "A10432", name: "Muonion kunta", VAT: "0191824-3" },
  { id: "A10944", name: "Muonion seurakunta", VAT: "0191829-4" },
  { id: "606001", name: "Museovirasto", VAT: "0292559-2" },
  { id: "A3118", name: "Mustankorkea Oy", VAT: "1108572-4" },
  { id: "A10434", name: "Muuramen kunta", VAT: "0176699-9" },
  { id: "A10947", name: "Muuramen seurakunta", VAT: "0176700-0" },
  { id: "A10435", name: "Mynämäen kunta", VAT: "2048364-4" },
  { id: "A10948", name: "Mynämäen seurakunta", VAT: "0135356-1" },
  { id: "60024322", name: "Mynämäen Vesihuolto Oy", VAT: "2792025-3" },
  { id: "A10436", name: "Myrskylän kunta", VAT: "0203282-3" },
  { id: "A11983", name: "Myrskylän seurakunta", VAT: "0203286-6" },
  { id: "A10437", name: "Mäntsälän kunta", VAT: "0129261-5" },
  { id: "A10950", name: "Mäntsälän seurakunta", VAT: "0129266-6" },
  { id: "A10438", name: "Mänttä-Vilppulan kaupunki", VAT: "0157867-2" },
  { id: "A12054", name: "Mänttä-Vilppulan seurakunta", VAT: "0205675-3" },
  { id: "A10439", name: "Mäntyharjun kunta", VAT: "0165761-0" },
  {
    id: "A11478",
    name: "Mäntyharjun Seudun Elinkeinojen Kehitys Oy",
    VAT: "1487065-6",
  },
  { id: "A10951", name: "Mäntyharjun seurakunta", VAT: "0165768-8" },
  { id: "963017", name: "Mäntän Virastotalo Oy", VAT: "0294137-3" },
  { id: "A10440", name: "Naantalin kaupunki", VAT: "0135457-2" },
  { id: "A10954", name: "Naantalin seurakuntayhtymä", VAT: "2231520-6" },
  { id: "A10441", name: "Nakkilan kunta", VAT: "0135662-3" },
  { id: "A10955", name: "Nakkilan seurakunta", VAT: "0135663-1" },
  { id: "A3188", name: "Napapiirin Energia ja Vesi Oy", VAT: "0730905-1" },
  { id: "A11480", name: "Napapiirin Residuum Oy", VAT: "1744004-5" },
  { id: "A3326", name: "Navitas Kehitys Oy", VAT: "0775736-4" },
  { id: "60024334", name: "Niiniveden seurakunta", VAT: "3086549-4" },
  { id: "551012", name: "Niuvanniemen sairaala", VAT: "0213383-5" },
  { id: "A10444", name: "Nivalan kaupunki", VAT: "0186757-0" },
  { id: "A11483", name: "Nivalan Liikuntakeskus Oy", VAT: "0921597-2" },
  { id: "A10958", name: "Nivalan seurakunta", VAT: "0186764-2" },
  { id: "A11188", name: "Nivos Energia Oy", VAT: "0773810-4" },
  { id: "A3288", name: "Nivos Oy", VAT: "2519715-3" },
  { id: "A3289", name: "Nivos Vesi ja Lämpö Oy", VAT: "2519694-3" },
  {
    id: "A10959",
    name: "Nokian Evankelis-Luterilainen seurakunta",
    VAT: "0205724-6",
  },
  { id: "A10445", name: "Nokian kaupunki", VAT: "0205717-4" },
  { id: "A11296", name: "Nokian Vesi Oy", VAT: "2589152-4" },
  { id: "60017860", name: "Nokian Vuokrakodit Oy", VAT: "0205709-4" },
  { id: "A10676", name: "NordLab hyvinvointiyhtymä", VAT: "2483868-4" },
  { id: "60026317", name: "Norra Ålands församling", VAT: "3227486-6" },
  { id: "A10446", name: "Nousiaisten kunta", VAT: "0135821-5" },
  { id: "A10961", name: "Nousiaisten seurakunta", VAT: "0135826-6" },
  { id: "A11484", name: "Novago Yrityskehitys Oy", VAT: "2397496-7" },
  {
    id: "A11486",
    name: "Nuoriso- ja luontomatkailukeskus Oivanki Oy",
    VAT: "2660727-3",
  },
  {
    id: "A10962",
    name: "Nurmeksen evankelisluterilainen seurakunta",
    VAT: "0207660-7",
  },
  { id: "A10448", name: "Nurmeksen kaupunki", VAT: "0207669-0" },
  { id: "A10449", name: "Nurmijärven kunta", VAT: "9014643-2" },
  { id: "A10963", name: "Nurmijärven seurakunta", VAT: "9014645-9" },
  { id: "A12003", name: "Nykarleby församling", VAT: "2509456-8" },
  { id: "A10560", name: "Nykarleby stad", VAT: "0183077-8" },
  { id: "A10965", name: "Närpes församling", VAT: "2564418-7" },
  { id: "A10450", name: "Närpes stad", VAT: "0181367-9" },
  { id: "125003", name: "Oikeuskanslerinvirasto", VAT: "0245975-5" },
  { id: "150001", name: "Oikeusministeriö", VAT: "0245974-7" },
  { id: "150016", name: "Oikeusrekisterikeskus", VAT: "2722042-5" },
  { id: "150007", name: "Onnettomuustutkintakeskus", VAT: "0245974-7" },
  { id: "600001", name: "Opetus- ja kulttuuriministeriö", VAT: "0245872-8" },
  { id: "660001", name: "Opetushallitus", VAT: "2769790-1" },
  {
    id: "600015",
    name: "Opintotuen muutoksenhakulautakunta",
    VAT: "0245872-8",
  },
  { id: "892001", name: "Oppiva Invest Oy", VAT: "2990750-3" },
  { id: "A10252", name: "Optima samkommun", VAT: "0796234-1" },
  { id: "A10451", name: "Orimattilan kaupunki", VAT: "0129920-0" },
  { id: "A10969", name: "Orimattilan seurakunta", VAT: "0129925-1" },
  { id: "A10452", name: "Oripään kunta", VAT: "0135869-6" },
  { id: "A10453", name: "Oriveden kaupunki", VAT: "0151789-6" },
  { id: "A10971", name: "Oriveden seurakunta", VAT: "0151796-8" },
  { id: "A10454", name: "Oulaisten kaupunki", VAT: "0186852-2" },
  { id: "A10972", name: "Oulaisten seurakunta", VAT: "0186856-5" },
  { id: "A3237", name: "Oulu, Business Oulu -liikelaitos", VAT: "0187690-1" },
  { id: "50001695", name: "Oulu, Konsernihallinto", VAT: "0187690-1" },
  { id: "A11946", name: "Oulu, Oulun Digi -liikelaitos", VAT: "0187690-1" },
  { id: "A3818", name: "Oulu, Oulun Infra -liikelaitos", VAT: "0187690-1" },
  {
    id: "60017979",
    name: "Oulu, Oulun seudun ympäristötoimi -liikelaitos",
    VAT: "0187690-1",
  },
  {
    id: "A3817",
    name: "Oulu, Oulun Tilapalvelut -liikelaitos",
    VAT: "0187690-1",
  },
  { id: "A13004", name: "Oulu, Oulun Vesi - liikelaitos", VAT: "0187690-1" },
  {
    id: "60017978",
    name: "Oulu, Sivistys- ja kulttuuripalvelut",
    VAT: "0187690-1",
  },
  {
    id: "60017977",
    name: "Oulu, Yhdyskunta- ja ympäristöpalvelut",
    VAT: "0187690-1",
  },
  { id: "A10631", name: "Oulun Aikuiskoulutuskeskus Oy", VAT: "0868699-1" },
  { id: "880001", name: "Oulun Ammattikorkeakoulu Oy", VAT: "2509747-8" },
  { id: "A11228", name: "Oulun Energia Oy", VAT: "0989376-5" },
  { id: "A10974", name: "Oulun Ev.-Lut. Seurakuntayhtymä", VAT: "0210109-1" },
  { id: "A10976", name: "Oulun Hiippakunnan Tuomiokapituli", VAT: "0634440-2" },
  { id: "155062", name: "Oulun käräjäoikeus", VAT: "3006157-6" },
  { id: "211101", name: "Oulun poliisilaitos", VAT: "2288666-6" },
  { id: "A3417", name: "Oulun Tuotantokeittiö Oy", VAT: "2811134-3" },
  { id: "834001", name: "Oulun Yliopisto", VAT: "0245895-5" },
  { id: "A10667", name: "Oulunkaaren kuntayhtymä", VAT: "1006538-5" },
  { id: "A11812", name: "Outokummun kaupunki", VAT: "0207604-1" },
  { id: "A11937", name: "Outokummun seurakunta", VAT: "0169214-1" },
  { id: "A11492", name: "Oy Apotti Ab", VAT: "2699989-5" },
  { id: "60025937", name: "Oy Expomade-Kiinteistöt Ab", VAT: "2481096-4" },
  { id: "60026311", name: "Oy Granilla Ab", VAT: "0359005-0" },
  { id: "A11217", name: "Oy Turku Energia-Åbo Energi Ab", VAT: "0984944-9" },
  { id: "A10457", name: "Padasjoen kunta", VAT: "0151924-2" },
  { id: "A10458", name: "Paimion kaupunki", VAT: "0136169-2" },
  { id: "A10984", name: "Paimion seurakunta", VAT: "0136176-4" },
  { id: "A11880", name: "Paimion Vesihuolto Oy", VAT: "2582349-2" },
  { id: "A11493", name: "Paimion Yrityskehitys Oy", VAT: "0301425-6" },
  {
    id: "A10668",
    name: "Paimion-Sauvon Kansanterveys kuntayhtymä",
    VAT: "0204213-2",
  },
  { id: "933001", name: "Palosuojelurahasto", VAT: "" },
  { id: "A10459", name: "Paltamon kunta", VAT: "0188808-0" },
  { id: "A10986", name: "Paltamon seurakunta", VAT: "0188812-8" },
  { id: "60024287", name: "Palveluyhtiö Viisarit Oy", VAT: "3086461-9" },
  {
    id: "A10987",
    name: "Pargas Kyrkliga Samfällighet Paraisten Seurakuntayhtymä",
    VAT: "0136080-9",
  },
  { id: "A10460", name: "Pargas stad", VAT: "0136082-5" },
  { id: "A3040", name: "Parik - säätiö sr", VAT: "1707452-4" },
  { id: "A10461", name: "PARIKKALAN KUNTA", VAT: "1913642-6" },
  { id: "A11985", name: "Parikkalan seurakunta", VAT: "1923860-9" },
  { id: "A10462", name: "Parkanon kaupunki", VAT: "0136311-0" },
  { id: "A12051", name: "Parkanon seurakunta", VAT: "0136322-5" },
  { id: "60017844", name: "Parkanon Vesi Oy", VAT: "2948190-1" },
  { id: "507001", name: "Patentti- ja Rekisterihallitus", VAT: "0244683-1" },
  { id: "A10463", name: "Pedersöre kommun", VAT: "0198517-1" },
  { id: "60017935", name: "Pedersöre Vatten Ab", VAT: "0181963-2" },
  {
    id: "A10989",
    name: "Pedersörenejdens Kyrkliga Samfällighet",
    VAT: "0209259-4",
  },
  { id: "A3428", name: "Peimarin koulutuskuntayhtymä", VAT: "0823246-3" },
  { id: "220001", name: "Pelastusopisto", VAT: "0909306-3" },
  { id: "A10464", name: "Pelkosenniemen kunta", VAT: "0191866-5" },
  { id: "A10990", name: "Pelkosenniemen seurakunta", VAT: "0191867-3" },
  { id: "60017712", name: "Pello, Elinvoiman toimiala", VAT: "0193729-2" },
  { id: "60017713", name: "Pello, Hyvinvoinnin toimiala", VAT: "0193729-2" },
  { id: "60017480", name: "Pello, Keskusvirasto", VAT: "0193729-2" },
  { id: "60017715", name: "Pello, Sivistystoimi", VAT: "0193729-2" },
  { id: "60017714", name: "Pello, Tekninen toimi", VAT: "0193729-2" },
  { id: "60024314", name: "Pellon Serviisi Oy", VAT: "2658604-6" },
  { id: "A10991", name: "Pellon seurakunta", VAT: "0193731-3" },
  { id: "60026452", name: "Perhon Kehitysyhtiö Oy", VAT: "0484435-8" },
  { id: "A10466", name: "Perhon kunta", VAT: "0181464-7" },
  { id: "60026496", name: "Perhon Opiskelija-Asunnot Oy", VAT: "1961160-2" },
  { id: "A10992", name: "Perhon seurakunta", VAT: "0181469-8" },
  { id: "A10467", name: "Pertunmaan kunta", VAT: "0165867-2" },
  { id: "A10669", name: "Peruspalvelukuntayhtymä Kallio", VAT: "2125690-2" },
  { id: "A10670", name: "Peruspalvelukuntayhtymä Selänne", VAT: "2265415-2" },
  { id: "A10671", name: "Perusturvakuntayhtymä Akseli", VAT: "2338872-2" },
  { id: "A10672", name: "Perusturvakuntayhtymä Karviainen", VAT: "2187280-1" },
  { id: "60017458", name: "Perämeren Jäähalli Oy", VAT: "0549018-4" },
  { id: "A10994", name: "Petalax Församling", VAT: "0198301-0" },
  { id: "A10468", name: "Petäjäveden kunta", VAT: "0176769-2" },
  { id: "A11763", name: "Petäjäveden seurakunta", VAT: "0176772-1" },
  { id: "A11901", name: "Pieksämäen Haka Oy", VAT: "0207267-3" },
  { id: "A10469", name: "Pieksämäen kaupunki", VAT: "2048903-4" },
  { id: "A10996", name: "Pieksämäen seurakunta", VAT: "2054133-0" },
  { id: "60026436", name: "Pieksämäen Tukipalvelut Oy", VAT: "3299842-6" },
  { id: "A11902", name: "Pieksämäen Vesi Oy", VAT: "2725576-7" },
  { id: "A10470", name: "Pielaveden kunta", VAT: "0172446-5" },
  { id: "A10472", name: "Pihtiputaan kunta", VAT: "0243027-4" },
  { id: "A11001", name: "Pihtiputaan seurakunta", VAT: "0176844-1" },
  { id: "60017856", name: "Pirkan Opiskelija-asunnot Oy", VAT: "0211539-7" },
  {
    id: "380005",
    name: "Pirkanmaan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025823", name: "Pirkanmaan hyvinvointialue", VAT: "3221308-6" },
  { id: "A3035", name: "Pirkanmaan Jätehuolto Oy", VAT: "0968008-1" },
  { id: "155063", name: "Pirkanmaan käräjäoikeus", VAT: "3006157-6" },
  { id: "A10594", name: "Pirkanmaan liitto", VAT: "0828308-4" },
  {
    id: "A10701",
    name: "Pirkanmaan Sairaanhoitopiirin kuntayhtymä",
    VAT: "0826597-8",
  },
  {
    id: "3800025",
    name: "Pirkanmaan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A3361", name: "Pirkanmaan Voimia Oy", VAT: "2938704-2" },
  { id: "60017933", name: "Pirkkalan Jäähalli Oy", VAT: "0713120-6" },
  { id: "A10473", name: "Pirkkalan kunta", VAT: "0152084-1" },
  { id: "A13009", name: "Pirkkalan seurakunta", VAT: "0152086-8" },
  {
    id: "380007",
    name: "Pohjanmaan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025832", name: "Pohjanmaan hyvinvointialue", VAT: "3221324-6" },
  { id: "155064", name: "Pohjanmaan käräjäoikeus", VAT: "3006157-6" },
  { id: "240112", name: "Pohjanmaan poliisilaitos", VAT: "2288666-6" },
  {
    id: "3800032",
    name: "Pohjanmaan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  {
    id: "A11497",
    name: "Pohjoisen Keski-Suomen Verkkopalvelut Oy",
    VAT: "1608786-8",
  },
  {
    id: "380013",
    name: "Pohjois-Karjalan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "60025829",
    name: "Pohjois-Karjalan hyvinvointialue",
    VAT: "3221317-4",
  },
  {
    id: "A10256",
    name: "Pohjois-Karjalan Koulutuskuntayhtymä",
    VAT: "0212371-7",
  },
  { id: "155065", name: "Pohjois-Karjalan käräjäoikeus", VAT: "3006157-6" },
  { id: "A10596", name: "Pohjois-Karjalan maakuntaliitto", VAT: "0927140-5" },
  {
    id: "3800029",
    name: "Pohjois-Karjalan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "60026519", name: "Pohjois-Lapin Ruoka Oy", VAT: "3316996-1" },
  { id: "60017529", name: "Pohjois-Lapin seurakuntayhtymä", VAT: "2862008-4" },
  {
    id: "380009",
    name: "Pohjois-Pohjanmaan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "60025813",
    name: "Pohjois-Pohjanmaan hyvinvointialue",
    VAT: "3221326-2",
  },
  { id: "A10597", name: "Pohjois-Pohjanmaan liitto", VAT: "0922484-4" },
  {
    id: "60017708",
    name: "Pohjois-Pohjanmaan shp ky, Kehitysvammahuolto",
    VAT: "0679480-9",
  },
  {
    id: "60017710",
    name: "Pohjois-Pohjanmaan shp ky, lapset ja naiset",
    VAT: "0679480-9",
  },
  {
    id: "60017711",
    name: "Pohjois-Pohjanmaan shp ky, Medisiininen",
    VAT: "0679480-9",
  },
  {
    id: "60017707",
    name: "Pohjois-Pohjanmaan shp ky, Oulaskankaan sairaala",
    VAT: "0679480-9",
  },
  {
    id: "60017706",
    name: "Pohjois-Pohjanmaan shp ky, Oulun yliopistollinen sairaala",
    VAT: "0679480-9",
  },
  {
    id: "60017709",
    name: "Pohjois-Pohjanmaan shp ky, Psykiatria",
    VAT: "0679480-9",
  },
  {
    id: "60017705",
    name: "Pohjois-Pohjanmaan shp ky, Sairaanhoidolliset palvelut",
    VAT: "0679480-9",
  },
  {
    id: "60017479",
    name: "Pohjois-Pohjanmaan shp ky, Yhtymähallinto",
    VAT: "0679480-9",
  },
  {
    id: "3800033",
    name: "Pohjois-Pohjanmaan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  {
    id: "A10674",
    name: "Pohjois-Satakunnan peruspalvelukuntayhtymä",
    VAT: "2205488-6",
  },
  {
    id: "380012",
    name: "Pohjois-Savon elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025828", name: "Pohjois-Savon hyvinvointialue", VAT: "3221316-6" },
  { id: "155066", name: "Pohjois-Savon käräjäoikeus", VAT: "3006157-6" },
  {
    id: "A3027",
    name: "Pohjois-Savon Liitto-Maakuntayhtymä",
    VAT: "0827616-7",
  },
  {
    id: "A10703",
    name: "Pohjois-Savon Sairaanhoitopiirin kuntayhtymä",
    VAT: "0171495-3",
  },
  {
    id: "3800028",
    name: "Pohjois-Savon työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  {
    id: "360005",
    name: "Pohjois-Suomen aluehallintovirasto",
    VAT: "0245422-5",
  },
  { id: "155036", name: "Pohjois-Suomen hallinto-oikeus", VAT: "3006157-6" },
  {
    id: "150499",
    name: "Pohjois-Suomen oikeusapu- ja edunvalvontapiiri",
    VAT: "0245974-7",
  },
  {
    id: "60017894",
    name: "Pohjois-Suomen ortodoksinen seurakunta",
    VAT: "0187717-0",
  },
  { id: "223001", name: "Poliisiammattikorkeakoulu", VAT: "2153105-4" },
  { id: "224001", name: "Poliisihallitus", VAT: "2288666-6" },
  {
    id: "A3276",
    name: "Polkka - Pohjois-Karjalan tukipalvelut oy",
    VAT: "2775939-1",
  },
  { id: "A10474", name: "Polvijärven kunta", VAT: "0169823-6" },
  { id: "A11938", name: "Polvijärven seurakunta", VAT: "0207685-0" },
  { id: "A10475", name: "Pomarkun kunta", VAT: "0136610-0" },
  { id: "A11197", name: "Pori Energia Oy", VAT: "0790442-4" },
  { id: "A11351", name: "Pori, Konsernihallinnon toimiala", VAT: "0137323-9" },
  { id: "A3332", name: "Pori, Perusturva", VAT: "0137323-9" },
  { id: "60024239", name: "Pori, Porin Palveluliikelaitos", VAT: "0137323-9" },
  { id: "60024240", name: "Pori, Porin Vesi", VAT: "0137323-9" },
  { id: "60024237", name: "Pori, Sivistystoimiala", VAT: "0137323-9" },
  { id: "A3682", name: "Pori, Tekninen toimiala", VAT: "0137323-9" },
  {
    id: "60024238",
    name: "Pori, Ympäristö- ja lupapalvelut -toimiala",
    VAT: "0137323-9",
  },
  {
    id: "A11006",
    name: "Porin Evankelis-Luterilainen Seurakuntayhtymä",
    VAT: "0204342-6",
  },
  { id: "A10477", name: "Pornaisten kunta", VAT: "0130095-3" },
  { id: "A11009", name: "Pornaisten seurakunta", VAT: "0130098-8" },
  { id: "60026666", name: "Porvoo, Elinvoima", VAT: "1061512-1" },
  { id: "A12027", name: "Porvoo, Kasvu ja oppiminen", VAT: "1061512-1" },
  { id: "60026667", name: "Porvoo, Kaupunkikehitys", VAT: "1061512-1" },
  { id: "A3598", name: "Porvoo, Konsernipalvelut", VAT: "1061512-1" },
  {
    id: "A3832",
    name: "Porvoo, Liikelaitos Kuninkaantien työterveys",
    VAT: "1061512-1",
  },
  {
    id: "A11858",
    name: "Porvoo, Liikelaitos Porvoon ateria- ja puhtauspalvelut",
    VAT: "1061512-1",
  },
  { id: "A3308", name: "Porvoo, Liikelaitos Porvoon vesi", VAT: "1061512-1" },
  {
    id: "A11011",
    name: "Porvoon Seurakuntayhtymä Borgå Kyrkliga Samfällighet",
    VAT: "0203446-6",
  },
  { id: "60025591", name: "Posion Kehitysyhtiö Oy", VAT: "0532910-4" },
  { id: "A10479", name: "Posion kunta", VAT: "0191908-6" },
  { id: "A11015", name: "Posion seurakunta", VAT: "0191910-7" },
  { id: "60025584", name: "Posion Taika-asunnot Oy", VAT: "2239975-3" },
  { id: "60025585", name: "Posion Vesi ja Lämpö Oy", VAT: "0191895-6" },
  { id: "60026453", name: "PPE-Köökki Oy", VAT: "3319133-3" },
  { id: "60026385", name: "PPP-Köökki Oy", VAT: "3297457-1" },
  { id: "A11366", name: "Provincia Oy", VAT: "2516653-3" },
  { id: "A10480", name: "Pudasjärven kaupunki", VAT: "0188962-2" },
  { id: "A11016", name: "Pudasjärven seurakunta", VAT: "0188970-2" },
  { id: "60018138", name: "Pudasjärven Vuokratalot Oy", VAT: "0233813-6" },
  { id: "A10042", name: "Puhas Oy", VAT: "1067047-0" },
  { id: "A10481", name: "Pukkilan kunta", VAT: "0130729-0" },
  { id: "A11018", name: "Pukkilan seurakunta", VAT: "0130731-1" },
  { id: "A10482", name: "Punkalaitumen kunta", VAT: "0138037-5" },
  { id: "A12055", name: "Punkalaitumen seurakunta", VAT: "0138043-9" },
  { id: "A10483", name: "Puolangan kunta", VAT: "0189081-8" },
  { id: "A11019", name: "Puolangan seurakunta", VAT: "0189086-9" },
  { id: "60025278", name: "Puolustuskiinteistöt", VAT: "3169414-8" },
  { id: "250940", name: "Puolustusministeriö", VAT: "0146010-5" },
  { id: "251001", name: "Puolustusvoimat", VAT: "0952029-9" },
  { id: "60025437", name: "Puro Tekstiilihuoltopalvelut Oy", VAT: "3172148-4" },
  { id: "A10484", name: "Puumalan kunta", VAT: "0166400-1" },
  { id: "A11987", name: "Puumalan seurakunta", VAT: "0166366-6" },
  { id: "A10485", name: "Pyhtään kunta", VAT: "0162798-0" },
  { id: "A11021", name: "Pyhtään seurakunta", VAT: "0162837-7" },
  { id: "A10486", name: "Pyhäjoen kunta", VAT: "0189127-1" },
  { id: "A11022", name: "Pyhäjoen seurakunta", VAT: "0189130-0" },
  { id: "A10487", name: "Pyhäjärven kaupunki", VAT: "0210261-7" },
  { id: "A11939", name: "Pyhäjärven seurakunta", VAT: "0210264-1" },
  { id: "A10488", name: "Pyhännän kunta", VAT: "0189226-6" },
  { id: "A10489", name: "Pyhärannan kunta", VAT: "0204403-1" },
  { id: "A11023", name: "Pyhärannan seurakunta", VAT: "0138068-2" },
  { id: "A3363", name: "Päijät-Hämeen Ateriapalvelut Oy", VAT: "2828895-8" },
  { id: "60025824", name: "Päijät-Hämeen hyvinvointialue", VAT: "3221309-4" },
  { id: "155067", name: "Päijät-Hämeen käräjäoikeus", VAT: "3006157-6" },
  {
    id: "A3364",
    name: "Päijät-Hämeen Laitoshuoltopalvelut Oy",
    VAT: "2828893-1",
  },
  { id: "60024695", name: "Päijät-Hämeen liitto", VAT: "0215610-5" },
  { id: "A10490", name: "Pälkäneen kunta", VAT: "2050961-3" },
  { id: "A11025", name: "Pälkäneen seurakunta", VAT: "0152192-4" },
  {
    id: "60026157",
    name: "Pääkaupunkiseudun Kaupunkiliikenne Oy",
    VAT: "3251002-1",
  },
  {
    id: "A12152",
    name: "Pääkaupunkiseudun Kierrätyskeskus Oy",
    VAT: "0809814-5",
  },
  {
    id: "A10677",
    name: "Pöytyän Kansanterveystyön kuntayhtymä",
    VAT: "0204411-1",
  },
  { id: "A10491", name: "Pöytyän kunta", VAT: "1929519-5" },
  { id: "60026600", name: "Pöytyän Lämpökeskus Oy", VAT: "0138095-7" },
  { id: "A11026", name: "Pöytyän seurakunta", VAT: "0138110-8" },
  { id: "A11200", name: "Raahen Energia Oy", VAT: "1609181-6" },
  { id: "A10492", name: "Raahen kaupunki", VAT: "1791817-6" },
  { id: "A10716", name: "Raahen Satama Oy", VAT: "2627981-2" },
  {
    id: "A10678",
    name: "Raahen seudun hyvinvointikuntayhtymä",
    VAT: "0210286-0",
  },
  { id: "A11027", name: "Raahen seurakunta", VAT: "2047367-3" },
  { id: "A11301", name: "Raahen Vesi Oy", VAT: "0188837-1" },
  { id: "940001", name: "Rahoitusvakausrahasto", VAT: "2683908-2" },
  { id: "315001", name: "Rahoitusvakausvirasto", VAT: "2683902-3" },
  { id: "A10494", name: "Raision kaupunki", VAT: "0204428-5" },
  {
    id: "A10259",
    name: "Raision Seudun Koulutuskuntayhtymä",
    VAT: "0204427-7",
  },
  { id: "A11029", name: "Raision seurakunta", VAT: "0204432-2" },
  { id: "A3154", name: "Raision Vesi Oy", VAT: "2771691-4" },
  { id: "A11506", name: "Rajamäen Uimahalli Oy", VAT: "0194273-7" },
  { id: "201021", name: "Rajavartiolaitos", VAT: "0246003-5" },
  { id: "A10495", name: "Rantasalmen kunta", VAT: "0166507-1" },
  { id: "A3420", name: "Ranuan Infra Oy", VAT: "2543501-9" },
  { id: "A10496", name: "Ranuan kunta", VAT: "0191974-8" },
  { id: "A11032", name: "Ranuan seurakunta", VAT: "0210538-3" },
  { id: "A11033", name: "Raseborgs Kyrkliga Samfällighet", VAT: "0203556-5" },
  { id: "A10493", name: "Raseborgs stad", VAT: "0131297-0" },
  { id: "A10497", name: "Rauman kaupunki", VAT: "0138780-9" },
  { id: "A11034", name: "Rauman seurakunta", VAT: "0215528-4" },
  { id: "A11035", name: "Rautalammin ev lut seurakunta", VAT: "0208017-1" },
  { id: "A3241", name: "Rautalammin kunta", VAT: "0172586-3" },
  {
    id: "60017889",
    name: "Rautalammin ortodoksinen seurakunta",
    VAT: "0172590-0",
  },
  { id: "A10498", name: "Rautavaaran kunta", VAT: "0172646-0" },
  { id: "A11036", name: "Rautavaaran seurakunta", VAT: "0172649-5" },
  { id: "A10499", name: "Rautjärven kunta", VAT: "0206951-1" },
  { id: "A10500", name: "Reisjärven kunta", VAT: "0189548-3" },
  { id: "A11037", name: "Reisjärven seurakunta", VAT: "0189553-9" },
  { id: "A3620", name: "Replot Församling", VAT: "0198369-4" },
  { id: "A10501", name: "Riihimäen kaupunki", VAT: "0152563-4" },
  { id: "A10191", name: "Riihimäen Kotikulma Oy", VAT: "0592797-2" },
  {
    id: "A10681",
    name: "Riihimäen Seudun Terveyskeskuksen kuntayhtymä",
    VAT: "0205809-7",
  },
  { id: "A3431", name: "Riihimäen seurakunta", VAT: "0152574-9" },
  { id: "A11508", name: "Riihimäen Tilat ja Kehitys Oy", VAT: "0348794-2" },
  { id: "151021", name: "Rikosseuraamuslaitos", VAT: "0312081-7" },
  {
    id: "A11990",
    name: "Rikssvenska Olaus Petri-församlingen",
    VAT: "0224025-1",
  },
  { id: "A10503", name: "Ristijärven kunta", VAT: "0189576-6" },
  { id: "60026055", name: "Ristijärven Vuokratalot Oy", VAT: "1934594-9" },
  { id: "A12038", name: "Riverbank Oy", VAT: "1755513-3" },
  { id: "A10044", name: `Rosk 'n Roll Oy Ab`, VAT: "2447281-1" },
  { id: "A11509", name: "Rovakaira Oy", VAT: "1637865-7" },
  { id: "A11510", name: "Rovanapa Oy", VAT: "1830727-0" },
  { id: "155025", name: "Rovaniemen hovioikeus", VAT: "3006157-6" },
  { id: "A10504", name: "Rovaniemen kaupunki", VAT: "1978283-1" },
  { id: "A11511", name: "Rovaniemen Kehitys Oy", VAT: "2007638-7" },
  { id: "A10260", name: "Rovaniemen Koulutuskuntayhtymä", VAT: "0973110-9" },
  {
    id: "60026343",
    name: "Rovaniemen kylien kehittämissäätiö sr",
    VAT: "1985499-7",
  },
  { id: "A11044", name: "Rovaniemen seurakunta", VAT: "0192436-0" },
  { id: "430001", name: "Ruokavirasto", VAT: "2911686-7" },
  { id: "A10505", name: "Ruokolahden kunta", VAT: "0163013-5" },
  { id: "A11992", name: "Ruokolahden seurakunta", VAT: "0163018-6" },
  { id: "A10506", name: "Ruoveden kunta", VAT: "0152842-1" },
  { id: "A13010", name: "Ruoveden seurakunta", VAT: "0152850-1" },
  { id: "A10507", name: "Ruskon kunta", VAT: "0204524-5" },
  { id: "A11046", name: "Ruskon seurakunta", VAT: "0139201-8" },
  { id: "60026723", name: "Ruukkukujan Autopaikat Oy", VAT: "0362841-8" },
  { id: "A10508", name: "Rääkkylän kunta", VAT: "0169967-7" },
  { id: "670021", name: "Saamelaisalueen koulutuskeskus", VAT: "0244767-4" },
  { id: "A10509", name: "Saarijärven kaupunki", VAT: "0176975-1" },
  {
    id: "60026404",
    name: "Saarijärven Seudun Ravitsemuspalvelut SaaSeRa Oy",
    VAT: "3322280-6",
  },
  { id: "A11048", name: "Saarijärven seurakunta", VAT: "0176980-7" },
  { id: "A3102", name: "Saariselän Siula Oy", VAT: "1648168-9" },
  { id: "600101", name: "Saavutettavuuskirjasto Celia", VAT: "0245872-8" },
  { id: "A12057", name: "Saimaan Tukipalvelut Oy", VAT: "0162017-2" },
  { id: "A3551", name: "Sakupe Oy", VAT: "0171338-7" },
  { id: "A10510", name: "Sallan kunta", VAT: "0192936-4" },
  { id: "A11049", name: "Sallan seurakunta", VAT: "0192945-2" },
  { id: "A10511", name: "Salon kaupunki", VAT: "0139533-1" },
  { id: "A10261", name: "Salon Seudun Koulutuskuntayhtymä", VAT: "0139545-4" },
  { id: "A11050", name: "Salon seurakunta", VAT: "2189843-8" },
  { id: "A10043", name: "Salpakierto Oy", VAT: "0937458-6" },
  { id: "A11052", name: "Saltviks Församling", VAT: "0145171-9" },
  { id: "60025209", name: "Saltviks kommun", VAT: "0205119-4" },
  { id: "1500011", name: "Samediggi/Saamelaiskäräjät", VAT: "1052535-3" },
  {
    id: "A10706",
    name: "Samkommunen för Österbottens välfärdsområde- Pohjanmaan hyvinvointialueen kuntayhtymä",
    VAT: "0349388-3",
  },
  { id: "A10262", name: "SASKY koulutuskuntayhtymä", VAT: "0204964-1" },
  { id: "A10512", name: "Sastamalan kaupunki", VAT: "0144411-3" },
  {
    id: "A11516",
    name: "Sastamalan Ruoka- ja Puhtauspalvelut Oy",
    VAT: "2414170-5",
  },
  { id: "A11054", name: "Sastamalan seurakunta", VAT: "0204967-6" },
  { id: "882001", name: "Satakunnan ammattikorkeakoulu Oy", VAT: "2388924-4" },
  {
    id: "380004",
    name: "Satakunnan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  { id: "60025821", name: "Satakunnan hyvinvointialue", VAT: "3221304-3" },
  { id: "A10263", name: "Satakunnan koulutuskuntayhtymä", VAT: "0203929-1" },
  { id: "155068", name: "Satakunnan käräjäoikeus", VAT: "3006157-6" },
  {
    id: "A10705",
    name: "Satakunnan Sairaanhoitopiirin kuntayhtymä",
    VAT: "0825915-6",
  },
  {
    id: "3800023",
    name: "Satakunnan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A10599", name: "Satakuntaliitto", VAT: "0830322-5" },
  { id: "A10513", name: "Sauvon kunta", VAT: "9038213-6" },
  { id: "A10514", name: "Savitaipaleen kunta", VAT: "0163109-0" },
  { id: "A10037", name: "Savon ICT-palvelut Oy", VAT: "2417854-0" },
  { id: "A10264", name: "Savon Koulutuskuntayhtymä", VAT: "1852679-9" },
  { id: "883001", name: "Savonia-ammattikorkeakoulu oy", VAT: "2629463-3" },
  { id: "A10515", name: "Savonlinnan kaupunki", VAT: "0166906-4" },
  { id: "A11996", name: "Savonlinnan seurakunta", VAT: "0207412-6" },
  { id: "A10516", name: "Savukosken kunta", VAT: "0210704-7" },
  { id: "884001", name: "Seinäjoen Ammattikorkeakoulu Oy", VAT: "2539767-3" },
  { id: "A11206", name: "Seinäjoen Energia Oy", VAT: "0974008-0" },
  { id: "60017476", name: "Seinäjoen kaupunki", VAT: "1928736-3" },
  { id: "A10267", name: "Seinäjoen koulutuskuntayhtymä", VAT: "1007629-5" },
  { id: "A11055", name: "Seinäjoen seurakunta", VAT: "0182398-1" },
  { id: "A3124", name: "Seinäjoen Työterveys Oy", VAT: "2788158-1" },
  { id: "60026586", name: "Seipark Oy", VAT: "2329893-8" },
  { id: "A11521", name: "Seiverkot Oy", VAT: "2083762-5" },
  { id: "963027", name: "Senaatin Asema-alueet Oy", VAT: "2956119-4" },
  { id: "310001", name: "Senaatti-kiinteistöt", VAT: "1503388-4" },
  { id: "60024286", name: "Servica Oy", VAT: "2947618-4" },
  { id: "A11522", name: "Seure Henkilöstöpalvelut Oy", VAT: "0800415-3" },
  {
    id: "A11061",
    name: "Sibbo Kyrkliga Samfällighet Sipoon Seurakuntayhtymä",
    VAT: "0203531-1",
  },
  { id: "A3406", name: "Sibbo svenska Församling", VAT: "0524877-7" },
  { id: "A10518", name: "Sievin kunta", VAT: "0189615-2" },
  { id: "A11057", name: "Sievin seurakunta", VAT: "0189618-7" },
  { id: "A10519", name: "Siikaisten kunta", VAT: "0139842-8" },
  { id: "A11058", name: "Siikaisten seurakunta", VAT: "0139844-4" },
  { id: "A10520", name: "Siikajoen kunta", VAT: "2047359-3" },
  { id: "A10521", name: "Siikalatvan kunta", VAT: "0189019-9" },
  { id: "A12065", name: "Siikalatvan seurakunta", VAT: "1980419-9" },
  { id: "A11059", name: "Siilinjärven Ev.Lut.Seurakunta", VAT: "0172723-6" },
  { id: "A10522", name: "Siilinjärven kunta", VAT: "0172718-0" },
  { id: "A10523", name: "Simon kunta", VAT: "0193015-4" },
  { id: "A11060", name: "Simon seurakunta", VAT: "0210710-0" },
  { id: "A10524", name: "Sipoon kunta Sibbo kommun", VAT: "0203533-8" },
  { id: "200001", name: "Sisäministeriö", VAT: "0245992-3" },
  {
    id: "A10683",
    name: "Sisä-Savon Terveydenhuollon kuntayhtymä",
    VAT: "0214913-2",
  },
  { id: "224104", name: "Sisä-Suomen poliisilaitos", VAT: "2288666-6" },
  { id: "A10525", name: "Siuntion kunta", VAT: "0131156-4" },
  { id: "A11065", name: "Siuntion Seurakuntayhtymä", VAT: "0131155-6" },
  { id: "60026601", name: "SK Hankintapalvelut Oy", VAT: "2431020-6" },
  { id: "A10526", name: "Sodankylän kunta", VAT: "0193169-1" },
  { id: "A11070", name: "Sodankylän seurakunta", VAT: "0193172-0" },
  { id: "A10527", name: "Soinin kunta", VAT: "0182637-3" },
  { id: "A11071", name: "Soinin seurakunta", VAT: "0182638-1" },
  { id: "A10528", name: "Someron kaupunki", VAT: "0153082-0" },
  { id: "A12226", name: "Someron seurakunta", VAT: "0205855-6" },
  { id: "A10529", name: "Sonkajärven kunta", VAT: "9090160-2" },
  {
    id: "562001",
    name: "Sosiaali- ja terveysalan lupa- ja valvontavirasto",
    VAT: "1567057-6",
  },
  { id: "550001", name: "Sosiaali- ja Terveysministeriö", VAT: "0244685-8" },
  { id: "A11255", name: "Sosiaalipalvelusäätiö Ote sr", VAT: "1438515-9" },
  {
    id: "563001",
    name: "Sosiaaliturva-asioiden muutoksenhakulautakunta",
    VAT: "2841366-7",
  },
  { id: "A10530", name: "Sotkamon kunta", VAT: "0189766-5" },
  { id: "A11073", name: "Sotkamon seurakunta", VAT: "0189774-5" },
  { id: "60025218", name: "Sottunga Kommun", VAT: "0205121-5" },
  { id: "A11523", name: "SSYP Kehitys Oy", VAT: "2410366-1" },
  { id: "213703", name: "Statens ämbetsverk på Åland", VAT: "0282400-3" },
  { id: "60026659", name: "Stöd Botnia Tuki Ab Oy", VAT: "3333208-7" },
  { id: "A10531", name: "Sulkavan kunta", VAT: "0167265-0" },
  { id: "A11074", name: "Sulkavan seurakunta", VAT: "0167270-6" },
  { id: "60025210", name: "Sunds kommun", VAT: "0205125-8" },
  { id: "217001", name: "Suojelupoliisi", VAT: "0245435-6" },
  { id: "650012", name: "Suomalais-venäläinen koulu", VAT: "0245913-0" },
  { id: "605001", name: "Suomen Akatemia", VAT: "0245893-9" },
  { id: "944001", name: "Suomen Erillisverkot Oy", VAT: "1552436-8" },
  {
    id: "868001",
    name: "Suomen Humanistinen Ammattikorkeakoulu Oy",
    VAT: "1474763-1",
  },
  { id: "973001", name: "Suomen Huoltovarmuusdata Oy", VAT: "2220751-2" },
  { id: "950001", name: "Suomen itsenäisyyden juhlarahasto", VAT: "0202132-3" },
  {
    id: "984001",
    name: "Suomen Kansallisooppera ja -baletti sr",
    VAT: "0116936-9",
  },
  {
    id: "891001",
    name: "Suomen Kansallisteatterin Osakeyhtiö",
    VAT: "0215276-1",
  },
  {
    id: "60017475",
    name: "Suomen Kuntaliitto, Tukipalvelut",
    VAT: "0926151-4",
  },
  { id: "A3142", name: "Suomen kuntasäätiö sr", VAT: "2781032-9" },
  {
    id: "958001",
    name: "Suomen metsäkeskus - Finlands skogscentral",
    VAT: "2440921-4",
  },
  { id: "A3023", name: "Suomen ortodoksinen kirkko", VAT: "0170776-1" },
  { id: "951001", name: "Suomen Pankki", VAT: "0202248-1" },
  { id: "957001", name: "Suomen riistakeskus", VAT: "0201724-4" },
  { id: "890001", name: "Suomen Urheilumuseosäätiö sr", VAT: "0202320-6" },
  { id: "970004", name: "Suomen Vientiluotto Oy", VAT: "1642253-1 " },
  { id: "702001", name: "Suomen ympäristökeskus", VAT: "0996189-5" },
  { id: "A11524", name: "Suomen Yrittäjäopisto Oy", VAT: "0208850-1" },
  { id: "600005", name: "Suomenlinnan Hoitokunta", VAT: "0245880-8" },
  { id: "60025342", name: "Suomi-rata Oy", VAT: "3176750-3" },
  { id: "A10533", name: "Suomussalmen kunta", VAT: "0189925-7" },
  { id: "A11076", name: "Suomussalmen seurakunta", VAT: "0189932-9" },
  { id: "A10534", name: "Suonenjoen kaupunki", VAT: "0208061-4" },
  { id: "A11077", name: "Suonenjoen seurakunta", VAT: "0208060-6" },
  { id: "A3111", name: "Suonenjoen Vesi Oy", VAT: "2586749-5" },
  {
    id: "A10652",
    name: "Suupohjan Elinkeinotoimen kuntayhtymä",
    VAT: "1078732-9",
  },
  { id: "A11695", name: "Suupohjan Koulutuskuntayhtymä", VAT: "0973712-1" },
  {
    id: "A10685",
    name: "Suupohjan peruspalveluliikelaitoskuntayhtymä",
    VAT: "2204227-9",
  },
  { id: "A10035", name: "Suupohjan Seutupalvelukeskus Oy", VAT: "1736476-7" },
  { id: "A11525", name: "Suupohjan Seutuverkko Oy", VAT: "1962466-5" },
  { id: "A13028", name: "Suur-Savon Sähkö Oy", VAT: "0215863-7" },
  {
    id: "60026661",
    name: "Svenska Framtidsskolan i Helsingforsregionen Ab",
    VAT: "1648362-5",
  },
  { id: "60017502", name: "Svenska Handelshögskolan", VAT: "0245907-7" },
  {
    id: "A3826",
    name: "Svenska Österbottens förbund för Utbildning och Kultur",
    VAT: "0988182-8",
  },
  { id: "A10535", name: "Sysmän kunta", VAT: "0167352-2" },
  { id: "152001", name: "Syyttäjälaitos", VAT: "2722043-3" },
  { id: "A12000", name: "Säkylä-Köyliön seurakunta", VAT: "0139939-1" },
  { id: "A10536", name: "Säkylän kunta", VAT: "0139937-5" },
  { id: "555001", name: "Säteilyturvakeskus", VAT: "0245869-9" },
  { id: "A11079", name: "Sääksmäen seurakunta", VAT: "0206292-6" },
  { id: "846700", name: "Taideyliopisto", VAT: "2500305-6" },
  { id: "A3828", name: "Tainionvirran seurakunta", VAT: "2939064-7" },
  { id: "60025650", name: "Taipaleen seurakunta", VAT: "2934593-4" },
  { id: "A10537", name: "Taipalsaaren kunta", VAT: "0163320-5" },
  { id: "600017", name: "Taiteen edistämiskeskus", VAT: "0245872-8" },
  { id: "A10538", name: "Taivalkosken kunta", VAT: "0190100-3" },
  { id: "A11080", name: "Taivalkosken seurakunta", VAT: "0190105-4" },
  { id: "A10539", name: "Taivassalon kunta", VAT: "0139991-4" },
  { id: "A11082", name: "Taivassalon seurakunta", VAT: "0139994-9" },
  { id: "A10540", name: "Tammelan kunta", VAT: "0153179-4" },
  { id: "A12056", name: "Tammelan seurakunta", VAT: "0153182-3" },
  {
    id: "60017699",
    name: "Tampere, Elinvoiman ja kilpailukyvyn palvelualue",
    VAT: "0211675-2",
  },
  {
    id: "A3577",
    name: "Tampere, Kaupunkiympäristön palvelualue",
    VAT: "0211675-2",
  },
  { id: "A3208", name: "Tampere, Konsernihallinto", VAT: "0211675-2" },
  {
    id: "60017697",
    name: "Tampere, Sivistyspalvelujen palvelualue",
    VAT: "0211675-2",
  },
  {
    id: "60017698",
    name: "Tampere, Sosiaali- ja terveyspalvelujen palvelualue",
    VAT: "0211675-2",
  },
  { id: "835002", name: "Tampereen ammattikorkeakoulu Oy", VAT: "1015428-1" },
  {
    id: "A12046",
    name: "Tampereen Evankelis-Luterilainen Seurakuntayhtymä",
    VAT: "0206114-9",
  },
  {
    id: "A11083",
    name: "Tampereen Hiippakunnan Tuomiokapituli",
    VAT: "0246828-0",
  },
  { id: "60017934", name: "Tampereen Infra Oy", VAT: "3022425-9" },
  {
    id: "A3132",
    name: "Tampereen kaupunkiseudun kuntayhtymä",
    VAT: "2014682-4",
  },
  { id: "60017936", name: "Tampereen Kotilinnasäätiö sr", VAT: "0155582-5" },
  {
    id: "60017892",
    name: "Tampereen ortodoksinen seurakunta",
    VAT: "0206152-8",
  },
  { id: "A3435", name: "Tampereen Raitiotie Oy", VAT: "2802791-8" },
  { id: "60017855", name: "Tampereen Sarka Oy", VAT: "2419449-5" },
  {
    id: "A11528",
    name: "Tampereen Seudun Keskuspuhdistamo Oy",
    VAT: "0211477-3",
  },
  { id: "A3434", name: "Tampereen Tilapalvelut Oy", VAT: "2863261-6" },
  { id: "60017883", name: "Tampereen Vuokra-asunnot Oy", VAT: "1611289-1" },
  { id: "A3266", name: "Tampereen Vuokratalosäätiö sr", VAT: "0155666-8" },
  { id: "835001", name: "Tampereen yliopisto", VAT: "2844561-8" },
  { id: "956001", name: "Tapio Oy", VAT: "2660009-8" },
  { id: "150018", name: "Tasa-arvovaltuutetun toimisto", VAT: "0245974-7" },
  { id: "120001", name: "Tasavallan Presidentin Kanslia", VAT: "0245979-8" },
  { id: "60024300", name: "Tays Kehitysyhtiö Oy", VAT: "2981181-9" },
  { id: "A11814", name: "TAYS Sydänkeskus OY", VAT: "2298047-6" },
  { id: "A3436", name: "TeeSe Botnia Oy Ab", VAT: "2859365-3" },
  { id: "A11535", name: "Teknologiakeskus TechVilla Oy", VAT: "1579776-1" },
  { id: "976001", name: "Teknologian tutkimuskeskus VTT Oy", VAT: "2647375-4" },
  { id: "A11215", name: "Tenergia Oy", VAT: "1868785-9" },
  { id: "561001", name: "Terveyden ja hyvinvoinnin laitos", VAT: "2229500-6" },
  { id: "A10544", name: "Tervolan kunta", VAT: "0193249-1" },
  { id: "A12041", name: "Tervolan Palveluverkot Oy", VAT: "2619054-3" },
  { id: "A11088", name: "Tervolan seurakunta", VAT: "0193252-0" },
  { id: "A11305", name: "Tervolan Vesi Oy", VAT: "2246015-5" },
  { id: "A10211", name: "Tervolan vuokratalot Oy", VAT: "0836895-0" },
  { id: "A10543", name: "Tervon kunta", VAT: "0173081-4" },
  { id: "A10545", name: "Teuvan kunta", VAT: "0182734-1" },
  { id: "A11765", name: "Teuvan seurakunta", VAT: "0182738-4" },
  {
    id: "60026432",
    name: "Tiedusteluvalvontavaltuutetun toimisto",
    VAT: "0245974-7",
  },
  { id: "150004", name: "Tietosuojavaltuutetun toimisto", VAT: "0245974-7" },
  { id: "321001", name: "Tilastokeskus", VAT: "0245491-1" },
  { id: "A10546", name: "Tohmajärven kunta", VAT: "1919717-3" },
  { id: "A11941", name: "Tohmajärven seurakunta", VAT: "0170027-9" },
  { id: "A10547", name: "Toholammin kunta", VAT: "0182779-8" },
  { id: "A11090", name: "Toholammin seurakunta", VAT: "0182781-9" },
  { id: "A10548", name: "Toivakan kunta", VAT: "0177201-0" },
  { id: "A11766", name: "Toivakan seurakunta", VAT: "0177203-7" },
  { id: "A11216", name: "Tornion Energia Oy", VAT: "1801477-7" },
  { id: "A10549", name: "Tornion kaupunki", VAT: "0193524-6" },
  { id: "A11536", name: "Tornion Krunni Oy", VAT: "0220007-3" },
  { id: "A11091", name: "Tornion seurakunta", VAT: "0210784-8" },
  { id: "A11257", name: "Tornion Työvoimalasäätiö sr", VAT: "2034178-5" },
  { id: "A11306", name: "Tornion Vesi Oy", VAT: "1935914-4" },
  { id: "A10213", name: "Tornion Vuokra-asunnot Oy", VAT: "0781617-6" },
  { id: "A3055", name: "TREDU-Kiinteistöt Oy", VAT: "2605630-1" },
  { id: "60017937", name: "TT Botnia Oy", VAT: "2995396-4" },
  { id: "302021", name: "Tulli", VAT: "0245442-8" },
  { id: "A3093", name: "Tunturiverkko Oy", VAT: "2264528-0" },
  { id: "A3857", name: "Tuohisentteri Oy", VAT: "2225206-5" },
  { id: "A11539", name: "Tuomi Logistiikka Oy", VAT: "2722581-6" },
  { id: "155001", name: "Tuomioistuinvirasto", VAT: "3006157-6" },
  { id: "A3065", name: "Turku Energia Sähköverkot Oy", VAT: "2001717-6" },
  { id: "A11818", name: "Turku Science Park Oy Ab", VAT: "2322323-1" },
  { id: "60024710", name: "Turku, Konsernihallinto", VAT: "0204819-8" },
  { id: "60026465", name: "Turun ammatti-instituutti", VAT: "0204819-8" },
  { id: "886001", name: "Turun ammattikorkeakoulu Oy", VAT: "2528160-3" },
  {
    id: "A11093",
    name: "Turun Arkkihiippakunnan Tuomiokapituli",
    VAT: "0276647-2",
  },
  { id: "155032", name: "Turun hallinto-oikeus", VAT: "3006157-6" },
  { id: "155021", name: "Turun hovioikeus", VAT: "3006157-6" },
  {
    id: "A11097",
    name: "Turun ja Kaarinan Seurakuntayhtymä",
    VAT: "0204809-1",
  },
  { id: "A11540", name: "Turun Kaupunginteatteri Oy", VAT: "2496346-9" },
  { id: "A11826", name: "Turun Kaupunkiliikenne Oy", VAT: "2240669-3" },
  { id: "A10717", name: "Turun Satama Oy", VAT: "2322326-6" },
  { id: "A11542", name: "Turun seudun puhdistamo Oy", VAT: "1774713-5" },
  { id: "A11307", name: "Turun Seudun Vesi Oy", VAT: "0141915-3" },
  { id: "60025341", name: "Turun Tunnin Juna Oy", VAT: "3176746-6" },
  { id: "A3099", name: "Turun Vesihuolto Oy", VAT: "1028215-2" },
  { id: "836001", name: "Turun yliopisto", VAT: "0245896-3" },
  { id: "513001", name: "Turvallisuus- ja kemikaalivirasto", VAT: "1021277-9" },
  { id: "A10551", name: "Tuusniemen kunta", VAT: "0173128-6" },
  { id: "A11546", name: "Tuusulan Jäähalli Oy", VAT: "1067126-2" },
  { id: "A11737", name: "Tuusulan kunnan kiinteistöt Oy", VAT: "0771183-3" },
  { id: "A10552", name: "Tuusulan kunta", VAT: "0131661-3" },
  { id: "A11107", name: "Tuusulan seurakunta", VAT: "0131666-4" },
  { id: "A10219", name: "TVT Asunnot Oy", VAT: "0872109-9" },
  {
    id: "60024312",
    name: "TVV lippu- ja maksujärjestelmä Oy",
    VAT: "2560184-9",
  },
  { id: "A10553", name: "Tyrnävän kunta", VAT: "0190140-9" },
  { id: "A11108", name: "Tyrnävän seurakunta", VAT: "0190141-7" },
  {
    id: "A11767",
    name: "Tyska Ev. Luth. Församlingen i Finland",
    VAT: "0213679-6",
  },
  { id: "540001", name: "Työ- ja elinkeinoministeriö", VAT: "2160307-0" },
  { id: "977001", name: "Työllisyysrahasto", VAT: "1098099-7" },
  { id: "60017400", name: "Työterveys Aalto Oy", VAT: "2957137-6" },
  { id: "A3125", name: "Työterveys Virta Oy", VAT: "2771501-4" },
  { id: "A11690", name: "Työterveys Wellamo Oy", VAT: "2306525-3" },
  { id: "965001", name: "Työterveyslaitos", VAT: "0220266-9" },
  { id: "155041", name: "Työtuomioistuin", VAT: "3006157-6" },
  { id: "130001", name: "Ulkoministeriö", VAT: "0245973-9" },
  { id: "130002", name: "Ulkoministeriön edustustot", VAT: "0245973-9" },
  { id: "112001", name: "Ulkopoliittinen instituutti", VAT: "2062721-4" },
  { id: "153001", name: "Ulosottolaitos", VAT: "2302414-4" },
  { id: "60018112", name: "Ulvilan Isännöintipalvelu Oy", VAT: "0623744-7" },
  { id: "A10555", name: "Ulvilan kaupunki", VAT: "0204910-7" },
  { id: "A11111", name: "Ulvilan seurakunta", VAT: "0204911-5" },
  { id: "A3738", name: "UNA Oy", VAT: "2898565-7" },
  { id: "A10556", name: "Urjalan kunta", VAT: "0157323-0" },
  { id: "A11112", name: "Urjalan seurakunta", VAT: "0157328-1" },
  { id: "A10557", name: "Utajärven kunta", VAT: "0190224-1" },
  { id: "A11113", name: "Utajärven seurakunta", VAT: "0190229-2" },
  { id: "60017489", name: "Utajärven Yrityspuisto Oy", VAT: "1092634-1" },
  { id: "A10558", name: "Utsjoen kunta", VAT: "9129466-4" },
  { id: "A10561", name: "Uudenkaupungin kaupunki", VAT: "0144036-6" },
  { id: "A11115", name: "Uudenkaupungin seurakunta", VAT: "2218660-0" },
  {
    id: "380001",
    name: "Uudenmaan elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "A10600",
    name: "Uudenmaan liitto - Nylands förbund",
    VAT: "0201296-1",
  },
  {
    id: "A10653",
    name: "Uudenmaan Päihdehuollon kuntayhtymä",
    VAT: "0125920-9",
  },
  {
    id: "3800021",
    name: "Uudenmaan työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A10559", name: "Uuraisten kunta", VAT: "0177224-8" },
  { id: "A11768", name: "Uuraisten seurakunta", VAT: "0177228-0" },
  { id: "A10562", name: "Vaalan kunta", VAT: "0190027-0" },
  { id: "A11116", name: "Vaalan seurakunta", VAT: "0190029-7" },
  { id: "A3375", name: "Vaalan Vesi ja Lämpö Oy", VAT: "2785860-5" },
  { id: "887001", name: "Vaasan ammattikorkeakoulu Oy", VAT: "2267669-3" },
  { id: "155034", name: "Vaasan hallinto-oikeus", VAT: "3006157-6" },
  { id: "155022", name: "Vaasan hovioikeus", VAT: "3006157-6" },
  { id: "A10563", name: "Vaasan kaupunki", VAT: "0209602-6" },
  { id: "60017463", name: "Vaasan Merikampus Oy", VAT: "2994564-9" },
  { id: "60017890", name: "Vaasan ortodoksinen seurakunta", VAT: "0209614-9" },
  { id: "A11118", name: "Vaasan Seurakuntayhtymä", VAT: "0209622-9" },
  { id: "60017911", name: "Vaasan Yliopisto", VAT: "0209599-8" },
  {
    id: "60017765",
    name: "Vaasanseudun Areenat Kuntayhtymä - Vasaregionens Arenor Samkommun",
    VAT: "1025565-6",
  },
  { id: "155042", name: "Vakuutusoikeus", VAT: "3006157-6" },
  { id: "60004135", name: "Valamon Luostari", VAT: "0164329-4" },
  { id: "A10564", name: "Valkeakosken kaupunki", VAT: "0157568-2" },
  {
    id: "A10269",
    name: "Valkeakosken seudun koulutuskuntayhtymä",
    VAT: "0206289-7",
  },
  { id: "660035", name: "Valteri-koulu", VAT: "2718015-2" },
  { id: "301001", name: "Valtiokonttori", VAT: "0245440-1" },
  { id: "927001", name: "Valtion asuntorahasto", VAT: "" },
  { id: "928001", name: "Valtion Eläkerahasto", VAT: "1583293-4" },
  { id: "552014", name: "Valtion koulukodit", VAT: "0186550-8" },
  {
    id: "306001",
    name: "Valtion Taloudellinen Tutkimuskeskus",
    VAT: "0809880-7",
  },
  {
    id: "309001",
    name: "Valtion talous- ja henkilöstöhallinnon palvelukeskus",
    VAT: "2272612-8",
  },
  {
    id: "320001",
    name: "Valtion tieto- ja viestintätekniikkakeskus Valtori",
    VAT: "2574261-7",
  },
  { id: "926001", name: "Valtion Ydinjätehuoltorahasto", VAT: "0702479-3" },
  { id: "125002", name: "Valtioneuvoston Kanslia", VAT: "0245975-5" },
  { id: "929001", name: "Valtiontakuurahasto", VAT: "" },
  { id: "303001", name: "Valtiontalouden tarkastusvirasto", VAT: "0245456-7" },
  { id: "300001", name: "Valtiovarainministeriö", VAT: "0245439-9" },
  { id: "551011", name: "Vanhan Vaasan Sairaala", VAT: "0244688-2" },
  { id: "60026433", name: "Vanhusasiavaltuutettu", VAT: "0245974-7" },
  { id: "561005", name: "Vankiterveydenhuollon yksikkö", VAT: "2703935-1" },
  { id: "A11222", name: "Vantaan Energia Oy", VAT: "0124461-3" },
  { id: "A11223", name: "Vantaan Energia Sähköverkot Oy", VAT: "2038408-0" },
  {
    id: "60025812",
    name: "Vantaan ja Keravan hyvinvointialue",
    VAT: "3221356-1",
  },
  { id: "60024711", name: "Vantaan kaupunki", VAT: "0124610-9" },
  { id: "A11125", name: "Vantaan Seurakuntayhtymä", VAT: "0202774-1" },
  { id: "A10226", name: "Vantaan Tilapalvelut Vantti Oy", VAT: "2440395-5" },
  { id: "60018144", name: "Vantaan Työterveys Oy", VAT: "0943497-2" },
  { id: "621011", name: "Varastokirjasto", VAT: "0245872-8" },
  { id: "A3204", name: "Varkauden Aluelämpö Oy", VAT: "0208116-6" },
  { id: "A10567", name: "Varkauden kaupunki", VAT: "0173416-1" },
  { id: "A11128", name: "Varkauden seurakunta", VAT: "0173429-2" },
  {
    id: "380003",
    name: "Varsinais-Suomen elinkeino-, liikenne- ja ympäristökeskus",
    VAT: "2296962-1",
  },
  {
    id: "A10270",
    name: "Varsinais-Suomen Erityishuoltopiirin kuntayhtymä",
    VAT: "0136167-6",
  },
  {
    id: "60025820",
    name: "Varsinais-Suomen hyvinvointialue",
    VAT: "3221065-1",
  },
  { id: "155069", name: "Varsinais-Suomen käräjäoikeus", VAT: "3006157-6" },
  {
    id: "A13015",
    name: "Varsinais-Suomen Lastensuojelukuntayhtymä",
    VAT: "0783597-5",
  },
  {
    id: "A10601",
    name: "Varsinais-Suomen liitto - Egentliga Finlands förbund",
    VAT: "0922305-9",
  },
  {
    id: "A10707",
    name: "Varsinais-Suomen Sairaanhoitopiirin kuntayhtymä",
    VAT: "0828255-9",
  },
  {
    id: "3800022",
    name: "Varsinais-Suomen työ- ja elinkeinotoimisto",
    VAT: "2296962-1",
  },
  { id: "A10227", name: "VAV Asunnot Oy", VAT: "2903526-6" },
  { id: "60026718", name: "VAV Hoiva-asunnot Oy", VAT: "3215910-4" },
  { id: "A3835", name: "VAV Palvelukodit Oy", VAT: "2696057-9" },
  { id: "A3834", name: "VAV Yhtymä Oy", VAT: "0640915-7" },
  { id: "A10568", name: "Vehmaan kunta", VAT: "0144561-8" },
  { id: "A11131", name: "Vehmaan seurakunta", VAT: "0144563-4" },
  { id: "923001", name: "Veikkaus Oy", VAT: "2765220-1" },
  { id: "970002", name: "Veraventure Oy", VAT: "0842522-2 " },
  { id: "305021", name: "Verohallinto", VAT: "0245458-3" },
  { id: "A10569", name: "Vesannon kunta", VAT: "0173787-2" },
  { id: "A10570", name: "Vesilahden kunta", VAT: "0157711-9" },
  { id: "A12053", name: "Vesilahden seurakunta", VAT: "0157715-1" },
  { id: "A11309", name: "Vesi-Mega Oy", VAT: "0783302-1" },
  { id: "A3117", name: "Vestia Oy", VAT: "1569793-9" },
  { id: "A10571", name: "Vetelin kunta", VAT: "0184278-7" },
  { id: "A11134", name: "Vetelin seurakunta", VAT: "0184279-5" },
  { id: "A10572", name: "Vieremän kunta", VAT: "0173835-7" },
  { id: "A11135", name: "Vieremän seurakunta", VAT: "0173866-4" },
  { id: "A10574", name: "Vihdin kunta", VAT: "0131905-6" },
  { id: "A11136", name: "Vihdin seurakunta", VAT: "0131924-0" },
  { id: "A10575", name: "Viitasaaren kaupunki", VAT: "0208573-0" },
  { id: "A11769", name: "Viitasaaren seurakunta", VAT: "0208575-7" },
  { id: "A10576", name: "Vimpelin kunta", VAT: "0184318-1" },
  { id: "A11770", name: "Vimpelin seurakunta", VAT: "0184321-0" },
  { id: "A10577", name: "Virolahden kunta", VAT: "0207033-6" },
  { id: "A11227", name: "Virtain Energia Oy", VAT: "2720806-3" },
  { id: "A10578", name: "Virtain kaupunki", VAT: "0206333-9" },
  { id: "A11137", name: "Virtain seurakunta", VAT: "0206343-5" },
  { id: "946003", name: "VR-Yhtymä Oyj", VAT: "1003521-5" },
  { id: "A10231", name: "VTK Kiinteistöt Oy", VAT: "0517745-4" },
  { id: "60025219", name: "Vårdö kommun", VAT: "0205126-6" },
  {
    id: "A10232",
    name: "Vähänkyrön Vuokratalot Kiinteistö Oy",
    VAT: "0992710-1",
  },
  { id: "456001", name: "Väylävirasto", VAT: "1010547-1" },
  { id: "A11773", name: "Vörå församling", VAT: "2074401-5" },
  { id: "A10580", name: "Vörå kommun", VAT: "2050514-5" },
  { id: "60024323", name: "Wartalo Kodit Oy", VAT: "0609659-2" },
  {
    id: "150019",
    name: "Yhdenvertaisuusvaltuutetun toimisto",
    VAT: "0245974-7",
  },
  {
    id: "150003",
    name: "Yhdistyneitten kansakuntien yhteydessä toimiva Euroopan kriminaalipolitiikan instituutti HEUNI",
    VAT: "0245974-7",
  },
  { id: "945001", name: "Yleisradio Oy", VAT: "0215438-8" },
  { id: "6600022", name: "Ylioppilastutkintolautakunta", VAT: "2769790-1" },
  { id: "A10582", name: "Ylitornion kunta", VAT: "0210826-9" },
  { id: "A11141", name: "Ylitornion seurakunta", VAT: "0193917-5" },
  { id: "60024701", name: "Ylivieskan kaupunki", VAT: "0190557-3" },
  { id: "A11142", name: "Ylivieskan seurakunta", VAT: "0190578-4" },
  {
    id: "A11945",
    name: "Ylä-Savon evankelis-luterilainen seurakuntayhtymä",
    VAT: "2335153-2",
  },
  { id: "A10271", name: "Ylä-Savon koulutuskuntayhtymä", VAT: "0214765-5" },
  { id: "A10689", name: "Ylä-Savon SOTE kuntayhtymä", VAT: "2265875-1" },
  { id: "A11310", name: "Ylä-Savon Vesi Oy", VAT: "1841779-5" },
  { id: "A10584", name: "Ylöjärven kaupunki", VAT: "0158221-7" },
  { id: "A3396", name: "Ylöjärven seurakunta", VAT: "0158175-9" },
  { id: "A3336", name: "Ylöjärven Vesi Oy", VAT: "2795015-3" },
  { id: "A11559", name: "Ylöjärven Yrityspalvelu Oy", VAT: "1471878-5" },
  { id: "700001", name: "Ympäristöministeriö", VAT: "0519456-1" },
  { id: "A10585", name: "Ypäjän kunta", VAT: "0158301-7" },
  { id: "A13011", name: "Ypäjän seurakunta", VAT: "0158306-8" },
  { id: "A12044", name: "YritysKannus Oy", VAT: "0915106-7" },
  { id: "864001", name: "Yrkeshögskolan Arcada Ab", VAT: "2553871-2" },
  {
    id: "879001",
    name: "Yrkeshögskolan vid Åbo Akademi Ab / Yrkeshögskolan Novia",
    VAT: "2059910-2",
  },
  { id: "60017910", name: "Åbo Akademi", VAT: "0246312-1" },
  { id: "60025197", name: "Åda Ab", VAT: "2652001-2" },
  { id: "60026577", name: "Ålands gymnasium", VAT: "0145076-7" },
  { id: "A11775", name: "Ålands södra skärgårdsförsamling", VAT: "2000710-3" },
  { id: "155070", name: "Ålands tingsrätt", VAT: "3006157-6" },
  { id: "A10586", name: "Ähtärin kaupunki", VAT: "0184622-7" },
  { id: "A11144", name: "Ähtärin seurakunta", VAT: "0184625-1" },
  {
    id: "A10272",
    name: "Äänekosken Ammatillisen Koulutuksen kuntayhtymä",
    VAT: "0208589-6",
  },
  { id: "A10587", name: "Äänekosken kaupunki", VAT: "2045520-5" },
  { id: "A11560", name: "Äänekosken Kiinteistönhoito Oy", VAT: "0194882-1" },
  { id: "A11145", name: "Äänekosken seurakunta", VAT: "2048889-1" },
  { id: "A10207", name: "Ääneseudun Asunnot Oy", VAT: "0177096-3" },
  { id: "924001", name: "Öljysuojarahasto", VAT: "" },
  {
    id: "A10595",
    name: "Österbottens förbund - Pohjanmaan liitto kuntayhtymä",
    VAT: "0970063-6",
  },
];

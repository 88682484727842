import { Customer, SearchCustomersQueryVariables } from "~/graphql/API";
import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import { searchCustomers } from "~/graphql/queries.js";
// @ts-ignore
import { listAll } from "~/utilities";
// @ts-ignore
import { AppContext } from "~/AppContext";

const fallbackData: Customer[] = [];

async function fetchCustomers(variables?: SearchCustomersQueryVariables) {
  const result = (
    await listAll(
      graphqlOperation(searchCustomers, variables),
      "searchCustomers"
    )
  ).filter(Boolean) as Customer[];

  result.sort((a, b) =>
    (a.companyName || "").localeCompare(b.companyName || "", "fi")
  );

  return result;
}

export function useCustomers(...params: Parameters<typeof fetchCustomers>) {
  const [variables, ...args] = params;

  const filter: SearchCustomersQueryVariables["filter"] = {
    isDeleted: { ne: true },
  };

  return useSWR(
    filter ? ["customers", filter, ...params] : null,
    async () =>
      await fetchCustomers(
        {
          ...variables,
          filter: {
            ...filter,
            ...variables?.filter,
          },
          limit: 1000,
        },
        ...args
      ),
    {
      fallbackData,
      revalidateOnFocus: false,
    }
  );
}

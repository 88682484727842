import { IconButton, TableCell } from "@mui/material";
import { EPricingBy } from "../../../utils/tabOptions";
import { Edit } from "@mui/icons-material";

export default function EditPricing(props) {
  const { setEditablePricing, activePricing, pricing } = props;
  if (activePricing === EPricingBy.BUSINESS) {
    return (
      <TableCell align="center" width={"60px"}>
        <IconButton onClick={() => setEditablePricing(pricing)}>
          <Edit />
        </IconButton>
      </TableCell>
    );
  } else {
    return <TableCell />;
  }
}

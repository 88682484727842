import car from "./henkilöauto.png";
import van from "./Pakettiautot.png";
import bike from "./ebike.png";
import camper from "./matkailuauto.png";
import jetSki from "./vesijetti.png";
import snowMobile from "./moottorikelkka.png";
import openTrailer from "./Avokärry.jpg";
import trailer from "./Kuomukärry.jpg";
import truck from "./Kuorma-auto.jpg";
import minibus from "./Pikkubussi.jpg";
import suv from "./maastoautot.jpg";
import minivan from "./tila-auto.jpg";

import {
  FaTruck,
  FaCarSide,
  FaCaravan,
  FaShuttleVan,
  FaTrailer,
  FaBicycle,
  FaHotTub,
  FaHotel,
  FaRegQuestionCircle,
  FaTruckMonster,
} from "react-icons/fa";

import { GiScooter } from "react-icons/gi";

import { MdAirportShuttle, MdSnowmobile } from "react-icons/md";
import {
  ADDITIONAL_FIELDS_CAR,
  ADDITIONAL_FIELDS_BICYCLE,
  ADDITIONAL_FIELDS_CAMPER,
  ADDITIONAL_FIELDS_RV,
  ADDITIONAL_FIELDS_SNOWMOBILE,
  ADDITIONAL_FIELDS_VAN,
  ADDITIONAL_FIELDS_TRAILER,
  ADDITIONAL_FIELDS_SAUNABOAT,
  ADDITIONAL_FIELDS_JETSKI,
  ADDITIONAL_FIELDS_ACCOMMODATION,
  ADDITIONAL_FIELDS_SCOOTER,
  ADDITIONAL_FIELDS_OTHER,
  //@ts-ignore
} from "../additional-fields";
import { EProductType } from "~/enums/product-type";

export const typeOfProducts = [
  {
    id: EProductType.CARS,
    group: "car",
    name: "Henkilöautot",
    name_en: "Passenger cars",
    name_fi: "Henkilöautot",
    name_se: "Personbilar",
    icon: <FaCarSide style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: car,
    additionalFields: ADDITIONAL_FIELDS_CAR,
    product: "Auton",
    product_fi: "Auton",
    product_en: "Cars",
    product_se: "Personbilars",
  },
  {
    id: EProductType.SUVS,
    group: "car",
    name: "Maastoautot",
    name_en: "SUVs",
    name_fi: "Maastoautot",
    name_se: "Terrängfordon",
    icon: <FaTruckMonster style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: suv,
    additionalFields: ADDITIONAL_FIELDS_CAR,
    product: "Maastoauton",
    product_fi: "Maastoauton",
    product_en: "SUVs",
    product_se: "Terrängfordons",
  },
  {
    id: EProductType.MINIVAN,
    group: "car",
    name: "Tila-auto",
    name_en: "Minivan",
    name_fi: "Tila-auto",
    name_se: "Familjebuss",
    icon: <MdAirportShuttle style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: minivan,
    additionalFields: ADDITIONAL_FIELDS_CAR,
    product: "Tila-auton",
    product_fi: "Tila-auton",
    product_en: "Minivans",
    product_se: "Familjebuss",
  },
  {
    id: EProductType.MINIBUS,
    group: "car",
    name: "Pikkubussi",
    name_en: "Minibus",
    name_fi: "Pikkubussi",
    name_se: "Minibuss",
    icon: <MdAirportShuttle style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: minibus,
    additionalFields: ADDITIONAL_FIELDS_CAR,
    product: "Pikkubussin",
    product_fi: "Pikkubussin",
    product_en: "Minibusses",
    product_se: "Minibuss",
  },
  {
    id: EProductType.TRUCK,
    group: "car",
    name: "Kuorma-auto",
    name_en: "Truck",
    name_fi: "Kuorma-auto",
    name_se: "Lastbil",
    icon: <FaTruck style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: truck,
    additionalFields: ADDITIONAL_FIELDS_CAR,
    product: "Kuorma-auton",
    product_fi: "Kuorma-auton",
    product_en: "Trucks",
    product_se: "Lastbils",
  },

  {
    id: EProductType.VANS,
    group: "car",
    name: "Pakettiautot",
    name_en: "Vans",
    name_fi: "Pakettiautot",
    name_se: "Transportbilar",
    icon: <FaTruck style={{ fontSize: 28 }} />,
    tabIndex: 1,
    product: "Pakettiauton",
    product_fi: "Pakettiauton",
    product_en: "Vans",
    product_se: "Transportbilars",
    image: van,
    additionalFields: ADDITIONAL_FIELDS_VAN,
    contractTemplate: false,
  },
  {
    id: EProductType.RVS,
    group: "rvsCampers",
    name: "Matkailuautot",
    name_en: "RVs",
    name_fi: "Matkailuautot",
    name_se: "Fritidsfordon",
    icon: <FaShuttleVan style={{ fontSize: 28 }} />,
    tabIndex: 2,
    image: camper,
    product: "Matkailuauton",
    product_fi: "Matkailuauton",
    product_en: "Rvs",
    product_se: "Fritidsfordons",
    additionalFields: ADDITIONAL_FIELDS_RV,
    contractTemplate: false,
  },
  {
    id: EProductType.CAMPERS,
    group: "rvsCampers",
    name: "Matkailuvaunut",
    name_en: "Caravans",
    name_fi: "Matkailuvaunut",
    name_se: "Husvagn",
    icon: <FaCaravan style={{ fontSize: 28 }} />,
    tabIndex: 3,
    product: "Matkailuvaunun",
    product_fi: "Matkailuvaunun",
    product_en: "Caravans",
    product_se: "Husvagns",
    image: null,
    additionalFields: ADDITIONAL_FIELDS_CAMPER,
    contractTemplate: false,
  },
  {
    id: EProductType.TRAILERS,
    group: "trailerVarieties",
    name: "Peräkärryt",
    name_en: "Trailers",
    name_fi: "Peräkärryt",
    name_se: "Släpvagnar",
    icon: <FaTrailer style={{ fontSize: 28 }} />,
    tabIndex: 4,
    product: "Peräkärryn",
    product_fi: "Peräkärryn",
    product_en: "Trailers",
    product_se: "Släpvagnars",
    image: trailer,
    additionalFields: ADDITIONAL_FIELDS_TRAILER,
    contractTemplate: false,
  },
  {
    id: EProductType.TRAILER_OPEN,
    group: "trailerVarietes",
    name: "Avokärry",
    name_en: "Open Trailer",
    name_fi: "Avokärry",
    name_se: "Öppen Trailer",
    icon: <FaTrailer style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: openTrailer,
    additionalFields: ADDITIONAL_FIELDS_TRAILER,
    product: "Kärryn",
    product_fi: "Kärryn",
    product_en: "Trailers",
    product_se: "Trailers",
  },
  {
    id: EProductType.TRAILER_ENCLOSED,
    group: "trailerVarietes",
    name: "Kuomu- ja koppikärry",
    name_en: "Enclosed Trailer",
    name_fi: "Kuomu- ja koppikärry",
    name_se: "Stängd Trailer",
    icon: <FaTrailer style={{ fontSize: 28 }} />,
    tabIndex: 0,
    image: trailer,
    additionalFields: ADDITIONAL_FIELDS_TRAILER,
    product: "Kärryn",
    product_fi: "Kärryn",
    product_en: "Trailers",
    product_se: "Trailers",
  },
  {
    id: EProductType.SNOWMOBILES,
    group: "recreationalVehicles",
    name: "Moottorikelkat",
    name_en: "Snowmobiles",
    name_fi: "Moottorikelkat",
    name_se: "Snöskotrar",
    icon: <MdSnowmobile style={{ fontSize: 28 }} />,
    tabIndex: 5,
    product: "Moottorikelkan",
    product_fi: "Moottorikelkan",
    product_en: "Snowmobiles",
    product_se: "Snöskotrars",
    image: snowMobile,
    additionalFields: ADDITIONAL_FIELDS_SNOWMOBILE,
    contractTemplate: false,
  },
  {
    id: EProductType.JETSKIS,
    group: "recreationalVehicles",
    name: "Vesijetit",
    name_en: "Jet skis",
    name_fi: "Vesijetit",
    name_se: "Vattenskoter",
    icon: <MdSnowmobile style={{ fontSize: 28 }} />,
    tabIndex: 6,
    product: "Vesijetin",
    product_fi: "Vesijetin",
    product_en: "Jet skis",
    product_se: "Vattenskoters",
    image: jetSki,
    additionalFields: ADDITIONAL_FIELDS_JETSKI,
    contractTemplate: false,
  },
  {
    id: EProductType.BICYCLES,

    name: "Polkupyörät",
    group: "recreationalVehicles",
    name_en: "Bicycles",
    name_fi: "Polkupyörät",
    name_se: "Cyklar",
    icon: <FaBicycle style={{ fontSize: 28 }} />,
    tabIndex: 7,
    product: "Polkupyörän",
    product_fi: "Polkupyörän",
    product_en: "Bicycles",
    product_se: "Cyklars",
    image: bike,
    additionalFields: ADDITIONAL_FIELDS_BICYCLE,
    contractTemplate: false,
  },
  {
    id: EProductType.SAUNABOATS,
    group: "recreationalVehicles",
    name: "Saunalautat",
    name_en: "Saunarafts",
    name_fi: "Saunalautat",
    name_se: "Basturaft",
    icon: <FaHotTub style={{ fontSize: 28 }} />,
    tabIndex: 8,
    product: "Saunalautan",
    product_en: "Saunarafts",
    product_fi: "Saunalautan",
    product_se: "Basturafts",
    additionalFields: ADDITIONAL_FIELDS_SAUNABOAT,
    contractTemplate: false,
  },

  {
    id: EProductType.ACCOMMODATIONS,
    group: "accommodations",
    name: "Majoituspaikka",
    name_en: "Accommodations",
    name_fi: "Majoituspaikat",
    name_se: "Boenden",
    icon: <FaHotel style={{ fontSize: 28 }} />,
    tabIndex: 9,
    product: "Majoituspaikan",
    product_en: "Accommodations",
    product_fi: "Majoituspaikan",
    product_se: "Boendens",
    additionalFields: ADDITIONAL_FIELDS_ACCOMMODATION,
    contractTemplate: false,
  },
  {
    id: EProductType.SCOOTER,
    group: "recreationalVehicles",
    name: "Skootteri",
    name_en: "Scooters",
    name_fi: "Skootterit",
    name_se: "Mopeder",
    icon: <GiScooter style={{ fontSize: 28 }} />,
    tabIndex: 10,
    product: "Skootterin",
    product_en: "Scooters",
    product_fi: "Skootterin",
    product_se: "Mopeders",
    additionalFields: ADDITIONAL_FIELDS_SCOOTER,
    contractTemplate: false,
  },
  {
    id: EProductType.OTHER,
    name: "Muu",
    name_en: "Others",
    name_fi: "Muut",
    name_se: "Övrig",
    icon: <FaRegQuestionCircle style={{ fontSize: 28 }} />,
    tabIndex: 10,
    product: "Muiden",
    product_en: "Others",
    product_fi: "Muut",
    product_se: "Övrig",
    additionalFields: ADDITIONAL_FIELDS_OTHER,
    contractTemplate: false,
  },
];

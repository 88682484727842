import { Reservation } from "~/graphql/API";
import styles from "./styles.module.scss";
import GridBg from "./components/GridBg";
import TimeCells from "./components/TimeCells";
import ReservationCardVertical from "./components/ReservationCardVertical";

type ReservationsHourlyVerticalProps = { reservations: Reservation[] };

function ReservationsHourlyVertical({
  reservations,
}: ReservationsHourlyVerticalProps) {
  return (
    <div className={styles.root}>
      <TimeCells className={styles.times} />

      <div className={styles.container}>
        <GridBg />

        <div className={styles.reservations}>
          {reservations.map((reservation) => (
            <ReservationCardVertical
              key={reservation.id}
              reservation={reservation}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReservationsHourlyVertical;

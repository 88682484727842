import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Skeleton } from "@mui/material";
import VoucherEditDialog from "./VoucherEditDialog";
import DialogComponent from "./DialogComponent";
import { AppContext } from "../AppContext";
import { useContextFetchVouchers } from "../services/fetchVouchers";

export default function VouchersDialog({ open, onClose, loading }) {
  const { vouchers, vouchersMutate } = useContextFetchVouchers();

  const { user } = useContext(AppContext);
  const disableFields = user?.role === "BUSINESS_ADMIN";
  const [editableVoucher, setEditableVoucher] = useState();

  const createNew = () => {
    setEditableVoucher({});
  };

  return (
    <DialogComponent
      open={open}
      dialogClose={() => onClose()}
      dialogActionText={"Lisää"}
      dialogAction={disableFields ? null : () => createNew()}
      dialogTitle={"Alekoodit"}
    >
      <Table data-cy="vouchersTable">
        <TableHead>
          <TableRow>
            <TableCell>Nimi</TableCell>
            <TableCell>Koodi</TableCell>
            <TableCell sx={{ textAlign: "right" }}>Määrä</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              <RowSkeleton />
              <RowSkeleton />
              <RowSkeleton />
            </>
          ) : (
            (vouchers || []).map((voucher, idx) => {
              return (
                <TableRow
                  key={idx}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => setEditableVoucher(voucher)}
                >
                  <TableCell>{voucher.name}</TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    <code
                      style={{
                        background: "#ccc",
                        padding: 4,
                        borderRadius: 4,
                      }}
                    >
                      {voucher.code}
                    </code>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right" }}>
                    {voucher.amount} %
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {editableVoucher && (
        <VoucherEditDialog
          voucher={editableVoucher}
          vouchers={vouchers}
          onClose={(created) => {
            setEditableVoucher(null);
            if (created) {
              vouchersMutate();
            }
          }}
          disabled={disableFields}
        />
      )}
    </DialogComponent>
  );
}

function RowSkeleton() {
  return (
    <TableRow hover>
      <TableCell>
        <Skeleton width={120} />
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Skeleton width={70} />
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Skeleton width={40} />
      </TableCell>
    </TableRow>
  );
}

import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { ReservationContext } from "../../contextProviders/ReservationContext";
import { reservationFields, translatedFields } from "../../formik/fields";
import OrderSummary from "./components/OrderSummary";
import ScheduleInfo from "./components/ScheduleInfo";

export default function ReservationOverview() {
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={mobileView ? "column" : "row"}
      width={"100%"}
      justifyContent={mobileView ? undefined : "space-between"}
      marginTop={"20px"}
      height={"100%"}
      spacing={2}
    >
      <Box width={mobileView ? "100%" : "60%"} paddingBottom={"26px"}>
        <InfoRow field={reservationFields.shortId} />
        <ScheduleInfo />
        <InfoRow field={reservationFields.name} />
        <InfoRow field={reservationFields?.phone} />
        <InfoRow field={reservationFields?.email} />
        <Box sx={{ marginY: "10px" }} />
        <InfoRow field={reservationFields?.companyName} />
        <InfoRow field={reservationFields?.companyBusinessId} />
        <InfoRow field={reservationFields?.companyBillingRef} />
        <Box sx={{ marginY: "10px" }} />
        <InfoRow field={reservationFields?.externalNotes} />
      </Box>
      <Box width={mobileView ? "100%" : "40%"}>
        <OrderSummary />
      </Box>
    </Stack>
  );
}

function InfoRow(props) {
  const { field } = props;
  const { selectedLanguage } = useContext(ReservationContext);
  const { values } = useFormikContext();
  const mobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Stack direction={mobileView ? "column" : "row"}>
      <Box sx={{ width: "200px" }}>
        <Typography sx={{ color: "grey" }}>
          {translatedFields[selectedLanguage][field]}:{" "}
        </Typography>
      </Box>
      <Typography>{values[field]}</Typography>
    </Stack>
  );
}

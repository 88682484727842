import useSWR from "swr";
import { API, graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import { getInvoice, invoiceByReservation } from "../graphql/queries";
import captureError from "../utils/capture-error";

const fetchInvoices = async (reservationId, invoiceIds) => {
  try {
    // Fetch Per-Reservation Invoices
    const invoicesPromise = listAll(
      graphqlOperation(invoiceByReservation, {
        reservationId: reservationId,
      }),
      "invoiceByReservation"
    );

    // Fetch Consolidated invoices linked to this Reservation
    const getInvoicePromises = invoiceIds?.length
      ? Promise.all(
          invoiceIds.map((id) => {
            return API.graphql(graphqlOperation(getInvoice, { id: id }));
          })
        )
      : Promise.resolve([]);

    // Let the promises execute in parallel
    const invoices = await invoicesPromise;
    const consolidatedInvoices = (await getInvoicePromises).map(
      (result) => result.data.getInvoice
    );
    return [...invoices, ...consolidatedInvoices];
  } catch (e) {
    console.error("Get invoices failed", "GET_INVOICES_FAILED", e);
    captureError("Get invoices failed", "GET_INVOICES_FAILED", e);
    return [];
  }
};

export function useFetchInvoices(reservationId, featureInvoicing, invoiceIds) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    featureInvoicing && (reservationId || invoiceIds)
      ? ["fetchInvoices", reservationId, invoiceIds]
      : null,
    () => fetchInvoices(reservationId, invoiceIds),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  return {
    invoices: data,
    invoicesLoading: isLoading,
    invoicesValidating: isValidating,
    invoicesError: error,
    invoicesMutate: mutate,
  };
}

// @ts-ignore
import DialogComponent from "~/components/DialogComponent";
import { Box } from "@mui/material";
import { Invoice } from "~/graphql/API";
import CustomerDetails from "./components/CustomerDetails";
import InvoiceDetails from "./components/InvoiceDetails";
import InvoiceTotal from "./components/InvoiceTotal";
import InvoiceItems from "./components/InvoiceItems";
import React from "react";

type ViewInvoiceProps = {
  invoice: Invoice;
  setSelectedInvoice: React.Dispatch<React.SetStateAction<Invoice | null>>;
};

function ViewInvoice({ invoice, setSelectedInvoice }: ViewInvoiceProps) {
  return (
    <DialogComponent
      open
      dialogClose={() => setSelectedInvoice(null)}
      dialogTitle="Lasku"
      maxWidth="lg"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          padding: "10px",
        }}
      >
        <CustomerDetails invoice={invoice} />

        <InvoiceItems invoice={invoice} />

        <InvoiceTotal invoice={invoice} />

        <InvoiceDetails invoice={invoice} />
      </Box>
    </DialogComponent>
  );
}

export default ViewInvoice;

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { AppContext } from "../../AppContext";
import ReservationDialogNew from "./Reservation";
import { useFetchReservations } from "../../services/fetchReservations";
import { nextFullHour } from "./common/utils";
import { getZonedTime } from "../../utils/common";
import { centsToLocalString, parseToCents } from "../../shared/money";
import { Cache } from "aws-amplify";
import {
  enableContractCustomers,
  enableWildCardReservations,
} from "../../features";
import { parsePhoneNumber } from "libphonenumber-js";
import { useCategories } from "~/queries/useCategories";
import { customers } from "./admin/HanselCustomers";
import { externalUserHanselClasses } from "./admin/hanselClasses";
import { useFetchVehicles } from "../../services/fetchVehicles";

import { useGetOfferPrices } from "./useGetOfferPrice";
import { useFetchCompanies } from "../../services/fetchCompanies";
import { useAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";
const ReservationContext = createContext();

const ReservationDataProvider = () => {
  const { user, business, timezone, selectedView, USER_ADMIN_CORPORATE } =
    useContext(AppContext);
  const [editReservationData, setEditReservationData] =
    useAtom(reservationDataAtom);

  const reservationData = editReservationData;
  const [submitting, setSubmitting] = useState(false);

  const queryMode =
    selectedView === "daily" ? "startOrEndBetween" : "existBetween";
  const { companies } = useFetchCompanies(user?.group, business?.id);
  const { vehicleData: allVehicles } = useFetchVehicles();
  const { reservationsMutate } = useFetchReservations(queryMode);
  const [disableCategoryDefaultsStacking, setDisableCategoryDefaultsStacking] =
    useState(true);
  const [selectedBusiness, setSelectedBusiness] = useState(business?.id);
  const categories = useCategories();

  const [adaptiveView, setAdaptiveView] = useState(false);
  const [selectedContractVehicleClass, setSelectedContractVehicleClass] =
    useState(() => {
      if (reservationData?.pricingCategoryId) {
        return reservationData?.pricingCategoryId;
      }
      if (reservationData?.insuranceData) {
        if (
          !reservationData?.reservationVehicles ||
          reservationData?.reservationVehicles?.length === 0
        ) {
          const excludedCategories = [
            "AVOKÄR1",
            "AVOKÄR2",
            "Alumiiniramppi",
            "AAA - tarkista autoluokka",
          ];
          const suggestedCategory = categories.find(
            (c) =>
              c.name.startsWith(
                reservationData?.insuranceData?.replacementVehicleClass
              ) && !excludedCategories.includes(c?.name)
          );
          return suggestedCategory?.id;
        } else {
          return (
            categories.find(
              (c) =>
                c.name === reservationData?.reservationVehicles[0]?.categoryName
            )?.id ?? ""
          );
        }
      }
      if (reservationData?.hanselData) {
        return externalUserHanselClasses.find(
          (hanselClass) =>
            hanselClass.class === reservationData?.initialClassInfo?.hanselClass
        )?.contractVehicleClass;
      }
    });

  const insuranceCompanies = useMemo(() => {
    if (!user?.externalBusinessByOrg?.items) return [];
    // If user belongs to ExternalBusiness, return corresponding insuranceRegionalCompanies
    if (user?.externalBusiness?.insuranceRegionalCompanies) {
      return user.externalBusiness.insuranceRegionalCompanies.map(
        (company) => ({ ...company, groupName: user.externalBusiness.name })
      );
    }
    //  Return insuranceRegionalCompanies from all ExternalBusinesses
    const externalBusinesses = user.externalBusinessByOrg.items;
    const companies = externalBusinesses
      .filter((extBiz) => extBiz.insuranceRegionalCompanies)
      .flatMap((extBiz) => {
        return extBiz.insuranceRegionalCompanies.map((company) => {
          return {
            ...company,
            groupName: extBiz.name,
          };
        });
      });
    return companies;
  }, [user]);
  const [queryCompanies, setQueryCompanies] = useState(null);

  useEffect(() => {
    if (
      (companies?.length > 0 && queryCompanies?.length === 0) ||
      !queryCompanies
    ) {
      setQueryCompanies(companies.map((company) => company?.id));
    }
  }, [companies, queryCompanies]);

  const [alternativePickupLocation, setAlternativePickupLocation] =
    useState(false);
  const [startTime, setStartTime] = useState(() => {
    // set default times for regular reservations
    // this ignores external reservations and doesnt suggest time for them
    if (!reservationData && !user?.externalBusinessId) {
      return nextFullHour(getZonedTime(new Date(), timezone));
    }
    if (reservationData?.startTime) {
      return new Date(reservationData?.startTime);
    }
    return null;
  });
  const [returnTime, setReturnTime] = useState(() => {
    if (!reservationData && !user?.externalBusinessId) {
      return nextFullHour(startTime);
    }
    if (reservationData?.returnTime) {
      return new Date(reservationData?.returnTime);
    }
    return null;
  });
  const [endTime, setEndTime] = useState(() => {
    if (!reservationData && !user?.externalBusinessId) {
      return returnTime;
    }
    if (reservationData?.endTime) {
      return new Date(reservationData?.endTime);
    }
    return null;
  });
  // queryResource consists of additional data for fetching offers

  const [queryResource, setQueryResource] = useState(() => {
    return {
      group: user?.group,
      orgBusinessId: business?.id,
      extBusinessId:
        user?.externalBusinessId ?? reservationData?.orgExternalBusinessId,
    };
  });

  const disableDialogAction = useMemo(() => {
    if (USER_ADMIN_CORPORATE && reservationData?.id) {
      return true;
    }
    if (submitting) {
      return true;
    }
    return false;
  }, [reservationData, USER_ADMIN_CORPORATE, submitting]);

  const FEATURE_ENABLE_CONTRACT_CUSTOMER = useMemo(() => {
    return enableContractCustomers(user);
  }, [user]);

  const FEATURE_ENABLE_WILDCARD_RESERVATIONS = useMemo(() => {
    return enableWildCardReservations(user?.organizationId);
  }, [user]);

  // apply the product data from the previously created reservation
  const initialSelectedOffers = () => {
    let offers = [];

    // Standard reservation vehicles processing
    if (reservationData?.reservationVehicles?.length > 0) {
      const standardOffers = reservationData.reservationVehicles.map(
        (vehicle, index) => {
          const vehicleData = allVehicles?.find((v) => v.id === vehicle.id);
          return {
            ...vehicle,
            id: vehicle?.id ? vehicle?.id : `no-vehicle-id${index}`,
            availability: true,
            companyId: vehicle?.companyId ?? vehicleData?.companyId,
            modifiedPrice: vehicle?.price
              ? centsToLocalString(vehicle?.price)
              : null,
            defaultPrice: vehicle?.price
              ? centsToLocalString(vehicle?.price)
              : null,
            categoryName: vehicle?.categoryName ?? vehicleData?.category?.name,
            damages: vehicle?.damages ?? vehicleData?.damages,
            deductible: vehicle?.deductible ?? vehicleData?.deductible,
            modifiedDeductible: vehicle?.deductible
              ? centsToLocalString(vehicle?.deductible)
              : "",
            extraInfo: vehicle?.extraInfo ?? vehicleData?.extraInfo,
            hideExport: vehicle?.hideExport ?? vehicleData?.hideExport,
            includedDistance:
              vehicle?.includedDistance ?? vehicleData?.includedDistance,
            mileage: vehicle?.mileage ?? vehicleData?.mileage,
            name: vehicle?.name ?? vehicleData?.name,
            powerType: vehicle?.powerType ?? vehicleData?.powerType,
            registrationPlate:
              vehicle?.registrationPlate ?? vehicleData?.registrationPlate,
          };
        }
      );
      offers = offers.concat(standardOffers);
    }

    // Wildcard vehicles processing
    if (reservationData?.wildCardVehicles?.length > 0) {
      const wildcardOffers = reservationData.wildCardVehicles.map(
        (vehicle, index) => {
          return {
            id: vehicle?.categoryId || `wildcard-id${index}`,
            availability: true,
            companyId: null,
            modifiedPrice: vehicle?.price
              ? centsToLocalString(vehicle?.price)
              : null,
            defaultPrice: vehicle?.price
              ? centsToLocalString(vehicle?.price)
              : null,
            categoryName: null, // Category is not directly available, might need a lookup or another method to determine
            damages: null,
            deductible: vehicle?.deductible,
            modifiedDeductible: vehicle?.deductible
              ? centsToLocalString(vehicle?.deductible)
              : "",
            extraInfo: null,
            hideExport: false, // Assuming it's not needed to hide exports
            includedDistance: null,
            mileage: null,
            name: vehicle?.name,
            powerType: null,
            registrationPlate: null,
          };
        }
      );
      offers = offers.concat(wildcardOffers);
    }

    return offers.length > 0 ? offers : [];
  };

  // apply the additional service data from the previously created reservation
  const initialSelectedServices = () => {
    if (!reservationData?.additionalServices) {
      return [];
    }
    return (
      reservationData?.additionalServices.map((service) => {
        return {
          ...service,
          modifiedPrice: centsToLocalString(service?.price), // this is price, defined by the user
          defaultPrice: centsToLocalString(service?.price),
        };
      }) ?? []
    );
  };

  // consists selected products of the reservation
  const [selectedOffers, updateSelectedOffers] = useReducer(
    OfferReducer,
    initialSelectedOffers()
  );

  // consists selected additional services of the reservation
  const [selectedServiceOffers, updateSelectedServiceOffers] = useReducer(
    serviceReducer,
    initialSelectedServices()
  );

  // Function to add a new additional service for the reservation
  const addNewServiceOffer = (offer) => {
    updateSelectedServiceOffers({ type: "addNew", offer });
  };

  // Function to remove an additional service by the product id
  const removeServiceOffer = (offerId) => {
    updateSelectedServiceOffers({ type: "remove", offerId });
  };

  // Function to modify the price of an additional service
  const modifyServiceOfferPrice = (offerId, newPrice) => {
    updateSelectedServiceOffers({ type: "modifyPrice", offerId, newPrice });
  };

  // Function to modify the quantity of an additional service
  const modifyServiceOfferQuantity = (offerId, newQuantity) => {
    updateSelectedServiceOffers({
      type: "modifyQuantity",
      offerId,
      newQuantity,
    });
  };

  // Function to add a new product for the reservation
  const addNewOffer = async (offers) => {
    return updateSelectedOffers({ type: "addNew", offers });
  };

  // Function to remove a product by offerId
  const removeOffer = (offerId) => {
    updateSelectedOffers({ type: "remove", offerId });
  };

  // Function to modify the price of an offer
  const modifyOfferPrice = (offerId, newPrice) => {
    updateSelectedOffers({ type: "modifyPrice", offerId, newPrice });
  };

  const modifyOfferDeductible = (offerId, newPrice) => {
    updateSelectedOffers({ type: "modifyDeductible", offerId, newPrice });
  };

  // Function to modify the price of an offer
  const modifyOfferListingPrice = (offerId, newPrice) => {
    updateSelectedOffers({ type: "modifyListingPrice", offerId, newPrice });
  };

  // Function to modify the quantity of an additional service
  const modifyOfferQuantity = (offerId, newQuantity) => {
    updateSelectedOffers({
      type: "modifyQuantity",
      offerId,
      newQuantity,
    });
  };

  // Function to modify the start mileage of the selected product
  const modifyStartMileage = (offerId, newMileage) => {
    updateSelectedOffers({ type: "modifyStartMileage", offerId, newMileage });
  };

  // Function to modify the return mileage of the selected product
  const modifyEndMileage = (offerId, newMileage) => {
    updateSelectedOffers({ type: "modifyEndMileage", offerId, newMileage });
  };

  const [vehicleIds, setVehicleIds] = useState(
    reservationData?.reservationVehicles?.map((vehicle) => vehicle?.id) ?? []
  );
  const { offerPrices, offerPricesLoading } = useGetOfferPrices(
    startTime,
    returnTime,
    queryResource,
    vehicleIds,
    selectedContractVehicleClass
  );
  useEffect(() => {
    if (offerPrices && !offerPricesLoading && vehicleIds) {
      vehicleIds.forEach((vehicle) => {
        const priceObj = offerPrices.find((offer) => offer.id === vehicle);
        const getPrice = priceObj?.listingPrice;

        modifyOfferListingPrice(vehicle, getPrice);
      });
    }
  }, [offerPrices, vehicleIds, offerPricesLoading]);

  const totalPrice = useMemo(() => {
    if (!selectedOffers) return null;
    const calcOfferPrices = selectedOffers.reduce(
      (accumulator, currentProduct) => {
        // price = user modified price, listingPrice = default offer price
        const getPrice = () => {
          if (
            currentProduct?.modifiedPrice === "" ||
            !currentProduct?.modifiedPrice
          ) {
            return currentProduct?.listingPrice ?? currentProduct?.price;
          } else {
            return parseToCents(currentProduct?.modifiedPrice);
          }
        };

        return accumulator + parseInt(getPrice());
      },
      0
    );

    const calcServicePrices = selectedServiceOffers.reduce(
      (accumulator, currentProduct) => {
        // price = user modified price, listingPrice = default offer price
        const offerPrice =
          parseToCents(currentProduct?.modifiedPrice) ?? currentProduct?.price;
        return accumulator + offerPrice;
      },
      0
    );

    return calcOfferPrices + calcServicePrices;
  }, [selectedOffers, selectedServiceOffers /* offerPrices */]);

  const initializeFormikData = useGetInitialFormikData(
    reservationData,
    insuranceCompanies
  );

  const closeDialog = (success, disableRevalidate) => {
    if (!disableRevalidate && success) {
      // Local state is updated instantly after Upsert or Deletion,
      // as OpenSearch may have not updated yet at this point
      reservationsMutate();
    }
    setEditReservationData(false);
  };

  const vehicleClasses = [
    { type: "A", id: 1 },
    { type: "B", id: 35 },
    { type: "C", id: 3 },
    { type: "C automaatti", id: 19 },
    { type: "C farmari", id: 40 },
    { type: "P", id: 13 },
    { type: "X", id: 53 },
    //{ type: "X automaatti", id: 7 },
  ];

  const vehicleClassInternal = [
    { type: "A", id: 1 },
    { type: "B", id: 35 },
    { type: "C", id: 3 },
    { type: "P", id: 13 },
    { type: "X", id: 53 },
    //{ type: "X automaatti", id: 7 },
  ];

  useEffect(() => {
    const getDisplayStyle = async () => {
      const cachedDataAdativeView = await Cache.getItem("adaptiveView");
      if (cachedDataAdativeView) {
        setAdaptiveView(cachedDataAdativeView);
      }
      return false;
    };
    getDisplayStyle();
  }, []);

  return (
    <ReservationContext.Provider
      value={{
        adaptiveView,
        addNewOffer,
        addNewServiceOffer,
        alternativePickupLocation,
        closeDialog,
        disableCategoryDefaultsStacking,
        disableDialogAction,
        endTime,
        FEATURE_ENABLE_CONTRACT_CUSTOMER,
        FEATURE_ENABLE_WILDCARD_RESERVATIONS,
        insuranceCompanies,
        modifyOfferDeductible,
        modifyEndMileage,
        modifyOfferListingPrice,
        modifyOfferPrice,
        modifyOfferQuantity,
        modifyServiceOfferPrice,
        modifyServiceOfferQuantity,
        modifyStartMileage,
        queryCompanies,
        queryResource,
        removeOffer,
        removeServiceOffer,
        reservationData,
        returnTime,
        selectedBusiness,
        selectedContractVehicleClass,
        selectedOffers,
        selectedServiceOffers,
        setAdaptiveView,
        setAlternativePickupLocation,
        setDisableCategoryDefaultsStacking,
        setEndTime,
        setQueryCompanies,
        setQueryResource,
        setReturnTime,
        setSelectedBusiness,
        setSelectedContractVehicleClass,
        setStartTime,
        setSubmitting,
        setVehicleIds,
        startTime,
        submitting,
        totalPrice,
        vehicleClasses,
        vehicleClassInternal,
        vehicleIds,
      }}
    >
      <ReservationDialogNew
        setEditReservationData={setEditReservationData}
        open
        reservationData={reservationData}
        onClose={closeDialog}
        initializeFormikData={initializeFormikData}
        adaptiveView={adaptiveView}
        setAdaptiveView={setAdaptiveView}
      />
    </ReservationContext.Provider>
  );
};

const useReservationContext = () => {
  return useContext(ReservationContext);
};

export { ReservationDataProvider, useReservationContext };

const useGetCompleteBefore = () => {
  const { company } = useContext(AppContext);
  const expirationHours = company?.reservationLinkExpirationHours || 1;
  const expirationMs = expirationHours * 3600000;

  return new Date(new Date().getTime() + expirationMs);
};

const useGetInitialFormikData = (reservationData, insuranceCompanies) => {
  const {
    user,
    company,
    USER_ADMIN_INSURANCE,
    USER_ADMIN_CORPORATE,
    timezone,
  } = useContext(AppContext);

  const completeBefore = useGetCompleteBefore(); // Now + hour
  const INITIAL_FORM_DATA = useMemo(() => {
    const getStartTime = () => {
      if (!reservationData && !user?.externalBusinessId) {
        return nextFullHour(getZonedTime(new Date(), timezone));
      }
      if (reservationData?.startTime) {
        return new Date(reservationData?.startTime);
      }
      return null;
    };
    const getReturnTime = () => {
      if (!reservationData && !user?.externalBusinessId) {
        return nextFullHour(getStartTime());
      }
      if (reservationData?.returnTime) {
        return new Date(reservationData?.returnTime);
      }
      return null;
    };
    const getEndTime = () => {
      if (!reservationData && !user?.externalBusinessId) {
        return getReturnTime();
      }
      if (reservationData?.endTime) {
        return new Date(reservationData?.endTime);
      }
      return null;
    };

    return {
      vehicleIds: [],
      startTime: getStartTime(),
      returnTime: getReturnTime(),
      endTime: getEndTime(),
      completeBefore: completeBefore, // Now + hour
      name: "",
      address: "",
      postalCode: "",
      city: "",
      phone: "",
      email: "",
      ssn: "",
      internalNotes: "",
      rentalNotes: "",
      differentDriver: "",
      driverName: "",
      driverSSN: "",
      driverAddress: "",
      driverPostalCode: "",
      driverCity: "",
      driverPhone: "",
      driverEmail: "",
      customerId: "",
      additionalDriver: false,
      additionalDriverName: "",
      additionalDriverPhone: "",
      sendStatusToCustomer: false,
      disableConfirmationEmail: false,
    };
  }, [completeBefore, reservationData, timezone, user?.externalBusinessId]);

  const initializeFormikData = useMemo(() => {
    const vehicleIds = reservationData?.reservationVehicles
      ? reservationData?.reservationVehicles.map(
          (v, index) => v?.id ?? `no-vehicle-id${index}`
        )
      : [];
    const additionalServiceKeys = reservationData?.additionalServices
      ? reservationData.additionalServices.map((service) => service.key)
      : [];
    const values = {
      ...INITIAL_FORM_DATA,
      ...reservationData,
      additionalServiceKeys: additionalServiceKeys,
      additionalServiceData: reservationData?.additionalServices ?? [],
      vehicle: reservationData?.vehicle?.id ?? "",
      vehicleIds: vehicleIds,
      // Use reservation's enableRegistrationPlate if it exists, if it doesn't use company's
      enableRegistrationPlate:
        typeof reservationData?.enableRegistrationPlate === "boolean"
          ? reservationData?.enableRegistrationPlate
          : typeof company?.enableRegistrationPlate === "boolean"
          ? company?.enableRegistrationPlate
          : true,
      disablePricesInPrintouts:
        reservationData?.disablePricesInPrintouts ?? false,
    };
    if (reservationData?.id) {
      // Make sure Checkbox values are Booleans
      values.additionalDriver = !!reservationData?.additionalDriver;
      values.isCompany = !!reservationData?.isCompany;
      values.isBroker = !!reservationData?.customerBrokerId;
      values.billPayment = !!reservationData?.billPayment;
      values.startLocation = reservationData?.startLocation ?? "";
      values.startLocationID = reservationData?.startLocationID ?? "";
      values.returnLocation = reservationData?.returnLocation ?? "";
      values.returnLocationID = reservationData?.returnLocationID ?? "";
    }

    if (reservationData?.wildCardVehicles?.length > 0) {
      values.selectedVehicleClass =
        reservationData?.wildCardVehicles[0]?.categoryId;
      values.wildCardVehicles = reservationData?.wildCardVehicles;
      values.enableWildCard = true;
      values.initialClassInfo = reservationData?.initialClassInfo;
      delete values.initialClassInfo?.__typename;
    }

    // Add insurance claim initial data
    if (USER_ADMIN_INSURANCE) {
      const insuranceData = {
        customerContact:
          reservationData?.insuranceData?.customerContact ?? false, // Kontaktoitu
        urgent: reservationData?.insuranceData?.urgent ?? false, // Kiireellinen
        customerCarModel:
          reservationData?.insuranceData?.customerCarModel ?? "", //Asiakkaan auton malli
        customerCarMaker:
          reservationData?.insuranceData?.customerCarMaker ?? "", // Asiakkaan auton valmistaja
        product: reservationData?.insuranceData?.product ?? "", // Vakuutustuote
        coveragePercentage:
          reservationData?.insuranceData?.coveragePercentage ?? 0, // Korvausaste
        compensatableDays:
          reservationData?.insuranceData?.compensatableDays ?? "", // Enimmäiskorvausaika
        billingPermitCode:
          reservationData?.insuranceData?.billingPermitCode ?? "", // Laskutuslupatunnus
        coverageDuration: reservationData?.insuranceData?.coverageDuration ?? 0, // Vakuutuksen voimassaoloaika
        providerCompany: reservationData?.insuranceData?.providerCompany ?? "", // Vakuutusyhtiö (maksaja)
        providerBusinessId:
          reservationData?.insuranceData?.providerBusinessId ?? "", // Vakuutusyhtiö (maksaja)
        claimAdjusterEmail:
          reservationData?.insuranceData?.claimAdjusterEmail ?? user?.email, // Korvauskäsittelijän sähköposti
        claimAdjusterName:
          reservationData?.insuranceData?.claimAdjusterName ?? user?.name, // Korvauskäsittelijän nimi
        receipt: reservationData?.insuranceData?.receipt ?? false, // Haluan kuittauksen
        replacementVehicleClass:
          reservationData?.insuranceData?.replacementVehicleClass ?? "", // Korvattava autoluokka
        claimId: reservationData?.insuranceData?.claimId ?? "", // Vahinkonumero
        vatDeductible: reservationData?.insuranceData?.vatDeductible ?? false, // ALV -vähennettävä
      };
      values.insuranceData = insuranceData;

      // TODO: Do we need to do this separately here? Doesn't this stuff get initialized already at ...data up there'
      values.orgBusinessId = reservationData?.orgBusinessId ?? null;
      values.organizationId = reservationData?.organizationId ?? null;
      values.externalBusinessId =
        reservationData?.externalBusinessId ?? user?.externalBusinessId;
    }
    // Initialize insuranceCompany field
    if (reservationData?.insuranceData?.providerBusinessId) {
      values.insuranceCompany =
        insuranceCompanies?.find(
          (insuranceCompany) =>
            insuranceCompany.businessId ===
            reservationData?.insuranceData?.providerBusinessId
        ) ?? null;
    }

    // Set default values of external business users
    if (USER_ADMIN_CORPORATE) {
      values.enableWildCard = true;
    }

    //Initialize Hansel data into Formik
    if (reservationData?.hanselData) {
      values.hanselData = reservationData.hanselData;
    }
    // Initialize additional services from data into Formik
    if (reservationData?.additionalServices) {
      reservationData.additionalServices.forEach((service) => {
        values["servicePrice-" + service.key] = centsToLocalString(
          service.price
        );
      });
    }

    // Apply type HANSEL to reservation if external business useHansel is active
    if (user?.externalBusiness?.hanselId) {
      const customerId = user?.externalBusiness?.hanselId;
      const companyData = customers.find((c) => c.id === customerId);

      values.type = "HANSEL";
      values.isCompany = true;
      values.companyName = companyData?.name;
      values.companyBusinessId = companyData?.VAT;

      values.hanselData = {
        contractNro: "6004",
        customerId: customerId,
        /*           subContractorNumber: String # Raportoitavan alihankkijan nro
          subContractorName: String # Raportoitavan alihankkijan nimi
          suplierProductId: String # Toimittajan tuotenro
          suplierProductName: String # Toimittajan tuotenimi/selite
          quantity: String # Kappalemäärä
          price: String # Kappalehinta alv 0 %
          totalPrice: String # Laskutetun tuotteen euromäärä yhteensä
          priceNoVAT: 0,
          additionalInfo1:
          additionalInfo2:
          additionalInfo3: 3
          desc:
 */
      };
    }

    if (!reservationData?.paymentStatus) {
      values.paymentMethod = "none";
    } else {
      values.paymentMethod = reservationData.paymentStatus;
    }

    if (reservationData?.phone) {
      let parsedPhoneNumber;
      try {
        // parse the phonenumber to get the phone country code for previously created reservations
        parsedPhoneNumber = parsePhoneNumber(reservationData.phone);
      } catch (error) {
        /*   console.error(
          "Error parsing phone number:",
          reservationData.phone,
          error.message,
        ); */
      }

      values.phone = parsedPhoneNumber?.nationalNumber ?? reservationData.phone;
      values.phoneCountryCode = parsedPhoneNumber
        ? `+${parsedPhoneNumber?.countryCallingCode}`
        : "+358";
    }

    if (reservationData?.driverPhone) {
      let parsedPhoneNumber;
      try {
        // parse the phonenumber to get the phone country code for previously created reservations
        parsedPhoneNumber = parsePhoneNumber(reservationData.driverPhone);
      } catch (error) {
        // console.error("Error parsing phone number:", error.message);
      }
      values.driverPhone =
        parsedPhoneNumber?.nationalNumber ?? reservationData?.driverPhone;
      values.driverPhoneCountryCode =
        `+${parsedPhoneNumber?.countryCallingCode}` ?? "+358";
    }

    return values;
  }, [
    reservationData,
    user,
    company,
    USER_ADMIN_INSURANCE,
    USER_ADMIN_CORPORATE,
    INITIAL_FORM_DATA,
  ]);
  return initializeFormikData;
};

// Define reducer function for the selected products
const OfferReducer = (state, action) => {
  switch (action.type) {
    case "addNew":
      // Filter out any new offers that already exist in the state

      if (action?.offers.length === 0) {
        return [];
      } else {
        // Concatenate the new offers to the existing state
        return action.offers;
      }
    case "updateOffers":
      // Filter out any new offers that already exist in the state

      return [action.offerPrices];

    case "remove":
      return state.filter((offer) => offer.id !== action.offerId);
    case "modifyPrice":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, modifiedPrice: action.newPrice };
        }
        return offer;
      });
    case "modifyDeductible":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, modifiedDeductible: action.newPrice };
        }
        return offer;
      });
    case "modifyListingPrice":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, listingPrice: action.newPrice };
        }
        return offer;
      });
    case "modifyStartMileage":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, startMileage: action.newMileage };
        }
        return offer;
      });

    case "modifyEndMileage":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, endMileage: action.newMileage };
        }
        return offer;
      });
    case "modifyQuantity":
      return state.map((offer) => {
        if (offer.id === action.offerId) {
          return { ...offer, quantity: action.newQuantity };
        }
        return offer;
      });

    default:
      return state;
  }
};

// Define reducer function for the selected additional services
const serviceReducer = (state, action) => {
  switch (action.type) {
    case "addNew":
      return [...state, action.offer];
    case "remove":
      return state.filter((offer) => offer.key !== action.offerId);
    case "modifyPrice":
      return state.map((offer) => {
        if (offer.id === action.offerId || offer.key === action.offerId) {
          return { ...offer, modifiedPrice: action.newPrice };
        }
        return offer;
      });

    case "modifyQuantity":
      return state.map((offer) => {
        if (offer.id === action.offerId || offer.key === action.offerId) {
          return { ...offer, quantity: action.newQuantity };
        }
        return offer;
      });
    default:
      return state;
  }
};

import { Form, Formik, useFormikContext } from "formik";
import { useGetInitialFormData } from "../../../formik/hooks/useGetInitialFormData";
import { Button, Stack } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { upsertReservationAdmin } from "../../../../../graphql/mutations";
import { captureError } from "../../../../../utilities";
import { reservationFields } from "../../../formik/fields";
import {
  handleInsuranceData,
  handleReservationFailedSuccessfully,
} from "../../../formik/utils";
import { useNotification } from "../../../../Notification";
import { useMutateLocalReservations } from "../../../hooks/useMutateLocalReservations";
import { useAtom, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useValidateFormikFields } from "../../../formik/validate";
import DeleteReservation from "../../../components/DialogFooter/components/DeleteReservation";

export default function ReservationForm(props) {
  const { children } = props;
  const { user } = useContext(AppContext);
  const [reservationData, setEditReservationData] =
    useAtom(reservationDataAtom);
  const notification = useNotification();
  const initialValues = useGetInitialFormData(reservationData);
  const { mutateLocalReservations } = useMutateLocalReservations();
  const { validate } = useValidateFormikFields();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        try {
          const response = await submit(values, reservationData, user);
          if (response?.error) {
            notification.show("Varauksen luonti epäonnistui", "error");
          }

          if (response?.success) {
            const getNotification = handleReservationFailedSuccessfully(
              response?.data?.code,
              response?.data?.emailConfirmationSuccess,
              response?.data?.reservation?.email
            );
            notification.show(getNotification.message, getNotification.variant);
            await mutateLocalReservations(response?.data?.reservation);
            setEditReservationData(null);
          }
        } catch (err) {
          captureError(
            "Submit insurance_reservation failed",
            "SUBMIT_INSURANCE_RESERVATION_FAILED",
            err
          );
          notification.show("Varauksen luonti epäonnistui", "error");
        }
      }}
      validate={(values) => validate(values)}
    >
      <Form
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        {children}
        <Stack
          sx={{
            justifyContent: "flex-end",
            position: "sticky",
            bottom: 0,
            borderTop: "1px solid lightgrey",
            padding: "10px 0px 4px 0px",
          }}
          direction={"row"}
          spacing={2}
          bgcolor={"white"}
        >
          <DeleteReservation />
          <span style={{ flex: 1 }} />
          <CloseReservation />
          <SubmitReservation />
        </Stack>
      </Form>
    </Formik>
  );
}

const submit = async (values, reservationData, user) => {
  const reservation = {
    [reservationFields.id]: values.id,
    [reservationFields.channel]: reservationData?.channel ?? "DEALER_EXTERNAL",

    /* DEALER FIELDS */
    [reservationFields.group]: values.group,
    [reservationFields.organizationId]: values.organizationId,
    [reservationFields.orgBusinessId]: values.orgBusinessId,
    [reservationFields.orgExternalBusinessId]: user.externalBusinessId,

    [reservationFields.type]: "INSURANCE",
    [reservationFields.status]:
      reservationData?.status ?? "EXTERNAL_PENDING_DEALER",

    [reservationFields.reservationStatusInfo]: values.reservationStatusInfo,

    // INSURANCE
    [reservationFields.insuranceData]: handleInsuranceData(
      values?.insuranceData,
      user
    ),
    [reservationFields.customerCarRegistrationPlate]:
      values.customerCarRegistrationPlate,

    /* CUSTOMER FIELDS */
    [reservationFields.email]: values?.email?.trim(),
    [reservationFields.name]: values.name,
    [reservationFields.address]: values.address,
    [reservationFields.ssn]: values.ssn,
    [reservationFields.phone]: values?.phone,

    /* RESERVATION NOTES */
    [reservationFields.internalNotes]: values.internalNotes,
    [reservationFields.externalNotes]: values.externalNotes,
    [reservationFields.rentalNotes]: values.rentalNotes,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(upsertReservationAdmin, {
        reservation: reservation,
        sendStatusToCustomer: values.sendStatusToCustomer,
        disableConfirmationEmail: values.disableConfirmationEmail,
      })
    );

    return { success: true, data: response.data.upsertReservationAdmin };
  } catch (err) {
    captureError("Upsert reservation failed", "UPSERT_RESERVATION_FAILED", err);
    //   notification.show("Varauksen luonti epäonnistui", "error");
    console.log("UPSERT", err);
    return { success: false, error: err };
  }
};

function SubmitReservation() {
  const { submitForm, isSubmitting, errors } = useFormikContext();
  const notification = useNotification();
  const handleSubmit = () => {
    const activeErrors = Object.values(errors).join(", ");
    if (activeErrors) {
      notification.show(
        `Täytä kaikki pakolliset kentät: ${activeErrors}`,
        "error"
      );
      return;
    } else {
      submitForm();
    }
  };

  return (
    <Button
      variant="contained"
      onClick={() => handleSubmit()}
      disabled={isSubmitting}
      type="submit"
    >
      Tallenna
    </Button>
  );
}

function CloseReservation() {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { isSubmitting } = useFormikContext();
  return (
    <Button
      onClick={() => setEditReservationData(null)}
      disabled={isSubmitting}
    >
      Sulje
    </Button>
  );
}

import { useContext, useState } from "react";

import ReservationForm from "./components/ReservationForm";
import CustomerForm from "./components/CustomerForm";
import { AnimatePresence } from "framer-motion";
import DialogHeader from "./components/DialogHeader";
import { useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../atoms/reservationDataAtom";
import ReservationOverview from "./ReservationOverview";
import { DialogFooter } from "./components/DialogFooter";
import { Box, Button, Stack } from "@mui/material";
import { translatedFields } from "../../formik/fields";
import { ReservationContext } from "../../contextProviders/ReservationContext";
import { useValidateForm } from "../../formik/hooks/useValidateForm";
import { useFormikContext } from "formik";
import DesktopView from "./components/ReservationDetails/views/DesktopView";

export const EFormSteps = {
  selectProduct: "selectProduct",
  customerForm: "customerForm",
};

export default function ReservationFormExternalUsers(props) {
  const [activeStep, setActiveStep] = useState(EFormSteps.selectProduct);
  const reservationData = useAtomValue(reservationDataAtom);
  const [serviceProvider, setServiceProvider] = useState(null);

  if (typeof reservationData === "object") {
    return (
      <ReservationForm>
        <DialogHeader activeStep={activeStep} />
        <ReservationOverview />
        <DialogFooter />
      </ReservationForm>
    );
  } else {
    return (
      <ReservationForm>
        <DialogHeader activeStep={activeStep} />
        <AnimatePresence initial={false}>
          <ActiveStep
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            serviceProvider={serviceProvider}
            setServiceProvider={setServiceProvider}
          />
        </AnimatePresence>
        <CreateReservationFooter
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </ReservationForm>
    );
  }
}

function ActiveStep({
  serviceProvider,
  setServiceProvider,
  activeStep,
  setActiveStep,
}) {
  switch (activeStep) {
    case EFormSteps.selectProduct:
      return (
        <DesktopView
          setActiveStep={setActiveStep}
          serviceProvider={serviceProvider}
          setServiceProvider={setServiceProvider}
        />
      );
    case EFormSteps.customerForm:
      return <CustomerForm />;
    default:
      return null;
  }
}

function CreateReservationFooter({ activeStep, setActiveStep }) {
  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      position={"sticky"}
      bottom={0}
      marginTop="10px"
      zIndex={99}
    >
      <AdvanceToContactInformation
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <AdvanceToReservationDetails
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </Box>
  );
}

function AdvanceToContactInformation({ setActiveStep, activeStep }) {
  const { selectedLanguage } = useContext(ReservationContext);

  if (activeStep === EFormSteps.selectProduct) {
    return (
      <Stack direction={"row"} width={"100%"} justifyContent={"flex-end"}>
        <Button
          onClick={() => setActiveStep(EFormSteps.customerForm)}
          variant="contained"
          color="primary"
          disableElevation
        >
          {translatedFields[selectedLanguage].fillContactInformation}
        </Button>
      </Stack>
    );
  }
}

function AdvanceToReservationDetails({ setActiveStep, activeStep }) {
  const { selectedLanguage } = useContext(ReservationContext);
  if (activeStep === EFormSteps.customerForm) {
    return (
      <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
        <Button onClick={() => setActiveStep(EFormSteps.selectProduct)}>
          {translatedFields[selectedLanguage].back}
        </Button>
        <SubmitForm />
      </Stack>
    );
  }
}

function SubmitForm() {
  const { selectedLanguage } = useContext(ReservationContext);
  const { validate } = useValidateForm();
  const { submitForm, isSubmitting } = useFormikContext();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  return (
    <Button
      type="submit"
      variant="contained"
      color="success"
      disableElevation
      disabled={isSubmitting}
      onClick={async () => {
        const isValid = await validate();
        if (!isValid) return;
        try {
          const result = await submitForm();
          if (result?.success) {
            setEditReservationData(null);
          } else {
            // TODO: handle error
          }
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {translatedFields[selectedLanguage].confirmReservation}
    </Button>
  );
}

import {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import styles from "./styles.module.scss";
import Dates from "./components/Dates";
import { clsx } from "~/utils/clsx";

type DatesRowProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "children"
> & {
  firstCell?: ReactNode;
  append?: ReactNode;
} & Pick<ComponentProps<typeof Dates>, "dates" | "override" | "render">;

function DatesRow({
  dates,
  firstCell,
  render,
  override,
  className,
  onMouseEnter,
  onMouseLeave,
  append,
  ...props
}: DatesRowProps) {
  const { inView, ref } = useInView();
  const [renderAll, setRenderAll] = useState(false);

  const datesProps = override
    ? {
        override: Object.keys(override).length ? override : undefined,
      }
    : { render };

  return (
    <div {...props} ref={ref} className={clsx(styles.root, className)}>
      <div
        className={styles.grid}
        onMouseEnter={(event) => {
          onMouseEnter?.(event);
          setRenderAll(true);
        }}
        onMouseLeave={(event) => {
          onMouseLeave?.(event);
          setRenderAll(false);
        }}
      >
        <div data-cell="first">{firstCell}</div>

        {inView && (
          <Dates dates={dates} renderAll={renderAll} {...datesProps} />
        )}
      </div>

      {append}
    </div>
  );
}

export default DatesRow;

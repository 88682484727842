import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AnnualChart = ({ data }) => {
  // Group the data by month and calculate the average review for each month
  const groupedData = data.reduce((acc, d) => {
    const month = d.Date.getMonth();
    if (!acc[month]) {
      acc[month] = {
        month,
        reviewSum: 0,
        reviewCount: 0,
      };
    }
    acc[month].reviewSum += d.rating;
    acc[month].reviewCount += 1;
    return acc;
  }, {});

  const monthlyAverages = Object.values(groupedData).map((d) => ({
    month: d.month,
    review: (d.reviewSum / d.reviewCount).toFixed(2) || null, // replace 0 with null
  }));

  // Map the month number to its name
  const monthNames = [
    "tammi",
    "helmi",
    "maalis",
    "huhti",
    "touko",
    "kesä",
    "heinä",
    "elo",
    "syys",
    "loka",
    "marras",
    "joulu",
  ];

  // Create an array of objects with each month name and an empty review property
  const allMonthsData = monthNames.map((name, index) => ({
    name,
    review: null,
  }));

  // Merge the monthly averages with the allMonthsData array
  const chartData = allMonthsData.map((monthData, index) => {
    const avgData = monthlyAverages.find((d) => d.month === index);
    if (avgData) {
      monthData.review = avgData.review;
    }
    return monthData;
  });

  return (
    <ResponsiveContainer width="99%" maxHeight={800} aspect={4.0 / 2.0}>
      <LineChart
        responsive={true}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, 10]} />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="review"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="Keskiarvo"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AnnualChart;

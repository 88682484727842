import { useFormikContext } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import { ReservationContext } from "../../../../../../contextProviders/ReservationContext";

export default function InsuranceCompanySelect({ label, size }) {
  const { insuranceCompanies } = useContext(ReservationContext);
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext();

  return (
    <Autocomplete
      disablePortal
      id="combo-box-insurancecompanies"
      options={insuranceCompanies}
      onBlur={handleBlur}
      value={values.insuranceCompany}
      renderInput={(params) => (
        <TextField
          {...params}
          name="insuranceCompany"
          onBlur={handleBlur}
          error={touched["insuranceCompany"] && !!errors["insuranceCompany"]}
          helperText={touched["insuranceCompany"] && errors["insuranceCompany"]}
          label={label ?? "Vakuutusyhtiö"}
          size={size ?? undefined}
        />
      )}
      getOptionLabel={(option) => option.companyName}
      groupBy={(option) => option.groupName}
      isOptionEqualToValue={(option, value) =>
        option.businessId === value.businessId
      }
      onChange={(event, value, reason, details) => {
        setFieldValue("insuranceCompany", value ?? null);
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      data-cy="reservationDialogInsurancePayerSelect"
      fullWidth
    />
  );
}

import React from "react";
import { Typography, List } from "@mui/material";

import { useField } from "formik";
import AdditionalServiceCardTemplate from "../../AdditionalServiceCardTemplate";

export default function AdditionalServiceList({ category, offerPrices }) {
  const [field, meta, helpers] = useField("additionalServices");
  const { value } = meta;
  const selectedServices = value
    ? value?.filter((service) => service.category === category)
    : [];
  return (
    <List dense sx={{ width: { width: "100%", maxWidth: "450px" } }}>
      {selectedServices?.map((service, index) => {
        // Handle if service is "deleted"
        if (!service?.hide && service?.category === category) {
          return (
            <AdditionalServiceCardTemplate
              service={service}
              offerPrices={offerPrices}
              key={service.key}
            />
          );
        }
        return null;
      }) ?? null}
    </List>
  );
}

function EmptyState() {
  let isServices = false;

  return (
    <>
      {isServices ? null : (
        <Typography>Tuotteella ei lisättyjä palveluja</Typography>
      )}
    </>
  );
}

import "../app-theme.css";
import React, { useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { motion } from "framer-motion";
import useGetVehicleObjById from "../CustomHooks/useGetVehicleObjById";

export default function EventsContainer({
  handleOpenReservation,
  getCompanyObjById,

  timelineContainer,
  timelineItem,

  events,
  time,
  eventColor,
  eventIcon,
}) {
  const showEvents = useMemo(() => {
    if (!events) return;
    return events.map((reservation, index) =>
      reservation.reservationVehicles.map((vehicle, idx) => {
        const location = () => {
          if (time === "startTime") {
            if (reservation?.startLocation) {
              return reservation.startLocation;
            } else {
              return getCompanyObjById(reservation.companyId)?.name;
            }
          }

          if (time === "returnTime") {
            if (reservation?.returnLocation) {
              return reservation.returnLocation;
            } else {
              return getCompanyObjById(reservation.companyId)?.name;
            }
          }
        };

        const timeValue = reservation[time];
        return (
          <motion.div
            variants={timelineItem}
            exit={{ opacity: 0 }}
            whileHover={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              cursor: "pointer",
            }}
            key={reservation.id + idx}
            onClick={() => handleOpenReservation(reservation)}
          >
            <TimelineItem>
              <TimelineOppositeContent
                style={{
                  flex: 0.1,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "grey",
                    marginTop: 7,
                  }}
                >
                  {format(timeValue, "HH:mm", {
                    locale: fi,
                  })}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={eventColor} variant="outlined">
                  {eventIcon}
                </TimelineDot>

                {events.length === index + 1 ? null : <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Grid container style={{ paddingBottom: 10 }}>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item container direction="column">
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        style={{
                          borderStyle: "solid",
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderColor: "lightgrey",
                        }}
                      >
                        <VehicleName vehicleId={vehicle?.id} />
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <VehiclePlate vehicleId={vehicle?.id} />
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 14,
                            }}
                          >
                            Asiakas:
                            <span
                              style={{
                                marginLeft: 5 /* fontStyle: "italic" */,
                              }}
                            >
                              {reservation?.name}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{ fontFamily: "Roboto", fontSize: 14 }}
                          >
                            {location()}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </motion.div>
        );
      })
    );
  }, [
    events,
    eventColor,
    eventIcon,
    getCompanyObjById,
    handleOpenReservation,
    time,
    timelineItem,
  ]);

  return (
    <>
      <Grid item container xs={11} style={{ marginTop: 25 }}>
        <Timeline align="left" style={{ margin: 0, padding: 0 }}>
          <motion.div
            key={`eventsContainer`}
            variants={timelineContainer}
            initial="hidden"
            animate="show"
            exit={{ opacity: 0 }}
            style={{
              borderTopWidth: 1,
              borderRadius: 10,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 0,
              borderColor: "lightgrey",
              borderSpacing: 0,
              borderStyle: "solid",
              boxShadow: `0 1px 1px rgba(0,0,0,0.12), 
                        0 2px 2px rgba(0,0,0,0.12), 
                        0 4px 4px rgba(0,0,0,0.12), 
                        0 6px 6px rgba(0,0,0,0.12),
                        0 7px 7px rgba(0,0,0,0.12)`,
              overflow: "auto",
              paddingBottom: 25,
              paddingTop: 25,
              maxHeight: "60vh",
            }}
          >
            {events?.length === 0 && (
              <Typography
                align="center"
                style={{
                  fontFamily: "Roboto",
                  padding: 30,
                }}
              >
                Ei tapahtumia
              </Typography>
            )}

            {showEvents}
          </motion.div>
        </Timeline>
      </Grid>
    </>
  );
}

function VehicleName(props) {
  const { vehicleId } = props;

  return (
    <Typography
      style={{
        fontFamily: "Sofia Pro",
        fontSize: 16,
      }}
    >
      {useGetVehicleObjById(vehicleId)?.name}
    </Typography>
  );
}

function VehiclePlate(props) {
  const { vehicleId } = props;

  return (
    <Typography
      style={{
        fontFamily: "monospace",
        fontWeight: "bold",
        padding: 1,
        fontSize: 14,
      }}
    >
      {useGetVehicleObjById(vehicleId)?.registrationPlate}
    </Typography>
  );
}

import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";

export default function EnableRegistrationPlate() {
  const { values, handleChange, handleBlur } = useFormikContext();

  return (
    <Grid item xs={6} sm={4}>
      <FormControlLabel
        control={
          <Checkbox
            name="enableRegistrationPlate"
            checked={values.enableRegistrationPlate ?? false}
            color="primary"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        }
        label="Rekisterinumero sopimuksella"
      />
    </Grid>
  );
}

import { Language, Reservation, ReservationChannel } from "~/graphql/API";
import { settingsPreviewVehicleData } from "./components/settings-preview-vehicle-data";

export const settingsPreviewReservation: Reservation = {
  id: "settings-preview-reservation",
  shortId: "2410X7",
  language: Language.fi,
  group: null,
  organizationId: "d90a0808-7f16-41af-a374-0244e5fe9df1",
  orgBusinessId: "preview-org-business-id",
  companyId: "76f1a6e7-67a3-4bbb-a677-de7c211ac60c",
  returnCompanyId: null,
  startLocation: "TESTING COMPANY",
  returnLocation: "TESTING COMPANY",
  startLocationID: "76f1a6e7-67a3-4bbb-a677-de7c211ac60c",
  returnLocationID: "76f1a6e7-67a3-4bbb-a677-de7c211ac60c",
  reviewId: null,
  customerBrokerId: null,
  customerCompanyId: null,
  customerPersonalId: null,
  reservationVehicleId: null,
  reservationVehicles: [settingsPreviewVehicleData],
  wildCardVehicles: null,
  createdAt: "2024-10-03T10:32:03.875Z",
  updatedAt: "2024-10-03T10:32:03.875Z",
  startTime: "2024-10-06T11:00:00.000Z",
  returnTime: "2024-10-06T12:00:00.000Z",
  endTime: "2024-10-06T12:00:00.000Z",
  name: "Anssi Asiakas",
  address: "Testaajanpolku 3",
  postalCode: "123567",
  city: "Kuopio",
  phone: "+35844000000",
  email: "anssi.asiakas@rowly.fi",
  billingRef: null,
  ssn: "010101-123A",
  rentalNotes: null,
  internalNotes: null,
  externalNotes: null,
  outOfCommission: null,
  vehiclePrice: null,
  additionalServices: [],
  driverSSN: "010101-123A",
  differentDriver: true,
  driverName: "Kalle Kuljettaja",
  driverAddress: "Kuljettajankatu 1",
  driverPostalCode: "12345",
  driverCity: "Kuljettajankaupunki",
  driverPhone: "+35844000001",
  driverEmail: "lasse.lisäkuljettaja@rowly.fi",
  additionalDriver: true,
  additionalDriverName: "Lasse Lisäkuljettaja",
  additionalDriverPhone: "+35844000001",
  additionalDriverSSN: "010101-123A",
  billPayment: null,
  isCompany: true,
  carStatus: null,
  rentalStatus: null,
  innovoiceStatus: null,
  companyName: 'Oy "Testiyritys" Ab',
  companyBusinessId: "1234567-8",
  companyCity: "Testikaupunki",
  companyPostalCode: "12345",
  companyBillingRef: "123456789",
  companyBillingAddress: "Testikatu 1",
  companyEBillingOperator: "123456789",
  companyEBillingAddress: "123456789",
  paymentToken: null,
  npsStatus: null,
  paymentStatus: null,
  paymentData: {
    paymentTotal: null,
    paymentTodo: null,
    depositPending: null,
    deposit: null,
    __typename: "PaymentData",
  },
  signStatus: null,
  signDocumentId: null,
  signerName: null,
  reviewStatus: null,
  authStatus: null,
  authTime: null,
  completeBefore: null,
  mustSign: null,
  livionData: null,
  tilakoneData: null,
  voucherData: null,
  customerCarRegistrationPlate: null,
  insuranceData: null,
  externalData: null,
  enableRegistrationPlate: false,
  disablePricesInPrintouts: false,
  hanselData: null,
  invoiceIds: null,
  consolidatedInvoiceIds: null,
  channel: ReservationChannel.DEALER_ORGANIZATION,
  originURL: null,
  type: null,
  status: null,
  reservationStatusInfo: {
    deliveryTimestamp: "2024-10-06T11:00:00.000Z",
    deliveryAddress: null,
    deliveryZipcode: null,
    deliveryCity: null,
    deliveryCountry: "Finland",
    deliveryDescription: null,
    returnTimestamp: "2024-10-06T12:00:00.000Z",
    returnAddress: null,
    returnZipcode: null,
    returnCity: null,
    returnCountry: "Finland",
    returnDescription: null,
    cancelReason: null,
    __typename: "ReservationStatusInfo",
  },
  initialCategory: null,
  initialClassInfo: null,
  orgInvoiceCustomer: null,
  pricingCategoryId: null,
  customImportDate: null,
  vehicle: null,
  invoices: null,
  paymentEvents: null,
  __typename: "Reservation",
};

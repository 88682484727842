import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";
import { useTheme } from "@mui/material";

export function useBackgroundColor(reservation: any) {
  const { legacyUser } = useContext<any>(AppContext);

  const theme = useTheme();
  // TODO: Put begind a feature flag. By default use single color for everything
  // like before, and by opting in user can start usin new coloring

  //   if (isTransferData) {
  //     return theme.palette.warning.dark;
  //   }

  // outOfCommission
  if (reservation.outOfCommission) {
    return "#999999";
  }

  if (legacyUser) {
    return "rgb(72, 94, 136)";
  }

  // notRetrievedVehicle
  if (
    reservation?.rentalStatus === "NONE" ||
    reservation?.innovoiceStatus === "NONE"
  ) {
    return theme.palette.error.main;
  }

  // retrievedVehicle
  if (
    reservation?.rentalStatus === "RETRIEVED" ||
    reservation?.innovoiceStatus === "RETRIEVED"
  ) {
    return theme.palette.warning.light;
  }

  // paymentCompleted
  if (
    reservation.paymentStatus === "COMPLETED" ||
    reservation.paymentStatus === "COMPLETED_MANUAL"
  ) {
    return "rgba(0,90,0,1)";
  }

  // returnedVehicle
  if (
    reservation?.rentalStatus === "RETURNED" ||
    reservation?.innovoiceStatus === "RETURNED"
  ) {
    return "rgba(0,175,125,1)";
  }

  if (reservation.type === "HANSEL") {
    return "rgba(150,150,0,1)";
  }

  if (
    (reservation?.rentalStatus === "NONE" &&
      reservation.startTime.getTime() < new Date().getTime()) ||
    (!reservation?.rentalStatus &&
      reservation.startTime.getTime() < new Date().getTime())
  ) {
    return theme.palette.error.main;
  }

  if (new Date().getTime() < reservation.startTime.getTime()) {
    return theme.palette.primary.main;
  }

  // paymentDepositCompleted
  if (reservation.paymentStatus === "COMPLETED_DEPOSIT") {
    return "rgba(0,245,125,1)";
  }

  return "rgba(0,175,125,1)";
}

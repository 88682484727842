import {
  Autocomplete,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useSearchCustomerCompany } from "../../../../../../../hooks/useSearchCustomerCompany";
import { Close } from "@mui/icons-material";

function handleAutocompleteChange(
  newValue,
  billingByCompany,
  setFieldValue,
  clearFields
) {
  if (newValue) {
    Object.entries(EcompanyFields).forEach(([key, field]) => {
      const newFieldValue =
        key === "CUSTOMER_COMPANY_ID" ? newValue.id : newValue[field] || "";
      setFieldValue(field, newFieldValue);
    });

    // Update these fields only if not a broker
    if (billingByCompany) {
      Object.entries(companyBillingFields).forEach(([key, field]) => {
        setFieldValue(field, newValue[field] || "");
      });
    }
  } else {
    clearFields();
  }
}

export default function CompanyCustomerNameField() {
  const { values, setFieldValue, errors, touched } = useFormikContext();
  const { searchCustomerCompany, options, loading } =
    useSearchCustomerCompany();
  const billingByCompany = !values?.isBroker;

  const clearFields = () => {
    Object.values(EcompanyFields).forEach((field) => setFieldValue(field, ""));
    Object.values(companyBillingFields).forEach((field) =>
      setFieldValue(field, "")
    );
  };

  return (
    <Autocomplete
      fullWidth
      options={options}
      loading={loading}
      loadingText="Ladataan..."
      selectOnFocus
      freeSolo
      disableClearable
      disabled={!!values?.customerCompanyId}
      getOptionLabel={(option) => option?.companyName ?? option}
      isOptionEqualToValue={(option, value) =>
        option?.companyName === (value?.companyName ?? value)
      }
      value={values?.companyName ?? ""}
      onChange={(event, newValue, reason) => {
        handleAutocompleteChange(
          newValue,
          billingByCompany,
          setFieldValue,
          clearFields
        );
      }}
      onInputChange={(event, value) => {
        searchCustomerCompany(value, "companyName");
        setFieldValue("companyName", value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Yrityksen nimi"
          variant="outlined"
          required
          error={touched.companyName && !!errors.companyName}
          helperText={touched.companyName && errors.companyName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  !!values.customerCompanyId && (
                    <IconButton onClick={clearFields} edge="end">
                      <Close />
                    </IconButton>
                  )
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id}>
          <ListItemText
            primary={option.companyName}
            secondary={option.companyBusinessId}
          />
        </ListItem>
      )}
    />
  );
}

// General company fields that are common to all
const EcompanyFields = {
  COMPANY_NAME: "companyName",
  COMPANY_BUSINESS_ID: "companyBusinessId",
  COMPANY_CITY: "companyCity",
  COMPANY_POSTAL_CODE: "companyPostalCode",
  CUSTOMER_COMPANY_ID: "customerCompanyId",
  COMPANY_BILLING_REF: "companyBillingRef",
};

// Fields to exclude if `isBroker` is `true`
const companyBillingFields = {
  COMPANY_BILLING_ADDRESS: "companyBillingAddress",
  COMPANY_EBILLING_OPERATOR: "companyEBillingOperator",
  COMPANY_EBILLING_ADDRESS: "companyEBillingAddress",
};

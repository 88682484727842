import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import "../app-theme.css";
import "./EconomyDialog.css";
import { FcRight } from "react-icons/fc";
export default function InfoContainerLarge({
  containerStyle,
  mainValue,
  mainTitle,
  secondaryTitle,
  secondaryValue,
  desc,
  icon,
  loading,
  onClick,
  amountOfServices,
  overviewIcon,
  overview,
}) {
  return (
    <Paper>
      <Box className={containerStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>{icon}</Box>
          <Box
            justifyContent="flex-start"
            sx={{ display: "flex", flex: 1, marginLeft: "10px" }}
          >
            {desc}
          </Box>
        </Stack>
        <Grid
          container
          direction="column"
          style={{ marginLeft: 15, marginTop: 15 }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              opacity: 0.6,
            }}
          >
            {mainTitle}
          </Typography>
          {!loading && (
            <Typography
              variant="h5"
              style={{
                fontFamily: "Sofia Pro",
                fontSize: 32,
              }}
            >
              {mainValue ? <>{mainValue} </> : null}
            </Typography>
          )}
          {loading && <CircularProgress size={35} />}
        </Grid>
        <Grid
          container
          direction="column"
          style={{ marginLeft: 15 }}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {secondaryTitle && (
            <>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  opacity: 0.6,
                }}
              >
                {secondaryTitle}
              </Typography>
              {secondaryTitle && !loading && (
                <Typography
                  variant="h5"
                  style={{
                    fontFamily: "Sofia Pro",
                    fontSize: 32,
                  }}
                >
                  {secondaryValue ? <>{secondaryValue} </> : null}
                </Typography>
              )}
            </>
          )}
          {secondaryTitle && loading && <CircularProgress size={35} />}
        </Grid>
        {!isNaN(amountOfServices) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "90%",
              height: "100%",

              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontFamily: "Sofia Pro",
                fontSize: 70,
              }}
            >
              {amountOfServices}
            </Typography>
          </Box>
        )}
        {amountOfServices && loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "80%",
              height: "100%",

              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {overview && (
        <Box sx={{ position: "relative", height: "50px" }}>
          <Box
            sx={{
              position: "absolute",
              top: -30,
              left: 10,
              height: "60px",
              width: "60px",
              bgcolor: "white",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                bgcolor: "rgba(0,0,0,0.09)",
                height: "90%",
                borderRadius: "50%",
              }}
            >
              {overviewIcon}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IconButton onClick={onClick}>
              <FcRight style={{ fontSize: "24px" }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

import {
  AdditionalServiceReservation,
  Reservation,
  ReservationVehicle,
} from "~/graphql/API";

type GetReservationTotalArgs = Pick<
  Reservation,
  "reservationVehicles" | "additionalServices"
>;

export function getReservationTotal({
  reservationVehicles,
  additionalServices,
}: GetReservationTotalArgs) {
  const calcVehiclePrices = (
    (reservationVehicles || []).filter(Boolean) as ReservationVehicle[]
  ).reduce((accumulator, currentProduct) => {
    // @ts-ignore
    const quantity = currentProduct?.quantity || 1;

    return accumulator + (currentProduct.price || 0) * quantity;
  }, 0);

  const calcServicePrices = (
    (additionalServices || []).filter(Boolean) as AdditionalServiceReservation[]
  ).reduce((accumulator, currentProduct) => {
    const quantity = currentProduct?.quantity || 1;

    return accumulator + currentProduct.price * quantity;
  }, 0);

  return calcVehiclePrices + calcServicePrices;
}

import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import { listBusinessHoursExceptions } from "../graphql/queries";
import captureError from "../utils/capture-error";
import { useContext } from "react";
import { AppContext } from "../AppContext";

// TODO: use by company GSI
const fetch = async (operationName, businessId) => {
  try {
    let filter;
    if (businessId) {
      filter = { orgBusinessId: { eq: businessId } };
    }
    const businessHoursExceptions = await listAll(
      graphqlOperation(listBusinessHoursExceptions, { filter: filter }),
      operationName
    );
    return businessHoursExceptions;
  } catch (e) {
    console.error(
      "Get business hours exceptions failed",
      "GET_BUSINESS_HOURS_EXCEPTIONS_FAILED",
      e
    );
    captureError(
      "Get business hours exceptions failed",
      "GET_BUSINESS_HOURS_EXCEPTIONS_FAILED",
      e
    );
  }
};

export function useFetchSpecialHours(group, businessId) {
  const operationName = "listBusinessHoursExceptions";
  const condition = group ?? businessId;
  const { data, error, isLoading, mutate } = useSWR(
    condition ? ["businessHoursExceptions", condition] : null,
    () => fetch(operationName, businessId),
    {
      revalidateOnFocus: false,
    }
  );

  return {
    specialHours: data,
    specialHoursLoading: isLoading,
    specialHoursError: error,
    specialHoursMutate: mutate,
  };
}

export function useContextFetchSpecialHours() {
  const { group, business } = useContext(AppContext);
  return useFetchSpecialHours(group, business?.id);
}

import { Skeleton, TableCell } from "@mui/material";
import { useFetchPricingsOrganization } from "../../../../../services/fetchPricings";
import { PricingsList } from "../../PricingsList";

export default function SelectActivePricing(props) {
  const { category, pricing, setEditablePricing } = props;

  const { pricingsLoading } = useFetchPricingsOrganization("pricingListDialog");
  const cellWidth = "250px";
  if (pricingsLoading) {
    return (
      <TableCell sx={{ width: cellWidth }}>
        <Skeleton sx={{ height: "50px" }} />
      </TableCell>
    );
  }

  return (
    <TableCell sx={{ width: cellWidth }}>
      <PricingsList
        setEditablePricing={setEditablePricing}
        category={category}
        selectedPricing={pricing}
      />
    </TableCell>
  );
}

import React, { useState, useContext, useEffect } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { API, graphqlOperation } from "aws-amplify";
import { searchReservations as searchReservationsQuery } from "../../../graphql/queries";
import { searchCustomers as searchCustomersQuery } from "../../../graphql/queries";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { FormContent, FormGrid } from "../../common/FormComponents";

import FormTextField from "./FormTextField";
import PhoneNumberField from "../../PhoneNumberField";
import { distinctBy } from "../../../utils/array-utilities";
import { useDebouncedEffect } from "../../../utils/use-debounced-effect";
import { AppContext } from "../../../AppContext";
import { useFormikContext } from "formik";

import { TbEye, TbEyeOff } from "react-icons/tb";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import parsePhoneNumberFromString from "libphonenumber-js";

export default function CustomerInformation(props) {
  const {
    USER_ADMIN_GENERIC,
    USER_ADMIN_INSURANCE,
    USER_ADMIN_CORPORATE,
    user,
    mobileViewPort,
  } = useContext(AppContext);
  const {
    values,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext();

  const theme = useTheme();
  const [brokerLoading, setBrokerLoading] = useState(true);
  const [companyNameLoading, setCompanyNameLoading] = useState(false);
  const [businessIdLoading, setBusinessIdLoading] = useState(false);
  const [personalAutofillSearch, setPersonalAutofillSearch] = useState("");
  const [personalAutofillValues, setPersonalAutofillValues] = useState([]);
  const [brokerName, setBrokerName] = useState("");
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [showAdditionalSSN, setShowAdditionalSSN] = useState(false);
  const [showDriverSSN, setShowDriverSSN] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const [toggleShowCompany, setToggleShowCompany] = useState(true);
  const [toggleShowContact, setToggleShowContact] = useState(true);
  const [toggleShowDriver, setToggleShowDriver] = useState(true);

  const [brokers, setBrokers] = useState([]);
  const [companyAutofillValues, setCompanyAutofillValues] = useState([]);
  const [companyBusinessIdAutofillValues, setCompanyBusinessIdAutofillValues] =
    useState([]);

  const nameAutoCompleteLoading = personalAutofillSearch !== values.name;
  const handleClickShowSSN = () => setShowSSN(!showSSN);
  const handleClickShowDriverSSN = () => setShowDriverSSN(!showDriverSSN);
  const handleClickShowAdditionalSSN = () =>
    setShowAdditionalSSN(!showAdditionalSSN);

  useEffect(() => {
    async function fetchBrokers() {
      setBrokerLoading(true);
      try {
        const filterConditions = {
          clientType: { eq: "BROKER" },
        };

        const brokerData = await API.graphql(
          graphqlOperation(searchCustomersQuery, {
            filter: filterConditions,
          })
        );

        const allBrokers = brokerData.data.searchCustomers.items;
        setBrokers(allBrokers); // Save all brokers to state

        // If editing an existing reservation, find and set the broker even if deleted
        if (values.customerBrokerId) {
          const existingBroker = allBrokers.find(
            (broker) => broker.id === values.customerBrokerId
          );
          if (existingBroker) {
            setSelectedBroker(existingBroker);
            setBrokerName(existingBroker.companyName);
            setFieldValue("brokerCustomer", existingBroker);
          }
        }

        // Filter out deleted brokers for dropdown display purposes
        const activeBrokers = allBrokers.filter((broker) => !broker.isDeleted);
        setBrokers(activeBrokers); // Update the brokers list to active ones for dropdown
      } catch (error) {
        console.error("Error fetching brokers", error);
      } finally {
        setBrokerLoading(false);
      }
    }

    fetchBrokers();
  }, [user.organizationId, user.orgBusinessId, user.role]);

  useEffect(() => {
    if (values.companyName) fetchData(values.companyName, "companyName");
    if (values.companyBusinessId)
      fetchData(values.companyBusinessId, "companyBusinessId");
  }, [values.companyName, values.companyBusinessId]);

  const fetchData = async (searchString, type) => {
    const loaderMap = {
      companyName: () => setCompanyNameLoading(true),
      companyBusinessId: () => setBusinessIdLoading(true),
    };

    const setterMap = {
      companyName: (data) => {
        setCompanyAutofillValues(data);
        setCompanyNameLoading(false);
      },
      companyBusinessId: (data) => {
        setCompanyBusinessIdAutofillValues(data);
        setBusinessIdLoading(false);
      },
    };

    loaderMap[type]();

    const searchField = type;
    let searchFragments = searchString
      .trim()
      .split(" ")
      .map((phrase, index, array) => ({
        [searchField]: {
          [index < array.length - 1 ? "matchPhrase" : "matchPhrasePrefix"]:
            phrase,
        },
      }));

    let userFilters = [];
    if (user.role === "BUSINESS_ADMIN") {
      userFilters.push({
        or: [
          { organizationId: { eq: user.organizationId } },
          { orgBusinessId: { eq: user.orgBusinessId } },
        ],
      });
    } else if (user.role === "ORGANIZATION_ADMIN") {
      userFilters.push({ organizationId: { eq: user.organizationId } });
    }

    const clientTypeFilter = {
      clientType: { eq: "COMPANY" }, // Specify client types when necessary
    };

    const notDeletedFilter = { isDeleted: { ne: true } };

    try {
      const response = await API.graphql(
        graphqlOperation(searchCustomersQuery, {
          filter: {
            and: [
              clientTypeFilter,
              notDeletedFilter,
              ...searchFragments,
              ...userFilters,
            ],
          },
        })
      );
      setterMap[type](response.data.searchCustomers.items);
    } catch (error) {
      console.error(`Error searching ${type}s:`, error);
      setterMap[type]([]); // Handle error by setting empty array for autofill values
    }
  };

  const getPersonalAutofillValues = async (str) => {
    let foundReservations;

    if (str) {
      /*
        Search by all matching all phrases, last one with prefix match
        =>
          Example data: "Firstname Lastname"
          Fill be found by:
            first
            last
            firstname last
            lastname first
          But not by:
            first lastname
      */
      const phrases = str.split(" ").filter((s) => s.length > 0);
      const searchFragments = phrases.map((phrase, pi) => {
        return {
          name:
            pi < phrases.length - 1
              ? {
                  matchPhrase: phrase,
                }
              : {
                  matchPhrasePrefix: phrase,
                },
        };
      });

      const response = await API.graphql(
        graphqlOperation(searchReservationsQuery, {
          filter: {
            and: searchFragments,
          },
          sort: {
            field: "startTime",
            direction: "desc",
          },
        })
      );
      foundReservations = distinctBy(
        response.data.searchReservations.items,
        (r) => r.email.toLowerCase()
      );
    } else {
      foundReservations = [];
    }

    setPersonalAutofillValues(foundReservations);
    setPersonalAutofillSearch(str ?? "");
  };

  useDebouncedEffect(
    () => {
      getPersonalAutofillValues(values.name);
    },
    250,
    [values.name]
  );

  // Debounced effect for company name autofill
  useDebouncedEffect(
    () => {
      if (values.companyName) {
        fetchData(values.companyName, "companyName");
      }
    },
    300,
    [values.companyName]
  );

  // Debounced effect for business ID autofill
  useDebouncedEffect(
    () => {
      if (values.companyBusinessId) {
        fetchData(values.companyBusinessId, "companyBusinessId");
      }
    },
    300,
    [values.companyBusinessId]
  );

  // This is needed atleast for now for the phonenumberfield
  // to work in both the old and new reservation dialog.
  const formik = useFormikContext();

  return (
    <Grid
      item
      lg={12}
      container
      sx={{ flexDirection: "row", justifyContent: "center" }}
    >
      <Grid item xs={12}>
        <FormContent
          title={"Yhteystiedot"}
          visibility={true}
          expanded={toggleShowContact}
          setExpanded={setToggleShowContact}
        >
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <>
              {USER_ADMIN_GENERIC && (
                <FormGrid>
                  <Grid
                    item
                    container
                    sx={{
                      flexDirection: "row",
                    }}
                  >
                    {USER_ADMIN_GENERIC && !values.id && (
                      <FormGrid>
                        <Stack
                          direction={mobileViewPort ? "column" : "row"}
                          width={"100%"}
                          justifyContent={"space-between"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="sendStatusToCustomer"
                                checked={values.sendStatusToCustomer ?? false}
                                color="primary"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            }
                            label="Lähetä asiakkaalle täydennettäväksi"
                          />

                          {values.sendStatusToCustomer && (
                            <Box>
                              <DateTimePicker
                                inputFormat="dd.MM.yyyy HH:mm"
                                ampm={false}
                                disablePast
                                label="Vanhenemisaika"
                                value={values.completeBefore}
                                onChange={async (date) => {
                                  await setFieldValue("completeBefore", date);
                                }}
                                onClose={() =>
                                  setFieldTouched("completeBefore", true)
                                }
                              />
                              <Tooltip title="Asiakkaalle lähetetään linkki, jonka kautta varaus voidaan tehdä loppuun. Mikäli varausta ei suoriteta loppuun ennen määriteltyä aikaa, se poistetaan. ">
                                <InfoIcon
                                  color="info"
                                  sx={{ cursor: "pointer" }}
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </Stack>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="disableConfirmationEmail"
                              checked={values.disableConfirmationEmail}
                              color="primary"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          }
                          label="Älä lähetä varausvahvistusta"
                        />
                      </FormGrid>
                    )}

                    <FormGrid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="enableRegistrationPlate"
                            checked={values.enableRegistrationPlate ?? false}
                            color="primary"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Rekisterinumero sopimuksella"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="disablePricesInPrintouts"
                            checked={values.disablePricesInPrintouts ?? false}
                            color="primary"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Poista hinta sopimukselta sekä varausvahvistukselta"
                      />
                    </FormGrid>
                  </Grid>
                </FormGrid>
              )}
            </>

            <FormGrid>
              <Autocomplete
                freeSolo
                disableClearable
                onChange={(e, value) => {
                  let parsedPhoneNumber;
                  try {
                    // parse the phonenumber to get the phone country code for previously created reservations
                    parsedPhoneNumber = parsePhoneNumberFromString(
                      value?.phone ?? null
                    );
                  } catch (error) {
                    console.error(
                      "Error handling the Autocomplete",
                      error.message
                    );
                  }
                  setValues({
                    ...values,
                    name: value.name,
                    address: value.address,
                    email: value.email,
                    postalCode: value.postalCode,
                    city: value.city,
                    phoneCountryCode: parsedPhoneNumber?.countryCallingCode
                      ? `+${parsedPhoneNumber?.countryCallingCode}`
                      : "+358",
                    phone: parsedPhoneNumber?.nationalNumber ?? value?.phone,
                  });
                }}
                fullWidth
                inputValue={values?.name ?? ""}
                options={personalAutofillValues}
                filterOptions={(options) => options}
                loading={nameAutoCompleteLoading}
                loadingText="Ladataan..."
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="none"
                    label="Nimi"
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    onBlur={() => setFieldTouched("name", true)}
                    variant="outlined"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {nameAutoCompleteLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    data-cy="newReservationDialogNameField"
                  />
                )}
                renderOption={(s, o) => {
                  return (
                    <Box {...s} key={o.id}>
                      <Box sx={{ cursor: "pointer", padding: "10px" }}>
                        <Typography variant="body1" display="block">
                          {o.name}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.email}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.address}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.postalCode} {o.city}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </FormGrid>

            {USER_ADMIN_GENERIC && (
              <Grid
                item
                xs={11}
                md={10}
                container
                sx={{
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <FormTextField
                  gridProps={{ xs: 10 }}
                  name="ssn"
                  label="Vuokraajan henkilötunnus"
                  variant="outlined"
                  type={showSSN ? "text" : "password"}
                  data-cy="newReservationDialogSSNField"
                />
                <Grid item xs={2} container justifyContent={"center"}>
                  <IconButton
                    color="primary"
                    onClick={handleClickShowSSN}
                    variant={showSSN ? "outlined" : "text"}
                  >
                    {showSSN ? <TbEyeOff /> : <TbEye />}
                  </IconButton>
                </Grid>
              </Grid>
            )}
            <FormGrid>
              <PhoneNumberField
                required
                name="phone"
                label="Puhelin"
                formik={formik}
                fieldConfig={{
                  name: "phone",
                  label: "Puhelin",
                  countryCodeField: "phoneCountryCode",
                }}
                fullWidth
                InputProps={{
                  style: {
                    color: theme.palette.primary.mainText,
                    backgroundColor: theme.palette.primary.dateRangeBg,
                  },
                }}
                separateDriver={false}
              />
            </FormGrid>
            <FormTextField
              name="email"
              label="Sähköposti"
              required
              data-cy="newReservationDialogEmailField"
            />

            {USER_ADMIN_INSURANCE && (
              <FormGrid>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "flex-start" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 550,
                      }}
                    >
                      Ajoneuvon tiedot
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      value={values.customerCarRegistrationPlate ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      name="customerCarRegistrationPlate"
                      label="Rekisterinumero"
                      variant="outlined"
                      fullWidth
                      error={
                        touched.customerCarRegistrationPlate &&
                        !!errors.customerCarRegistrationPlate
                      }
                      helperText={
                        touched.customerCarRegistrationPlate &&
                        errors.customerCarRegistrationPlate
                      }
                      data-cy="reservationDialogRegistrationPlateField"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={values.insuranceData?.customerCarMaker}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      name="insuranceData.customerCarMaker"
                      label="Merkki"
                      variant="outlined"
                      fullWidth
                      data-cy="reservationDialogCarMakerField"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={values.insuranceData?.customerCarModel}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      size="small"
                      name="insuranceData.customerCarModel"
                      label="Malli"
                      variant="outlined"
                      fullWidth
                      data-cy="reservationDialogCarModelField"
                    />
                  </Grid>
                </Grid>
              </FormGrid>
            )}

            {USER_ADMIN_GENERIC && (
              <FormTextField
                name="address"
                label="Osoite"
                data-cy="newReservationDialogAddressField"
              />
            )}
            {USER_ADMIN_GENERIC && (
              <Grid
                item
                xs={11}
                md={10}
                container
                spacing={2}
                sx={{ flexDirection: "row" }}
              >
                <FormTextField
                  name="city"
                  label="Kaupunki"
                  gridProps={{ lg: 8, xs: 12, sm: 12, md: 8, xl: 8 }}
                  data-cy="newReservationDialogCityField"
                />
                <FormTextField
                  name="postalCode"
                  label="Postinumero"
                  gridProps={{ lg: 4, xs: 12, sm: 12, md: 4, xl: 4 }}
                  data-cy="newReservationDialogPostalField"
                />
              </Grid>
            )}
            {USER_ADMIN_GENERIC && (
              <FormGrid>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="differentDriver"
                      checked={values.differentDriver ? true : false}
                      color="primary"
                      onChange={() =>
                        setFieldValue(
                          "differentDriver",
                          !values.differentDriver
                        )
                      }
                      onBlur={handleBlur}
                      data-cy="newReservationDialogDifferentDriverCheckbox"
                    />
                  }
                  label={<>Kuljettaja eri kuin vuokraaja</>}
                />
              </FormGrid>
            )}
            {USER_ADMIN_GENERIC && (
              <FormGrid container sx={{ flexDirection: "row" }} spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isCompany"
                        checked={values.isCompany ?? false}
                        color="primary"
                        onChange={() => {
                          setFieldValue("isCompany", !values?.isCompany);
                          setFieldValue("companyName", "");
                          setFieldValue("companyBusinessId", "");
                          setFieldValue("customerCompanyId", "");
                          setFieldValue("companyBillingAddress", "");
                          setFieldValue("companyEBillingOperator", "");
                          setFieldValue("companyEBillingAddress", "");
                          setFieldValue("companyBillingRef", "");
                          setFieldValue("companyCity", "");
                          setFieldValue("companyPostalCode", "");
                        }}
                        onBlur={handleBlur}
                        data-cy="newReservationDialogCompanyCheckbox"
                      />
                    }
                    label={<>Yritysasiakas</>}
                  />
                </Grid>
                {user?.organizationId && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isBroker"
                          checked={values.isBroker ?? false}
                          color="primary"
                          onChange={() => {
                            setFieldValue("isBroker", !values?.isBroker);
                            setSelectedBroker(null);
                            setBrokerName("");
                            setFieldValue("brokerCustomer", null);
                            setFieldValue("customerBrokerId", "");
                          }}
                          onBlur={handleBlur}
                        />
                      }
                      label={<>Agentti asiakas</>}
                    />
                  </Grid>
                )}
              </FormGrid>
            )}
          </Grid>
        </FormContent>
      </Grid>
      <Grid item xs={12}>
        <FormContent
          title={"Agentin tiedot"}
          visibility={values.isBroker && USER_ADMIN_GENERIC}
          expanded={toggleShowCompany}
          setExpanded={setToggleShowCompany}
          testName="showCompanyFieldsButton"
        >
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <FormGrid>
              {brokerLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Autocomplete
                  fullWidth
                  disableClearable
                  disabled={!!values?.customerBrokerId}
                  options={brokers}
                  loading={brokerLoading}
                  loadingText="Ladataan..."
                  getOptionLabel={(option) => option?.companyName || ""}
                  value={selectedBroker}
                  inputValue={brokerName}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      setBrokerName(newInputValue);
                    }
                  }}
                  onChange={(event, newValue, reason) => {
                    if (reason === "selectOption" && newValue) {
                      // Remove selectedBroker and use brokerCustomer from formik instead?
                      setSelectedBroker(newValue);
                      setFieldValue("customerBrokerId", newValue.id);
                      setFieldValue("brokerCustomer", newValue);
                      setBrokerName(newValue.companyName);
                    } else if (reason === "clear") {
                      setSelectedBroker(null);
                      setFieldValue("customerBrokerId", "");
                      setBrokerName("");
                    }
                  }}
                  selectOnFocus
                  clearOnBlur
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Valitse agentti"
                      variant="outlined"
                      error={
                        touched.customerBrokerId && !!errors.customerBrokerId
                      }
                      helperText={
                        touched.customerBrokerId && errors.customerBrokerId
                      }
                      InputProps={{
                        ...params.InputProps,
                        id: "broker-autocomplete",
                        endAdornment: (
                          <>
                            {brokerLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                            {!!values.customerBrokerId && (
                              <IconButton
                                onClick={() => {
                                  setSelectedBroker(null);
                                  setFieldValue("brokerCustomer", null);
                                  setFieldValue("customerBrokerId", "");
                                  setBrokerName("");
                                }}
                                edge="end"
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.companyName}
                    </li>
                  )}
                />
              )}

              <Typography
                variant="body1"
                display="block"
                sx={{
                  mt: 1,
                  fontWeight: "bold",
                }}
              >
                Laskun vastaanottajaksi asetetaan automaattisesti agentti!
              </Typography>
            </FormGrid>
          </Grid>
        </FormContent>
      </Grid>

      <Grid item xs={12}>
        <FormContent
          title={"Yritysasiakas"}
          visibility={values.isCompany}
          expanded={toggleShowCompany}
          setExpanded={setToggleShowCompany}
          testName="showCompanyFieldsButton"
        >
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <FormGrid>
              <Grid container spacing={1}>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    disabled={!!values?.customerCompanyId}
                    options={companyAutofillValues}
                    filterOptions={(options) => options}
                    loading={companyNameLoading}
                    loadingText="Ladataan..."
                    getOptionLabel={(option) => option?.companyName || ""}
                    inputValue={values?.companyName ?? ""}
                    onChange={(event, value) => {
                      if (!values.isBroker) {
                        setFieldValue(
                          "companyBillingAddress",
                          value ? value.companyBillingAddress : ""
                        );
                        setFieldValue(
                          "companyEBillingOperator",
                          value ? value.companyEBillingOperator : ""
                        );
                        setFieldValue(
                          "companyEBillingAddress",
                          value ? value.companyEBillingAddress : ""
                        );
                      }
                      setFieldValue(
                        "companyName",
                        value ? value.companyName : ""
                      );
                      setFieldValue(
                        "companyCity",
                        value ? value.companyCity : ""
                      );
                      setFieldValue(
                        "companyPostalCode",
                        value ? value.companyPostalCode : ""
                      );
                      setFieldValue(
                        "companyBusinessId",
                        value ? value.companyBusinessId : ""
                      );
                      setFieldValue("customerCompanyId", value ? value.id : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Yrityksen nimi"
                        variant="outlined"
                        autoComplete="none"
                        onChange={(e) =>
                          setFieldValue("companyName", e.target.value)
                        }
                        onBlur={() => setFieldTouched("companyName", true)}
                        error={touched.companyName && !!errors.companyName}
                        helperText={touched.companyName && errors.companyName}
                        required
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {companyNameLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : (
                                <>
                                  {!!values?.customerCompanyId && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue("companyName", "");
                                        setFieldValue("companyBusinessId", "");
                                        setFieldValue("customerCompanyId", "");
                                      }}
                                      size="small"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  )}
                                  {params.InputProps.endAdornment}
                                </>
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        data-cy="newReservationDialogCompanyName"
                      />
                    )}
                    renderOption={(s, o) => (
                      <Box {...s} key={o.id}>
                        <Box sx={{ cursor: "pointer", padding: "10px" }}>
                          <Typography variant="body1" display="block">
                            {o.companyName}
                          </Typography>
                          <Typography variant="caption" display="block">
                            {o.companyBusinessId}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    disabled={!!values?.customerCompanyId}
                    options={companyBusinessIdAutofillValues}
                    filterOptions={(options) => options}
                    loading={businessIdLoading}
                    loadingText="Ladataan..."
                    getOptionLabel={(option) => option?.companyBusinessId || ""}
                    inputValue={values?.companyBusinessId ?? ""}
                    onChange={(event, value) => {
                      setFieldValue(
                        "companyName",
                        value ? value.companyName : ""
                      );
                      if (!values.isBroker) {
                        setFieldValue(
                          "companyBillingAddress",
                          value ? value.companyBillingAddress : ""
                        );
                        setFieldValue(
                          "companyEBillingOperator",
                          value ? value.companyEBillingOperator : ""
                        );
                        setFieldValue(
                          "companyEBillingAddress",
                          value ? value.companyEBillingAddress : ""
                        );
                      }
                      setFieldValue(
                        "companyCity",
                        value ? value.companyCity : ""
                      );
                      setFieldValue(
                        "companyPostalCode",
                        value ? value.companyPostalCode : ""
                      );
                      setFieldValue(
                        "companyBusinessId",
                        value ? value.companyBusinessId : ""
                      );
                      setFieldValue("customerCompanyId", value ? value.id : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Y-tunnus"
                        variant="outlined"
                        onChange={(e) =>
                          setFieldValue("companyBusinessId", e.target.value)
                        }
                        onBlur={() =>
                          setFieldTouched("companyBusinessId", true)
                        }
                        error={
                          touched.companyBusinessId &&
                          !!errors.companyBusinessId
                        }
                        helperText={
                          touched.companyBusinessId && errors.companyBusinessId
                        }
                        required
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {businessIdLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(s, o) => (
                      <Box {...s} key={o.id}>
                        <Box sx={{ cursor: "pointer", padding: "10px" }}>
                          <Typography variant="body1" display="block">
                            {o.companyName}
                          </Typography>
                          <Typography variant="caption" display="block">
                            {o.companyBusinessId}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    value={values.companyCity ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="companyCity"
                    label="Kaupunki"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    value={values.companyPostalCode ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="companyPostalCode"
                    label="Postinumero"
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                {!values.isBroker && (
                  <>
                    <Grid item md={6} xs={12}>
                      <TextField
                        value={values.companyBillingAddress ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="companyBillingAddress"
                        label="Laskutusosoite"
                        variant="outlined"
                        disabled={USER_ADMIN_CORPORATE}
                        error={
                          touched.companyBillingAddress &&
                          !!errors.companyBillingAddress
                        }
                        helperText={
                          touched.companyBillingAddress &&
                          errors.companyBillingAddress
                        }
                        fullWidth
                        data-cy="newReservationDialogCompanyBillingAddress"
                        size="small"
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={6} xs={12}>
                  <TextField
                    value={values.companyBillingRef ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="companyBillingRef"
                    required={USER_ADMIN_CORPORATE}
                    label="Laskun viite"
                    variant="outlined"
                    error={
                      touched.companyBillingRef && !!errors.companyBillingRef
                    }
                    helperText={
                      touched.companyBillingRef && errors.companyBillingRef
                    }
                    fullWidth
                    data-cy="newReservationDialogCompanyBillingRef"
                    size="small"
                  />
                </Grid>

                {!values.isBroker && (
                  <>
                    <Grid item md={6} xs={12}>
                      <TextField
                        value={values.companyEBillingOperator ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="companyEBillingOperator"
                        label="Verkkolasku välitystunnus"
                        disabled={USER_ADMIN_CORPORATE}
                        variant="outlined"
                        error={
                          touched.companyEBillingOperator &&
                          !!errors.companyEBillingOperator
                        }
                        helperText={
                          touched.companyEBillingOperator &&
                          errors.companyEBillingOperator
                        }
                        fullWidth
                        data-cy="newReservationDialogCompanyBillingRef"
                        size="small"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        value={values.companyEBillingAddress ?? ""}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="companyEBillingAddress"
                        label="Verkkolasku osoite"
                        disabled={USER_ADMIN_CORPORATE}
                        variant="outlined"
                        error={
                          touched.companyEBillingAddress &&
                          !!errors.companyEBillingAddress
                        }
                        helperText={
                          touched.companyEBillingAddress &&
                          errors.companyEBillingAddress
                        }
                        fullWidth
                        data-cy="newReservationDialogCompanyBillingRef"
                        size="small"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </FormGrid>
          </Grid>
        </FormContent>
      </Grid>

      <FormContent
        title={"Kuljettajan tiedot"}
        visibility={values.differentDriver}
        expanded={toggleShowDriver}
        setExpanded={setToggleShowDriver}
        testName="showDriverFieldsButton"
      >
        <Grid container spacing={1} mb={1} sx={{ justifyContent: "center" }}>
          <FormTextField
            name="driverName"
            label={"Kuljettajan nimi"}
            fullWidth
            error={touched.driverAddress && !!errors?.driverName}
            helperText={touched.driverAddress && errors?.driverName}
            data-cy="newReservationDialogDriverName"
          />

          <FormTextField
            value={values.driverAddress ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="driverAddress"
            label={"Kuljettajan osoite"}
            variant="outlined"
            error={touched.driverAddress && !!errors.driverAddress}
            helperText={touched.driverAddress && errors.driverAddress}
            fullWidth
            data-cy="newReservationDialogDriverAddress"
          />

          <FormGrid>
            <Grid item container spacing={2}>
              <Grid item xs={6} md={12} lg={8}>
                <TextField
                  value={values.driverCity ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="driverCity"
                  label="Kuljettajan kaupunki"
                  variant="outlined"
                  error={touched.driverCity && !!errors.driverCity}
                  helperText={touched.driverCity && errors.driverCity}
                  fullWidth
                  data-cy="newReservationDialogDriverCity"
                />
              </Grid>
              <Grid item xs={6} md={12} lg={4}>
                <TextField
                  value={values.driverPostalCode ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="driverPostalCode"
                  label="Kuljettajan postinumero"
                  variant="outlined"
                  error={touched.driverPostalCode && !!errors.driverPostalCode}
                  helperText={
                    touched.driverPostalCode && errors.driverPostalCode
                  }
                  fullWidth
                  data-cy="newReservationDialogDriverPostalCode"
                />
              </Grid>
            </Grid>
          </FormGrid>

          <FormGrid>
            <PhoneNumberField
              required
              name="driverPhone"
              label="Kuljettajan puhelinnumero"
              formik={formik}
              fieldConfig={{
                name: "driverPhone",
                label: "Kuljettajan puhelinnumero",
                countryCodeField: "driverPhoneCountryCode",
              }}
              fullWidth
              InputProps={{
                style: {
                  color: theme.palette.primary.mainText,
                  backgroundColor: theme.palette.primary.dateRangeBg,
                },
              }}
              separateDriver={true}
            />
          </FormGrid>

          <FormTextField
            value={values.driverEmail ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="driverEmail"
            label={"Kuljettajan sähköposti"}
            variant="outlined"
            error={touched.driverEmail && !!errors.driverEmail}
            helperText={touched.driverEmail && errors.driverEmail}
            fullWidth
            data-cy="newReservationDialogDriverEmail"
          />
          <FormGrid>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item md={10}>
                <TextField
                  value={values.driverSSN ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="driverSSN"
                  label="Kuljettajan henkilötunnus"
                  variant="outlined"
                  type={showDriverSSN ? "text" : "password"}
                  error={touched.driverSSN && !!errors.driverSSN}
                  helperText={touched.driverSSN && errors.driverSSN}
                  fullWidth
                  data-cy="newReservationDialogDriverSSN"
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={handleClickShowDriverSSN}
                  variant={showDriverSSN ? "outlined" : "text"}
                >
                  {showDriverSSN ? "Piilo" : "Näytä"}
                </Button>
              </Grid>
            </Grid>
          </FormGrid>
        </Grid>

        <FormGrid>
          <FormControlLabel
            control={
              <Checkbox
                name="additionalDriver"
                checked={values.additionalDriver ?? false}
                color="primary"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            label={<>Lisäkuljettaja</>}
          />
        </FormGrid>

        {values.additionalDriver && (
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <FormTextField name="additionalDriverName" label="Nimi" />
            <FormGrid>
              <PhoneNumberField
                required
                name="additionalDriverPhone"
                label="Puhelinnumero"
                formik={formik}
                fieldConfig={{
                  name: "additionalDriverPhone",
                  label: "Puhelinnumero",
                  countryCodeField: "additionalDriverPhoneCountryCode",
                }}
                fullWidth
                InputProps={{
                  style: {
                    color: theme.palette.primary.mainText,
                    backgroundColor: theme.palette.primary.dateRangeBg,
                  },
                }}
              />
            </FormGrid>
            <FormGrid>
              <Grid item container spacing={2} sx={{ alignItems: "center" }}>
                <Grid item xs={10}>
                  <TextField
                    value={values.additionalDriverSSN ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="additionalDriverSSN"
                    label="Henkilötunnus"
                    variant="outlined"
                    type={showAdditionalSSN ? "text" : "password"}
                    error={
                      touched.additionalDriverSSN &&
                      !!errors.additionalDriverSSN
                    }
                    helperText={
                      touched.additionalDriverSSN && errors.additionalDriverSSN
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={handleClickShowAdditionalSSN}
                    variant={showAdditionalSSN ? "outlined" : "text"}
                  >
                    {showAdditionalSSN ? "Piilota" : "Näytä"}
                  </Button>
                </Grid>
              </Grid>
            </FormGrid>
          </Grid>
        )}
      </FormContent>
    </Grid>
  );
}

import React, { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { centsToLocalString } from "../../../../shared/money";
import DialogComponent from "../../../DialogComponent";
import {
  updateInvoice,
  updatePaymentEvent,
} from "../../../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { devStaging } from "../../../../features";
import { AppContext } from "../../../../AppContext";

const PaymentEventsTable = ({
  setShowInvoiceDialog,
  unpaidAmount,
  showActions,
  invoicesMutate,
  invoicesLoading,
  paymentEventsMutate,
  paymentEventsLoading,
  combinedEvents,
}) => {
  const { user } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(null); // 'delete' or 'details'
  const open = Boolean(anchorEl);

  const isDevStaging = React.useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV);
  }, []);

  const handleClick = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedEvent(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDetailsDialog = (event, type) => {
    setSelectedEvent(event);
    setDialogType(type);
    setDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedEvent.channel === "Invoice") {
      await API.graphql(
        graphqlOperation(updateInvoice, {
          input: {
            id: selectedEvent.id,
            isDeleted: true,
            deletedBy: user?.name,
          },
        })
      );
      invoicesMutate();
    } else if (selectedEvent.channel === "Payment") {
      await API.graphql(
        graphqlOperation(updatePaymentEvent, {
          input: {
            id: selectedEvent.id,
            isDeleted: true,
            deletedBy: user?.name,
          },
        })
      );
      paymentEventsMutate();
    }

    setDialogOpen(false);
  };

  const cancelDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
    setSelectedEvent(null);
  };

  if (invoicesLoading || paymentEventsLoading) {
    return (
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (combinedEvents.length === 0) {
    return (
      <Typography variant="h6" my={"10px"}>
        Varaukselle ei löytynyt maksutapahtumia.
      </Typography>
    );
  }

  const renderRow = (event) => {
    const { id, label, sum, isDeleted, type, channel, invoice } = event;

    // Determine if the More options menu should be displayed
    const showOptionsMenu = !(type !== "MANUAL" && channel === "Payment");

    return (
      <TableRow
        key={id}
        style={{
          textDecoration: isDeleted ? "line-through" : "none",
        }}
      >
        <TableCell>{label}</TableCell>
        <TableCell align="right">{centsToLocalString(sum)} €</TableCell>
        {showOptionsMenu && showActions && (
          <TableCell align="right">
            <IconButton size="small" onClick={(e) => handleClick(e, event)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open && selectedEvent === event}
              onClose={handleClose}
            >
              {/* Show 'Tiedot' option for MANUAL events and for invoices */}
              {(type === "MANUAL" || invoice) && (
                <MenuItem
                  onClick={() => {
                    if (type === "MANUAL") {
                      openDetailsDialog(event, "details");
                    } else {
                      setShowInvoiceDialog({
                        type,
                        invoice: invoice || selectedEvent,
                        readOnly: true,
                      });
                    }
                    handleClose();
                  }}
                >
                  Tiedot
                </MenuItem>
              )}
              {/* Show 'Hyvitä Lasku' only in dev and staging environments */}
              {isDevStaging && channel === "Invoice" && (
                <MenuItem onClick={handleClose}>Hyvitä Lasku</MenuItem>
              )}
              {/* Show 'Poista Maksu' only for MANUAL event types */}
              {type === "MANUAL" && !event?.isDeleted && (
                <MenuItem onClick={() => openDetailsDialog(event, "delete")}>
                  Poista Maksu
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 1, mb: 1 }}>
        <Typography variant="body1" sx={{ ml: 2, my: 1, fontWeight: "bold" }}>
          Maksutapahtumat
        </Typography>
        <Table aria-label="payment events" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Tyyppi</TableCell>
              <TableCell align="right">Summa</TableCell>
              {showActions && <TableCell align="right">Toiminnot</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedEvents.map(renderRow)}
            <TableRow>
              <TableCell
                colSpan={1}
                align="right"
                style={{ fontWeight: "bold" }}
              >
                Maksamatta:
              </TableCell>
              <TableCell align="right" style={{ fontWeight: "bold" }}>
                {centsToLocalString(unpaidAmount)} €
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogComponent open={dialogOpen} fullWidth maxWidth="md">
        <Box padding={4}>
          <Grid container spacing={2} alignItems="center" direction="column">
            <Grid item>
              {dialogType === "delete" ? (
                <>
                  <Typography variant="h6" align="center">
                    Haluatko varmasti poistaa maksutapahtuman:
                  </Typography>
                  <Typography variant="h6" align="center" fontWeight={"bold"}>
                    {selectedEvent?.label}
                    {selectedEvent?.innovoiceId
                      ? ` - Innovoice ID: ${selectedEvent?.innovoiceId}?`
                      : "?"}
                  </Typography>
                  <Typography variant="h6" align="center" fontWeight={"bold"}>
                    Summa: {centsToLocalString(selectedEvent?.sum)} €
                  </Typography>
                </>
              ) : (
                <>
                  {selectedEvent?.isDeleted && (
                    <>
                      <Typography
                        variant="h6"
                        align="center"
                        fontWeight={"bold"}
                      >
                        Poistanut: {selectedEvent.deletedBy}
                      </Typography>
                      {selectedEvent?.deletedAt && (
                        <Typography
                          variant="h6"
                          align="center"
                          fontWeight={"bold"}
                        >
                          Poistoaika:{" "}
                          {new Date(selectedEvent.deletedAt).toLocaleDateString(
                            "fi-FI"
                          )}
                        </Typography>
                      )}
                    </>
                  )}
                  <br></br>
                  <Typography variant="h6" align="center">
                    Maksutapahtuman tiedot:
                  </Typography>
                  <Typography variant="h6" align="center" fontWeight={"bold"}>
                    {selectedEvent?.label}
                  </Typography>
                  <Typography variant="h6" align="center" fontWeight={"bold"}>
                    Summa: {centsToLocalString(selectedEvent?.sum)} €
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  {dialogType === "delete" ? (
                    <Button
                      variant="contained"
                      onClick={confirmDelete}
                      color="success"
                    >
                      Vahvista
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={cancelDialog}
                      color="primary"
                    >
                      Ok
                    </Button>
                  )}
                </Grid>
                {dialogType === "delete" && (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={cancelDialog}
                      color="error"
                    >
                      Peruuta
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogComponent>
    </>
  );
};

export default PaymentEventsTable;

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Avatar, Typography, useTheme } from "@mui/material";
import styles from "./styles.module.scss";
import { useMoveReservation } from "../../../../hooks/useMoveReservation";
import { Category } from "~/graphql/API";
import { useCategoryImage } from "~/hooks/useCategoryImage";
import { useSettingsContext } from "../../../../SettingsContext";
import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";

type CategoryLabelProps = {
  category: Category;
  isOpen?: boolean;
  onClick?: () => void;
};

function CategoryLabel({ category, isOpen, onClick }: CategoryLabelProps) {
  const theme = useTheme();
  const { rowVariant } = useSettingsContext();

  const { image, backgroundColor } = useCategoryImage(category);

  const { isMoving, moveToCategory } = useMoveReservation();
  // Fix TypeScript types
  const { legacyUser } = useContext<any>(AppContext);

  const allowMoveTo = isMoving && !legacyUser;

  return (
    <button
      type="button"
      className={styles.button}
      onClick={allowMoveTo ? () => moveToCategory(category) : onClick}
      style={{ "--onHover": allowMoveTo ? theme.palette.primary.light : "" }}
    >
      <div className={styles.label}>
        {rowVariant === "regular" && (
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: backgroundColor,
              height: 40,
              width: 40,
            }}
            src={image}
          />
        )}

        <Typography
          variant="body1"
          sx={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
            color: "rgba(0,0,0,0.7)",
            lineHeight: 1,
          }}
        >
          {category.name}
        </Typography>
      </div>

      <ArrowDropDownIcon
        viewBox="6 6 12 12"
        sx={{
          position: "sticky",
          right: 10,
          fontSize: 24,
          color: theme.palette.primary.mainText,
          transform: isOpen ? undefined : "rotate(180deg)",
        }}
      />
    </button>
  );
}

export default CategoryLabel;

import { useContext } from "react";
import { AppContext } from "../../../../AppContext";
import { Box, Grid, Typography, useTheme } from "@mui/material";

export function Filter({
  title,
  icon,
  iconColor,
  value,
  amount,
  activeFilter,
  setActiveFilter,
  filterId,
}) {
  return (
    <FilterContainer
      activeFilter={activeFilter}
      filterId={filterId}
      setActiveFilter={setActiveFilter}
    >
      <Box
        sx={{
          padding: "5px",
          opacity: 0.4,
          marginRight: "2px",
        }}
      >
        <Box
          sx={{
            padding: "7px",
            border: "5px solid ",
            borderColor: iconColor,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: { xs: "1.5rem", sm: "2rem" },
            color: iconColor,
          }}
        >
          {icon}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Typography sx={{ fontFamily: "Sofia Pro" }}>{title}</Typography>
        <Typography
          variant="caption"
          sx={{ fontFamily: "Public Sans, sans-serif" }}
        >
          <span style={{ fontWeight: 700 }}>{amount} </span>{" "}
          {!!amount && "varausta"}
        </Typography>
        <Typography
          variant="caption"
          color={iconColor}
          sx={{ fontFamily: "Public Sans, sans-serif" }}
        >
          {value} {!!value && "€"}
        </Typography>
      </Box>
    </FilterContainer>
  );
}

function FilterContainer({
  children,
  filterId,
  activeFilter,
  setActiveFilter,
}) {
  const { mobileViewPort } = useContext(AppContext);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "80px",
        justifyContent: mobileViewPort ? "flex-start" : "center",
        alignItems: "center",
        width: "250px",

        cursor: "pointer",
        "&:hover": {
          bgcolor: "rgba(0,0,0,0.05)",
          boxShadow: theme.shadows[2],
        },

        transition: "250ms ease-in-out",
        borderRadius: "14px",
        border: "2px solid",
        borderColor:
          filterId === activeFilter
            ? theme.palette.primary.main
            : "transparent",

        bgcolor: filterId === activeFilter ? "rgba(0,0,0,0.03)" : "transparent",
      }}
      onClick={() => setActiveFilter(filterId)}
    >
      {children}
    </Box>
  );
}

const styles = {
  maxWidth: "150px",
};

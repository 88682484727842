import React, { useState, useContext } from "react";
import { AppContext } from "../../AppContext";
import { Box, Typography, CircularProgress } from "@mui/material";
import CustomerForm from "./CustomerForm";
import CustomerRegistry from "./CustomerRegistry";
import DialogComponent from "../DialogComponent";

import useSWR from "swr";

import { API, graphqlOperation } from "aws-amplify";
import { createCustomer, updateCustomer } from "../../graphql/mutations";
import { useNotification } from "../Notification";
import { searchCustomers } from "../../graphql/queries";
import listAll from "../../utils/list-all";

const fetchCustomers = async (organizationId, business, group) => {
  const filter = { and: [{ isDeleted: { ne: true } }] };

  if (organizationId && !business) {
    filter.and.push({
      and: [
        { organizationId: { eq: organizationId } },
        { orgBusinessId: { exists: false } },
      ],
    });
  } else if (business) {
    filter.and.push({
      or: [
        {
          and: [
            { organizationId: { eq: business.organizationId } },
            { orgBusinessId: { exists: false } },
            { orgExternalBusinessId: { exists: false } },
          ],
        },
        { orgBusinessId: { eq: business.id } },
      ],
    });
  } else if (group) {
    filter.and.push({
      group: { eq: group },
    });
  } else {
    return [];
  }

  try {
    const data = await listAll(
      graphqlOperation(searchCustomers, { filter, limit: 1000 }),
      "searchCustomers"
    );
    return data;
  } catch (error) {
    console.error("Failed to fetch customers:", error);
    return [];
  }
};

export default function CustomerManagementDialog(props) {
  const { showCustomerManagementDialog, setShowCustomerManagementDialog } =
    useContext(AppContext);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [view, setView] = useState("list");
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const { user, business, group } = useContext(AppContext);

  const organizationId = user?.organizationId;
  let key;
  if (organizationId && !business) {
    key = ["fetchCustomers", organizationId];
  } else if (business) {
    key = ["fetchCustomers", business.id];
  } else if (group) {
    key = ["fetchCustomers", group];
  }

  const {
    data: customers,
    error,
    isLoading,
    mutate,
  } = useSWR(
    key ? key : null,
    () => fetchCustomers(organizationId, business, group),
    {
      revalidateOnFocus: false,
      fallbackData: [],
    }
  );

  if (error) {
    console.error("SWR error fetching customers:", error.message);
  }

  const onClose = () => {
    setShowCustomerManagementDialog(false);
  };

  const handleSave = async (customer) => {
    setLoading(true);
    try {
      const input = {
        organizationId: user?.organizationId ?? undefined,
        group: user?.group ?? undefined,
        orgBusinessId: business?.id ?? undefined,
        brokerName: customer.brokerName,
        companyName: customer.companyName,
        address: customer.address,
        postalCode: customer.postalCode,
        city: customer.city,
        phone: customer.phone,
        email: customer.email,
        companyBillingAddress: customer.companyBillingAddress,
        companyBusinessId: customer.companyBusinessId,
        companyEBillingOperator: customer.companyEBillingOperator,
        companyEBillingAddress: customer.companyEBillingAddress,
        companyBillingRef: customer.companyBillingRef,
        companyCity: customer.companyCity,
        companyPostalCode: customer.companyPostalCode,
        clientType: customer.clientType ?? "COMPANY",
        isDeleted: false,
        orgExternalBusinessId: customer?.orgExternalBusinessId ?? undefined,
      };

      let updatedCustomers = [...customers];

      if (customer.id) {
        await API.graphql(
          graphqlOperation(updateCustomer, {
            input: { id: customer.id, ...input },
          })
        );
        notification.show("Asiakas päivitettiin onnistuneesti.", "success");
        const index = updatedCustomers.findIndex((c) => c.id === customer.id);
        if (index !== -1)
          updatedCustomers[index] = { ...updatedCustomers[index], ...input };
      } else {
        const { data } = await API.graphql(
          graphqlOperation(createCustomer, { input })
        );
        notification.show("Asiakas luotiin onnistuneesti.", "success");
        updatedCustomers.push(data.createCustomer);
      }

      mutate(updatedCustomers, false);
      setView("list");
      setEditingCustomer(null);
    } catch (error) {
      notification.show("Tallennuksessa tapahtui virhe.", "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setEditingCustomer(null);
    setView("form");
  };

  const handleEdit = (customer) => {
    setEditingCustomer(customer);
    setView("form");
  };

  const handleDeleteRequest = (customer) => {
    setCustomerToDelete(customer);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!customerToDelete) return;
    try {
      const input = { id: customerToDelete.id, isDeleted: true };
      await API.graphql(graphqlOperation(updateCustomer, { input }));
      mutate(
        customers.map((customer) =>
          customer.id === customerToDelete.id
            ? { ...customer, isDeleted: true }
            : customer
        ),
        false
      );
      notification.show("Asiakas poistettiin onnistuneesti.", "success");
    } catch (error) {
      notification.show("Poistossa tapahtui virhe.", "error");
    } finally {
      setConfirmDeleteOpen(false);
      setTimeout(() => {
        setCustomerToDelete(null);
      }, 150);
    }
  };

  const handleDeleteCancel = () => {
    setConfirmDeleteOpen(false);
    setTimeout(() => {
      setCustomerToDelete(null);
    }, 150);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DialogComponent
      open={showCustomerManagementDialog}
      dialogClose={() => onClose()}
      fullWidth
      maxWidth="xl"
    >
      <Box padding={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            {view === "list" ? "Yritysasiakkaat" : "Asiakkaan tiedot"}
          </Typography>
        </Box>
        {view === "list" ? (
          <CustomerRegistry
            business={business}
            customers={customers?.filter((customer) => !customer.isDeleted)}
            onEdit={handleEdit}
            onDelete={handleDeleteRequest}
            onAddNew={handleAddNew}
          />
        ) : (
          <CustomerForm
            customer={editingCustomer}
            onSave={handleSave}
            onCancel={() => setView("list")}
            loading={loading}
          />
        )}
      </Box>
      <DialogComponent
        open={confirmDeleteOpen}
        dialogClose={handleDeleteCancel}
        dialogAction={handleDeleteConfirm}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
      >
        Haluatko varmasti poistaa asiakkaan {customerToDelete?.name}?
      </DialogComponent>
    </DialogComponent>
  );
}

import {
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
  Box,
  Divider,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

function DeleteButton({ onClick }) {
  const theme = useTheme();
  return (
    <Button
      color="error"
      variant="contained"
      onClick={onClick}
      sx={{
        bgcolor: theme.palette.error.dark,
        borderBottomLeftRadius: "15px",
        borderTopLeftRadius: "15px",
      }}
    >
      Poista
    </Button>
  );
}

function DialogTitle({ children }) {
  return (
    <Box display={"flex"} sx={{ width: "100%", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontFamily: "Sofia Pro" }} align="center">
        {children}
      </Typography>
      <Divider />
    </Box>
  );
}

function DialogClose({ onClick, dialogCloseText }) {
  let text = "Sulje";
  if (dialogCloseText) {
    text = dialogCloseText;
  }
  return (
    <Button color="primary" variant="text" onClick={onClick}>
      {text}
    </Button>
  );
}

function DialogAction({
  onClick,
  dialogActionText,
  dialogActionColor,
  dialogActionDisabled,
  dialogActionSubmitting,
}) {
  return (
    <Button
      disabled={dialogActionDisabled || dialogActionSubmitting}
      color={dialogActionColor ? dialogActionColor : "success"}
      variant="contained"
      onClick={onClick}
      sx={{
        minWidth: "90px",
        borderBottomRightRadius: "15px",
        borderTopRightRadius: "15px",
      }}
    >
      {dialogActionSubmitting ? (
        <CircularProgress size={24} />
      ) : (
        dialogActionText
      )}
    </Button>
  );
}

function AdditionalComponent({ children }) {
  return (
    <Box
      display={"flex"}
      sx={{
        flexDirection: "row",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
}

export default function DialogComponent({
  children,
  maxWidth,
  open,
  onClose,
  dialogDelete,
  dialogClose,
  dialogTitle,
  dialogAction,
  dialogActionText,
  dialogCloseText,
  dialogActionColor,
  dialogActionSubmitting,
  dialogActionDisabled,
  additionalComponent,
  height,
  companyDialog,
  testName,
  removePadding,
  noMobileFullScreen,
  dialogAltTitle,
}) {
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(
    theme.breakpoints.down(companyDialog ? "md" : "md")
  );
  return (
    <Dialog
      data-cy={testName}
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullScreen={!noMobileFullScreen ? mobileViewPort : undefined}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: mobileViewPort ? undefined : 20,
          border: "none",
          //  height: mobileViewPort ? undefined : "80vh",
          //  backgroundColor: theme.palette.primary.background.default,
        },
      }}
    >
      {dialogAltTitle}

      <DialogContent
        sx={{
          height: height && !mobileViewPort ? height : undefined,

          padding: mobileViewPort ? 0 : removePadding,
          position: "relative",
        }}
      >
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        {children}
      </DialogContent>
      {mobileViewPort && additionalComponent && (
        <DialogActions
          style={{
            display: "flex",
            borderStyle: "solid",
            borderWidth: "1px 0 0 0",
            borderColor: "lightgray",
            flexDirection: "row",
          }}
        >
          <AdditionalComponent>{additionalComponent} </AdditionalComponent>
        </DialogActions>
      )}
      <DialogActions
        style={{
          display: "flex",
          borderStyle: "solid",
          borderWidth: "1px 0 0 0",
          borderColor: "lightgray",
          flexDirection: "row",
        }}
      >
        {dialogDelete && <DeleteButton onClick={dialogDelete} />}
        {!mobileViewPort && additionalComponent && (
          <AdditionalComponent>{additionalComponent} </AdditionalComponent>
        )}

        {!additionalComponent && <span style={{ flex: 1 }} />}
        {dialogClose && (
          <DialogClose
            dialogCloseText={dialogCloseText}
            onClick={dialogClose}
          />
        )}
        {dialogAction && (
          <DialogAction
            dialogActionText={dialogActionText}
            onClick={dialogAction}
            dialogActionColor={dialogActionColor}
            dialogActionSubmitting={dialogActionSubmitting}
            dialogActionDisabled={dialogActionDisabled}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import {
  centsToLocalString,
  parseToCents,
} from "../../../../../../../../../../shared/money";
import { Euro } from "@mui/icons-material";
import ListingPrice from "./ListingPrice";
import { useAtomValue, useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../../../../../../../../stores/vehiclePrices";

export default function DeductibleField(props) {
  const {
    vehicleId,
    offerPricesLoading,
    listingDeductiblePrice,
    expanded,
    setDeductibleInput,
    deductibleInput,
  } = props;
  const prices = useAtomValue(vehiclePricesAtom);
  const deductible = prices[vehicleId]?.deductible;
  const setPrice = useSetAtom(vehiclePricesAtom);
  const { values } = useFormikContext();
  const handleChangePrice = () => {
    if (deductibleInput === "" || deductibleInput === null) {
      return setPrice((prev) => ({
        ...prev,
        [vehicleId]: {
          deductible: listingDeductiblePrice,
          startTime: values.startTime,
          returnTime: values.returnTime,
          pricingCategoryId: values.pricingCategoryId,
          price: prices[vehicleId]?.price,
          tax: prices[vehicleId]?.tax,
        },
      }));
    }
    setDeductibleInput(centsToLocalString(parseToCents(deductibleInput)));
    setPrice((prev) => ({
      ...prev,
      [vehicleId]: {
        deductible: parseToCents(deductibleInput),
        startTime: values.startTime,
        returnTime: values.returnTime,
        pricingCategoryId: values.pricingCategoryId,
        price: prices[vehicleId]?.price,
        tax: prices[vehicleId]?.tax,
      },
    }));
  };

  if (!expanded) {
    return (
      <Stack
        alignItems={"flex-end"}
        direction={"column"}
        justifyContent={"flex-end"}
        width={"100%"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography variant="caption" sx={{ fontFamily: "Sofia Pro" }}>
            Omavastuu:
          </Typography>
          <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1rem" }}>
            {centsToLocalString(deductible) ??
              centsToLocalString(listingDeductiblePrice)}
            €
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack>
      <ListingPrice
        listingPrice={listingDeductiblePrice}
        loading={offerPricesLoading}
        type="deductible"
      />
      <TextField
        label="Omavastuu"
        placeholder={centsToLocalString(listingDeductiblePrice)}
        InputLabelProps={{ shrink: true }}
        inputProps={{ style: { textAlign: "right", marginRight: "5px" } }}
        value={deductibleInput ?? ""}
        size="small"
        onChange={(e) => setDeductibleInput(e.target.value)}
        onBlur={handleChangePrice}
        sx={{ maxWidth: "130px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Euro sx={{ fontSize: "18px" }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";

export default function NavBarButton({
  handleNavigate,
  handleClick,
  selectedPage,
  ToolTipTitle,
  navigateTo,
  icon,
  testName,
  visibility,
  iconSize,
}) {
  const theme = useTheme();

  if (!visibility) return;
  return (
    <Tooltip title={ToolTipTitle}>
      <Box
        onClick={() =>
          navigateTo ? handleNavigate(navigateTo) : handleClick()
        }
        variant={selectedPage === navigateTo ? "contained" : "outlined"}
        color={selectedPage === navigateTo ? "primary" : "secondary"}
        sx={{
          height: iconSize * 2,
          width: iconSize * 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border:
            selectedPage === navigateTo ? undefined : "1px solid lightgrey",
          borderRadius: "10px",
          transition: "350ms ease-in-out",
          cursor: "pointer",
          color:
            selectedPage === navigateTo ? "white" : theme.palette.primary.main,
          bgcolor:
            selectedPage === navigateTo
              ? theme.palette.primary.main
              : "transparent",
          "&:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            boxShadow: theme.shadows[4],
          },
        }}
        data-cy={testName}
      >
        {icon}
      </Box>
    </Tooltip>
  );
}

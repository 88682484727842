import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
} from "@mui/material";

import SpecialReservationTimesDialog from "../SpecialReservationTimesDialog";
import { useFormikContext } from "formik";

export default function ProductTimeSettings({
  company,
  setShowSpecialTimes,
  data,
}) {
  const { values, errors, handleChange, handleBlur } = useFormikContext();
  if (company?.enableVehicleStartReturnTimes)
    return (
      <Grid item xs={12} sm={12}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item style={{ marginBottom: 40 }}>
            <FormControlLabel
              control={
                <Switch
                  name={"useStartReturnTimes"}
                  checked={values["useStartReturnTimes"] || false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label={"Erikois nouto- ja palautusajat"}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            {values?.useStartReturnTimes && (
              <SpecialReservationTimesDialog
                open
                onClose={(success) => {
                  setShowSpecialTimes(false);
                }}
                vehicle={data}
                company={company}
              />
            )}
            {errors.startReturnTimes && (
              <FormControl error={!!errors.startReturnTimes}>
                <FormHelperText>Tarkista ajat</FormHelperText>
              </FormControl>
            )}
            {errors.categoryStartReturnTimeConflict && (
              <FormControl error={!!errors.categoryStartReturnTimeConflict}>
                <FormHelperText>
                  Ei mahdollista käyttää kategorian niputuksen kanssa
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
}

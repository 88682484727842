import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { getReservationsTotal } from "~/utils/getReservationsTotal";
import { getTotal } from "~/utils/getTotal";
import { ReservationsGroup } from "../../utils/groupReservations";
import { ECustomerType } from "./enums";

export const columns: GridColDef<
  {
    id: string;
  } & ReservationsGroup
>[] = [
  {
    headerName: "Asiakas",
    field: "customer",
    flex: 1,
    valueGetter({ row }) {
      // some of the older reservations don't have customer registry object, so this falls back to the first reservation's companyName
      const getNameFromReservation = row.items[0]?.companyName;

      return row.customerType === ECustomerType.PERSON
        ? row.items.find(({ name }) => name)?.name ||
            row.items.find(({ email }) => email)?.email!
        : row.customer?.companyName || getNameFromReservation;
    },
  },
  {
    headerName: "Laskuttamattomien tilausten määrä",
    field: "reservations_count",
    flex: 1,
    align: "right",
    valueGetter({ row }) {
      return row.items.length;
    },
  },
  {
    headerName: "Vanhin tilaus luotu",
    field: "latest_reservation",
    width: 160,
    align: "center",
    valueGetter({ row }) {
      return format(new Date(row.items[row.items.length - 1].createdAt), "PPP");
    },
  },
  {
    headerName: "Uusin tilaus luotu",
    field: "oldest_reservation",
    width: 160,
    align: "center",
    valueGetter({ row }) {
      return format(new Date(row.items[0].createdAt), "PPP");
    },
  },

  {
    headerName: "Yhteissumma",
    field: "sum",
    width: 130,
    align: "right",
    valueGetter({ row: { items: reservations } }) {
      return getTotal(getReservationsTotal(reservations));
    },
    renderCell({ value }) {
      return value.toFixed(2);
    },
  },
];

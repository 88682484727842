import {
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Avatar,
} from "@mui/material";
import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import {
  createAdditionalService,
  updateAdditionalService,
} from "../graphql/mutations";
import { useNotification } from "./Notification";
import { useFormik } from "formik";
import captureError from "../utils/capture-error";
import { useConfirmation } from "./Confirmation";
import { v4 } from "uuid";
import { CloudUpload, Delete, Info } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/system";
import DialogComponent from "./DialogComponent";

import FINflag from "~/assets/flags/fin.png";
import SWEflag from "~//assets/flags/swe.png";
import ENGflag from "~/assets/flags/eng.png";

import { AppContext } from "../AppContext";

const INITIAL_FORM_DATA = {
  description: "",
  enableQuantity: false,
  enableNotification: false,
  enableTextInput: false,
  inputLabel: "",
  serviceType: false,
  maxQuantity: null,
  descriptionT: {
    fi: null,
    en: null,
    sv: null,
  },
};

const validate = (values) => {
  const errors = {};

  if (!values.descriptionT.fi) {
    errors.descriptionT = "Täytä tämä kenttä";
  }

  if (values.enableTextInput && !values.inputLabel) {
    errors.inputLabel = "Täytä tämä kenttä";
  }
  console.log("errors", errors);

  return errors;
};

const FormikTextField = ({ formik, name, ...props }) => {
  return (
    <TextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={name}
      variant="outlined"
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      fullWidth
      {...props}
    />
  );
};

export default function AdditionalServicesEditDialog({
  onClose,
  additionalService,
}) {
  const { group, user, business } = useContext(AppContext);
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const fileInputRef = useRef();
  const [image, setImage] = useState(additionalService?.image);
  const [signedUrl, setSignedUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  /* const FEATURE_SERVICE_DEV = useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV, company);
  }, [company]);
 */
  const handleRemoveUserImage = () => {
    setImage(null);
    setSignedUrl(null);
  };

  const getSignedUrl = async (value) => {
    if (!value) {
      return;
    }
    setImageLoading(true);
    const [identityId, key] = value.split("/");
    const url = key
      ? await Storage.get(key, { identityId, level: "protected" })
      : null;
    setSignedUrl(url);
    setImageLoading(false);
  };

  const onSubmit = async () => {
    setSubmitting(true);

    const {
      description,
      serviceType,
      enableNotification,
      inputLabel,
      enableTextInput,
    } = formik.values;
    const enableQuantity = serviceType ? false : formik.values.enableQuantity;

    let updatedInputLabel;

    if (serviceType && enableTextInput) {
      if (inputLabel !== null && inputLabel.trim() !== "") {
        updatedInputLabel = inputLabel;
      } else {
        updatedInputLabel = "Lisätietoja";
      }
    } else if (serviceType) {
      updatedInputLabel = "Kohdemaa";
    } else if (enableTextInput) {
      if (inputLabel !== null) {
        updatedInputLabel = inputLabel;
      } else {
        updatedInputLabel = "Lisätietoja";
      }
    } else {
      updatedInputLabel = undefined;
    }

    const category = additionalService.category
      ? additionalService.category
      : "normal";

    const maxQuantityInput = formik.values.maxQuantity;
    let maxQuantity = null;
    if (
      Number.isInteger(maxQuantityInput) &&
      maxQuantityInput > 0 &&
      !serviceType
    ) {
      maxQuantity = maxQuantityInput;
    }

    const input = {
      description: formik.values.descriptionT.fi,
      descriptionT: formik.values.descriptionT,
      category,
      type: serviceType ? "EXPORT_LICENSE" : null,
      ...(updatedInputLabel !== undefined && {
        inputLabel: updatedInputLabel,
      }),
      enableQuantity,
      enableNotification,
      enableTextInput,
      maxQuantity,
      image: image,
    };
    delete input?.descriptionT?.__typename;
    if (group) {
      input.group = group;
    }
    if (user?.organizationId) {
      // Always add Organization information, if user belongs to one
      input.organizationId = user.organizationId;
      input.orgBusinessId = business?.id;
    }

    try {
      if (additionalService.id) {
        await API.graphql(
          graphqlOperation(updateAdditionalService, {
            input: {
              ...input,
              id: additionalService.id,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createAdditionalService, {
            input: input,
          })
        );
      }

      onClose(true);
      return;
    } catch (e) {
      captureError(
        "Upsert AdditionalService failed",
        "UPSERT_ADDITIONAL_SERVICE_FAILED",
        e
      );
      console.log("error", e);

      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate: (values) => validate(values),
    onSubmit,
  });

  const maxQuantityEnabled = useMemo(() => {
    const maxQuantityInput = formik.values.maxQuantity;
    if (Number.isInteger(maxQuantityInput)) return true;
    return false;
  }, [formik.values.maxQuantity]);

  useEffect(() => {
    if (additionalService) {
      formik.setValues({
        ...INITIAL_FORM_DATA,
        ...additionalService,
        descriptionT: additionalService?.descriptionT ?? {
          fi: additionalService?.description ?? null,
          en: null,
          sv: null,
        },
        enableQuantity: !!additionalService.enableQuantity, // always set a Boolean here
        enableNotification: !!additionalService.enableNotification,
        enableTextInput: !!additionalService.enableTextInput,
        serviceType: !!additionalService.type,
      });
    } else {
      formik.setValues(INITIAL_FORM_DATA);
    }
    formik.setErrors({});
    formik.setTouched({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalService]);

  const [showDeleteConfirmation, DeleteConfirmationDialog] = useConfirmation(
    async () => {
      await API.graphql(
        graphqlOperation(updateAdditionalService, {
          input: {
            _removed: true,
            id: additionalService.id,
          },
        })
      );
      //   fetchPricingExceptions();
      onClose(true);
    }
  );

  const onChange = async (e) => {
    setImageLoading(true);
    setImage(null);
    const file = e.target.files[0];
    const type = file.type;
    const ext = type === "image/jpeg" ? "jpg" : "png";
    try {
      const result = await Storage.put(v4() + "." + ext, file, {
        contentType: type,
        level: "protected",
      });

      const creds = await Auth.currentCredentials();
      const imageValue = creds.identityId + "/" + result.key;
      setImage(imageValue);
    } catch (e) {
      captureError("Upload image failed", "UPLOAD_IMAGE_FAILED", e);
      console.log(e);
      notification.show("Kuvan lataus epäonnistui");
    }
    e.target.value = null;
    setImageLoading(false);
  };

  const confirmationMessage =
    "Haluatko varmasti poistaa lisäpalvelun " +
    additionalService.description +
    " ?";
  useEffect(() => {
    getSignedUrl(image);
  }, [image]);

  return (
    <>
      <DialogComponent
        open={true}
        onClose={() => onClose()}
        maxWidth={"sm"}
        dialogTitle={"Lisäpalvelun tiedot"}
        dialogDelete={
          additionalService?.id ? () => showDeleteConfirmation() : null
        }
        dialogClose={() => onClose()}
        dialogAction={formik.submitForm}
        dialogActionText={"Tallenna"}
        dialogActionSubmitting={submitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid item container direction="column" xs={12}>
            <Grid item xs={11} sx={{ marginTop: "25px" }}>
              <DescriptionTranslationsForm formik={formik} />
            </Grid>
            <Grid
              container
              direction={"row"}
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "15px",
              }}
            >
              <Grid item sx={{ width: "150px" }}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  sx={{
                    width: "150px",
                    height: "150px",
                    border: "1px solid lightgrey",
                    borderRadius: "15px",
                    padding: "10px",
                  }}
                >
                  {!imageLoading && signedUrl && (
                    <img
                      src={signedUrl}
                      alt="lisäpalvelun kuva"
                      style={{
                        objectFit: "scale-down",

                        width: "100%",
                      }}
                    />
                  )}
                  {!signedUrl && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "150px",
                        height: "150px",
                      }}
                    >
                      <Typography variant="body2"> Ei kuvaa</Typography>
                    </Box>
                  )}
                </Box>
                <Stack
                  direction="row"
                  sx={{
                    marginTop: "10px",

                    justifyContent: "space-evenly",
                  }}
                >
                  <IconButton component="label">
                    <CloudUpload
                      style={{ fontSize: 32, color: "dodgerblue" }}
                    />
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(evt) => onChange(evt)}
                      ref={fileInputRef}
                    />
                  </IconButton>
                  {image && (
                    <IconButton onClick={() => handleRemoveUserImage()}>
                      <Delete style={{ fontSize: 32, color: "darkgrey" }} />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={11} sm={8}>
                {additionalService.category.includes("normal") && (
                  <Stack sx={{ flexDirection: "column" }}>
                    {!formik.values.serviceType && (
                      <>
                        <Box display={"flex"}>
                          <FormControlLabel
                            control={
                              <Switch
                                name="enableQuantity"
                                checked={formik.values.enableQuantity || false}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            }
                            label={"Salli kappalemäärä"}
                          />
                        </Box>
                        {formik.values.enableQuantity && (
                          <Stack sx={{ flexDirection: "column" }}>
                            <Box display={"flex"}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    name="enableMaxQuantity"
                                    checked={maxQuantityEnabled || false}
                                    onChange={() => {
                                      if (formik.values.maxQuantity) {
                                        formik.setFieldValue(
                                          "maxQuantity",
                                          null
                                        );
                                      } else {
                                        formik.setFieldValue("maxQuantity", 1);
                                      }
                                    }}
                                  />
                                }
                                label={"Aseta maksimimäärä yhdelle varaukselle"}
                              />
                            </Box>
                            {maxQuantityEnabled && (
                              <Box sx={{ marginTop: "15px" }}>
                                <FormikTextField
                                  formik={formik}
                                  name="maxQuantity"
                                  label="Maksimimäärä"
                                  type="number"
                                  InputProps={{ inputProps: { min: "1" } }}
                                  required
                                />
                              </Box>
                            )}
                          </Stack>
                        )}
                      </>
                    )}
                    <Box display="flex">
                      <FormControlLabel
                        control={
                          <Switch
                            name="enableNotification"
                            checked={formik.values.enableNotification || false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        }
                        label={
                          <>
                            Ilmoitukset
                            <Tooltip title="Saa ilmoitus kun lisäpalvelua käytetään">
                              <IconButton size="small">
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            name="enableTextInput"
                            checked={formik.values.enableTextInput || false}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        }
                        label={"Tekstinsyöttö"}
                      />
                      <Tooltip title="Lisäpalvelua käytettäessä asiakkaalle näytetään tekstinsyöttökenttä, jonka otsikko määritellään alapuolella">
                        <IconButton size="small">
                          <Info />
                        </IconButton>
                      </Tooltip>
                      {formik.values.enableTextInput && (
                        <div style={{ marginTop: "1em", marginBottom: "1em" }}>
                          <FormikTextField
                            formik={formik}
                            name="inputLabel"
                            label="Tekstinsyötön otsikko"
                            type="string"
                            required
                            data-cy="additionalServiceTextInputTitle"
                          />
                        </div>
                      )}
                    </Box>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography sx={{ width: "50%", flexShrink: 0 }}>
                          Lisäasetukset
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex">
                          <FormControlLabel
                            control={
                              <Switch
                                name="serviceType"
                                checked={formik.values.serviceType || false}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  if (e.target.checked) {
                                    formik.setFieldValue(
                                      "enableNotification",
                                      true
                                    );
                                    formik.setFieldValue(
                                      "enableTextInput",
                                      true
                                    );
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                            }
                            label={
                              <>
                                Maastapoistumislupa
                                <Tooltip title="Käytä tätä kun kyseessä on maastapoistumislupa. Tämän palvelun ollessa varauksella, näytetään maastapoistumislupa myös sopimuksella.">
                                  <IconButton size="small">
                                    <Info />
                                  </IconButton>
                                </Tooltip>
                              </>
                            }
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogComponent>
      <DeleteConfirmationDialog
        message={confirmationMessage}
        confirmLabel="Poista"
        testName="deleteAdditionalServiceDialog"
      />
    </>
  );
}

function DescriptionTranslationsForm({ formik }) {
  return (
    <Stack spacing={1}>
      <TextField
        value={formik.values.descriptionT["fi"]}
        onChange={(e) => {
          formik.setFieldValue("descriptionT.fi", e.target.value);
        }}
        onBlur={formik.handleBlur}
        label="Nimi"
        variant="outlined"
        data-cy="fi-additional-service-name-field"
        required
        fullWidth
        error={formik.touched.descriptionT && !!formik.errors.descriptionT}
        helperText={formik.touched.descriptionT && formik.errors.descriptionT}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Avatar
                src={FINflag}
                sx={{
                  border: "1px solid lightgrey",
                  height: 26,
                  width: 26,
                }}
              />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        value={formik.values.descriptionT.sv}
        onChange={(e) => {
          formik.setFieldValue("descriptionT.sv", e.target.value);
        }}
        label="Namn"
        required
        data-cy="sv-additional-service-name-field"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Avatar
                src={SWEflag}
                sx={{
                  border: "1px solid lightgrey",
                  height: 26,
                  width: 26,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        value={formik.values.descriptionT["en"]}
        onChange={(e) => {
          formik.setFieldValue("descriptionT.en", e.target.value);
        }}
        onBlur={formik.handleBlur}
        label="Nimi"
        variant="outlined"
        data-cy="en-additional-service-name-field"
        required
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Avatar
                src={ENGflag}
                sx={{
                  border: "1px solid lightgrey",
                  height: 26,
                  width: 26,
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Invoice } from "~/graphql/API";
import { getTotal } from "~/utils/getTotal";

export const columns: GridColDef<Invoice>[] = [
  {
    headerName: "Asiakas",
    field: "customer",
    flex: 1,
    valueGetter({ row: { customer } }) {
      return (
        customer.company_name ||
        [customer.firstname, customer.lastname].join(" ")
      );
    },
  },
  {
    headerName: "Laskun päivämäärä",
    field: "createdAt",
    width: 175,
    align: "center",
    renderCell({ row: { createdAt } }) {
      return format(new Date(createdAt), "dd.MM.yyyy");
    },
  },
  {
    headerName: "Myyjän viite",
    field: "seller_reference",
    flex: 1,
  },
  {
    headerName: "Ostajan viite",
    field: "buyer_reference",
    flex: 1,
  },
  {
    headerName: "Maksuehto",
    field: "payment_terms",
    width: 120,
    align: "right",
  },
  {
    headerName: "Summa",
    field: "items",
    width: 100,
    align: "right",
    valueGetter({ row: { items } }) {
      return items.reduce((acc, { quantity, unitprice, discount }) => {
        const itemTotal = getTotal(unitprice, {
          quantity,
          discount: discount || undefined,
        });

        acc += parseFloat(`${itemTotal}`);

        return acc;
      }, 0);
    },
    renderCell({ value }) {
      return value.toFixed(2);
    },
  },
];

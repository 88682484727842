// search for company or pickup location by id
export const findItemById = (user, targetId) => {
  // Search in companies.items
  for (const businessItem of user?.businessByOrg?.items || []) {
    for (const company of businessItem.companies.items || []) {
      if (company.id === targetId) {
        return { ...company, orgBusinessId: businessItem.id };
      }
      if (businessItem?.pickupLocations) {
        for (const pickupLocation of businessItem.pickupLocations) {
          if (pickupLocation.id === targetId) {
            return { ...pickupLocation, orgBusinessId: businessItem.id };
          }
        }
      }
    }
  }

  // Search in pickuplocations
  for (const pickupLocation of user?.businessByOrg?.items || []) {
    if (pickupLocation.pickuplocationstargetId) {
      return { ...pickupLocation, orgBusinessId: user?.orgBusinessId };
    }
  }

  return null; // Return null if no match is found
};

import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { sendEmailVerification } from "../../../graphql/mutations";
import { captureError } from "../../../utilities";
import { useFormikContext } from "formik";
import { useValidateForm } from "../formik/hooks/useValidateForm";
import { useNotification } from "../../Notification";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import { TbMailFast } from "react-icons/tb";

export default function SendEmailConfirmation() {
  const reservationData = useAtomValue(reservationDataAtom);
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState(false);
  const { submitForm, isSubmitting } = useFormikContext();
  const { validate } = useValidateForm();
  const notification = useNotification();
  const handleClick = async () => {
    const isValid = await validate();

    if (isValid) {
      setSendEmailConfirmation(true);
    }
  };

  const handleSendEmail = async () => {
    await submitForm();
    try {
      const result = await API.graphql(
        graphqlOperation(sendEmailVerification, {
          reservationId: reservationData?.id,
        })
      );
      if (result.data.sendEmailVerification) {
        notification.show("Sähköposti lähetetty", "success");
      } else {
        throw new Error("Email send failed", reservationData?.id);
      }
    } catch (e) {
      console.log(e);
      captureError("Send email verification failed", "SEND_EMAIL_FAILED", e);
      notification.show("Sähköpostin lähetys epäonnistui", "warning");
    } finally {
      setSendEmailConfirmation(false);
    }
  };

  if (reservationData?.id)
    return (
      <React.Fragment>
        <Tooltip title={"Tallenna ja lähetä varausvahvistus"} placement="top">
          <IconButton
            disabled={isSubmitting}
            onClick={() => {
              handleClick();
            }}
          >
            <TbMailFast style={{ fontSize: "30px" }} />
          </IconButton>
        </Tooltip>
        <Dialog open={sendEmailConfirmation}>
          <DialogContent>
            Haluatko varmasti lähettää varausvahvistuksen?
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSendEmailConfirmation(false)}
              disabled={isSubmitting}
            >
              Peruuta
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSendEmail()}
              disabled={isSubmitting}
            >
              Lähetä
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}

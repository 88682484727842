import { Reservation } from "~/graphql/API";

export function makeFilterVisibleReservations(
  startDate: number,
  endDate: number
) {
  return function filterVisibleReservations(item: Reservation) {
    const startTime = (item.startTime as unknown as Date).getTime();
    const endTime = (item.endTime as unknown as Date).getTime();

    return !(startTime > endDate || endTime < startDate);
  };
}

import { API, graphqlOperation } from "aws-amplify";
import { updateCompany } from "../../../graphql/custom-mutations";

export const handleUpdateDynamicPricing = async (companyInput) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateCompany, {
        input: companyInput,
      })
    );
    if (response) {
      delete response.data.updateCompany.__typename;
      const categoryDynamicPricings =
        response.data.updateCompany.categoryDynamicPricings.map((item) => {
          const { __typename, ...rest } = item;

          return rest;
        });

      return {
        ...response.data.updateCompany,
        categoryDynamicPricings,
      };
    }
  } catch (error) {
    console.log("Error updating company", error);
  }
};

export const updateDynamicPricingOptions = (
  updatedCompanyId,
  updatedCategoryDynamicPricings
) => {
  return {
    optimisticData: (data) => {
      const updated = data.map((company) => {
        if (company.id === updatedCompanyId) {
          return {
            ...company,
            categoryDynamicPricings: updatedCategoryDynamicPricings,
          };
        } else {
          return company;
        }
      });
      return updated;
    },
    rollbackOnError: true,
    populateCache: false,

    revalidate: false,
  };
};

import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import ReservationFormBusinessUsers from "./BusinessUsers";
import { EUserAdminType } from "../../../enums/user-admin-type";
import ReservationFormExternalUsers from "./ExternalUsers";
import ReservationFormInsuranceUsers from "./InsuranceUsers/";

export default function ContentByUserRole() {
  const { USER_ADMIN_TYPE } = useContext(AppContext);

  const content = getContentByUserRole(USER_ADMIN_TYPE);
  return content;
}

const getContentByUserRole = (userRole) => {
  switch (userRole) {
    case EUserAdminType.GENERIC:
      return <ReservationFormBusinessUsers />;
    case EUserAdminType.INSURANCE:
      return <ReservationFormInsuranceUsers />;
    case EUserAdminType.CORPORATE:
      return <ReservationFormExternalUsers />;
    default:
      return null;
  }
};

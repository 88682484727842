import { TextField } from "@mui/material";

import InsuranceProduct from "./InsuranceProduct";
import InsuranceCoverage from "./InsuranceCoverage";
import { FormGrid } from "../../common/FormComponents";
import { useFormikContext } from "formik";
import InsuranceCompanySelect from "../common/InsuranceRegionalCompanySelect";
export default function InsuranceInfo() {
  const { values, handleBlur, touched, errors, handleChange } =
    useFormikContext();

  return (
    <>
      <FormGrid>
        <TextField
          value={
            values?.insuranceData?.billingPermitCode
              ? values?.insuranceData?.billingPermitCode
              : ""
          }
          onChange={handleChange}
          onBlur={handleBlur}
          name="insuranceData.billingPermitCode"
          label="Laskutuslupatunnus"
          variant="outlined"
          error={
            touched?.insuranceData?.billingPermitCode &&
            !!errors.insuranceDataBillingPermitCode
          }
          helperText={
            touched?.insuranceData?.billingPermitCode &&
            errors.insuranceDataBillingPermitCode
          }
          fullWidth
        />
      </FormGrid>
      <FormGrid>
        <InsuranceCompanySelect label="Maksaja" />
      </FormGrid>
      <FormGrid>
        <InsuranceProduct />
      </FormGrid>
      <FormGrid>
        <InsuranceCoverage />
      </FormGrid>
    </>
  );
}

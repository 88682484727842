import React, { useContext } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { AppContext } from "../AppContext";
import { useFetchPricings } from "../services/fetchPricings";
import { useFormikContext } from "formik";

export default function PricingSelection({
  showPricings,
  name,
  label,
  required,
  disabled,
}) {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext();
  // Id of a removed service, if it was initially in use
  const [existed, setExisted] = React.useState(false);
  const { group, user } = useContext(AppContext);
  const { pricings } = useFetchPricings(group, user, {
    revalidateIfStale: false,
  });

  // Don't list removed pricings, unless they are already in use
  const filteredPricings = React.useMemo(() => {
    // Pricings are still loading
    if (!pricings) return null;
    const appliedPricingId = values[name] || "";
    return pricings.filter((pricing) => {
      // Save in state if removed service was initially in use
      if (!existed && pricing._removed && appliedPricingId === pricing.id) {
        setExisted(pricing.id);
      }
      // Filter out all removed pricings that were not initially in use
      if (pricing._removed && !existed) return false;
      return true;
    });
  }, [pricings, name, values, existed]);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
      error={touched[name] && !!errors[name]}
    >
      <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={values[name] || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        label={label}
        disabled={disabled}
        data-cy="pricingSelect"
        MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            showPricings();
          }}
        >
          Hallitse hinnastoja
        </MenuItem>

        {!required ? (
          <MenuItem value="">Ei hinnastoa / Ei saatavilla</MenuItem>
        ) : null}

        {pricingItems(filteredPricings, values[name])}
      </Select>
      {touched[name] && errors[name] && (
        <FormHelperText>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
}

const pricingItems = (pricings, vehiclePricingId) => {
  if (pricings) {
    return pricings.map((pricing) => (
      <MenuItem key={pricing.id} value={pricing.id}>
        <Grid container direction="column" item xs={12} data-cy="pricingList">
          <Grid item xs={8}>{`${pricing.name} ${
            pricing._removed ? "(poistettu)" : ""
          }`}</Grid>
          {(pricing.tax || pricing.tax === 0) && pricing.tax !== 24 && (
            <Grid
              item
              xs={4}
              style={{ fontSize: "10px", fontWeight: "bold" }}
            >{`ALV: ${pricing.tax} %`}</Grid>
          )}
        </Grid>
      </MenuItem>
    ));
  } else if (vehiclePricingId) {
    return (
      <MenuItem value={vehiclePricingId}>
        <Skeleton />
      </MenuItem>
    );
  } else {
    return null;
  }
};

import React, { useContext, useMemo, useState } from "react";
import { Autocomplete, Divider, ListItemText, MenuItem } from "@mui/material";
import { TextField, Typography } from "@mui/material";
import { AppContext } from "../../../AppContext";
import { useHandlePricing } from "../hooks/useHandlePricing";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";

export function PricingsList(props) {
  const {
    error,
    errorHelperText,
    freeSolo,
    label,
    selectedPricing,
    category,

    setEditablePricing,
  } = props;
  const { business, user } = useContext(AppContext);
  const [submitting, setSubmitting] = useState(false);

  const {
    pricingsLoading,
    businessPricings,
    organizationPricings,
    pricingsMutate,
  } = useFetchPricingsOrganization("pricingListDialog");

  const defaultOptions = useMemo(() => {
    return [...businessPricings, ...organizationPricings];
  }, [businessPricings, organizationPricings]);

  const options = useMemo(() => {
    const optionValues = [
      { name: "Luo uusi hinnasto", id: "CREATE_NEW" },
      ...defaultOptions.filter((item) => item?.orgBusinessId === business?.id),
    ];

    if (
      selectedPricing?.orgBusinessId === business?.id ||
      optionValues.length === 1
    ) {
      optionValues.unshift({
        name: `${user?.organization?.name} - hinta`,
        id: "ORGANIZATION_PRICING",
      });
    }

    return optionValues;
  }, [business?.id, defaultOptions, selectedPricing?.orgBusinessId]);

  const value = useMemo(() => {
    const handleValue = (selectedPricing) => {
      if (!selectedPricing) {
        return null;
      }
      if (selectedPricing?.orgBusinessId === business?.id) {
        return selectedPricing;
      } else {
        return options[1] ?? null;
      }
    };
    return handleValue(selectedPricing);
  }, [business?.id, options, selectedPricing]);
  const { handleUpdate } = useHandlePricing();
  const updatePricing = async ({ pricing, categoryId }) => {
    setSubmitting(true);
    await handleUpdate(pricing?.id, {
      businessCategoryAssociation: categoryId,
    });
    await handleUpdate(selectedPricing?.id, {
      businessCategoryAssociation: null,
    });
    await pricingsMutate();
    setSubmitting(false);
  };

  const handleSetOrganizationPricing = async () => {
    setSubmitting(true);

    await handleUpdate(selectedPricing?.id, {
      businessCategoryAssociation: null,
    });
    await pricingsMutate();
    setSubmitting(false);
  };

  return (
    <Autocomplete
      disabled={submitting}
      freeSolo={freeSolo}
      fullWidth
      value={value}
      loading={pricingsLoading}
      loadingText="Ladataan..."
      options={options}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      //  loading={nameAutoCompleteLoading}
      filterOptions={(options, state) => {
        return options?.filter((option) => {
          if (typeof option === "string") {
            if (option.toLowerCase().includes(state?.inputValue.toLowerCase()))
              return true;
            else return false;
          } else {
            if (
              option?.name
                .toLowerCase()
                .includes(state?.inputValue.toLowerCase())
            )
              return true;
            else return false;
          }
        });
      }}
      getOptionLabel={(option) => {
        if (selectedPricing?.orgBusinessId === business?.id) {
          return typeof option === "string" ? option : option.name;
        } else {
          return typeof option === "string"
            ? option
            : `${user?.organization?.name} - hinta`;
        }
      }}
      noOptionsText="Ei tuloksia"
      renderOption={(props, option) => {
        /*     const classObj = classes.find(
            (c) => c.productNro === option.productNro
          );
  */
        if (option?.id === "CREATE_NEW") {
          return (
            <React.Fragment key={option?.id}>
              <MenuItem
                disabled={submitting}
                {...props}
                onClick={() =>
                  setEditablePricing({
                    name: category?.name,
                    elements: selectedPricing?.elements ?? [],
                    businessCategoryAssociation: category?.id,
                  })
                }
              >
                <Typography>Luo uusi hinnasto</Typography>
              </MenuItem>
              <Divider />
            </React.Fragment>
          );
        }
        if (option?.id === "ORGANIZATION_PRICING") {
          const organizationName = user?.organization?.name ?? "Organisaation";
          return (
            <React.Fragment key={option?.id}>
              <MenuItem
                {...props}
                disabled={submitting}
                onClick={() => handleSetOrganizationPricing()}
              >
                <Typography>
                  <b>{organizationName} hinta</b>
                </Typography>
              </MenuItem>
            </React.Fragment>
          );
        }
        return (
          <MenuItem
            {...props}
            disabled={submitting}
            key={option?.id}
            onClick={() =>
              updatePricing({ pricing: option, categoryId: category?.id })
            }
          >
            <ListItemText primary={option?.name} />
          </MenuItem>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={error}
          helperText={errorHelperText}
          placeholder="Ei hinnastoa / Poistettu hinnasto"
        />
      )}
    />
  );
}

import { Grid, InputAdornment } from "@mui/material";
import { Field } from "formik";
import FormikInputTextArray from "~/components/Inputs/FormikInputTextArray";

type InsuranceInvoiceItemProps = {
  index: number;
  item: any;
};

function InsuranceInvoiceItem({ index, item }: InsuranceInvoiceItemProps) {
  return (
    <Grid container item spacing={1}>
      <Field
        index={index}
        label="Tuotenimi"
        disabled
        value={item.name}
        component={FormikInputTextArray}
        xs={12}
      />
      <Field
        index={index}
        label="Selite"
        disabled
        value={item.description}
        component={FormikInputTextArray}
        xs={12}
      />
      <Field
        index={index}
        label="Hinta"
        disabled
        value={item.unitprice}
        component={FormikInputTextArray}
        xs={6}
        md={2}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    </Grid>
  );
}

export default InsuranceInvoiceItem;

import { ThemeColors } from "../types";

export const lightTheme: ThemeColors = {
  main: "#497296",
  mainText: "#ffffff",
  containerBg: "#ffffff",
  drawerBackground: "#ffffff",
  drawerTitle: "#6e7682",
  drawerText: "rgba(0,0,0,0.7)",
  drawerSelected: "#eeeeee",
  drawerSelectedBG: "#1e293a",
  option: "rgba(0,0,0,0.15)",
  secondaryText: "#d3d4d7",
  dark: "#04060D",
  icon: "#616161",
  buttonbg: "white",
  background: { default: "#ffffff", paper: "#ffffff" },
};

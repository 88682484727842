import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useFetchVehicles } from "../../../../../../../../../../services/fetchVehicles";
import VehicleImage from "~/components/VehicleImage";

export default function ProductCardTitle(props) {
  const { item, mobileViewPort } = props;
  const { vehicleData, vehiclesLoading } = useFetchVehicles();
  const vehicle = vehicleData?.find((v) => item.id === v.id);

  const imageSize = mobileViewPort
    ? { height: "50px", width: "50px" }
    : { height: "100px", width: "100px" };
  return (
    <Stack
      direction={"row"}
      flex={1}
      alignItems={"center"}
      spacing={1}
      width={"100%"}
    >
      <Box /* sx={{ display: { xs: "none", sm: "block" } }} */>
        {vehiclesLoading ? (
          <Skeleton variant="rounded" sx={imageSize} />
        ) : (
          <VehicleImage vehicle={vehicle} variant="rounded" sx={imageSize} />
        )}
      </Box>
      <Stack width={"70%"} sx={{ overflow: "hidden" }}>
        <Typography
          sx={{
            fontSize: { sm: "1.2rem", xs: "1.1rem" },
            fontFamily: "Sofia Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {item?.name}
        </Typography>
        <Typography variant={"body2"} sx={{ fontWeight: 550 }}>
          {item?.registrationPlate}
        </Typography>
      </Stack>
    </Stack>
  );
}

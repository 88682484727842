import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useFormikContext } from "formik";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import SelectLanguage from "./SelectLanguage";

export default function DialogHeader(props) {
  const { activeStep } = props;
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { isSubmitting } = useFormikContext();

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={2}
      position={"sticky"}
      top={0}
      sx={{
        bgcolor: "white",
        zIndex: 99,
      }}
    >
      <Typography variant="h5" align="left">
        {activeStep === "selectProduct" ? "Varauksen tiedot" : "Yhteystiedot"}
      </Typography>

      <span style={{ flex: 1 }} />

      <Box>
        <SelectLanguage />
      </Box>

      <Box>
        <IconButton
          onClick={() => setEditReservationData(null)}
          disabled={isSubmitting}
        >
          <Close />
        </IconButton>
      </Box>
    </Stack>
  );
}

import {
  Popper,
  Box,
  ClickAwayListener,
  Paper,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState, useMemo, useContext } from "react";

import NotificationsContainer from "./NotificationsPopperComponent/NotificationsContainer";
import RedBulb from "./NotificationsPopperComponent/Bulb";
import { useNotifications } from "../utils/swr";

import { AppContext } from "../AppContext";

function Notifications({
  company,
  navBoxRef,
  openMessages,
  setOpenMessages,
  oldNotifications,
  newNotifications,
  markAsRead,
}) {
  const { user, mobileSmViewPort } = useContext(AppContext);
  const theme = useTheme();

  const notificationsCount = useMemo(() => {
    return newNotifications?.reduce(
      (prev, current) => prev + (current.status === "UNREAD"),
      0
    );
  }, [newNotifications]);

  return (
    <ClickAwayListener onClickAway={() => setOpenMessages(false)}>
      <Popper
        open={openMessages}
        anchorEl={navBoxRef.current}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "90%",
          width: "450px",
          zIndex: 9998,
        }}
      >
        <Paper
          sx={{
            borderRadius: "14px",
            marginTop: mobileSmViewPort ? "10px" : "10px",
            padding: "15px",
            maxHeight: mobileSmViewPort ? undefined : "400px",
            overflow: "auto",
            width: "100%",
          }}
        >
          <Grid p={1} container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  Lukemattomat:
                </Typography>
                <RedBulb
                  width="21px"
                  height="21px"
                  ml={1}
                  bgcolor={notificationsCount > 0 ? "red" : "rgba(0,0,0,0.45)"}
                >
                  <Typography variant="body2" color={theme.palette.grey[100]}>
                    {notificationsCount}
                  </Typography>
                </RedBulb>
              </Box>
            </Grid>
          </Grid>
          <Divider />
          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#9e9e9e",
              },
            }}
          >
            <NotificationsContainer
              notifications={newNotifications}
              readNotifications={oldNotifications}
              markAsRead={markAsRead}
            />
          </Box>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}

export default Notifications;

import { useField } from "formik";
import {
  Stack,
  InputAdornment,
  TextField,
  Avatar,
  ListItem,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Delete, Euro, Extension } from "@mui/icons-material";
import React, { useState } from "react";
import { centsToLocalString, parseToCents } from "../../../shared/money";
import { ServiceQuantitySelector } from "../utils/utilities";
import { useTheme } from "@emotion/react";
import { useFetchAdditionalServices } from "../../../services/fetchAdditionalServices";

export default function AdditionalServiceCardTemplate(props) {
  const { offerPrices } = props;
  const [field, meta, helpers] = useField("additionalServices");
  const { additionalServices } = useFetchAdditionalServices();

  const theme = useTheme();
  const serviceMeta = additionalServices.find(
    (s) => s.key === props.service?.key
  );
  const service = { ...props.service, ...serviceMeta };
  const handleRemove = () => {
    const newServices = field.value.filter((s) => s.key !== service?.key);
    helpers.setValue(newServices);
  };
  const avatarSize = "65px";
  return (
    <ListItem
      secondaryAction={
        service?.enableQuantity ? null : (
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        )
      }
      disableGutters
    >
      <Stack direction={"row"} flex={1} height={"100%"}>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: theme.palette.primary.main,
            height: avatarSize,
            width: avatarSize,
            marginTop: "4px",
          }}
        >
          <Extension sx={{ fontSize: "36px" }} />
        </Avatar>

        <Stack
          height={"100%"}
          justifyContent={"flex-end"}
          sx={{ marginLeft: "5px" }}
          flex={1}
        >
          <Typography noWrap sx={{ marginBottom: "5px" }}>
            {service?.description}
          </Typography>
          <Stack direction={"row"} flex={1} justifyContent={"space-between"}>
            <PriceField
              variant="standard"
              item={service}
              sx={{ maxWidth: "120px" }}
              offerPrices={offerPrices}
            />
            <ServiceQuantitySelector
              service={service}
              offerPrices={offerPrices}
            />
          </Stack>
        </Stack>
      </Stack>
    </ListItem>
  );
}

function PriceField(props) {
  const { item, offerPricesLoading, offerPrices } = props;
  const quantity = item?.quantity ?? 1;
  const [fieldInput, setFieldInput] = useState(() => item?.price ?? "");
  const [, meta, helpers] = useField("additionalServices");
  const { value } = meta;
  const { setValue } = helpers;
  const offerPrice =
    offerPrices?.find((offer) => offer.key === item.key)?.offerPrice ?? 0;

  const handleChangePrice = () => {
    if (fieldInput === "" || fieldInput === null) {
      return setValue(
        value.map((service) => {
          if (service.key === item.key) {
            return {
              ...service,
              price: null,
            };
          }
          return service;
        })
      );
    }
    if (fieldInput === item?.price) {
      return;
    }

    return setValue(
      value.map((service) => {
        if (service.key === item?.key) {
          return {
            ...service,
            price: fieldInput,
          };
        }
        return service;
      })
    );
  };

  const price = item?.price ? parseToCents(item?.price) : offerPrice;
  const unitPrice = price / quantity;

  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      <TextField
        variant="outlined"
        label="Hinta"
        placeholder={centsToLocalString(offerPrice)}
        value={fieldInput}
        onChange={(e) => setFieldInput(e.target.value)}
        onBlur={handleChangePrice}
        size="small"
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: 0,

          style: { textAlign: "right", marginRight: "3px" },
        }}
        sx={{ width: "135px" }}
        helperText={
          <Typography variant="caption">
            {item?.quantity > 1
              ? `${centsToLocalString(unitPrice.toFixed(2))} € / kpl`
              : null}
          </Typography>
        }
        InputProps={{
          endAdornment: offerPricesLoading ? (
            <CircularProgress size={20} />
          ) : (
            <InputAdornment position="end">
              <Euro sx={{ fontSize: "20px", mb: "3px" }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

export const reviewsByOrganization = /* GraphQL */ `
  query ReviewsByOrganization(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByOrganizationByStartTime(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        rating
        comment
        explanation
        reservationId
        companyId
        startTime
        returnTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const reviewsByBusiness = /* GraphQL */ `
  query ReviewsByBusiness(
    $orgBusinessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByBusinessByStartTime(
      orgBusinessId: $orgBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        rating
        comment
        explanation
        reservationId
        companyId
        startTime
        returnTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const reviewsByExternalBusiness = /* GraphQL */ `
  query ReviewsByExternalBusiness(
    $orgExternalBusinessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByExternalBusinessByStartTime(
      orgExternalBusinessId: $orgExternalBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        rating
        comment
        explanation
        reservationId
        companyId
        startTime
        returnTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import { IconButton } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useAtom } from "jotai";
import { Vehicle } from "~/graphql/API";
import { showHourlyReservations } from "../../../store";

type DropdownProps = { vehicle: Vehicle };

function Dropdown({ vehicle }: DropdownProps) {
  const [showHourly, setShowHourly] = useAtom(showHourlyReservations);

  return (
    <IconButton
      style={{
        transform: showHourly !== vehicle ? "rotate(180deg)" : "rotate(0deg)",
      }}
      onClick={(event) => {
        event.stopPropagation();

        setShowHourly((p) => (p === vehicle ? null : vehicle));
      }}
      size={"small"}
      sx={{ bgcolor: "white" }}
    >
      <ExpandMoreOutlinedIcon />
    </IconButton>
  );
}

export default Dropdown;

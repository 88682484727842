import { useState } from "react";
import DialogComponent from "./DialogComponent";

export const useConfirmation = (onConfirm) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirm = () => {
    onConfirm();
    setShowConfirmation(false);
  };

  const confirmationDialog = ({ message, confirmLabel, testName }) => {
    return (
      <DialogComponent
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        dialogClose={() => setShowConfirmation(false)}
        dialogAction={confirm}
        dialogActionText={confirmLabel}
        dialogActionColor={"error"}
        testName={testName}
      >
        {message}
      </DialogComponent>
    );
  };

  return [() => setShowConfirmation(true), confirmationDialog];
};

import React, { memo, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Cache } from "aws-amplify";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import { ExpandMore, Home, Settings, Store } from "@mui/icons-material";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import {
  Box,
  Divider,
  MenuItem,
  Typography,
  useTheme,
  Stack,
  Badge,
  Menu,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  MdOutlineMenu,
  MdBarChart,
  MdPostAdd,
  MdAssignmentAdd,
} from "react-icons/md";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import NavBarButton from "~/components/NavBarButton";
import IndexPageDatePicker from "~/components/IndexPageDatePicker";
import { useFetchExternalReservations } from "~/services/fetchReservations";
import NotificationsPopper from "~/components/NotificationsPopper";
import { useContext } from "react";
import { AppContext } from "~/AppContext";
import { useState } from "react";
import { RiOrganizationChart } from "react-icons/ri";
import DateInput from "~/components/DateInput";
import CustomerSearch from "~/components/CustomerSearch";
import SelectCalendarView from "~/components/SelectCalendarView";
import { useNotifications } from "~/utils/swr";
import { MdNotificationsNone } from "react-icons/md";
import { useFetchCompanies } from "~/services/fetchCompanies";
import { GetLogo } from "~/components/getLogo";
import FindVehicle from "~/components/FindVehicle";
import { FaCar } from "react-icons/fa";
import DatePickerCorporate from "~/components/DatePickerCorporate";
import { EconomyDatePicker } from "~/components/EconomyDatePicker";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

const NavBar = memo(function NavBar(props) {
  const {
    customerSearch,
    iconSize,
    setCustomerSearch,
    setDrawerOpen,
    openMessages,
    setOpenMessages,
    showExternalReservationsLink,
    setShowFindVehicle,
    showFindVehicle,
  } = props;

  const {
    company,
    dateRange,
    economyQueryDates,
    setEconomyQueryDates,
    mobileSmViewPort,
    mobileViewPort,
    selectedView,
    setDateRange,
    setEndDate,
    setStartDate,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    USER_ADMIN_INSURANCE,
    user,
  } = useContext(AppContext);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const theme = useTheme();
  const navBoxRef = useRef();
  const location = useLocation();
  const { externalReservations } = useFetchExternalReservations();
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState("eventsCalendar");

  const { oldNotifications, newNotifications, markAsRead } = useNotifications(
    company,
    setOpenMessages,
    user
  );

  const changeDateByPicker = (newValue) => {
    if (selectedView === "reservationTable") {
      setDateRange(newValue);
      setStartDate(newValue[0]);
      setEndDate(newValue[1]);
    }
  };

  const handleNavigate = (value) => {
    if (value === "eventsCalendar") {
      setSelectedPage("eventsCalendar");
      navigate("/");
    }
    if (value === "externalReservations") {
      setSelectedPage("externalReservations");
      navigate("/toimeksiannot");
    }
    if (value === "economy") {
      setSelectedPage("economy");
      navigate("/raportointi");
    }
    if (value === "overview") {
      setSelectedPage("overview");
      navigate("/overview");
    }
    if (value === "product") {
      setSelectedPage("product");
      navigate("/products");
    }

    if (value === "assignment") {
      navigate("/varaus/9971e8d6-c652-4588-a706-2d7f58edd4f5");
    }
  };

  const notificationsCount = useMemo(() => {
    return newNotifications?.reduce(
      (prev, current) => prev + (current.status === "UNREAD"),
      0
    );
  }, [newNotifications]);

  const findVehicleVisibility =
    user?.organizationId && user?.role !== "EXTERNAL_BUSINESS_ADMIN";

  return (
    <AppBar
      position="sticky"
      color={"transparent"}
      sx={{
        display: "flex",
        justifyContent: "center",
        bgcolor: theme.palette.background.default,
        /*
        borderWidth: 0, */
        boxShadow: 0,

        height: "var(--appBarHeight)",
      }}
    >
      {!mobileViewPort && (
        <Toolbar>
          <div className="toolbarContainer">
            <div className="navbox">
              <Stack
                direction="row"
                alignItems="center"
                spacing={{
                  [MOBILE_BREAKPOINT]: 1,
                  [DESKTOP_BREAKPOINT]: 2,
                }}
                width={"100%"}
              >
                <NavBarButton
                  handleClick={() => setDrawerOpen(true)}
                  ToolTipTitle="Valikko"
                  icon={<MdOutlineMenu style={{ fontSize: iconSize }} />}
                  selectedPage={null}
                  testName="openSidebarButton"
                  visibility={true}
                  iconSize={iconSize}
                />
                <BusinessSelect
                  visibility={
                    user?.role === "ORGANIZATION_ADMIN" || user?.orgBusinessIds
                  }
                />

                <CompanySelect visibility={!user?.organizationId} />
                {USER_ADMIN_CORPORATE && <GetLogo customHeight={"52px"} />}
              </Stack>
            </div>

            <div
              className="navbox"
              ref={navBoxRef}
              style={{ position: "relative" }}
            >
              <span>
                <Stack direction="row" justifyContent="center" spacing={2}>
                  <NavBarButton
                    handleClick={() => {
                      setEditReservationData(true);
                    }}
                    selectedPage={selectedPage}
                    iconSize={iconSize}
                    ToolTipTitle={"Luo uusi varaus"}
                    icon={<MdPostAdd style={{ fontSize: iconSize }} />}
                    visibility={USER_ADMIN_INSURANCE || USER_ADMIN_CORPORATE}
                    testName="createReservationButton"
                  />
                  <NavBarButton
                    handleNavigate={handleNavigate}
                    selectedPage={selectedPage}
                    iconSize={iconSize}
                    ToolTipTitle={"Varauskalenteri"}
                    navigateTo={"eventsCalendar"}
                    icon={<Home style={{ fontSize: iconSize }} />}
                    testName="reservationCalendarButton"
                    visibility={true}
                  />
                  {showExternalReservationsLink && (
                    <Badge
                      color="error"
                      badgeContent={externalReservations?.length}
                    >
                      <NavBarButton
                        handleNavigate={handleNavigate}
                        selectedPage={selectedPage}
                        iconSize={iconSize}
                        ToolTipTitle={"Toimeksiannot"}
                        navigateTo={"externalReservations"}
                        icon={
                          <MdAssignmentAdd style={{ fontSize: iconSize }} />
                        } // replace with the desired icon
                        visibility={showExternalReservationsLink}
                        testName="externalReservationsButton"
                      />
                    </Badge>
                  )}
                  <NavBarButton
                    handleNavigate={handleNavigate}
                    selectedPage={selectedPage}
                    iconSize={iconSize}
                    ToolTipTitle={"Raportointi"}
                    navigateTo={"economy"}
                    icon={<MdBarChart style={{ fontSize: iconSize }} />}
                    testName="economyViewButton"
                    visibility={USER_ADMIN_GENERIC && !mobileSmViewPort}
                  />

                  <NavBarButton
                    handleClick={() =>
                      setCustomerSearch((prevState) => !prevState)
                    }
                    selectedPage={selectedPage}
                    iconSize={iconSize}
                    ToolTipTitle={"Asiakashaku"}
                    icon={<SearchIcon style={{ fontSize: iconSize }} />}
                    testName="customerSearchButton"
                    visibility={true}
                  />

                  <NavBarButton
                    handleClick={() =>
                      setShowFindVehicle((prevState) => !prevState)
                    }
                    selectedPage={selectedPage}
                    iconSize={iconSize}
                    ToolTipTitle={"Ajoneuvohaku"}
                    icon={<FaCar style={{ fontSize: iconSize }} />}
                    //testName="customerSearchButton"
                    visibility={findVehicleVisibility}
                  />
                  <Badge color="error" badgeContent={notificationsCount}>
                    <div id="notificationsAnchor">
                      <NavBarButton
                        handleClick={() => {
                          setOpenMessages((prevState) => !prevState);
                        }}
                        ToolTipTitle="Viestit"
                        icon={
                          <MdNotificationsNone style={{ fontSize: iconSize }} />
                        }
                        selectedPage={null}
                        testName="openNotificationsButton"
                        visibility={user?.role !== "EXTERNAL_BUSINESS_ADMIN"}
                        iconSize={iconSize}
                      />
                    </div>
                  </Badge>
                </Stack>
              </span>
            </div>
            <div className="navbox">
              <span style={{ flex: 1 }} />
            </div>
          </div>
        </Toolbar>
      )}

      <Toolbar>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          {mobileViewPort && (
            <NavBarButton
              handleClick={() => setDrawerOpen(true)}
              ToolTipTitle="Valikko"
              icon={<MdOutlineMenu style={{ fontSize: iconSize }} />}
              selectedPage={null}
              testName="openSidebarButton"
              visibility={true}
              iconSize={iconSize}
            />
          )}

          <div className="navbox">
            <span style={{ flex: 1 }} />
          </div>
          {location?.pathname === "/raportointi" && USER_ADMIN_GENERIC && (
            <>
              <EconomyDatePicker
                NavigateNext={NavigateNext}
                selectedView={selectedView}
                NavigateBefore={NavigateBefore}
                dateRange={dateRange}
                changeDateByPicker={changeDateByPicker}
                DateInput={DateInput}
                iconSize={iconSize}
              />

              {mobileViewPort && (
                <div className="navbox">
                  <span style={{ flex: 1 }} />
                </div>
              )}
            </>
          )}
          {location?.pathname === "/" && USER_ADMIN_GENERIC && (
            <>
              <IndexPageDatePicker
                NavigateNext={NavigateNext}
                selectedView={selectedView}
                NavigateBefore={NavigateBefore}
                dateRange={dateRange}
                changeDateByPicker={changeDateByPicker}
                DateInput={DateInput}
                iconSize={iconSize}
              />

              {mobileViewPort && (
                <div className="navbox">
                  <span style={{ flex: 1 }} />
                </div>
              )}
            </>
          )}
          {(USER_ADMIN_CORPORATE || USER_ADMIN_INSURANCE) && (
            <>
              <DatePickerCorporate
                NavigateNext={NavigateNext}
                selectedView={selectedView}
                NavigateBefore={NavigateBefore}
                dateRange={dateRange}
                changeDateByPicker={changeDateByPicker}
                DateInput={DateInput}
                iconSize={iconSize}
              />
              <div className="navbox">
                <span style={{ flex: 1 }} />
              </div>
            </>
          )}
          {mobileViewPort && (
            <div className="navbox">
              <span style={{ flex: 1 }} />
            </div>
          )}
          {user?.role !== "EXTERNAL_BUSINESS_ADMIN" && (
            <>
              <div className="navbox">
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent={"flex-end"}
                  width={"100%"}
                >
                  <SelectCalendarView />
                  {!mobileViewPort && <VehicleTableSettings />}
                </Stack>
              </div>
            </>
          )}
        </Stack>
      </Toolbar>

      {customerSearch && (
        <CustomerSearch
          // editReservationData={editReservationData}
          navBoxRef={navBoxRef}
          company={company}
          onClose={() => setCustomerSearch(false)}
          setCustomerSearch={setCustomerSearch}
          customerSearch={customerSearch}
        />
      )}

      {showFindVehicle && (
        <FindVehicle
          // editReservationData={editReservationData}
          navBoxRef={navBoxRef}
          company={company}
          onClose={() => setShowFindVehicle(false)}
          showFindVehicle={showFindVehicle}
          setShowFindVehicle={setShowFindVehicle}
        />
      )}

      {openMessages && (
        <NotificationsPopper
          company={company}
          iconSize={iconSize}
          navBoxRef={navBoxRef}
          openMessages={openMessages}
          setOpenMessages={setOpenMessages}
          oldNotifications={oldNotifications}
          newNotifications={newNotifications}
          markAsRead={markAsRead}
        />
      )}
    </AppBar>
  );
});

const MOBILE_BREAKPOINT = "sm";
const DESKTOP_BREAKPOINT = "md";

function VehicleTableSettings() {
  const { rowDisplayStyle, setRowDisplayStyle } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenViewMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const storeValuesToCache = async (value) => {
    try {
      await Cache.setItem("rowDisplayStyle", value);
    } catch (error) {
      console.error("Error storing data to cache:", error);
    }
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleOpenViewMenu}>
        <Settings />
      </IconButton>

      <Menu
        id="row-display-style"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setRowDisplayStyle("regular");
            storeValuesToCache("regular");
            handleClose();
          }}
          selected={rowDisplayStyle === "regular"}
        >
          <ListItemIcon>
            <DensityMediumIcon />
          </ListItemIcon>
          <ListItemText>Tavallinen</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowDisplayStyle("compact");
            storeValuesToCache("compact");
            handleClose();
          }}
          selected={rowDisplayStyle === "compact"}
        >
          <ListItemIcon>
            <DensitySmallIcon />
          </ListItemIcon>
          <ListItemText>Kompakti</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function CompanySelect({ visibility }) {
  const {
    company,
    mobileSmViewPort,
    mobileViewPort,
    setShowAllCompanies,
    showAllCompanies,
    setCompany,
    user,
    business,
  } = useContext(AppContext);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const { companies } = useFetchCompanies(user?.group, business?.id);

  const handleChangeCompany = (value) => {
    if (value === "allCompanies") {
      setShowAllCompanies(true);
      handleCloseCompanyMenu();
    } else {
      setShowAllCompanies(false);
      setCompany(() => companies.find((company) => company.id === value));
      handleCloseCompanyMenu();
    }
  };

  const handleOpenCompanyMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCompanyMenu = () => {
    setAnchorEl(null);
  };
  if (!visibility) return;
  return (
    <Box
      sx={{
        maxWidth: !mobileViewPort ? "380px" : "100%",
        width: "100%",
        height: mobileSmViewPort ? "46px" : "56px",
        border: "1px solid lightgrey",
        borderRadius: "15px",
        cursor: "pointer",
        transition: "250ms ease-in-out",

        "&:hover": {
          color: "black",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderColor: theme.palette.primary.main,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: " row",
          alignItems: "center",
          height: "100%",
          overflow: "hidden",
        }}
        onClick={handleOpenCompanyMenu}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.palette.primary.main,
            borderTopLeftRadius: "14px",
            borderBottomLeftRadius: "14px",
            height: mobileSmViewPort ? "46px" : "56px",
            width: mobileSmViewPort ? "46px" : "56px",
          }}
        >
          <Store
            sx={{ fontSize: mobileSmViewPort ? 26 : 32, color: "white" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: mobileSmViewPort ? 11 : 12,
                fontWeight: 550,
                fontFamily: "Sofia Pro",
              }}
            >
              TOIMIPISTE
            </Typography>
            <ExpandMore
              sx={{
                fontSize: "16px",
                marginLeft: "7px",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: mobileSmViewPort ? 10 : 14,
              fontFamily: "Roboto",
            }}
            noWrap
          >
            {showAllCompanies ? "Kaikki toimipisteet" : company?.name}
          </Typography>
        </Box>
      </Box>
      <Menu
        sx={{ mt: "60px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseCompanyMenu}
        PaperProps={{
          style: {
            borderRadius: 15,
            margin: 0,
            padding: 10,
            maxHeight: "400px",
            minWidth: "300px",
          },
        }}
        data-cy="companySelectList"
      >
        <Box
          display={"flex"}
          flexDirection="column"
          sx={{ marginBottom: "10px" }}
        >
          <Typography
            sx={{
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              marginBottom: "7px",
            }}
            align="center"
          >
            Valitse toimipiste
          </Typography>
          <Divider variant="middle" />
        </Box>
        <MenuItem
          value={"allCompanies"}
          onClick={() => handleChangeCompany("allCompanies")}
        >
          <Typography sx={{ fontWeight: 550 }}>Kaikki toimipisteet</Typography>
        </MenuItem>
        {companies &&
          companies.map((company, idx) => (
            <MenuItem
              value={company.id}
              key={idx}
              onClick={() => handleChangeCompany(company?.id)}
            >
              {company?.name}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}

function BusinessSelect({ visibility }) {
  const {
    user,
    mobileSmViewPort,
    mobileViewPort,
    selectedBusiness,
    FEATURE_BUSINESS_TITLE,
    setSelectedBusiness,
    setShowAllCompanies,
  } = useContext(AppContext);
  let businesses = user?.businessByOrg?.items;

  const { role, orgBusinessIds } = user;
  if (role === "BUSINESS_ADMIN" && orgBusinessIds) {
    businesses = businesses.filter((business) =>
      orgBusinessIds.some((businessId) => businessId === business.id)
    );
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleChangeBusiness = (value) => {
    if (value === "organizationAdmin") {
      setShowAllCompanies(true);
      setSelectedBusiness(null);
      setAnchorEl(null);
    } else {
      setSelectedBusiness(value);
      setAnchorEl(null);
    }
  };
  const handleCloseBusinessMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenBusinessMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const theme = useTheme();
  if (!visibility) return;
  return (
    <Box
      sx={{
        maxWidth: !mobileViewPort ? "380px" : "100%",
        width: "100%",
        height: mobileSmViewPort ? "46px" : "56px",
        border: "1px solid lightgrey",
        borderRadius: "15px",
        cursor: "pointer",
        transition: "250ms ease-in-out",

        "&:hover": {
          color: "black",
          backgroundColor: "rgba(0,0,0,0.05)",
          borderColor: theme.palette.primary.main,
          boxShadow: theme.shadows[4],
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: " row",
          alignItems: "center",
          height: "100%",
          overflow: "hidden",
        }}
        onClick={handleOpenBusinessMenu}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: theme.palette.primary.main,
            borderTopLeftRadius: "14px",
            borderBottomLeftRadius: "14px",
            height: mobileSmViewPort ? "46px" : "56px",
            width: mobileSmViewPort ? "46px" : "56px",
          }}
        >
          <RiOrganizationChart
            style={{ fontSize: mobileSmViewPort ? 26 : 32, color: "white" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: mobileSmViewPort ? 11 : 12,
                fontWeight: 550,
                fontFamily: "Sofia Pro",
              }}
            >
              {FEATURE_BUSINESS_TITLE ? "YRITTÄJÄ" : "KAUPUNKI"}
            </Typography>
            <ExpandMore
              sx={{
                fontSize: "16px",
                marginLeft: "7px",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: mobileSmViewPort ? 10 : 14,
              fontFamily: "Roboto",
            }}
          >
            {!selectedBusiness ? "Pääkäyttäjä" : selectedBusiness?.name}
          </Typography>
        </Box>
      </Box>
      <Menu
        sx={{ mt: "60px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseBusinessMenu}
        PaperProps={{
          style: {
            borderRadius: 15,
            margin: 0,
            padding: 10,
            maxHeight: "400px",
            minWidth: "300px",
          },
        }}
        data-cy="businessSelectList"
      >
        <Box
          display={"flex"}
          flexDirection="column"
          sx={{ marginBottom: "10px" }}
        >
          <Typography
            sx={{
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              marginBottom: "7px",
            }}
            align="center"
          >
            {FEATURE_BUSINESS_TITLE ? "Valitse yrittäjä" : "Valitse kaupunki"}
          </Typography>
          <Divider variant="middle" />
        </Box>
        {user?.role === "ORGANIZATION_ADMIN" && (
          <MenuItem
            value={"allCompanies"}
            onClick={() => handleChangeBusiness("organizationAdmin")}
          >
            <Typography sx={{ fontWeight: 550 }}>Pääkäyttäjä</Typography>
          </MenuItem>
        )}
        {businesses &&
          businesses
            .sort((b1, b2) => {
              if (b1.name > b2.name) {
                return 1;
              } else if (b2.name > b1.name) {
                return -1;
              }
              return 0;
            })
            .map((business, idx) => (
              <MenuItem
                value={business.id}
                key={idx}
                onClick={() => handleChangeBusiness(business)}
              >
                {business?.name}
              </MenuItem>
            ))}
      </Menu>
    </Box>
  );
}

export default NavBar;

import { useFormikContext } from "formik";
import { getTotal } from "~/utils/getTotal";
import { Box, Grid, useTheme } from "@mui/material";
import { defaultInvoiceItem } from "../form/defaultValues";

function InvoiceTotal() {
  const theme = useTheme();
  const { values } = useFormikContext<any>();

  return (
    <Grid container fontWeight="bold" spacing={2} marginTop={-5}>
      <Grid item xs="auto" paddingTop={0}>
        Yhteensä:
      </Grid>

      <Grid item xs="auto">
        <Grid container>
          {values.items
            .reduce((acc: number, item: typeof defaultInvoiceItem) => {
              const itemPrice = getTotal(parseFloat(item.unitprice) * 100, {
                quantity: item.quantity,
              });

              return acc + itemPrice;
            }, 0)
            .toFixed(2)}
          <Box
            marginLeft={1}
            alignContent="end"
            fontSize="85%"
            color={theme.palette.text.secondary}
          >
            (sis. alv)
          </Box>
        </Grid>

        <Grid container>
          {values.items
            .reduce((acc: number, item: typeof defaultInvoiceItem) => {
              const unitPrice = parseFloat(item.unitprice) * 100;

              const itemPrice = getTotal(unitPrice, {
                quantity: item.quantity,
                tax: item.vat,
                removeTax: true,
              });

              return acc + itemPrice;
            }, 0)
            .toFixed(2)}
          <Box
            marginLeft={1}
            alignContent="end"
            fontSize="85%"
            color={theme.palette.text.secondary}
          >
            (ilman alv)
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InvoiceTotal;

import { API, graphqlOperation } from "aws-amplify";
import useSWR from "swr";
import { useContext, useMemo } from "react";

import { AppContext } from "../AppContext";
import { useFetchCompanies } from "../services/fetchCompanies";
import { useCategories } from "../queries/useCategories";
import { getReservationOffersAdmin } from "../graphql/queries";
import { isTimesValid } from "../components/ReservationDialogV2/utils/isTimesValid";

export function useFetchOffers({ disableCategoryDefaultsStacking, values }) {
  let fetcher = null;

  const { group, business } = useContext(AppContext);
  const { companies } = useFetchCompanies(group, business?.id);
  const { orgExternalBusinessId, companyId } = values;
  const companyIds = companies.map((company) => company.id);
  const categories = useCategories();

  const queryResource = {
    companyIds: companyIds,
    reservationCompanyId: companyId,
    startTime: values.startTime,
    returnTime: values.returnTime,
    disableCategoryDefaultsStacking: true, //TODO: set correct source for this
    orgExternalBusinessId: orgExternalBusinessId,
    group: group,
    orgBusinessId: business?.id,
  };

  const enableQuery =
    queryResource?.startTime &&
    queryResource?.returnTime &&
    companyIds?.length > 0 &&
    (queryResource?.orgBusinessId || queryResource?.group);

  const key = isTimesValid(values?.startTime, values?.returnTime)
    ? enableQuery
      ? [queryResource]
      : null
    : null;
  fetcher = () => fetchGetReservationOffers(queryResource);

  const { data, error, isLoading } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    //  revalidateIfStale: false,
  });

  const vehiclesByCategory = useMemo(() => {
    const result = [];
    for (let item of categories) {
      const offers = [];
      data?.forEach((offer) => {
        if (offer.vehicle?.category?.id === item.id) {
          offers.push(offer);
        }
      });
      if (offers.length > 0) result.push({ category: item, offers: offers });
    }
    return (
      result?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }) ?? []
    );
  }, [data, categories]);

  return {
    offersData: vehiclesByCategory,
    offersDataLoading: isLoading,
    offersDataError: error,
  };
}

const fetchGetReservationOffers = async ({
  companyIds,
  reservationCompanyId,
  startTime,
  returnTime,
  disableCategoryDefaultsStacking,
  orgExternalBusinessId,
  group,
  orgBusinessId,
}) => {
  const response = await API.graphql(
    graphqlOperation(getReservationOffersAdmin, {
      companyIds,
      reservationCompanyId,
      startTime: startTime.toJSON(),
      returnTime: returnTime.toJSON(),
      disableCategoryDefaultsStacking: disableCategoryDefaultsStacking,
      group: group,
      businessId: orgBusinessId,
      externalBusinessId: orgExternalBusinessId,
    })
  );

  const result = response.data.getReservationOffersAdmin.map((r) => ({
    ...r,
    startTime: new Date(r.startTime),
    returnTime: new Date(r.returnTime),
    freeTimes: r.freeTimes.map((f) => ({
      firstStartTime: new Date(f.firstStartTime),
      lastStartTime: new Date(f.lastStartTime),
      lastReturnTime: new Date(f.lastReturnTime),
    })),
    vehicle: {
      ...r.vehicle,
      availability: true,
      price: r.vehicleOfferPrice,
      additionalServiceOffers: r.additionalServiceOffers,
    },
  }));
  if (result) {
    return result;
  } else return [];
};

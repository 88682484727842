import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { useSettingsContext } from "../../SettingsContext";
import { EDeviceType } from "~/enums/device-type";

type HeaderDateCardProps = {
  date: number;
};

function HeaderDateCard({ date }: HeaderDateCardProps) {
  const { colVariant, deviceType } = useSettingsContext();

  return (
    <div className={styles.root}>
      <Typography variant="body1">
        {format(
          date,
          colVariant === "compact" || deviceType === EDeviceType.MOBILE
            ? "EEEEEE"
            : "eeee",
          {
            locale: fi,
          }
        )}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontWeight: "bolder",
        }}
      >
        {format(date, "dd.MM.", { locale: fi })}
      </Typography>
    </div>
  );
}

export default HeaderDateCard;

import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Cache } from "aws-amplify";

import DialogContent from "../../components/DialogContent";
import { ReservationForm } from "./content/ReservationForm";
import SideMenu from "../../components/sidemenu";
import Content from "./content";

export default function ReservationFormBusinessUsers() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showAllContent, setShowAllContent] = useState(false);
  const [selectedTab, setSelectedTab] = useState("summary");

  useEffect(() => {
    const getDisplayStyle = async () => {
      const cachedDataAdativeView = await Cache.getItem("adaptiveView");
      if (cachedDataAdativeView) {
        setShowAllContent(cachedDataAdativeView);
      }
      return false;
    };
    getDisplayStyle();
  }, []);

  return (
    <ReservationForm
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      showAllContent={showAllContent}
    >
      <DialogContent
        openDrawer={openDrawer}
        selectedTab={selectedTab}
        setOpenDrawer={setOpenDrawer}
        setSelectedTab={setSelectedTab}
        setShowAllContent={setShowAllContent}
        showAllContent={showAllContent}
      >
        <RegularView
          openDrawer={openDrawer}
          selectedTab={selectedTab}
          setOpenDrawer={setOpenDrawer}
          setSelectedTab={setSelectedTab}
          showAllContent={showAllContent}
        />
      </DialogContent>
    </ReservationForm>
  );
}

function RegularView(props) {
  const {
    openDrawer,
    selectedTab,
    setOpenDrawer,
    setSelectedTab,
    showAllContent,
  } = props;
  const containerRef = useRef(null);

  return (
    <Stack
      direction={"row"}
      sx={{ flex: 1, overflow: "auto", position: "relative" }}
    >
      <SideMenu
        openDrawer={openDrawer}
        selectedTab={selectedTab}
        setOpenDrawer={setOpenDrawer}
        setSelectedTab={setSelectedTab}
        visibility={!showAllContent}
      />

      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
        }}
        ref={containerRef}
      >
        <Content
          containerRef={containerRef}
          selectedTab={selectedTab}
          showAllContent={showAllContent}
        />
      </Box>
    </Stack>
  );
}

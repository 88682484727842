import { API, graphqlOperation } from "aws-amplify";
import { createPricing, updatePricing } from "../../../graphql/mutations";
import { captureError } from "../../../utilities";

export const useHandlePricing = (props) => {
  return {
    handleCreate: handleCreate,
    handleUpdate: handleUpdate,
    handleDelete: handleDelete,
  };
};

const handleUpdate = async (pricingId, newPricing) => {
  delete newPricing.__typename;
  delete newPricing?.createdAt;
  delete newPricing?.updatedAt;
  try {
    if (pricingId) {
      const result = await API.graphql(
        graphqlOperation(updatePricing, {
          input: {
            ...newPricing,
            id: pricingId,
          },
        })
      );
      if (result) {
        return result?.data?.updatePricing;
      }
    }
  } catch (e) {
    captureError("Upsert pricing failed", "UPSERT_PRICING_FAILED", e);
    //  notification.show("Jokin meni vikaan");
  }
};

const handleDelete = async (pricingId) => {
  try {
    const result = await API.graphql(
      graphqlOperation(updatePricing, {
        input: {
          _removed: true,
          id: pricingId,
        },
      })
    );

    if (result) {
      return { response: "success", result: result };
    }
  } catch (error) {
    console.log("ERROR", error);
  }
};

const handleCreate = async (newPricing) => {
  try {
    const result = await API.graphql(
      graphqlOperation(createPricing, {
        input: newPricing,
      })
    );
    if (result) {
      return { response: "success" };
    }
  } catch (error) {
    console.log("ERROR", error);
  }
};

import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Box,
  Stack,
} from "@mui/material";

import { useFormikContext } from "formik";
import { FormGrid } from "../../../../common/FormComponents";
import { useReservationContext } from "../../../ReservationContext";
import { AppContext } from "../../../../../AppContext";
import ProductImage from "../../../../ProductImage";
import { QuantitySelector } from "../../../common/utils";
import ReservationPriceField from "../../../common/ReservationPriceField";

export default function AdditionalServiceList({
  categories,
  vehicleQuantity,
  additionalServiceOffers,
}) {
  if (additionalServiceOffers?.length === 0) {
    return <EmptyState />;
  }

  return (
    <FormGrid>
      <Stack spacing={4} flex={1}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          {additionalServiceOffers?.length > 0 &&
            additionalServiceOffers.map((s, index) => {
              // Handle if service is "deleted"
              if (!s?.hide && categories.includes(s?.category)) {
                return (
                  <AdditionalServiceField
                    defaultPrice={s?.offerPrice}
                    service={s}
                    vehicleQuantity={vehicleQuantity}
                    additionalServiceOffers={additionalServiceOffers}
                    key={s?.key}
                  />
                );
              }
              return null;
            })}
        </Stack>
      </Stack>
    </FormGrid>
  );
}

function EmptyState() {
  let isServices = false;

  return (
    <FormGrid>
      {isServices ? null : (
        <Typography>Tuotteella ei lisättyjä palveluja</Typography>
      )}
    </FormGrid>
  );
}

const AdditionalServiceField = ({
  defaultPrice,
  isDefaultPriceLoading,
  service,
  vehicleQuantity,
  additionalServiceOffers,
  ...props
}) => {
  const {
    addNewServiceOffer,
    removeServiceOffer,
    selectedServiceOffers,
    modifyServiceOfferPrice,
  } = useReservationContext();

  const { modifyServiceOfferQuantity } = useReservationContext();
  const [isSelected, setIsSelected] = useState(() => {
    const getState = selectedServiceOffers.find((s) => {
      const serviceIdentifier = service.id ?? service.key; // id may not exist for dynamically created Service
      return s?.id === serviceIdentifier || s?.key === serviceIdentifier;
    });
    if (getState) {
      return true;
    } else {
      return false;
    }
  });
  const serviceId = service?.key ?? service?.id;
  const { values, handleBlur, handleChange } = useFormikContext();
  const { mobileSmViewPort } = useContext(AppContext);

  return (
    <Stack
      sx={{
        position: "relative",
        flexDirection: "row",
        marginY: "3px",
        alignItems: "center",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <Box>
        <FormControlLabel
          control={
            <Switch
              {...props}
              name={values.additionalServiceKeys[serviceId]}
              checked={isSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsSelected(true);
                  addNewServiceOffer(service);
                } else {
                  setIsSelected(false);
                  removeServiceOffer(service.key);
                }
              }}
            />
          }
          // label={service.description}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ProductImage
          vehicleId={service?.key}
          productImage={service?.image}
          //     imageSize={mobileViewPort ? "large" : undefined}
          size={mobileSmViewPort ? 60 : 60}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: mobileSmViewPort ? "column" : "row",
          width: "100%",
          alignItems: mobileSmViewPort ? "flex-start" : "center",
        }}
      >
        <Typography>{service?.description}</Typography>

        <Grid
          container
          item
          sx={{
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {isSelected && service?.enableQuantity && (
            <Grid
              item
              sx={{ maxWidth: "105px", marginY: "2px", marginX: "10px" }}
            >
              <QuantitySelector
                visibility={true}
                targetId={service?.key}
                quantityModifier={modifyServiceOfferQuantity}
                quantity={service?.quantity}
                maxQuantity={service?.maxQuantity}
              />
            </Grid>
          )}

          <Box sx={{ width: "150px" }}>
            <ReservationPriceField
              name={"servicePrice-" + serviceId}
              enableQuantity={service.enableQuantity}
              vehicleQuantity={vehicleQuantity}
              service={service}
              priceModifier={modifyServiceOfferPrice}
            />
          </Box>
        </Grid>
        {service.enableTextInput &&
          values.additionalServiceKeys.includes(serviceId) && (
            <Grid item>
              <TextField
                name={service.key + "Details"}
                label={service.inputLabel}
                size="small"
                value={values[service.key + "Details"] || ""}
                onBlur={handleBlur}
                onChange={handleChange}
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
      </Box>
    </Stack>
  );
};

import asNumber from "./asNumber";

type Options = {
  quantity?: number;
  tax?: number; // Used to add tax to vat-excluded price
  removeTax?: boolean; // Used with tax to remove tax from vat-included price
  discount?: number;
};

export function getTotal(price = 0, options?: Options) {
  let value = asNumber(price);

  if (options?.quantity) {
    value *= options.quantity;
  }

  if (options?.tax) {
    const tax = asNumber(options.tax);

    if (options?.removeTax) {
      value = (100 * value) / (100 + tax);
    } else {
      value *= 1 + tax / 100;
    }
  }

  if (options?.discount) {
    value -= asNumber(options.discount);
  }

  const fixed = (value / 100).toFixed(2);

  return parseFloat(fixed);
}

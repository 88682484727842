import { API, graphqlOperation } from "aws-amplify";

import { useContext, useEffect, useState } from "react";
import { searchReservationsByVehicle } from "../graphql/queries";
import { AppContext } from "../AppContext";

export function useGetReservationHistory(vehicle) {
  const { business, group } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (!vehicle?.id) {
        return [];
      }
      setLoading(true);
      try {
        const response = await API.graphql(
          graphqlOperation(searchReservationsByVehicle, {
            vehicleId: vehicle?.id,
            orgBusinessId: business?.id,
            group: group,
          })
        );

        if (response?.data) {
          setData(response?.data?.searchReservationsByVehicle);
        }
      } catch (e) {
        console.error("fetchReservations error", e);
        //  captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [vehicle, business?.id, group]);

  const history = data;

  return {
    reservationsHistory: history,
    loading: loading,
  };
}

import "./EconomyDialog.css";
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  IconButton,
  Paper,
} from "@mui/material";
import "../app-theme.css";
import { FcRight } from "react-icons/fc";
export default function InfoContainerSmall({
  containerStyle,
  value,
  title,
  desc,
  icon,
  loading,
  testName,
  overview,
  overviewIcon,
  onClick,
}) {
  return (
    <Box
      sx={{
        display: "flex",

        width: "100%",
        borderRadius: "14px",
        overflow: "hidden",
      }}
    >
      <Paper sx={{ width: "100%" }}>
        <Grid
          className={
            containerStyle === "light"
              ? "infoContainer-small-light"
              : "infoContainer-small-dark"
          }
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          data-cy={testName}
        >
          <Grid item xs={1} style={{ margin: 10 }}>
            {icon}
          </Grid>

          <Grid
            container
            item
            xs={8}
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ paddingLeft: 25 }}
          >
            {desc && <Grid item>{desc}</Grid>}
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,

                opacity: 0.7,
              }}
            >
              {title}
            </Typography>
            {value && !loading && (
              <Typography
                variant="h5"
                style={{
                  fontFamily: "Sofia Pro",
                  fontSize: 32,
                }}
              >
                {value}
              </Typography>
            )}
            {loading && <CircularProgress size={35} />}
          </Grid>
        </Grid>
        {overview && (
          <Box sx={{ position: "relative", height: "30px" }}>
            <Box
              sx={{
                position: "absolute",
                top: -25,
                left: 10,
                height: "50px",
                width: "50px",
                bgcolor: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                  bgcolor: "rgba(0,0,0,0.09)",
                  height: "90%",
                  borderRadius: "50%",
                }}
              >
                {overviewIcon}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%",
                zIndex: 999,
              }}
            >
              <IconButton onClick={onClick}>
                <FcRight style={{ fontSize: "24px" }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
}

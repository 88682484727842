import { Grid, TextField } from "@mui/material";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import * as Sentry from "@sentry/react";
import { API, graphqlOperation } from "aws-amplify";
import { isValid } from "date-fns";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNotification } from "../Notification";
import { upsertReservationAdmin } from "../../graphql/mutations";
import captureError from "../../utils/capture-error";
import DialogComponent from "../DialogComponent";
import { AppContext } from "../../AppContext";
import { useFetchReservations } from "../../services/fetchReservations";

const nextFullHour = (date) => {
  const hour = 3600000;
  return new Date((Math.floor(date.getTime() / hour) + 1) * hour);
};

const validate = (values) => {
  const errors = {};
  const GENERAL_ERROR_MSG = "Täytä tämä kenttä";
  const TIME_ERROR_MSG = "Virheellinen aikamuoto";

  if (!values.startTime) {
    errors.startTime = GENERAL_ERROR_MSG;
  }

  if (!values.returnTime) {
    errors.returnTime = GENERAL_ERROR_MSG;
  }

  if (!isValid(values.startTime)) {
    errors.startTime = TIME_ERROR_MSG;
  }

  if (!isValid(values.returnTime)) {
    errors.returnTime = TIME_ERROR_MSG;
  }

  if (values.startTime > values.returnTime) {
    errors.returnTime = "Palautusaika ennen aloitusaikaa";
    errors.startTime = "Palautusaika ennen aloitusaikaa";
  }
  if (values.startTime?.getTime() === values.returnTime?.getTime()) {
    errors.returnTime = "Palautusaika sama kuin aloitusaika";
    errors.startTime = "Palautusaika sama kuin aloitusaika";
  }

  return errors;
};

const INITIAL_FORM_DATA = {
  id: undefined,
  group: "",
  companyId: "",
  startTime: null,
  returnTime: null,
  endTime: null,
  additionalServices: [],
  reservationVehicles: "",
  internalNotes: "",
  name: "",
  address: "",
  postalCode: "",
  city: "",
  phone: "",
  email: "-",
  outOfCommission: true,
};

export default function DisableProductDialog({ onClose, vehicle }) {
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const { selectedView, user, business } = useContext(AppContext);
  const queryMode =
    selectedView === "daily" ? "startOrEndBetween" : "existBetween";
  const { reservationsMutate } = useFetchReservations(queryMode);
  const onSubmit = async (values) => {
    setSubmitting(true);

    const reservationVehicle = [
      {
        id: vehicle.id,
        price: 0,
      },
    ];

    const reservation = {
      ...values,
      group: vehicle?.group ?? undefined,
      companyId: vehicle?.companyId,
      organizationId: user?.organizationId ?? undefined,
      orgBusinessId: business?.id ?? undefined,
      endTime: values.returnTime,
      reservationVehicles: reservationVehicle,
    };

    try {
      const response = await API.graphql(
        graphqlOperation(upsertReservationAdmin, {
          reservation: reservation,
        })
      );

      const result = response.data.upsertReservationAdmin;

      if (result.code != null) {
        Sentry.captureMessage("Reservation failed", {
          tags: {
            code: result.code,
          },
        });
        if (result.code === "VEHICLE_NOT_AVAILABLE") {
          notification.show(
            "Valitulla aikavälillä on olemassaolevia varauksia."
          );
        } else {
          notification.show("Jokin meni vikaan");
        }
        setSubmitting(false);
        return;
      }

      await reservationsMutate();
    } catch (e) {
      console.log(e);
      captureError(
        "Upsert quick vehicle failed",
        "UPSERT_QUICK_VEHICLE_FAILED",
        e
      );
      notification.show("Jokin meni vikaan");
    }
    onClose(true);
    setSubmitting(false);
    return;
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate,
    onSubmit,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    formik.setErrors({});
    formik.setTouched({});
  }, [vehicle]);

  return (
    <DialogComponent
      open={true}
      dialogClose={() => onClose()}
      dialogAction={formik.submitForm}
      dialogActionText={"Tallenna"}
      dialogTitle={"Poista tilapäisesti käytöstä - " + vehicle.name}
      dialogActionSubmitting={submitting}
    >
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="none">
        <Grid
          container
          direction="column"
          spacing={4}
          sx={{ my: "3dvh", overFlowY: "auto", overFlow: "auto" }}
        >
          <Grid item container direction="row" spacing={4}>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                inputFormat="dd.MM.yyyy HH:mm"
                ampm={false}
                minutesStep={5}
                label="Aloitusaika"
                value={formik.values.startTime}
                onChange={async (date) => {
                  // If changing multiple values, trigger validation only in last to avoid false-positive errors
                  const validate = !(date > formik.values.returnTime);
                  await formik.setFieldValue("startTime", date, validate);
                  if (date > formik.values.returnTime) {
                    await formik.setFieldValue(
                      "returnTime",
                      nextFullHour(date),
                      false
                    );
                    await formik.setFieldValue("endTime", nextFullHour(date));
                  }
                }}
                onClose={() => formik.setFieldTouched("startTime", true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="startTime"
                    fullWidth
                    error={
                      formik.touched.startTime && !!formik.errors.startTime
                    }
                    helperText={
                      formik.touched.startTime && formik.errors.startTime
                    }
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateTimePicker
                inputFormat="dd.MM.yyyy HH:mm"
                ampm={false}
                minutesStep={5}
                label="Palautusaika"
                value={formik.values.returnTime}
                onChange={async (date) => {
                  await formik.setFieldValue("returnTime", date);
                  await formik.setFieldValue("endTime", date);
                }}
                onClose={() => formik.setFieldTouched("returnTime", true)}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    name="returnTime"
                    error={
                      formik.touched.returnTime && !!formik.errors.returnTime
                    }
                    helperText={
                      formik.touched.returnTime && formik.errors.returnTime
                    }
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <FormControlLabel
                sx={{ mb: "25px" }}
                control={<Checkbox />}
                label="Poista käytöstä toistaiseksi"
              /> */}

              <TextField
                value={formik.values.internalNotes ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{ maxHeight: "400px" }}
                name="internalNotes"
                label="Syy"
                variant="outlined"
                error={
                  formik.touched.internalNotes && !!formik.errors.internalNotes
                }
                helperText={
                  formik.touched.internalNotes && formik.errors.internalNotes
                }
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  sx: {
                    maxHeight: "160px",
                    overflowY: "auto",
                  },
                }}
              />

              {/* <FormControlLabel
                sx={{ my: "25px" }}
                control={<Checkbox />}
                label="Lainataan toiselle yrittäjälle"
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </DialogComponent>
  );
}

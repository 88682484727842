import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";

type FormikInputSelectProps = any;

function FormikInputSelect({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  index,
  arrayName,
  attributeName,
  readOnly,
  ...props
}: FormikInputSelectProps) {
  return (
    <Grid item xs={xs} md={md}>
      <FormControl
        fullWidth
        error={form?.touched?.[field.name] && !!form?.errors?.[field.name]}
      >
        <InputLabel id={`${field?.name}-select-label`}>
          {props.label}
        </InputLabel>
        <InputLabel id={`${field?.name}-select-label`}>
          {props.label}
        </InputLabel>
        <Select
          {...field}
          labelId={`${field?.name}-select-label`}
          size="small"
          fullWidth
          disabled={readOnly}
          {...props}
        />
        <FormHelperText>
          {form?.touched?.[field.name] && form?.errors?.[field.name]}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
}

export default FormikInputSelect;

import { DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "./table";
import { dataGidStyle } from "~/styles/data-grid";
import { ViewProps } from "../../enums";
import { useReservationsByPaymentStatus } from "~/queries/useReservationsByPaymentStatus";
import {
  PaymentStatus,
  ReservationChannel,
  ReservationType,
} from "~/graphql/API";
import { useCustomers } from "~/queries/useCustomers";
import { groupReservations } from "../../utils/groupReservations";
import { mapById } from "~/utils/mapById";
import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";

function OpenOrdersView({ onSelect }: ViewProps) {
  const { business } = useContext<any>(AppContext);

  const { data, isLoading: loadingReservations } =
    useReservationsByPaymentStatus({
      paymentStatus: PaymentStatus.PENDING_INVOICE,
      orgBusinessId: { eq: business.id },

      limit: 1000,
    });
  const reservations = groupReservations(
    data.filter(
      ({ channel, type }) =>
        channel !== ReservationChannel.AUTOLIITTO &&
        type !== ReservationType.INSURANCE
    )
  );

  const { data: customers, isLoading: loadingCustomers } = useCustomers({
    filter: {
      and: [
        { isDeleted: { ne: true } },
        { organizationId: { eq: business.organizationId } },
      ],
    },
  });
  const mappedCustomers = mapById(customers);

  return (
    <DataGridPro
      loading={loadingReservations || loadingCustomers}
      rows={Object.entries(reservations).map(
        ([customerId, { customerType, items }]) => ({
          id: customerId,
          customer: mappedCustomers[customerId],
          customerType,
          items,
        })
      )}
      columns={columns}
      sx={dataGidStyle}
      onRowClick={({ row }) => {
        onSelect(row);
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "customer", sort: "asc" }],
        },
      }}
    />
  );
}

export default OpenOrdersView;

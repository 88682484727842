import { Button } from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { isValid, format } from "date-fns";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import { fi } from "date-fns/locale";
import useForkRef from "@mui/utils/useForkRef";

export default function DatePickerCorporate() {
  const { setEndDate, setStartDate, dateRange } = useContext(AppContext);

  const handleChangeDate = (newValue) => {
    // handle substract date based on selected view
    setStartDate(newValue[0]);
    setEndDate(newValue[1]);
  };

  return (
    <ButtonDateRangePicker
      value={dateRange}
      onChange={(newValue) => handleChangeDate(newValue)}
    />
  );
}

const DateRangeButtonField = React.forwardRef((props, ref) => {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref: containerRef } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;
  const { startDate, endDate } = useContext(AppContext);
  const handleRef = useForkRef(ref, containerRef);

  // navigation text changes based on the selectedView
  const navigationText = useMemo(() => {
    let start = "";
    let end = "";
    if (isValid(startDate)) {
      start = format(startDate, "dd.MM.yyyy", { locale: fi });
    }
    if (isValid(endDate)) {
      end = format(endDate, "dd.MM.yyyy", { locale: fi });
    }
    return `${start} - ${end}`;
  }, [startDate, endDate]);

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{ width: "250px", borderRadius: "10px", textTransform: "none" }}
    >
      {navigationText}
    </Button>
  );
});

DateRangeButtonField.fieldType = "single-input";

const ButtonDateRangePicker = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { mobileViewPort } = useContext(AppContext);

  return (
    <DateRangePicker
      calendars={mobileViewPort ? 1 : 2}
      slots={{ field: DateRangeButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      ref={ref}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
});

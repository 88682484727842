import {
  EContractAgreement,
  EOrderVehicle,
} from "./LanguagePack/translatedFields";
import { ContractField } from "./common";
import { Typography, Stack, Box } from "@mui/material";
import { titleStyle } from "./styles";

export default function ProductConditionAgreement({
  selectedLanguage,
}: {
  selectedLanguage: string;
}) {
  const fields =
    EContractAgreement[selectedLanguage as keyof typeof EOrderVehicle];
  //@ts-ignore

  return (
    <Stack direction={"column"} height={"100%"} justifyContent={"flex-end"}>
      <Typography sx={titleStyle}>
        {fields.PRODUCT_CONDITION_AGREEMENT}
      </Typography>
      <Box sx={{ border: "1px solid lightgrey", padding: "2px" }}>
        <Typography sx={{ fontSize: "8px" }}>
          {fields.AGREEMENT_TERMS}
        </Typography>
        <Stack direction={"row"}>
          <ContractField
            label={fields.AGREEMENT_CUSTOMER}
            value={""}
            visibility={true}
          />
          <ContractField
            label={fields.AGREEMENT_RENTAL_COMPANY}
            value={""}
            visibility={true}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

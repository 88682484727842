import React, { useMemo, useState } from "react";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { centsToLocalString } from "../../../../../../../../../../shared/money";
import { typeOfProducts } from "../../../../../../../../../../shared/ProductClassification/type-of-products";
import { useField, useFormikContext } from "formik";
import { useFetchVehicles } from "../../../../../../../../../../services/fetchVehicles";
import Menu from "../../../../../../../../components/MenuComponent";
import { Autorenew } from "@mui/icons-material";
import { useFetchOffers } from "../../../../../../../../../../hooks/useFetchOffers";
import { useAtomValue, useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../../../../../../../../stores/vehiclePrices";
import { isTimesValid } from "../../../../../../../../utils/isTimesValid";

export default function AvailableOffersMenu(props) {
  const { menuType, selectedItemId, containerRef, buttonType } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { values } = useFormikContext();
  const isDisabled = !isTimesValid(values.startTime, values.returnTime);
  const handleClick = (event) => {
    if (isDisabled) return null;
    scrollToTheBottom();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const scrollToTheBottom = () => {
    if (containerRef.current.scrollTopMax > 100) {
      containerRef.current?.scroll({
        top: 260,
        behavior: "smooth",
      });
    }
  };
  const title = menuType === "addNew" ? "Lisää tuote" : "Vaihda";
  if (menuType === "addNew") {
    return (
      <>
        <Tooltip
          title={isDisabled ? "Valitse ensin aloitus- ja palautusaika" : null}
        >
          <Button
            variant="contained"
            sx={{ textTransform: "none", width: menuType ? "100%" : "75px" }}
            onClick={handleClick}
            fullWidth
          >
            <Typography>{title}</Typography>
          </Button>
        </Tooltip>
        {anchorEl && (
          <OffersMenu
            anchorEl={anchorEl}
            menuType={menuType}
            selectedItemId={selectedItemId}
            setAnchorEl={setAnchorEl}
          />
        )}
      </>
    );
  } else {
    if (buttonType === "regular") {
      return (
        <>
          <Button
            onClick={(event) => {
              setAnchorEl(anchorEl ? null : event.currentTarget);
            }}
          >
            Siirrä varaus tuotteelle
          </Button>
          {anchorEl && (
            <OffersMenu
              anchorEl={anchorEl}
              menuType={menuType}
              selectedItemId={selectedItemId}
              setAnchorEl={setAnchorEl}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          <Tooltip title="Vaihda tuote">
            <IconButton
              size="small"
              onClick={(event) => {
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
            >
              <Autorenew />
            </IconButton>
          </Tooltip>
          {anchorEl && (
            <OffersMenu
              anchorEl={anchorEl}
              menuType={menuType}
              selectedItemId={selectedItemId}
              setAnchorEl={setAnchorEl}
            />
          )}
        </>
      );
    }
  }
}

function OffersMenu(props) {
  const { anchorEl, setAnchorEl, menuType, selectedItemId } = props;
  const [disableCategoryDefaultsStacking, setDisableCategoryDefaultsStacking] =
    useState(true);
  const { values } = useFormikContext();
  const [, meta, helpers] = useField("reservationVehicles");
  const [, wildCardMeta, wildCardHelpers] = useField("wildCardVehicles");
  const setPrices = useSetAtom(vehiclePricesAtom);
  const prices = useAtomValue(vehiclePricesAtom);
  const { value } = meta;
  const { value: wildCardValue } = wildCardMeta;
  const { setValue } = helpers;

  const { offersData, offersDataLoading } = useFetchOffers({
    disableCategoryDefaultsStacking: disableCategoryDefaultsStacking,
    values: values,
  });
  const { vehicleData: allVehicles } = useFetchVehicles();
  const handleAddNewProduct = async (item) => {
    // adjust the price and deductible to be in the correct format
    const selectedItem = {
      ...item,
      listingPrice: centsToLocalString(item?.price),
      deductible: centsToLocalString(item?.deductible),
      price: null,
    };

    await setValue([...value, selectedItem]);
    await wildCardHelpers.setValue([]);
  };

  const handleChangeProduct = (item) => {
    // adjust the price and deductible to be in the correct format

    const previousItem =
      wildCardValue.length > 0
        ? wildCardValue[0]
        : value.find((v) => v.id === selectedItemId);
    const selectedItem = {
      ...item,
      deductible: previousItem?.deductible
        ? previousItem?.deductible
        : centsToLocalString(item?.deductible),
      price: previousItem?.price,
      tax: item?.tax,
    };

    const priceId = previousItem?.categoryId ?? previousItem?.id;

    const filteredValues = value.filter((v) => v.id !== selectedItemId);
    const newPrices = {
      ...prices,
      [item.id]: {
        // keep the price of the previous item if it was already set
        price: prices[priceId]?.price,
        startTime: values.startTime,
        returnTime: values.returnTime,
        pricingCategoryId: values.pricingCategoryId,
        deductible: prices[priceId]?.deductible,
        tax: item?.tax,
      },
    };
    delete newPrices[priceId];
    setPrices(newPrices);
    setValue([...filteredValues, selectedItem]);

    wildCardHelpers.setValue([]);
  };

  const addNewProduct = (item) => {
    if (menuType === "addNew") {
      handleAddNewProduct(item);
      setPrices((prev) => ({
        ...prev,
        [item.id]: {
          price: item.price,
          startTime: values.startTime,
          returnTime: values.returnTime,
          pricingCategoryId: values.pricingCategoryId,
          deductible: item.deductible,
          tax: item?.tax,
        },
      }));
      setAnchorEl(null);
    } else {
      handleChangeProduct(item);
      setAnchorEl(null);
    }
  };

  const options = useMemo(() => {
    const vehiclesIds = value.map((v) => v.id);
    const results = [];
    offersData.forEach((item) => {
      const offers = item?.offers?.filter((offer) => {
        return !vehiclesIds.includes(offer.vehicle.id);
      });

      if (offers?.length !== 0) {
        results.push({ ...item, offers });
      }
    });
    return results;
  }, [offersData, value]);

  return (
    <Menu
      anchorEl={anchorEl}
      disableCategoryDefaultsStacking={disableCategoryDefaultsStacking}
      handleChange={addNewProduct}
      imageSource={{
        primary: (item) =>
          allVehicles?.find((v) => item.id === v.id)?.vehicleImage,
        secondary: (item) =>
          typeOfProducts.find((p) => p?.id === item?.category?.typeId)?.icon,
      }}
      loading={offersDataLoading}
      menuOptions={options}
      primaryText={"name"}
      secondaryText={"registrationPlate"}
      setAnchorEl={setAnchorEl}
      setDisableCategoryDefaultsStacking={setDisableCategoryDefaultsStacking}
    />
  );
}

import { useContextFetchCompanies } from "../../../services/fetchCompanies";
import { getDynamicPricing } from "../utils/getDynamicPricing";

export default function useGetDynamicPricingPercentage(companyId, pricingId) {
  const { companies } = useContextFetchCompanies();
  const selectedCompany = companies.find((c) => c.id === companyId);
  return {
    categoryDynamicPricings: selectedCompany?.categoryDynamicPricings,
    dynamicPricing: getDynamicPricing(selectedCompany, pricingId),
  };
}

import { Stack } from "@mui/material";
import { ECompanyInfo } from "./LanguagePack/translatedFields";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { ContractField } from "./common";

export default function ContractCompanyCustomerInformation({
  visibility,
  selectedLanguage,
}: {
  visibility: boolean;
  selectedLanguage: string;
}) {
  const reservation = useAtomValue(reservationDataAtom);
  if (!reservation) {
    return null;
  }

  //@ts-ignore
  const { companyName, companyBillingAddress, companyBusinessId } = reservation;
  const fields = ECompanyInfo[selectedLanguage as keyof typeof ECompanyInfo];
  if (visibility)
    return (
      <Stack
        sx={{
          border: "1px solid lightgrey",
          flex: 1,
          borderRadius: "5px",
        }}
        direction={"row"}
      >
        <ContractField
          value={companyName}
          label={fields?.NAME}
          visibility={true}
        />
        <ContractField
          value={companyBusinessId}
          label={fields?.BUSINESS_ID}
          visibility={true}
          sx={{ flex: 0.3 }}
        />
        {
          //@ts-ignore
          !reservation?.customerBrokerId && (
            <ContractField
              value={companyBillingAddress}
              label={fields?.BILLING_ADDRESS}
              visibility={true}
            />
          )
        }
      </Stack>
    );
}

import React from "react";
import "./EconomyDialog.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";

import { centsToLocalString } from "../../shared/money";
import ListMenu from "./ListCategoriesMenu";
import InfoContainerSmall from "./InfoContainerSmall";
import {
  FcPieChart,
  FcSalesPerformance,
  FcPaid,
  FcPuzzle,
} from "react-icons/fc";
import MobileViewProducts from "./MobileViewProducts";
import MobileViewAdditionalServices from "./MobileViewAdditionalServices";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro";

export default function MobileView({
  categories,
  changeSelectedCategory,
  economyQueryDates,
  handleChangeEconomyDate,
  incomeFromServices,
  incomeFromVehicles,
  loading,
  paymentMethods,
  reservationServices,
  reservedVehicles,
  selectedCategory,
  selectedCompany,
  startDate,
  theme,
  totalIncome,
}) {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      justifyContent="center"
      alignItems="start"
    >
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        Toimipiste: {selectedCompany?.name}
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "25px" }}
      >
        <MobileDateRangePicker
          displayWeekNumber
          toolbarFormat="d MMMM"
          value={economyQueryDates}
          onChange={(newValue) => {
            handleChangeEconomyDate(newValue);
          }}
          slotProps={{
            toolbar: {
              hidden: true,
            },
          }}
          slots={{ actionBar: TimePickerActionBar }}
        />
      </Grid>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <Grid container justifyContent="space-evenly" spacing={2}>
        <Grid item xs={12}>
          <Accordion
            sx={{
              margin: 0,
              padding: 0,
              borderWidth: 0,
              boxShadow: "none",
              bgcolor: "transparent",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InfoContainerSmall
                loading={loading}
                containerStyle="light"
                value={`${centsToLocalString(totalIncome)} €`}
                title={"Tulos ajalta"}
                icon={
                  <FcSalesPerformance
                    style={{
                      fontSize: 42,
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "100%" }}
              >
                <Grid item xs={11} container>
                  {paymentMethods.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={item.name}
                        style={{ marginBottom: 10 }}
                      >
                        <InfoContainerSmall
                          loading={loading}
                          containerStyle="light"
                          value={`${centsToLocalString(item.total)} €`}
                          title={item.name}
                          icon={
                            <FcPieChart
                              style={{
                                fontSize: 42,

                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                                padding: 5,
                                borderRadius: 10,
                                rotate:
                                  index === 1
                                    ? "180deg"
                                    : index === 2
                                    ? "90deg"
                                    : null,
                              }}
                            />
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            sx={{
              margin: 0,
              padding: 0,
              borderWidth: 0,
              boxShadow: "none",
              bgcolor: "transparent",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InfoContainerSmall
                loading={loading}
                containerStyle="dark"
                value={`${centsToLocalString(incomeFromVehicles)} €`}
                title={"Tuotteet"}
                testName="mobileEconomyViewProductsButton"
                icon={
                  <FcPaid
                    style={{
                      fontSize: 42,
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <ListMenu
                  key={500}
                  categories={categories}
                  title={"Kategoriat"}
                  changeSelectedCategory={changeSelectedCategory}
                />
                <Divider style={{ margin: 10 }} />
                <Grid
                  container
                  direction="column"
                  style={{
                    MaxHeight: "70vh",
                    overflow: "auto",
                  }}
                  justifyContent="center"
                >
                  <Grid item xs={12} sx={{ padding: "3px" }}>
                    <MobileViewProducts
                      reservedVehicles={reservedVehicles}
                      selectedCategory={selectedCategory}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            sx={{
              margin: 0,
              padding: 0,
              borderWidth: 0,
              boxShadow: "none",
              bgcolor: "transparent",
            }}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InfoContainerSmall
                loading={loading}
                containerStyle="dark"
                value={`${centsToLocalString(incomeFromServices)} €`}
                title={"Lisäpalvelut"}
                icon={
                  <FcPuzzle
                    style={{
                      fontSize: 42,
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      padding: 5,
                      borderRadius: 10,
                    }}
                  />
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="column"
                style={{ MaxHeight: "70vh", overflow: "auto", width: "100%" }}
                justifyContent="center"
              >
                <Grid item xs={12}>
                  <MobileViewAdditionalServices
                    reservationServices={reservationServices}
                    theme={theme}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
}

const TimePickerActionBar = (props) => {
  const { onAccept, onCancel, className } = props;
  return (
    <DialogActions className={className}>
      <Button onClick={onCancel}>Peruuta</Button>
      <Button onClick={onAccept}> Valmis </Button>
    </DialogActions>
  );
};

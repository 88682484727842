import { differenceInCalendarDays } from "date-fns";
import styles from "./styles.module.scss";
import DatesRow from "./components/DatesRow";
import HeaderDateCard from "./components/HeaderDateCard";
// @ts-ignore
import { useFetchVehicles } from "~/services/fetchVehicles";
// @ts-ignore
import { useFetchReservations } from "~/services/fetchReservations";
import CategoryContainer from "./components/CategoryContainer";
import NewReservation from "./components/NewReservation";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { EDeviceType } from "~/enums/device-type";
import ContextMenu from "./components/ContextMenu";
import RowSelector from "./components/RowSelector";
import { useDates } from "./hooks/useDates";
import { LinearProgress } from "@mui/material";
import type {
  Category,
  Reservation as ReservationType,
  Vehicle,
} from "~/graphql/API";
import {
  SortedReservations,
  makeSortReservationsReducer,
} from "./utils/sortReservationsReducer";
import { Settings, SettingsContext } from "./SettingsContext";
import { clsx } from "~/utils/clsx";
import makeVehicleRow from "./components/VehicleRow";
import { makeFilterVisibleReservations } from "./utils/filterVisibleReservations";

const datesClassName = clsx(styles.cell, styles["row-dates"]);
const dataClassName = clsx(styles.cell, styles["row-data"]);

type VehiclesByCategory = {
  categoryData: Category;
  vehicles: Vehicle[];
}[];

type CalendarProps = {
  startDate: number;
  endDate: number;
} & Partial<Settings>;

function Calendar({
  deviceType = EDeviceType.DESKTOP,
  startDate,
  endDate,
  rowVariant = "regular",
  colVariant: _colVariant = "regular",
}: CalendarProps) {
  const daysCount = differenceInCalendarDays(endDate, startDate);
  const colVariant = daysCount > 7 ? "compact" : _colVariant;

  const [view, setView] = useState<null | ReactNode>(null);

  // Fix TypeScript types
  const fetchedVehicles = useFetchVehicles();
  const vehiclesByCategory =
    fetchedVehicles.vehiclesByCategory as VehiclesByCategory;
  const vehiclesLoading = fetchedVehicles.vehiclesLoading as boolean;

  // Fix TypeScript types
  const fetchedReservations = useFetchReservations("existBetween");
  const reservations = fetchedReservations.reservations as ReservationType[];
  const reservationsLoading =
    fetchedReservations.reservationsLoading as boolean;

  const isLoading = vehiclesLoading || reservationsLoading || !view;

  const dates = useDates(startDate, daysCount);

  const bodyRenderer = useCallback(
    async (
      vehiclesByCategory: VehiclesByCategory,
      reservations?: ReservationType[],
      sortedReservations?: SortedReservations
    ) => {
      const vehicleRow = makeVehicleRow({
        dates,
        reservations,
        sortedReservations: sortedReservations?.vehicle,
        className: dataClassName,
      });

      const hasWildcardReservations = sortedReservations?.category
        ? Object.entries(sortedReservations?.category)
            .filter(([, overrides]) => overrides.length)
            .map(([categoryId]) => categoryId)
        : false;

      setView(
        await Promise.all(
          vehiclesByCategory
            .filter(
              ({ categoryData, vehicles }) =>
                vehicles.length ||
                (hasWildcardReservations &&
                  hasWildcardReservations.includes(categoryData.id))
            )
            .map(async ({ categoryData, vehicles }) => (
              <CategoryContainer
                key={categoryData.id}
                category={categoryData}
                visible={sortedReservations?.category[categoryData.id]?.map(
                  (overrides, i) => (
                    <DatesRow
                      key={i}
                      dates={dates}
                      className={dataClassName}
                      override={overrides}
                      append={<RowSelector category={categoryData} />}
                    />
                  )
                )}
                hidable={await Promise.all(vehicles.map(vehicleRow))}
              />
            ))
        )
      );
    },
    [dates]
  );

  useEffect(() => {
    if (vehiclesLoading || reservationsLoading) {
      bodyRenderer(vehiclesByCategory);

      return;
    }

    const visibleReservationsFilter = makeFilterVisibleReservations(
      startDate,
      endDate
    );

    const visibleReservations = reservations.filter(visibleReservationsFilter);

    visibleReservations.sort(
      (a, b) =>
        (a.startTime as unknown as Date).getTime() -
        (b.startTime as unknown as Date).getTime()
    );

    const sortReservationsReducer = makeSortReservationsReducer(startDate);
    const sortedReservations = visibleReservations.reduce(
      sortReservationsReducer,
      {
        vehicle: {},
        category: {},
      } as SortedReservations
    );

    bodyRenderer(vehiclesByCategory, visibleReservations, sortedReservations);
  }, [vehiclesByCategory, reservations, bodyRenderer]);

  return (
    <SettingsContext.Provider
      value={{
        deviceType,
        rowVariant,
        colVariant,
        calendar: { startDate, endDate, daysCount },
      }}
    >
      {isLoading && (
        <div style={{ position: "relative" }}>
          <LinearProgress
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 20,
              width: "100%",
            }}
          />
        </div>
      )}

      <div
        className={styles.root}
        data-device-type={deviceType}
        data-row-variant={rowVariant}
        data-col-variant={colVariant}
        style={{
          "--colsCount": daysCount,
        }}
      >
        <div className={styles.container}>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 15,
            }}
          >
            <DatesRow
              dates={dates}
              className={datesClassName}
              firstCell={<NewReservation />}
              render={({ date }) => <HeaderDateCard date={date} />}
            />
          </div>

          {view}
        </div>
      </div>

      <ContextMenu />
    </SettingsContext.Provider>
  );
}

export default Calendar;

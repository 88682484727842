import { useState } from "react";
import {
  Button,
  Dialog,
  Tooltip,
  Typography,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useMutateLocalReservations } from "../../../hooks/useMutateLocalReservations";
import { API, graphqlOperation } from "aws-amplify";
import { deleteReservation } from "../../../../../graphql/mutations";
import { captureError } from "../../../../../utilities";
import { useNotification } from "../../../../Notification";
import { useFormikContext } from "formik";
import { useAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { EUserAdminType } from "../../../../../enums/user-admin-type";
import InfoIcon from "@mui/icons-material/Info";

export default function DeleteReservation(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reservationData, setEditReservationData] =
    useAtom(reservationDataAtom);
  const { USER_ADMIN_TYPE } = useContext(AppContext);
  const { isSubmitting, setSubmitting } = useFormikContext();
  const notification = useNotification();
  const { removeLocalReservation } = useMutateLocalReservations();
  const onRemove = async () => {
    setSubmitting(true);

    try {
      const response = await API.graphql(
        graphqlOperation(deleteReservation, {
          input: {
            id: reservationData?.id,
          },
        })
      );
      const result = response.data.deleteReservation;
      if (result.id) {
        // Update local state instantly after deletion
        removeLocalReservation(result);
      }
      return setEditReservationData(null);
    } catch (e) {
      console.log("error deleting reservation", e);
      captureError("Delete reservation failed", "DELETE_RESERVATION_FAILED", e);
      notification.show("Varauksen poistaminen epäonnistui", "error");
    }
    setShowDeleteConfirmation(false);
    setSubmitting(false);
  };

  // business users are not allowed to delete insurance reservations
  const preventDeletion =
    reservationData?.type === "INSURANCE" &&
    USER_ADMIN_TYPE === EUserAdminType.GENERIC;

  if (reservationData?.id)
    return (
      <>
        <Button
          variant="contained"
          color="error"
          disabled={isSubmitting || preventDeletion}
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Poista
        </Button>
        {preventDeletion && (
          <Tooltip title="Vakuutusyhtiön varausten poistaminen ei ole mahdollista. Ota yhteys asiakaspalveluun.">
            <InfoIcon sx={{ marginLeft: "10px" }} color="warning" />
          </Tooltip>
        )}
        <Dialog open={showDeleteConfirmation}>
          <DialogTitle>
            <Typography>Haluatko varmasti poistaa varauksen?</Typography>
          </DialogTitle>

          <DialogActions>
            <Button
              onClick={() => setShowDeleteConfirmation(false)}
              disabled={isSubmitting}
            >
              peruuta
            </Button>
            <Button
              onClick={() => onRemove()}
              variant="contained"
              color="error"
              disabled={isSubmitting}
            >
              poista
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

import React from "react";
import "./EconomyDialog.css";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
} from "@mui/material";

import { format } from "date-fns";
import { centsToLocalString } from "../../shared/money";
import { calculateVAT, valueExcludedVAT } from "./components/utils";

export default function PrintReport({
  totalIncome,
  startDate,
  endDate,
  incomeFromServices,
  incomeFromVehicles,
  reservationServices,
  reservedVehicles,
  callback,
}) {
  return (
    <Grid
      ref={callback}
      container
      direction="column"
      justifyContent="space-evenly"
      style={{ padding: 20 }}
    >
      <Grid
        item
        xs={12}
        style={{
          marginBottom: 20,
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            marginBottom: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "flex-start",
            }}
          >
            <Typography
              align="right"
              style={{
                fontFamily: "Roboto, sans-serif",
                letterSpacing: "0.03em",
                color: "#000000",
                fontSize: 24,
                fontWeight: 600,
              }}
            >
              Raportointi
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto, sans-serif",
                letterSpacing: "0.03em",
                color: "#000000",
                fontSize: "14px",
              }}
            >
              Ajanjaksolta: {format(new Date(startDate), " dd.MM.yyyy ")} -{" "}
              {format(new Date(endDate), " dd.MM.yyyy ")}
            </Typography>
          </div>
        </div>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow></TableRow>

              <TableRow
                style={{
                  backgroundColor: "#d9d9d9",
                  borderColor: "#ffffff",
                }}
              >
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="left"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                    align="left"
                  >
                    Tuotteet
                  </Typography>
                </TableCell>

                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  ></Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Välisumma
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ALV %
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ALV
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Summa
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservedVehicles.map((item) => {
                return (
                  <TableRow
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderColor: "#ffffff",
                    }}
                    key={item.id}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {item.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    ></TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {valueExcludedVAT(item.total)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        24%
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {centsToLocalString(calculateVAT(item.total))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {centsToLocalString(item.total)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow style={{ height: 50 }}>
                <TableCell
                  align="left"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    Tuotteiden summa
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {valueExcludedVAT(incomeFromVehicles)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(calculateVAT(incomeFromVehicles))}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(incomeFromVehicles)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                />
              </TableRow>
              <TableRow sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}>
                <TableCell align="left">
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Lisäpalvelut
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="center"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Määrä
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography style={{ color: "#000000" }}>
                    Välisumma
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ALV %
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ALV
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: "#acb9c9", fontSize: "12px" }}
                  align="right"
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0.05em",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Summa
                  </Typography>
                </TableCell>
              </TableRow>
              {reservationServices.map((item) => {
                return (
                  <TableRow
                    key={item.id + 10000}
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderColor: "#ffffff",
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {item.description}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {item.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {valueExcludedVAT(item.total)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {item?.tax ?? 24} %
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {centsToLocalString(calculateVAT(item.total))}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: "#000000",
                        borderColor: "#FFFFFF",
                        fontSize: 12,
                        borderWidth: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Roboto, sans-serif",
                          color: "#000000",
                          fontSize: 14,
                        }}
                      >
                        {centsToLocalString(item.total)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow style={{ height: 50 }}>
                <TableCell
                  align="left"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    Lisäpalveluiden summa
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {valueExcludedVAT(incomeFromServices)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(calculateVAT(incomeFromServices))}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(incomeFromServices)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                />
              </TableRow>
              <TableRow style={{ backgroundColor: "lightgrey" }}>
                <TableCell
                  align="left"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    Loppusumma
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {valueExcludedVAT(totalIncome)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                ></TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(calculateVAT(totalIncome))}
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#000000",
                    borderColor: "#FFFFFF",
                    fontSize: 12,
                    borderWidth: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto, sans-serif",
                      fontWeight: 600,
                      color: "#000000",
                      fontSize: "14px",
                    }}
                  >
                    {centsToLocalString(totalIncome)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

import { Grid, TextField } from "@mui/material";

type FormikInputTextProps = any;

function FormikInputText({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  readOnly,
  ...props
}: FormikInputTextProps) {
  return (
    <Grid item xs={xs} md={md}>
      <TextField
        {...field}
        variant="outlined"
        error={form?.touched?.[field.name] && !!form?.errors?.[field.name]}
        helperText={form?.touched?.[field.name] && form?.errors?.[field.name]}
        size="small"
        disabled={readOnly}
        fullWidth
        {...props}
      />
    </Grid>
  );
}

export default FormikInputText;

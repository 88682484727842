import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Select,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { API, graphqlOperation } from "aws-amplify";
import PricingEditDialog from "./PricingEditDialog";
import DynaminPricingEditDialog from "./DynamicPricingEditDialog";
import { centsToLocalString } from "../shared/money";
import captureError from "../utils/capture-error";
import { listPricingExceptions } from "../graphql/queries";
import {
  createPricing,
  createPricingsFromCsv,
  deletePricing,
} from "../graphql/mutations";
import listAll from "../utils/list-all";
import DialogComponent from "./DialogComponent";
import { AppContext } from "../AppContext";
import { useFetchPricings } from "../services/fetchPricings";
import {
  DataGridPro,
  GridEditInputCell,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import { Edit } from "@mui/icons-material";
import { updateCompany } from "../graphql/custom-mutations";
import { useContextFetchCompanies } from "../services/fetchCompanies";
import { useNotification } from "./Notification";
import { useCategories } from "~/queries/useCategories";

const ETabs = {
  PRICING: "PRICING",
  DYNAMIC_PRICING: "DYNAMIC_PRICING",
  CATEGORY_PRICING: "CATEGORY_PRICING",
  EXTERNAL_BUSINESS_PRICING: "EXTERNAL_BUSINESS_PRICING",
};

const EUserRoles = {
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  BUSINESS_ADMIN: "BUSINESS_ADMIN",
  LEGACY: "LEGACY",
};

const defaultTab = {
  [EUserRoles.ORGANIZATION_ADMIN]: ETabs.PRICING,
  [EUserRoles.BUSINESS_ADMIN]: ETabs.CATEGORY_PRICING,
  [EUserRoles.LEGACY]: ETabs.PRICING,
};

export default function PricingListDialog({ open, onClose }) {
  const {
    user,
    group,
    business,
    company: contextCompany,
  } = useContext(AppContext);
  const { pricings, pricingsMutate, pricingsValidating } = useFetchPricings(
    group,
    user,
    {},
    "pricingListDialog"
  );

  const userRole = user?.group ? EUserRoles.LEGACY : EUserRoles[user?.role];
  const theme = useTheme();
  const inputRef = useRef(null);
  const disableFields = false; // user?.role === "BUSINESS_ADMIN";
  const [editablePricing, setEditablePricing] = useState(null);
  const [editableException, setEditableException] = useState(null);
  const [pricingExceptions, setPricingExceptions] = useState([]);
  const [pricingExceptionLoading, setPricingExceptionLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(() => defaultTab[userRole]);
  const [snackbar, setSnackbar] = useState(null);

  const [company, setCompany] = useState(() => contextCompany);

  const categoryDynamicPricing = useMemo(() => {
    const dynamicPricings = company?.categoryDynamicPricings;
    if (dynamicPricings) {
      return dynamicPricings;
    }
    return [];
  }, [company]);
  const { companies, companiesMutate } = useContextFetchCompanies();
  const notification = useNotification();
  const [errorMsg, setErrorMsg] = useState("");

  const monthExceptionExists = useMemo(
    () => pricingExceptions.some((exception) => exception.type === "MONTH"),
    [pricingExceptions]
  );

  const fetchPricingExceptions = async () => {
    setPricingExceptionLoading(true);
    try {
      const pricingExceptions = await listAll(
        graphqlOperation(listPricingExceptions),
        "listPricingExceptions"
      );
      setPricingExceptions(pricingExceptions);
    } catch (e) {
      captureError(
        "Get pricingExceptions failed",
        "GET_PRICINGEXCEPTIONS_FAILED",
        e
      );
    }
    setPricingExceptionLoading(false);
  };

  const useUpdateDiscountPercentage = (categoryDynamicPricing) => {
    return useCallback(
      (row) =>
        new Promise(async (resolve, reject) => {
          if (isNaN(row.percentage)) {
            reject(new Error("Virhe tallennettaessa hintaa."));
          }

          const isPopulated = categoryDynamicPricing
            ? categoryDynamicPricing.find(
                (discount) => discount?.pricingId === row.pricing?.id
              )
            : null;
          let updatedPercentages = null;

          if (isPopulated) {
            updatedPercentages = categoryDynamicPricing.map((discount) => {
              if (row.pricing && discount.pricingId === row.pricing.id) {
                return {
                  ...discount,
                  pricingId: row.pricing.id,
                  percentage: row.percentage,
                };
              } else {
                return discount;
              }
            });
          } else {
            updatedPercentages = [
              ...categoryDynamicPricing,
              { pricingId: row.pricing.id, percentage: row.percentage },
            ];
          }
          let result;
          setCompany((prevState) => {
            return {
              ...prevState,
              categoryDynamicPricings: updatedPercentages,
            };
          });
          try {
            const updateFunc = updateCategoryDynamicPricing(
              updatedPercentages,
              company,
              setCompany
            );
            // Update company and update local cache
            companiesMutate(() => updateFunc, {
              populateCache: (result, companies) => {
                const updatedCompanies = companies.map((c) => {
                  if (c.id === result.id) {
                    return {
                      ...c,
                      categoryDynamicPricings: result.categoryDynamicPricings,
                    };
                  }
                  return c;
                });
                return updatedCompanies;
              },
              revalidate: false,
            });
          } catch (error) {
            console.log("ERROR", error);
            result = null;
            setSnackbar({
              children: `Virhe tallentaessa prosenttia`,
              severity: "warning",
            });
          }
          if (result) {
          }

          resolve(row);
        }),
      [categoryDynamicPricing]
    );
  };

  const useUpdateBusinessCategoryAssociation = () => {
    return useCallback(
      (row) =>
        new Promise(async (resolve, reject) => {
          let result;

          try {
            if (row.id) {
              const newPricingElements = [
                { duration: "h0", price: 0 },
                { duration: "d0", price: 0 },
              ];

              const newPricing = {
                name: row.name,
                businessCategoryAssociation: row.id,
                elements: row?.pricing?.elements
                  ? row.pricing.elements.map((el) => {
                      return { price: el.price, duration: el.duration };
                    })
                  : newPricingElements,
                useNextDayPrice: row?.pricing?.useNextDayPrice ?? null,
                organizationId: user?.organizationId,
                orgBusinessId: business.id,
              };

              await API.graphql(
                graphqlOperation(createPricing, {
                  input: newPricing,
                })
              );
              pricingsMutate();
            }
          } catch (error) {
            console.log("ERROR", error);
            result = null;
            setSnackbar({
              children: `Virhe tallentaessa hinnastoa.`,
              severity: "warning",
            });
          }
          if (result) {
            setSnackbar({
              children: `Hinnasto muutettu muokattavaksi.`,
              severity: "success",
            });
          }

          resolve(row);
        }),
      []
    );
  };

  const useDeleteBusinessCategoryAssociationPricing = () => {
    return useCallback(
      (row) =>
        new Promise(async (resolve, reject) => {
          let response;

          let deleteItem = await API.graphql(
            graphqlOperation(deletePricing, {
              input: {
                id: row?.pricing?.id,
              },
            })
          );

          try {
            response = deleteItem;
          } catch (error) {
            setSnackbar({
              children: `Virhe hinnaston poistossa`,
              severity: "warning",
            });
          }

          if (response) {
            setSnackbar({
              children: `Hinnasto muutettu organisaation hinnastoksi.`,
              severity: "success",
            });
          }

          resolve(row);
        }),
      []
    );
  };

  const mutateRow = useUpdateDiscountPercentage(categoryDynamicPricing);
  const mutateRowUpdatePricing = useUpdateBusinessCategoryAssociation();
  const deleteModfiedPricing = useDeleteBusinessCategoryAssociationPricing();
  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = React.useCallback(
    async (row) => {
      let response;

      // handle organization modifications
      if (Boolean(!row?.businessCategoryAssociation)) {
        // handle remove modified pricing
        if (row?.pricing?.businessCategoryAssociation) {
          try {
            response = await deleteModfiedPricing(row);
          } catch (error) {
            console.log("error deleting pricing", error);
          }
          if (response) {
            pricingsMutate();
            return response;
          }
        } else {
          // handle update organization price modification with percentage
          if (row?.percentage) {
            response = await mutateRow(row);

            if (response?.percentage) {
              notification.show(
                `Hinnan muutos on tallennettu ${row?.name} kategorialle.`,
                "success",
                2000
              );
            } else {
              notification.show(
                `Tallennus ${row?.name} kategorialle epäonnistui.`,
                "warning"
              );
            }
          }
        }
      }

      // handle create modified pricing
      if (
        Boolean(row?.businessCategoryAssociation) &&
        !row?.pricing?.businessCategoryAssociation
      ) {
        try {
          response = await mutateRowUpdatePricing(row);
        } catch (error) {
          console.log("error updating pricing", error);
        }

        if (response) {
          return response;
        }
      }

      return row;
    },
    [
      mutateRow,
      notification,
      mutateRowUpdatePricing,
      deleteModfiedPricing,
      pricingsMutate,
    ]
  );

  const createNew = (type) => {
    if (type === "pricing") {
      setEditablePricing({
        name: "",
        elements: [],
      });
    } else {
      setEditableException({});
    }
  };

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    fetchPricingExceptions();
  }, []);
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));

  const groupingColDef = {
    headerName: "",
    width: 50,
  };

  const ETabOptions = {
    [ETabs.PRICING]: {
      LABEL: "Hinnasto",
      VALUE: ETabs.PRICING,
      COMPONENT: (
        <DefaultPricingsView
          categoryDynamicPricing={categoryDynamicPricing}
          createNew={createNew}
          disableFields={disableFields}
          mobileViewPort={mobileViewPort}
          pricings={pricings}
          pricingsValidating={pricingsValidating}
          processRowUpdate={processRowUpdate}
          setEditablePricing={setEditablePricing}
        />
      ),
    },

    [ETabs.DYNAMIC_PRICING]: {
      LABEL: "Dynaamisethinnastot",
      VALUE: ETabs.DYNAMIC_PRICING,
      COMPONENT: (
        <DynamicPricingsView
          createNew={createNew}
          disableFields={disableFields}
          mobileViewPort={mobileViewPort}
          pricingExceptions={pricingExceptions}
          pricings={pricings}
          pricingsValidating={pricingsValidating}
          processRowUpdate={processRowUpdate}
          setEditableException={setEditableException}
        />
      ),
    },
    [ETabs.CATEGORY_PRICING]: {
      LABEL: "Autoluokkahinnastot",
      VALUE: ETabs.CATEGORY_PRICING,
      COMPONENT: (
        <CategoryPricingsView
          categoryDynamicPricing={categoryDynamicPricing}
          createNew={createNew}
          disableFields={disableFields}
          //    editPricing={editPricing}
          mobileViewPort={mobileViewPort}
          pricings={pricings}
          pricingsValidating={pricingsValidating}
          processRowUpdate={processRowUpdate}
          setEditablePricing={setEditablePricing}
        />
      ),
    },

    [ETabs.EXTERNAL_BUSINESS_PRICING]: {
      LABEL: "Sopimushinnastot",
      VALUE: ETabs.EXTERNAL_BUSINESS_PRICING,
      COMPONENT: (
        <ContractPricingView
          errorMsg={errorMsg}
          groupingColDef={groupingColDef}
          handleButtonClick={handleButtonClick}
          inputRef={inputRef}
          mobileViewPort={mobileViewPort}
          pricingExceptionLoading={pricingExceptionLoading}
          pricings={pricings}
          pricingsMutate={pricingsMutate}
          setEditablePricing={setEditablePricing}
          setErrorMsg={setErrorMsg}
        />
      ),
    },
  };

  const EOptionsByUser = {
    [EUserRoles.ORGANIZATION_ADMIN]: [
      ETabOptions[ETabs.PRICING],
      ETabOptions[ETabs.CATEGORY_PRICING],
      ETabOptions[ETabs.EXTERNAL_BUSINESS_PRICING],
    ],
    [EUserRoles.BUSINESS_ADMIN]: [
      ETabOptions[ETabs.PRICING],
      ETabOptions[ETabs.CATEGORY_PRICING],
    ],

    [EUserRoles.LEGACY]: [
      ETabOptions[ETabs.PRICING],
      ETabOptions[ETabs.DYNAMIC_PRICING],
    ],
  };

  return (
    <DialogComponent
      open={open}
      dialogClose={() => onClose()}
      maxWidth="lg"
      height={!mobileViewPort ? 900 : undefined}
    >
      <Box style={{ padding: theme.spacing(4) }}>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontFamily: "Sofia Pro" }}
        >
          Hinnastot
        </Typography>
        <Divider variant="middle" />

        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {EOptionsByUser[userRole].map(({ LABEL, VALUE }) => (
            <Tab key={VALUE} label={LABEL} value={VALUE} />
          ))}
        </Tabs>
      </Box>

      <Box>{ETabOptions[activeTab].COMPONENT}</Box>

      {editablePricing && (
        <PricingEditDialog
          pricing={editablePricing}
          disabled={disableFields}
          onClose={(created) => {
            setEditablePricing(null);
            pricingsMutate();
            /*   if (created) {
              fetchPricings();
            } */
          }}
        />
      )}
      {editableException && (
        <DynaminPricingEditDialog
          fetchPricingExceptions={fetchPricingExceptions}
          pricingException={editableException}
          monthExceptionExists={monthExceptionExists}
          disabled={disableFields}
          onClose={() => {
            setEditableException(null);
          }}
        />
      )}

      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </DialogComponent>
  );
}

function AddedPricingsDialog({
  open,
  onClose,
  addedPricings,
  csvLoading,
  notAddedPricings,
  errorMsg,
}) {
  return (
    <DialogComponent
      open={open}
      onClose={onClose}
      maxWidth="md"
      dialogClose={() => onClose()}
      height={1500}
    >
      <Container>
        {csvLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="200px"
          >
            <CircularProgress />
          </Box>
        ) : !!errorMsg ? (
          <Typography variant="h6">{errorMsg}</Typography>
        ) : (
          <>
            <Typography variant="h6">Lisätty Hinnastot</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Tuntihinta</TableCell>
                  <TableCell>Päivähinta</TableCell>
                  <TableCell>Veroprosentti</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedPricings.map((pricing, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{pricing.name}</TableCell>
                    <TableCell>{(pricing.hourly / 100).toFixed(2)} €</TableCell>
                    <TableCell>{(pricing.daily / 100).toFixed(2)} €</TableCell>
                    <TableCell>{pricing.tax}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography mt={2} variant="h6">
              Lisäämättömät Hinnastot
            </Typography>
            <Typography variant="subtitle2" fontStyle="italic">
              (kategoria puuttuu)
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nimi</TableCell>
                  <TableCell>Tuntihinta</TableCell>
                  <TableCell>Päivähinta</TableCell>
                  <TableCell>Veroprosentti</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notAddedPricings.map((pricing, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{pricing.name}</TableCell>
                    <TableCell>{(pricing.hourly / 100).toFixed(2)} €</TableCell>
                    <TableCell>{(pricing.daily / 100).toFixed(2)} €</TableCell>
                    <TableCell>{pricing.tax}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </Container>
    </DialogComponent>
  );
}

const getTreeDataPath = (row) => row.hierarchy;

async function updateCategoryDynamicPricing(percentages, company, setCompany) {
  const companyInput = {
    categoryDynamicPricings: percentages.map((p) => {
      delete p.__typename;
      return p;
    }),
    id: company.id,
  };

  /*   setCompany((prevState) => {
    return {
      ...prevState,
      categoryDynamicPricings: percentages.map((p) => {
        delete p.__typename;
        return p;
      }),
    };
  }); */
  const response = await API.graphql(
    graphqlOperation(updateCompany, {
      input: companyInput,
    })
  );

  console.log("response", response.data.updateCompany);
  return response.data.updateCompany;
}

function ContractPricingView(props) {
  const {
    errorMsg,
    groupingColDef,
    handleButtonClick,
    inputRef,
    mobileViewPort,
    pricingExceptionLoading,
    pricings,
    pricingsMutate,
    setEditablePricing,
    setErrorMsg,
  } = props;

  const theme = useTheme();
  const { user } = useContext(AppContext);
  const showCSV = user?.role === "ORGANIZATION_ADMIN";
  const [selectedExternalBusiness, setSelectedExternalBusiness] =
    useState(null);
  const [csvLoading, setCsvLoading] = useState(false);
  const [addedPricings, setAddedPricings] = useState([]);
  const [notAddedPricings, setNotAddedPricings] = useState([]);
  const [showAddedPricingsDialog, setShowAddedPricingsDialog] = useState(false);

  const handleFileSelect = async (event) => {
    try {
      const file = event.target.files[0];

      if (file) {
        setCsvLoading(true);
        setShowAddedPricingsDialog(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          const result = await API.graphql(
            graphqlOperation(createPricingsFromCsv, {
              csvData: csvContent,
              orgExternalBusinessId: selectedExternalBusiness.id,
            })
          );
          try {
            const response = JSON.parse(
              result.data.createPricingsFromCsv.message
            );
            setAddedPricings(response.addedPricings);
            setNotAddedPricings(response.notAddedPricings);
          } catch (error) {
            if (error instanceof SyntaxError) {
              setErrorMsg("CSV ei ole oikeassa formaatissa.");
              console.error("JSON data is not in the expected format.");
            } else {
              console.error("Error while parsing JSON data:", error);
            }
            setCsvLoading(false);
            return;
          }

          /*    fetchPricings(); */
          setCsvLoading(false);
        };
        reader.onerror = (e) => {
          console.error("FileReader error:", e.target.error);
          setCsvLoading(false);
        };
        reader.readAsText(file);
      } else {
        console.error("Tiedostoa ei valittu");
      }
    } catch (e) {
      console.log(e);
      setCsvLoading(false);
    }
  };

  const externalBusinessList = useMemo(() => {
    const businesses = user?.externalBusinessByOrg?.items.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (!businesses || businesses.length === 0) {
      return [];
    } else {
      return [...businesses];
    }
  }, [user?.externalBusinessByOrg]);

  // First, create a mapping of externalBusinessId to their names.
  const externalBusinessNamesMap = {};
  externalBusinessList?.forEach((business) => {
    externalBusinessNamesMap[business.id] = business.name;
  });

  // Next, group pricings by external business name.
  const pricingsByBusiness = {};
  pricings?.forEach((pricing) => {
    const businessName =
      externalBusinessNamesMap[pricing.orgExternalBusinessId];
    if (businessName) {
      if (!pricingsByBusiness[businessName]) {
        pricingsByBusiness[businessName] = [];
      }
      pricingsByBusiness[businessName].push(pricing);
    }
  });

  const extBusinessRows = useMemo(() => {
    const rows = [];
    externalBusinessList.forEach((extBusiness, idx) => {
      rows.push({
        id: extBusiness?.id,
        name: extBusiness?.name,
        hierarchy: [extBusiness?.name],
      });
      pricings?.forEach((pricing, index) => {
        if (extBusiness.id === pricing.orgExternalBusinessId) {
          rows.push({
            id: pricing.id,
            pricingName: pricing?.name,
            businessCategoryAssociation: pricing?.businessCategoryAssociation
              ? true
              : false,
            hierarchy: [extBusiness?.name, `${pricing.name}`],
            pricingHour: centsToLocalString(
              pricing?.elements?.find((el) => el.duration === "h0")?.price
            ),
            pricingDay: centsToLocalString(
              pricing?.elements?.find((el) => el.duration === "d0")?.price
            ),
            VAT: pricing?.tax ?? 24,
            editPricing: pricing,
          });
        }
      });
    });
    return rows;
  }, [externalBusinessList, pricings]);

  const extBusinessColumns = [
    {
      field: "name",
      headerName: "Asiakas",
      flex: 1,
      minWidth: 175,
    },
    {
      field: "pricingName",
      headerName: "Hinnasto",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "pricingHour",
      headerName: "Tunti",
      flex: 0.5,
      minWidth: 150,
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} €`;
      },
    },

    {
      field: "pricingDay",
      headerName: "Vuorokausi",
      type: "number",
      flex: 0.5,
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} €`;
      },
    },
    {
      field: "VAT",
      headerName: "Verokanta",
      flex: 0.5,
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} %`;
      },
    },

    {
      field: "editPricing",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditablePricing(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (externalBusinessList?.length > 0) {
      setSelectedExternalBusiness(externalBusinessList[0]);
    }
  }, [externalBusinessList]);

  return (
    <>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Stack
          direction={mobileViewPort ? "column" : "row"}
          justifyContent={mobileViewPort ? "center" : "space-between"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          sx={{ padding: mobileViewPort ? "10px" : undefined }}
        >
          <Box style={{ padding: theme.spacing(4) }}>
            <Typography
              variant="h5"
              align="center"
              sx={{ fontFamily: "Sofia Pro" }}
            >
              Sopimushinnastot
            </Typography>
          </Box>

          {showCSV && (
            <Stack
              spacing={2}
              alignItems="center"
              direction={"row"}
              justifyContent={mobileViewPort ? "space-between" : "flex-end"}
            >
              <Tooltip
                title="Valitse yhtiö jolle sopimushinnastot lisätään"
                arrow
                placement="top"
              >
                <Box
                  sx={{
                    display: "flex",
                    width: mobileViewPort ? "90%" : "300px",
                    maxWidth: mobileViewPort ? "180px" : undefined,
                  }}
                >
                  <Select
                    fullWidth
                    label="Valitse sopimusasiakas"
                    key={selectedExternalBusiness?.id || ""}
                    value={selectedExternalBusiness?.name || ""}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedOption = externalBusinessList?.find(
                        (option) => option.name === selectedValue
                      );
                      setSelectedExternalBusiness(selectedOption);
                    }}
                    MenuProps={{
                      PaperProps: { style: { maxHeight: "300px" } },
                    }}
                  >
                    <MenuItem
                      key="select-business"
                      value="Valitse sopimusasiakas"
                      disabled
                      sx={{ color: "black" }}
                    >
                      Valitse sopimusasiakas
                    </MenuItem>
                    {externalBusinessList?.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Tooltip>
              <Box sx={{ width: "150px " }}>
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    selectedExternalBusiness?.id === "no-business" ||
                    selectedExternalBusiness?.id === "select-business"
                  }
                  onClick={handleButtonClick}
                  startIcon={<UploadFileIcon />}
                  sx={{ height: "56px", textTransform: "none" }}
                >
                  Lataa CSV
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
        <Box sx={{ maxHeight: "500px" }}>
          <DataGridPro
            loading={pricingExceptionLoading}
            treeData
            getTreeDataPath={getTreeDataPath}
            //  getRowId={(row) => row.key}
            sx={{
              //   borderColor: "transparent",
              borderRadius: "14px",
              overflow: "hidden",
              height: "500px",
              /*         fontFamily: "Public Sans, sans-serif",
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        }, */
              "& .MuiDataGrid-columnHeadersInner": {
                backgroundColor: "#f7f7f8",
                color: "#4e536c",
                fontFamily: "Sofia Pro",
                fontSize: "16px",
                // boxShadow: theme.shadows[4],
              },
            }}
            rows={extBusinessRows}
            columns={extBusinessColumns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[2]}
            experimentalFeatures={{ newEditingApi: true }}
            groupingColDef={groupingColDef}
            disableRowSelectionOnClick
            disableSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
          />
        </Box>
      </Stack>
      <AddedPricingsDialog
        open={showAddedPricingsDialog}
        onClose={() => {
          setShowAddedPricingsDialog(false);
          setErrorMsg(null);
          pricingsMutate();
        }}
        addedPricings={addedPricings}
        csvLoading={csvLoading}
        notAddedPricings={notAddedPricings}
        errorMsg={errorMsg}
      />
    </>
  );
}

function DefaultPricingsView(props) {
  const {
    categoryDynamicPricing,
    createNew,
    disableFields,
    mobileViewPort,
    pricings,
    pricingsValidating,
    processRowUpdate,
    setEditablePricing,
  } = props;
  const { user, business } = useContext(AppContext);

  const pricingRows = useMemo(() => {
    const rows = [];
    if (pricings) {
      pricings.forEach((pricing, idx) => {
        if (
          !pricing?.orgExternalBusinessId &&
          !pricing?._removed &&
          !pricing?.orgBusinessId
        ) {
          const pricingHour = pricing.elements.find(
            (el) => el.duration === "h0"
          )?.price;
          const pricingDay = pricing.elements.find(
            (el) => el.duration === "d0"
          )?.price;

          rows.push({
            id: pricing?.id,
            name: pricing?.name,
            editPricing: pricing,
            pricingHour: pricingHour,
            pricingDay: pricingDay,
            VAT: pricing?.tax ?? 24,
            percentage: categoryDynamicPricing
              ? categoryDynamicPricing.find(
                  (discount) => discount?.pricingId === pricing?.id
                )?.percentage
              : null,
          });
        }
      });
    }

    return rows;
  }, [pricings, categoryDynamicPricing]);

  const pricingsColumns = useMemo(() => {
    const nameHeader = "Hinnasto";
    const nameField = {
      field: "name",
      headerName: nameHeader,
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
    };

    const VAT = {
      field: "VAT",
      headerName: "Verokanta",
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? undefined : 175,
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} %`;
      },
    };

    const editPricing = {
      field: "editPricing",
      headerName: " ",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditablePricing(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    };

    if (business) {
      return [
        nameField,
        percentage,
        pricingHourly,
        pricingDaily,
        VAT,
        editPricing,
      ];
      // columns for legacy users
    } else {
      return [nameField, pricingHourly, pricingDaily, VAT, editPricing];
    }
  }, [mobileViewPort, business, setEditablePricing]);

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ padding: "10px" }}>
        <Stack
          direction={mobileViewPort ? "column" : "row"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} flex={1} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              align="center"
              sx={{ fontFamily: "Sofia Pro" }}
            >
              Hinnastot
            </Typography>

            <Button
              variant="contained"
              color="primary"
              disabled={disableFields}
              onClick={() => createNew("pricing")}
              sx={{
                textTransform: "none",
                width: "200px",
              }}
            >
              Lisää uusi hinnasto
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ maxHeight: "500px", overflow: "hidden", padding: "10px" }}>
        <DataGridPro
          loading={pricingsValidating}
          rows={pricingRows}
          columns={pricingsColumns}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            overflow: "hidden",
            height: "500px",
            /*         fontFamily: "Public Sans, sans-serif",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </>
  );
}

function DynamicPricingsView(props) {
  const {
    createNew,
    disableFields,
    mobileViewPort,
    pricingExceptions,
    pricings,
    pricingsValidating,
    processRowUpdate,
    setEditableException,
  } = props;
  const { user } = useContext(AppContext);

  const pricingRows = useMemo(() => {
    const rows = [];
    if (pricings) {
      if (pricingExceptions) {
        pricingExceptions.forEach((pricing, idx) => {
          if (!pricing?.orgExternalBusinessId) {
            rows.push({
              id: pricing?.id,
              name: pricing?.name,
              exceptionType: pricing.type === "WEEK" ? "Viikko" : "Kuukausi",
              editPricing: pricing,
              validity:
                pricing.type === "WEEK"
                  ? `${pricing.startDate} - ${pricing.endDate}`
                  : `Ennen: ${pricing.daysBeforeMonthChange} | Jälkeen: ${pricing.daysAfterMonthChange}`,
            });
          }
        });
      }
    }
    return rows;
  }, [pricings, pricingExceptions]);
  const pricingsColumns = useMemo(() => {
    const nameHeader = "Hinnasto";
    const nameField = {
      field: "name",
      headerName: nameHeader,
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
    };

    const pricingExceptionType = {
      field: "exceptionType",
      headerName: "Tyyppi",
      flex: 0.5,
      minWidth: 175,
    };

    const pricingExceptionValidity = {
      field: "validity",
      headerName: "Voimassaolo",
      flex: 0.5,
      minWidth: 175,
    };

    const pricingExceptionEdit = {
      field: "editPricing",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditableException(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    };

    return [
      nameField,
      pricingExceptionType,
      pricingExceptionValidity,
      pricingExceptionEdit,
    ];
  }, [mobileViewPort, user?.role]);

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ padding: "10px" }}>
        {/* Hide dynamic pricings from organization users until further notice */}

        <Stack
          direction={mobileViewPort ? "column" : "row"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="h5"
            align="left"
            sx={{ fontFamily: "Sofia Pro" }}
          >
            Dynaaminen hinnoittelu
          </Typography>

          <Box sx={{ marginLeft: mobileViewPort ? "45px" : undefined }}>
            <Button
              variant="contained"
              color="primary"
              disabled={disableFields}
              sx={{
                textTransform: "none",
                width: "200px",
              }}
              onClick={() => createNew("pricingException")}
            >
              Lisää dynaaminen hinta
            </Button>
          </Box>
        </Stack>
      </Stack>

      <Box sx={{ maxHeight: "500px", overflow: "hidden", padding: "10px" }}>
        <DataGridPro
          loading={pricingsValidating}
          rows={pricingRows}
          columns={pricingsColumns}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            overflow: "hidden",
            height: "500px",
            /*         fontFamily: "Public Sans, sans-serif",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </>
  );
}

function CategoryPricingsView(props) {
  const {
    categoryDynamicPricing,
    createNew,
    disableFields,
    editPricing,
    mobileViewPort,
    pricings,
    pricingsValidating,
    processRowUpdate,
    setEditablePricing,
  } = props;
  const { user, business } = useContext(AppContext);
  const categories = useCategories();

  const pricingRows = useMemo(() => {
    const rows = [];
    if (pricings) {
      if (categories?.length > 0) {
        categories.forEach((category, idx) => {
          const pricing = pricings?.find(
            (pricing) => pricing?.id === category?.pricing?.id
          );
          const modifiedPricing = pricings?.find(
            (pricing) =>
              pricing?.businessCategoryAssociation === category?.id &&
              pricing?.orgBusinessId === business?.id
          );
          const pricingHour = pricing?.elements.find(
            (el) => el.duration === "h0"
          )?.price;
          const pricingDay = pricing?.elements.find(
            (el) => el.duration === "d0"
          )?.price;

          const modifiedPricingHour = modifiedPricing?.elements.find(
            (el) => el.duration === "h0"
          )?.price;
          const modifiedPricingDay = modifiedPricing?.elements.find(
            (el) => el.duration === "d0"
          )?.price;

          rows.push({
            id: category?.id,
            name: category?.name,
            pricing: modifiedPricing ?? pricing,
            businessCategoryAssociation:
              modifiedPricing?.businessCategoryAssociation ? true : false,
            percentage:
              categoryDynamicPricing && !modifiedPricing
                ? categoryDynamicPricing.find(
                    (discount) => discount?.pricingId === pricing?.id
                  )?.percentage
                : null,
            pricingHour: modifiedPricingHour ?? pricingHour,
            pricingDay: modifiedPricingDay ?? pricingDay,
            editPricing: modifiedPricing ?? null,
            VAT: pricing?.tax ?? 24,
          });
        });
      }
    }
    return rows;
  }, [pricings, business?.id, categoryDynamicPricing, categories]);

  const pricingsColumns = useMemo(() => {
    const nameHeader = "Autoluokka";
    const nameField = {
      field: "name",
      headerName: nameHeader,
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
    };

    const percentage = {
      field: "percentage",
      headerName: "Hinnan muutos",
      type: "number",
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
      renderEditCell: renderEditPercentage,
      editable: true,
      valueGetter: (params) => params.value ?? "",
      valueFormatter: (params) => {
        if (!params.value || params.value === "") {
          return "";
        }
        return `${params.value} %`;
      },
    };

    const pricingDaily = {
      field: "pricingDay",
      headerName: "Vuorokausi",
      type: "number",
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
      renderCell: (params) => {
        if (params.row?.percentage) {
          const rowPricingDay = () => {
            const userDefinedPrice =
              params.row?.pricingDay +
              (params.row?.pricingDay * params.row?.percentage) / 100;
            if (!!userDefinedPrice) {
              return centsToLocalString(userDefinedPrice);
            } else return params.row?.pricingDay;
          };
          return rowPricingDay();
        } else {
          if (isNaN(params.row.pricingDay)) {
            return "";
          } else {
            return centsToLocalString(params.row.pricingDay);
          }
        }
      },
    };
    const VAT = {
      field: "VAT",
      headerName: "Verokanta",
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? undefined : 175,
      type: "number",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toLocaleString()} %`;
      },
    };

    const editPricing = {
      field: "editPricing",
      headerName: " ",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditablePricing(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    };

    const businessCategoryAssociation = {
      field: "businessCategoryAssociation",
      headerName: "Hinnasto",
      type: "singleSelect",
      editable: true,
      getOptionValue: (value) => value.code,
      getOptionLabel: (value) => value.name,
      valueFormatter: (params) =>
        !!params.value ? "Oma hinnasto " : "Organisaatio",

      valueOptions: [
        { code: false, name: "Organisaation hinnasto" },
        { code: true, name: "Oma hinnasto" },
      ],
      flex: 0.5,
      minWidth: 125,
    };

    if (user?.role === "ORGANIZATION_ADMIN" && !business) {
      return [nameField, pricingHourly, pricingDaily, VAT, editPricing];
    } else {
      return [
        nameField,
        businessCategoryAssociation,
        percentage,
        pricingHourly,
        pricingDaily,
        VAT,
        editPricing,
      ];
    }
  }, [mobileViewPort, business, user?.role, setEditablePricing]);

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ padding: "10px" }}>
        <Stack
          direction={mobileViewPort ? "column" : "row"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} flex={1} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              align="center"
              sx={{ fontFamily: "Sofia Pro" }}
            >
              Hinnastot
            </Typography>

            <Button
              variant="contained"
              color="primary"
              disabled={disableFields}
              onClick={() => createNew("pricing")}
              sx={{
                textTransform: "none",
                width: "200px",
              }}
            >
              Lisää uusi hinnasto
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ maxHeight: "500px", overflow: "hidden", padding: "10px" }}>
        <DataGridPro
          loading={pricingsValidating}
          rows={pricingRows}
          columns={pricingsColumns}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            overflow: "hidden",
            height: "500px",
            /*         fontFamily: "Public Sans, sans-serif",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </>
  );
}

const pricingHourly = {
  field: "pricingHour",
  headerName: "Tunti",
  flex: 0.5,
  minWidth: 100,
  type: "number",
  renderCell: (params) => {
    //  console.log("params", params.row.pricing);
    if (params.row?.percentage) {
      const rowPricingHour = () => {
        const userDefinedPrice =
          params.row?.pricingHour +
          (params.row?.pricingHour * params.row?.percentage) / 100;
        if (!!userDefinedPrice) {
          return centsToLocalString(userDefinedPrice);
        } else return params.row?.pricingHour;
      };

      return rowPricingHour();
    } else {
      if (isNaN(params.row.pricingHour)) {
        return "";
      } else {
        return centsToLocalString(params.row.pricingHour);
      }
    }
  },
};

const pricingDaily = {
  field: "pricingDay",
  headerName: "Vuorokausi",
  type: "number",
  flex: 0.5,
  minWidth: 100,
  renderCell: (params) => {
    if (params.row?.percentage) {
      const rowPricingDay = () => {
        const userDefinedPrice =
          params.row?.pricingDay +
          (params.row?.pricingDay * params.row?.percentage) / 100;
        if (!!userDefinedPrice) {
          return centsToLocalString(userDefinedPrice);
        } else return params.row?.pricingDay;
      };
      return rowPricingDay();
    } else {
      if (isNaN(params.row.pricingDay)) {
        return "";
      } else {
        return centsToLocalString(params.row.pricingDay);
      }
    }
  },
};

const percentage = {
  field: "percentage",
  headerName: "Hinnan muutos",
  type: "number",
  flex: 0.5,
  minWidth: 100,
  renderEditCell: renderEditPercentage,
  editable: true,
  valueGetter: (params) => params.value ?? "",
  valueFormatter: (params) => {
    if (!params.value || params.value === "") {
      return "";
    }
    return `${params.value} %`;
  },
};

function renderEditPercentage(params) {
  return (
    <GridEditInputCell
      {...params}
      disabled={params.row?.businessCategoryAssociation}
    />
  );
}

import { TextField, Grid } from "@mui/material";
import { FormContent, FormGrid } from "../../common/FormComponents";
import { useFormikContext } from "formik";
import { useContext, useState } from "react";
import { AppContext } from "../../../AppContext";

export default function ReservationMessages() {
  const { values, errors, handleBlur, handleChange, touched } =
    useFormikContext();
  const [toggleShowContent, setToggleShowContent] = useState(true);
  const { USER_ADMIN_GENERIC } = useContext(AppContext);

  return (
    <Grid
      item
      lg={12}
      container
      sx={{ flexDirection: "row", justifyContent: "center" }}
    >
      <Grid item xs={12}>
        <FormContent
          title={"Viestit"}
          visibility={true}
          expanded={toggleShowContent}
          setExpanded={setToggleShowContent}
        >
          <Grid container sx={{ justifyContent: "center" }} spacing={1}>
            {USER_ADMIN_GENERIC && (
              <FormGrid>
                <TextField
                  value={values.internalNotes ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="internalNotes"
                  label="Sisäiset muistiinpanot"
                  variant="outlined"
                  error={touched.internalNotes && !!errors.internalNotes}
                  helperText={touched.internalNotes && errors.internalNotes}
                  fullWidth
                  multiline={true}
                  rows={5}
                />
              </FormGrid>
            )}
            {USER_ADMIN_GENERIC && (
              <FormGrid>
                <TextField
                  value={values.rentalNotes ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="rentalNotes"
                  label="Vuokraamon viesti asiakkaalle"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  rows={5}
                />
              </FormGrid>
            )}
            <FormGrid>
              <TextField
                value={values.externalNotes ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                name="externalNotes"
                label="Asiakkaan viesti vuokraamolle"
                variant="outlined"
                fullWidth
                multiline={true}
                InputProps={{
                  readOnly: USER_ADMIN_GENERIC,
                }}
                rows={5}
              />
            </FormGrid>
          </Grid>
        </FormContent>
      </Grid>
    </Grid>
  );
}

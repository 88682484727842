export const externalUserHanselClasses = [
  {
    class: "1",
    contractVehicleClass: "A",
    name: "Pienet autot",
    exampleModel: "Volkswagen Polo tai vastaava",
    productNro: "7001650",
    vehicleClasses: ["B-EDMR"],
  },

  {
    class: "2",
    contractVehicleClass: "C",
    name: "Pieni keskiluokka",
    exampleModel: "Peugeot 308 tai vastaava",
    productNro: "7001651",
    vehicleClasses: ["CWA-CWAR"],
  },
  {
    class: "3",
    contractVehicleClass: "D",
    name: "Suuri keskiluokka",
    exampleModel: "Skoda Octavia tai vastaava",
    productNro: "7001652",
    vehicleClasses: ["DWA-IWAR"],
  },
  {
    class: "4",
    contractVehicleClass: "F",
    name: "Suuret autot",
    exampleModel: "BMW 5-sarja tai vastaava",
    productNro: "7001653",
    vehicleClasses: ["FW-FWAR"],
  },
  {
    class: "5",
    contractVehicleClass: "I",
    name: "SUV",
    exampleModel: "Peugeot 3008 tai vastaava",
    productNro: "7001654",
    vehicleClasses: ["IA-IFAR"],
  },
  {
    class: "6",
    contractVehicleClass: "X",
    name: "Neliveto SUV",
    exampleModel: "BMW X3 tai vastaava",
    productNro: "7001655",
    vehicleClasses: ["X-PFBR"],
  },
  {
    class: "7",
    contractVehicleClass: "H",
    name: "Suuret tila-autot ja pikkubussit",
    exampleModel: "Volkswagen Transporter Kombi tai vastaava",
    productNro: "7001656",
    vehicleClasses: ["H2A-LVAR"],
  },
  {
    class: "8",
    contractVehicleClass: "K",
    name: "Pakettiautot",
    exampleModel: "Peugeot Expert tai vastaava",
    productNro: "7001657",
    vehicleClasses: ["K-CKMD"],
  },
  {
    class: "9",
    contractVehicleClass: "R",
    name: "Isot pakettiautot",
    exampleModel: "Ford Transit tai vastaava",
    productNro: "7001658",
    vehicleClasses: ["P-DKMD", "PA-DKAD"],
  },
  {
    class: "10A",
    contractVehicleClass: "M-FKMD",
    name: "Muuttoautot",
    exampleModel: "Volkswagen Crafter tai vastaava",
    productNro: "7001659",
    vehicleClasses: ["M-FKMD"],
  },
  {
    class: "10B",
    contractVehicleClass: "W",
    name: "Neliveto pick-up",
    exampleModel: "Volkswagen Amarok tai vastaava",
    productNro: "7001660",
    vehicleClasses: ["W-XPAD"],
  },
  {
    class: "10C",
    contractVehicleClass: "",
    name: "Peräkärry",
    productNro: "7001661",
    vehicleClasses: ["PE-KUOMU", "AVOKÄR1", "AVOKÄR2"],
  },
];

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { defaultHanselClasses } from "../utils/hanselClasses";
import { useFormikContext } from "formik";

export function SelectHanselClass(props) {
  const { isDisabled } = props;
  const { setFieldValue, values } = useFormikContext();

  const value = values?.hanselData?.hanselClass || null;
  return (
    <Autocomplete
      disabled={isDisabled}
      fullWidth
      value={value}
      options={defaultHanselClasses}
      isOptionEqualToValue={(option, value) => {
        return option?.productNro === value;
      }}
      onChange={async (event, newValue) => {
        await setFieldValue(
          "hanselData.hanselClass",
          newValue?.productNro ? newValue?.productNro : null
        );
      }}
      filterOptions={(options, state) => {
        return options.filter((option) => {
          const inputValue = state?.inputValue;
          if (
            option.desc.toLowerCase().includes(inputValue?.toLowerCase()) ||
            option.class.toLowerCase().startsWith(inputValue?.toLowerCase()) ||
            option.productNro.startsWith(state?.inputValue)
          ) {
            return option;
          }
          return null;
        });
      }}
      getOptionLabel={(option) => option?.productNro ?? option}
      noOptionsText="Ei luokkaa"
      renderOption={(props, option) => {
        return (
          <Box {...props} key={option.productNro}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: "10px",
                cursor: "pointer",
                transition: "250ms ease-in-out",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: 550 }} align="left">
                {option?.productNro}
              </Typography>
              <Typography>{option?.desc}</Typography>
              <Typography sx={{ color: "rgba(0,0,0,0.55)" }}>
                {option?.class}
              </Typography>
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Valitse hansel luokka" />
      )}
    />
  );
}

import React from "react";
import { createRoot } from "react-dom/client";
import "./print-rules.css";

let printRoot = null;
function clearPrintRoot() {
  if (printRoot != null) {
    printRoot.remove();
    printRoot = null;
  }
}

//Warning: Clear is also called before print as a workaround since afterprint-event is pseudo randomly ignored on Chrome.
window.addEventListener("afterprint", function (event) {
  clearPrintRoot();
});

export default function print(reactElement) {
  clearPrintRoot();

  const domNode = document.createElement("div");
  domNode.className = "print-root";
  printRoot = domNode;

  const root = createRoot(domNode);
  console.log("print");
  const elWithCallback = React.cloneElement(reactElement, {
    callback: () => {
      console.log("callback");
      // Use callback function to make sure DOM we are printing is populated
      document.body.appendChild(domNode);
      window.print();
    },
  });
  root.render(elWithCallback);
}

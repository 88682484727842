import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import TabbedContent from "./components/TabbedContent";
import React, { useState } from "react";
import PricingEditDialog from "./components/EditPricingDialog";

export default function PricingsDialog(props) {
  const { open, onClose } = props;
  const [editablePricing, setEditablePricing] = useState(null);

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth maxWidth={"xl"}>
        <DialogContent
          sx={{ height: { xs: undefined, sm: "80vh", overflow: "hidden" } }}
        >
          <TabbedContent setEditablePricing={setEditablePricing} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()}>Sulje</Button>
        </DialogActions>
      </Dialog>
      {editablePricing && (
        <PricingEditDialog
          pricing={editablePricing}
          setEditablePricing={setEditablePricing}
          onClose={() => setEditablePricing(null)}
        />
      )}
    </React.Fragment>
  );
}

import { Skeleton, TableCell } from "@mui/material";
import { useFetchPricingsOrganization } from "../../../../../services/fetchPricings";
import { EPricingBy } from "../../../utils/tabOptions";
import { centsToLocalString } from "../../../../../shared/money";
import useGetDynamicPricingPercentage from "../../../hooks/useGetDynamicPricingPercentage";

export default function DayPricingRate(props) {
  const { pricing, activePricing, selectedCompany } = props;
  const cellWidth = "150px";
  const { pricingsLoading } = useFetchPricingsOrganization("pricingListDialog");

  const { dynamicPricing } = useGetDynamicPricingPercentage(
    selectedCompany?.id,
    pricing?.id
  );

  if (pricingsLoading) {
    return (
      <TableCell sx={{ width: cellWidth }}>
        <Skeleton sx={{ height: "50px" }} />
      </TableCell>
    );
  }

  if (pricing) {
    const pricingDay = pricing.elements.find(
      (el) => el.duration === "d0"
    )?.price;
    if (activePricing === EPricingBy.ORGANIZATION) {
      const price = dynamicPricing?.percentage
        ? pricingDay + (pricingDay * dynamicPricing.percentage) / 100
        : pricingDay;

      return (
        <TableCell width={cellWidth} align="right">
          {price ? `${centsToLocalString(price)} €` : "-"}
        </TableCell>
      );
    }
    return (
      <TableCell width={cellWidth} align="right">
        {pricingDay ? ` ${centsToLocalString(pricingDay)} €` : "-"}
      </TableCell>
    );
  } else {
    return <TableCell />;
  }
}

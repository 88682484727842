import { Avatar } from "@mui/material";
import { Vehicle } from "~/graphql/API";
import { useVehicleImage } from "~/hooks/useVehicleImage";

type VehiclePictureProps = {
  vehicle: Vehicle;
};

function VehiclePicture({ vehicle }: VehiclePictureProps) {
  const { image, backgroundColor } = useVehicleImage(vehicle);

  return (
    <Avatar
      variant="rounded"
      src={typeof image === "string" ? image : undefined}
      sx={{
        width: 50,
        height: 50,
        border: "solid",
        borderWidth: "1px",
        borderColor: "rgba(0,0,0,0.07)",
        bgcolor: backgroundColor || "rgba(0,0,0,0.02)",
      }}
    >
      {image}
    </Avatar>
  );
}

export default VehiclePicture;

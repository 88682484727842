import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useFormikContext } from "formik";

export default function DisablePricesInPrintouts() {
  const { values, handleChange, handleBlur } = useFormikContext();
  return (
    <Grid item xs={12} sm={6}>
      <FormControlLabel
        control={
          <Checkbox
            name="disablePricesInPrintouts"
            checked={Boolean(values.disablePricesInPrintouts)}
            color="primary"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        }
        label="Poista hinta sopimukselta sekä varausvahvistukselta"
      />
    </Grid>
  );
}

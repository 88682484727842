import { addDays, format, isToday } from "date-fns";
import { useMemo } from "react";

export type DateInfo = {
  date: number;
  count: "even" | "odd";
  isToday: boolean;
  stringDate: string;
};

export function useDates(startDate: number, count: number) {
  return useMemo(() => {
    const out: DateInfo[] = [];

    for (let i = 0; i < count; i++) {
      const date = addDays(startDate, i);

      out.push({
        date: date.getTime(),
        count: i % 2 ? "odd" : "even",
        isToday: isToday(date),
        stringDate: format(date, "yyyy-MM-dd"),
      });
    }

    return out;
  }, [startDate, count]);
}

import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  Tooltip,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { BsPlus } from "react-icons/bs";

function CustomerRegistry({ business, customers, onEdit, onDelete, onAddNew }) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCustomers = customers
    ?.filter(
      (customer) =>
        customer?.companyName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer?.companyBusinessId
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .map((customer) => {
      let disableEdit = false;
      if (business && customer.organizationId && !customer.orgBusinessId) {
        // Organization level customers should not be updated when Business level is selected
        disableEdit = true;
      }
      return { ...customer, disableEdit };
    });

  const renderText = (text, isCustomerType = false) => {
    if (isCustomerType) {
      text =
        text === "COMPANY" ? "Yritys" : text === "BROKER" ? "Agentti" : text;
    }

    return (
      <Box
        sx={{
          maxWidth: 150,
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        {text}
      </Box>
    );
  };

  return (
    <Box>
      <Grid container spacing={2} alignItems="top">
        <Grid item xs={12} sm={true}>
          <TextField
            label="Etsi"
            variant="outlined"
            fullWidth
            margin="normal"
            sx={{ minHeight: "7dvh" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm="auto" sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onAddNew}
            startIcon={<BsPlus style={{ fontSize: 26 }} />}
            sx={{
              mt: { sm: 2, xs: 0 },
              mb: { sm: 0, xs: 2 },
              maxWidth: { sm: "none", xs: "100%" },
              height: "56px",
            }}
          >
            Lisää uusi asiakas
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: "80%", minHeight: 400, overflow: "auto" }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nimi</TableCell>
              <TableCell>Y-tunnus</TableCell>
              <TableCell>Asiakkaan tyyppi</TableCell>
              <TableCell>Postitoimipaikka</TableCell>
              <TableCell>Postinumero</TableCell>
              <TableCell>Laskutusosoite</TableCell>
              <TableCell>Laskun viite</TableCell>
              <TableCell>Verkkolasku välitystunnus</TableCell>
              <TableCell>Verkkolasku osoite</TableCell>
              <TableCell
                align="right"
                style={{
                  position: "sticky",
                  right: 0,
                  background: "white",
                  zIndex: 1,
                }}
              >
                Toiminnot
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCustomers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>
                  {renderText(
                    customer?.clientType === "BROKER"
                      ? customer?.brokerName
                      : customer?.companyName
                  )}
                </TableCell>
                <TableCell>{renderText(customer?.companyBusinessId)}</TableCell>
                <TableCell>{renderText(customer?.clientType, true)}</TableCell>
                <TableCell>{renderText(customer?.companyCity)}</TableCell>{" "}
                <TableCell>{renderText(customer?.companyPostalCode)}</TableCell>{" "}
                <TableCell>
                  {renderText(customer?.companyBillingAddress)}
                </TableCell>
                <TableCell>{renderText(customer?.companyBillingRef)}</TableCell>
                <TableCell>
                  {renderText(customer?.companyEBillingOperator)}
                </TableCell>
                <TableCell>
                  {renderText(customer?.companyEBillingAddress)}
                </TableCell>
                <TableCell
                  align="right"
                  style={{
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <Tooltip title="Muokkaa">
                      <IconButton
                        disabled={customer.disableEdit}
                        onClick={() => onEdit(customer)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Poista">
                      <IconButton
                        disabled={customer.disableEdit}
                        onClick={() =>
                          onDelete({
                            id: customer.id,
                            name: customer?.companyName,
                          })
                        }
                        aria-label="delete"
                        sx={{ color: "salmon" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
            {filteredCustomers.length === 0 && (
              <TableRow style={{ height: 53 * 5 }}>
                <TableCell colSpan={10} align="center">
                  {" "}
                  Asiakkaita ei löytynyt
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default CustomerRegistry;

import React, { createContext, useState, useEffect, useMemo } from "react";
import { useFetchCompanies } from "./services/fetchCompanies";
import { addDays, lastDayOfMonth, startOfMonth } from "date-fns";
import { useMediaQuery, useTheme } from "@mui/material";
import { enableNPSfeature } from "./features";
import { Cache } from "aws-amplify";
import { EUserAdminType } from "./enums/user-admin-type";

export const AppContext = createContext();

export const AppProvider = ({ children, userData, USER_ADMIN_TYPE }) => {
  const theme = useTheme();

  const defaultMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const mobileSmViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileViewPort = defaultMobileView;

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 6));
  const [economyQueryDates, setEconomyQueryDates] = useState([
    startOfMonth(new Date()),
    lastDayOfMonth(new Date()),
  ]);
  const [company, setCompany] = useState();
  const [disableDialogAction, setDisableDialogAction] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedView, setSelectedView] = useState("weekly");
  const [showAllCompanies, setShowAllCompanies] = useState(false);
  const [user, setUser] = useState(() => userData);
  const [editVehicleData, setEditVehicleData] = useState(null);
  const [rowDisplayStyle, setRowDisplayStyle] = useState("regular");
  const [showCustomerManagementDialog, setShowCustomerManagementDialog] =
    useState(false);

  // Currently selected Business. Can be altered to also take into account
  // a separate selection of Business (org admin)
  const business = useMemo(() => {
    // selectedBusiness is controlled by organization admin
    if (selectedBusiness) {
      return selectedBusiness;
    }
    if (user?.organizationId)
      // Normal use case for all the users except organization admin
      return user?.businessByOrg?.items.find(
        (business) => business.id === user?.orgBusinessId
      );
    return null;
  }, [user, selectedBusiness]);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  // Feature toggles business title
  // true = entrepreneur, false = city
  const { FEATURE_BUSINESS_TITLE, FEATURE_ENABLE_HANSEL } = useMemo(
    () => ({
      FEATURE_BUSINESS_TITLE: ![
        "703cdba4-7158-11ee-b962-0242ac120002", // TOKMANNI
        "d90a0808-7f16-41af-a374-0244e5fe9df1", // Dev organization
        "06b8b3a2-b441-11ee-a506-0242ac120002", // PUUILO
      ].includes(user?.organizationId),

      FEATURE_ENABLE_HANSEL: [
        "d90a0808-7f16-41af-a374-0244e5fe9df1", // Dev organization
        "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent
      ].includes(user?.organizationId),
    }),
    [user?.organizationId]
  );

  useEffect(() => {
    if (companies && !company) {
      setCompany(companies[0]); // Set initial
    }
    if (companies && company) {
      const selectedCompany = companies.find((c) => c.id === company.id);
      setCompany(selectedCompany); // Update active
    }
  }, [company, companies]);

  useEffect(() => {
    const getDisplayStyle = async () => {
      const cachedDataRowDisplayStyle = await Cache.getItem("rowDisplayStyle");
      if (cachedDataRowDisplayStyle) {
        setRowDisplayStyle(cachedDataRowDisplayStyle);
      } else {
        return "regular";
      }
    };

    getDisplayStyle();
  }, []);

  // TODO: Replace vehicles from App.jsx with this one

  useEffect(() => {
    if (user?.organizationId) {
      setShowAllCompanies(true);
      if (user?.orgBusinessIds?.length) {
        setSelectedBusiness(
          user?.businessByOrg?.items.find(
            (b) => b.id === user?.orgBusinessIds[0]
          )
        );
      }
    }
  }, [user]);

  // Features
  const enableNPS = useMemo(() => {
    return enableNPSfeature(company, user?.organization);
  }, [company, user]);

  const store = {
    business,
    company,
    dateRange,
    disableDialogAction,
    editVehicleData,
    enableNPS,
    endDate,
    economyQueryDates,
    setEconomyQueryDates,
    FEATURE_BUSINESS_TITLE,
    FEATURE_ENABLE_HANSEL,
    FEATURE_PAYMENT_METHOD_SELECTOR: false,
    group: companies?.[0]?.group || null,
    legacyUser: !!user?.group,
    mobileSmViewPort,
    mobileViewPort,
    reservationDialogVersion:
      !!user?.organizationId || !!company?.features?.enableReservationDialogV2,
    rowDisplayStyle,
    selectedBusiness,
    selectedView,
    setCompany,
    setDateRange,
    setDisableDialogAction,
    setEditVehicleData,
    setEndDate,
    setRowDisplayStyle,
    setSelectedBusiness,
    setSelectedView,
    setShowAllCompanies,
    setShowCustomerManagementDialog,
    setStartDate,
    setUser,
    showAllCompanies,
    showCustomerManagementDialog,
    startDate,
    timezone: company?.timezone || "Europe/Helsinki",
    USER_ADMIN_CORPORATE: USER_ADMIN_TYPE === EUserAdminType.CORPORATE,
    USER_ADMIN_GENERIC: USER_ADMIN_TYPE === EUserAdminType.GENERIC,
    USER_ADMIN_INSURANCE: USER_ADMIN_TYPE === EUserAdminType.INSURANCE,
    USER_ADMIN_TYPE,
    user,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

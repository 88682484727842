import { getTotal } from "~/utils/getTotal";
import { Box, Grid, useTheme } from "@mui/material";
import { Invoice } from "~/graphql/API";

type InvoiceTotalProps = { invoice: Invoice };

function InvoiceTotal({ invoice }: InvoiceTotalProps) {
  const theme = useTheme();

  return (
    <Grid container fontWeight="bold" spacing={2} marginTop={-5}>
      <Grid item xs="auto" paddingTop={0}>
        Yhteensä:
      </Grid>

      <Grid item xs="auto">
        <Grid container>
          {invoice.items
            .reduce((acc, item) => {
              const itemPrice = getTotal(parseFloat(`${item.unitprice}`), {
                quantity: item.quantity,
              });

              return acc + itemPrice;
            }, 0)
            .toFixed(2)}
          <Box
            marginLeft={1}
            alignContent="end"
            fontSize="85%"
            color={theme.palette.text.secondary}
          >
            (sis. alv)
          </Box>
        </Grid>

        <Grid container>
          {invoice.items
            .reduce((acc, item) => {
              const unitPrice = parseFloat(`${item.unitprice}`);

              const itemPrice = getTotal(unitPrice, {
                quantity: item.quantity,
                tax: item.vat,
                removeTax: true,
              });

              return acc + itemPrice;
            }, 0)
            .toFixed(2)}
          <Box
            marginLeft={1}
            alignContent="end"
            fontSize="85%"
            color={theme.palette.text.secondary}
          >
            (ilman alv)
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InvoiceTotal;

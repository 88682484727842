import { getHours, set } from "date-fns";
import { useSetAtom } from "jotai";
// @ts-ignore
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

type useCreateReservationArgs = { date?: string | number; vehicle?: any };

export function useCreateReservationFromContext() {
  // Fix typescript types
  const setEditReservationData = useSetAtom<any>(reservationDataAtom);

  return ({ date, vehicle }: useCreateReservationArgs) => {
    const now = new Date();
    const startDate = date ? new Date(date) : now;
    const nowHours = getHours(now);

    setEditReservationData({
      reservationVehicles: [vehicle],
      companyId: vehicle?.companyId,
      startTime: set(startDate, {
        hours: nowHours + 1,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      returnTime: set(startDate, {
        hours: nowHours + 2,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      endTime: set(startDate, {
        hours: nowHours + 2,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
    });
  };
}

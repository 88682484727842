import { Stack, useMediaQuery, useTheme } from "@mui/material";

import { useFormikContext } from "formik";

import { dialogDimensions } from "../../utils/dialogDimensions";
import { menu } from "../../utils/menuItems";
import SideMenuItem from "./components/SideMenuItem";
import MobileSideMenu from "./components/MobileSideMenu";

export default function SideMenu(props) {
  const {
    collapsed,
    openDrawer,
    selectedTab,
    setOpenDrawer,
    setSelectedTab,
    visibility,
  } = props;
  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    setOpenDrawer(false);
  };
  const theme = useTheme();

  const mobileViewPort = useMediaQuery(theme.breakpoints.down("lg"));
  if (visibility) {
    if (mobileViewPort)
      return (
        <>
          <MobileSideMenu
            handleDrawerToggle={() => setOpenDrawer(!openDrawer)}
            menuOptions={
              <MenuOptions
                collapsed={false}
                handleSelectTab={handleSelectTab}
                menu={menu}
                selectedProductTab={selectedTab}
                setSelectedProductTab={setSelectedTab}
              />
            }
            mobileViewPort={mobileViewPort}
            openDrawer={openDrawer}
          />
        </>
      );
    return (
      <Stack
        spacing={1}
        sx={{
          marginRight: "15px",
          padding: "2px",
          paddingTop: "10px",
          position: "sticky",
          top: 0,
          width: dialogDimensions.sideMenuWidth,
        }}
      >
        <MenuOptions
          collapsed={collapsed}
          handleSelectTab={handleSelectTab}
          menu={menu}
          selectedProductTab={selectedTab}
          setSelectedProductTab={setSelectedTab}
        />
      </Stack>
    );
  }
}

function MenuOptions(props) {
  const {
    collapsed,
    handleSelectTab,
    menu,
    selectedProductTab,
    setSelectedProductTab,
  } = props;
  const { values } = useFormikContext();

  function isVisible(item, values) {
    // Return true if directly visible
    if (item.VISIBILITY) return true;
    // If formik has hansel values show the hansel tab
    return (
      item.VISIBILITY_FIELD &&
      item.VISIBILITY_VALUE &&
      values[item.VISIBILITY_FIELD] === item.VISIBILITY_VALUE
    );
  }
  if (menu) {
    return Object.values(menu).map((item, index) => {
      if (isVisible(item, values)) {
        return (
          <SideMenuItem
            collapsed={collapsed}
            handleSelectTab={handleSelectTab}
            item={item}
            key={item.TAB}
            selectedProductTab={selectedProductTab}
            setSelectedProductTab={setSelectedProductTab}
          />
        );
      }
      return null;
    });
  }
}

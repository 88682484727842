import { GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import useSWR from "swr";
import { searchCustomers } from "../graphql/queries";
import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";
import {
  Customer,
  SearchCustomersQuery,
  SearchCustomersQueryVariables,
} from "~/graphql/API";

export const useGetBrokers = () => {
  //@ts-ignore
  const { business } = useContext(AppContext);
  const variables = {
    filter: {
      clientType: { eq: "BROKER" },
      organizationId: { eq: business?.organizationId },
    },
  };
  const shouldFetch = !!business?.organizationId;
  const key = shouldFetch ? { query: searchCustomers, variables } : null;
  const { data, error, isLoading } = useSWR(key, fetchBrokers, {
    revalidateOnFocus: false,
  });

  // Filter out deleted brokers for dropdown display purposes
  const brokers = data ? data.filter((broker) => !broker.isDeleted) : [];

  return { data, brokers, isLoading, error };
};

const fetchBrokers = async (args: {
  query: string;
  variables: SearchCustomersQueryVariables;
}) => {
  try {
    const { query, variables } = args;

    const response = (await API.graphql(
      graphqlOperation(query, variables)
    )) as GraphQLResult<SearchCustomersQuery>;
    const allBrokers = (response?.data?.searchCustomers?.items || []).filter(
      Boolean
    ) as Customer[];
    return allBrokers; // Save all brokers to state
  } catch (error) {
    console.error("Error fetching brokers", error);
  }
};

import { TextField, Grid, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import MessageEdit from "../ReviewsReportDialog/MessageEdit";
import { useFormikContext } from "formik";
import { AppContext } from "../../AppContext";

function TooltipWrapperForDisabled({ children, disabledMsg }) {
  if (children.props.disabled) {
    return (
      <Tooltip title={disabledMsg}>
        <span>{children}</span>
      </Tooltip>
    );
  }
  return children;
}

export default function ConfirmationMessage() {
  const { company, enableNPS } = React.useContext(AppContext);
  const { values, errors, handleChange, handleBlur } = useFormikContext();
  const [editMessage, setEditMessage] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            value={values.confirmationMsg ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="confirmationMsg"
            label="Viesti varausvahvistukselle"
            variant="outlined"
            fullWidth
            multiline={true}
            error={!!errors.confirmationMsg}
            helperText={errors.confirmationMsg}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={values.statusLinkMsg ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="statusLinkMsg"
            label="Viesti täydennyslinkille"
            variant="outlined"
            fullWidth
            multiline={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TooltipWrapperForDisabled disabledMsg="Oletko kiinnostunut automatisoiduista NPS kyselyistä? Ota yhteys myyntiin">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditMessage(true)}
              style={{ marginLeft: "auto" }}
              disabled={!enableNPS}
            >
              NPS -viestin muokkaus
            </Button>
          </TooltipWrapperForDisabled>
        </Grid>
      </Grid>
      {editMessage && (
        <MessageEdit
          company={company}
          onClose={() => {
            setEditMessage(false);
          }}
        />
      )}
    </>
  );
}

import {
  Grid,
  Stack,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import InsuranceCompanySelect from "./InsuranceCompanySelect";
import InnovoiceCredit from "../../../../../../../InnovoiceCredit";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function Invoicing({
  setShowInvoiceDialog,
  business,
  values,
  notification,
}) {
  const reservationData = useAtomValue(reservationDataAtom);
  if (!reservationData?.id) return; // Reservation must exist before createing Invoice is possible
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          {reservationData?.type !== "INSURANCE" && (
            <Button
              variant="contained"
              onClick={(e) => {
                setShowInvoiceDialog({ type: "FULL" });
              }}
              disabled={reservationData?.type === "INSURANCE"}
            >
              {" "}
              Lasku {values?.isBroker ? "agentille" : "asiakkaalle"}{" "}
            </Button>
          )}
          {reservationData?.channel === "AUTOLIITTO" &&
            reservationData?.type !== "INSURANCE" && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setShowInvoiceDialog({ type: "AUTOLIITTO" });
                }}
              >
                Lasku autoliitolle
              </Button>
            )}
          {reservationData?.type === "INSURANCE" && (
            <Button
              variant="contained"
              onClick={(e) => {
                if (!values.insuranceCompany) {
                  return notification.show("Valitse ensin vakuutusyhtiö");
                }
                if (!values.insuranceData?.billingPermitCode) {
                  return notification.show("Aseta laskutuslupatunnus");
                }
                setShowInvoiceDialog({ type: "INSURANCE_COMPANY" });
              }}
              disabled={reservationData?.type !== "INSURANCE"}
            >
              Vakuutusyhtiölasku{" "}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={(e) => {
              setShowInvoiceDialog({ type: "EXTRA" });
            }}
          >
            Lisälaskutus{" "}
          </Button>
        </Stack>
      </Grid>
      <InsuranceInvoicingSettings />
      <Grid item xs={12}>
        <InnovoiceCredit orgBusinessId={business?.id} initialSsn={values.ssn} />
      </Grid>
    </Grid>
  );
}

function InsuranceInvoicingSettings() {
  const { values, handleChange, handleBlur } = useFormikContext();
  const reservationData = useAtomValue(reservationDataAtom);
  if (reservationData.type !== "INSURANCE") return null;
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography style={{ fontFamily: "Sofia Pro", fontSize: 18 }}>
          Vakuutuslaskutuksen asetukset
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="insuranceData.vatDeductible"
              checked={values?.insuranceData?.vatDeductible ?? false}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={<>ALV-vähennys</>}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Field
          name="insuranceData.coveragePercentage"
          label="Korvausjako %"
          component={FormikTextField}
        />
      </Grid>
      <Grid item xs={12} lg={10}>
        <InsuranceCompanySelect size="small" />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Field
          name="insuranceData.billingPermitCode"
          label="Laskutuslupatunnus"
          component={FormikTextField}
        />
      </Grid>
    </Grid>
  );
}

const FormikTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md}>
      <TextField
        {...field}
        variant="outlined"
        error={touched[field.name] && !!errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        size="small"
        fullWidth
        {...props}
      />
    </Grid>
  );
};

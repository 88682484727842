import { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { captureError, listAll } from "../../../utilities";
import { pricingExceptionByOrgBusinessId } from "../../../graphql/queries";
import { graphqlOperation } from "aws-amplify";
import DynaminPricingEditDialog from "./EditDynamicPricingDialog";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";
import { useTheme } from "@emotion/react";
import { AppContext } from "../../../AppContext";

export default function DynamicPricingsView() {
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const { business } = useContext(AppContext);
  const [pricingExceptions, setPricingExceptions] = useState(null);

  const { businessPricings, pricingsValidating } =
    useFetchPricingsOrganization("pricingListDialog");

  const [pricingExceptionLoading, setPricingExceptionLoading] = useState(false);
  const loading = pricingsValidating || pricingExceptionLoading;
  const [editableException, setEditableException] = useState(null);
  const fetchPricingExceptions = async () => {
    setPricingExceptionLoading(true);
    try {
      const pricingExceptions = await listAll(
        graphqlOperation(pricingExceptionByOrgBusinessId, {
          orgBusinessId: business?.id,
        }),
        "pricingExceptionByOrgBusinessId"
      );
      setPricingExceptions(pricingExceptions);
    } catch (e) {
      captureError(
        "Get pricingExceptions failed",
        "GET_PRICINGEXCEPTIONS_FAILED",
        e
      );
    }
    setPricingExceptionLoading(false);
  };

  useEffect(() => {
    fetchPricingExceptions();
  }, []);
  const pricingRows = useMemo(() => {
    const rows = [];
    if (businessPricings) {
      if (pricingExceptions) {
        pricingExceptions.forEach((pricing, idx) => {
          if (!pricing?.orgExternalBusinessId) {
            rows.push({
              id: pricing?.id,
              name: pricing?.name,
              exceptionType: pricing.type === "WEEK" ? "Viikko" : "Kuukausi",
              editPricing: pricing,
              validity:
                pricing.type === "WEEK"
                  ? `${pricing.startDate} - ${pricing.endDate}`
                  : `Ennen: ${pricing.daysBeforeMonthChange} | Jälkeen: ${pricing.daysAfterMonthChange}`,
            });
          }
        });
      }
    }
    return rows;
  }, [businessPricings, pricingExceptions]);

  const pricingsColumns = useMemo(() => {
    const nameHeader = "Hinnasto";
    const nameField = {
      field: "name",
      headerName: nameHeader,
      flex: mobileViewPort ? 0.2 : 0.5,
      minWidth: mobileViewPort ? 100 : 175,
    };

    const pricingExceptionType = {
      field: "exceptionType",
      headerName: "Tyyppi",
      flex: 0.5,
      minWidth: 175,
    };

    const pricingExceptionValidity = {
      field: "validity",
      headerName: "Voimassaolo",
      flex: 0.5,
      minWidth: 175,
    };

    const pricingExceptionEdit = {
      field: "editPricing",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditableException(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    };

    return [
      nameField,
      pricingExceptionType,
      pricingExceptionValidity,
      pricingExceptionEdit,
    ];
  }, [mobileViewPort]);

  const monthExceptionExists = useMemo(
    () => pricingExceptions?.some((exception) => exception.type === "MONTH"),
    [pricingExceptions]
  );

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ padding: "10px" }}>
        {/* Hide dynamic pricings from organization users until further notice */}

        <Stack
          direction={mobileViewPort ? "column" : "row"}
          alignItems={mobileViewPort ? "flex-start" : "center"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Typography
            variant="h5"
            align="left"
            sx={{ fontFamily: "Sofia Pro" }}
          >
            Dynaaminen hinnoittelu
          </Typography>

          <Box sx={{ marginLeft: mobileViewPort ? "45px" : undefined }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                width: "200px",
              }}
              onClick={() => setEditableException({})}
            >
              Lisää dynaaminen hinta
            </Button>
          </Box>
        </Stack>
      </Stack>

      <Box sx={{ maxHeight: "500px", overflow: "hidden", padding: "10px" }}>
        <DataGridPro
          loading={loading}
          rows={pricingRows}
          columns={pricingsColumns}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            overflow: "hidden",
            height: "500px",
            /*         fontFamily: "Public Sans, sans-serif",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          disableRowSelectionOnClick
          disableSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: "name", sort: "asc" }],
            },
          }}
        />
      </Box>
      {editableException && (
        <DynaminPricingEditDialog
          fetchPricingExceptions={fetchPricingExceptions}
          pricingException={editableException}
          monthExceptionExists={monthExceptionExists}
          onClose={() => {
            setEditableException(null);
          }}
        />
      )}
    </>
  );
}

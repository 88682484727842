import { TableCell } from "@mui/material";

export default function VATRate(props) {
  const { pricing } = props;
  if (pricing) {
    return (
      <TableCell align="right" width={"100px"}>
        {pricing.tax ?? 24}%
      </TableCell>
    );
  } else {
    return <TableCell />;
  }
}

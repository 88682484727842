import styles from "./styles.module.scss";
import { differenceInHours } from "date-fns";
import { useBackgroundColor } from "./hooks/useBackgroundColor";
import { useTheme } from "@mui/material";
import { useBorderColor } from "./hooks/useBorderColor";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef,
  useContext,
} from "react";
// @ts-ignore
import { Reservation as ReservationType } from "~/graphql/API";
import { RowContext } from "../VehicleRow/RowContext";
import { clsx } from "~/utils/clsx";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

type ReservationCardProps = DetailedHTMLProps<
  HTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  reservation: ReservationType;
  direction: "horizontal" | "vertical";
  hourOffset?: number;
};

const ReservationCard = forwardRef<HTMLButtonElement, ReservationCardProps>(
  function ReservationCard(
    {
      reservation,
      direction,
      hourOffset = 0,
      children,
      className,
      style,
      ...props
    },
    ref
  ) {
    const isHorizontal = direction === "horizontal";
    const setEditReservationData = useSetAtom(reservationDataAtom);
    const { vehicle } = useContext(RowContext);
    const theme = useTheme();

    const startTime = reservation.startTime as unknown as Date;
    const duration =
      differenceInHours(reservation.endTime as unknown as Date, startTime) -
      hourOffset;
    let usageDuration =
      differenceInHours(reservation.returnTime as unknown as Date, startTime) -
      hourOffset;
    usageDuration = (100 * usageDuration) / duration;

    const borderColor = useBorderColor(reservation, vehicle);
    const backgroundColor = useBackgroundColor(reservation);

    return (
      <button
        {...props}
        ref={ref}
        type="button"
        data-reservation={reservation.id}
        className={clsx("ReservationCard", styles.reservation, className)}
        style={{
          ...style,
          ...(isHorizontal
            ? { width: `${duration}00%` }
            : { width: "100%", height: "100%" }),
          background: borderColor,
          "--backgroundHoverColor": theme.palette.primary.main,
          "--backgroundColor": backgroundColor,
          "--usageDuration": usageDuration,
        }}
        onClick={() => {
          setEditReservationData(reservation);
        }}
      >
        <div>
          <div
            className={clsx("ReservationCard-usage", styles.usage)}
            style={
              isHorizontal
                ? {
                    width: `${usageDuration}%`,
                  }
                : {
                    width: `100%`,
                    height: `${usageDuration}%`,
                  }
            }
          />

          {children}
        </div>
      </button>
    );
  }
);

export default ReservationCard;

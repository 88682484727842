//@ts-ignore
import { ADDITIONAL_FIELDS_ALL } from "../../../shared/additional-fields";
//@ts-ignore
import { centsToLocalString } from "../../../shared/money";
import { EOrderVehicle } from "./LanguagePack/translatedFields";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { ContractField } from "./common";
import { ReservationVehicle, Vehicle } from "~/graphql/API";
//@ts-ignore
import { useFetchVehicles } from "~/services/fetchVehicles";
import { settingsPreviewVehicleData } from "./settings-preview-vehicle-data";

export default function ContractProduct({
  item,
  selectedLanguage,
  contractSettings,
}: {
  item: ReservationVehicle;
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const { vehicleData } = useFetchVehicles();
  const reservation = useAtomValue(reservationDataAtom);
  //@ts-ignore
  const additionalServices = reservation?.additionalServices;
  //@ts-ignore
  const fields = EOrderVehicle[selectedLanguage];
  //@ts-ignore
  const as = reservation.additionalServices;

  //@ts-ignore
  const enabledServices = reservation?.additionalServices?.map(
    (reservationService: any) => {
      const service = additionalServices?.find(
        (as: any) => as.id === reservationService.key
      );
      return {
        ...reservationService,
        ...service,
      };
    }
  );
  const itemData = getProductData(item, vehicleData);
  const deductible = getDeductible(as, item, itemData);

  const includedDistance =
    enabledServices.findIndex((as: any) => as.category === "distance") > -1
      ? enabledServices?.find((as: any) => as.category === "distance")
          .description
      : itemData?.includedDistance ?? "";

  const exportLicense = enabledServices?.find(
    (as: any) => as.key === "exportLicense" || as.type === "EXPORT_LICENSE"
  );
  const exportCountry =
    exportLicense?.details || fields.EXPORT_LICENSE_NO_DETAILS;

  const exportLabel = exportLicense
    ? fields.EXPORT_LICENSE_CLEARANCE + exportCountry
    : fields.EXPORT_LICENSE_NO_CLEARANCE;
  //@ts-ignore
  const powerType = getPowerType(itemData?.powerType, reservation?.language);

  // if registration plate is hidden, add "or equivalent" to the vehicle name
  const nameField = getProductName(item, contractSettings, fields);

  // @ts-ignore
  const insuranceData = reservation?.insuranceData?.providerCompany
    ? // @ts-ignore
      `Vakuutusyhtiö: ${reservation?.insuranceData?.providerCompany}, Laskutuslupa: ${reservation?.insuranceData?.billingPermitCode}, asiakkaan rekisterinumero: ${reservation?.customerCarRegistrationPlate} `
    : "";

  return (
    <Stack flex={1}>
      <Stack
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        <Stack direction={"row"}>
          <ContractField
            sx={{ maxWidth: "100px" }}
            label={fields.REGISTRATION_NUMBER}
            value={itemData?.registrationPlate}
            visibility={!!contractSettings.showRegistrationPlateField}
          />
          <ContractField
            label={fields.NAME}
            value={nameField}
            visibility={true}
          />
        </Stack>
        <Stack direction={"row"}>
          <ContractField
            label={fields.MILEAGE_ON_DELIVERY}
            value={itemData?.startMileage}
            visibility={!!contractSettings.showVehicleMileage}
          />
          <ContractField
            label={fields.MILEAGE_ON_RETURN}
            value={itemData?.endMileage}
          />
          <ContractField
            label={fields.MILEAGE_DRIVEN}
            value={calcMileage(itemData?.startMileage, itemData?.endMileage)}
            visibility={!!contractSettings.showVehicleMileage}
          />

          <ContractField
            label={fields.MILEAGE_INCLUDED}
            value={includedDistance}
            visibility={!!contractSettings.showVehicleMileage}
          />
        </Stack>

        <Stack direction={"row"}>
          <ContractField
            label={fields.POWERTYPE}
            value={powerType}
            visibility={!!contractSettings.showVehicleFuelFields}
          />
          <ContractField
            label={fields.FUEL_ON_DELIVERY}
            value={""}
            visibility={!!contractSettings.showVehicleFuelFields}
          />
          <ContractField
            label={fields.FUEL_ON_RETURN}
            value={""}
            visibility={!!contractSettings.showVehicleFuelFields}
          />
        </Stack>

        <Stack direction={"row"}>
          <ContractField
            label={`${fields.COLLISION_DAMAGE_WAIVER} ${fields.DEDUCTIBLE}`}
            value={deductible}
            visibility={!!contractSettings.showDeductibleField}
          />
          <ContractField
            label={`${fields.THEFT_PROTECTION} ${fields.DEDUCTIBLE}`}
            value={deductible}
            visibility={!!contractSettings.showDeductibleField}
          />
          <ContractField
            label={fields.EXPORT_LICENSE_LABEL}
            value={exportLabel}
            visibility={!!contractSettings.showExportField}
          />
        </Stack>
        <ContractField
          label={fields.EXTRAINFO}
          value={`${itemData?.extraInfo ?? ""} ${insuranceData}`}
          visibility={!!contractSettings.showVehicleExtraInfo}
        />
      </Stack>
    </Stack>
  );
}

const getDeductible = (
  as: any,
  item: ReservationVehicle,
  itemData: Vehicle
) => {
  // New way of setting deducible service, value found on the service itself
  const deductibleFromService = as?.find((s: any) => s.deductibleValue);
  if (deductibleFromService)
    return centsToLocalString(deductibleFromService.deductibleValue);

  // Old way of setting deductible services
  const noDeductible = as?.find((s: any) => s.key === "noDeductible")
    ? true
    : false;
  const halvedDeductible = as?.find((s: any) => s.key === "halvedDeductible")
    ? true
    : false;
  const deductibleFromReservation = item?.deductible;

  const deductibleFromVehicle = itemData?.deductible;

  let deductible = null;
  if (deductibleFromReservation === 0 || deductibleFromReservation) {
    deductible = deductibleFromReservation;
  }
  if (
    deductibleFromReservation === null ||
    deductibleFromReservation === undefined
  ) {
    if (deductibleFromVehicle === 0 || deductibleFromVehicle) {
      deductible = deductibleFromVehicle;
    }
  }

  if (noDeductible || deductible === null || deductible === undefined) {
    //@ts-ignore
    deductible = "";
  } else if (halvedDeductible) {
    //@ts-ignore
    deductible = centsToLocalString(deductible / 2, "fi-FI");
  } else {
    deductible = centsToLocalString(deductible, "fi-FI");
  }
  return deductible;
};

function calcMileage(start: any, end: any) {
  if (start && end) {
    return end - start;
  }
  return null;
}

const getProductData = (item: any, vehicleData: any) => {
  if (!item) return null;
  if (item?.id === "settings-preview-reservation-vehicle")
    return settingsPreviewVehicleData;
  return vehicleData?.find((v: any) => v.id === item?.id);
};

const getProductName = (item: any, contractSettings: any, fields: any) => {
  if (!item) return null;

  if (item?.id === "settings-preview-reservation-vehicle") {
    return settingsPreviewVehicleData?.name;
  }

  if (contractSettings.contractProductTitle === "vehicle") {
    return item?.name;
  }
  if (contractSettings?.contractProductTitle === "exampleModel") {
    return `${item?.name} ${fields.VEHICLE_OR_EQUIVALENT_LABEL}`;
  }

  /* 
   contractSettings.contractProductTitle === "vehicle"
      ? itemData?.name
      : itemData?.name
      ? `${itemData?.name} ${fields.VEHICLE_OR_EQUIVALENT_LABEL}`
      : "Tuote nimi - tai vastaava";
  
  */
};

const getPowerType = (powerType: string, selectedLanguage: string) => {
  const translatedPowerType = ADDITIONAL_FIELDS_ALL?.find(
    (field: any) => field.key === "powerType"
  )?.optionsT?.[selectedLanguage]?.[powerType];
  return translatedPowerType;
};

import { Grid, TextField } from "@mui/material";

type FormikInputTextArrayProps = any;

function FormikInputTextArray({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  index,
  arrayName,
  attributeName,
  hidden,
  readOnly,
  ...props
}: FormikInputTextArrayProps) {
  if (hidden) return null;

  return (
    <Grid item xs={xs} md={md}>
      <TextField
        {...field}
        variant="outlined"
        error={
          form?.touched?.[arrayName]?.[index]?.[attributeName] &&
          !!form?.errors?.[arrayName]?.[index]?.[attributeName]
        }
        helperText={
          form?.touched?.[arrayName]?.[index]?.[attributeName] &&
          form?.errors?.[arrayName]?.[index]?.[attributeName]
        }
        disabled={readOnly}
        size="small"
        fullWidth
        {...props}
      />
    </Grid>
  );
}

export default FormikInputTextArray;

import { useField } from "formik";
import { translatedFields } from "../../../formik/fields";
import {
  addHours,
  getHours,
  isBefore,
  isSameDay,
  isValid,
  setHours,
} from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useContext } from "react";
import { ReservationContext } from "../../../contextProviders/ReservationContext";

export default function ReservationDatePicker(props) {
  const { fieldName, cutoffDate, disabled } = props;
  const { selectedLanguage } = useContext(ReservationContext);
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [, meta, helpers] = useField(fieldName);
  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;
  const label = translatedFields[selectedLanguage][fieldName];

  async function handleChange(date) {
    if (fieldName === "startTime") {
      return handleChangeStartTime(date);
    }
    if (fieldName === "returnTime") {
      return handleChangeReturnTime(date);
    }
  }

  async function handleChangeStartTime(date) {
    if (cutoffDate && !meta?.value) {
      if (isValid(date)) {
        if (isBefore(date, cutoffDate)) {
          return setValue(addHours(cutoffDate, 2));
        }
      } else {
        return setValue(date);
      }
    }
    return setValue(date);
  }

  async function handleChangeReturnTime(date) {
    if (cutoffDate && !meta?.value) {
      if (isValid(date) && isSameDay(date, cutoffDate)) {
        return setValue(addHours(cutoffDate, 2));
      } else {
        return setValue(date);
      }
    }
    return setValue(date);
  }

  const handleShouldDisableDate = (day) => {
    if (!cutoffDate) {
      return false;
    } else {
      return isBefore(day, cutoffDate) && !isSameDay(day, cutoffDate);
    }
  };

  const handleShouldDisableTime = (time) => {
    if (cutoffDate) {
      const addBuffer = addHours(cutoffDate, 1); // add 1 hour minimum reservation duration
      return time.getTime() <= addBuffer.getTime();
    }
  };
  return (
    <DateTimePicker
      ampm={false}
      label={label}
      displayWeekNumber
      disabled={disabled}
      value={value}
      slots={{
        toolbar: PickerToolbar,
        actionBar: ActionList,
      }}
      slotProps={{
        textField: {
          error: touched && !!error,
          onBlur: () => setTouched(true, true),
          size: "small",
          fullWidth: mobileViewPort ? true : false,
        },
      }}
      onChange={(date) => handleChange(date)}
      // thresholdToRenderTimeInASingleColumn={100}
      disablePast
      timeSteps={{ hours: 1, minutes: 15, seconds: 5 }}
      shouldDisableDate={handleShouldDisableDate}
      shouldDisableTime={handleShouldDisableTime}
    />
  );
}

function PickerToolbar(props) {
  const { className } = props;

  return (
    <Box className={className}>
      <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
    </Box>
  );
}

function ActionList(props) {
  const { onAccept, onCancel, className } = props;

  const actions = [
    { text: "Peruuta", method: onCancel },

    { text: "Valmis", method: onAccept },
  ];

  return (
    // Propagate the className such that CSS selectors can be applied

    <Box
      className={className}
      sx={{
        display: "flex",

        flexDirection: "row",

        justifyContent: "flex-end",
      }}
    >
      {actions.map(({ text, method }) => (
        <List key={text} sx={{ margin: "5px", marginBottom: "10px" }}>
          <ListItemButton
            onClick={method}
            sx={{
              paddingY: "5px",

              borderRadius: "7px",
            }}
          >
            <ListItemText primary={text} />
          </ListItemButton>
        </List>
      ))}
    </Box>
  );
}

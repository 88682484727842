import { useState } from "react";
import { useReservationContext } from "../../../ReservationContext";
import { v4 as uuidv4 } from "uuid";
import { Button, Stack, TextField } from "@mui/material";
import { useAddNewService } from "../../../../ReservationDialogV2/hooks/useAddNewService";

export default function AddCustomService(props) {
  const { category } = props;
  const [newAdditionalServiceName, setNewAdditionalServiceName] = useState("");
  const { addNewServiceOffer } = useReservationContext();
  const createNewAdditionalService = () => {
    const id = uuidv4();

    const newService = {
      key: `custom-service-${id}`,
      description: newAdditionalServiceName,
      offerPrice: "0",
      category: category,
      quantity: 1,
    };
    setNewAdditionalServiceName("");
    addNewServiceOffer(newService);
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <TextField
        label="Lisää kertakäyttöpalvelu"
        value={newAdditionalServiceName}
        onChange={(e) => setNewAdditionalServiceName(e.target.value)}
      />
      <Button
        onClick={createNewAdditionalService}
        disabled={newAdditionalServiceName === ""}
      >
        Tallenna
      </Button>
    </Stack>
  );
}

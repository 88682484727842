import DialogHeader from "../DialogHeader";
import DialogFooter from "../DialogFooter";

export default function DialogContent({
  children,
  selectedTab,
  setOpenDrawer,
  setSelectedTab,
  setShowAllContent,
  showAllContent,
}) {
  return (
    <>
      <DialogHeader
        selectedTab={selectedTab}
        setOpenDrawer={setOpenDrawer}
        setSelectedTab={setSelectedTab}
        setShowAllContent={setShowAllContent}
        showAllContent={showAllContent}
      />
      {children}
      <DialogFooter selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </>
  );
}

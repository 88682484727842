import React, { useContext } from "react";
import { Stack, FormControlLabel, Checkbox, Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useField } from "formik";
import { AppContext } from "../../../../../../../../AppContext";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { nextFullHour } from "../../../../../../../Reservation/common/utils";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function SendStatusToCustomer() {
  const { USER_ADMIN_GENERIC } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const [, meta, helpers] = useField("sendStatusToCustomer");
  const { setValue, handleBlur } = helpers;
  const { value } = meta;
  if (USER_ADMIN_GENERIC)
    if (!reservationData?.id)
      return (
        <Stack
          direction={{ sm: "column", md: "row" }}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="sendStatusToCustomer"
                checked={Boolean(value) ?? false}
                color="primary"
                onChange={(e) => setValue(e.target.checked)}
                onBlur={handleBlur}
              />
            }
            label="Lähetä asiakkaalle täydennettäväksi"
          />
          <CompleteBeforeTimePicker visibility={Boolean(value)} />
        </Stack>
      );
}

const CompleteBeforeTimePicker = (props) => {
  const { visibility } = props;
  const [, meta, helpers] = useField("completeBefore");
  const { setValue, handleBlur } = helpers;
  const { value } = meta;
  const dateValue = value ?? nextFullHour(new Date());
  if (visibility)
    return (
      <Box>
        <Tooltip title="Asiakkaalle lähetetään linkki, jonka kautta varaus voidaan tehdä loppuun. Mikäli varausta ei suoriteta loppuun ennen määriteltyä aikaa, se poistetaan. ">
          <InfoIcon color="info" sx={{ cursor: "pointer" }} />
        </Tooltip>
        <DateTimePicker
          inputFormat="dd.MM.yyyy HH:mm"
          ampm={false}
          disablePast
          label="Vanhenemisaika"
          value={dateValue}
          handleBlur={handleBlur}
          onChange={async (date) => {
            await setValue(date);
          }}
        />
      </Box>
    );
};

import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { searchCustomers } from "../../../graphql/queries";

export function useSearchCustomerCompanyByExternalUser() {
  const { user } = useContext(AppContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const customers = await fetchCustomerData(user);
      setOptions(customers);
    };
    if (user) {
      fetchOptions();
    }
  }, [user]);

  return options;
}

const fetchCustomerData = async (user) => {
  const userFilters = [
    {
      or: [
        {
          and: [
            { isDeleted: { ne: true } },
            {
              clientType: { eq: "COMPANY" }, // Specify client types when necessary
            },
            { organizationId: { eq: user?.organizationId } },
            { orgExternalBusinessId: { eq: user?.externalBusinessId } },
          ],
        },
      ],
    },
  ];

  try {
    const response = await API.graphql(
      graphqlOperation(searchCustomers, {
        filter: {
          and: [...userFilters],
        },
      })
    );
    if (response?.data?.searchCustomers?.items) {
      return response.data.searchCustomers.items;
    } else {
      return [];
    }
    // return response.data.searchCustomers.items;
  } catch (error) {
    console.error(`Error fetching external user data:`, error);
  }
};

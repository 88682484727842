import {
  Grid,
  InputAdornment,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { Euro } from "@mui/icons-material";
import { useCategories } from "~/queries/useCategories";
import { centsToLocalString } from "../../../shared/money";

export default function WildcardVehicle(props) {
  const { visibility } = props;

  const categories = useCategories();
  const { values } = useFormikContext();
  const category = categories
    ? categories.find((c) => c.id === values?.initialClassInfo?.categoryId)
    : "";
  if (visibility && values?.wildCardVehicles?.[0]?.price)
    return (
      <Grid item container>
        <Grid
          item
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
              Varaus luotu kategorialle:
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{category?.name}</Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
              Alkuperäinen tarjous:
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={5}>
            <TextField
              defaultValue={centsToLocalString(
                values?.wildCardVehicles?.[0]?.price
              )}
              disabled
              name={"nimi"}
              variant="outlined"
              //  error={touched[name] && !!errors[name]}
              //   helperText={touched[name] && errors[name]}
              InputProps={{
                endAdornment: (
                  <Euro
                    sx={{
                      color: "grey",
                      marginBottom: "5px",
                      fontSize: "1.2rem",
                    }}
                  />
                ),
              }}
              size="small"
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
}

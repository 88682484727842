import "./styles/global.css";
import AppThemeProvider from "./AppThemeProvider";
import AppRoutes from "./routes";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import { fi } from "date-fns/locale";
import setDefaultOptions from "date-fns/setDefaultOptions";
import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "./graphql/custom-queries";
import { captureError } from "./utilities";

LicenseInfo.setLicenseKey(
  "2a05a1d540293f031db1ad247bad60e5Tz0xMDEyNzMsRT0xNzYyNDIyMDM0MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);

export default function App(props) {
  setDefaultOptions({ locale: fi });
  const { signOut, cognitoUser } = props;

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async (cognitoUser) => {
      console.log("fetchUser", cognitoUser);
      try {
        if (user) {
          console.log("User is already fetched");
          return;
        }

        if (!cognitoUser.signInUserSession.idToken.payload["orgId"]) {
          console.log(
            "User does not belong to an Organization (legacy), do not fetch"
          );
          const userGroup =
            cognitoUser.signInUserSession.idToken.payload["cognito:groups"][0];
          setUser({ role: "admin", group: userGroup });
          return;
        }

        // If ExternalBusiness user also fetch information of available Categories
        const externalBusinessId =
          cognitoUser.signInUserSession.idToken.payload[
            "orgExternalBusinessAdmin"
          ];
        let pricingFilter;
        if (externalBusinessId) {
          pricingFilter = {
            orgExternalBusinessId: {
              eq: externalBusinessId,
            },
          };
        }

        console.log(`Fetch ddb user ${cognitoUser.username}`);
        // Fetch the current authenticated User from DDB
        const ddbUser = (
          await API.graphql(
            graphqlOperation(getUser, {
              id: cognitoUser.username,
              pricingFilter: pricingFilter,
              includeExtPricing: !!externalBusinessId,
            })
          )
        ).data.getUser;
        if (!ddbUser) {
          setUser({ role: "admin" });
          return;
        }
        console.log("ddbUser: ", ddbUser);
        // Retrieve the role that is stored as a Custom claim on the ID token
        const role = ddbUser.role;
        if (role) {
          setUser(ddbUser);
        } else {
          return { role: "admin" };
        }
      } catch (error) {
        console.log("Error fetching user:", error);
        captureError("fetchUser failed", "GET_USER_FAILED", error);
        // For now every old user do not have an user. Default to admin
        setUser({ role: "admin" });
      }
    };
    fetchUser(cognitoUser);
  }, [cognitoUser]);

  if (user)
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={fi}
        localeText={{ start: "Noutopäivä", end: "Palautuspäivä" }}
      >
        <AppThemeProvider>
          <AppRoutes user={user} signOut={signOut} />
        </AppThemeProvider>
      </LocalizationProvider>
    );
}

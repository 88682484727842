import { Cache, Storage } from "aws-amplify";
import { useEffect, useState } from "react";
import { Vehicle } from "~/graphql/API";
import { typeOfProducts } from "~/shared/ProductClassification/type-of-products";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { addMinutes } from "date-fns";
import { useCategories } from "~/queries/useCategories";

export function useVehicleImage(vehicle: undefined | Vehicle) {
  const [image, setImage] = useState<undefined | string>(undefined);
  const category = useCategories().find(
    ({ id }) => id === vehicle?.category?.id
  );

  useEffect(() => {
    (async () => {
      if (vehicle?.image) {
        const [identityId, key] = vehicle.image.split("/");

        let url = Cache.getItem(key);

        if (!url) {
          url = await Storage.get(key, {
            identityId,
            level: "protected",
          });

          Cache.setItem(key, url, {
            expires: addMinutes(new Date(), 15).getTime(),
          });
        }

        setImage(url);
      }
    })();
  }, [vehicle?.image]);

  return {
    image,
    icon: typeOfProducts.find(({ id }) => id === vehicle?.category?.typeId)
      ?.icon || <ErrorOutlineIcon />,
    backgroundColor: category?.backgroundColor,
  };
}

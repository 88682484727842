import { useTheme } from "@emotion/react";
import { MonetizationOn } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FaFileSignature } from "react-icons/fa";

export function OnlinePaymentCompletedIcon() {
  const theme = useTheme();
  return (
    <Tooltip enterTouchDelay={50} title="Maksu suoritettu (Visma Pay)">
      <IconButton>
        <MonetizationOn
          style={{
            color: theme.palette.success.light,
            fontSize: "24px",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}

export function AuthStatusCompletedIcon() {
  const theme = useTheme();

  return (
    <Tooltip enterTouchDelay={50} title="Telia vahvasti tunnistaunut">
      <IconButton>
        <FaFileSignature
          style={{
            color: theme.palette.success.light,
            fontSize: "24px",
            marginLeft: "3px",
          }}
        />
      </IconButton>
    </Tooltip>
  );
}

import React from "react";
import {
  TableCell,
  TableRow,
  Typography,
  Grid,
  Skeleton,
  useTheme,
} from "@mui/material";

import { centsToLocalString } from "../../shared/money";
import { useVehicleImage } from "~/hooks/useVehicleImage";

import { Box } from "@mui/system";

export default function EconomyDialogProduct({ vehicle, loading }) {
  const theme = useTheme();
  const { image } = useVehicleImage(vehicle);

  return (
    <TableRow sx={{ bgcolor: theme.palette.primary.containerBg }}>
      <TableCell
        padding="none"
        sx={{ padding: 0, margin: 0, width: "15%" }}
        align="left"
      >
        <div
          style={{
            border: "solid 1px lightgrey",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 14,
            backgroundColor: "white",
            padding: 5,
            maxWidth: 80,
            minHeight: 75,
            minWidth: 75,
          }}
        >
          {!loading && image && (
            <img
              alt=""
              src={image}
              style={{
                display: "block",
                objectFit: "scale-down",
                heigth: 75,
                width: 75,
                minHeight: 74,
                backgroundColor: "transparent",
              }}
            />
          )}
        </div>
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0 }} size="small">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!vehicle.id && <CellSkeleton justify="flex-start" />}
          {!vehicle.id && <CellSkeleton justify="flex-start" />}
          {vehicle.id && (
            <>
              <Typography variant="body1">{vehicle?.name}</Typography>

              <Typography variant="subtitle1">
                {vehicle?.registrationPlate}
              </Typography>
            </>
          )}
        </div>
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0 }} size="small" align="center">
        {loading && <CellSkeleton justify="center" />}
        {!loading && (
          <Typography variant="body1">
            {vehicle.quantity > 0 && vehicle.quantity}
          </Typography>
        )}
      </TableCell>
      <TableCell sx={{ margin: 0, padding: 0 }} size="small" align="right">
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {loading && <CellSkeleton justify="flex-end" />}
          {!loading && (
            <Grid item>
              <Typography
                variant="body2"
                style={{
                  fontWeight: "bold",
                }}
              >
                {centsToLocalString(vehicle.total) + " €"}
              </Typography>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <TableCell
        sx={{ margin: 0, padding: 0, paddingRight: "15px" }}
        size="small"
        align="right"
      >
        {loading && <CellSkeleton justify="flex-end" />}
        {!loading && (
          <Typography variant="body2">{vehicle?.utilizationRate} %</Typography>
        )}
      </TableCell>
    </TableRow>
  );
}

function CellSkeleton({ justify }) {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: justify }}>
      <Skeleton variant="text" width={"50%"} sx={{ fontSize: "1rem" }} />
    </Box>
  );
}

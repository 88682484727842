import { useDebouncedEffect } from "../../../utils/use-debounced-effect";
import React, { useState, useContext } from "react";
import { Box, TextField, Typography, Autocomplete } from "@mui/material";
import { AppContext } from "../../../AppContext";
import { useFormikContext } from "formik";

export default function SearchByPostalCode(props) {
  const { user } = useContext(AppContext);
  const { setFieldValue, touched, errors } = useFormikContext();
  const [searchValue, setSearchValue] = useState("");
  const [businessByPostalCode, setBusinessByPostalCode] = useState([]);

  const getAutofillValues = async (str) => {
    let result;
    if (str) {
      // search by business name
      if (isNaN(str)) {
        result = user?.businessByOrg?.items.filter((business) => {
          if (!business?.insuranceCoverageZones) return null;
          if (business.name.toLowerCase().includes(str.toLowerCase())) {
            return business;
          }
          return null;
        });
      }
      if (!isNaN(str)) {
        result = user?.businessByOrg?.items.filter((business) => {
          const searchByInt = parseInt(str);
          const zones = business?.insuranceCoverageZones;
          if (!zones) return null;
          for (let zone of zones) {
            if (zone?.start.startsWith(str) || zone?.end.startsWith(str)) {
              return business;
            }
            if (
              searchByInt >= parseInt(zone?.start) &&
              searchByInt <= parseInt(zone?.end)
            ) {
              return business;
            }
          }
          return null;
        });
      }
      if (result.length === 0) {
        setBusinessByPostalCode([]);
        setFieldValue("orgBusinessId", null);
      } else {
        setBusinessByPostalCode(result);
        setFieldValue("orgBusinessId", result.id);
      }
    } else {
      setBusinessByPostalCode([]);
      setFieldValue("orgBusinessId", null);
    }
  };

  useDebouncedEffect(
    () => {
      getAutofillValues(searchValue);
    },
    50,
    [searchValue]
  );

  return (
    <>
      <Autocomplete
        freeSolo
        disableClearable
        fullWidth
        inputValue={searchValue}
        options={businessByPostalCode}
        filterOptions={(options) => options}
        //  loading={nameAutoCompleteLoading}
        loadingText="Ladataan..."
        getOptionLabel={(option) => option?.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            error={touched.orgBusinessId && !!errors.orgBusinessId}
            helperText={touched.orgBusinessId && errors.orgBusinessId}
            autoComplete="none"
            label="Postinumero haku"
            onChange={(e) => setSearchValue(e.target.value)}
            variant="outlined"
            data-cy="reservationDialogPostalSearchField"
          />
        )}
        renderOption={(s, o) => {
          return (
            <Box
              {...s}
              key={o?.id}
              onClick={() => setFieldValue("orgBusinessId", o?.id)}
              sx={{
                margin: "2px",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <ContactInfo
                  title={o?.name}
                  visibility={o?.name}
                  variant="body2"
                />
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <ContactInfo
                    title={o?.city}
                    visibility={o?.city}
                    variant="caption"
                  />
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    </>
  );
}

function ContactInfo(props) {
  const { title, variant, visibility } = props;
  if (visibility) return <Typography variant={variant}>{title}</Typography>;
}

import "./EconomyDialog.css";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  useTheme,
  Box,
} from "@mui/material";

import EconomyDialogProduct from "./EconomyDialogProduct";
import CategoryMenu from "./ListCategoriesMenu";

export default function ProductsTable({
  categories,
  changeSelectedCategory,
  loading,
  reservedVehicles,
  selectedCategory,
  showAllCompanies,
  weeklyhours,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{ boxShadow: theme.shadows[1], borderRadius: "15px", padding: "5px" }}
    >
      <Box sx={{ overflow: "auto", height: "600px" }}>
        <TableContainer sx={{ overflowX: "initial" }}>
          <Table
            size="small"
            sx={{ bgcolor: theme.palette.primary.containerBg }}
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ bgcolor: "transparent" }}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontFamily: "Sofia Pro",
                          fontSize: 22,
                          color: "rgba(0, 0, 0, 0.8)",
                          padding: 5,
                        }}
                      >
                        Tuotteet
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <CategoryMenu
                    key={500}
                    categories={categories}
                    title={"Kategoriat"}
                    changeSelectedCategory={changeSelectedCategory}
                  />
                </TableCell>

                <TableCell
                  align="center"
                  style={{
                    paddingLeft: 3,
                    paddingRight: 3,
                    color: "#acb9c9",
                    fontSize: 13,
                  }}
                >
                  <Typography variant="caption" sx={{ color: "black" }}>
                    KPL
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ paddingRight: 0 }}>
                  <Typography variant="caption">MYYNTI</Typography>
                </TableCell>
                <TableCell align="right" sx={{ color: "black" }}>
                  <Typography variant="caption">KÄYTTÖ</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedCategory === "showAllCategories"
                ? reservedVehicles.map((vehicle) => (
                    <EconomyDialogProduct
                      showAllCompanies={showAllCompanies}
                      loading={loading}
                      key={vehicle.id}
                      vehicle={vehicle}
                      reservationsAmount={reservedVehicles.length}
                      weeklyhours={weeklyhours}
                    />
                  ))
                : reservedVehicles
                    .filter(
                      (vehicle) => vehicle?.category?.id === selectedCategory
                    )
                    .map((vehicle) => (
                      <EconomyDialogProduct
                        showAllCompanies={showAllCompanies}
                        loading={loading}
                        key={vehicle.id}
                        vehicle={vehicle}
                        reservationsAmount={reservedVehicles.length}
                        weeklyhours={weeklyhours}
                      />
                    ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

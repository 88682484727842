import { AutoCompleteComponent } from "../../../components/AutoCompleteComponent";
import { useFormikContext } from "formik";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../../AppContext";
import { findItemById } from "../../../utils/findItemById";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import { translatedFields } from "../../../formik/fields";

export default function ExternalUserSelectReturnLocation(props) {
  const { startLocationOrgBusinessId, serviceProvider } = props;
  const { setFieldTouched, setFieldValue, errors, values, touched } =
    useFormikContext();
  const { user } = useContext(AppContext);
  const { selectedLanguage } = useContext(ReservationContext);
  const [inputValue, setInputValue] = useState("");
  const availableStartLocations = useMemo(() => {
    let availableLocations = [];
    user?.externalBusiness?.availableCompanyIds.forEach((locationId) => {
      const locationData = findItemById(user, locationId);
      if (locationData) {
        availableLocations.push(locationData);
      }
    });

    return availableLocations;
  }, [user]);

  const availableReturnLocations = useMemo(() => {
    const availableLocations = availableStartLocations.filter(
      (location) => location?.orgBusinessId === startLocationOrgBusinessId
    );

    return availableLocations;
  }, [availableStartLocations, startLocationOrgBusinessId]);

  const handleSelectReturnLocation = async (e, newValue) => {
    await setFieldValue("returnLocation", newValue?.name);
    await setFieldValue("returnLocationID", newValue?.id);
  };

  return (
    <AutoCompleteComponent
      defaultOptions={availableReturnLocations}
      label={translatedFields[selectedLanguage]?.returnLocation}
      type={"location"}
      inputValue={inputValue}
      size={"small"}
      onInputChange={(e, newValue) => {
        setInputValue(newValue);
        setFieldValue("returnLocation", newValue);

        setFieldValue("returnLocationID", values?.startLocationID);
      }}
      value={values.returnLocation}
      setValue={async (value) => {
        await setFieldValue("returnLocation", value);

        await setFieldTouched("returnLocation", true, false);
      }} // used for input change
      onChange={handleSelectReturnLocation}
      freeSolo={true}
      disabled={!serviceProvider || !values?.startLocationID}
      error={!!errors?.returnLocation && touched.returnLocation}
      errorHelperText={touched.returnLocation && errors?.returnLocation}
      name="returnLocation"
      onBlur={() => {
        setFieldTouched("returnLocation", true, true);
      }}
    />
  );
}

import { useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { BsCheckCircleFill, BsCreditCard } from "react-icons/bs";
import { FaMoneyBill1Wave, FaStar } from "react-icons/fa6";
import { IoReceiptOutline } from "react-icons/io5";
import { TbLetterH } from "react-icons/tb";
import { AppContext } from "../../../../AppContext";
import { Filter } from "./Filter";
import { centsToLocalString } from "../../../../shared/money";
import { calculateReservationTotalPrice, filteringOptions } from "../utils";

export const FilterOptions = (props) => {
  const { reservationsByCompany, visibility, activeFilter, setActiveFilter } =
    props;

  const theme = useTheme();
  const { user } = useContext(AppContext);

  const options = useMemo(() => {
    const defaultOptions = [
      {
        id: "all-results",
        name: "Kaikki",
        icon: <BsCheckCircleFill />,
        iconColor: theme.palette.info.light,
        value: calculateReservationsTotal(reservationsByCompany, "all-results"),
        amount: reservationsByCompany?.length,
      },
      {
        id: "resultsByCash",
        name: "Käteis- ja korttimaksut",
        icon: <FaMoneyBill1Wave />,
        iconColor: theme.palette.success.main,
        value: calculateReservationsTotal(
          reservationsByCompany.filter((reservation) =>
            filteringOptions["resultsByCash"](reservation)
          )
        ),
        amount: reservationsByCompany.filter((reservation) =>
          filteringOptions["resultsByCash"](reservation)
        )?.length,
      },
      {
        id: "resultsByOnline",
        name: "Verkkomaksut",
        icon: <BsCreditCard />,
        iconColor: theme.palette.info.dark,
        value: calculateReservationsTotal(
          reservationsByCompany.filter((reservation) =>
            filteringOptions["resultsByOnline"](reservation)
          )
        ),
        amount: reservationsByCompany.filter((reservation) =>
          filteringOptions["resultsByOnline"](reservation)
        )?.length,
      },
      {
        id: "resultsByInvoice",
        name: "Laskutus",
        icon: <IoReceiptOutline />,
        iconColor: theme.palette.warning.dark,
        value: calculateReservationsTotal(
          reservationsByCompany.filter((reservation) =>
            filteringOptions["resultsByInvoice"](reservation)
          )
        ),
        amount: reservationsByCompany.filter((reservation) =>
          filteringOptions["resultsByInvoice"](reservation)
        )?.length,
      },
    ];

    return defaultOptions;
  }, [
    reservationsByCompany,
    theme.palette.info.light,
    theme.palette.success.main,
    theme.palette.info.dark,
    theme.palette.warning.dark,
  ]);

  if (visibility)
    return options.map((option) => (
      <Filter
        key={option.id}
        title={option.name}
        icon={option.icon}
        iconColor={option.iconColor}
        value={option.value}
        amount={option.amount}
        filterId={option.id}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
    ));
};

const calculateReservationsTotal = (reservations) => {
  if (!reservations || reservations?.length === 0) return "-";

  const totalPrice = reservations.reduce((total, reservation) => {
    return total + calculateReservationTotalPrice(reservation);
  }, 0);
  return centsToLocalString(totalPrice);
};

import { BiMinus, BiPlus } from "react-icons/bi";
import { Divider, IconButton, Stack, TextField } from "@mui/material";
import { centsToLocalString, parseToCents } from "../../../shared/money";
import { useField } from "formik";
import { useState } from "react";
import { Cache } from "aws-amplify";

// Price of distance and deductible services is by default multiplied by the amount of vehicles
export const getPriceMultiplier = (category, values) => {
  if (["deductible", "distance"].includes(category)) {
    return values?.vehicleIds.length;
  }
  return 1;
};

export const nextFullHour = (date) => {
  const hour = 3600000;
  return new Date((Math.floor(date.getTime() / hour) + 1) * hour);
};

export const storeValuesToCache = async (item, value) => {
  try {
    await Cache.setItem(item, value);
  } catch (error) {
    console.error("Error storing data to cache:", error);
  }
};

export const getTotalPrice = ({ additionalServices, reservationVehicles }) => {
  const additionalServicesTotalPrice = additionalServices.reduce(
    (accumulator, currentProduct) => {
      // price = user modified price, listingPrice = default offer price
      const price =
        parseToCents(currentProduct?.price) ?? currentProduct?.offerPrice;
      const quantity = currentProduct?.quantity ?? 1;
      if (typeof price === "number") {
        return accumulator + price * quantity;
      } else {
        return accumulator + 0;
      }
    },
    0
  );

  /* HANDLE REGULAR TOTAL PRICE */

  const vehiclePrices = Object.values(reservationVehicles).reduce(
    (accumulator, product) => {
      const price = product?.price;
      return accumulator + price;
    },
    0
  );

  return centsToLocalString(vehiclePrices + additionalServicesTotalPrice);
};

export function ServiceQuantitySelector(props) {
  const { service } = props;
  const [inputValue, setInputValue] = useState(service?.quantity ?? 1);

  const size = "30px";
  const [field, , helpers] = useField("additionalServices");

  const maxQuantity = service?.maxQuantity ?? Infinity;
  const quantity = parseInt(service?.quantity);
  const handleChangeQuantity = (value) => {
    const modifyQuantity =
      quantity + value >= 1 && quantity + value <= maxQuantity;
    const removeItem = quantity === 1 && value === -1;
    if (removeItem) {
      helpers.setValue([...field.value.filter((s) => s.key !== service?.key)]);
    }

    if (modifyQuantity) {
      setInputValue(parseInt(quantity) + value);
      helpers.setValue([
        ...field.value.map((s) => {
          if (s.key === service?.key) {
            return { ...s, quantity: parseInt(quantity) + value };
          }
          return s;
        }),
      ]);
    }
  };
  if (!service?.enableQuantity) return null;
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      height={"40px"}
      sx={{
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      <IconButton
        onClick={() => {
          handleChangeQuantity(-1);
        }}
        sx={{
          width: size,
          height: size,

          // backgroundColor: theme.palette.primary.decButton,
          //color: theme.palette.primary.buttonText,
          color: "#494848",
          backgroundColor: "transparent",
          borderRadius: 0,
        }}
      >
        <BiMinus style={{ fontSize: "14px" }} />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <TextField
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: `${inputValue.toString().length * 20}px`,
          maxWidth: "100px",
          minWidth: "50px",
          height: "30px",
          fontWeight: "bolder",
          // backgroundColor: theme.palette.primary.qtyBackground,
          // color: theme.palette.primary.mainText,
          color: "#494848",
        }}
        onBlur={(e) => {
          if (e.target.value === "") {
            setInputValue(1);
            return helpers.setValue([
              ...field.value.map((s) => {
                if (s.key === service?.key) {
                  return { ...s, quantity: 1 };
                }
                return s;
              }),
            ]);
          } else {
            helpers.setValue([
              ...field.value.map((s) => {
                if (s.key === service?.key) {
                  return { ...s, quantity: inputValue };
                }
                return s;
              }),
            ]);
          }
        }}
        onChange={(e) => {
          if (e.target.value > maxQuantity) {
            // setInputValue(maxQuantity);
            return null;
          } else {
            setInputValue(e.target.value);
          }
        }}
        inputProps={{ style: { textAlign: "center" } }}
        value={inputValue}
      ></TextField>
      <Divider orientation="vertical" flexItem />

      <IconButton
        // disabled={alreadySelectedDeductible}
        sx={{
          width: size,
          height: size,
          borderRadius: 0,
          //  backgroundColor: theme.palette.primary.addButton,
          //  color: theme.palette.primary.buttonText,
          backgroundColor: "transparent",
          color: "#494848",
        }}
        onClick={() => {
          // maxQuantity is enabled
          handleChangeQuantity(+1);
        }}
      >
        <BiPlus style={{ fontSize: "30px" }} />
      </IconButton>
    </Stack>
  );
}

export const getReservationDuration = (startTime, returnTime) => {
  const start = new Date(startTime);
  const end = new Date(returnTime);
  const diff = Math.abs(end - start);
  return Math.ceil(diff / (1000 * 60 * 60 * 24));
};

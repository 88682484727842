import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";

import { searchCustomers as searchCustomersQuery } from "../../../graphql/custom-queries.js";
import { useContext, useMemo } from "react";
import { getZonedTime } from "../../../utils/common.jsx";
import captureError from "../../../utils/capture-error.js";

import { listAll } from "../../../utilities.js";
import { AppContext } from "../../../AppContext.jsx";

// Uses the Lambda function and byGroupByEndTime GSI
const search = async (searchInput, group, business, user) => {
  if (!searchInput) {
    return [];
  } // group is mandatory for the fetch

  try {
    const filter = {
      and: [
        {
          or: [
            { shortId: { matchPhrasePrefix: searchInput } },
            { billingRef: { matchPhrasePrefix: searchInput } },
            { companyBillingRef: { matchPhrasePrefix: searchInput } },
            { companyName: { matchPhrasePrefix: searchInput } },
            { email: { matchPhrasePrefix: searchInput } },
            { name: { matchPhrasePrefix: searchInput } },
            { phone: { matchPhrasePrefix: searchInput } },
            {
              customerCarRegistrationPlate: {
                matchPhrasePrefix: searchInput,
              },
            },
          ],
        },
      ],
    };
    if (user?.role === "ORGANIZATION_ADMIN") {
      filter.and.push({ organizationId: { eq: user.organizationId } });
    }
    if (group) {
      filter.and.push({ group: { eq: group } });
    }
    if (business) {
      filter.and.push({ orgBusinessId: { eq: business.id } });
    }
    if (user?.externalBusinessId) {
      filter.and.push({
        orgExternalBusinessId: { eq: user.externalBusinessId },
      });
    }

    const response = await listAll(
      graphqlOperation(searchCustomersQuery, {
        limit: 1000,
        filter: filter,
        sort: {
          field: "createdAt",
          direction: "desc",
        },
      }),
      "searchReservations"
    );

    if (response == null) {
      return;
    }

    return response;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

export function useSearchCustomers(query) {
  const { group, business, user } = useContext(AppContext);
  let fetcher = null;

  if (query) {
    fetcher = () => search(query, group, business, user);
  }
  const timezone = "Europe/Helsinki";

  const { data, isLoading } = useSWR(!!query ? query : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  const reservationData = useMemo(() => {
    if (!data || data.length === 0) return [];
    // remove duplicate items according to email & name

    const filtered = data.reduce((unique, item) => {
      return unique.find((u) => u.name === item.name && u.email === item.email)
        ? unique
        : [...unique, item];
    }, []);

    return filtered.map((item) => ({
      ...item,
      startTime: getZonedTime(item?.startTime, timezone),
      returnTime: getZonedTime(item?.returnTime, timezone),
      endTime: getZonedTime(item?.endTime, timezone),
    }));
  }, [data, timezone]);

  return {
    listOfCustomers: reservationData,
    listOfCustomersLoading: isLoading,
  };
}

import { TextField, InputAdornment, Typography, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";

export default function ReservationDeposit() {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();
  return (
    <Grid container spacing={1} direction={"row"} justifyContent={"center"}>
      {/*       <FormGrid>
        <Typography
          align="center"
          sx={{ fontFamily: "Sofia Pro", fontSize: "20px" }}
        >
          Varausmaksun hallinta
        </Typography>
        <Divider sx={{ marginY: "10px" }} flexItem />
      </FormGrid> */}

      <FormGrid
        container
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ marginRight: "15px" }}>Varausmaksun määrä</Typography>
        <TextField
          sx={{ width: "150px" }}
          value={values.reservationDeposit.amount}
          onChange={handleChange}
          onBlur={handleBlur}
          name={"reservationDeposit.amount"}
          variant="outlined"
          size="small"
          error={
            touched.reservationDeposit?.amount &&
            !!errors.reservationDeposit?.amount
          }
          helperText={
            touched.reservationDeposit?.amount &&
            errors.reservationDeposit?.amount
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </FormGrid>
      <FormGrid
        container
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ marginRight: "15px" }}>
          Maksumuistutus päivää ennen varausta
        </Typography>
        <TextField
          sx={{ width: "150px" }}
          value={values.reservationDeposit.reminderBefore}
          onChange={handleChange}
          onBlur={handleBlur}
          name={"reservationDeposit.reminderBefore"}
          variant="outlined"
          size="small"
          error={!!errors.reservationDeposit?.reminderBefore}
          type="number"
          helperText={errors.reservationDeposit?.reminderBefore}
        />
      </FormGrid>
      <FormGrid
        container
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ marginRight: "15px" }}>
          Maksu päivää ennen varausta
        </Typography>
        <TextField
          sx={{ width: "150px" }}
          value={values.reservationDeposit.paymentBefore}
          onChange={handleChange}
          onBlur={handleBlur}
          name="reservationDeposit.paymentBefore"
          variant="outlined"
          size="small"
          error={!!errors.reservationDeposit?.paymentBefore}
          helperText={errors.reservationDeposit?.paymentBefore}
          type="number"
        />
      </FormGrid>
    </Grid>
  );
}

import { format } from "date-fns";
import {
  Reservation as ReservationType,
  ReservationVehicle,
  WildCardVehicle,
} from "~/graphql/API";
import { ReactNode } from "react";
import ReservationCardHorizontal from "../components/ReservationCardHorizontal";

type VehicleReservations = Record<string, Record<string, ReactNode[]>>;
type CategoryReservations = Record<string, Record<string, ReactNode>[]>;

export type SortedReservations = {
  vehicle: VehicleReservations;
  category: CategoryReservations;
};

export function makeSortReservationsReducer(startDate: number) {
  return function sortReservationsReducer(
    acc: SortedReservations,
    item: ReservationType
  ) {
    const startTime = (item.startTime as unknown as Date).getTime();

    const beforeView = startTime < startDate;
    const stringDate = format(beforeView ? startDate : startTime, "yyyy-MM-dd");

    const hourOffset = beforeView
      ? Math.round((startDate - startTime) / 1000 / 60 / 60)
      : 0;

    (item.wildCardVehicles as WildCardVehicle[] | null)?.forEach(
      ({ categoryId: _categoryId }) => {
        // Fix TypeScript types
        const categoryId = _categoryId!;

        if (!acc.category[categoryId]) {
          acc.category[categoryId] = [];
        }

        acc.category[categoryId].push({
          [stringDate]: (
            <ReservationCardHorizontal
              reservation={item}
              hourOffset={hourOffset}
            />
          ),
        });
      }
    );

    (item.reservationVehicles as ReservationVehicle[] | null)?.forEach(
      (vehicle, i) => {
        // Fix TypeScript types
        const vehicleId = vehicle.id!;

        if (!acc.vehicle[vehicleId]) {
          acc.vehicle[vehicleId] = {};
        }

        if (!acc.vehicle[vehicleId][stringDate]) {
          acc.vehicle[vehicleId][stringDate] = [];
        }

        acc.vehicle[vehicleId][stringDate].push(
          <ReservationCardHorizontal
            key={startDate + item.id + vehicleId + i}
            reservation={item}
            hourOffset={hourOffset}
          />
        );
      }
    );

    return acc;
  };
}

import { IconButton } from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { ComponentProps } from "react";

type BackButtonProps = ComponentProps<typeof IconButton>;

function BackButton({ style, ...props }: BackButtonProps) {
  return (
    <IconButton
      size={"small"}
      {...props}
      style={{
        ...style,
        transform: "rotate(90deg)",
      }}
    >
      <ExpandMoreOutlinedIcon />
    </IconButton>
  );
}

export default BackButton;

import { useContext, useMemo } from "react";
import { AppContext } from "../../../AppContext";
import { IconButton, Stack, useMediaQuery } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { VAT, pricingNameField, pricingDaily, pricingHourly } from "./columns";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useTheme } from "@emotion/react";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";

export default function DefaultPricingsView(props) {
  const { setEditablePricing } = props;
  const { user, business, group, company } = useContext(AppContext);
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));

  const categoryDynamicPricing = company?.categoryDynamicPricings;
  const disableFields = null;

  const { organizationPricings, businessPricings, pricingsValidating } =
    useFetchPricingsOrganization("pricingListDialog");

  const pricings = useMemo(() => {
    if (user?.role === "ORGANIZATION_ADMIN" && !business?.id) {
      return organizationPricings;
    }
    if (business?.id) {
      return businessPricings;
    }
    return [];
  }, [organizationPricings, businessPricings, business?.id, user?.role]);

  const pricingRows = useMemo(() => {
    const rows = [];
    if (pricings) {
      pricings.forEach((pricing, idx) => {
        if (!pricing?.orgExternalBusinessId && !pricing?._removed) {
          const pricingHour = pricing.elements.find(
            (el) => el.duration === "h0"
          )?.price;
          const pricingDay = pricing.elements.find(
            (el) => el.duration === "d0"
          )?.price;

          rows.push({
            id: pricing?.id,
            name: pricing?.name,
            editPricing: pricing,
            pricingHour: pricingHour,
            pricingDay: pricingDay,
            VAT: pricing?.tax ?? 24,
            percentage: categoryDynamicPricing
              ? categoryDynamicPricing.find(
                  (discount) => discount?.pricingId === pricing?.id
                )?.percentage
              : null,
          });
        }
      });
    }

    return rows;
  }, [pricings, categoryDynamicPricing]);

  const pricingsColumns = useMemo(() => {
    const editPricing = {
      field: "editPricing",
      headerName: " ",
      width: 70,
      renderCell: (params) => {
        if (params.row?.editPricing) {
          return (
            <IconButton
              onClick={() => setEditablePricing(params.row.editPricing)}
            >
              <Edit />
            </IconButton>
          );
        }
      },
    };

    if (business) {
      return [pricingNameField, pricingHourly, pricingDaily, VAT, editPricing];
      // columns for legacy users
    } else {
      return [pricingNameField, pricingHourly, pricingDaily, VAT, editPricing];
    }
  }, [business, setEditablePricing]);

  return (
    <>
      <Stack direction={"column"} spacing={2} sx={{ padding: "10px" }}></Stack>

      <DataGridPro
        loading={pricingsValidating}
        rows={pricingRows}
        columns={pricingsColumns}
        sx={{
          //   borderColor: "transparent",
          borderRadius: "14px",
          overflow: "hidden",
          height: "500px",
          /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "#f7f7f8",
            color: "#4e536c",
            fontFamily: "Sofia Pro",
            fontSize: "16px",
            // boxShadow: theme.shadows[4],
          },
        }}
        disableRowSelectionOnClick
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </>
  );
}

import {
  Grid,
  TextField,
  FormControlLabel,
  Tooltip,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import { useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";

export default function ReservationCancellation() {
  const { values, handleBlur, handleChange, setFieldValue, touched, errors } =
    useFormikContext();
  return (
    <Grid
      container
      direction={"row"}
      spacing={2}
      sx={{ justifyContent: "center" }}
    >
      <FormGrid>
        <FormControlLabel
          control={
            <Checkbox
              name="reservationCancellation.cancellationEnabled"
              checked={
                values.reservationCancellation?.cancellationEnabled ?? false
              }
              color="primary"
              onChange={(e) => {
                setFieldValue(
                  "reservationCancellation.cancellationEnabled",
                  e.target.checked
                );
              }}
              onBlur={handleBlur}
            />
          }
          label={
            <>
              Asiakkaan varauksen peruutusmahdollisuus
              <Tooltip title="Peruutus ei toimi nettimaksuilla toteutetuille varauksille.">
                <IconButton
                  style={{
                    marginLeft: 8,
                    padding: 0,
                    width: 24,
                    height: 24,
                  }}
                >
                  {/*   <InfoIcon style={{ fontSize: 18 }} /> */}
                </IconButton>
              </Tooltip>
            </>
          }
        />
        {values.reservationCancellation?.cancellationEnabled && (
          <>
            <FormGrid>
              <Typography variant="body2" marginBottom="1dvh">
                Sähköpostissa näkyvän peruutuslinkin teksti määrittyy sulkeiden
                toisen osion perusteella.
                <br />
                Lisää seuraava teksti varausvahvistukseen, jotta asiakkaat
                voivat perua varauksensa itse:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  [LINKKI, täältä]
                </span>
              </Typography>
            </FormGrid>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <FormGrid md={8}>
                <Typography>
                  Varauksen peruutusmahdollisuus päivinä ennen varauksen alkua
                </Typography>
              </FormGrid>
              <FormGrid md={3}>
                <TextField
                  value={values.reservationCancellation?.daysBeforeStart}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="reservationCancellation.daysBeforeStart"
                  type="number"
                  inputProps={{ min: 0 }}
                  variant="outlined"
                  size="small"
                  error={
                    touched.reservationCancellation?.daysBeforeStart &&
                    !!errors.reservationCancellation?.daysBeforeStart
                  }
                  helperText={
                    touched.reservationCancellation?.daysBeforeStart &&
                    errors.reservationCancellation?.daysBeforeStart
                  }
                  fullWidth
                />
              </FormGrid>
            </Grid>
          </>
        )}
      </FormGrid>
    </Grid>
  );
}

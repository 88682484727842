import { FormattedDate } from "../../utils/common";
import { Typography, useTheme } from "@mui/material";

function calculateMonths(date1, date2) {
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  if (date1.getDate() > date2.getDate()) {
    months--;
  }
  return months;
}

function Timing({ timestamp }) {
  const theme = useTheme();
  const date = new Date(timestamp);
  const now = Date.now();
  const diff = now - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = calculateMonths(date, new Date(now));
  const weeks = Math.floor((days - months * 30) / 7);
  const remainingDays = days - months * 30 - weeks * 7;

  const units = [
    { value: months, label: "kk" },
    { value: weeks, label: "vko" },
    { value: remainingDays, label: "pv" },
    { value: hours % 24, label: "t" },
    { value: minutes % 60, label: "m" },
    { value: seconds % 60, label: "s" },
  ];

  const result = units
    .filter((unit) => unit.value > 0)
    .slice(0, 3)
    .map((unit) => `${unit.value}${unit.label}`)
    .join(" ");

  return (
    <div>
      <Typography variant="body2" color={theme.palette.primary.dark}>
        {result}
      </Typography>
      <Typography variant="caption" color={theme.palette.primary.dark}>
        <FormattedDate date={date} />
      </Typography>
    </div>
  );
}

export default Timing;

import React from "react";

import vanDamageMap from "../../../assets/damageMapImages/van_damagemap.webp";
import carDamageMap from "../../../assets/damageMapImages/car_damagemap.webp";
import jetskiDamageMap from "../../../assets/damageMapImages/jetski_damagemap.webp";
import bicycleDamageMap from "../../../assets/damageMapImages/bicycle_damagemap.webp";
import cabinettrolleyDamageMap from "../../../assets/damageMapImages/cabinettrolley_damagemap.webp";
import camperDamageMap from "../../../assets/damageMapImages/camper_damagemap.webp";
import snowmobileDamageMap from "../../../assets/damageMapImages/snowmobile_damagemap.webp";
import trailerDamageMap from "../../../assets/damageMapImages/trailer_damagemap.webp";
import { Box, Stack, Typography } from "@mui/material";
import { EOrderVehicle } from "./LanguagePack/translatedFields";
//@ts-ignore
import { useFetchVehicles } from "~/services/fetchVehicles";
import { titleStyle } from "./styles";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { ReservationVehicle, Vehicle } from "~/graphql/API";
import { ContractField } from "./common";

export const ContractVehicleDamageMap = ({
  vehicle,
  selectedLanguage,
  contractSettings,
}: {
  vehicle: ReservationVehicle;
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) => {
  const reservation = useAtomValue(reservationDataAtom);
  //@ts-ignore
  const returnTime = reservation?.returnTime;
  const { vehicleData } = useFetchVehicles();

  const reservedVehicleData = vehicleData?.find(
    (v: Vehicle) => v.id === vehicle?.id
  );
  const relevantDamageRecords = reservedVehicleData?.damageRecords?.filter(
    (damageRecord: any) =>
      new Date(damageRecord.createdAt) < new Date(returnTime)
  );
  const damageMap = {
    "product-type-vans": vanDamageMap,
    "product-type-minibus": vanDamageMap,
    "product-type-rvs": vanDamageMap,
    "product-type-jetskis": jetskiDamageMap,
    "product-type-cars": carDamageMap,
    "product-type-bicycles": bicycleDamageMap,
    "product-type-trailers": cabinettrolleyDamageMap,
    "product-type-trailer-enclosed": cabinettrolleyDamageMap,
    "product-type-campers": camperDamageMap,
    "product-type-snowmobiles": snowmobileDamageMap,
    "product-type-trailer-open": trailerDamageMap,
    // ... any other product types
  };

  const image =
    damageMap[
      reservedVehicleData?.category?.typeId as keyof typeof damageMap
    ] || null;
  const fields = EOrderVehicle[selectedLanguage as keyof typeof EOrderVehicle];

  if (
    !reservedVehicleData ||
    !image ||
    contractSettings.showVehicleDamagesMap === "disabled"
  )
    return null;
  return (
    <Stack flex={0.4}>
      <Typography sx={titleStyle}>
        {
          EOrderVehicle[selectedLanguage as keyof typeof EOrderVehicle]
            .VEHICLE_CONDITION
        }
      </Typography>
      {vehicle?.damages}

      <Box
        sx={{
          position: "relative",
        }}
      >
        <img
          src={image}
          alt="Vauriokartta"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
          }}
        />
        {relevantDamageRecords?.map((coord: any, index: number) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                position: "absolute",
                left: `${coord.normalizedX * 100}%`,
                top: `${coord.normalizedY * 100}%`,
                transform: "translate(-50%, -50%)",
                width: 10,
                height: 10,
                borderRadius: "50%",
                backgroundColor: "black",
                outline: "2px solid black",
              }}
            />
            <Typography
              sx={{
                position: "absolute",
                left: `${coord.normalizedX * 100}%`,
                top: `${coord.normalizedY * 100 + 2}%`,
                transform: "translate(-50%, 0)",
                color: "white",
                fontSize: "0.55rem",
                backgroundColor: "rgba(0,0,0,0.7)",
                borderRadius: "4px",
                userSelect: "none",
              }}
            >
              {coord.label}
            </Typography>
          </React.Fragment>
        ))}
      </Box>
      <ContractField
        label={fields.DAMAGE_RECORDS}
        value={
          relevantDamageRecords?.length === 0
            ? "Ei merkintöjä"
            : relevantDamageRecords?.map((record: any, index: any) => (
                <Stack key={index} direction={"row"} spacing={1}>
                  <Typography sx={{ fontWeight: 550, fontSize: "8px" }}>
                    {record.label}
                  </Typography>
                  <Typography sx={{ fontSize: "8px" }}>
                    {record.description || ""}
                  </Typography>
                </Stack>
              ))
        }
        visibility={true}
      />
    </Stack>
  );
};

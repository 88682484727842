import React from "react";
import {
  Dialog,
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { centsToLocalString } from "../../../../../../../../shared/money";

const ConfirmActions = ({ dialogType, onConfirm, closeDialog, submitting }) => {
  if (dialogType === "details") {
    return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button variant="contained" onClick={closeDialog} color="primary">
            Ok
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Button
          variant="contained"
          onClick={closeDialog}
          color="error"
          disabled={submitting}
        >
          Peruuta
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => onConfirm(dialogType)}
          disabled={submitting}
          color="success"
        >
          Vahvista
        </Button>
      </Grid>
    </Grid>
  );
};

const EventDetails = ({ event, dialogType }) => {
  if (!event) return null;
  const deletedAt = new Date(event.deletedAt).toLocaleString("fi-FI", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  // Refun Invoice
  if (dialogType === "refund") {
    return (
      <>
        <Typography variant="h6" align="center" fontWeight="bold">
          Haluatko varmasti hyvittää laskun: {event.invoice.innovoiceId}?
        </Typography>
        <Table aria-label="Maksurivit" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Tuote</TableCell>
              <TableCell align="right">Yhteensä</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {event.invoice.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right">
                  {centsToLocalString(
                    parseFloat(item.quantity) * parseFloat(item.unitprice)
                  )}{" "}
                  €
                </TableCell>
              </TableRow>
            ))}{" "}
            <TableRow>
              <TableCell align="right" style={{ fontWeight: "bold" }}>
                Summa:
              </TableCell>
              <TableCell align="right" style={{ fontWeight: "bold" }}>
                {centsToLocalString(event.sum)} €
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }

  // Delete manual PaymentEvent
  if (dialogType === "delete") {
    return (
      <>
        <Typography variant="h6" align="center" fontWeight="bold">
          Haluatko varmasti poistaa maksutapahtuman:
        </Typography>
        <Typography variant="h6" align="center" fontWeight={"bold"}>
          {event?.label}
          {event?.innovoiceId ? ` - Innovoice ID: ${event?.innovoiceId}?` : "?"}
        </Typography>
        <Typography variant="h6" align="center" fontWeight="bold">
          Summa: {centsToLocalString(event.sum)} €
        </Typography>
      </>
    );
  }

  return (
    <>
      {event?.isDeleted && (
        <>
          <Typography variant="h6" align="center" fontWeight={"bold"}>
            Poistanut: {event.deletedBy}
          </Typography>
          {event?.deletedAt && (
            <Typography variant="h6" align="center" fontWeight={"bold"}>
              Poistoaika: {deletedAt}
            </Typography>
          )}
          <br></br>
        </>
      )}
      <Typography variant="h6" align="center">
        Maksutapahtuman tiedot:
      </Typography>
      <Typography variant="h6" align="center" fontWeight="bold">
        {event.label}
      </Typography>
      <Typography variant="h6" align="center" fontWeight="bold">
        Summa: {centsToLocalString(event?.sum)} €
      </Typography>
    </>
  );
};

const PaymentEventDialog = ({
  open,
  onClose,
  event,
  dialogType,
  confirmDelete,
  confirmRefund,
  handleClose,
  submitting,
}) => {
  const handleConfirm = (type) => {
    if (type === "delete") {
      confirmDelete();
    } else {
      confirmRefund();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Box padding={4}>
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item xs={12}>
            <EventDetails event={event} dialogType={dialogType} />
          </Grid>
          <Grid item xs={12}>
            <ConfirmActions
              dialogType={dialogType}
              submitting={submitting}
              onConfirm={handleConfirm}
              closeDialog={handleClose}
            />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default PaymentEventDialog;

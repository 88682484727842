import ReservationForm from "./components/ReservationForm";
import FormContent from "./components/FormContent";

export default function CustomInsuranceForm(props) {
  return (
    <ReservationForm>
      <FormContent />
    </ReservationForm>
  );
}

import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import captureError from "../utils/capture-error";
import { listNewsItems } from "../graphql/queries";

const fetch = async (url) => {
  try {
    const news = await listAll(graphqlOperation(listNewsItems), url);
    // Sort the newsItems by createdAt timestamp in ascending order
    const sortedNews = news.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    return sortedNews;
  } catch (e) {
    console.log("Get news failed", "GET_NEWS_FAILED", e);
    captureError("Get news failed", "GET_NEWS_FAILED", e);
  }
};

export function useFetchNews() {
  const url = "listNewsItems";
  const {
    data: newsItems,
    error,
    isLoading,
    mutate,
  } = useSWR(url, () => fetch(url), {
    revalidateOnFocus: false,
  });

  return {
    newsItems,
    newsItemsLoading: isLoading,
    newsItemsError: error,
    newsItemsMutate: mutate,
  };
}

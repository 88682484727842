import {
  Grid,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

import React, { useState, useMemo, useEffect, useContext } from "react";
import { parseToCents, centsToLocalString } from "../shared/money";
import {
  calculateEffectivePrices,
  parsePricingElements,
} from "../shared/pricing-utilities";
import { API, graphqlOperation } from "aws-amplify";
import { createPricing, updatePricing } from "../graphql/mutations";
import { useNotification } from "./Notification";
import captureError from "../utils/capture-error";
import { useConfirmation } from "./Confirmation";
import DialogComponent from "./DialogComponent";
import { AppContext } from "../AppContext";
import { DEFAULT_VAT_VALUE, VAT_VALUES_PRICINGS } from "~/configs/vat-values";

function calculatePrices(priceStrings, defaultStartPrice) {
  const prices = priceStrings.map(parseToCents);
  return calculateEffectivePrices(prices, defaultStartPrice);
}

export default function PricingEditDialog({ onClose, pricing, disabled }) {
  const [submitting, setSubmitting] = useState(false);
  const [useDifferentTaxRate, setUseDifferentTaxRate] = useState(true);
  const [taxRate, setTaxRate] = useState(pricing.tax ?? DEFAULT_VAT_VALUE);
  const notification = useNotification();
  const initialPricingSheet = useMemo(() => {
    const pricingSheet = parsePricingElements(pricing.elements);
    const format = (price) => (price != null ? centsToLocalString(price) : "");

    return {
      hourlyRates: pricingSheet.hourlyRates.map(format),
      dailyRates: pricingSheet.dailyRates.map(format),
    };
  }, [pricing]);

  const [name, setName] = useState(pricing.name);
  const [submitted, setSubmitted] = useState(false);
  const [hourlyRates, setHourlyRates] = useState(
    initialPricingSheet.hourlyRates
  );
  const [dailyRates, setDailyRates] = useState(initialPricingSheet.dailyRates);
  const [useNextDayPrice, setUseNextDayPrice] = useState(
    pricing.useNextDayPrice
  );
  const { group, user } = useContext(AppContext);

  const effectiveHourlyRates = useMemo(
    () => calculatePrices(hourlyRates),
    [hourlyRates]
  );
  const effectiveDailyRates = useMemo(
    () =>
      calculatePrices(
        dailyRates,
        effectiveHourlyRates[22].total +
          effectiveHourlyRates[22].currentEffectiveRate
      ),
    [dailyRates, effectiveHourlyRates]
  );

  useEffect(() => {
    if (
      pricing.tax === DEFAULT_VAT_VALUE ||
      isNaN(pricing.tax) ||
      pricing.tax === null
    ) {
      setUseDifferentTaxRate(false);
    }
  }, [pricing.tax]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const submit = async () => {
    setSubmitted(true);

    if (!name || (!taxRate && useDifferentTaxRate)) {
      return;
    }

    const tax = !useDifferentTaxRate ? DEFAULT_VAT_VALUE : taxRate;

    setSubmitting(true);

    const newPricing = {
      name,
      tax,
      elements: [
        ...hourlyRates.map((r, index) => ({
          duration: "h" + index,
          price: parseToCents(r),
        })),
        ...dailyRates.map((r, index) => ({
          duration: "d" + index,
          price: parseToCents(r),
        })),
      ].filter((element) => element.price != null),
      useNextDayPrice,
    };

    if (group) {
      newPricing.group = group;
    }
    // TODO (CU-2g00z7z): Support editing Pricings from a Business as a ORG ADMIN.
    // Now hard coded so that org admin always edits org level pricings, idea is
    // that in the future if org admin has selected a business, for example pricings
    // are created in that business.
    if (user?.role === "ORGANIZATION_ADMIN") {
      newPricing.organizationId = user.organizationId;
    }

    try {
      if (pricing.id) {
        await API.graphql(
          graphqlOperation(updatePricing, {
            input: {
              ...newPricing,
              id: pricing.id,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createPricing, {
            input: newPricing,
          })
        );
      }

      onClose(true);
      return;
    } catch (e) {
      captureError("Upsert pricing failed", "UPSERT_PRICING_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const [showDeleteConfirmation, DeleteConfirmationDialog] = useConfirmation(
    async () => {
      await API.graphql(
        graphqlOperation(updatePricing, {
          input: {
            _removed: true,
            id: pricing.id,
          },
        })
      );
      //   fetchPricingExceptions();
      onClose(true);
    }
  );

  const confirmationMessage =
    "Haluatko varmasti poistaa hinnaston " + pricing.name + " ?";

  return (
    <>
      <DialogComponent
        open
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={fullScreen}
        dialogDelete={
          pricing?.id && !disabled ? () => showDeleteConfirmation() : null
        }
        dialogClose={() => onClose()}
        dialogAction={disabled ? null : submit}
        dialogActionText={"Tallenna"}
        dialogTitle={pricing?.name ? "Muokkaa hinnastoa" : "Luo hinnasto"}
        dialogActionSubmitting={submitting}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ marginTop: "25px" }}>
            <TextField
              placeholder="Nimi"
              label="Nimi"
              value={name}
              disabled={disabled}
              onChange={(evt) => {
                setSubmitted(false);
                setName(evt.target.value);
              }}
              required
              error={submitted && !name}
              helperText={submitted && !name && "Täytä tämä kenttä"}
              data-cy="pricingDialogNameField"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="useNextDayPrice"
                  checked={useNextDayPrice}
                  disabled={disabled}
                  color="primary"
                  onChange={(e) => {
                    setUseNextDayPrice(e.target.checked);
                  }}
                />
              }
              label={"Yli 24h varauksissa seuraavan täyden vuorokauden hinta"}
              data-cy="pricingDialogNextDayPriceCheckbox"
            />
          </Grid>
          <Grid
            style={{
              paddingTop: 0,
              marginTop: 0,
              paddingBottom: 0,
              marginBottom: 0,
              alignItems: "center",
              display: "flex",
            }}
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="useDifferentTaxRate"
                  checked={useDifferentTaxRate}
                  disabled={disabled}
                  color="primary"
                  onChange={(e) => {
                    setUseDifferentTaxRate(e.target.checked);
                  }}
                  data-cy="pricingDialogTaxRateCheckbox"
                />
              }
              label={`Käytä muuta kuin ${VAT_VALUES_PRICINGS[DEFAULT_VAT_VALUE]}% veroa`}
            />
          </Grid>
          {useDifferentTaxRate ? (
            <Grid item style={{ paddingTop: 0, marginTop: 0 }} xs={12}>
              <Typography variant="h6">ALV %</Typography>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                style={{ minWidth: 200 }}
                disabled={disabled}
                size="small"
                value={taxRate}
                onChange={(evt) => {
                  setSubmitted(false);
                  setTaxRate(evt.target.value);
                }}
                error={submitted && useDifferentTaxRate && !taxRate}
                data-cy="pricingDialogTaxRateSelect"
              >
                {Object.entries(VAT_VALUES_PRICINGS).map(([value, label]) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-cy="pricingDialogTaxRateOption"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          ) : null}

          <Grid item xs={12} sm={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tunti</TableCell>
                  <TableCell>Tuntihinta</TableCell>
                  <TableCell align="right">Kokonais&shy;hinta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hourlyRates.map((hourlyRate, index) => {
                  const rateInfo = effectiveHourlyRates[index];
                  const isExtraHour = index === hourlyRates.length - 1;

                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {isExtraHour ? "Lisätunti" : index + 1 + "."}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          size="small"
                          placeholder={centsToLocalString(
                            rateInfo.currentEffectiveRate
                          )}
                          disabled={disabled}
                          value={hourlyRate}
                          onChange={(evt) => {
                            let value = evt.target.value;

                            // Verify that inputted value is numerical with max one ",". E.g 10,50
                            if ((value.match(/,/g) || []).length <= 1) {
                              const numValue = value.replace(",", ".");
                              if (isNaN(numValue)) {
                                return;
                              }
                            } else {
                              return;
                            }

                            const newRates = [...hourlyRates];
                            newRates[index] = value;
                            setHourlyRates(newRates);
                          }}
                          data-cy="pricingDialogHourlyPriceField"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {isExtraHour ? "-" : centsToLocalString(rateInfo.total)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Päivä</TableCell>
                  <TableCell>Päivähinta</TableCell>
                  <TableCell align="right">Kokonais&shy;hinta</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dailyRates.map((dailyRate, index) => {
                  const rateInfo = effectiveDailyRates[index];
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1 + "."}
                      </TableCell>
                      <TableCell align="right">
                        <TextField
                          size="small"
                          placeholder={centsToLocalString(
                            rateInfo.currentEffectiveRate
                          )}
                          value={dailyRate}
                          disabled={disabled}
                          onChange={(evt) => {
                            let value = evt.target.value;
                            // Verify that inputted value is numerical with max one ",". E.g 10,50
                            if ((value.match(/,/g) || []).length <= 1) {
                              const numValue = value.replace(",", ".");
                              if (isNaN(numValue)) {
                                return;
                              }
                            } else {
                              return;
                            }

                            const newRates = [...dailyRates];
                            newRates[index] = value;
                            setDailyRates(newRates);
                          }}
                          data-cy="pricingDialogDailyPriceField"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {centsToLocalString(rateInfo.total)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogComponent>
      <DeleteConfirmationDialog
        message={confirmationMessage}
        confirmLabel="Poista"
        testName="deletePricingDialog"
      />
    </>
  );
}

import { ThemeProvider, createTheme } from "@mui/material";
import { fiFI as dataGridLocale } from "@mui/x-data-grid";
import * as locales from "@mui/material/locale";
import { ReactNode } from "react";
import { lightTheme } from "./themes/light";

const locale = "fiFI";
const theme = createTheme(
  {
    typography: {
      fontFamily: [
        "Roboto",
        "Sofia Pro",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    components: {
      MuiPaper: {
        defaultProps: {
          sx: {
            borderRadius: "20px",
            WebkitBoxShadow: "5px 5px 15px 5px rgba(0,0,0,0.11)",
            boxShadow: "5px 5px 15px 5px rgba(0,0,0,0.11)",
            overflow: "hidden",
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: " rgba(0,0,0,0.10)",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          sx: {
            textTransform: "none",
            borderRadius: "20px",
            paddingX: "20px",
          },
        },
      },
    },
    palette: {
      primary: lightTheme,
      secondary: { main: "#a6a6a6" },

      background: {
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  },

  locales[locale], // for MUI components
  dataGridLocale // DataGrid uses its own locale
);

type AppThemeProviderProps = { children: ReactNode };

export default function AppThemeProvider({ children }: AppThemeProviderProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

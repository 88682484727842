import { useCategories } from "~/queries/useCategories";
import { API, graphqlOperation } from "aws-amplify";
import { getPriceByCategoryIds } from "../../../graphql/queries";
import { captureError } from "../../../utilities";
import { useContext, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useReservationContext } from "../ReservationContext";
import { AppContext } from "../../../AppContext";

export const useGetWildCardPrice = (enableWildCard) => {
  const categories = useCategories();
  const { values, setFieldValue } = useFormikContext();
  const { startTime, returnTime, endTime, queryResource } =
    useReservationContext();
  const { user } = useContext(AppContext);

  const externalBusinessId = useMemo(() => {
    // define contract price for external users
    if (user?.externalBusinessId) {
      return user?.externalBusinessId;
    }
    // define contract price for wild card reservations
    if (queryResource?.extBusinessId) {
      return queryResource?.extBusinessId;
    }
  }, [user, queryResource?.extBusinessId]);

  const fetchWildCardOfferPrice = useCallback(async () => {
    if (!enableWildCard) return null;
    let wildCardOffer;
    const categoryIds = [values?.selectedVehicleClass];
    // Don't try to fetch pricing if incorrect dates chosen
    if ((!startTime && !returnTime) || !values?.selectedVehicleClass) {
      return null;
    }

    if (
      returnTime < startTime ||
      endTime < returnTime ||
      startTime?.getTime() === returnTime?.getTime()
    ) {
      return null;
    }

    try {
      const response = await API.graphql(
        graphqlOperation(getPriceByCategoryIds, {
          categoryIds,
          startTime,
          returnTime,
          externalBusinessId,
        })
      );
      wildCardOffer =
        response.data.getPriceByCategoryIds.categoryOfferPrices.map((offer) => {
          return { ...offer, price: offer?.price, tax: offer?.tax };
        });
    } catch (e) {
      captureError("Get price failed", "GET_PRICE_FAILED", e);

      // notification.show("Jokin meni vikaan");
    }

    if (wildCardOffer) {
      //  setWildCardDefaultPrice(wildCardOffer[0]?.price);
      setFieldValue("wildCardVehicles", [
        {
          categoryId: wildCardOffer[0].id,
          name: categories.find((c) => c.id === wildCardOffer[0].id)?.name,
          price: wildCardOffer[0]?.price,
          deductible: wildCardOffer[0]?.deductible,
          tax: wildCardOffer[0]?.tax,
        },
      ]);
    }
  }, [
    categories,
    startTime,
    returnTime,
    endTime,
    values.selectedVehicleClass,
    externalBusinessId,
    setFieldValue,
  ]);

  useEffect(() => {
    // incase user is typing a date value, or something weird happens with dates, dont fetch a new price
    if (enableWildCard) {
      fetchWildCardOfferPrice();
    }
  }, [enableWildCard, fetchWildCardOfferPrice]);
};

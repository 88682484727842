import { Grid } from "@mui/material";

import PricingSelection from "../PricingSelection";
import AdditionalServicePricing from "../AdditionalServicePricing";
import { useMemo } from "react";
import { useFetchAdditionalServices } from "../../services/fetchAdditionalServices";
import { useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";

export default function ProductPricing({
  showPricings,
  company,
  selectedCategory,
  servicesOnVehicle,
}) {
  const { additionalServices } = useFetchAdditionalServices();
  const { values } = useFormikContext();
  const enabledServices = useMemo(() => {
    if (selectedCategory && values["useCategoryDefaults"]) {
      // Use services on the Category
      return selectedCategory?.additionalServices?.items;
    }
    return servicesOnVehicle;
  }, [selectedCategory, values, servicesOnVehicle]);

  return (
    <Grid container direction={"row"} spacing={1} justifyContent={"center"}>
      <FormGrid>
        <PricingSelection
          additionalServices={additionalServices}
          showPricings={showPricings}
          company={company}
          name={
            selectedCategory && values["useCategoryDefaults"]
              ? "categoryPricingId"
              : "vehiclePricingId"
          }
          label="Hinnasto"
          disabled={values["useCategoryDefaults"]}
        />
      </FormGrid>
      <FormGrid>
        <AdditionalServicePricing
          additionalServices={additionalServices}
          showPricings={showPricings}
          company={company}
          filterCategory={
            selectedCategory && values["useCategoryDefaults"]
              ? selectedCategory
              : false
          }
          enabledServices={enabledServices}
        />
      </FormGrid>
    </Grid>
  );
}

import { TextField } from "@mui/material";
import { FormGrid } from "../../common/FormComponents";
import { useFormikContext } from "formik";

export default function FormikTextField({
  contentByUserRole,
  name,
  gridProps,
  ...props
}) {
  const { values, handleBlur, handleChange, errors, touched } =
    useFormikContext();

  return (
    <FormGrid {...gridProps}>
      <TextField
        value={values[name]}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        variant="outlined"
        error={touched[name] && !!errors[name]}
        helperText={touched[name] && errors[name]}
        fullWidth
        {...props}
      />
    </FormGrid>
  );
}

import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
//@ts-ignore
import { AppContext } from "~/AppContext";
//@ts-ignore
import { useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

//@ts-ignore
import SelectContractLanguage from "~/components/ReservationDialogV2/components/SelectContractLanguage";

import { ContractTemplatePreview } from "~/print-contracts/ContractTemplate/contract-template-preview";

import { TbPrinter } from "react-icons/tb";
import { FcPrint } from "react-icons/fc";

import { useReactToPrint } from "react-to-print";
import { Close } from "@mui/icons-material";
import { Company, Reservation, User } from "~/graphql/API";
import { getFixedLanguage } from "~/print-contracts/ContractTemplate/utils";
import { settingsPreviewReservation } from "~/print-contracts/ContractTemplate/settings-preview-reservation-data";
import { API, graphqlOperation } from "aws-amplify";
import { updateCompany } from "~/graphql/mutations";

//@ts-ignore
import { useNotification } from "~/components/Notification";

import {
  useContextFetchCompanies,
  //@ts-ignore
} from "~/services/fetchCompanies";

/* 
TODO: handle showExportField

hideExport from vehicles
*/

export const PrintingComponent = ({
  adjustSettings,
  submitForm,
  validate,
}: {
  adjustSettings?: boolean;
  submitForm: any;
  validate: any;
}) => {
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const reservationData = useAtomValue(reservationDataAtom);

  //@ts-ignore
  if (!reservationData && !adjustSettings) return null;
  return (
    <React.Fragment>
      <ChangeSettingsButton
        setShowPrintPreview={setShowPrintPreview}
        adjustSettings={adjustSettings}
      />
      <ShowPrintPreviewButton
        setShowPrintPreview={setShowPrintPreview}
        validate={validate}
        submitForm={submitForm}
      />
      {showPrintPreview && (
        <PreviewContract
          showPrintPreview={showPrintPreview}
          setShowPrintPreview={setShowPrintPreview}
        />
      )}
    </React.Fragment>
  );
};

function PreviewContract({
  showPrintPreview,
  setShowPrintPreview,
}: {
  showPrintPreview: boolean;
  setShowPrintPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { user, company } = useContext<any>(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const [showPrintingSettings, setShowPrintingSettings] = useState(false);
  //@ts-ignore
  const fixedLanguage = getFixedLanguage(reservationData?.language);

  const [selectedLanguage, setSelectedLanguage] = useState(() => fixedLanguage);
  const group = user?.group;
  const userEntity = user?.organizationId ?? group;
  const [contractSettings, setContractSettings] = useState<
    Record<string, boolean | string>
    //@ts-ignore
  >(() =>
    handleInitialSettings({
      reservationData: reservationData,
      user: user,
      company: company,
      group: group,
    })
  );

  const printRef = useRef(null);

  const openPreview = useReactToPrint({
    content: () => printRef.current,
  });

  const mobileViewPort = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Dialog
      open={showPrintPreview}
      onClose={() => setShowPrintPreview(false)}
      maxWidth={"lg"}
      fullScreen={mobileViewPort}
    >
      <DialogContent sx={{ overflow: "hidden", position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            bgcolor: "white",
            marginBottom: "5px",
          }}
        >
          <Stack>
            <Box justifyContent={"flex-end"} display={"flex"} flex={1}>
              <Box>
                <IconButton onClick={() => setShowPrintPreview(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Stack direction={"row"} spacing={1}>
              <Box flex={1} display={"flex"}>
                <Button
                  onClick={() =>
                    setShowPrintingSettings((prevState) => !prevState)
                  }
                >
                  Lisäasetukset
                </Button>
              </Box>
              <SaveContractSettingsButton
                contractSettings={contractSettings}
                setShowPrintPreview={setShowPrintPreview}
              />
              <PrintContractButton handlePrint={openPreview} />
            </Stack>
          </Stack>
          <Divider />
        </Box>
        <Stack direction={"row"} sx={{ overflow: "hidden", height: "90vh" }}>
          <SettingsAdjustmentContainer
            mobileViewPort={mobileViewPort}
            showPrintingSettings={showPrintingSettings}
            setShowPrintingSettings={setShowPrintingSettings}
          >
            <Box
              sx={{
                display: "flex",
                padding: "5px",
                overflow: "auto",
                height: "100%",
              }}
            >
              <Stack spacing={2} width={"100%"} height={"100%"}>
                <SelectContractLanguage
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                />
                <Stack spacing={1}>
                  <Box>
                    <Typography>Asiakastiedot</Typography>
                    <Divider />
                  </Box>
                  <AdjustContractSetting
                    label="Näytä asiakkaan lisätietokenttä"
                    value={
                      contractSettings?.showCustomerAdditionalInformationField
                    }
                    setContractSettings={setContractSettings}
                    settingKey="showCustomerAdditionalInformationField"
                  />
                  <AdjustContractSetting
                    label="Näytä yritysasiakkaan tiedot"
                    value={contractSettings?.showCompanyCustomerFields}
                    setContractSettings={setContractSettings}
                    settingKey="showCompanyCustomerFields"
                  />
                  <AdjustContractSetting
                    label="Näytä kuljettaja"
                    value={contractSettings?.showDriverFields}
                    setContractSettings={setContractSettings}
                    settingKey="showDriverFields"
                  />
                  <AdjustContractSetting
                    label="Näytä lisäkuljettaja"
                    value={contractSettings?.showAdditionalDriver}
                    setContractSettings={setContractSettings}
                    settingKey="showAdditionalDriver"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Box>
                    <Typography>Tuotteet</Typography>
                    <Divider />
                  </Box>
                  {/*  <ContractProductType
                      setContractSettings={setContractSettings}
                      contractSettings={contractSettings}
                      selectedLanguage={selectedLanguage}
                    /> */}
                  <ContractProductTitle
                    setContractSettings={setContractSettings}
                    contractSettings={contractSettings}
                  />
                  <AdjustContractProductVisibility
                    contractSettings={contractSettings}
                    setContractSettings={setContractSettings}
                  />
                  <AdjustContractSetting
                    label="Näytä hinnat"
                    value={contractSettings?.showPrices}
                    settingKey="showPrices"
                    setContractSettings={setContractSettings}
                  />
                  <AdjustContractSetting
                    label="Näytä tuotteen lisätiedot"
                    value={contractSettings?.showVehicleExtraInfo}
                    setContractSettings={setContractSettings}
                    settingKey="showVehicleExtraInfo"
                  />
                  <AdjustContractSetting
                    label="Näytä rekisterinumero"
                    value={contractSettings?.showRegistrationPlateField}
                    setContractSettings={setContractSettings}
                    settingKey="showRegistrationPlateField"
                  />

                  <AdjustContractSetting
                    label="Näytä vauriokartta"
                    value={contractSettings?.showVehicleDamagesMap}
                    setContractSettings={setContractSettings}
                    settingKey="showVehicleDamagesMap"
                  />
                  <AdjustContractSetting
                    label="Näytä kilometritiedot"
                    value={contractSettings?.showVehicleMileage}
                    setContractSettings={setContractSettings}
                    settingKey="showVehicleMileage"
                  />

                  <AdjustContractSetting
                    label="Näytä polttoainetiedot"
                    value={contractSettings?.showVehicleFuelFields}
                    setContractSettings={setContractSettings}
                    settingKey="showVehicleFuelFields"
                  />

                  <AdjustContractSetting
                    label="Näytä omavastuutiedot"
                    value={contractSettings?.showDeductibleField}
                    setContractSettings={setContractSettings}
                    settingKey="showDeductibleField"
                  />
                  <AdjustContractSetting
                    label="Näytä maastapoistumislupa"
                    value={contractSettings?.showExportField}
                    setContractSettings={setContractSettings}
                    settingKey="showExportField"
                  />
                </Stack>
                <Stack spacing={1} paddingBottom={"100px"}>
                  <Box>
                    <Typography>Sopimuksen lisätiedot</Typography>
                    <Divider />
                  </Box>
                  <AdjustContractSetting
                    label="Näytä jälkiveloitustiedot"
                    value={contractSettings?.showPostDebit}
                    setContractSettings={setContractSettings}
                    settingKey="showPostDebit"
                  />
                  <AdjustContractSetting
                    label="Näytä vuokrausehdot"
                    value={contractSettings?.showContractAgreementDetails}
                    setContractSettings={setContractSettings}
                    settingKey="showContractAgreementDetails"
                  />

                  <AdjustContractSetting
                    label="Näytä nouto- & palautuspaikka"
                    value={contractSettings?.showLocation}
                    setContractSettings={setContractSettings}
                    settingKey="showLocation"
                  />
                  <AdjustContractSetting
                    label="Näytä vuokrausehtojen lisäehto"
                    value={!!contractSettings?.showContractAdditionalAgreement}
                    setContractSettings={setContractSettings}
                    settingKey="showContractAdditionalAgreement"
                  />
                </Stack>
              </Stack>
            </Box>
          </SettingsAdjustmentContainer>
          <Box sx={{ overflow: "auto", paddingBottom: "100px" }}>
            <ContractTemplatePreview
              userEntity={userEntity}
              selectedLanguage={selectedLanguage}
              contractSettings={contractSettings}
              setContractSettings={setContractSettings}
              ref={printRef}
            />
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

function SettingsAdjustmentContainer({
  mobileViewPort,
  showPrintingSettings,
  setShowPrintingSettings,
  children,
}: {
  mobileViewPort: boolean;
  children: React.ReactNode;
  showPrintingSettings: boolean;
  setShowPrintingSettings: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  if (!mobileViewPort) {
    return (
      <Collapse in={showPrintingSettings} orientation="horizontal">
        {children}
      </Collapse>
    );
  }
  return (
    <Drawer
      open={showPrintingSettings}
      sx={{ zIndex: 9999 }}
      onClose={() => setShowPrintingSettings(false)}
    >
      {children}
    </Drawer>
  );
}

function AdjustContractProductVisibility({
  contractSettings,
  setContractSettings,
}: {
  contractSettings: Record<string, string | boolean>;
  setContractSettings: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean>>
  >;
}) {
  const { user, company, group } = useContext<any>(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);

  return (
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          checked={!!contractSettings?.showProductInformation}
          onChange={(event) => {
            setContractSettings((prevState) => ({
              ...prevState,
              showProductInformation: event.target.checked,
              showPrices: event.target.checked ? true : "disabled",
              showVehicleExtraInfo: event.target.checked ? true : "disabled",
              showRegistrationPlateField: event.target.checked
                ? true
                : "disabled",
              showVehicleDamagesMap: event.target.checked
                ? handleInitialSettings({
                    reservationData: reservationData,
                    user: user,
                    company: company,
                    group: group,
                    //@ts-ignore
                  })?.showVehicleDamagesMap
                : "disabled",
              showVehicleMileage: event.target.checked ? true : "disabled",
              showVehicleFuelFields: event.target.checked ? true : "disabled",
              showDeductibleField: event.target.checked ? true : "disabled",
              showExportField: event.target.checked ? true : "disabled",
            }));
          }}
        />
      }
      label={"Näytä tuotetiedot"}
      slotProps={{
        typography: {
          width: "100%",
          variant: "caption",
        },
      }}
    />
  );
}

function AdjustContractSetting({
  setContractSettings,
  label,
  settingKey,
  value,
}: {
  setContractSettings: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean>>
  >;
  label: string;
  settingKey: string;
  value: boolean | undefined | null | string;
}) {
  return (
    <FormControlLabel
      labelPlacement="start"
      disabled={value === "disabled"}
      control={
        <Switch
          checked={value === "disabled" ? false : !!value}
          onChange={(event) => {
            setContractSettings((prevState) => ({
              ...prevState,
              [settingKey]: event.target.checked,
            }));
          }}
        />
      }
      label={label}
      slotProps={{
        typography: {
          width: "100%",
          variant: "caption",
        },
      }}
    />
  );
}

function ContractProductTitle({
  setContractSettings,
  contractSettings,
}: {
  setContractSettings: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean>>
  >;
  contractSettings: Record<string, string | boolean>;
}) {
  return (
    <FormControl
      fullWidth
      sx={{
        paddingRight: "10px",
      }}
    >
      <InputLabel>Tuotteen nimi</InputLabel>
      <Select
        size="small"
        label="Tuotteen nimi"
        onChange={(event) => {
          setContractSettings((prevState) => ({
            ...prevState,
            contractProductTitle: event.target.value,
          }));
        }}
        value={contractSettings?.contractProductTitle}
      >
        <MenuItem value="vehicle">Ajoneuvo</MenuItem>
        <MenuItem value="exampleModel">Esimerkki malli </MenuItem>
      </Select>
    </FormControl>
  );
}

function handleInitialSettings({
  reservationData,
  user,
  company,
}: {
  reservationData: Reservation | true | null;
  user: User | null;
  company: Company;
  group?: string;
}) {
  //@ts-ignore
  const settingsJSON = company?.contractSettingsJSON
    ? //@ts-ignore
      JSON.parse(company?.contractSettingsJSON)
    : {};

  const defaultSettings = {
    contractProductTitle: "vehicle",
    showAdditionalDriver: true,
    //@ts-ignore
    showCompanyCustomerFields: reservationData?.isCompany,
    showContractAgreementDetails: true,
    showDeductibleField: true,
    showDriverFields: true,
    showExportField: true,
    showLocation: true,
    showPostDebit: true,
    showPrices: true,
    showRegistrationPlateField: true,
    showVehicleDamagesMap: user?.organizationId ? true : "disabled",
    showVehicleFuelFields: true,
    showVehicleMileage: true,
    showVehicleExtraInfo: true,
    showPaymentStatus: true,
    showProductInformation: true,
    showContractAdditionalAgreement: false,
  };
  return {
    ...defaultSettings,
    ...settingsJSON,
    //@ts-ignore
    showAdditionalDriver: !!reservationData?.additionalDriver,
    //@ts-ignore
    showDriverFields: !!reservationData?.differentDriver,
    showPrices: handleShowPrices(
      defaultSettings.showPrices,
      settingsJSON.showPrices,
      //@ts-ignore
      reservationData?.disablePricesInPrintouts
    ),
  };
}

function handleShowPrices(
  defaultSetting: boolean,
  companySetting: boolean,
  reservationSetting: boolean
) {
  if (reservationSetting !== undefined && reservationSetting !== null) {
    /* Reservation has disablePricesInPrintouts that uses the opposite logic that is used with showPrices */
    return reservationSetting === false ? true : false;
  } else if (companySetting !== undefined && companySetting !== null) {
    return companySetting;
  }
  return defaultSetting;
}

function ChangeSettingsButton({
  setShowPrintPreview,
  adjustSettings,
}: {
  setShowPrintPreview: React.Dispatch<React.SetStateAction<boolean>>;
  adjustSettings?: boolean;
}) {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  if (adjustSettings)
    return (
      <Box>
        <Tooltip title={"Muuta asetuksia"} placement="top">
          <Button
            onClick={() => {
              setEditReservationData(settingsPreviewReservation);
              setShowPrintPreview((prevState) => !prevState);
            }}
            sx={{ textTransform: "none" }}
          >
            Muokkaa sopimuksen asetuksia
          </Button>
        </Tooltip>
      </Box>
    );
}

function SaveContractSettingsButton({
  contractSettings,
  setShowPrintPreview,
}: {
  contractSettings: Record<string, string | boolean>;
  setShowPrintPreview: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const reservationData = useAtomValue(reservationDataAtom);
  const { companiesMutate } = useContextFetchCompanies();
  const { company } = useContext<any>(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const notification = useNotification();
  //@ts-ignore
  if (reservationData?.id === "settings-preview-reservation" && company?.id) {
    return (
      <Box
        flex={1}
        display={"flex"}
        justifyContent={"center"}
        sx={{ paddingY: "10px" }}
      >
        <Button
          disabled={isSubmitting}
          onClick={() =>
            submitContractSettings({
              contractSettings: contractSettings,
              company: company,
              companiesMutate: companiesMutate,
              setIsSubmitting,
              setEditReservationData,
              setShowPrintPreview,
              notification,
            })
          }
          variant="contained"
        >
          Tallenna asetukset
        </Button>
      </Box>
    );
  }
}

function PrintContractButton({ handlePrint }: { handlePrint: () => void }) {
  const reservationData = useAtomValue(reservationDataAtom);

  if (
    //@ts-ignore
    reservationData?.id === "settings-preview-reservation" ||
    !reservationData
  ) {
    return null;
  }
  return (
    <Box flex={1} display={"flex"} justifyContent={"center"}>
      <Button size="large" startIcon={<FcPrint />} onClick={handlePrint}>
        Tulosta sopimus
      </Button>
    </Box>
  );
}

function ShowPrintPreviewButton({
  setShowPrintPreview,
  validate,
  submitForm,
}: {
  setShowPrintPreview: React.Dispatch<React.SetStateAction<boolean>>;
  validate: any;
  submitForm: any;
}) {
  const reservationData = useAtomValue(reservationDataAtom);
  const setEditReservationData = useSetAtom(reservationDataAtom);

  if (
    //@ts-ignore
    !reservationData?.id ||
    //@ts-ignore
    reservationData?.id === "settings-preview-reservation"
  )
    return null;
  return (
    <Box>
      <Tooltip title={"Tulosta sopimus"} placement="top">
        <IconButton
          onClick={async (_event) => {
            const isValid = await validate();
            if (!isValid) return;
            const response = await submitForm();

            if (response) {
              const reservation =
                response?.data?.reservation ??
                response?.data?.upsertReservationAdmin?.reservation;
              setEditReservationData(reservation);
              setShowPrintPreview(true);
            }

            /*             if (response?.success) {
              const userEntity = company?.group ?? user?.organizationId;
              const logo = clientLogoData.find(
                (item) => item.id === userEntity
              )?.logo;

              // This removes any undefineds etc. from the list that might prevent the printing to go through.
              const imageUrls = [vanDamageMap, carDamageMap, logo].filter(
                (url) => Boolean(url)
              );
              await Promise.all(
                imageUrls.map((url) => {
                  return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = url;
                  });
                })
              );
              // await new Promise((resolve) => setTimeout(resolve, 1000)); This delay might help the issues with images not loading in time.
            } */
          }}
          // onClick={() => setShowPrintPreview(true)}
        >
          <TbPrinter style={{ fontSize: "1.5rem" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

/* 
// This component is not used in the current implementation
function ContractProductType({
  setContractSettings,
  contractSettings,
}: {
  setContractSettings: React.Dispatch<
    React.SetStateAction<Record<string, string | boolean>>
  >;
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
}) {
  return (
    <FormControl fullWidth>
      <InputLabel>Tuotetyyppi</InputLabel>
      <Select
        size="small"
        label="Tuotetyyppi"
        onChange={(event) => {
          setContractSettings((prevState) => ({
            ...prevState,
            contractProductType: event.target.value,
          }));
        }}
        value={contractSettings?.contractProductType}
      >
        {typeOfProducts?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

*/

const submitContractSettings = async ({
  contractSettings,
  company,
  companiesMutate,
  setIsSubmitting,
  setEditReservationData,
  setShowPrintPreview,
  notification,
}: {
  contractSettings: Record<string, string | boolean>;
  company: Company;
  companiesMutate: any;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setEditReservationData: any;
  setShowPrintPreview: any;
  notification: any;
}) => {
  const jsonSettings = JSON.stringify(contractSettings);
  try {
    setIsSubmitting(true);
    if (company?.id) {
      const response = await API.graphql(
        graphqlOperation(updateCompany, {
          input: {
            id: company.id,
            contractSettingsJSON: jsonSettings,
          },
        })
      );
      if (response) {
        notification.show("Sopimuksen asetukset tallennettu");
        setShowPrintPreview(false);
        setEditReservationData(null);
      }
    }
    companiesMutate();
  } catch (error) {
    console.log("Error updating company", error);
  } finally {
    setIsSubmitting(false);
  }
};

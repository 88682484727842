import React, { useRef } from "react";
import { TextField, Typography, Box } from "@mui/material";
import DialogComponent from "../DialogComponent";
import captureError from "../../utils/capture-error";
import { useNotification } from "../Notification";
import { API, graphqlOperation } from "aws-amplify";
import { updateCompany } from "../../graphql/custom-mutations";
import { useFormik, useFormikContext } from "formik";

// TODO: Make use just contextFormik and not initialize it's own.
export default function MessageEdit({ company, onClose }) {
  const textFieldRef = useRef(null);
  const notification = useNotification();
  const { values } = useFormikContext();
  const INITIAL_FORM_DATA = {
    message:
      values.feedbackMsg ||
      "Kiitos vuokrauksesta!\n\nAnnathan palautetta linkistä:\n[LINKKI]",
  };

  const linkText = `${process.env.REACT_APP_HOSTING_URL_CUSTOMER}#/palaute?reservationId=bca1359d-ef02-4e7c-8f4d-321a6b4cde6e`;
  const maxChars = 160;

  const getMessageCount = (text) => {
    const actualLength = text.length + linkText.length;
    return Math.ceil(Math.max(0, actualLength) / maxChars);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const companyInput = {
        id: company.id,
        feedbackMsg: values.message,
      };

      // Should always have id
      if (company.id) {
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: companyInput,
          })
        );
      }
      onClose(true);
      return;
    } catch (e) {
      captureError("Update company failed", "UPDATE_COMPANY_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.message.includes("[LINKKI]")) {
      errors.message = "Lisää [LINKKI] viestiin.";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate: (values) => validate(values),
    onSubmit,
  });

  const messageCount = getMessageCount(formik.values.message);

  return (
    <>
      <DialogComponent
        open={true}
        onClose={() => onClose(false)}
        maxWidth={"sm"}
        dialogTitle={"Palaute viesti"}
        dialogClose={() => onClose(false)}
        dialogAction={formik.submitForm}
        dialogActionText={"Tallenna"}
        dialogActionSubmitting={formik.isSubmitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <TextField
            label="Viesti"
            variant="outlined"
            multiline
            rows={8}
            fullWidth
            name="message"
            value={formik.values.message}
            inputRef={textFieldRef}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && !!formik.errors.message}
            helperText={formik.touched.message && formik.errors.message}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mt: 1,
            }}
          >
            <Typography
              variant="caption"
              color={messageCount > 1 ? "warning.main" : "text.secondary"}
            >
              Tekstiviestien määrä: {messageCount}
            </Typography>
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="caption" color="text.secondary">
                Linkki tulee olemaan tekstiviestissä muotoa:{" "}
                <span style={{ display: "block", marginTop: "0" }}>
                  {linkText}
                </span>
              </Typography>
            </Box>
          </Box>
        </form>
      </DialogComponent>
    </>
  );
}

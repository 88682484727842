import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";

import { AppContext } from "../../AppContext";
import { SearchResultsCustomers } from "./components/SearchResultsCustomers";

import { useFetchCompanies } from "../../services/fetchCompanies";
import { useMobileViewPort } from "../../utils/mobileViewPort";

import { AiOutlineClear } from "react-icons/ai";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";
import { ReservationsByCustomer } from "./components/ReservationsByCustomer";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";

export default function CustomerSearch(props) {
  const {
    customerSearch,
    editReservationData,
    loading,
    navBoxRef,
    setCustomerSearch,
  } = props;

  const theme = useTheme();

  const { user, setCompany, company, business } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);
  const [queryResults, setQueryResults] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const mobileViewPort = useMobileViewPort(theme);
  const [searchInput, setSearchInput] = useState("");
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const handleClear = () => {
    setSearchInput("");
    setQueryResults([]);
    setReservations([]);
    setSelectedCustomer(null);
  };

  const handleOpenReservation = (reservation) => {
    const legacyCompany = !!reservation?.group;
    const organizationCompany =
      !!reservation?.organizationId ?? user?.organizationId;

    if (legacyCompany) {
      if (!reservation?.reservationVehicles) return;

      setCompany(company);
      setCustomerSearch(false);
      setEditReservationData(reservation);
    }
    if (organizationCompany) {
      setEditReservationData(reservation);
    }
    /*
    Link to a new page: 
    navigate(`/varaus/${reservation.id}`, {
      state: {
        // build support for open customer search, when returning from open reservation
      },
    }); */
  };

  // close the popper if reservation is opened, and return to search when reservation is closed
  useEffect(() => {
    if (!editReservationData) {
      setCustomerSearch(true);
    } else {
      setCustomerSearch(false);
    }
  }, [editReservationData, setCustomerSearch]);

  return (
    <ClickAwayListener onClickAway={() => setCustomerSearch(false)}>
      <Popper
        open={customerSearch}
        anchorEl={navBoxRef.current}
        style={{ zIndex: 1100 }}
      >
        <Paper
          sx={{
            borderRadius: mobileViewPort ? undefined : "14px",
            marginTop: mobileViewPort ? undefined : "20px",
            padding: "15px",
            maxHeight: mobileViewPort ? undefined : "800px",
            overflow: "auto",
            height: mobileViewPort ? "100%" : undefined,
            width: mobileViewPort ? "100vw" : undefined,
            minHeight: mobileViewPort ? undefined : "130px",
            minWidth: mobileViewPort ? undefined : "500px",
          }}
        >
          <Stack spacing={1} width={"100%"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton size="small" onClick={() => setCustomerSearch(false)}>
                <Close style={{ color: "rgba(0,0,0,0.25)" }} />
              </IconButton>
            </Box>

            <Box display={"flex"}>
              <TextField
                inputMode="search"
                placeholder="Asiakashaku"
                variant="standard"
                value={searchInput}
                data-cy="customerSearchField"
                autoFocus={true}
                onChange={(event) => {
                  setSearchInput(event.target.value);

                  if (event.target.value.length > 2) {
                    setSearchLoading(true);
                  }
                  if (event.target.value.length < 2) {
                    setSearchLoading(false);
                  }
                  if (event.target.value.length === 0) {
                    setQueryResults([]);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => handleClear()}>
                        <AiOutlineClear
                          style={{
                            fontSize: 24,
                            cursor: "pointer",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>

            <SearchResultsCustomers
              searchInput={searchInput}
              queryResults={queryResults}
              setSelectedCustomer={setSelectedCustomer}
              setSearchInput={setSearchInput}
              searchLoading={searchLoading}
              reservations={reservations}
              selectedCustomer={selectedCustomer}
            />
            <ReservationsByCustomer
              user={user}
              companies={companies}
              selectedCustomer={selectedCustomer}
              reservations={reservations}
              loading={loading}
              handleOpenReservation={handleOpenReservation}
              searchInput={searchInput}
            />
          </Stack>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}

import React from "react";
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import { CheckCircle, ExpandMore, Info } from "@mui/icons-material";
import { devStaging } from "../features";
import { API, graphqlOperation } from "aws-amplify";
import { innovoiceCreditRequest } from "../graphql/queries";

function DevelopmentHelper() {
  const isDevStaging = React.useMemo(() => {
    return !!devStaging(process.env.REACT_APP_ENV);
  }, []);
  if (!isDevStaging) return null;
  return (
    <Tooltip
      title={
        <Stack>
          <Typography>Testaus:</Typography>
          <Typography>Puoltava: 010150-123A</Typography>
          <Typography>Kielteinen: 010150-123B</Typography>
        </Stack>
      }
    >
      <Info style={{ color: "grey" }} />
    </Tooltip>
  );
}

function CreditStatusText({ granted, message, creditDecisionText }) {
  if (granted === undefined) return null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ display: "inline-block", fontFamily: "Sofia Pro" }}
        >
          {`Rahoituspäätös`}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginLeft: "5px",
            fontFamily: "Sofia Pro",
            display: "inline-block",
            color: granted ? "green" : "red",
          }}
        >
          {creditDecisionText}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontFamily: "Sofia Pro" }}>{message}</Typography>
      </Grid>
    </Grid>
  );
}

function CreditStatusIcon({ creditGranted }) {
  // Creditcheck not done yet
  if (creditGranted === undefined) return null;
  // Credit not granted
  if (!creditGranted) {
    return <CheckCircle color="error" />;
  } else {
    // Credit granted
    return <CheckCircle color="success" />;
  }
}

export default function InnovoiceCredit({ orgBusinessId, initialSsn }) {
  const [ssn, setSsn] = React.useState(initialSsn);
  const [creditGranted, setCreditGranted] = React.useState(undefined);
  const [creditMessage, setCreditMessage] = React.useState("");
  const [creditDecisionText, setCreditDecisionText] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <Accordion sx={{ boxShadow: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        Rahoituskysely
        <CreditStatusIcon creditGranted={creditGranted} />
        <DevelopmentHelper />
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2} justifyContent="center">
          <Stack direction="row">
            <TextField
              label="Henkilötunnus"
              value={ssn}
              onChange={(event) => {
                setSsn(event.target.value);
              }}
            />
            <Button
              disabled={submitting}
              onClick={async () => {
                setSubmitting(true);
                const response = (
                  await API.graphql(
                    graphqlOperation(innovoiceCreditRequest, {
                      orgBusinessId: orgBusinessId,
                      ssn: ssn,
                    })
                  )
                ).data.innovoiceCreditRequest;
                setCreditGranted(response.granted);
                setCreditMessage(response.message);
                setCreditDecisionText(response.creditDecisionText);
                setSubmitting(false);
              }}
            >
              Tee kysely
            </Button>
          </Stack>
          <CreditStatusText
            granted={creditGranted}
            message={creditMessage}
            creditDecisionText={creditDecisionText}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
  // return <Button variant="contained">Rahoituskysely</Button>;
}

import { Box, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import FormikInputText from "~/components/Inputs/FormikInputText";

type CustomerDetailsProps = any;

function CustomerDetails({ innovoiceId }: CustomerDetailsProps) {
  const { values } = useFormikContext<any>();

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 1 }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Asiakkaan tiedot -{" "}
          {values.customer_type === "person" ? "Kuluttaja" : "Yritys"}
        </Typography>

        <Typography
          sx={{
            textAlign: "right",
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          {innovoiceId ? `Innovoice ID: ${innovoiceId}` : ""}
        </Typography>
      </Box>

      {values.customer_type === "person" ? (
        <>
          <Field
            name="firstname"
            label="Etunimi"
            component={FormikInputText}
            required
          />
          <Field
            name="lastname"
            label="Sukunimi"
            component={FormikInputText}
            required
          />
          <Field name="ssn" label="Henkilötunnus" component={FormikInputText} />
        </>
      ) : (
        <>
          <Field
            name="companyName"
            label="Yrityksen nimi"
            component={FormikInputText}
            required
          />
          <Field
            name="companyBusinessId"
            label="Y-tunnus"
            component={FormikInputText}
            required
          />
          <Field
            name="contact_person"
            label="Yhteyshenkilö"
            component={FormikInputText}
          />
        </>
      )}
      <Field
        name="address"
        label="Osoite"
        component={FormikInputText}
        required
      />
      <Field
        name="postalCode"
        label="Postinumero"
        component={FormikInputText}
        required
      />
      <Field
        name="city"
        label="Postitoimipaikka"
        component={FormikInputText}
        required
      />
      <Field name="email" label="Sähköposti" component={FormikInputText} />
      <Field name="phone" label="Puhelin" component={FormikInputText} />
    </Box>
  );
}

export default CustomerDetails;

import { useNavigate, useRouteError } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import captureError from "../utils/capture-error";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function ErrorBoundary() {
  let error = useRouteError();
  const [errorId, setErrorId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Capture error to sentry with unique identifier, which is also shown to user
    console.log("Error received on boundary: ", error);
    const uuid = uuidv4();
    setErrorId(uuid);
    captureError(
      "Uncaught exception ErrorBoundary",
      "ERROR_BOUNDARY",
      error,
      uuid
    );
  }, [error]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "10%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          maxWidth: "550px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <Typography sx={{ marginY: "10px" }}>
          Pahus! Tapahtui jokin virhe!
        </Typography>

        <Button
          variant="contained"
          sx={{ marginY: "20px" }}
          onClick={() => navigate(-1)}
        >
          Takaisin
        </Button>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{ textAlign: "center" }}
        >
          Voitte laittaa tarkemmat tiedot tapahtumasta osoitteeseen{" "}
          <a
            style={{ color: "darkmagenta", textDecoration: "unset" }}
            href="mailto:tuki@orhi.fi"
          >
            tuki@orhi.fi
          </a>
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Odottamaton virhe tapahtui, ja pahoittelemme aiheutunutta haittaa.
          Auttaaksesi meitä tutkimaan ja korjaamaan tämän ongelman nopeasti,
          liitäthän alla olevan virhetunnisteen meille lähettämiisi viesteihin.
        </Typography>
        <Typography variant="caption" sx={{ marginTop: "10px" }}>
          <b>Virhetunniste:</b> {errorId}
        </Typography>
      </Box>
    </Box>
  );
}

import React, { forwardRef, useContext, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { ReservationVehicle } from "~/graphql/API";

//@ts-ignore
import { clientLogoData } from "../../customerLogos/ClientLogoData";
import { format } from "date-fns";
import { fi } from "date-fns/locale";

//@ts-ignore
import { AppContext } from "~/AppContext";
import {
  ECustomerInfo,
  EOrderDetails,
} from "./components/LanguagePack/translatedFields";
import ContractReservationSchedule from "./components/contract-reservation-schedule";
import { titleStyle } from "./components/styles";
import ContractCustomerInformation from "./components/contract-customer-information";
import ContractDriverInformation from "./components/contract-driver-information";
import ContractCompanyCustomerInformation from "./components/contract-company-customer-information";
import ContractAdditionalDriverInformation from "./components/contract-additional-driver-information";
import ContractProduct from "./components/contract-product-information";
import { ContractVehicleDamageMap } from "./components/contract-vehicle-damage-map";
import ContractPostDebit from "./components/contract-post-debit";
import ContractItemsBreakdown from "./components/contract-items-breakdown";
import ContractAgreement from "./components/contract-agreement";
import { ContractAdditionalAgreement } from "./components/contract-additional-information-fields";
import ProductConditionAgreement from "./components/contract-product-condition-agreement";

export const contractFontSize = "12px";

export const ContractTemplatePreview = forwardRef(
  (
    {
      userEntity,
      selectedLanguage,
      contractSettings,
      setContractSettings,
    }: {
      userEntity: string;
      selectedLanguage: string;
      contractSettings: Record<string, string | boolean>;
      setContractSettings: any;
    },
    ref: any
  ) => {
    const reservation = useAtomValue(reservationDataAtom);
    //@ts-ignore
    const shortId = reservation?.shortId;
    const pageHeight = 1122;

    const [
      contractProductsAlternativePosition,
      setContractProductsAlternativePosition,
    ] = React.useState(false);

    useEffect(() => {
      if (contractSettings?.showProductInformation) {
        if (ref?.current?.scrollHeight > pageHeight) {
          setContractProductsAlternativePosition(true);
        }

        if (ref?.current?.scrollHeight < pageHeight) {
          setContractProductsAlternativePosition(false);
        }
      }
    }, [contractSettings?.showProductInformation, ref]);

    return (
      <Stack
        sx={{
          width: "210mm",
          paddingX: "10mm",

          boxSizing: "border-box",
          backgroundColor: "white",
          margin: "auto",
          pageBreakAfter: "always",
          breakAfter: "always",
        }}
        justifyContent={"space-between"}
        spacing={1}
        ref={ref}
      >
        <Box
          sx={{
            height: pageHeight,
            paddingTop: "5mm",
            paddingBottom: "2mm",
          }}
        >
          <Stack
            direction={"column"}
            justifyContent={"space-between"}
            height={"100%"}
          >
            <Box>
              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                height={"50px"}
              >
                <Box display={"flex"} flex={1}>
                  <Logo userEntity={userEntity} />
                </Box>
                <Box display={"flex"} flex={1} justifyContent={"center"}>
                  <Typography align="center" sx={{ fontWeight: 550 }}>
                    {
                      EOrderDetails[
                        selectedLanguage as keyof typeof EOrderDetails
                      ].CONTRACT_TITLE
                    }
                  </Typography>
                </Box>
                <Box display={"flex"} flex={1}></Box>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack
                  direction={"row"}
                  justifyContent={shortId ? "space-between" : "flex-end"}
                  flex={1}
                  height={"18px"}
                >
                  {shortId && (
                    <Typography
                      sx={{
                        fontSize: contractFontSize,
                      }}
                    >
                      {
                        EOrderDetails[
                          selectedLanguage as keyof typeof EOrderDetails
                        ].RESERVATION_NUMBER
                      }{" "}
                      <>{shortId}</>
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: contractFontSize,
                    }}
                  >
                    {format(new Date(), "dd.MM.yyyy", { locale: fi })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
                direction={"row"}
              >
                <ServiceProviderCompanyInfo />

                <ContractReservationSchedule
                  selectedLanguage={selectedLanguage}
                  contractSettings={contractSettings}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography sx={titleStyle}>
                  {
                    ECustomerInfo[
                      selectedLanguage as keyof typeof ECustomerInfo
                    ].TITLE
                  }
                </Typography>

                <Stack direction={"row"} spacing={1}>
                  <ContractCustomerInformation
                    selectedLanguage={selectedLanguage}
                  />

                  <ContractDriverInformation
                    visibility={!!contractSettings.showDriverFields}
                    selectedLanguage={selectedLanguage}
                  />
                </Stack>

                <ContractCompanyCustomerInformation
                  visibility={!!contractSettings.showCompanyCustomerFields} //reservation?.isCompany
                  selectedLanguage={selectedLanguage}
                />
                <ContractAdditionalDriverInformation
                  visibility={!!contractSettings?.showAdditionalDriver}
                  selectedLanguage={selectedLanguage}
                  contractSettings={contractSettings}
                />
                {contractSettings?.showProductInformation &&
                  !contractProductsAlternativePosition && (
                    <Stack display={"flex"}>
                      <ContractProducts
                        selectedLanguage={selectedLanguage}
                        contractSettings={contractSettings}
                      />
                    </Stack>
                  )}
              </Stack>
              <ContractPostDebit
                selectedLanguage={selectedLanguage}
                contractSettings={contractSettings}
              />
              <ContractItemsBreakdown
                selectedLanguage={selectedLanguage}
                contractSettings={contractSettings}
              />
              {/* 
       Disabled due to missing data
       <ContractPaymentStatus
          contractSettings={contractSettings}
          selectedLanguage={selectedLanguage}
        /> */}
            </Box>
            <Box sx={{ paddingBottom: "10px" }}>
              <Stack spacing={1} justifyContent={"flex-end"}>
                <ContractAgreement
                  selectedLanguage={selectedLanguage}
                  contractSettings={contractSettings}
                />
                <ContractAdditionalAgreement
                  contractSettings={contractSettings}
                  setContractSettings={setContractSettings}
                  selectedLanguage={selectedLanguage}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
        {contractSettings?.showProductInformation &&
          contractProductsAlternativePosition && (
            <Stack sx={{ breakBefore: "always" }} spacing={1}>
              <ContractProducts
                selectedLanguage={selectedLanguage}
                contractSettings={contractSettings}
              />
              <ProductConditionAgreement selectedLanguage={selectedLanguage} />
            </Stack>
          )}
      </Stack>
    );
  }
);

function ContractProducts({
  selectedLanguage,
  contractSettings,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const reservation = useAtomValue(reservationDataAtom);
  //@ts-ignore
  const products = reservation?.reservationVehicles;

  return products
    ? products?.map((item: ReservationVehicle) => (
        <Stack key={item.id} direction={"row"}>
          <ContractProduct
            item={item}
            selectedLanguage={selectedLanguage}
            contractSettings={contractSettings}
          />
          {contractSettings.showVehicleDamagesMap && (
            <>
              <ContractVehicleDamageMap
                vehicle={item}
                selectedLanguage={selectedLanguage}
                contractSettings={contractSettings}
              />
            </>
          )}
        </Stack>
      ))
    : null;
}

function Logo({ userEntity }: { userEntity: string }) {
  const logo = clientLogoData.find((item: any) => item.id === userEntity)?.logo;
  if (!logo) {
    return <Box />;
  }
  return <img alt={"logo"} src={logo} style={{ width: "200px" }} />;
}

const ServiceProviderCompanyInfo = () => {
  const { company } = useContext<any>(AppContext);

  if (!company) return <Box flex={0.5} />;
  return (
    <Stack flex={0.5}>
      <Typography sx={{ fontWeight: 550, fontSize: contractFontSize }}>
        {company.name}
      </Typography>
      <Typography sx={{ fontSize: contractFontSize }}>
        {company.address} {company.postalCode} {company.city}
      </Typography>
      <Typography sx={{ fontSize: contractFontSize }}>
        {company.phone}
      </Typography>
      <Typography sx={{ fontSize: contractFontSize }}>
        {company.businessId}
      </Typography>
    </Stack>
  );
};

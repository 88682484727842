import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";

export function FormattedDate({ date }) {
  //Ke 12.12.2020
  const str = format(date, "eeeeee dd.MM.yyyy", {
    locale: fi,
  });
  return str[0].toUpperCase() + str.slice(1);
}

export function FormattedTime({ date }) {
  //Klo. 16.30
  return (
    <>
      Klo.{" "}
      {format(date, "HH.mm", {
        locale: fi,
      })}
    </>
  );
}

// Get Date based on timezone
export function getZonedTime(date, tz) {
  if (!date) return null;
  let zonedTime = utcToZonedTime(date, tz);
  if (zonedTime instanceof Date && isNaN(zonedTime)) {
    console.error("utcToZonedTime failed, use browser TZ instead", date, tz);
    zonedTime = new Date(date);
  }
  return zonedTime;
}

export const localeText = {
  fieldMonthPlaceholder: (params) =>
    params.contentType === "letter" ? "YYYY" : "KK",
  fieldDayPlaceholder: () => "PP",
  fieldYearPlaceholder: (params) => "V".repeat(params.digitAmount),
};

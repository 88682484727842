import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useField } from "formik";
import { useCategoryImage } from "~/hooks/useCategoryImage";
import { centsToLocalString } from "../../../../../shared/money";
import { Extension } from "@mui/icons-material";

export default function ServiceOfferMenuItem(props) {
  const { setAnchorEl, offer } = props;
  const { image } = useCategoryImage(offer);
  const [, meta, helpers] = useField("additionalServices");
  const { setValue } = helpers;
  const { value } = meta;
  const theme = useTheme();
  return (
    <MenuItem
      onClick={() => {
        setAnchorEl(null);
        setValue([...value, offer]);
      }}
    >
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          src={image}
          sx={{ bgcolor: !image && theme.palette.primary.main }}
        >
          <Extension />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={offer?.description ?? "Lisäpalvelu"}
        secondary={`${centsToLocalString(offer?.offerPrice)} €`}
      />
    </MenuItem>
  );
}

import {
  ClickAwayListener,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import { contextMenuAtom } from "./store";
import { MdMoveUp } from "react-icons/md";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { useCreateReservationFromContext } from "./hooks/useCreateReservationFromContext";
import { moveReservationAtom } from "../../hooks/useMoveReservation";

function ContextMenu() {
  const theme = useTheme();

  const createReservation = useCreateReservationFromContext();
  const setMoveReservation = useSetAtom(moveReservationAtom);

  const [contextMenu, setContextMenu] = useAtom(contextMenuAtom);
  const vehicleRowRef = useRef<null | HTMLElement>(null);
  const [data, setData] = useState(contextMenu);

  function handleClose() {
    setContextMenu(null);
  }

  useEffect(() => {
    if (contextMenu) {
      setData(contextMenu);
      vehicleRowRef.current = contextMenu.target.parentElement || null;
    } else {
      vehicleRowRef.current = null;
    }
  }, [contextMenu]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClose}>
      <Menu
        open={!!contextMenu}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu?.pos}
        PaperProps={{
          style: {
            pointerEvents: "auto",
            minWidth: "100px",
            backgroundColor: theme.palette.primary.drawerBackground,
            boxShadow: theme.shadows[0],
            border: `1px solid ${theme.palette.primary.main}`,
          },
        }}
        classes={{ root: styles.root }}
      >
        {data?.reservation && (
          <MenuItem
            onClick={() => {
              setMoveReservation({
                reservation: data.reservation!,
                vehicle: data.vehicle,
              });
              handleClose();
            }}
          >
            <MdMoveUp
              style={{
                fontSize: "26px",
                marginRight: "5px",
                color: theme.palette.primary.main,
              }}
            />
            <Typography>Siirrä varaus tuotteelle</Typography>
          </MenuItem>
        )}

        {data?.date && (
          <MenuItem
            onClick={() => {
              createReservation({
                date: data.date,
                vehicle: { ...data.vehicle, deductible: undefined },
              });
              handleClose();
            }}
          >
            <NoteAddIcon
              sx={{
                fontSize: "26px",
                marginRight: "5px",
                color: theme.palette.primary.main,
              }}
            />
            <Typography>Luo uusi varaus</Typography>
          </MenuItem>
        )}
      </Menu>
    </ClickAwayListener>
  );
}

export default ContextMenu;

import { getReservationOffersAdmin } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { useReservationContext } from "./ReservationContext";
import useSWR from "swr";
import { useMemo, useState, useEffect } from "react";

export function useGetOfferData(startTime, returnTime) {
  const {
    queryCompanies,
    disableCategoryDefaultsStacking,
    queryResource,
    selectedOffers,
  } = useReservationContext();

  let fetcher = null;
  const key =
    queryCompanies && startTime && returnTime && queryResource
      ? [
          queryCompanies,
          startTime,
          returnTime,
          queryResource,
          disableCategoryDefaultsStacking,
        ]
      : null;
  fetcher = () =>
    fetchGetReservationOffers(
      queryCompanies,
      startTime,
      returnTime,
      disableCategoryDefaultsStacking,
      queryResource
    );

  const { data, error, isLoading } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    //  revalidateIfStale: false,
  });

  const vehicleData = useMemo(() => {
    const productsFromQuery = data
      ? data
          .filter(
            (o) =>
              !selectedOffers.find(
                (selectedOffer) => selectedOffer?.id === o.vehicle.id
              )
          )
          .map((offer, idx) => {
            return {
              key: offer?.vehicle?.id,
              selected: false,
              id: offer?.vehicle?.id,
              vehicleName: offer?.vehicle?.name,
              listingPrice: parseInt(offer?.vehicleOfferPrice),
              categoryName: offer?.vehicle?.category?.name,
              registrationPlate: offer?.vehicle?.registrationPlate,
              company: offer?.vehicle?.companyId,
              price: null,
              /*    deductible: offerPrices?.vehicleOfferPrices?.find(
            (vehicle) => offer?.id === vehicle.id
          )?.deductible, */
              ...offer.vehicle,
              // -----------------
            };
          })
      : [];

    const productsFromData =
      selectedOffers?.length > 0
        ? selectedOffers.map((vehicle, index) => {
            /*           const getPrice = () => {
              if (affectivePrice) {
                // handle price by category: is an object and the same price goes for all the selected offers
                if (affectivePrice?.priceBy === "category") {
                  return affectivePrice?.value?.price;
                }
                // handle price by vehicle id: is an array and price is searched by an id
                if (affectivePrice?.priceBy === "vehicleIds") {
                  const price = affectivePrice?.value.find(
                    (offer) => offer.id === vehicle.id
                  )?.price;
                  return price;
                }
              } else return undefined;
            }; */
            return {
              ...vehicle,
              key: vehicle?.id ?? `no-vehicle-id${index}`,
              selected: true,
              id: vehicle?.id ?? `no-vehicle-id${index}`,
              vehicleName: vehicle?.name,
              modifiedPrice: vehicle?.modifiedPrice,
              categoryName: vehicle?.category?.name,
              registrationPlate: vehicle?.registrationPlate,
              company: vehicle?.companyId,
              listingPrice: vehicle?.listingPrice,
              /*     listingPrice: offerPrices?.vehicleOfferPrices?.find(
                (offer) => offer?.id === vehicle.id
              )?.price, */
            };
          })
        : [];

    return [...productsFromData, ...productsFromQuery].sort(function (a, b) {
      if (a.categoryName < b.categoryName) {
        return -1;
      }
      if (a.categoryName > b.categoryName) {
        return 1;
      }
      return 0;
    });
  }, [selectedOffers, data]);

  return {
    offersData: vehicleData ? vehicleData : [],
    offersDataLoading: isLoading,
    offersDataError: error,
  };
}

const fetchGetReservationOffers = async (
  companyIds,
  startTime,
  returnTime,
  disableCategoryDefaultsStacking,
  queryResource
) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getReservationOffersAdmin, {
        companyIds,
        startTime: startTime.toJSON(),
        returnTime: returnTime.toJSON(),
        disableCategoryDefaultsStacking: disableCategoryDefaultsStacking,
        group: queryResource?.group,
        businessId: queryResource?.orgBusinessId,
        externalBusinessId: queryResource?.extBusinessId,
      })
    );

    const result = response.data.getReservationOffersAdmin.map((r) => ({
      ...r,
      startTime: new Date(r.startTime),
      returnTime: new Date(r.returnTime),
      freeTimes: r.freeTimes.map((f) => ({
        firstStartTime: new Date(f.firstStartTime),
        lastStartTime: new Date(f.lastStartTime),
        lastReturnTime: new Date(f.lastReturnTime),
      })),
      vehicle: {
        ...r.vehicle,
        availability: true,
        price: r.vehicleOfferPrice,
        additionalServiceOffers: r.additionalServiceOffers,
      },
    }));

    return result || [];
  } catch (error) {
    console.error("Failed to fetch reservation offers:", error);
    throw error;
  }
};

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { fi } from "date-fns/locale";
import { format } from "date-fns";
import ExternalDataList from "../../ExternalDataList";
import InsuranceDataList from "../common/InsuranceDataList";
import ReservationProducts from "../common/ReservationProducts";
import ReservationSchedule from "../common/ReservationSchedule";
import ReservedProductCard from "../components/ReservedProductCard/";
import WildcardVehicle from "../common/WildcardVehicle";

import { centsToLocalString } from "../../../shared/money";

import { AppContext } from "../../../AppContext";
import { FormContent, FormGrid } from "../../common/FormComponents";
import { useCategories } from "~/queries/useCategories";
import { useFormikContext } from "formik";
import { useGetWildCardPrice } from "../common/reservationCustomHooks";
import { useReservationContext } from "../ReservationContext";

import ReservationPriceField from "../common/ReservationPriceField";
import ProductImage from "../../ProductImage";
import TruncatedTypography from "../../TruncatedTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import { getEffectiveOfferPrice } from "../common/utils";
import { externalUserHanselClasses } from "./hanselClasses";
import {
  AutoCompleteComponent,
  CustomMenuItem,
  findItemById,
} from "../common/Components";
import { useFetchExternalBusinessPricings } from "../../../services/fetchPricings";
import { useFetchCompanies } from "../../../services/fetchCompanies";
import { useGetOfferData } from "../useGetOfferData";
import DeductiblePriceField from "../components/ReservedProductCard/DeductiblePriceField";

export default function ReservationSummary() {
  const {
    mobileViewPort,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    user,
    business,
  } = useContext(AppContext);
  const {
    FEATURE_ENABLE_CONTRACT_CUSTOMER,
    FEATURE_ENABLE_WILDCARD_RESERVATIONS,
    reservationData,
    selectedOffers,
    startTime,
    returnTime,
    modifyEndMileage,
    modifyStartMileage,
    addNewOffer,
    setVehicleIds,
  } = useReservationContext();

  const { offersData, offersDataLoading } = useGetOfferData(
    startTime,
    returnTime
  );

  const prevVehicleIdRef = useRef();

  useEffect(() => {
    const currentVehicleId = reservationData?.contextVehicle?.id;

    if (
      !offersDataLoading &&
      offersData.length &&
      currentVehicleId !== prevVehicleIdRef.current
    ) {
      const selectedOffer = offersData.find(
        (offer) => offer.id === currentVehicleId
      );

      // If a matching offer is found, add it. Otherwise, add the contextVehicle as a fallback.
      if (selectedOffer) {
        addNewOffer([selectedOffer]);
      } else if (reservationData?.contextVehicle) {
        addNewOffer([reservationData?.contextVehicle]);
      }

      // Update the ref to the current vehicle ID to prevent repetitive additions.
      prevVehicleIdRef.current = currentVehicleId;

      setVehicleIds([currentVehicleId]);
    }
  }, [
    reservationData?.contextVehicle,
    offersData,
    offersDataLoading,
    addNewOffer,
    setVehicleIds,
  ]);

  const { companies } = useFetchCompanies(user?.group, business?.id);
  const { values, setFieldValue, handleBlur } = useFormikContext();
  const [showEditProducts, setShowEditProducts] = useState(() => {
    if (USER_ADMIN_CORPORATE) {
      if (reservationData?.id) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  });
  const handleChangeViewButtonVisibility = () => {
    if (USER_ADMIN_CORPORATE) {
      return false;
    }
    return true;
  };

  // The custom hook handles the selected vehicle class price
  useGetWildCardPrice(values?.enableWildCard);

  return (
    <Grid item container xs={12} sx={{ justifyContent: "center" }}>
      <Grid item xs={12}>
        <FormContent
          visibility={USER_ADMIN_CORPORATE || USER_ADMIN_GENERIC}
          title={"Varauksen ajankohta"}
        >
          <FormGrid>
            <ReservationSchedule />
          </FormGrid>
        </FormContent>
      </Grid>
      <Grid item xs={12}>
        <FormContent
          visibility={true}
          title={"Varauksen nouto- ja palautuspiste"}
        >
          {USER_ADMIN_CORPORATE && (
            <FormGrid>
              <ExternalUsersSelectPickupLocation />
            </FormGrid>
          )}

          <FormGrid>
            <Stack spacing={2} sx={{ marginTop: "10px", width: "100%" }}>
              <AlternativeLocationSelect visibility={USER_ADMIN_GENERIC} />
            </Stack>
          </FormGrid>
        </FormContent>
      </Grid>
      <Grid item xs={12}>
        <FormContent
          title={"Varauksen tuotteet"}
          visibility={USER_ADMIN_CORPORATE || USER_ADMIN_GENERIC}
          testName="showProductInfo"
        >
          <ChangeViewButton
            showEditProducts={showEditProducts}
            setShowEditProducts={setShowEditProducts}
            visibility={handleChangeViewButtonVisibility()}
          />

          <Grid
            container
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
          >
            {showEditProducts && (
              <>
                <SelectReservationType
                  visibility={
                    FEATURE_ENABLE_WILDCARD_RESERVATIONS && USER_ADMIN_GENERIC
                  }
                />
                {/*     <SelectProvider hasErrors={hasErrors} /> */}
                <ExternalUserSelectVehicleClass
                  // vehicle class picker for external business users
                  visibility={
                    values?.enableWildCard &&
                    USER_ADMIN_CORPORATE &&
                    showEditProducts
                  }
                />
                <BusinessSelectVehicleClass
                  // vehicle class picker for business users
                  visibility={
                    values?.enableWildCard &&
                    USER_ADMIN_GENERIC &&
                    showEditProducts
                  }
                />
                <ContractCustomerProvider
                  visibility={FEATURE_ENABLE_CONTRACT_CUSTOMER}
                />
              </>
            )}
            <ReservationOffers visibility={!showEditProducts} />

            {showEditProducts && (
              <ReservationProducts
                showEditProducts={showEditProducts}
                offersData={offersData}
                offersDataLoading={offersDataLoading}
              />
            )}
          </Grid>
        </FormContent>
      </Grid>

      <Grid item xs={12}>
        <FormContent
          title={"Lisätiedot"}
          visibility={USER_ADMIN_GENERIC && reservationData}
        >
          <FormGrid
            sx={{
              justifyContent: "flex-start",
              flexDirection: "row",
            }}
          >
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
                  Luontipäivä:
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {reservationData?.createdAt &&
                    format(
                      new Date(reservationData?.createdAt),
                      "dd.MM HH:mm",
                      {
                        locale: fi,
                      }
                    )}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              width={"100%"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
                  Varauksen toimipiste:
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {companies &&
                    companies.find((c) => c.id === reservationData?.companyId)
                      ?.name}
                </Typography>
              </Box>
            </Stack>
            <ExternalDataList />

            <Stack width={"100%"} sx={{ marginTop: "10px" }}>
              <Divider flexItem sx={{ marginY: "10px" }} />
              {selectedOffers.map((offer) => (
                <Stack
                  key={offer?.id}
                  direction={"row"}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Stack direction={"column"}>
                    <Typography>{offer?.name}</Typography>
                    <Typography>
                      <b>{offer?.registrationPlate}</b>
                    </Typography>
                  </Stack>
                  <Stack
                    direction={mobileViewPort ? "column" : "row"}
                    spacing={1}
                  >
                    <Box>
                      <TextField
                        value={offer?.startMileage ?? ""}
                        onChange={(e) => {
                          modifyStartMileage(offer?.id, e.target.value);
                          if (values.type === "HANSEL") {
                            setFieldValue(
                              "hanselData.additionalInfo1",
                              offer?.endMileage - e.target.value
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        size="small"
                        name="startMileage"
                        label="Aloitus kilometrit"
                        variant="outlined"
                        fullWidth
                        data-cy="reservedProductStartMileage"
                      />
                    </Box>
                    <Box>
                      <TextField
                        value={offer?.endMileage ?? ""}
                        onChange={(e) => {
                          modifyEndMileage(offer?.id, e.target.value);
                          if (values.type === "HANSEL") {
                            setFieldValue(
                              "hanselData.additionalInfo1",
                              e.target.value - offer?.startMileage
                            );
                          }
                        }}
                        onBlur={handleBlur}
                        size="small"
                        name="endMileage"
                        label="Lopetus kilometrit"
                        variant="outlined"
                        fullWidth
                        data-cy="reservedProductEndMileage"
                      />
                    </Box>
                  </Stack>
                </Stack>
              ))}
              <Divider flexItem sx={{ marginY: "10px" }} />
            </Stack>
            {reservationData?.insuranceData && (
              <InsuranceDataList visibility={true} />
            )}
            <WildcardVehicle visibility={reservationData?.wildCardVehicles} />
            {values.type === "HANSEL" && (
              <Grid
                item
                container
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
                    Valittu hansel luokka:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography>
                    {values?.initialClassInfo?.hanselClass}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </FormGrid>
        </FormContent>
      </Grid>
    </Grid>
  );
}

/* function SelectBusiness(props) {
  const { visibility, hasErrors } = props;
  const { user } = useContext(AppContext);
  const {
    selectedBusiness,
    setQueryResource,
    setQueryCompanies,
    setSelectedBusiness,
  } = useReservationContext();
  const theme = useTheme();
  const businessByOrg = user?.businessByOrg;
  const handleChangeBusiness = (value) => {
    setQueryResource((prevState) => ({ ...prevState, orgBusinessId: value }));
    setSelectedBusiness(value);
    if (businessByOrg) {
      setQueryCompanies([]);
    }
  };
  if (visibility)
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-franchising-label">
            Valitse franchising-yrittäjä
          </InputLabel>
          <Select
            labelId="select-franchising-label"
            id="select-franchising"
            label="Valitse franchising-yrittäjä"
            value={selectedBusiness || ""}
            onChange={(e) => handleChangeBusiness(e.target.value)}
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {businessByOrg.items.map((business) => (
              <MenuItem value={business?.id} key={business?.id}>
                {business?.name}
              </MenuItem>
            ))}
          </Select>
          {Array.isArray(hasErrors) &&
            hasErrors?.includes("select-franchising") && (
              <FormHelperText sx={{ color: theme.palette.warning.main }}>
                Valitse franchising-yrittäjä
              </FormHelperText>
            )}
        </FormControl>
      </Grid>
    );
} */

function SelectContractCustomer(props) {
  const { visibility } = props;
  const { user } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();
  const listOfCustomers = user?.externalBusinessByOrg?.items.sort((a, b) =>
    a?.name.toString().localeCompare(b?.name.toString())
  );
  const {
    queryResource,
    setQueryResource,
    reservationData,
    setSelectedContractVehicleClass,
  } = useReservationContext();

  if (visibility)
    return (
      <FormControl fullWidth>
        <InputLabel id="select-franchising-label">
          Valitse sopimushinta-asiakas
        </InputLabel>
        <Select
          // disabled={!enableContractCustomer}
          labelId="select-franchising-label"
          id="select-franchising"
          label=" Valitse sopimushinta-asiakas"
          value={queryResource?.extBusinessId || ""}
          disabled={
            // disable select component for external reservations from LÄHITAPIOLA
            reservationData?.type === "INSURANCE" &&
            reservationData?.orgExternalBusinessId ===
              "77010970-14c1-4936-9a41-3054522a56a8"
              ? true
              : false
          }
          onChange={(e) => {
            setQueryResource((prevState) => ({
              ...prevState,
              extBusinessId: e.target.value,
            }));
            setFieldValue("orgExternalBusinessId", e.target.value);
            setSelectedContractVehicleClass(null);
          }}
          MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
        >
          <MenuItem value={null}>
            <b>Ei asiakasta</b>
          </MenuItem>
          {listOfCustomers &&
            listOfCustomers.map((customer) => (
              <MenuItem value={customer?.id} key={customer?.id}>
                {customer?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}
function SelectContractVehicleClass(props) {
  const {
    selectedContractVehicleClass,
    setSelectedContractVehicleClass,
    queryResource,
  } = useReservationContext();

  const { externalBusinessPricings } = useFetchExternalBusinessPricings(
    queryResource?.extBusinessId
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="select-franchising-label">
        Valitse sopimushinta-autoluokka
      </InputLabel>
      <Select
        // disabled={!enableContractCustomer}
        labelId="select-contract-vehicle-class-label"
        id="select-contract-vehicle"
        label=" Valitse sopimushinta-autoluokka"
        value={selectedContractVehicleClass || ""}
        onChange={(e) => setSelectedContractVehicleClass(e.target.value)}
        MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
      >
        <MenuItem value={null}>
          <b>Ei valittua autoluokkaa</b>
        </MenuItem>
        {externalBusinessPricings &&
          externalBusinessPricings.map((c) => (
            <MenuItem
              key={`vehicle-class-type-${c.id}`}
              value={c.externalBusinessCategoryAssociation} // pick only the first letter from the type, there is few exeptions
              data-cy="reservationDialogVehicleClassOption"
              selected={c?.id === selectedContractVehicleClass}
            >
              {c.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

function WildCard(props) {
  const { offer } = props;
  const { USER_ADMIN_CORPORATE } = useContext(AppContext);
  const { values } = useFormikContext();
  // const isBundle = offer?.availableInCategory > 0 ? true : false;
  if (USER_ADMIN_CORPORATE) {
    const label = values?.type === "HANSEL" ? "Hansel luokka" : "Autoluokka";
    const vehicleName = offer?.name;
    const value = values?.hanselData?.class ?? "";

    return (
      <FormGrid>
        <Card sx={{ minWidth: "100%" }}>
          <CardContent>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Avatar
                  // key={`${vehicle.id}-res-vehicle-image-${idx}-${index}`}
                  variant="rounded"
                  sx={{
                    height: "75px",
                    width: "75px",
                    marginRight: "10px",
                    bgcolor: "#ffffff",
                    color: "rgba(0,0,0,0.35)",
                    boxShadow: "none",
                    border: "1px solid #eaeaea",
                    borderRadius: "12px",
                    padding: "7px",
                  }}
                ></Avatar>

                <Stack direction={"column"}>
                  <Typography>{vehicleName ?? label}</Typography>
                  <Typography>{value}</Typography>
                  <Typography variant="caption" sx={{ color: "#a9a9a9" }}>
                    {offer?.registrationPlate ?? ""}
                  </Typography>
                </Stack>
              </Stack>
              <Typography sx={{ fontFamily: "Sofia Pro" }} variant="h6">
                {centsToLocalString(offer.price)} €
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </FormGrid>
    );
  }
  return <ProductInfo offer={offer} />;
}

const ProductInfo = (props) => {
  const { offer } = props;
  const { mobileViewPort, USER_ADMIN_GENERIC } = useContext(AppContext);
  const { modifyOfferPrice, removeOffer, setVehicleIds, vehicleIds } =
    useReservationContext();
  const { setFieldValue } = useFormikContext();
  const [expandedView, setExpandedView] = useState(false);
  const theme = useTheme();
  const price = getEffectiveOfferPrice(offer);
  return (
    <Accordion
      sx={{ width: "100%", boxShadow: theme.shadows[6] }}
      onChange={(e, expanded) => {
        setExpandedView(expanded);
      }}
      square
    >
      <AccordionSummary>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%" }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <ProductImage
              vehicleId={offer?.id}
              imageSize={mobileViewPort ? "large" : undefined}
              size={mobileViewPort ? 80 : 72}
            />

            <Stack direction={"column"}>
              <TruncatedTypography text={offer?.name + " - luokan varaus"} />
              <Typography variant="caption" sx={{ color: "#a9a9a9" }}>
                {offer?.registrationPlate ?? ""}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {!expandedView && (
              <Typography sx={{ fontFamily: "Sofia Pro" }} variant="h6">
                {centsToLocalString(price)} €
              </Typography>
            )}

            {expandedView && (
              <IconButton
                onClick={() => {
                  removeOffer(offer?.id);
                  setFieldValue(
                    "vehicleIds",
                    vehicleIds.filter((id) => id !== offer?.id)
                  );
                  setVehicleIds((prevState) => {
                    return prevState.filter((id) => id !== offer?.id);
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
      </AccordionSummary>
      {USER_ADMIN_GENERIC && (
        <AccordionDetails>
          <Stack
            spacing={1}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
          >
            <Stack direction={"row"} alignItems={"flex-end"}>
              <Box maxWidth={"180px"}>
                <ReservationPriceField
                  offer={offer}
                  name={"vehiclePrice-" + offer?.id}
                  //  isDefaultPriceLoading={defaultPrice === undefined}
                  size="small"
                  key={offer?.id}
                  enableQuantity={!!offer?.enableQuantity}
                  vehicleQuantity={offer?.quantity}
                  priceModifier={modifyOfferPrice}
                />
              </Box>
            </Stack>
            <Box maxWidth={"180px"}>
              <DeductiblePriceField offer={offer} />
            </Box>
          </Stack>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

/* function CustomMenuItem(props) {
  return <MenuItem {...props}>testing</MenuItem>;
}
 */

const AlternativeLocationSelect = (props) => {
  const { mobileViewPort, business, user } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);
  const { values, errors, setFieldValue } = useFormikContext();
  const { visibility } = props;

  const combinedLocations = useMemo(() => {
    let result = [];
    if (!companies) {
      return null;
    }
    for (let company of companies) {
      result.push(company);
      if (business?.pickupLocations) {
        for (let location of business?.pickupLocations) {
          if (location?.companyId === company.id) {
            result.push(location);
          }
        }
      }
    }
    return result;
  }, [companies, business]);
  if (visibility)
    return (
      <Stack
        direction={mobileViewPort ? "column" : "row"}
        width={"100%"}
        spacing={2}
      >
        <Box width={mobileViewPort ? "100%" : "50%"}>
          <AutoCompleteComponent
            defaultOptions={combinedLocations ?? []}
            label={"Valitse noutopiste"}
            type={"location"}
            value={values?.startLocation}
            setValue={(value) => setFieldValue("startLocation", value)} // used for input change
            onChange={async (event, newValue) => {
              const companyId = () => {
                // pickup locations have companyId, companies have their own id

                if (newValue?.companyId) {
                  return newValue?.companyId;
                } else if (newValue?.id) {
                  return newValue?.id;
                }

                // handle user input location company id
                return combinedLocations[0]?.id;
              };
              await setFieldValue("startLocation", newValue?.name);
              await setFieldValue("startLocationID", newValue?.id);
              await setFieldValue("companyId", companyId());
            }}
            freeSolo={true}
            errors={errors}
          />
        </Box>
        <Box width={mobileViewPort ? "100%" : "50%"}>
          <AutoCompleteComponent
            defaultOptions={combinedLocations ?? []}
            label={"Valitse palautuspiste"}
            type={"location"}
            value={values?.returnLocation}
            setValue={(value) => setFieldValue("returnLocation", value)} // used for input change
            onChange={async (event, newValue) => {
              await setFieldValue("returnLocation", newValue?.name);
              await setFieldValue("returnLocationID", newValue?.id);
            }}
            freeSolo={true}
          />
        </Box>
      </Stack>
    );
};

function ExternalUsersSelectPickupLocation() {
  const { setQueryResource, queryResource, setQueryCompanies, queryCompanies } =
    useReservationContext();
  const { user, mobileViewPort } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();
  const availableStartLocations = useMemo(() => {
    let availableLocations = [];

    user?.externalBusiness?.availableCompanyIds.forEach((locationId) => {
      const locationData = findItemById(user, locationId);
      if (locationData) {
        availableLocations.push(locationData);
      }
    });

    return availableLocations;
  }, [user]);

  const availableReturnLocations = useMemo(() => {
    const availableLocations = availableStartLocations.filter(
      (location) => location?.orgBusinessId === queryResource?.orgBusinessId
    );
    return availableLocations;
  }, [availableStartLocations, queryResource]);

  const { values, errors } = useFormikContext();
  const handleSelectStartLocation = async (e, newValue) => {
    const companyId = () => {
      // pickup locations have companyId, companies have their own id
      if (newValue?.companyId) {
        return newValue?.companyId;
      } else if (newValue?.id) {
        return newValue?.id;
      }

      // handle user input location company id
      return availableStartLocations[0]?.id;
    };
    setQueryResource((prevState) => ({
      extBusinessId: user?.externalBusinessId,
      orgBusinessId: newValue?.orgBusinessId,
    }));
    await setFieldValue("startLocation", newValue?.name);
    await setFieldValue("startLocationID", companyId());
    await setFieldValue("companyId", companyId());
    setQueryCompanies([companyId()]);

    await setFieldValue("companyId", companyId());
  };
  const handleSelectReturnLocation = async (e, newValue) => {
    await setFieldValue("returnLocation", newValue?.name);
    await setFieldValue("returnLocationID", newValue?.id);
  };

  // set value for queryCompanies if startlocation is set from input value
  useEffect(() => {
    if (!queryCompanies || queryCompanies?.length === 0) {
      setQueryCompanies([user?.externalBusiness?.availableCompanyIds[0]]);
    }
  }, [
    queryCompanies,
    setQueryCompanies,
    user?.externalBusiness?.availableCompanyIds,
  ]);

  return (
    <Stack
      direction={mobileViewPort ? "column" : "row"}
      width={"100%"}
      spacing={2}
    >
      <Box width={mobileViewPort ? "100%" : "50%"}>
        <AutoCompleteComponent
          defaultOptions={availableStartLocations}
          label={"Valitse noutopiste"}
          type={"location"}
          value={values?.startLocation}
          setValue={(value) => setFieldValue("startLocation", value)} // used for input change
          onChange={handleSelectStartLocation}
          freeSolo={true}
          error={!!errors?.startLocation}
          errorHelperText={errors?.startLocation}
        />
      </Box>
      <Box width={mobileViewPort ? "100%" : "50%"}>
        <AutoCompleteComponent
          defaultOptions={availableReturnLocations}
          label={"Valitse palautuspiste"}
          type={"location"}
          value={values.returnLocation}
          setValue={(value) => setFieldValue("returnLocation", value)} // used for input change
          onChange={handleSelectReturnLocation}
          freeSolo={true}
          error={!!errors?.returnLocation}
          errorHelperText={errors?.returnLocation}
        />
      </Box>
    </Stack>
  );
}

function ExternalUserSelectVehicleClass(props) {
  const { visibility } = props;

  const categories = useCategories();
  const { user } = useContext(AppContext);
  const { values, setValues } = useFormikContext();
  const theme = useTheme();

  const selectedVehicleClass = values.selectedVehicleClass;
  const handleChange = (e) => {
    const category = categories.find((c) => c.id === e.target.value);
    const hanselClass = externalUserHanselClasses?.find((hanselClass) =>
      hanselClass.vehicleClasses.includes(category?.name)
    );
    setValues({
      ...values,
      selectedVehicleClass: e.target.value,

      hanselData:
        values?.type === "HANSEL"
          ? { ...values.hanselData, hanselClass: hanselClass?.class }
          : null,
      initialClassInfo: {
        categoryId: category?.id,
        categoryName: category?.name,
        hanselClass: hanselClass?.class,
      },
    });
  };
  const listCategories = useMemo(() => {
    if (values.type === "HANSEL") {
      return externalUserHanselClasses.map((hanselClass) => {
        const category = categories.find((c) =>
          hanselClass.vehicleClasses.includes(c.name)
        );
        return (
          <CustomMenuItem
            key={`hanselClass-menu-item-${hanselClass.class}`}
            value={category?.id}
            itemcontent={hanselClass}
            category={category}
          />
        );
      });
    } else {
      const availableCategories = categories.filter((category) =>
        user?.externalBusiness?.pricings?.items?.some(
          (pricing) => pricing?.name === category?.name
        )
      );

      return availableCategories.map((category) => {
        const name = category?.exampleModelName
          ? `${category?.exampleModelName} - tai vastaava`
          : category?.name;
        return (
          <CustomMenuItem
            key={`category-menu-item-${category?.id}`}
            value={category?.id}
            itemcontent={category}
            category={category}
            name={name}
          />
        );
      });
    }
  }, [values.type, categories, user?.externalBusiness?.pricings?.items]);
  const getSelectedItemName = (selected) => {
    if (values.type === "HANSEL") {
      const category = categories.find((c) => c.id === selected);

      const hanselClass = externalUserHanselClasses?.find((hanselClass) =>
        hanselClass.vehicleClasses.includes(category?.name)
      );

      return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ marginRight: "15px" }}>
            <b>Hansel luokka {hanselClass?.class}</b>
          </Typography>
          <Typography sx={{ color: theme.palette.primary.main }}>
            <b>{hanselClass?.name}</b>
          </Typography>
          <Typography>{hanselClass?.exampleModel}</Typography>
        </Box>
      );
    } else {
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ marginRight: "15px" }}>
          <b>{values.selectedVehicleClass}</b>
        </Typography>
        <Typography sx={{ color: theme.palette.primary.main }}>
          <b>{selected?.name}</b>
        </Typography>
        <Typography>{selected?.exampleModel}</Typography>
      </Box>;
    }
  };

  const label = useMemo(() => {
    if (values?.type === "HANSEL") {
      return "Valitse Hansel luokka";
    }
    if (user?.organizationId) {
      return "Valitse autoluokka";
    } else {
      return "Valitse kategoria";
    }
  }, [values.type, user]);

  if (visibility)
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="select-category-label">{label}</InputLabel>
            <Select
              labelId="select-category-label"
              id="select-category"
              label={label}
              value={selectedVehicleClass || ""}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                  },
                },
              }}
              onChange={handleChange}
              renderValue={(selected) => getSelectedItemName(selected) || ""}
            >
              {listCategories}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Hinta"
            disabled
            value={
              values?.wildCardVehicles?.length > 0
                ? `${centsToLocalString(values?.wildCardVehicles[0]?.price)} €`
                : ""
            }
          />
        </Grid>
      </>
    );
}

function BusinessSelectVehicleClass(props) {
  const { visibility } = props;

  const categories = useCategories();
  const { user } = useContext(AppContext);
  const { reservationData } = useReservationContext();
  const { setFieldValue, values, setValues } = useFormikContext();
  const [selectedVehicleClass, setSelectedVehicleClass] = useState(() =>
    values?.wildCardVehicles?.length > 0 ? values?.wildCardVehicles[0] : null
  );
  const theme = useTheme();
  const listCategories = useMemo(() => {
    return categories.map((category) => (
      <CustomMenuItem
        key={`category-menu-item-${category?.id}`}
        value={category}
        itemcontent={category}
        category={category}
      />
    ));
  }, [categories]);

  const getSelectedItemName = (selected) => {
    if (selected) {
      const name =
        selected?.name ??
        categories.find((c) => c.id === selected?.categoryId)?.name;
      return (
        <Box>
          <Typography sx={{ marginRight: "15px" }}>
            <b>{selected?.class}</b>
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: theme.palette.primary.main }}>
              <b>{name}</b>
            </Typography>

            <Typography>{selected?.exampleModel}</Typography>
          </Box>
        </Box>
      );
    } else {
      return "";
    }
  };

  const label = useMemo(() => {
    if (values?.type === "HANSEL") {
      return "Valitse Hansel luokka";
    }
    if (user?.organizationId) {
      return "Valitse autoluokka";
    } else {
      return "Valitse kategoria";
    }
  }, [values.type, user]);

  const handleChange = (e) => {
    if (!reservationData?.id) {
      setSelectedVehicleClass(e.target.value);

      setValues({
        ...values,
        initialClassInfo: {
          categoryId: e.target.value.id,
          categoryName: e.target.value.name,
        },
        selectedVehicleClass: e.target.value.id,
      });
    } else {
      setSelectedVehicleClass(e.target.value);
      setFieldValue("selectedVehicleClass", e.target.value.id);
    }
  };

  if (visibility)
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="select-category-label">{label}</InputLabel>
            <Select
              labelId="select-category-label"
              id="select-category"
              label={label}
              value={selectedVehicleClass || ""}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: "300px",
                  },
                },
              }}
              onChange={handleChange}
              renderValue={(selected) => getSelectedItemName(selected) || ""}
            >
              {listCategories}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Hinta"
            disabled
            value={
              values?.wildCardVehicles?.length > 0
                ? `${centsToLocalString(values?.wildCardVehicles[0]?.price)} €`
                : ""
            }
          />
        </Grid>
      </>
    );
}

function ChangeViewButton(props) {
  const { setShowEditProducts, showEditProducts, visibility } = props;
  const { mobileViewPort } = useContext(AppContext);
  if (visibility)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          mb: "15px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            paddingY: "10px",
            textTransform: "none",
            width: !mobileViewPort ? "200px" : undefined,
          }}
          onClick={() => setShowEditProducts((prevState) => !prevState)}
          fullWidth
        >
          {showEditProducts ? "Näytä valitut tuotteet" : "Lisää tuotteita"}
        </Button>
      </Box>
    );
}

function SelectReservationType(props) {
  const { visibility } = props;
  const { company, user } = useContext(AppContext);
  const { setQueryCompanies } = useReservationContext();
  const { setFieldValue, values } = useFormikContext();
  if (visibility)
    return (
      <Grid item xs={11} sm={12}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e, value) => {
            if (value === "regular") {
              setQueryCompanies(company ? [company.id] : null);
              setFieldValue("enableWildCard", false);

              setFieldValue("selectedVehicleClass", null);
            } else {
              setQueryCompanies(company ? [company.id] : null);
              setFieldValue("enableWildCard", true);
            }
          }}
          value={values?.enableWildCard ? "category" : "regular"}
        >
          <FormControlLabel
            value="regular"
            control={<Radio />}
            label="Lisää tuote varaukselle"
          />
          <FormControlLabel
            value="category"
            control={<Radio />}
            label="Luo varaus kategorialle"
            disabled={!user?.organizationId}
          />
        </RadioGroup>
      </Grid>
    );
}
/*
function SelectCompany(props) {
  const { visibility, hasErrors } = props;
  const { user, USER_ADMIN_CORPORATE, business } = useContext(AppContext);
  const { values, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const {
    selectedBusiness,
    setQueryCompanies,
    setQueryResource,
    setStartLocation,
    setStartLocationID,
    queryCompanies,
  } = useReservationContext();
  const businessByOrg = user?.businessByOrg;
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const companiesByBusiness = useMemo(() => {
    if (user?.orgBusinessId) {
      const companies = businessByOrg?.items.find(
        (b) => b?.id === selectedBusiness
      )?.companies?.items;

      return companies ?? [];
    }

    if (USER_ADMIN_CORPORATE) {
      let availableCompanies = [];
      user?.businessByOrg.items.forEach((b) => {
        let locations;
        if (b?.pickupLocations) {
          locations = [...b?.companies?.items, ...b?.pickupLocations];
        } else {
          locations = b?.companies?.items;
        }
        if (user?.externalBusiness?.availableCompanyIds) {
          const companies = locations.filter((c) =>
            user?.externalBusiness?.availableCompanyIds.includes(c.id)
          );
          if (companies?.length > 0) {
            availableCompanies = [...availableCompanies, ...companies];
          }
        }
      });

      return availableCompanies;
    } else {
      return companies;
    }
  }, [selectedBusiness, businessByOrg, companies, USER_ADMIN_CORPORATE, user]);

  const handleChangeCompany = (value) => {
    if (user?.organizationId) {
      if (!selectedBusiness) {
        setQueryResource((prevState) => ({
          ...prevState,
          orgBusinessId: user?.orgBusinessId,
        }));
      }
      // set reservation companyId for external reservations

      const companyId = value?.companyId ?? value?.id; // pickup locations are attached to companies by companyId
      console.log("companyId", companyId);
      setFieldValue("companyId", companyId);
      setStartLocation(value?.name);
      setStartLocationID(value?.id);

      setQueryCompanies(value);
    } else {
      if (value?.length > 0 && !user?.organizationId) {
        setQueryResource({ group: user?.group });
        setQueryCompanies(value);
      }
    }
  };

  if (visibility)
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="select-companies-for-search-label">
            {values.enableWildCard
              ? "Valitse toimipiste"
              : " Hae toimipisteiltä"}
          </InputLabel>

          <Select
            labelId="select-companies-for-search-label"
            id="select-companies-for-search"
            fullWidth
            multiple={values?.enableWildCard ? false : true}
            variant="standard"
            input={<OutlinedInput label="Hae toimipisteiltä" />}
            onChange={(e) => handleChangeCompany(e.target.value)}
            disabled={
              user?.organizationId
                ? companiesByBusiness?.length === 0 || !companiesByBusiness
                : false
            }
            value={queryCompanies ?? ""}
            margin="dense"
            data-cy="newReservationDialogSelectOffice"
            MenuProps={{
              PaperProps: {
                sx: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {companiesByBusiness &&
              companiesByBusiness.map((c, idx) => (
                <MenuItem
                  value={c?.id}
                  key={idx}
                  data-cy="newReservationDialogOfficeOption"
                >
                  {c?.name}
                </MenuItem>
              ))}
          </Select>

          {Array.isArray(hasErrors) &&
            hasErrors?.includes("select-company") && (
              <FormHelperText sx={{ color: theme.palette.warning.main }}>
                Valitse toimipiste
              </FormHelperText>
            )}
        </FormControl>
      </Grid>
    );
}

function SelectWildCardProvider(props) {
  const { hasErrors } = props;
  const { user, USER_ADMIN_CORPORATE, business } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();
  const theme = useTheme();
  const {
    selectedBusiness,
    setQueryCompanies,
    setQueryResource,
    setStartLocation,
    setStartLocationID,
    queryCompanies,
  } = useReservationContext();
  const businessByOrg = user?.businessByOrg;
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const companiesByBusiness = useMemo(() => {
    if (user?.orgBusinessId) {
      const companies = businessByOrg?.items.find(
        (b) => b?.id === selectedBusiness
      )?.companies?.items;

      return companies ?? [];
    }

    if (USER_ADMIN_CORPORATE) {
      let availableCompanies = [];
      user?.businessByOrg.items.forEach((b) => {
        let locations;
        if (b?.pickupLocations) {
          locations = [...b?.companies?.items, ...b?.pickupLocations];
        } else {
          locations = b?.companies?.items;
        }
        if (user?.externalBusiness?.availableCompanyIds) {
          const companies = locations.filter((c) =>
            user?.externalBusiness?.availableCompanyIds.includes(c.id)
          );
          if (companies?.length > 0) {
            availableCompanies = [...availableCompanies, ...companies];
          }
        }
      });

      return availableCompanies;
    } else {
      return companies;
    }
  }, [selectedBusiness, businessByOrg, companies, USER_ADMIN_CORPORATE, user]);

  const handleChangeCompany = (value) => {
    if (user?.organizationId) {
      if (!selectedBusiness) {
        setQueryResource((prevState) => ({
          ...prevState,
          orgBusinessId: user?.orgBusinessId,
        }));
      }
      // set reservation companyId for external reservations

      const companyId = value?.companyId ?? value?.id; // pickup locations are attached to companies by companyId
      console.log("companyId", companyId);
      setFieldValue("companyId", companyId);
      setStartLocation(value?.name);
      setStartLocationID(value?.id);

      setQueryCompanies([value]);
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <InputLabel id="select-companies-for-search-label">
          Valitse toimipiste
        </InputLabel>

        <Select
          labelId="select-companies-for-search-label"
          id="select-companies-for-search"
          fullWidth
          variant="standard"
          input={<OutlinedInput label=" Valitse toimipiste" />}
          onChange={(e) => handleChangeCompany(e.target.value)}
          value={queryCompanies ? queryCompanies[0] : ""}
          margin="dense"
          data-cy="newReservationDialogSelectOffice"
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "300px",
              },
            },
          }}
        >
          {companiesByBusiness &&
            companiesByBusiness.map((c, idx) => (
              <MenuItem
                value={c?.id}
                key={idx}
                data-cy="newReservationDialogOfficeOption"
              >
                {c?.name}
              </MenuItem>
            ))}
        </Select>

        {Array.isArray(hasErrors) && hasErrors?.includes("select-company") && (
          <FormHelperText sx={{ color: theme.palette.warning.main }}>
            Valitse toimipiste
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}
 */
/* function SelectProvider(props) {
  const { hasErrors, visibility } = props;
  const { user, USER_ADMIN_CORPORATE } = useContext(AppContext);
  const { values } = useFormikContext();

  // query company picker for wildcards. Company is used to determine price for the wildcard.
  if (values?.enableWildCard) {
    return <SelectWildCardProvider />;
  }

  // regular business & company picker for selecting query companies
  if (!values?.enableWildCard)
    return (
      <>
        <SelectBusiness
          visibility={user?.role === "ORGANIZATION_ADMIN"}
          hasErrors={hasErrors}
        />
        <SelectCompany visibility={!USER_ADMIN_CORPORATE} />
      </>
    );
} */

function ReservationOffers(props) {
  const { visibility } = props;
  const { USER_ADMIN_CORPORATE, USER_ADMIN_GENERIC } = useContext(AppContext);
  const { selectedOffers } = useReservationContext();
  const { values } = useFormikContext();

  if (visibility)
    return (
      <FormGrid>
        <Stack
          direction={"column"}
          spacing={1}
          sx={{ width: "100%", maxWidth: "500px" }}
        >
          {values?.wildCardVehicles?.length > 0 &&
            selectedOffers?.length === 0 && (
              <WildCard offer={values?.wildCardVehicles[0]} />
            )}
          {selectedOffers.length > 0 &&
            selectedOffers.map((offer) => {
              if (offer) {
                if (USER_ADMIN_CORPORATE) {
                  return (
                    <FormGrid key={offer?.id}>
                      <WildCard offer={offer} />
                    </FormGrid>
                  );
                }
                if (USER_ADMIN_GENERIC) {
                  return (
                    <ReservedProductCard
                      offer={offer}
                      key={offer?.id}
                      //   handleRemoveProduct={handleRemoveProduct}
                      displaySummary={true}
                    />
                  );
                }
              }
              return null;
            })}
        </Stack>
      </FormGrid>
    );
}

function ContractCustomerProvider(props) {
  const { visibility } = props;
  const { queryResource } = useReservationContext();
  if (visibility)
    return (
      <Grid item container direction={"row"} spacing={2}>
        <Grid item xs={12} sm={6}>
          <SelectContractCustomer visibility={true} />
        </Grid>
        {queryResource?.extBusinessId && (
          <Grid item xs={12} sm={6}>
            <SelectContractVehicleClass />
          </Grid>
        )}
      </Grid>
    );
}

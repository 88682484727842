const toTwoDigitNumber = (number) => {
  if (number < 10) {
    return "0" + number.toString();
  } else {
    return number.toString();
  }
};

//Ensures correct format and values, accepts HH:MM and H:MM
//Return unified HH:MM string if valid
export const unifyBusinessHoursString = (str) => {
  const timeRegex = /^([0-9][0-9]?):([0-9][0-9])$/;
  const result = timeRegex.exec(str);
  if (result == null) {
    return;
  }

  const hours = parseInt(result[1]);
  const minutes = parseInt(result[2]);

  if (isNaN(hours) || isNaN(minutes)) {
    return; //Should never happen
  }

  if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
    return;
  }

  return toTwoDigitNumber(hours) + ":" + toTwoDigitNumber(minutes);
};

//Returns unified and { ok } business hours if valid, otherwise { errors }
export const parseBusinessHours = (businessHours) => {
  //Null is valid value
  if (businessHours == null) {
    return { ok: null };
  }

  const errors = {};
  const ok = {};

  if (businessHours.closeAt) {
    const unifiedCloseAt = unifyBusinessHoursString(businessHours.closeAt);
    if (unifiedCloseAt == null) {
      errors.closeAt = "Anna aika muodossa HH:MM";
    } else {
      ok.closeAt = unifiedCloseAt;
    }
  }

  if (businessHours.openAt) {
    const unifiedOpenAt = unifyBusinessHoursString(businessHours.openAt);
    if (unifiedOpenAt == null) {
      errors.openAt = "Anna aika muodossa HH:MM";
    } else {
      ok.openAt = unifiedOpenAt;
    }
  }

  if (Object.keys(errors).length > 0) {
    //We found errors already? Return them.
    return { errors };
  } else if (ok.openAt && ok.closeAt) {
    //Both ok? As final check make sure that order is sensible.
    if (ok.openAt < ok.closeAt) {
      return { ok };
    } else {
      return {
        errors: {
          closeAt: "Aukeamis ajan täytyy olla ennen sulkeutumis aikaa",
          openAt: "Aukeamis ajan täytyy olla ennen sulkeutumis aikaa",
        },
      };
    }
  } else if (!ok.openAt && !ok.closeAt) {
    //Neither is ok (and we didn't have errors) so business hours simply won't exist
    return { ok: null };
  } else {
    //One field is ok and one is not. Add error to empty one.
    if (!ok.openAt) {
      return {
        errors: {
          openAt:
            "Täytä molemmat: aukeamisaika ja sulkeutumisaika tai älä kumpaakaan.",
        },
      };
    } else {
      return {
        errors: {
          closeAt:
            "Täytä molemmat: aukeamisaika ja sulkeutumisaika tai älä kumpaakaan.",
        },
      };
    }
  }
};

import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";

export default function InsuranceProduct() {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const { user, USER_ADMIN_TYPE } = useContext(AppContext);

  const getInsuranceProducts = () => {
    if (USER_ADMIN_TYPE === "INSURANCE") {
      return user?.externalBusiness?.insuranceProducts;
    } else {
      const externalBusinessId = values?.orgExternalBusinessId ?? null;
      if (externalBusinessId) {
        return user?.externalBusinessByOrg?.items?.find(
          (extBiz) => extBiz.id === externalBusinessId
        )?.insuranceProducts;
      }
    }
  };
  const products = getInsuranceProducts();
  // Set insurance coverage, insurance billing permit and maximum policy duration for insurance when changing policy
  const handleChange = (e) => {
    const product = products.find((p) => p.name === e);

    if (product) {
      setFieldValue("insuranceData.product", product.name);
      setFieldValue(
        "insuranceData.compensatableDays",
        product.compensatableDays
      );
      setFieldValue("insuranceData.coveragePercentage", product.coverage);

      setFieldValue("insuranceData.maximumCoverage", product.maximumCoverage);
      setFieldValue(
        "insuranceData.coveragePercentage",
        product.coveragePercentage
      );

      if (product?.billingPermitCode !== "") {
        setFieldValue(
          "insuranceData.billingPermitCode",
          product.billingPermitCode
        );
      }

      /*     setFieldValue(
        "insuranceData.maximumPolicyDuration",
        product?.maximumPolicyDuration
      ); */
    }
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="insurance-product-label">Vakuutustuote</InputLabel>
      <Select
        labelId="insurance-product-label"
        id="insurance-product"
        value={
          values?.insuranceData?.product ? values?.insuranceData?.product : ""
        }
        label="Vakuutustuote"
        name="insuranceData.product"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        sx={{ bgcolor: "white" }}
        error={
          touched?.insuranceData?.product && !!errors?.insuranceDataProduct
        }
        data-cy="reservationDialogInsuranceProductSelect"
      >
        {(products?.length === 0 || !products) && (
          <MenuItem disabled value="">
            Ei vakuutustuotteita
          </MenuItem>
        )}
        {products?.map((product) => (
          <MenuItem
            key={product.name}
            value={product.name}
            data-cy="reservationDialogInsuranceProductOption"
          >
            {product.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function PaymentMethodSelect() {
  const { values } = useFormikContext();
  const [field, meta, helpers] = useField("paymentStatus");
  const { onChange } = field;
  const { value } = meta;
  const reservationData = useAtomValue(reservationDataAtom);
  if (
    ["COMPLETED", "COMPLETED_DEPOSIT", "PENDING_FULL", "PENDING"].includes(
      reservationData?.paymentStatus
    )
  ) {
    // PaymentStatus is controller by Visma Pay payment process.
    return null;
  }

  if (values.sendStatusToCustomer) {
    return "Lähetetään asiakkaalle täydennettäväksi, asiakas valitsee maksutavan";
  }
  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="payment-select-label" shrink>
          Maksun tila
        </InputLabel>
        <Select
          labelId="payment-select-label"
          id="payment-select"
          value={value ?? ""}
          label="Maksutapa"
          displayEmpty
          name="paymentStatus"
          onChange={onChange}
        >
          <MenuItem value={undefined || ""}>Noudettaessa</MenuItem>
          <MenuItem value={"PENDING_INVOICE"}>Laskutettava</MenuItem>
          <MenuItem value={"COMPLETED_INVOICE"}>Laskutettu</MenuItem>
          <MenuItem value={"COMPLETED_MANUAL"}>Maksettu</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

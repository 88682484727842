import React from "react";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";

import SelectedVehicleClass from "../SelectedVehicleClass";
import ProductCard from "./components/ProductCard";

export default function ReservationProducts(props) {
  const { selectedBusiness } = props;
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      {/* WILDCARD */}
      <SelectedVehicleClass />
      {/* PRODUCTS */}
      <ProductsListing selectedBusiness={selectedBusiness} />
    </Stack>
  );
}

function ProductsListing(props) {
  const { selectedBusiness, vehiclesByCategory } = props;
  const { values } = useFormikContext();
  if (values?.reservationVehicles?.length > 0) {
    return values?.reservationVehicles.map((item, index) => {
      return (
        <ProductCard
          key={item.id}
          item={item}
          selectedBusiness={selectedBusiness}
          vehiclesByCategory={vehiclesByCategory}
        />
      );
    });
  }
}

import { EAdditionalDriverInfo } from "./LanguagePack/translatedFields";
import { Box } from "@mui/material";
import { ContractField } from "./common";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

export default function ContractAdditionalDriverInformation({
  visibility,
  selectedLanguage,
  contractSettings,
}: {
  visibility: boolean;
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const fields =
    EAdditionalDriverInfo[
      selectedLanguage as keyof typeof EAdditionalDriverInfo
    ];
  const reservation = useAtomValue(reservationDataAtom);

  if (visibility) {
    //@ts-ignore
    const additionalDriverName = reservation?.additionalDriverName ?? "";
    //@ts-ignore
    const additionalDriverPhone = reservation?.additionalDriverPhone
      ? //@ts-ignore
        `, ${reservation?.additionalDriverPhone}`
      : "";
    //@ts-ignore
    const additionalDriverSSN = reservation?.additionalDriverSSN
      ? //@ts-ignore
        `, ${reservation?.additionalDriverSSN}`
      : "";

    return (
      <Box
        sx={{
          border: "1px solid lightgrey",
          borderRadius: "5px",
          paddingBottom: "5px",
        }}
      >
        <ContractField
          value={
            additionalDriverName + additionalDriverPhone + additionalDriverSSN
          }
          label={fields.TITLE}
          visibility={!!contractSettings?.showAdditionalDriver}
        />
      </Box>
    );
  }
}

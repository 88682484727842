import {
  Invoice,
  InvoiceByOrgBusinessIdQueryVariables,
  InvoiceByOrgBusinessIdQuery,
  ModelSortDirection,
} from "~/graphql/API";
import useSWR from "swr";
import { API } from "aws-amplify";
import { invoiceByOrgBusinessId } from "~/graphql/queries";
import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";

const fallbackData: Invoice[] = [];

async function fetchInvoices(orgBusinessId: string) {
  // Fetch latest 100 Invoices by createdAt by orgBusinessId
  const variables: InvoiceByOrgBusinessIdQueryVariables = {
    orgBusinessId: orgBusinessId,
    sortDirection: ModelSortDirection.DESC,
  };
  const result = (await API.graphql({
    query: invoiceByOrgBusinessId,
    variables,
  })) as { data: InvoiceByOrgBusinessIdQuery };

  return (result.data.invoiceByOrgBusinessId?.items || []).filter(
    Boolean
  ) as Invoice[];
}

export function useInvoices() {
  const { business } = useContext<any>(AppContext);

  return useSWR(
    business ? ["invoiceHistory", business.id] : null,
    async () => await fetchInvoices(business.id),
    {
      fallbackData,
      revalidateOnFocus: false,
    }
  );
}

import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { timelineContainer } from "../constants";

export default function EmptyState(props) {
  return (
    <motion.div
      key={"departuresEmpty"}
      variants={timelineContainer}
      initial="hidden"
      animate="show"
      exit={{ opacity: 0 }}
    >
      <Typography>Ei tapahtumia</Typography>
    </motion.div>
  );
}

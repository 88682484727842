import React, { useContext, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { FormContainer } from "../../../../../components/FormContainer";
import PaymentStatus from "./components/PaymentStatus";
import PaymentMethodSelect from "./components/PaymentMethodSelect";
import InvoicingContainer from "./components/InvoicingContainer";
import { ReservationContext } from "../../../../../contextProviders/ReservationContext";
import { AppContext } from "../../../../../../../AppContext";
import InvoiceDialog from "../../../../../components/InvoiceDialog";
import { useNotification } from "../../../../../../Notification";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../atoms/reservationDataAtom";
import AppliedVoucher from "../../../../../components/AppliedVoucher";

export default function ReservationPayment() {
  const notification = useNotification();
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const { USER_ADMIN_GENERIC, business } = useContext(AppContext);
  const { FEATURE_INVOICING, FEATURE_INNOVOICE } =
    useContext(ReservationContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const { values } = useFormikContext();
  return (
    <Stack
      sx={{ justifyContent: "flex-start" }}
      direction={"column"}
      width={"100%"}
    >
      <FormContainer title={"Varauksen maksu"} visibility={true}>
        <Stack flex={1}>
          <Box>
            {USER_ADMIN_GENERIC && (
              <>
                <PaymentMethodSelect />
                <InvoicingContainer
                  setShowInvoiceDialog={setShowInvoiceDialog}
                  business={business}
                  values={values}
                  notification={notification}
                  FEATURE_INVOICING={FEATURE_INVOICING}
                  FEATURE_INNOVOICE={FEATURE_INNOVOICE}
                />

                <PaymentStatus />
              </>
            )}
          </Box>
          <AppliedVoucher />
        </Stack>
      </FormContainer>
      {showInvoiceDialog && (
        <InvoiceDialog
          title="Laskutus"
          onClose={() => setShowInvoiceDialog(false)}
          invoiceState={showInvoiceDialog}
          reservationId={reservationData?.id}
          reservationValues={values}
          FEATURE_INVOICING={FEATURE_INVOICING}
        />
      )}
    </Stack>
  );
}

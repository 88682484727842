import { useAtomValue } from "jotai";
import { modalAtom } from "~/modals/store";
import { modals } from "~/modals";

function ModalContainer() {
  const val = useAtomValue(modalAtom);

  if (!val) {
    return null;
  }

  const { modal, props } = val;

  const { component: ModalComponent } = modals[modal];

  return <ModalComponent {...(props as any)} />;
}

export default ModalContainer;

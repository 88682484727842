import scandiaLogo from "../customerLogos/scandiaRent_logo.png.webp";
import tokmanniLogo from "../customerLogos/tokmanni_Logo.png";
import kauppiLeasing from "../customerLogos/kauppi_leasing_logo.png";
import motonetLogo from "../customerLogos/motonet_logo.png";
import university_of_lapland from "../customerLogos/university_of_lapland.png";
import university_of_turku from "../customerLogos/university_of_turku.png";
import xamkLogo from "../customerLogos/xamk_logo.png";
import puuilo_logo from "../customerLogos/puuilo_logo.png";
export const clientLogoData = [
  {
    id: "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent organization from master env,
    logo: scandiaLogo,
  },
  { id: "kauppi_leasing", logo: kauppiLeasing },
  {
    id: "703cdba4-7158-11ee-b962-0242ac120002", // Tokmanni organization from master env
    logo: tokmanniLogo,
  },
  {
    id: "06b8b3a2-b441-11ee-a506-0242ac120002", // Motonet organization from master env
    logo: motonetLogo,
  },
  {
    id: "d234b1f0-de07-40ef-a334-e46ef1abe77a", //
    logo: university_of_lapland,
  },
  {
    id: "2503ac08-a89b-453c-a179-28104ad4d6f5",
    logo: university_of_turku,
  },
  {
    id: "82b1fdb6-8bef-4bcd-b547-18f2e8a5654c",
    logo: puuilo_logo,
  },
  {
    id: "0e6c9fbe-6e93-4186-86d7-962f19a370d9",
    logo: xamkLogo,
  },
];

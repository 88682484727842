import {
  Button,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Grid,
  InputLabel,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Tooltip,
  Stack,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { API, graphqlOperation, Auth } from "aws-amplify";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { getReservation, getReservations } from "../graphql/queries";

import { Autocomplete } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFormik } from "formik";
import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  deleteReservation,
  upsertReservationAdmin,
  sendEmailVerification,
  createLivionContract,
  resetAndInitSign,
} from "../graphql/mutations";
import {
  getPriceByVehicleIds,
  searchReservations as searchReservationsQuery,
} from "../graphql/queries";
import RegistrationPlate from "./RegistrationPlate";
import { useNotification } from "./Notification";
import { format, isValid, isBefore, isSameDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { getZonedTime } from "../utils/common";
import { fi } from "date-fns/locale";
import { centsToLocalString, parseToCents } from "../shared/money";
import { mapMaybe, distinctBy } from "../utils/array-utilities";
import { useDebouncedEffect } from "../utils/use-debounced-effect";
import ContractTemplateEbikeRental from "../print-contracts/ContractTemplateEbikeRental";
import ContractTemplateJetRental from "../print-contracts/ContractTemplateJetRental";
import captureError from "../utils/capture-error";
import * as Sentry from "@sentry/react";
import AdditionalServiceList from "./AdditionalServiceList";
import PriceField from "./PriceField";
import {
  enableCustomBikeContract,
  enableJetContract,
  enableVismaSign,
  devStaging,
  development,
} from "../features";
import StoreIcon from "@mui/icons-material/Store";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import DialogComponent from "./DialogComponent";
import InvoiceDialog from "./InvoiceDialog";
import InnovoiceCredit from "./InnovoiceCredit";
import PhoneNumberField from "./PhoneNumberField";
import {
  isValidPhoneNumber,
  parsePhoneNumberWithError,
  ParseError,
  parsePhoneNumber,
} from "libphonenumber-js";
import { AppContext } from "../AppContext";
import useSWR from "swr";

import { useFetchAdditionalServices } from "../services/fetchAdditionalServices";
import { useFetchInvoices } from "../services/fetchInvoices";
import { useFetchReservations } from "../services/fetchReservations";
import { useFetchCompanies } from "../services/fetchCompanies";
import { useFetchVehicles } from "../services/fetchVehicles";
import { BsPersonExclamation } from "react-icons/bs";

import OldContractTemplate from "../print-contracts/OldContractTemplate";
import { reservationDataAtom } from "../atoms/reservationDataAtom";
import { useAtom } from "jotai";
import { PrintingComponent } from "./ReservationDialogV2/components/PrintContract";
import SelectReservationLanguageLegacy from "./SelectReservationLanguageLegacy";

const nextFullHour = (date) => {
  const hour = 3600000;
  return new Date((Math.floor(date.getTime() / hour) + 1) * hour);
};
const containerSpacing = 2;

const validate = (values) => {
  const errors = {};
  const GENERAL_ERROR_MSG = "Täytä tämä kenttä";
  const TIME_ERROR_MSG = "Virheellinen aikamuoto";

  // TODO: reservations: vehicles
  if (values.vehicleIds.length === 0) {
    errors.vehicleIds = "Valitse tuote";
  }

  if (!values.startTime) {
    errors.startTime = GENERAL_ERROR_MSG;
  }

  if (!values.returnTime) {
    errors.returnTime = GENERAL_ERROR_MSG;
  }

  if (!values.endTime) {
    errors.endTime = GENERAL_ERROR_MSG;
  }

  if (values.sendStatusToCustomer) {
    if (!values.completeBefore) {
      errors.completeBefore = GENERAL_ERROR_MSG;
    }

    if (values.completeBefore < new Date()) {
      errors.completeBefore = "Et voi asettaa aikaa menneisyyteen";
    }
    if (!isValid(values.completeBefore)) {
      errors.completeBefore = TIME_ERROR_MSG;
    }
  }

  if (!isValid(values.startTime)) {
    errors.startTime = TIME_ERROR_MSG;
  }

  if (!isValid(values.returnTime)) {
    errors.returnTime = TIME_ERROR_MSG;
  }

  if (!isValid(values.endTime)) {
    errors.endTime = TIME_ERROR_MSG;
  }

  if (values.startTime > values.returnTime) {
    errors.returnTime = "Palautusaika ennen noutoaikaa";
    errors.startTime = "Palautusaika ennen noutoaikaa";
  }
  if (values.startTime?.getTime() === values.returnTime?.getTime()) {
    errors.returnTime = "Palautusaika sama kuin noutoaika";
    errors.startTime = "Palautusaika sama kuin noutoaika";
  }
  if (values.endTime < values.returnTime) {
    errors.endTime = "Lopetusaika ennen palautusaikaa";
  }

  const phoneFields = [
    {
      field: "phone",
      countryCodeField: "phoneCountryCode",
      error: "Tarkista puhelinnumero",
      isEnabled: true,
    },
    {
      field: "driverPhone",
      countryCodeField: "driverPhoneCountryCode",
      error: "Tarkista kuljettajan puhelinnumero",
      isEnabled: values.differentDriver,
    },
    {
      field: "additionalDriverPhone",
      countryCodeField: "additionalDriverPhoneCountryCode",
      error: "Tarkista lisäkuljettajan puhelinnumero",
      isEnabled: values.additionalDriver,
    },
  ];

  phoneFields.forEach(({ field, countryCodeField, error, isEnabled }) => {
    //   If the phone field and the corresponding countryCode field are not empty, do the validation

    if (isEnabled && values[field] && values[field] !== "") {
      try {
        const phoneNumber = parsePhoneNumberWithError(
          values[countryCodeField] + values[field]
        );
        const validNumber =
          isValidPhoneNumber(phoneNumber?.number, phoneNumber?.country) ??
          false;

        if (!validNumber) {
          errors[field] = error;
        }
      } catch (e) {
        if (e instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          console.log(
            "Error parsing the phone number of field " + field + ":",
            e.message,
            "value:",
            values[countryCodeField],
            values[field]
          );
          errors[field] = error;
        } else {
          throw error;
        }
      }
    }
  });

  if (!values.email) {
    errors.email = GENERAL_ERROR_MSG;
  }

  if (values.isCompany) {
    if (!values.companyName) {
      errors.companyName = GENERAL_ERROR_MSG;
    }
  }

  if (values.differentDriver) {
    if (!values.driverName) {
      errors.driverName = GENERAL_ERROR_MSG;
    }

    if (values.additionalDriver) {
      if (!values.additionalDriverName) {
        errors.additionalDriverName = GENERAL_ERROR_MSG;
      }

      if (!values.additionalDriverPhone) {
        errors.additionalDriverPhone = GENERAL_ERROR_MSG;
      }
    }
  }

  return errors;
};

// Return price entered in to PriceField, otherwise price from the offer
const getEffectiveVehiclePrice = (formikValues, offerPrice, vehicleId) => {
  let vehiclePrice = parseToCents(formikValues["vehiclePrice-" + vehicleId]);
  return vehiclePrice ?? offerPrice?.vehicleOfferPrices[vehicleId]?.offerPrice;
};

export default function ReservationDialog({ open, selectedView }) {
  const { company, setCompany, group, timezone, user } = useContext(AppContext);
  const [reservationData, setEditReservationData] =
    useAtom(reservationDataAtom);
  const { vehicleData: allVehicles } = useFetchVehicles();
  const queryMode =
    selectedView === "daily" ? "startOrEndBetween" : "existBetween";
  const { companies } = useFetchCompanies(user?.group, null);
  const { reservationsMutate } = useFetchReservations(queryMode);
  const { additionalServices } = useFetchAdditionalServices();
  const [personalAutofillSearch, setPersonalAutofillSearch] = useState("");
  const [personalAutofillValues, setPersonalAutofillValues] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showPriceConfirmation, setShowPriceConfirmation] = useState(false);
  const [showSignConfirmation, setShowSignConfirmation] = useState(false);
  const [offerPrice, setOfferPrice] = useState(undefined); //undefined = unloaded, null = loaded but not value
  const [review, setReview] = useState(undefined);
  const [explanation, setExplanation] = useState(undefined);
  const notification = useNotification();
  const [showSSN, setShowSSN] = useState(false);
  const [showDriverSSN, setShowDriverSSN] = useState(false);
  const [showAdditionalSSN, setShowAdditionalSSN] = useState(false);
  const handleClickShowSSN = () => setShowSSN(!showSSN);
  const handleClickShowDriverSSN = () => setShowDriverSSN(!showDriverSSN);
  const handleClickShowAdditionalSSN = () =>
    setShowAdditionalSSN(!showAdditionalSSN);
  const [enabledServices, setEnabledServices] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [closeDialog, setCloseDialog] = useState(false);
  const [showChangeCompany, setShowChangeCompany] = useState(false);
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const [invoiceDialogState, setInvoiceDialogState] = useState(false);
  const [data, setData] = useState(reservationData);

  const getUpdatedReservation = async () => {
    const result = await API.graphql(
      graphqlOperation(getReservation, {
        id: data.id,
      })
    );
    const reservation = result.data.getReservation;
    const updatedReservation = {
      ...reservation,
      startTime: getZonedTime(reservation.startTime, timezone),
      returnTime: getZonedTime(reservation.returnTime, timezone),
      endTime: getZonedTime(reservation.endTime, timezone),
    };
    setData(updatedReservation);
    return updatedReservation;
  };

  const FEATURE_INVOICING = useMemo(() => {
    // Not fully supported on old ReservationDialog
    return false;
    const notProduction = development(process.env.REACT_APP_ENV, company);
    if (notProduction) {
      console.log("Development feature INVOICING enabled");
      return true;
    }
    return false;
  }, [company]);

  const { invoices, invoicesLoading, invoicesError, invoicesMutate } =
    useFetchInvoices(data?.id, FEATURE_INVOICING);

  const updateInvoices = async () => {
    // Update the data used in the table
    await invoicesMutate();
  };

  const onClose = (success) => {
    if (success) {
      reservationsMutate();
    }
    setEditReservationData(null);
  };

  const FEATURE_INNOVOICE = useMemo(() => {
    if (!FEATURE_INVOICING) return false;
    const notProduction = development(process.env.REACT_APP_ENV, company);
    if (notProduction) {
      console.log("Development feature Innovoice integration enabled");
      return true;
    }
    return false;
  }, [company, FEATURE_INVOICING]);

  const FEATURE_REVIEWS_REPORT = useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV, company);
  }, [company]);

  const FEATURE_EBIKERENTAL_CONTRACT = React.useMemo(() => {
    return enableCustomBikeContract(group);
  }, [group]);

  const FEATURE_JET_CONTRACT = React.useMemo(() => {
    return enableJetContract(group);
  }, [group]);

  const FEATURE_LIVION_KEY = React.useMemo(() => {
    // Vuokraapaku REDI
    return !!company?.livionDeviceId;
  }, [company]);

  const FEATURE_ENABLE_VISMA_SIGN = useMemo(() => {
    return enableVismaSign(import.meta.env.REACT_APP_ENV, group);
  }, [group]);

  const sortedProducts = useMemo(() => {
    if (!allVehicles) return null;
    const companyVehicles = allVehicles.filter(
      (v) => v.companyId === company?.id
    );
    return companyVehicles.sort((v1, v2) => {
      return (
        (v2.orderNumber != null) - (v1.orderNumber != null) ||
        v1.orderNumber - v2.orderNumber
      );
    });
  }, [company?.id, allVehicles]);

  // Sort out data for the select component
  const listProducts = useMemo(() => {
    // replace null category values
    if (!sortedProducts) return;
    const productsWithCategory = sortedProducts.map((item) => {
      if (!item.category) {
        return {
          ...item,
          ["category"]: {
            name: "Ei kategoriaa",
            id: "no-id-number",
            orderNumber: sortedProducts.length + 1,
          },
        };
      } else return item;
    });

    // Create a list of categories
    const productCategories = productsWithCategory.map((item) => {
      return {
        name: item.category.name,
        id: item.category.id,
        orderNumber: item.category.orderNumber,
      };
    });

    // Filter out duplicates of categories list
    const checkCategories = new Set();
    const listOfCategories = productCategories.filter((obj) => {
      // check if name property value is already in the set
      const isCategoryListed = checkCategories.has(obj.name);
      // add name property value to Set
      checkCategories.add(obj.name);
      // return the negated value of
      // isCategoryListed variable
      return !isCategoryListed;
    });

    // Sorting the categories list by ordernumber
    listOfCategories.sort(function (a, b) {
      return (
        (b.orderNumber !== null) - (a.orderNumber !== null) ||
        a.orderNumber - b.orderNumber
      );
    });

    // Create vehicle list for select component
    const items = [];

    for (let itemList of listOfCategories) {
      // Retrieve category title
      items.push(
        <ListSubheader
          key={itemList.id}
          onClickCapture={(e) => e.stopPropagation()}
          disableSticky={true}
          style={{ lineHeight: "42px" }}
        >
          {itemList.name}
        </ListSubheader>
      );
      for (let item of productsWithCategory) {
        // Add vehicles under the appropriate category
        if (item.category.id === itemList.id) {
          items.push(
            <MenuItem
              value={item.id}
              key={item.id}
              data-cy="reservationDialogProductList"
            >
              <RegistrationPlate style={{ marginRight: 4 }}>
                {item.registrationPlate}
              </RegistrationPlate>

              {item.name}
            </MenuItem>
          );
        }
      }
    }
    return items;
  }, [sortedProducts]);

  const onSubmit = async (values, actions, confirmPriceChange) => {
    setSubmitting(true);

    const {
      id,
      vehicleIds,
      startTime,
      returnTime,
      endTime,
      name,
      address,
      postalCode,
      city,
      phone,
      phoneCountryCode,
      email,
      ssn,
      internalNotes,
      rentalNotes,
      driverSSN,
      differentDriver,
      driverName,
      driverAddress,
      driverPostalCode,
      driverCity,
      driverPhone,
      driverPhoneCountryCode,
      driverEmail,
      additionalDriver,
      additionalDriverName,
      additionalDriverPhone,
      additionalDriverPhoneCountryCode,
      additionalDriverSSN,
      isCompany,
      billPayment,
      companyName,
      companyBusinessId,
      companyBillingRef,
      companyBillingAddress,
      completeBefore,
    } = values;

    const oldVehicles = data?.reservationVehicles ?? [];
    const findOldField = (newId) => {
      if (!data?.id) return null; // New reservation, no old values exist
      return oldVehicles.find((oldVehicle) => newId === oldVehicle.id);
    };

    const reservationVehicles = vehicleIds.map((id) => {
      // Only update tax information, if a new Vehicle is added onto Reservation
      const oldValues = data?.reservationVehicles ?? [];
      const oldVehicle = findOldField(id);
      const tax = oldVehicle
        ? oldVehicle.tax ?? 24
        : offerPrice?.vehicleOfferPrices[id]?.tax;
      return {
        id: id,
        price: getEffectiveVehiclePrice(values, offerPrice, id),
        tax: tax,
      };
    });

    const startTimeUTC = zonedTimeToUtc(startTime, timezone);
    const returnTimeUTC = zonedTimeToUtc(returnTime, timezone);
    const endTimeUTC = zonedTimeToUtc(endTime || returnTime, timezone);

    try {
      // Parse the phone numbers to append the country codes

      const parsedPhone = phone ? phoneCountryCode + phone : "";
      const parsedDriverPhone = driverPhone
        ? driverPhoneCountryCode + driverPhone
        : "";
      const parsedAdditionalDriverPhone = additionalDriverPhone
        ? additionalDriverPhoneCountryCode + additionalDriverPhone
        : "";

      const reservation = {
        id,
        group,
        companyId: company.id,
        reservationVehicles: reservationVehicles,
        startTime: startTimeUTC,
        returnTime: returnTimeUTC,
        endTime: endTimeUTC,
        name,
        address,
        postalCode,
        city,
        phone: parsedPhone,
        email: email.trim(), // Remove leading and trailing white spaces
        ssn,
        internalNotes,
        rentalNotes,
        driverSSN,
        differentDriver,
        driverName,
        driverAddress,
        driverPostalCode,
        driverCity,
        driverPhone: parsedDriverPhone,
        driverEmail,
        additionalDriver,
        additionalDriverName,
        additionalDriverPhone: parsedAdditionalDriverPhone,
        additionalDriverSSN,
        isCompany,
        billPayment,
        companyName,
        companyBusinessId,
        companyBillingRef,
        companyBillingAddress,
        additionalServices: selectedAdditionalServices,
        language: values.language,
      };

      // Add Organization identifiers if available
      if (company.organizationId) {
        reservation.organizationId = company.organizationId;
      }
      if (company.orgBusinessId) {
        reservation.orgBusinessId = company.orgBusinessId;
      }

      if (
        data?.paymentData?.paymentTotal &&
        data.paymentData.paymentTotal !== totalPrice
      ) {
        // Customer has confirmed the price change
        if (!confirmPriceChange) {
          setShowPriceConfirmation(true);
          setSubmitting(false);
          return false; // Update not succesful
        } else {
          const oldTotal = data.paymentData.paymentTotal;
          const oldTodo = data.paymentData.paymentTodo;
          const difference = oldTotal - totalPrice;
          reservation.paymentData = data.paymentData;
          delete reservation.paymentData.__typename; // __typename may be included in Reservation queries, but does not exist on PaymentDataInputAdmin
          reservation.paymentData.paymentTotal = totalPrice;
          let newTodo = oldTodo - difference;
          if (newTodo < 0) newTodo = 0;
          reservation.paymentData.paymentTodo = newTodo;
          setShowPriceConfirmation(false);
        }
      }

      // Send link to user, through which the reservation can be
      // filled, paid and signed.
      if (values.sendStatusToCustomer) {
        reservation.paymentStatus = "PENDING";
        reservation.completeBefore = completeBefore;
        reservation.paymentData = {
          paymentTotal: totalPrice,
          paymentTodo: totalPrice,
        };
        if (company.enableMustSign) {
          reservation.authStatus = "PENDING_MUST";
        }
      }

      if (values.paymentMethod === "invoice") {
        reservation.paymentStatus = "PENDING_INVOICE";
      }

      // TODO: Should it be possible to use sendStatusToCustomer, billPayment and automatic removal together?
      if (data?.id && FEATURE_INVOICING && billPayment) {
        console.log("Set paymentStatus to PENDING_INVOICE");
        reservation.paymentStatus = "PENDING_INVOICE";
      }

      const response = await API.graphql(
        graphqlOperation(upsertReservationAdmin, {
          reservation: reservation,
          sendStatusToCustomer: values.sendStatusToCustomer,
        })
      );

      const result = response.data.upsertReservationAdmin;
      if (result.code != null) {
        Sentry.captureMessage("Reservation failed", {
          tags: {
            code: result.code,
          },
        });
        if (result.code === "VEHICLE_NOT_AVAILABLE") {
          notification.show("Ajoneuvo ei ole enää vapaana");
        } else {
          console.log(".......3");
          notification.show("Jokin meni vikaan");
        }
      } else {
        if (!result.emailConfirmationSuccess) {
          Sentry.captureMessage("Send confirmation email failed", {
            tags: {
              code: "CONFIRMATION_EMAIL_FAILED",
              destinationEmail: email,
            },
          });

          notification.show(
            "Sähköposti vahvistuksta ei voitu toimittaa asiakkaalle."
          );
        }

        if (closeDialog) {
          onClose(true);
        }
      }
      setSubmitting(false);
      return response;
    } catch (err) {
      captureError(
        "Upsert reservation failed",
        "UPSERT_RESERVATION_FAILED",
        err
      );
      notification.show(
        "Jokin meni vikaan, varauksen tallennus ei onnistunut."
      );
      setSubmitting(false);
      throw new Error(err);
    }

    /*     if (explanation !== review?.explanation) {
      try {
        await API.graphql(
          graphqlOperation(upsertReview, {
            review: {
              id: review.id,
              reservationId: review.reservationId,
              rating: review.rating,
              comment: review.comment,
              explanation: review.explanation,
            },
          })
        );
      } catch (err) {
        captureError("Upsert review failed", "UPSERT_REVIEW_FAILED", err);
        notification.show("Arvosanan syyn päivitys epäonnistui.");
        setSubmitting(false);
      }
    } */
  };

  const expirationHours = company?.reservationLinkExpirationHours || 1;
  const expirationMs = expirationHours * 3600000;

  const INITIAL_FORM_DATA = {
    vehicleIds: [],
    startTime: null,
    returnTime: null,
    endTime: null,
    completeBefore: new Date(new Date().getTime() + expirationMs), // Now + hour
    name: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    email: "",
    ssn: "",
    internalNotes: "",
    language: "fi",
    rentalNotes: "",
    differentDriver: false,
    driverName: "",
    driverSSN: "",
    driverAddress: "",
    driverPostalCode: "",
    driverCity: "",
    driverPhone: "",
    driverEmail: "",
    additionalDriver: false,
    additionalDriverName: "",
    additionalDriverPhone: "",
    additionalDriverSSN: "",
    isCompany: false,
    billPayment: false,
    companyName: "",
    companyBusinessId: "",
    companyBillingRef: "",
    companyBillingAddress: "",
    paymentStatus: "",
    sendStatusToCustomer: false,
    paymentMethod: "none",
  };

  useEffect(() => {
    console.log("company: ", company);
  }, [company]);
  const initializeFormikData = () => {
    // If reservation has Company information, make sure the correct company is selected
    try {
      if (data?.companyId && company.id !== data.companyId) {
        const companyFromReservation = companies.find(
          (c) => c.id === data.companyId
        );
        if (!companyFromReservation) {
          throw new Error("Company not found");
        }
        const test = companies.find((c) => c.id === data.companyId);
        console.log("Correcting company to", test);
        setCompany(test);
        //setCompany(companies.find((c) => c.id === data.companyId));
      }
    } catch (e) {
      captureError(
        `Set company ${data?.companyId} from reservation ${data?.id} failed`,
        "SET_RESERVATIONDIALOG_COMPANY_FAILED",
        e
      );
    }

    const resVehicles = data?.reservationVehicles;
    const vehicleIds = resVehicles ? resVehicles.map((v) => v.id) : [];
    const values = {
      ...INITIAL_FORM_DATA,
      ...data,
      vehicle: data?.vehicle?.id ?? "",
      vehicleIds: vehicleIds ?? [],
      // Make sure Checkbox values are Booleans
      differentDriver: !!data?.differentDriver,
      additionalDriver: !!data?.additionalDriver,
      isCompany: !!data?.isCompany,
      billPayment: !!data?.billPayment,
    };

    values.startTime = getZonedTime(data?.startTime, timezone) ?? null;
    values.returnTime = getZonedTime(data?.returnTime, timezone) ?? null;
    values.endTime = getZonedTime(data?.endTime, timezone) ?? null;

    if (!values.startTime) {
      const start = nextFullHour(getZonedTime(new Date(), timezone));
      values.startTime = start;
      if (!values.returnTime) {
        values.returnTime = nextFullHour(values.startTime);
      }
      if (!values.endTime) {
        values.endTime = values.returnTime;
      }
    }

    // Initialize additionalService data into Formik
    (data?.additionalServices ?? []).forEach((service) => {
      values[service.key + "Enabled"] = true;
      values[service.key + "Quantity"] = service.quantity;
      values[service.key + "Details"] = service.details;
      values[service.key + "Deductible"] = centsToLocalString(
        service.deductibleValue
      );
      values[service.key] = centsToLocalString(service.price);
    });

    // Initialize vehicles prices into Formik
    (data?.reservationVehicles ?? []).forEach((vehicle) => {
      // When opened from VehicleCalendar, no price is set
      if (vehicle.price || vehicle.price === 0) {
        values["vehiclePrice-" + vehicle.id] = centsToLocalString(
          vehicle.price
        );
      }
    });

    if (data?.phone) {
      let parsedPhoneNumber;
      try {
        // parse the phonenumber to get the phone country code for previously created reservations
        parsedPhoneNumber = parsePhoneNumber(data?.phone);
      } catch (error) {
        /*   console.error(
          "Error parsing phone number:",
          reservationData.phone,
          error.message,
        ); */
      }

      values.phone = parsedPhoneNumber?.nationalNumber ?? reservationData.phone;
      values.phoneCountryCode = parsedPhoneNumber
        ? `+${parsedPhoneNumber?.countryCallingCode}`
        : "+358";
    }
    if (data?.driverPhone) {
      let parsedPhoneNumber;
      try {
        // parse the phonenumber to get the phone country code for previously created reservations
        parsedPhoneNumber = parsePhoneNumber(data.driverPhone);
      } catch (error) {
        // console.error("Error parsing phone number:", error.message);
      }
      values.driverPhone =
        parsedPhoneNumber?.nationalNumber ?? data?.driverPhone;
      values.driverPhoneCountryCode =
        `+${parsedPhoneNumber?.countryCallingCode}` ?? "+358";
    }

    return values;
  };
  const formik = useFormik({
    initialValues: { ...INITIAL_FORM_DATA, ...initializeFormikData() },
    validate: (values) => {
      const errors = validate(values);

      // Validate vehicle prices
      for (let id of values.vehicleIds) {
        if (values["vehiclePrice-" + id]) {
          if (parseToCents(values["vehiclePrice-" + id]) === null) {
            errors["vehiclePrice-" + id] = 'Anna hinta muodossa "123,45"';
          }
        } else {
          // Ensure that non-filled field has a default value
          if (offerPrice?.vehicleOfferPrices[id]?.offerPrice === null) {
            errors["vehiclePrice-" + id] =
              "Listahinta ei saatavilla, täytä tämä kenttä";
          }
        }
      }
      // Validate additional service prices
      enabledServices.forEach((service) => {
        if (values[service.id + "Enabled"]) {
          //Apply validation only for selected services
          if (values[service.id]) {
            //Ensure that filled field is parseable price
            if (parseToCents(values[service.id]) == null) {
              errors[service.id] = 'Anna hinta muodossa "123,45"';
            }
          } else {
            //Ensure that non-filled field has default value
            if (
              offerPrice?.additionalServicePrices[service.id].offerPrice == null
            ) {
              errors[service.id] = "Täytä tämä kenttä";
            }
          }
        }
      });

      // check for overlaps

      if (values.vehicleIds && values.startTime && values.endTime) {
        const overlappingReservation = maybeOverlappingReservations
          ?.filter(
            (res) =>
              res.reservationVehicles.some((v) =>
                values.vehicleIds.some((v2) => v.id === v2)
              ) && res.id !== values.id
          )
          .find(
            (res) =>
              values.startTime < res.endTime && values.endTime > res.startTime
          );

        if (overlappingReservation) {
          errors.startTime = `Päällekkäinen varaus ${format(
            overlappingReservation.startTime,
            "dd.MM.yyyy HH:mm"
          )} -  ${format(overlappingReservation.endTime, "dd.MM.yyyy HH:mm")}`;
        }
      }

      return errors;
    },
    onSubmit,
  });

  const { maybeOverlappingReservations } = useReservationOverlap(
    formik.values?.startTime,
    formik.values?.returnTime
  );
  // Price of distance and deductible services is by default multiplied by the amount of vehicles
  const getPriceMultiplier = (category) => {
    if (["deductible", "distance"].includes(category)) {
      return formik.values.vehicleIds.length;
    }
    return 1;
  };

  const updateReview = (review) => {
    setReview(review);
  };

  const selectedAdditionalServices = useMemo(() => {
    return mapMaybe(enabledServices, (service) => {
      const enabled = formik.values[service.id + "Enabled"];
      const quantity = formik.values[service.id + "Quantity"];
      if (enabled) {
        const servicePriceStr = formik.values[service.id];
        let price = parseToCents(servicePriceStr);
        if (price == null) {
          if (quantity > 1) {
            price =
              offerPrice?.additionalServicePrices[service.id].offerPrice *
              quantity;
          } else {
            price =
              offerPrice?.additionalServicePrices[service.id].offerPrice *
              getPriceMultiplier(service.category);
          }
        }
        let deductible = parseToCents(formik.values[service.id + "Deductible"]);
        if (deductible === null) {
          deductible =
            offerPrice?.additionalServicePrices[service.id].deductibleValue;
        }
        if (price != null) {
          const oldServices = data?.additionalServices ?? [];
          const findOldField = (newKey) => {
            if (!data?.id) return null; // New reservation, no old values exist
            return oldServices.find((oldService) => newKey === oldService.key);
          };
          // Only update tax information, if a new Service is added onto Reservation
          const oldService = findOldField(service.id);
          const tax = oldService
            ? oldService.tax ?? 24
            : offerPrice?.additionalServicePrices[service.id].tax;
          return {
            key: service.id,
            price: price,
            details: formik.values[service.id + "Details"],
            deductibleValue: deductible,
            quantity: formik.values[service.id + "Quantity"] || 1,
            tax: tax,
          };
        }
      }
    });
  }, [data, enabledServices, formik.values, offerPrice, getPriceMultiplier]);

  const totalPrice = useMemo(() => {
    let effectiveVehicleTotalPrice = 0;
    for (let id of formik.values.vehicleIds) {
      const effectiveVehiclePrice = getEffectiveVehiclePrice(
        formik.values,
        offerPrice,
        id
      );
      if (effectiveVehiclePrice === null) {
        return null;
      }
      effectiveVehicleTotalPrice += effectiveVehiclePrice;
    }

    return selectedAdditionalServices.reduce(
      (p, c) => p + c.price,
      effectiveVehicleTotalPrice
    );
  }, [formik.values, offerPrice, selectedAdditionalServices]);

  const getContract = (reservation) => {
    if (FEATURE_EBIKERENTAL_CONTRACT) {
      return (
        <ContractTemplateEbikeRental
          reservation={reservation}
          company={company}
          additionalServices={enabledServices}
          vehicles={allVehicles}
        />
      );
    }
    if (FEATURE_JET_CONTRACT) {
      return (
        <ContractTemplateJetRental
          reservation={reservation}
          company={company}
          additionalServices={enabledServices}
          vehicles={allVehicles}
        />
      );
    }
    return (
      <OldContractTemplate
        reservation={reservation}
        company={company}
        additionalServices={enabledServices}
        vehicles={allVehicles}
      />
    );
  };

  useEffect(() => {
    // incase user is typing a date value, or something weird happens with dates, dont fetch a new price
    if (!isValid(formik.values.startTime)) return;
    if (!isValid(formik.values.returnTime)) return;

    const fetchOfferPrice = async (vehicleIds, startTime, returnTime) => {
      try {
        const companyId = company.id;
        for (const id of vehicleIds) {
          if (!getVehicleObjById(id)) {
            return "";
          }
        }
        const response = await API.graphql(
          graphqlOperation(getPriceByVehicleIds, {
            vehicleIds,
            startTime,
            returnTime,
            companyId,
          })
        );
        const newOfferPrices = response.data.getPriceByVehicleIds;
        setOfferPrice({
          vehicleOfferPrices: Object.fromEntries(
            newOfferPrices.vehicleOfferPrices.map((x) => [
              x.id,
              { offerPrice: x.price, tax: x.tax },
            ])
          ),
          additionalServicePrices: Object.fromEntries(
            newOfferPrices.additionalServiceOffers.map((x) => [
              x.key,
              {
                offerPrice: x.offerPrice,
                deductibleValue: x.deductibleValue,
                tax: x.tax,
              },
            ])
          ),
        });
      } catch (e) {
        captureError("Get price failed", "GET_PRICE_FAILED", e);
        setOfferPrice(null);
        notification.show("Jokin meni vikaan");
      }
    };
    async function updateOfferPrice() {
      if (
        formik.values.vehicleIds?.length > 0 &&
        formik.values.startTime &&
        formik.values.returnTime
      ) {
        const startTime = formik.values.startTime;
        const returnTime = formik.values.returnTime;
        const endTime = formik.values.endTime;
        // Don't try to fetch pricing if incorrect dates chosen
        if (
          returnTime < startTime ||
          endTime < returnTime ||
          startTime?.getTime() === returnTime?.getTime()
        ) {
          return;
        }
        if (offerPrice !== undefined) {
          setOfferPrice(undefined);
        }
        fetchOfferPrice(
          formik.values.vehicleIds,
          formik.values.startTime,
          formik.values.returnTime
        );
      } else {
        setOfferPrice(null);
      }
    }
    updateOfferPrice();
  }, [
    formik.values.vehicleIds,
    formik.values.startTime,
    formik.values.returnTime,
    formik.values.endTime,
    company.id,
  ]);

  // Set services that are enabled for the Vehicle,
  // or that are set on the reservation.
  // TODO: reservations: check services from all vehicles
  useMemo(() => {
    if (formik.values.vehicleIds.length === 0 || !sortedProducts) return;
    const activeVehicles = sortedProducts.filter((v) =>
      formik.values.vehicleIds.some((id) => id === v.id)
    );
    const servicesOnReservation = data?.additionalServices
      ? data.additionalServices
      : [];
    const servicesOnVehicles = [];
    for (let v of activeVehicles) {
      const services = v.useCategoryDefaults
        ? v.category.additionalServices.items
        : v.additionalServices.items;
      for (let s of services) {
        const service = servicesOnVehicles.find((s2) => s.key === s2.key);
        if (!service) {
          servicesOnVehicles.push(s);
          continue;
        }
      }
    }
    if (additionalServices) {
      const servicesByVehicle = additionalServices.filter(
        (s) =>
          servicesOnVehicles.find((vs) => vs.key === s.id) ||
          servicesOnReservation.find((rs) => rs.key === s.id)
      );
      setEnabledServices(servicesByVehicle);
    }
  }, [formik.values.vehicleIds, additionalServices]);

  const setValues = (s) => {
    formik.setFieldValue("name", s.name);
    formik.setFieldValue("address", s.address);
    formik.setFieldValue("email", s.email);
    formik.setFieldValue("postalCode", s.postalCode);
    formik.setFieldValue("city", s.city);
  };
  const getPersonalAutofillValues = async (str) => {
    let foundReservations;

    if (str) {
      /*
        Search by all matching all phrases, last one with prefix match
        =>
          Example data: "Firstname Lastname"
          Fill be found by:
            first
            last
            firstname last
            lastname first
          But not by:
            first lastname
      */
      const phrases = str.split(" ").filter((s) => s.length > 0);
      const searchFragments = phrases.map((phrase, pi) => {
        return {
          name:
            pi < phrases.length - 1
              ? {
                  matchPhrase: phrase,
                }
              : {
                  matchPhrasePrefix: phrase,
                },
        };
      });

      const response = await API.graphql(
        graphqlOperation(searchReservationsQuery, {
          filter: {
            and: searchFragments,
          },
          sort: {
            field: "startTime",
            direction: "desc",
          },
        })
      );
      foundReservations = distinctBy(
        response.data.searchReservations.items,
        (r) => r.email.toLowerCase()
      );
    } else {
      foundReservations = [];
    }

    setPersonalAutofillValues(foundReservations);
    setPersonalAutofillSearch(str ?? "");
  };

  useDebouncedEffect(
    () => {
      getPersonalAutofillValues(formik.values.name);
    },
    500,
    [formik.values.name]
  );

  const onRemove = async () => {
    setSubmitting(true);
    try {
      await API.graphql(
        graphqlOperation(deleteReservation, {
          input: {
            id: formik.values.id,
          },
        })
      );
      onClose(true);
    } catch (e) {
      captureError("Delete reservation failed", "DELETE_RESERVATION_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setShowDeleteConfirmation(false);
    setSubmitting(false);
  };

  const nameAutoCompleteLoading = personalAutofillSearch !== formik.values.name;

  const getVehicleObjById = (id) => {
    return allVehicles.find((v) => v.id === id);
  };

  const handleVehiclesChange = (e) => {
    if (e === "clear") return formik.setFieldValue("vehicleIds", []);
    setOfferPrice(null);

    const newVehicleIds = e.target.value;

    formik.setFieldValue("vehicleIds", newVehicleIds);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "80%",
        marginTop: "30px",
      },
    },
  };

  const resetSign = async () => {
    setSubmitting(true);
    await formik.submitForm();
    try {
      const result = await API.graphql(
        graphqlOperation(resetAndInitSign, {
          reservationId: data.id,
        })
      );
      if (result.data.resetAndInitSign.success) {
        notification.show(
          "Uusi sopimus on luotu ja allekirjoituspyyntö lähetetty"
        );
        await getUpdatedReservation();
      } else {
        throw new Error("Reset and init visma sign failed", data.id);
      }
    } catch (e) {
      console.error(e);
      captureError(
        "Reset and init Visma Sign failed",
        "RESET_VISMA_SIGN_FAILED",
        e
      );
      notification.show("Uutta sopimusta luodessa tapahtui virhe");
    }
    setSubmitting(false);
    setAnchorEl(null);
  };

  const initLivionKey = async () => {
    setSubmitting(true);
    await formik.submitForm();
    try {
      const result = await API.graphql(
        graphqlOperation(createLivionContract, {
          reservationId: data.id,
        })
      );
      if (result.data.createLivionContract.success) {
        notification.show("Livion Key koodi luotu");
        await getUpdatedReservation();
      } else {
        throw new Error("Create livion contract failed", data.id);
      }
    } catch (e) {
      console.error(e);
      captureError(
        "Create livion key contract failed",
        "CREATE_LIVION_FAILED",
        e
      );
      notification.show("Livion Key sopimuksen luonti epäonnistui");
    }
    setSubmitting(false);
    setAnchorEl(null);
  };

  const sendEmail = async () => {
    setSubmitting(true);
    await formik.submitForm();
    await getUpdatedReservation();
    try {
      const result = await API.graphql(
        graphqlOperation(sendEmailVerification, {
          reservationId: data.id,
        })
      );
      if (result.data.sendEmailVerification) {
        notification.show("Sähköposti lähetetty");
      } else {
        throw new Error("Email send failed", data.id);
      }
    } catch (e) {
      console.log(e);
      captureError("Send email verification failed", "SEND_EMAIL_FAILED", e);
      notification.show("Sähköpostin lähetys epäonnistui");
    }
    setSubmitting(false);
    setAnchorEl(null);
  };

  const priceDoesNotMatch = useMemo(() => {
    const paymentTotal = data?.paymentData?.paymentTotal;
    if (!paymentTotal) return false;
    if (paymentTotal !== totalPrice) {
      return true;
    }
  }, [totalPrice, data]);

  if (reservationData?.id === "settings-preview-reservation") {
    return null;
  }

  return (
    <DialogComponent
      open={true}
      dialogClose={() => {
        onClose();
      }}
      dialogDelete={
        formik.values.id ? () => setShowDeleteConfirmation(true) : null
      }
      maxWidth={formik.values.differentDriver ? "lg" : "md"}
      dialogAction={async () => {
        setCloseDialog(true);
        await formik.submitForm();
      }}
      dialogActionText={"Vahvista"}
      dialogActionDisabled={submitting}
      dialogActionSubmitting={submitting}
      additionalComponent={
        <>
          {formik.values.id && <span style={{ flex: 1 }} />}
          {formik.values.id && (
            <PrintingComponent
              submitForm={formik.submitForm}
              validate={formik.validateForm}
            />
          )}
          {!formik.values.id && (
            <Box
              sx={{
                display: "flex",
                flexDirection: mobileViewPort ? "column" : "row",
                flex: 1,
                justifyContent: "space-evenly",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="sendStatusToCustomer"
                      checked={formik.values.sendStatusToCustomer ?? false}
                      color="primary"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label="Lähetä asiakkaalle täydennettäväksi"
                />

                <Tooltip title="Asiakkaalle lähetetään linkki, jonka kautta varaus voidaan tehdä loppuun. Mikäli varausta ei suoriteta loppuun ennen määriteltyä aikaa, se poistetaan. ">
                  <InfoIcon color="info" sx={{ cursor: "pointer" }} />
                </Tooltip>
              </Box>

              {formik.values.sendStatusToCustomer && (
                <Box sx={{ marginTop: mobileViewPort ? "15px" : undefined }}>
                  <DateTimePicker
                    ampm={false}
                    disablePast
                    label="Vanhenemisaika"
                    value={formik.values.completeBefore}
                    onChange={async (date) => {
                      await formik.setFieldValue("completeBefore", date);
                    }}
                    onClose={() =>
                      formik.setFieldTouched("completeBefore", true)
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        name="completeBefore"
                        helperText={
                          formik.touched.completeBefore &&
                          formik.errors.completeBefore
                        }
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.completeBefore &&
                          !!formik.errors.completeBefore
                        }
                      />
                    )}
                  />
                </Box>
              )}
              {/* <DateTimePicker
                  label="Vanhenemisaika (legacy)"
                  inputVariant="outlined"
                  value={formik.values.completeBefore}
                  onChange={async (date) => {
                    await formik.setFieldValue("completeBefore", date);
                  }}
                  onBlur={formik.handleBlur}
                  name="completeBefore"
                  ampm={false}
                  autoOk
                  format="dd.MM.yyyy HH:mm"
                  minutesStep={5}
                  required
                  error={
                    formik.touched.completeBefore &&
                    !!formik.errors.completeBefore
                  }
                  helperText={
                    formik.touched.completeBefore &&
                    formik.errors.completeBefore
                  }
                  cancelLabel="Peruuta"
                /> */}
            </Box>
          )}
        </>
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="none"
        data-cy="reservationDialogForm"
      >
        <Grid
          container
          spacing={2}
          direction={mobileViewPort ? "column" : "row"}
        >
          <Grid container item justifyContent="flex-end">
            {data?.id && (
              <Button
                aria-controls="Lisävalinnat"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <MoreHorizIcon />
              </Button>
            )}

            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={sendEmail} disabled={submitting}>
                Tallenna ja lähetä varausvahvistus
              </MenuItem>
              {FEATURE_ENABLE_VISMA_SIGN && (
                <MenuItem
                  onClick={() => {
                    setShowSignConfirmation(true);
                    setAnchorEl(null);
                  }}
                  disabled={submitting}
                >
                  Luo ja lähetä uusi Visma Sign sopimus
                </MenuItem>
              )}
              {FEATURE_LIVION_KEY && (
                <MenuItem onClick={initLivionKey} disabled={submitting}>
                  Luo uusi Livion Key koodi
                </MenuItem>
              )}
            </Menu>
          </Grid>
          <Grid item md={formik.values.differentDriver ? 4 : 6}>
            <Grid container direction="column" spacing={containerSpacing}>
              <Grid item>
                <Typography variant="h6">Tuotteiden tiedot:</Typography>
              </Grid>
              <Box>
                {showChangeCompany ? (
                  <FormControl variant="outlined">
                    <Select
                      variant="standard"
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                      }}
                      placeholder=""
                      value={company.id}
                      onChange={(e) => {
                        handleVehiclesChange("clear");
                        setCompany(
                          companies.find(
                            (company) => company.id === e.target.value
                          )
                        );
                      }}
                      margin="dense"
                      renderValue={(value) => {
                        return (
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <StoreIcon
                              color="primary"
                              style={{ fontSize: 50 }}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography style={{ fontSize: 16 }}>
                                Toimipiste
                              </Typography>
                              <Typography style={{ fontSize: 13 }}>
                                {company?.name}
                              </Typography>
                            </div>
                          </Box>
                        );
                      }}
                    >
                      {companies &&
                        companies.map((company, idx) => (
                          <MenuItem value={company.id} key={idx}>
                            {company.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowChangeCompany(true)}
                    sx={{ marginLeft: "25px" }}
                  >
                    Vaihda toimipistettä
                  </Button>
                )}
              </Box>
              <Grid item>
                <FormControl
                  fullWidth
                  required
                  error={
                    !!formik.touched.vehicleIds && !!formik.errors.vehicleIds
                  }
                >
                  <InputLabel id="vehicleLabel">Tuotteet</InputLabel>
                  <Select
                    defaultValue={[]}
                    labelId="vehicleLabel"
                    id="reservation-id"
                    multiple
                    value={formik.values.vehicleIds}
                    onChange={handleVehiclesChange}
                    onBlur={formik.handleBlur}
                    name="vehicles"
                    label="Tuotteet"
                    MenuProps={MenuProps}
                    data-cy="reservationDialogSelectProducts"
                    renderValue={(selected) => (
                      <div>
                        {selected.map((id) => (
                          <MenuItem
                            key={id}
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "4px",
                              display: "flex",
                              flexWrap: "wrap",
                              margin: 2,
                            }}
                          >
                            <RegistrationPlate style={{ marginRight: 4 }}>
                              {getVehicleObjById(id)?.registrationPlate ??
                                "Poistettu tuote"}
                            </RegistrationPlate>
                            {getVehicleObjById(id)?.name ?? "Poistettu tuote"}
                          </MenuItem>
                        ))}
                      </div>
                    )}
                  >
                    {listProducts}
                  </Select>
                  {/* TODO: reservations: vehicles */}
                  {formik.touched.vehicleIds && formik.errors.vehicleIds && (
                    <FormHelperText>{formik.errors.vehicleIds}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {false && (
                <Grid item>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    error={formik.touched.vehicle && !!formik.errors.vehicle}
                  >
                    <InputLabel id="vehicleLabel">Auto</InputLabel>
                    <Select
                      labelId="vehicleLabel"
                      id="demo-simple-select-outlined"
                      value={formik.values.vehicle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="vehicle"
                      label="Auto"
                    >
                      {sortedProducts &&
                        sortedProducts.map((vehicle) => (
                          <MenuItem value={vehicle.id} key={vehicle.id}>
                            <RegistrationPlate style={{ marginRight: 4 }}>
                              {vehicle.registrationPlate}
                            </RegistrationPlate>
                            {vehicle.name}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.vehicle && formik.errors.vehicle && (
                      <FormHelperText>{formik.errors.vehicle}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item>
                <DateTimePicker
                  ampm={false}
                  label="Aloitusaika"
                  value={formik.values.startTime}
                  onChange={async (date) => {
                    // If changing multiple values, trigger validation only in last to avoid false-positive errors
                    const validate = !(date > formik.values.returnTime);
                    await formik.setFieldValue("startTime", date, validate);
                    if (date > formik.values.returnTime) {
                      await formik.setFieldValue(
                        "returnTime",
                        nextFullHour(date),
                        false
                      );
                      await formik.setFieldValue("endTime", nextFullHour(date));
                    }
                  }}
                  onClose={() => formik.setFieldTouched("startTime", true)}
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  ampm={false}
                  label="Palautusaika"
                  value={formik.values.returnTime}
                  onChange={async (date) => {
                    await formik.setFieldValue("returnTime", date);
                    await formik.setFieldValue("endTime", date);
                  }}
                  onClose={() => formik.setFieldTouched("returnTime", true)}
                  shouldDisableDate={(day) =>
                    isBefore(day, formik.values.startTime) &&
                    !isSameDay(day, formik.values.startTime)
                  }
                  shouldDisableTime={(time) =>
                    time.getTime() < formik.values.startTime.getTime()
                  }
                />
              </Grid>
              <Grid item>
                <DateTimePicker
                  ampm={false}
                  label="Vapaa uudelleen varattavaksi"
                  value={formik.values.endTime}
                  onChange={async (date) =>
                    await formik.setFieldValue("endTime", date)
                  }
                  onClose={() => formik.setFieldTouched("endTime", true)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      name="endTime"
                      error={formik.touched.endTime && !!formik.errors.endTime}
                      helperText={
                        formik.touched.endTime && formik.errors.endTime
                      }
                      onBlur={formik.handleBlur}
                    />
                  )}
                  shouldDisableDate={(day) =>
                    isBefore(day, formik.values.startTime) &&
                    !isSameDay(day, formik.values.startTime)
                  }
                  shouldDisableTime={(time) =>
                    time.getTime() < formik.values.startTime.getTime()
                  }
                />
                {/* <DateTimePicker
                  label="Vapaa uudelleen varattavaksi"
                  inputVariant="outlined"
                  value={formik.values.endTime}
                  onChange={(date) => formik.setFieldValue("endTime", date)}
                  onBlur={formik.handleBlur}
                  name="endTime"
                  ampm={false}
                  autoOk
                  format="dd.MM.yyyy HH:mm"
                  minutesStep={5}
                  fullWidth
                  minDate={formik.values.returnTime}
                  error={formik.touched.endTime && !!formik.errors.endTime}
                  helperText={formik.touched.endTime && formik.errors.endTime}
                  cancelLabel="Peruuta"
                /> */}
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.internalNotes ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="internalNotes"
                  label="Sisäiset muistiinpanot"
                  variant="outlined"
                  error={
                    formik.touched.internalNotes &&
                    !!formik.errors.internalNotes
                  }
                  helperText={
                    formik.touched.internalNotes && formik.errors.internalNotes
                  }
                  fullWidth
                  multiline={true}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.rentalNotes ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="rentalNotes"
                  label="Vuokraamon viesti asiakkaalle"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.externalNotes ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="externalNotes"
                  label="Asiakkaan viesti vuokraamolle"
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              {formik?.values.vehicleIds &&
                formik.values.vehicleIds.map((id) => {
                  return (
                    <PriceField
                      formik={formik}
                      name={"vehiclePrice-" + id}
                      isDefaultPriceLoading={offerPrice === undefined}
                      defaultPrice={
                        offerPrice?.vehicleOfferPrices[id]?.offerPrice
                      }
                      label={getVehicleObjById(id)?.name ?? "Poistettu tuote"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      key={id}
                    />
                  );
                })}
              <Grid item>
                <AdditionalServiceList
                  formik={formik}
                  categories={["normal"]}
                  services={enabledServices}
                  header="Lisäpalvelut:"
                  offerPrice={offerPrice}
                />
              </Grid>
              <AdditionalServiceList
                formik={formik}
                categories={["deductible"]}
                services={enabledServices}
                header="Omavastuu:"
                offerPrice={offerPrice}
                vehicleQuantity={getPriceMultiplier("deductible")}
              ></AdditionalServiceList>
              <AdditionalServiceList
                formik={formik}
                categories={["distance"]}
                services={enabledServices}
                header="Kilometrit:"
                offerPrice={offerPrice}
                vehicleQuantity={getPriceMultiplier("distance")}
              ></AdditionalServiceList>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={containerSpacing}
            md={formik.values.differentDriver ? 4 : 6}
          >
            <Grid item>
              <Typography variant="h6">Varaajan tiedot:</Typography>
            </Grid>
            <Grid item>
              <SelectReservationLanguageLegacy formik={formik} />
            </Grid>
            <Grid item>
              <Autocomplete
                freeSolo
                disableClearable
                onChange={(e, value) => {
                  formik.setValues({
                    ...formik.values,
                    name: value.name,
                    address: value.address,
                    postalCode: value.postalCode,
                    city: value.city,
                    phone: value.phone,
                    email: value.email,
                  });
                }}
                fullWidth
                inputValue={formik.values.name}
                options={personalAutofillValues}
                filterOptions={(options) => options}
                loading={nameAutoCompleteLoading}
                loadingText="Ladataan..."
                getOptionLabel={(option) => option?.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="none"
                    label="Nimi"
                    data-cy="reservationDialogNameField"
                    onChange={(e) =>
                      formik.setFieldValue("name", e.target.value)
                    }
                    onBlur={() => formik.setFieldTouched("name", true)}
                    variant="outlined"
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {nameAutoCompleteLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
                renderOption={(s, o) => {
                  return (
                    <Box {...s} key={o.id}>
                      <Box
                        sx={{ cursor: "pointer", padding: "10px" }}
                        onClick={() => {
                          setValues(o);
                        }}
                      >
                        <Typography variant="body1" display="block">
                          {o.name}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.email}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.address}
                        </Typography>
                        <Typography variant="caption" display="block">
                          {o.postalCode} {o.city}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="address"
                label="Osoite"
                variant="outlined"
                error={formik.touched.address && !!formik.errors.address}
                helperText={formik.touched.address && formik.errors.address}
                fullWidth
              />
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6} md={12} lg={6}>
                <TextField
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="postalCode"
                  label="Postinumero"
                  variant="outlined"
                  error={
                    formik.touched.postalCode && !!formik.errors.postalCode
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} md={12} lg={6}>
                <TextField
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="city"
                  label="Kaupunki"
                  variant="outlined"
                  error={formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item>
              <PhoneNumberField
                required
                name="phone"
                label="Puhelin"
                formik={formik}
                fieldConfig={{
                  name: "phone",
                  label: "Puhelin",
                  countryCodeField: "phoneCountryCode",
                }}
                fullWidth
                InputProps={{
                  style: {
                    color: theme.palette.primary.mainText,
                    backgroundColor: theme.palette.primary.dateRangeBg,
                  },
                }}
                separateDriver={false}
              />
            </Grid>
            {/* 
            These fields are necessary to prevent autofill for Safari browsers which causes errors in the dialog. 
            This is so far the best way that was found to handle this.
            */}
            <input
              type="password"
              style={{ position: "absolute", top: "-9999px" }}
              tabIndex="-1"
              autoComplete="new-password"
              aria-hidden="true"
            />
            <input
              type="text"
              style={{ position: "absolute", top: "-9999px" }}
              tabIndex="-1"
              autoComplete="username"
              aria-hidden="true"
            />
            {/* End of the field autofill fields */}
            <Grid item>
              <TextField
                value={formik.values.email ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="email"
                label="Sähköposti"
                variant="outlined"
                required
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                data-cy="reservationDialogEmailField"
              />
            </Grid>
            <Grid item>
              <TextField
                value={formik.values.ssn ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="ssn"
                label="Vuokraajan henkilötunnus"
                variant="outlined"
                type={showSSN ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowSSN} edge="end">
                        {showSSN ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={formik.touched.ssn && !!formik.errors.ssn}
                helperText={formik.touched.ssn && formik.errors.ssn}
                fullWidth
              />
            </Grid>
            <Grid item container>
              <FormControlLabel
                control={
                  <Checkbox
                    name="differentDriver"
                    checked={formik.values.differentDriver ?? false}
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={<>Kuljettaja eri kuin vuokraaja</>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isCompany"
                    checked={formik.values.isCompany ?? false}
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                }
                label={<>Yritysasiakas</>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="billPayment"
                    checked={formik.values.billPayment ?? false}
                    color="primary"
                    onChange={() =>
                      formik.setFieldValue(
                        "billPayment",
                        !formik.values.billPayment
                      )
                    }
                    onBlur={formik.handleBlur}
                  />
                }
                label={<>Maksu laskulla</>}
              />
            </Grid>
            {FEATURE_INVOICING && !data?.id && (
              <Grid item container spacing={2}>
                <Grid item container>
                  <FormControl fullWidth>
                    <InputLabel id="payment-select-label">Maksutapa</InputLabel>
                    <Select
                      labelId="payment-select-label"
                      id="payment-select"
                      value={formik.values.paymentMethod}
                      label="Maksutapa"
                      size="small"
                      name="paymentMethod"
                      onChange={formik.handleChange}
                    >
                      <MenuItem value={"none"}>Noudettaessa</MenuItem>
                      <MenuItem value={"invoice"}>{`Laskutus ${
                        FEATURE_INNOVOICE ? "Innovoice" : ""
                      }`}</MenuItem>
                      <MenuItem value={"online"}>Täydennyslinkki</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values.paymentMethod === "online" && (
                  <Grid item container>
                    <Box
                      sx={{ marginTop: mobileViewPort ? "15px" : undefined }}
                    >
                      <DateTimePicker
                        inputFormat="dd.MM.yyyy HH:mm"
                        ampm={false}
                        disablePast
                        label="Vanhenemisaika"
                        value={formik.values.completeBefore}
                        onChange={async (date) => {
                          await formik.setFieldValue("completeBefore", date);
                        }}
                        onClose={() =>
                          formik.setFieldTouched("completeBefore", true)
                        }
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            name="completeBefore"
                            helperText={
                              formik.touched.completeBefore &&
                              formik.errors.completeBefore
                            }
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.completeBefore &&
                              !!formik.errors.completeBefore
                            }
                          />
                        )}
                      />
                    </Box>
                    <Tooltip title="Asiakkaalle lähetetään linkki, jonka kautta varaus voidaan tehdä loppuun. Mikäli varausta ei suoriteta loppuun ennen määriteltyä aikaa, se poistetaan. ">
                      <InfoIcon color="info" sx={{ cursor: "pointer" }} />
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            )}
            {formik.values.isCompany && (
              <>
                <Grid item>
                  <TextField
                    value={formik.values.companyName ?? ""}
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="companyName"
                    label="Yrityksen nimi"
                    variant="outlined"
                    error={
                      formik.touched.companyName && !!formik.errors.companyName
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={formik.values.companyBillingAddress ?? ""}
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="companyBillingAddress"
                    label="Laskutusosoite"
                    variant="outlined"
                    error={
                      formik.touched.companyBillingAddress &&
                      !!formik.errors.companyBillingAddress
                    }
                    helperText={
                      formik.touched.companyBillingAddress &&
                      formik.errors.companyBillingAddress
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={6} md={12} lg={6}>
                    <TextField
                      value={formik.values.companyBusinessId ?? ""}
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="companyBusinessId"
                      label="Y-tunnus"
                      variant="outlined"
                      error={
                        formik.touched.companyBusinessId &&
                        !!formik.errors.companyBusinessId
                      }
                      helperText={
                        formik.touched.companyBusinessId &&
                        formik.errors.companyBusinessId
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} md={12} lg={6}>
                    <TextField
                      value={formik.values.companyBillingRef ?? ""}
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="companyBillingRef"
                      label="Yrityksen viite"
                      variant="outlined"
                      error={
                        formik.touched.companyBillingRef &&
                        !!formik.errors.companyBillingRef
                      }
                      helperText={
                        formik.touched.companyBillingRef &&
                        formik.errors.companyBillingRef
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <ReservationStatus
              data={data}
              formik={formik}
              totalPrice={totalPrice}
              company={company}
              invoices={invoices}
            />
            {FEATURE_INNOVOICE && data?.id && (
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        setInvoiceDialogState("FULL");
                      }}
                      disabled={data?.paymentStatus !== "PENDING_INVOICE"}
                    >
                      {" "}
                      Luo lasku{" "}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        console.log("testi");
                        setInvoiceDialogState("EXTRA");
                      }}
                    >
                      Jälkilaskutus{" "}
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <InnovoiceCredit
                    companyId={company.id}
                    ssn={formik.values.ssn}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          {formik.values.differentDriver && (
            <Grid
              item
              container
              direction="column"
              spacing={containerSpacing}
              md={4}
            >
              <Grid item>
                <Typography variant="h6">Kuljettajan tiedot:</Typography>
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.driverName ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="driverName"
                  label="Kuljettajan nimi"
                  variant="outlined"
                  error={
                    formik.touched.driverName && !!formik.errors.driverName
                  }
                  helperText={
                    formik.touched.driverName && formik.errors.driverName
                  }
                  fullWidth
                  required
                />
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.driverAddress ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="driverAddress"
                  label="Kuljettajan osoite"
                  variant="outlined"
                  error={
                    formik.touched.driverAddress &&
                    !!formik.errors.driverAddress
                  }
                  helperText={
                    formik.touched.driverAddress && formik.errors.driverAddress
                  }
                  fullWidth
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6} md={12} lg={6}>
                  <TextField
                    value={formik.values.driverPostalCode ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="driverPostalCode"
                    label="Kuljettajan postinumero"
                    variant="outlined"
                    error={
                      formik.touched.driverPostalCode &&
                      !!formik.errors.driverPostalCode
                    }
                    helperText={
                      formik.touched.driverPostalCode &&
                      formik.errors.driverPostalCode
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} md={12} lg={6}>
                  <TextField
                    value={formik.values.driverCity ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="driverCity"
                    label="Kuljettajan kaupunki"
                    variant="outlined"
                    error={
                      formik.touched.driverCity && !!formik.errors.driverCity
                    }
                    helperText={
                      formik.touched.driverCity && formik.errors.driverCity
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item>
                <PhoneNumberField
                  required
                  name="driverPhone"
                  label="Kuljettajan puhelinnumero"
                  formik={formik}
                  fieldConfig={{
                    name: "driverPhone",
                    label: "Kuljettajan puhelinnumero",
                    countryCodeField: "driverPhoneCountryCode",
                  }}
                  fullWidth
                  InputProps={{
                    style: {
                      color: theme.palette.primary.mainText,
                      backgroundColor: theme.palette.primary.dateRangeBg,
                    },
                  }}
                  separateDriver={false}
                />
              </Grid>
              <Grid item>
                <TextField
                  value={formik.values.driverEmail ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="driverEmail"
                  label="Kuljettajan sähköposti"
                  variant="outlined"
                  error={
                    formik.touched.driverEmail && !!formik.errors.driverEmail
                  }
                  helperText={
                    formik.touched.driverEmail && formik.errors.driverEmail
                  }
                  fullWidth
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item md={9}>
                  <TextField
                    value={formik.values.driverSSN ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="driverSSN"
                    label="Kuljettajan henkilötunnus"
                    variant="outlined"
                    type={showDriverSSN ? "text" : "password"}
                    error={
                      formik.touched.driverSSN && !!formik.errors.driverSSN
                    }
                    helperText={
                      formik.touched.driverSSN && formik.errors.driverSSN
                    }
                    fullWidth
                  />
                </Grid>
                <Button
                  color="primary"
                  onClick={handleClickShowDriverSSN}
                  variant={showDriverSSN ? "outlined" : "text"}
                >
                  {showDriverSSN ? "Piilota" : "Näytä"}
                </Button>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="additionalDriver"
                      checked={formik.values.additionalDriver ?? false}
                      color="primary"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label={<>Lisäkuljettaja</>}
                />
              </Grid>
              {formik.values.additionalDriver && (
                <>
                  <Grid item>
                    <TextField
                      value={formik.values.additionalDriverName ?? ""}
                      size="small"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="additionalDriverName"
                      label="Nimi"
                      variant="outlined"
                      error={
                        formik.touched.additionalDriverName &&
                        !!formik.errors.additionalDriverName
                      }
                      helperText={
                        formik.touched.additionalDriverName &&
                        formik.errors.additionalDriverName
                      }
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item>
                    <PhoneNumberField
                      required
                      name="additionalDriverPhone"
                      label="Puhelinnumero"
                      formik={formik}
                      fieldConfig={{
                        name: "additionalDriverPhone",
                        label: "Puhelinnumero",
                        countryCodeField: "additionalDriverPhoneCountryCode",
                      }}
                      fullWidth
                      InputProps={{
                        style: {
                          color: theme.palette.primary.mainText,
                          backgroundColor: theme.palette.primary.dateRangeBg,
                        },
                      }}
                      separateDriver={false}
                    />
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <TextField
                        value={formik.values.additionalDriverSSN ?? ""}
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="additionalDriverSSN"
                        label="Henkilötunnus"
                        variant="outlined"
                        type={showAdditionalSSN ? "text" : "password"}
                        error={
                          formik.touched.additionalDriverSSN &&
                          !!formik.errors.additionalDriverSSN
                        }
                        helperText={
                          formik.touched.additionalDriverSSN &&
                          formik.errors.additionalDriverSSN
                        }
                        fullWidth
                        required
                      />
                    </Grid>
                    <Button
                      color="primary"
                      onClick={handleClickShowAdditionalSSN}
                      variant={showAdditionalSSN ? "outlined" : "text"}
                    >
                      {showAdditionalSSN ? "Piilota" : "Näytä"}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </form>
      {!!invoiceDialogState && (
        <InvoiceDialog
          title="Laskutus"
          onClose={() => setInvoiceDialogState(false)}
          paymentType={invoiceDialogState}
          reservationId={data?.id}
          data={formik.values}
          servicesOnReservation={selectedAdditionalServices}
          updateInvoices={updateInvoices}
          FEATURE_INVOICING={FEATURE_INVOICING}
        />
      )}

      <DialogComponent
        open={showSignConfirmation}
        dialogClose={() => setShowDeleteConfirmation(false)}
        dialogAction={async () => {
          await resetSign();
          setShowSignConfirmation(false);
        }}
        dialogActionText={"Luo uusi sopimus"}
      >
        <Grid container spacing={2}>
          <Grid item>
            {data?.signDocumentId
              ? "Tämä operaatio luo uuden Visma Sign -sopimuksen, ja lähettää asiakkaalle kutsun allekirjoittamaan. Aiemmin luotu sopimus ja siihen mahdollisesti tehty allekirjoitus poistetaan."
              : "Tämä operaatio luo uuden Visma Sign -sopimuksen, ja lähettää asiakkaalle kutsun allekirjoittamaan"}
          </Grid>
          {submitting && (
            <Grid container item justifyContent="center">
              <Typography variant="body2" component="p">
                Odota hetki, sopimusta luodaan
              </Typography>
              <CircularProgress
                style={{ marginLeft: "5px" }}
                color="inherit"
                size={20}
              />
            </Grid>
          )}
        </Grid>
      </DialogComponent>
      <DialogComponent
        open={showDeleteConfirmation}
        dialogClose={() => setShowDeleteConfirmation(false)}
        dialogAction={onRemove}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
        testName="deleteReservationConfirmation"
      >
        Haluatko varmasti poistaa varauksen?
      </DialogComponent>
      <DialogComponent
        open={showPriceConfirmation}
        dialogClose={() => setShowPriceConfirmation(false)}
        dialogAction={() => onSubmit(formik.values, formik.actions, true)}
        dialogActionText={"Vahvista"}
        dialogCloseText={"Peruuta"}
        dialogActionColor={"primary"}
        dialogTitle={"Vahvista muuttunut hinta"}
      >
        {`Varauksen kokonaishinta on muuttunut ${centsToLocalString(
          data?.paymentData?.paymentTotal
        )} -> ${centsToLocalString(totalPrice)}`}
      </DialogComponent>
    </DialogComponent>
  );
}

const PaymentStatus = ({ data, formik, company }) => {
  const paymentStatusMsg = useMemo(() => {
    if (data?.paymentStatus === "COMPLETED") {
      return null;
    } else if (data?.paymentStatus === "PENDING") {
      // Reservations in PENDING state will be removed if not completed in time
      let deadlineDate = new Date(
        new Date(data?.updatedAt).getTime() + 60000 * 25
      ); // 25minutes
      if (data?.completeBefore) {
        const completeBeforeDate = new Date(data?.completeBefore);
        if (completeBeforeDate > deadlineDate) {
          deadlineDate = completeBeforeDate;
        }
      }
      const paymentBefore = new Date(deadlineDate);
      if (company.enablePaymentOnlineOnly) {
        return `Odottaa verkkomaksua ${format(
          paymentBefore,
          "HH:mm"
        )} mennessä`;
      }
      return `Odottaa maksutavan valintaa ${format(
        paymentBefore,
        "HH:mm"
      )} mennessä`;
    } else if (data?.paymentStatus === "COMPLETED_DEPOSIT") {
      return "Varausmaksu suoritettu";
    } else if (data?.paymentStatus === "PENDING_FULL") {
      return "Varausmaksu suoritettu. Odottaa verkkomaksua lopuista.";
    } else if (formik?.values.billPayment === true) {
      return "";
    } else if (
      data?.paymentStatus === "PENDING_INVOICE" ||
      formik?.values.paymentMethod === "invoice"
    ) {
      return "Odottaa laskutusta";
    } else {
      return "Maksu noudettaessa";
    }
  }, [
    data?.paymentStatus,
    formik.values.sendStatusToCustomer,
    formik.values.billPayment,
    formik.values.completeBefore,
    formik.values.paymentMethod,
  ]);

  return <Typography variant="h6">{paymentStatusMsg}</Typography>;
};

const PendingStatuses = ({ data, formik, company }) => {
  let completeBefore = data?.completeBefore;
  let sendStatus = false;
  if (
    formik.values.sendStatusToCustomer ||
    formik.values.paymentMethod === "online"
  ) {
    completeBefore = formik.values.completeBefore;
    sendStatus = true;
  }
  if (
    (data?.authStatus === "PENDING_MUST" &&
      data?.paymentStatus === "PENDING") ||
    (sendStatus && company.enableMustSign && company.enablePaymentOnlineOnly)
  ) {
    return (
      <Typography variant="h6">
        {`Odottaa vahvaa tunnistautumista ja maksutavan valintaa ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  // This state should not happen currently
  if (
    data?.authStatus === "PENDING_MUST" ||
    (sendStatus && company.enableMustSign)
  ) {
    return (
      <Typography variant="h6">
        {`Odottaa vahvaa tunnistautumista ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  if (sendStatus && isValid(completeBefore)) {
    return (
      <Typography variant="h6">
        {`Odottaa varauksen täydentämistä ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  return <PaymentStatus formik={formik} data={data} company={company} />;
};

const ReservationStatus = ({ data, totalPrice, formik, company, invoices }) => {
  const calculateInvoiceSum = (invoice) => {
    return invoice.reduce(
      (total, item) => total + item.quantity * item.unitprice,
      0
    );
  };
  return (
    <Grid item>
      <Typography variant="h6">
        <b>Yhteensä:</b>{" "}
        {totalPrice != null && Number.isInteger(totalPrice)
          ? centsToLocalString(totalPrice) + " €"
          : "-"}
      </Typography>
      <AuthenticationByPassNotification formik={formik} />
      <PendingStatuses data={data} formik={formik} company={company} />
      {invoices && (
        <Grid
          container
          direction="row"
          style={{
            maxHeight: "200px",
            overflow: "auto",
            marginTop: "10px",
            border: "1px solid #bdbdbd",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <Typography variant="h6">
            <b>Luodut laskut:</b>
          </Typography>
          {invoices
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((invoice) => (
              <Grid item xs={12} key={invoice.id}>
                <Card
                  style={{
                    marginBottom: ".3em",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {" "}
                  <CardContent>
                    <Typography variant="body1">
                      Luotu:{" "}
                      {new Date(invoice.createdAt).toLocaleDateString("fi-FI")}
                    </Typography>
                    <Typography variant="body1">
                      Summa:{" "}
                      {centsToLocalString(calculateInvoiceSum(invoice.items))} €
                    </Typography>
                    <Typography variant="body1">
                      Innovoice ID: {invoice.innovoiceId}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      )}
      {data?.signStatus === "PENDING" && (
        <>
          <Typography variant="h6">
            Odottaa sähköistä allekirjoitusta. Sign dokumentin ID:{" "}
            {data?.signDocumentId}
          </Typography>
        </>
      )}
      {data?.paymentStatus === "COMPLETED" && (
        <>
          <Typography variant="h6">Maksu suoritettu</Typography>
        </>
      )}
      {data?.authStatus === "COMPLETED" && (
        <>
          <Typography variant="h6">
            Vahvasti tunnistautunut: {data.signerName}{" "}
          </Typography>
        </>
      )}
      {data?.signStatus === "COMPLETED" && (
        <>
          <Typography variant="h6">
            Sähköisesti allekirjoittanut: {data.signerName}{" "}
          </Typography>
        </>
      )}
      {data?.livionData?.some((data) => data.pincode) &&
        data.livionData.map((data) => (
          <Typography variant="h6" key={`${data.pincode}-${data.id}`}>
            Livion pincode {data.name || ""}: {data.pincode}
          </Typography>
        ))}
      {(data?.paymentStatus === "PENDING" ||
        data?.paymentStatus === "PENDING_FULL") && (
        <>
          <Typography variant="h6">
            <a
              href={`${process.env.REACT_APP_HOSTING_URL_CUSTOMER}#/varaus?reservationId=${data?.id}`}
              target="_blank"
            >
              Täydennyslinkki
            </a>
          </Typography>
        </>
      )}
    </Grid>
  );
};

const fetch = async (group, dates) => {
  const start = dates[0];
  const end = dates[1];

  if (!group) {
    return [];
  } // group is mandatory for the fetch

  try {
    const user = await Auth.currentAuthenticatedUser();
    /*  const group = company?.group
      ? company.group
      : user.signInUserSession.idToken.payload["cognito:groups"][0]; */

    const reservations = (
      await API.graphql(
        graphqlOperation(getReservations, {
          startTime: start,
          endTime: end,
          group: group,
          queryMode: "existBetween",
        })
      )
    ).data.getReservations;

    if (reservations == null) {
      return;
    }

    return reservations;
  } catch (e) {
    console.log("fetchReservations error", e);
  }
};

function useReservationOverlap(startDate, endDate) {
  const { group } = useContext(AppContext);
  const dates = [startDate, endDate];
  const { data, error, isLoading, mutate } = useSWR(
    group && startDate && endDate
      ? ["getOverlappingReservations", group, dates]
      : null,
    ([group, dates]) => fetch(group, dates),
    {
      revalidateOnFocus: false,
    }
  );

  const reservationData = useMemo(() => {
    if (!data) return [];
    return data.map((item) => ({
      ...item,
      startTime: new Date(item.startTime),
      returnTime: new Date(item.returnTime),
      endTime: new Date(item.endTime),
    }));
  }, [data]);

  return {
    maybeOverlappingReservations: reservationData,
    reservationsLoading: isLoading,
    reservationsError: error,
    reservationsMutate: mutate,
  };
}

function AuthenticationByPassNotification({ formik }) {
  if (formik?.values?.authStatus === "CUSTOMER_BYPASS")
    return (
      <Grid item xs={12}>
        <Typography>Asiakkaalla ei ole suomalaista henkilötunnusta.</Typography>
      </Grid>
    );
}

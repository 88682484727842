import { Stack } from "@mui/material";
import PriceField from "./PriceField";
import DeductibleField from "./DeductibleField";
import useGetEffectivePrice from "../../../../../../../../hooks/useGetEffectivePrice";
import { useGetOfferPrices } from "../../../../../../../../../../hooks/useGetOfferPrices";
import { useFormikContext } from "formik";

export default function FieldInput(props) {
  const { expanded, vehicleId } = props;

  const [
    price,
    priceLoading,
    fieldInput,
    setFieldInput,
    deductibleInput,
    setDeductibleInput,
  ] = useGetEffectivePrice(vehicleId);
  const { values } = useFormikContext();

  const { offerPrices, offerPriceByCategory } = useGetOfferPrices(values);
  const offerPrice = offerPrices?.vehicleOfferPrices?.find(
    (offer) => offer.id === vehicleId
  );

  // item deductible is saved value for product. If not found, use listing price
  const listingDeductiblePrice =
    offerPriceByCategory?.deductible ?? offerPrice?.deductible;

  // offerPriceByCategory overrides offerPrices (selected contract price)
  const listingPrice = offerPriceByCategory?.price ?? offerPrice?.price;

  return (
    <Stack
      alignItems={"flex-end"}
      direction={"column"}
      justifyContent={"flex-end"}
      width={"100%"}
    >
      <PriceField
        listingPrice={listingPrice}
        priceLoading={priceLoading}
        price={price}
        vehicleId={vehicleId}
        expanded={expanded}
        fieldInput={fieldInput}
        setFieldInput={setFieldInput}
      />
      <DeductibleField
        listingDeductiblePrice={listingDeductiblePrice}
        vehicleId={vehicleId}
        expanded={expanded}
        deductibleInput={deductibleInput}
        setDeductibleInput={setDeductibleInput}
      />
    </Stack>
  );
}

import { Grid, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  createBusinessHoursException,
  updateBusinessHoursException,
  deleteBusinessHoursException,
} from "../graphql/mutations";
import { useNotification } from "./Notification";
import { useConfirmation } from "./Confirmation";
import { useFormik } from "formik";
import { formatISO } from "date-fns";
import { parseBusinessHours } from "../utils/business-hours-string";
import captureError from "../utils/capture-error";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogComponent from "./DialogComponent";
import { AppContext } from "../AppContext";
import { localeText } from "../utils/common";

const INITIAL_FORM_DATA = {
  date: null,
  name: "",
  businessHours: {
    openAt: "",
    closeAt: "",
  },
};

const validate = (values, specialHours) => {
  const errors = {};

  if (!values.date) {
    errors.date = "Täytä tämä kenttä";
  }

  const businessHoursResult = parseBusinessHours(values.businessHours);
  if (businessHoursResult.errors) {
    errors.businessHours = businessHoursResult.errors;
  }

  return errors;
};

const get = (obj, properties) => {
  return properties.reduce((value, property) => value?.[property], obj);
};

const FormikTextField = ({ formik, names, ...props }) => {
  return (
    <TextField
      value={get(formik.values, names) ?? ""}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={names.join(".")}
      variant="outlined"
      error={get(formik.touched, names) && !!get(formik.errors, names)}
      helperText={get(formik.touched, names) && get(formik.errors, names)}
      fullWidth
      {...props}
    />
  );
};

export default function SpecialHoursEditDialog({
  onClose,
  selected,
  specialHours,
  company,
}) {
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const { group, business } = useContext(AppContext);

  const isCreatingNew = selected.date == null;

  const onSubmit = async () => {
    setSubmitting(true);

    const { date, name, businessHours } = formik.values;

    const newBusinessHours = parseBusinessHours(businessHours).ok;
    const unifiedName = name ? name : null;
    const formatedDate = formatISO(date, { representation: "date" });

    try {
      if (!isCreatingNew) {
        await API.graphql(
          graphqlOperation(updateBusinessHoursException, {
            input: {
              group: group ?? undefined,
              orgBusinessId: business?.id ?? undefined,
              organizationId: business?.organizationId ?? undefined,
              date: formatedDate,
              name: unifiedName,
              businessHours: newBusinessHours,
              companyId: company.id,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createBusinessHoursException, {
            input: {
              group: group ?? undefined,
              orgBusinessId: business?.id ?? undefined,
              organizationId: business?.organizationId ?? undefined,
              date: formatedDate,
              name: unifiedName,
              businessHours: newBusinessHours,
              companyId: company.id,
            },
          })
        );
      }

      onClose(true);
      return;
    } catch (e) {
      captureError(
        "Upsert business hours exception failed",
        "UPSERT_BUSINESS_HOURS_EXCEPTION_FAILED",
        e
      );
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const [showDeleteConfirmation, DeleteConfirmationDialog] = useConfirmation(
    async () => {
      await API.graphql(
        graphqlOperation(deleteBusinessHoursException, {
          input: {
            companyId: selected.companyId,
            date: selected.date,
          },
        })
      );
      onClose(true);
    }
  );

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate: (values) => validate(values, specialHours),
    onSubmit,
  });

  useEffect(() => {
    if (selected) {
      formik.setValues({
        businessHours: {
          openAt: selected?.openAt ?? "",
          closeAt: selected?.closeAt ?? "",
        },
        name: selected.name ?? "",
        date: selected.date != null ? new Date(selected.date) : null,
      });
    } else {
      formik.setValues(INITIAL_FORM_DATA);
    }
    formik.setErrors({});
    formik.setTouched({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <DialogComponent
        open={true}
        dialogDelete={!isCreatingNew ? () => showDeleteConfirmation() : null}
        dialogClose={() => onClose()}
        maxWidth={"xs"}
        dialogTitle={"Erikoisaukioloaika"}
        dialogAction={formik.submitForm}
        dialogActionText={"Tallenna"}
        dialogActionSubmitting={submitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid
            item
            container
            direction="column"
            spacing={2}
            xs={12}
            sx={{ marginTop: "15px", marginBottom: "15px" }}
          >
            <Grid item xs={12}>
              <FormikTextField formik={formik} names={["name"]} label="Nimi" />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Päivä"
                value={formik?.values.date}
                localeText={localeText}
                disabled={!isCreatingNew}
                onChange={(date) => formik.setFieldValue("date", date)}
                onBlur={formik.handleBlur}
                name="date"
                autoOk
                inputFormat="dd.MM.yyyy"
                required
                error={formik.touched.date && formik.errors.date !== undefined}
                helperText={formik.touched.date && formik.errors.date}
                cancelLabel="Peruuta"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                names={["businessHours", "openAt"]}
                label="Aukeaa"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                names={["businessHours", "closeAt"]}
                label="Sulkeutuu"
              />
            </Grid>
          </Grid>
        </form>
      </DialogComponent>
      <DeleteConfirmationDialog
        message="Haluatko varmasti poistaa erikoisaukioloajan?"
        confirmLabel="Poista"
      />
    </>
  );
}

const services = [
  {
    key: "unlimitedDistance",
    id: "unlimitedDistance",
    description: "Rajattomat kilometrit",
    category: "distance",
    isDefault: true,
    hide: true,
  },
  {
    key: "handTruck",
    id: "handTruck",
    description: "Nokkakärryt",
    category: "normal",
    isDefault: true,
    hide: true,
  },
  {
    key: "pet",
    id: "pet",
    description: "Lemmikkimaksu",
    category: "normal",
    isDefault: true,
    hide: true,
  },
  {
    key: "exportLicense",
    id: "exportLicense",
    description: "Maastapoistumislupa",
    category: "normal",
    isDefault: true,
    hide: false,
    enableTextInput: true,
    inputLabel: "Kohdemaa",
  },
  {
    key: "halvedDeductible",
    id: "halvedDeductible",
    description: "Omavastuu puolitettu",
    category: "deductible",
    isDefault: true,
    hide: false,
  },
  {
    key: "noDeductible",
    id: "noDeductible",
    description: "Ei omavastuuta",
    category: "deductible",
    isDefault: true,
    hide: false,
  },
];

export default services;

import { useContext } from "react";
import { Box, Stack } from "@mui/material";

import { AppContext } from "../../../../../../../AppContext";
import { Features } from "../../../../../utils/enums";

import { FormContainer } from "../../../../../components/FormContainer";
import AdditionalInformation from "./components/AdditionalInformation";
import AvailableOffersMenu from "./components/AvailableOffers/components/AvailableOffersMenu";
import ExternalDataList from "./components/ExternalDataList";
import HanselData from "./components/HanselData";
import InsuranceDataList from "./components/InsuranceDataList";
import PickupLocations from "./components/PickupLocations";
import ReservationProducts from "./components/ReservationProducts/";
import ReservationSchedule from "../../../../../components/ReservationForm/ReservationSchedule";
import SelectContractCustomer from "./components/SelectContracCustomer";
import SelectContractVehicleClass from "./components/SelectContractVehicleClass";
import SelectVehicleClass from "./components/SelectVehicleClass";
import WildcardVehicle from "../../../../../../Reservation/common/WildcardVehicle";

import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../atoms/reservationDataAtom";
import { useField } from "formik";

export default function Summary(props) {
  const { containerRef } = props;
  const { USER_ADMIN_CORPORATE, USER_ADMIN_GENERIC } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);

  return (
    <Stack width={"100%"}>
      <FormContainer
        visibility={true}
        title={"Ajankohta, nouto- & palautuspiste"}
      >
        <Stack spacing={4}>
          <ReservationSchedule />
          <PickupLocations visibility={USER_ADMIN_GENERIC} />
        </Stack>
      </FormContainer>
      <FormContainer
        title={"Tuotteet"}
        visibility={USER_ADMIN_CORPORATE || USER_ADMIN_GENERIC}
      >
        <Products
          visibility={USER_ADMIN_CORPORATE || USER_ADMIN_GENERIC}
          containerRef={containerRef}
        />
      </FormContainer>
      <AdditionalInformation />
      <ExternalDataList />
      <InsuranceDataList visibility={reservationData?.insuranceData} />
      <WildcardVehicle visibility={reservationData?.wildCardVehicles} />

      <HanselData />
    </Stack>
  );
}

function Products(props) {
  const { selectedBusiness, containerRef } = props;
  const { user } = useContext(AppContext);

  const FEATURE_ENABLE_CONTRACT_CUSTOMER =
    Features.ENABLE_CONTRACT_CUSTOMER(user);
  const FEATURE_ENABLE_WILDCARD_RESERVATIONS =
    Features.ENABLE_WILDCARD_RESERVATIONS(user?.organizationId);
  const [, meta] = useField("wildCardVehicles");

  // addNew menutype doesnt handle defining the price for the product correctly
  const showChangeProductMenu = meta.value?.length > 0;
  return (
    <Stack
      flex={1}
      spacing={2}
      sx={{ maxWidth: { xs: "100%", sm: "810px" } }}
      ref={containerRef}
    >
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Box>
          <AvailableOffersMenu
            menuType={showChangeProductMenu ? null : "addNew"}
            containerRef={containerRef}
            buttonType={"regular"}
          />
        </Box>
        <SelectVehicleClass visibility={FEATURE_ENABLE_WILDCARD_RESERVATIONS} />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
        <Box width={{ xs: "90%", sm: "50%" }}>
          <SelectContractCustomer
            visibility={FEATURE_ENABLE_CONTRACT_CUSTOMER}
          />
        </Box>
        <Box width={{ xs: "90%", sm: "50%" }}>
          <SelectContractVehicleClass
            visibility={FEATURE_ENABLE_CONTRACT_CUSTOMER}
          />
        </Box>
      </Stack>
      <ReservationProducts selectedBusiness={selectedBusiness} />
    </Stack>
  );
}

import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { Cancel, Email, LocationOn, Phone } from "@mui/icons-material";
import { useFormikContext } from "formik";

export default function ServiceProviderCard({ formType }) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();

  const { user } = useContext(AppContext);

  const iconSize = "1.4rem";
  const selectedBusiness = user?.businessByOrg?.items.find(
    (b) => b.id === values?.orgBusinessId
  );

  return (
    <Box
      sx={{
        padding: "14px",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: 550 }}>
          {selectedBusiness?.name}
        </Typography>

        {formType === "insuranceClaim" && (
          <IconButton
            onClick={() => {
              setFieldValue("orgBusinessId", "");
            }}
          >
            <Cancel sx={{ color: theme.palette.warning.main }} />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ marginBottom: "10px" }} />
      <ContactInfo
        icon={<Phone sx={{ fontSize: iconSize }} />}
        text={selectedBusiness?.phone}
        visibility={selectedBusiness?.phone}
      />
      <ContactInfo
        icon={<Email sx={{ fontSize: iconSize }} />}
        text={selectedBusiness?.email}
        visibility={selectedBusiness?.email}
      />
      <ContactInfo
        icon={<LocationOn sx={{ fontSize: iconSize }} />}
        text={` ${selectedBusiness?.address}, ${selectedBusiness?.city}`}
        visibility={selectedBusiness?.address}
      />
    </Box>
  );
}

function ContactInfo({ icon, text, visibility }) {
  if (visibility)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
            color: "rgba(185,185,185,1)",
          }}
        >
          {icon}
        </Box>

        <Typography>{text}</Typography>
      </Box>
    );
}

import {
  Box,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";

export function TabPanel(props) {
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));
  const { typeOfProducts, children, value, index, ...other } = props;
  const { values } = useFormikContext();
  return (
    <Grid
      container
      direction="row"
      role="tabpanel"
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      sx={{
        margin: !mobileViewPort ? "20px" : undefined,
        height: !mobileViewPort ? "270px" : undefined,
        justifyContent: !mobileViewPort ? "space-evenly" : "center",
        alignItems: "center",
      }}
    >
      <Grid
        item
        container
        md={2}
        xs={12}
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        p={3}
        style={{ marginTop: 12 }}
      >
        <Typography align="left">{typeOfProducts[value].name}</Typography>
        <Box
          sx={{
            display: "flex",
            marginTop: "10px",
            borderRadius: "7px",
            justifyContent: "center",
            alignItems: "center",
            height: "125px",
            width: "125px",
            backgroundColor: values.backgroundColor || "white",
          }}
        >
          {typeOfProducts[value].image && (
            <img
              alt="Tuotekuva"
              src={typeOfProducts[value].image}
              style={{ width: 200 }}
            />
          )}
        </Box>
      </Grid>

      <Grid
        item
        md={7}
        xs={12}
        container
        direction="column"
        style={{
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "lightgrey",
          padding: 10,
          borderRadius: 7,
        }}
      >
        <Box>
          <Typography align="center">
            Tuotteelle määritettävät kentät
          </Typography>
        </Box>
        <Box>
          {typeOfProducts[value].additionalFields.map((item, index) => {
            const icon = (
              <div style={{ fontSize: 22, marginTop: 5, marginLeft: 10 }}>
                {item.icon}
              </div>
            );
            return (
              <Chip
                key={index}
                style={{ margin: 5, padding: 10 }}
                label={item.label}
                variant="outlined"
                icon={icon}
              />
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
}
export function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

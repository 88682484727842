import {
  TextField,
  Box,
  Typography,
  Stack,
  Divider,
  Avatar,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import { Delete, Edit, Home, LocationOn } from "@mui/icons-material";
import { useRef } from "react";
import { TfiSave } from "react-icons/tfi";
import { useNotification } from "../Notification";
import { API, graphqlOperation } from "aws-amplify";
import { updateBusiness, updateCompany } from "../../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { useFetchCompanies } from "../../services/fetchCompanies";

export default function PickupLocations() {
  const { business } = useContext(AppContext);

  const { companies } = useFetchCompanies(business?.id);

  const [companiesColorSettings, setCompaniesColorSettings] = useState(
    companies.map((c) => {
      return {
        id: c.id,
        locationColor: c?.locationColor,
        name: c.name,
        city: c.city,
      };
    })
  );

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        width: "100%",
      }}
    >
      <Stack
        spacing={2}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        direction={"column"}
        width={"100%"}
      >
        {companiesColorSettings.map((c) => {
          return (
            <DefaultCompany
              company={c}
              key={c.id}
              companiesColorSettings={companiesColorSettings}
              setCompaniesColorSettings={setCompaniesColorSettings}
            />
          );
        })}
        <Box></Box>
      </Stack>
    </Box>
  );
}

const PickupLocation = (props) => {
  const { company, locationData } = props;
  const { business, setUser, user } = useContext(AppContext);
  const [locationColor, setLocationColor] = useState(
    locationData?.locationColor
  );
  const [locationName, setLocationName] = useState(locationData?.name ?? "");
  const [submitting, setSubmitting] = useState(false);
  const notification = useNotification();
  const inputRef = useRef(null);
  const [enableEdit, setEnableEdit] = useState(false);
  const openColorPicker = () => {
    inputRef.current.click(); // Programmatically click the hidden color input
  };

  const handleAddNewPickupLocation = async (locationId) => {
    setSubmitting(true);
    try {
      const pickupLocation = {
        name: locationName,
        companyId: company?.id,
        locationColor: locationData?.locationColor ?? locationColor,
        id: locationId ? locationId : uuidv4(),
      };
      let pickupLocations;
      if (!locationId) {
        pickupLocations = business?.pickupLocations
          ? [...business?.pickupLocations, pickupLocation]
          : [pickupLocation];
      }
      if (locationId) {
        pickupLocations = business.pickupLocations.map((location) => {
          if (location?.id === locationId) {
            return pickupLocation;
          } else {
            return location;
          }
        });
      }
      const businessInput = {
        id: business.id,
        pickupLocations: pickupLocations,
      };
      await API.graphql(
        graphqlOperation(updateBusiness, {
          input: businessInput,
        })
      );
      setUser((prevState) => {
        const updateBusinessByOrg = user?.businessByOrg?.items.map((b) => {
          if (b.id === business.id) {
            return { ...b, pickupLocations: pickupLocations };
          } else {
            return b;
          }
        });
        return {
          ...prevState,
          businessByOrg: { items: updateBusinessByOrg },
        };
      });
    } catch (e) {
      console.log("error adding new pickup location", e);
      // handle error
      notification.show("Noutopisteen tallennus epäonnistui.", "error");
    }
    setEnableEdit(false);
    setSubmitting(false);
  };

  const onInputChangeHandler = (event) => {
    handleColorChange(event.target.value);
  };

  const throttledSubmitLocationColor = useMemo(() => {
    const submitLocationColor = async (locationColor) => {
      setSubmitting(true);

      try {
        const pickupLocations = business?.pickupLocations.map((location) => {
          if (location.id === locationData.id) {
            return { ...location, locationColor: locationColor };
          } else {
            return location;
          }
        });

        const businessInput = {
          id: business.id,
          pickupLocations: pickupLocations,
        };
        await API.graphql(
          graphqlOperation(updateBusiness, {
            input: businessInput,
          })
        );
        setUser((prevState) => {
          const updateBusinessByOrg = user?.businessByOrg?.items.map((b) => {
            if (b.id === business.id) {
              return { ...b, pickupLocations: pickupLocations };
            } else {
              return b;
            }
          });
          return {
            ...prevState,
            businessByOrg: { items: updateBusinessByOrg },
          };
        });
      } catch (e) {
        console.log("error adding new pickup location", e);
        // handle error
        //  notification.show("Noutopisteen tallennus epäonnistui.", "error");
      }
      setSubmitting(false);
    };

    return throttle(submitLocationColor, 500);
  }, [business, locationData, user, setUser]);

  const handleColorChange = useCallback(
    (locationColor) => {
      if (locationData) {
        throttledSubmitLocationColor(locationColor);
      } else {
        setLocationColor(locationColor);
      }
    },
    [throttledSubmitLocationColor, locationData]
  );

  const handleRemovePickupLocation = async (id) => {
    setSubmitting(true);
    try {
      const pickupLocations = business.pickupLocations.filter(
        (location) => location?.id !== locationData?.id
      );

      const businessInput = {
        id: business.id,
        pickupLocations: pickupLocations,
      };
      await API.graphql(
        graphqlOperation(updateBusiness, {
          input: businessInput,
        })
      );
      setUser((prevState) => {
        const updateBusinessByOrg = user?.businessByOrg?.items.map((b) => {
          if (b.id === business.id) {
            return { ...b, pickupLocations: pickupLocations };
          } else {
            return b;
          }
        });
        return {
          ...prevState,
          businessByOrg: { items: updateBusinessByOrg },
        };
      });
    } catch (e) {
      console.log("error adding new pickup location", e);
      // handle error
      notification.show("Noutopisteen tallennus epäonnistui.", "error");
    }
    setEnableEdit(false);
    setSubmitting(false);
  };

  return (
    <Stack
      direction={"row"}
      sx={{ marginLeft: "60px", marginTop: "15px" }}
      spacing={1}
      alignItems={"center"}
    >
      {submitting ? (
        <Box
          sx={{
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: locationData?.locationColor ?? locationColor,
            cursor: "pointer",
          }}
          onClick={openColorPicker}
        >
          <LocationOn />
        </Avatar>
      )}
      <TextField
        size="small"
        label="Noutopiste"
        value={locationName ?? ""}
        disabled={enableEdit ? false : !!locationData ? true : false}
        onChange={(e) => setLocationName(e.target.value)}
      />

      <input
        ref={inputRef}
        id="colorPicker"
        type="color"
        value={locationColor || "#f1faff"}
        onChange={(e) => onInputChangeHandler(e)}
        style={{ display: "none" }}
      />
      {(!locationData?.id || enableEdit) && (
        <IconButton
          disabled={!locationName || submitting}
          color={!locationName ? undefined : "success"}
          onClick={() => {
            if (!locationData) {
              handleAddNewPickupLocation();
              setLocationName("");
              setLocationColor(null);
            } else {
              handleAddNewPickupLocation(locationData?.id);
            }
          }}
        >
          <TfiSave />
        </IconButton>
      )}
      {locationData?.id && !enableEdit && (
        <IconButton
          variant="contained"
          onClick={() => setEnableEdit((prevState) => !prevState)}
        >
          <Edit />
        </IconButton>
      )}

      {enableEdit && (
        <IconButton
          onClick={() => handleRemovePickupLocation()}
          disabled={submitting}
        >
          <Delete />
        </IconButton>
      )}
    </Stack>
  );
};

const DefaultCompany = (props) => {
  const { company, handleRemovePickupLocation, setCompaniesColorSettings } =
    props;
  const { business } = useContext(AppContext);
  const inputRef = useRef();

  const { companiesMutate } = useFetchCompanies(business?.id);

  const openColorPicker = () => {
    inputRef.current.click(); // Programmatically click the hidden color input
  };

  const onInputChangeHandler = (event) => {
    handleColorChange(event.target.value);

    setCompaniesColorSettings((prevState) => {
      const updateCompanies = prevState.map((c) => {
        if (c.id === company?.id) {
          return { ...c, locationColor: event.target.value };
        } else {
          return c;
        }
      });
      return updateCompanies;
    });
  };

  const throttledSubmitLocationColor = useMemo(() => {
    const submitLocationColor = async (locationColor) => {
      try {
        const companyInput = {
          id: company?.id,
          locationColor: locationColor,
        };
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: companyInput,
          })
        );
        companiesMutate();
      } catch (e) {
        console.log("Error updating the color");
      }
    };

    return throttle(submitLocationColor, 500);
  }, [company?.id, companiesMutate]);

  const handleColorChange = useCallback(
    (locationColor) => {
      throttledSubmitLocationColor(locationColor);
    },
    [throttledSubmitLocationColor]
  );

  return (
    <Box width={"100%"}>
      <Stack
        sx={{
          flexDirection: "row",
          transition: "250ms ease-in-out",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ marginX: "10px" }}>
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: company?.locationColor ?? undefined,
              cursor: "pointer",
            }}
            onClick={openColorPicker}
          >
            <Home />
          </Avatar>
        </Box>
        <input
          ref={inputRef}
          id="colorPicker"
          type="color"
          value={company?.locationColor || "#f1faff"}
          onChange={(e) => onInputChangeHandler(e)}
          style={{ display: "none" }}
        />
        <Stack direction={"column"}>
          <Typography sx={{ fontWeight: 550 }} align="left">
            {company?.name}
          </Typography>
          <Typography align="left" variant="caption">
            {company?.city}
          </Typography>
        </Stack>
      </Stack>

      {business?.pickupLocations &&
        business?.pickupLocations.map((location) => {
          if (location?.companyId === company.id) {
            return (
              <PickupLocation
                key={location.id}
                locationData={location}
                company={company}
                handleRemovePickupLocation={handleRemovePickupLocation}
              />
            );
          } else {
            return null;
          }
        })}
      <PickupLocation company={company} />
      <Divider sx={{ marginY: "10px" }} />
    </Box>
  );
};
function throttle(cb, delay = 250) {
  let shouldWait = false;

  return (...args) => {
    if (shouldWait) return;

    cb(...args);
    shouldWait = true;
    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}

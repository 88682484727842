import {
  AppBar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Button,
  Grid,
  TextField,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCategory } from "../../graphql/mutations";
import { useNotification } from "../Notification";
import { Formik, useFormikContext } from "formik";
import captureError from "../../utils/capture-error";
import PricingSelection from "../PricingSelection";
import AdditionalServicePricing from "../AdditionalServicePricing";
import { mapMaybe } from "../../utils/array-utilities";
import {
  createAdditionalServicePricing,
  updateAdditionalServicePricing,
  deleteAdditionalServicePricing,
} from "../../graphql/mutations";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { typeOfProducts } from "../../shared/ProductClassification/type-of-products";
import { TabPanel, a11yProps } from "./TabPanel";
import { useFetchAdditionalServices } from "../../services/fetchAdditionalServices";

import { Done } from "@mui/icons-material";
import DialogComponent from "../DialogComponent";
import { AppContext } from "../../AppContext";
import { useFetchPricings } from "../../services/fetchPricings";
import { useContextFetchCompanies } from "../../services/fetchCompanies";
const DEFAULT_COLOR = "#f1faff";
const INITIAL_FORM_DATA = {
  name: "",
  orderNumber: null,
  minuteBuffer: null,
  backgroundColor: DEFAULT_COLOR,
  categoryPricingId: "",
};

export default function CategoriesCreateDialog({
  onClose,
  category,
  company,
  showPricings,
}) {
  const { group, user } = useContext(AppContext);
  const { pricings } = useFetchPricings(group, user);
  const { companiesMutate } = useContextFetchCompanies();
  const { additionalServices } = useFetchAdditionalServices();

  const [selectedCategoryType, setSelectedCategoryType] = useState(0);
  const [typeId, setTypeId] = useState(typeOfProducts[0].id);
  const handleChangeCategoryType = (event, newValue) => {
    setTypeId(typeOfProducts[newValue].id);
    setSelectedCategoryType(newValue);
  };
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const handleChangeStep = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const filteredServices = React.useMemo(() => {
    if (additionalServices) {
      return additionalServices.filter(
        (service) => !(service._removed || service.isDefault)
      );
    }
  }, [additionalServices]);

  const advanceStep = (panel) => {
    setExpanded(panel);
  };

  const onSubmit = async (values) => {
    setSubmitting(true);
    const {
      name,
      minuteBuffer,
      orderNumber,
      backgroundColor,
      categoryPricingId,
    } = values;

    let categoryId;
    try {
      const input = {
        name: name,
        group: group ?? undefined,
        organizationId: user?.organizationId ?? undefined,
        typeId: typeId,
        orderNumber: orderNumber || null,
        minuteBuffer: minuteBuffer || null,
        backgroundColor: backgroundColor || null,
        categoryPricingId: categoryPricingId || null,
      };

      const response = await API.graphql(
        graphqlOperation(createCategory, {
          input: input,
        })
      );
      const createdCategory = response.data.createCategory;
      categoryId = createdCategory.id;
      const additionalServiceFields = mapMaybe(additionalServices, (k) => {
        const fieldValue = values[k.id];
        if (fieldValue) {
          return {
            group: group ?? undefined,
            organizationId: user?.organizationId ?? undefined,
            key: k.id,
            categoryId: categoryId,
            pricingId: fieldValue,
          };
        }
      });

      const oldAdditionalServices = category?.additionalServices?.items ?? [];

      const findOldField = (newFieldValue) =>
        oldAdditionalServices.find(
          (oldFieldValue) => newFieldValue.key === oldFieldValue.key
        );

      const createPromises = additionalServiceFields
        .filter((newFieldValue) => findOldField(newFieldValue) == null)
        .map(async (newFieldValue) => {
          await API.graphql(
            graphqlOperation(createAdditionalServicePricing, {
              input: newFieldValue,
            })
          );
        });

      const updatePromises = additionalServiceFields.map(
        async (newFieldValue) => {
          const oldFieldValue = findOldField(newFieldValue);
          if (
            oldFieldValue != null &&
            oldFieldValue.pricingId !== newFieldValue.pricingId
          ) {
            await API.graphql(
              graphqlOperation(updateAdditionalServicePricing, {
                input: {
                  id: oldFieldValue.id,
                  pricingId: newFieldValue.pricingId,
                },
              })
            );
          }
        }
      );

      const deletePromises = oldAdditionalServices
        .filter((oldFieldValue) => !values[oldFieldValue.key])
        .map(async (oldFieldValue) => {
          await API.graphql(
            graphqlOperation(deleteAdditionalServicePricing, {
              input: { id: oldFieldValue.id },
            })
          );
        });

      Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
      /* const newCat = (
        await API.graphql(graphqlOperation(getCategory, { id: categoryId }))
      ).data.getCategory;
       */
      if (category.id) {
        companiesMutate();
      }
      onClose();
      return;
    } catch (e) {
      captureError("Upsert Category failed", "UPSERT_CATEGORY_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Formik
          onSubmit={onSubmit}
          initialValues={INITIAL_FORM_DATA}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = "Täytä tämä kenttä";
            }

            return errors;
          }}
        >
          <DialogForm
            category={category}
            expanded={expanded}
            handleChangeStep={handleChangeStep}
            selectedCategoryType={selectedCategoryType}
            handleChangeCategoryType={handleChangeCategoryType}
            advanceStep={advanceStep}
            showPricings={showPricings}
            pricings={pricings}
            filteredServices={filteredServices}
            company={company}
            onClose={onClose}
            submitting={submitting}
          />
        </Formik>
      </Box>
    </>
  );
}

function DialogForm(props) {
  const {
    category,
    expanded,
    handleChangeStep,
    selectedCategoryType,
    handleChangeCategoryType,
    advanceStep,
    showPricings,
    pricings,
    filteredServices,
    company,
    onClose,
    submitting,
  } = props;
  const {
    setFieldValue,
    values,
    setValues,
    setErrors,
    setTouched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormikContext();
  useEffect(() => {
    if (category.id) {
      const selectedAdditionalServicePricings = Object.fromEntries(
        (category.additionalServices.items ?? []).map((as) => [
          as.key,
          as.pricingId,
        ])
      );
      setValues({
        ...INITIAL_FORM_DATA,
        ...category,
        ...selectedAdditionalServicePricings,
        categoryPricingId: category.pricing?.id,
      });
    } else {
      setValues(INITIAL_FORM_DATA);
    }
    setErrors({});
    setTouched({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const setBackgroundColor = (nextValue) => {
    setFieldValue("backgroundColor", nextValue);
  };

  const debounceColorChange = useCallback(
    debounce((nextValue) => setBackgroundColor(nextValue), 50),
    []
  );

  // Chrome click&drag style colorpicker's value can change often and slow things down, hence the debounce
  const onInputChangeHandler = (e) => {
    const nextValue = e.target.value;
    debounceColorChange(nextValue);
  };
  return (
    <DialogComponent
      open
      dialogAction={submitForm}
      dialogActionText={"Tallenna"}
      dialogClose={() => onClose()}
      maxWidth={"lg"}
      dialogActionSubmitting={submitting}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeStep("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              transition: "250ms ease-in-out",
            }}
          >
            <Avatar
              style={{
                color: "white",
                backgroundColor:
                  expanded !== "panel1" ? "limegreen" : "#485e88",

                transition: "250ms ease-in-out",
                marginRight: 10,
              }}
            >
              {expanded !== "panel1" ? <Done /> : "1"}
            </Avatar>
            {expanded !== "panel1" ? (
              <Typography>
                Tuoteryhmä: {typeOfProducts[selectedCategoryType]?.name}
              </Typography>
            ) : (
              <Typography>Valitse tuoteryhmä</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display={"flex"} flexDirection={"column"}>
            <AppBar
              position="static"
              color="default"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <Tabs
                value={selectedCategoryType}
                onChange={handleChangeCategoryType}
                variant="scrollable"
                scrollButtons
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
                allowScrollButtonsMobile
              >
                {typeOfProducts.map((item, index) => {
                  return (
                    <Tab
                      id={item.id}
                      key={item.id}
                      label={item.name}
                      icon={item.icon}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            <TabPanel
              value={selectedCategoryType}
              index={0}
              typeOfProducts={typeOfProducts}
            ></TabPanel>
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <label htmlFor="colorPicker">Taustaväri: </label>
              <input
                id="colorPicker"
                type="color"
                style={{ marginLeft: ".4rem" }}
                value={values.backgroundColor || DEFAULT_COLOR}
                onChange={(e) => onInputChangeHandler(e)}
              />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => advanceStep("panel2")}
              >
                Valitse {typeOfProducts[selectedCategoryType]?.name}
              </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangeStep("panel2")}
        disabled={
          expanded === "panel1" ? (values?.name > 2 ? false : true) : false
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                color: "white",
                backgroundColor:
                  expanded === "panel3" ? "limegreen" : "#485e88",
                transition: "250ms ease-in-out",
                marginRight: 10,
              }}
            >
              {expanded === "panel3" ? <Done /> : "2"}
            </Avatar>
            <div style={{ transition: "250ms ease-in-out" }}>
              {expanded === "panel3" || values.name > 2 ? (
                <Typography>Kategorian nimi: {values?.name}</Typography>
              ) : (
                <Typography>Lisää Kategorian tiedot</Typography>
              )}
            </div>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            data-cy="createCategoryDialogDetailsPage"
          >
            <Grid item xs={12} md={7} data-cy="createCategoryDialogNameField">
              <TextField
                name="name"
                label="Nimi"
                required
                autoFocus
                value={values.name ?? ""}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              data-cy="createCategoryDialogOrderNumberField"
            >
              <TextField
                value={values.orderNumber || ""}
                name="orderNumber"
                label="Järjestysnumero"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <PricingSelection
                showPricings={showPricings}
                pricings={pricings}
                name={"categoryPricingId"}
                label="Hinnasto"
              />
            </Grid>
            <Grid item xs={12} md={5} data-cy="createCategoryDialogMinuteField">
              <TextField
                value={values.minuteBuffer || ""}
                name="minuteBuffer"
                label="Varoaika (min)"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{ width: "100%" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => advanceStep("panel3")}
                disabled={values.name?.length >= 2 ? false : true}
                sx={{ marginTop: "15px" }}
              >
                Valitse Lisäpalvelut
              </Button>
            </Box>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChangeStep("panel3")}
        disabled={values.name?.length >= 2 ? false : true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                color: "white",
                backgroundColor: "#485e88",

                transition: "250ms ease-in-out",
                marginRight: 10,
              }}
            >
              {expanded === "panel3" ? <Done /> : "3"}
            </Avatar>
            <Typography>Valitse Lisäpalvelut</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <AdditionalServicePricing
            additionalServices={filteredServices}
            enabledServices={filteredServices}
            showPricings={showPricings}
            pricings={pricings}
            company={company}
            //label={service.description}
          />
        </AccordionDetails>
      </Accordion>
    </DialogComponent>
  );
}

import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";

import { fi } from "date-fns/locale";

import {
  Box,
  ClickAwayListener,
  TableCell,
  Typography,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import "./reservationCalendar.css";
import {
  add,
  areIntervalsOverlapping,
  differenceInDays,
  endOfDay,
  format,
  getDaysInMonth,
  getHours,
  isToday,
  set,
} from "date-fns";

import { useFetchReservations } from "../../services/fetchReservations";
import { useFetchVehicles } from "../../services/fetchVehicles";
import { useNotification } from "../Notification";
import transferReservation from "../TransferReservation";
import { MdPostAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";

import { MdMoveUp } from "react-icons/md";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ReservationTooltip from "../ReservationTooltip";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";

export default function ReservationsCalendar() {
  const {
    rowDisplayStyle,
    mobileViewPort,
    reservationDialogVersion,
    selectedView,
    startDate,
  } = useContext(AppContext);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { reservations, reservationsMutate, unassignedReservations } =
    useFetchReservations("existBetween");
  const { vehiclesByCategoryNew } = useFetchVehicles();
  const theme = useTheme();
  const { show } = useNotification();
  const navigate = useNavigate();
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [transferData, setTransferData] = useState("vehicle");

  const daysVisible = useMemo(() => {
    if (selectedView === "monthly") {
      return getDaysInMonth(startDate);
    }
    if (selectedView === "weekly") {
      return 7;
    }
    if (selectedView === "daily") {
      return 1;
    }
  }, [selectedView, startDate]);

  const dates = useMemo(() => {
    let date = startDate;

    return new Array(daysVisible)
      .fill(null)
      .map((_, idx) => add(date, { days: idx }));
  }, [daysVisible, startDate]);

  const handleTransferReservation = useCallback(
    async ({ transferToVehicle, transferToCategory }) => {
      if (typeof transferData === "object") {
        if (
          !transferData?.reservation?.reservationVehicles.some(
            (resVehicle) => resVehicle.id === transferToVehicle?.id
          )
        ) {
          const reservation = transferData?.reservation; // reservation object
          const fromVehicle = transferData?.fromVehicle; // the id of the vehicle that is being replaced
          const fromWildCard = transferData?.fromWildCard;
          setTransferData("vehicle");
          const result = await transferReservation(
            reservation,
            transferToVehicle,
            fromVehicle,
            fromWildCard,
            transferToCategory
          );

          if (result?.success) {
            reservationsMutate(
              reservations.map((res) => {
                if (reservation.id === res.id) {
                  return result?.reservation;
                } else return res;
              }),
              {
                optimisticData: reservations.map((res) => {
                  if (reservation.id === res.id) {
                    return result?.reservation;
                  } else return res;
                }),
                revalidate: false,
              }
            );
            show("Varaus siirretty!", "success");
          }
          if (!result?.success) {
            show("Varauksen siirto epäonnistui!", "error");
          }
        } else {
          return null;
        }
        setSelectedReservation(null);
        setSelectedVehicle(null);
      }
    },
    [reservations, show, reservationsMutate, transferData]
  );

  const items = reservations
    .filter((reservation) => {
      const startDate = new Date(reservation.startTime);
      const endDate = new Date(reservation.endTime);
      // Check if the reservation overlaps with the dates
      return areIntervalsOverlapping(
        { start: startDate, end: endDate },
        { start: dates[0], end: endOfDay(dates[dates.length - 1]) }
      );
    })
    .map((reservation, index) => {
      let startDate = new Date(reservation.startTime);
      let endTime = new Date(reservation.endTime);
      const lastItemOfDates = endOfDay(dates[dates.length - 1]);
      let day;
      if (startDate < dates[0]) {
        day = 0;
        startDate = dates[0]; // Adjust the start date for the length calculation
      } else if (startDate > lastItemOfDates) {
        day = dates.length - 1;
      } else {
        day = differenceInDays(startDate, dates[0]); // Calculate the difference in days
      }

      if (endTime > lastItemOfDates) {
        endTime = lastItemOfDates; // Adjust the end time for the length calculation
      }

      const start = startDate.getHours();
      const startMinute = startDate.getMinutes();
      let length = Math.ceil((endTime - startDate) / (1000 * 60 * 30)); // Length in half-hours

      // If the reservation starts before dates[0] and ends after dates[dates.length - 1]
      // the length should be the total number of half-hours in the date range
      if (
        new Date(reservation.startTime) < dates[0] &&
        new Date(reservation.endTime) > lastItemOfDates
      ) {
        length = dates.length * 24 * 2; // Total number of half-hours in the date range
      }

      // If the reservation ends after dates[dates.length - 1]
      // the length should be calculated from startDate to dates[dates.length - 1]
      if (new Date(reservation.endTime) > lastItemOfDates) {
        length = Math.ceil((lastItemOfDates - startDate) / (1000 * 60 * 30));
      }

      if (isNaN(length)) {
        throw new Error(`Invalid length in reservation ${index + 1}`);
      }

      return {
        day,
        start,
        startMinute: startDate < dates[0] ? 0 : startMinute, // Adjust the start minute
        length,
        content: ` ${format(reservation.startTime, "HH:mm")} - ${format(
          reservation.endTime,
          "HH:mm"
        )}`,
        row: 0, // All reservations are in the same row
        ...reservation,
      };
    });

  const unassignedItems = unassignedReservations
    .filter((reservation) => {
      const startDate = new Date(reservation.startTime);
      const endDate = new Date(reservation.endTime);
      // Check if the reservation overlaps with the dates
      return areIntervalsOverlapping(
        { start: startDate, end: endDate },
        { start: dates[0], end: endOfDay(dates[dates.length - 1]) }
      );
    })
    .map((reservation, index) => {
      let startDate = new Date(reservation.startTime);
      let endTime = new Date(reservation.endTime);
      const lastItemOfDates = endOfDay(dates[dates.length - 1]);
      let day;
      if (startDate < dates[0]) {
        day = 0;
        startDate = dates[0]; // Adjust the start date for the length calculation
      } else if (startDate > lastItemOfDates) {
        day = dates.length - 1;
      } else {
        day = differenceInDays(startDate, dates[0]); // Calculate the difference in days
      }

      if (endTime > lastItemOfDates) {
        endTime = lastItemOfDates; // Adjust the end time for the length calculation
      }

      const start = startDate.getHours();
      const startMinute = startDate.getMinutes();
      let length = Math.ceil((endTime - startDate) / (1000 * 60 * 30)); // Length in half-hours

      // If the reservation starts before dates[0] and ends after dates[dates.length - 1]
      // the length should be the total number of half-hours in the date range
      if (
        new Date(reservation.startTime) < dates[0] &&
        new Date(reservation.endTime) > lastItemOfDates
      ) {
        length = dates.length * 24 * 2; // Total number of half-hours in the date range
      }

      // If the reservation ends after dates[dates.length - 1]
      // the length should be calculated from startDate to dates[dates.length - 1]
      if (new Date(reservation.endTime) > lastItemOfDates) {
        length = Math.ceil((lastItemOfDates - startDate) / (1000 * 60 * 30));
      }

      if (isNaN(length)) {
        throw new Error(`Invalid length in reservation ${index + 1}`);
      }

      return {
        day,
        start,
        startMinute: startDate < dates[0] ? 0 : startMinute, // Adjust the start minute
        length,
        content: ` ${format(reservation.startTime, "HH:mm")} - ${format(
          reservation.endTime,
          "HH:mm"
        )}`,
        row: 0, // All reservations are in the same row
        ...reservation,
      };
    });
  const vehiclesByCategoryWithUnassigned = useMemo(() => {
    const newItems = [];
    vehiclesByCategoryNew?.forEach((category) => {
      if (category?.categoryTitle) {
        const unassigned = unassignedItems.filter(
          (item) =>
            item?.initialClassInfo?.categoryName === category?.categoryTitle
        );
        newItems.push({
          ...category,
        });
        unassigned.forEach((item) => {
          newItems.push({
            ...item,
          });
        });
      } else {
        newItems.push({
          ...category,
        });
      }
    });

    return newItems;
  }, [vehiclesByCategoryNew, unassignedItems]);

  /*
      const items = [
      { day: 0, start: 10, startMinute: 30, length: 800, content: "Item 1" }, // This will be displayed from Tuesday 10:30 to 12:00
      { day: 1, start: 10, startMinute: 30, length: 800, content: "Item 1" }, // This will be displayed from Tuesday 10:30 to 12:00
      { day: 5, start: 10, startMinute: 30, length: 800, content: "Item 1" }, // This will be displayed from Tuesday 10:30 to 12:00
      { day: 2, start: 10, startMinute: 30, length: 800, content: "Item 1" }, // This will be displayed from Tuesday 10:30 to 12:00
      { day: 3, start: 15, startMinute: 30, length: 1800, content: "Item 2" }, // This will be displayed from Tuesday 10:30 to 12:00
      // ...more items...
    ];
    */

  const columnWidth = mobileViewPort ? "minmax(150px,1fr)" : "minmax(50px,1fr)";

  const firstCellWidth = "200px";

  const datesGridTemplateColumns = (dates) => {
    return `${firstCellWidth} repeat(${dates.length}, ${columnWidth})`;
  };

  const [contextMenuCreateNew, setContextMenuCreateNew] = React.useState(null);

  const handleOpenContextMenu = (event, date) => {
    event.preventDefault();

    setContextMenuCreateNew(
      contextMenuCreateNew === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            date: date,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleContextMenu = ({ createNewEvent, date, transferEvent }) => {
    if (!transferEvent) {
      return handleOpenContextMenu(createNewEvent, date);
    } else {
      if (createNewEvent) {
        return handleOpenContextMenu(transferEvent, date);
      }
    }
  };
  const handleClose = () => {
    setContextMenuCreateNew(null);
    setSelectedVehicle(null);
    setSelectedReservation(null);
  };
  const handleMoveReservationToVehicle = (reservation, vehicle) => {
    setTransferData({ reservation: reservation, fromVehicle: vehicle });
  };

  const borderBottomColor = "#cccccc";

  return (
    <ClickAwayListener onClickAway={() => setTransferData("vehicle")}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflow: "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: ` ${datesGridTemplateColumns(dates)}`,
            gridTemplateRows: `repeat(${
              vehiclesByCategoryNew?.length + 1
            }, auto)`,
            rowGap: "2px",
            position: "relative",
          }}
        >
          <div
            style={{
              gridColumn: "1 / 2",
              gridRow: "1 / 2",
              position: "sticky",
              top: 0,
              left: 0,
              zIndex: 22,
              backgroundColor: "white",
              borderBottom: `1px solid ${borderBottomColor}`,
              width: firstCellWidth,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={() => {
                  reservationDialogVersion
                    ? navigate("/varaus/")
                    : setEditReservationData(true);
                }}
                data-cy="createReservationButton"
              >
                <MdPostAdd
                  style={{
                    fontSize: 30,
                    color: theme.palette.primary.main,
                  }}
                />
              </IconButton>
            </Box>
          </div>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(${dates?.length}, 1fr)`,
              gridColumn: "2 / -1",
              gridRow: "1 / 2",
              position: "sticky",
              top: 0,
              height: "40px",
              zIndex: 22,
              borderBottom: `1px solid ${borderBottomColor}`,
            }}
          >
            {dates.map((weekday, index) => {
              const getBgcolor = (date) => {
                if (isToday(date)) {
                  return theme.palette.primary.main;
                } else {
                  if (index % 2 === 0) {
                    return "lightgray";
                  } else {
                    return "white";
                  }
                }
              };
              return (
                <div
                  //    className={isToday(weekday) ? "todayDateItem" : "dateItem"}
                  className="dateItem"
                  style={{ backgroundColor: getBgcolor(weekday), zIndex: 2 }}
                  key={weekday.getTime()}
                >
                  {/*      {Array.from({ length: 24 }).map((_, hour) => (
                  <div
                    key={hour}
                    style={{
                      border: "1px solid pink",
                    }}
                  ></div>
                ))} */}
                  {/*    */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography noWrap> {format(weekday, "dd.MM")}</Typography>
                  </div>
                </div>
              );
            })}
          </Box>

          {vehiclesByCategoryWithUnassigned &&
            vehiclesByCategoryWithUnassigned.map((vehicle, index) => {
              if (vehicle?.categoryTitle) {
                return (
                  <React.Fragment key={`${vehicle?.categoryTitle}-header`}>
                    <div
                      style={{
                        gridColumn: "1 / -1",

                        border: `1px solid ${borderBottomColor}`,
                        zIndex: 25,
                      }}
                    >
                      <Box
                        style={{
                          // width: `calc(${firstCellWidth} - 2px)`,
                          backgroundColor: "lightgray",
                          position: "sticky",
                          left: 0,
                        }}
                      >
                        <Box sx={{ width: `calc(${firstCellWidth} - 2px)` }}>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 550,
                              bgcolor: vehicle?.category?.backgroundColor,
                            }}
                          >
                            {vehicle?.categoryTitle}
                          </Typography>
                        </Box>
                      </Box>
                    </div>

                    {/*  <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${
                          dates.length * 24 * 2
                        }, 1fr)`,
                        gridColumn: "2 / -1",
                        gridRow: `${index + 2} / span 1`,
                        borderBottom: "1px solid black",
                      }}
                    ></div> */}
                  </React.Fragment>
                );
              } else {
                if (vehicle?.wildCardVehicles?.length > 0) {
                  return (
                    <React.Fragment key={vehicle.id}>
                      <div
                        style={{
                          gridColumn: "1 / 2",
                          position: "sticky",
                          left: 0,
                          zIndex: 25,
                        }}
                      />

                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: `repeat(${
                            dates.length * 24 * 2
                          }, 1fr)`,
                          gridColumn: "2 / -1",
                          gridRow: `${index + 2} / span 1`,
                          borderBottom: `1px solid ${borderBottomColor}`,
                        }}
                      >
                        <div
                          style={{
                            gridRowStart: 1,
                            gridColumnStart:
                              (vehicle.day + 1) * 48 +
                              vehicle.start * 2 +
                              (vehicle.startMinute >= 30 ? 1 : 0) +
                              1,
                            gridColumnEnd:
                              (vehicle.day + 1) * 48 +
                              vehicle.start * 2 +
                              (vehicle.startMinute >= 30 ? 1 : 0) +
                              vehicle.length +
                              1,
                            backgroundColor: "darkmagenta",
                            overflow: "hidden",
                            zIndex: 24,
                          }}
                          onClick={() => setEditReservationData(vehicle)}
                        >
                          <div style={{ width: vehicle.length }}>
                            <Typography noWrap>{vehicle.content}</Typography>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={vehicle.id}>
                    <div
                      style={{
                        gridColumn: "1 / 2",
                        position: "sticky",
                        left: 0,
                        border: `1px solid ${borderBottomColor}`,
                        zIndex: 25,
                      }}
                    >
                      <Box
                        sx={{
                          width: `calc(${firstCellWidth} - 2px)`,
                          backgroundColor: "white",
                        }}
                      >
                        <Typography noWrap>{vehicle?.name}</Typography>
                      </Box>
                    </div>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: `repeat(${
                          dates.length * 24 * 2
                        }, 1fr)`,
                        gridColumn: "2 / -1",
                        gridRow: `${index + 2} / span 1`,
                        borderBottom: `1px solid ${borderBottomColor}`,
                      }}
                      style={{ cursor: "context-menu" }}
                      onContextMenu={(event) => {
                        const grid = event.target;
                        const gridRect = grid.getBoundingClientRect();
                        const cellWidth =
                          gridRect.width / (dates.length * 24 * 2);
                        const clickPositionRelativeToGrid =
                          event.clientX - gridRect.left;
                        const clickedCellIndex = Math.floor(
                          clickPositionRelativeToGrid / cellWidth
                        );
                        const clickedDay = Math.floor(
                          clickedCellIndex / (24 * 2)
                        );
                        handleContextMenu({
                          createNewEvent: event,
                          date: set(new Date(), { days: clickedDay }),
                        });
                      }}
                      /*   onClick={(event) => {
                        const grid = event.target;
                        const gridRect = grid.getBoundingClientRect();
                        const cellWidth =
                          gridRect.width / (dates.length * 24 * 2);
                        const clickPositionRelativeToGrid =
                          event.clientX - gridRect.left;
                        const clickedCellIndex = Math.floor(
                          clickPositionRelativeToGrid / cellWidth
                        );
                        const clickedDay = Math.floor(
                          clickedCellIndex / (24 * 2)
                        );
                        console.log(`Day clicked: ${clickedDay}`);
                      }} */
                    >
                      {items.map((item) => {
                        if (
                          item?.reservationVehicles?.some(
                            (resVehicle) => resVehicle.id === vehicle.id
                          )
                        )
                          return (
                            <div
                              key={item.id}
                              style={{
                                gridRowStart: 1,
                                gridColumnStart:
                                  (item.day + 1) * 48 +
                                  item.start * 2 +
                                  (item.startMinute >= 30 ? 1 : 0) +
                                  1,
                                gridColumnEnd:
                                  (item.day + 1) * 48 +
                                  item.start * 2 +
                                  (item.startMinute >= 30 ? 1 : 0) +
                                  item.length +
                                  1,
                                backgroundColor: "lightblue",
                                overflow: "hidden",
                                zIndex: 24,
                              }}
                              onClick={() => setEditReservationData(item)}
                            >
                              <ReservationTooltip
                                reservation={item}
                                differentReturnLocation={
                                  vehicle.companyId !== item.companyId
                                    ? true
                                    : false
                                }
                                vehicle={vehicle}
                                visibility={!contextMenuCreateNew}
                              >
                                <div style={{ width: item.length }}>
                                  <Typography noWrap>{item.content}</Typography>
                                </div>
                              </ReservationTooltip>
                            </div>
                          );

                        return null;
                      })}
                    </Box>
                    <ContextMenuCreateNew
                      contextMenu={contextMenuCreateNew}
                      handleClose={handleClose}
                      setContextMenu={setContextMenuCreateNew}
                      vehicle={vehicle}
                      date={new Date()}
                      transferData={transferData}
                      selectedVehicle={selectedVehicle}
                      setSelectedVehicle={setSelectedVehicle}
                      selectedReservation={selectedReservation}
                      setSelectedReservation={setSelectedReservation}
                      handleMoveReservationToVehicle={
                        handleMoveReservationToVehicle
                      }
                      dayOfWeek={index}
                    />
                  </React.Fragment>
                );
              }
            })}
        </div>
      </Box>
    </ClickAwayListener>
  );
}

function CalendarHeader(props) {
  const { dates, cellWidth, setCellWidth } = props;
  const {
    mobileViewPort,
    selectedView,
    reservationDialogVersion,
    setEditReservationData,
  } = useContext(AppContext);
  const theme = useTheme();
  const [headerCell, setHeaderCell] = useState(null);
  //const cellRef = useRef(null);
  const headerCellRef = useCallback((node) => {
    if (node !== null) {
      setHeaderCell(node);
    }
  }, []);
  const navigate = useNavigate();
  // First cells width is determined by the content of the cell, not the width of the cell.

  useEffect(() => {
    if (headerCell) {
      setCellWidth(headerCell.getBoundingClientRect().width);
    }
  }, [headerCell, dates.length, setCellWidth]);

  useEffect(() => {
    const updateCellWidth = async () => {
      if (headerCell) {
        const newCellWidth = await headerCell.current.getBoundingClientRect()
          .width;
        setCellWidth(newCellWidth);
      }
    };

    // Initial measurement after the component has mounted
    if (headerCell === null) {
      setTimeout(() => {
        updateCellWidth();
      }, 50);
    } else {
      updateCellWidth();
    }

    const handleResize = () => {
      updateCellWidth();
    };

    // Update on window resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("resize", handleResize);
    };
  }, [headerCell, setCellWidth]);

  return (
    <>
      <TableCell
        sx={{
          bgcolor: theme.palette.primary.background.paper,
          paddingX: 0,
          //   maxWidth: firstCellWidth,
          width: "250px",
          height: !mobileViewPort ? "50px" : undefined,
          position: "sticky",
          left: 0,
          zIndex: 20,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            onClick={() => {
              reservationDialogVersion
                ? navigate("/varaus/")
                : setEditReservationData(true);
            }}
            data-cy="createReservationButton"
          >
            <MdPostAdd
              style={{
                fontSize: 30,
                color: theme.palette.primary.main,
              }}
            />
          </IconButton>
        </Box>
      </TableCell>
      {!!dates &&
        dates.map((date, idx) => {
          return (
            <TableCell
              key={date.getTime()}
              sx={{
                backgroundColor: isToday(date)
                  ? theme.palette.primary.main
                  : theme.palette.primary.background.paper,
                margin: 0,
                minWidth: mobileViewPort ? 300 : 100,
                maxWidth: mobileViewPort ? 300 : 100,
                padding: 0,
                zIndex: 20,
              }}
              ref={idx === 0 ? headerCellRef : null}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.25)",
                  borderWidth: "0px 0px 0px 1px",
                }}
              >
                {!mobileViewPort && (
                  <Typography
                    variant="body1"
                    sx={{ color: isToday(date) ? "white" : "grey" }}
                  >
                    {format(
                      date,
                      selectedView !== "monthly" ? "eeee" : "EEEEEE",
                      { locale: fi }
                    )}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bolder",
                    color: isToday(date) ? "white" : "black",
                  }}
                >
                  {format(date, "dd.MM.", { locale: fi })}
                </Typography>
              </Box>
            </TableCell>
          );
        })}
    </>
  );
}

function ContextMenuCreateNew(props) {
  const {
    contextMenu,
    handleClose,
    setContextMenu,
    handleMoveReservationToVehicle,
    vehicle,
    selectedVehicle,
    selectedReservation,
  } = props;

  const { setEditReservationData } = useContext(AppContext);
  const theme = useTheme();
  const handleCreateNewReservation = async (date) => {
    setEditReservationData({
      contextVehicle: vehicle,
      companyId: vehicle.companyId,
      startTime: set(new Date(date), {
        hours: getHours(new Date()) + 1,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      returnTime: set(new Date(date), {
        hours: getHours(new Date()) + 2,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      endTime: set(new Date(date), {
        hours: getHours(new Date()) + 2,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
    });
  };

  if (contextMenu) {
    return (
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.drawerBackground,
            boxShadow: theme.shadows[0],
            border: `1px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        {selectedVehicle && (
          <MenuItem
            onClick={() => {
              handleMoveReservationToVehicle(selectedReservation, vehicle);
              setContextMenu(null);
            }}
          >
            <MdMoveUp
              style={{
                fontSize: "26px",
                marginRight: "5px",
                color: theme.palette.primary.main,
              }}
            />
            <Typography>Siirrä varaus tuotteelle</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleCreateNewReservation(contextMenu?.date);
            setContextMenu(null);
          }}
        >
          <NoteAddIcon
            sx={{
              fontSize: "26px",
              marginRight: "5px",
              color: theme.palette.primary.main,
            }}
          />
          <Typography>Luo uusi varaus</Typography>
        </MenuItem>
      </Menu>
    );
  }
}

import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import AdditionalServicesEditDialog from "./AdditionalServicesEditDialog";
import { Skeleton } from "@mui/material";
import DialogComponent from "./DialogComponent";
import { Box } from "@mui/system";
import { BsPlus } from "react-icons/bs";
import { useFetchAdditionalServices } from "../services/fetchAdditionalServices";
import { AppContext } from "../AppContext";

function Title({ children }) {
  return (
    <Box display={"flex"} sx={{ width: "100%", flexDirection: "column" }}>
      <Typography variant="h6" sx={{ fontFamily: "Sofia Pro" }} align="center">
        {children}
      </Typography>
      <Divider />
    </Box>
  );
}

export default function AdditionalServiceDialog({ open, onClose }) {
  const { business } = useContext(AppContext);
  const {
    additionalServices,
    additionalServicesLoading,
    additionalServicesMutate,
  } = useFetchAdditionalServices();

  const [editableAdditionalService, setEditableAdditionalService] = useState();

  const services = React.useMemo(() => {
    if (!additionalServices) return null;
    // Filter removed services out from this view
    return additionalServices
      .filter((service) => !service._removed)
      .map((service) => {
        return {
          ...service,
          disableEditing: !!(!service.orgBusinessId && business),
        };
      });
  }, [additionalServices, business]);

  const createNew = (category) => {
    setEditableAdditionalService({ category: category });
  };
  return (
    <DialogComponent
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      dialogClose={() => onClose()}
      dialogActionText={"Lisää"}
    >
      <Title>Lisäpalvelut</Title>

      <Table data-cy="additionalServicesTable">
        <TableBody>
          {additionalServicesLoading ? (
            <RowSkeleton />
          ) : (
            (services || [])
              .filter((as) => as.category === "normal")
              .filter((as) => as.isDefault !== true)
              .map((as, idx) => {
                return (
                  <TableRow
                    key={idx}
                    hover
                    sx={{ cursor: as.disableEditing ? "auto" : "pointer" }}
                    selected={as.disableEditing}
                    onClick={() =>
                      !as.disableEditing && setEditableAdditionalService(as)
                    }
                  >
                    <TableCell>{as.description}</TableCell>
                  </TableRow>
                );
              })
          )}
          <TableRow>
            <TableCell sx={{ padding: 0 }}>
              <Button
                sx={{ margin: "7px" }}
                variant="text"
                color="primary"
                onClick={() => {
                  createNew("normal");
                }}
                startIcon={<BsPlus style={{ fontSize: 26 }} />}
              >
                <Typography sx={{ textTransform: "none" }} variant="body1">
                  Lisää uusi
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ marginTop: "25px" }} />
      <Title>Kilometrit </Title>
      <Table data-cy="additionalServicesDistanceTable">
        <TableBody>
          {additionalServicesLoading ? (
            <RowSkeleton />
          ) : (
            (services || [])
              .filter((as) => as.category === "distance")
              .filter((as) => as.isDefault !== true)
              .map((as, idx) => {
                return (
                  <TableRow
                    key={idx}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => setEditableAdditionalService(as)}
                  >
                    <TableCell>{as.description}</TableCell>
                  </TableRow>
                );
              })
          )}
          <TableRow>
            <TableCell sx={{ padding: 0 }}>
              <Button
                sx={{ margin: "7px" }}
                variant="text"
                color="primary"
                onClick={() => {
                  createNew("distance");
                }}
                startIcon={<BsPlus style={{ fontSize: 26 }} />}
              >
                <Typography sx={{ textTransform: "none" }} variant="body1">
                  Lisää uusi
                </Typography>
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {editableAdditionalService && (
        <AdditionalServicesEditDialog
          additionalService={editableAdditionalService}
          onClose={(created) => {
            setEditableAdditionalService(null);
            additionalServicesMutate();
          }}
        />
      )}
    </DialogComponent>
  );
}

function RowSkeleton() {
  return (
    <TableRow hover>
      <TableCell>
        <Skeleton width={180} />
      </TableCell>
    </TableRow>
  );
}

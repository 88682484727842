import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";
import { Business, Company, Reservation, Vehicle } from "~/graphql/API";
// @ts-ignore
import { useFetchCompanies } from "~/services/fetchCompanies";

export function useBorderColor(
  reservation: Pick<Reservation, "startLocationID" | "returnLocationID">,
  vehicle: undefined | Vehicle
) {
  const context = useContext<any>(AppContext);
  const business = context.business as Business;
  const user = context.user;

  const companies = useFetchCompanies(user?.group, business?.id).companies as
    | Company[]
    | undefined;

  const startLocationColor =
    business?.pickupLocations?.find(
      (location) => location?.id === reservation.startLocationID
    )?.locationColor ??
    companies?.find(({ id }) => id === reservation.startLocationID)
      ?.locationColor ??
    companies?.find(({ id }) => id === vehicle?.companyId)?.locationColor;

  const endLocationColor =
    business?.pickupLocations?.find(
      (location) => location?.id === reservation.returnLocationID
    )?.locationColor ??
    companies?.find(({ id }) => id === reservation.returnLocationID)
      ?.locationColor ??
    companies?.find(({ id }) => id === vehicle?.companyId)?.locationColor;

  const fallback = startLocationColor || endLocationColor || "rgb(72, 94, 136)";

  return `linear-gradient(90deg, ${startLocationColor || fallback} 50%, ${
    endLocationColor || fallback
  }) 50%`;
}

import { useState } from "react";
import { Box, IconButton, Stack, Tooltip, useMediaQuery } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import AvailableOffersMenu from "../views/BusinessUsers/content/tabs/summary/components/AvailableOffers/components/AvailableOffersMenu";
import { useFormikContext } from "formik";
import { useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../stores/vehiclePrices";

export function ProductCardTemplate(props) {
  const { summary, details, productId, expanded, setExpanded } = props;
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const direction = mobileViewPort ? "column" : "row";
  return (
    <Stack
      alignItems={"flex-start"}
      direction={"row"}
      flex={1}
      spacing={1}
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        height: "100%",
        padding: "10px",
        position: "relative",
        width: { xs: "95%", sm: "95%", lg: "810px" },
      }}
    >
      <Stack
        alignItems={mobileViewPort ? "flex-start" : "center"}
        direction={direction}
        justifyContent={mobileViewPort ? "center" : "space-between"}
        sx={{
          width: "calc(100% - 40px)",
        }}
      >
        {summary}
        {details}
      </Stack>
      <Stack
        alignItems={"center"}
        height={"100%"}
        justifyContent={"flex-start"}
        spacing={1}
        width={"40px"}
      >
        <Box>
          <IconButton
            onClick={() => setExpanded((prevState) => !prevState)}
            size="small"
          >
            <Edit />
          </IconButton>
        </Box>
        <ChangeProduct expanded={expanded} productId={productId} />
        <Box>
          <DeleteButton productId={productId} expanded={expanded} />
        </Box>
      </Stack>
    </Stack>
  );
}

function ChangeProduct(props) {
  const { productId, expanded } = props;
  if (expanded)
    return (
      <Box>
        <AvailableOffersMenu selectedItemId={productId} menuType={"none"} />
      </Box>
    );
}

function DeleteButton(props) {
  const { productId, expanded } = props;
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const setPrices = useSetAtom(vehiclePricesAtom);
  const handleDelete = () => {
    if (confirmDelete) {
      // Perform delete action

      if (values?.wildCardVehicles?.length > 0) {
        setFieldValue("wildCardVehicles", []);
      } else {
        setFieldValue(
          "reservationVehicles",
          values?.reservationVehicles.filter(
            (vehicle) => vehicle.id !== productId
          )
        );
      }
      setPrices((prev) => {
        const prices = { ...prev };
        delete prices[productId];
        return prices;
      });
    } else {
      setConfirmDelete(true);
      setTimeout(() => {
        setConfirmDelete(false);
      }, 5000);
    }
  };

  if (expanded)
    return (
      <Tooltip open={confirmDelete} title="Vahvista tuotteen poistaminen">
        <IconButton
          onClick={handleDelete}
          color={confirmDelete ? "error" : "transparent"}
          size="small"
        >
          <Delete />
        </IconButton>
      </Tooltip>
    );
}

import { Box, CircularProgress, Divider, List, Stack } from "@mui/material";
import React from "react";
import { useGetReservationHistory } from "../../../../hooks/useGetReservationHistory";
import HistoryItem from "./components/HistoryItem";

export default function ReservationHistory(props) {
  const { vehicle } = props;
  const { reservationsHistory, loading } = useGetReservationHistory(vehicle);

  if (loading) {
    return (
      <Box
        display={"flex"}
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (reservationsHistory.length === 0) {
    return (
      <Box
        display={"flex"}
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box textAlign={"center"}>Ei varaushistoriaa</Box>
      </Box>
    );
  }

  return (
    <Stack display={"flex"} flexDirection={"column"} width={"100%"}>
      <List dense>
        {reservationsHistory.map((reservation) => {
          return (
            <React.Fragment key={reservation.id}>
              <HistoryItem reservation={reservation} />
              <Divider />
            </React.Fragment>
          );
        })}
      </List>
    </Stack>
  );
}

import React, { useState } from "react";
import {
  Button,
  TextField,
  Stack,
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { CheckCircle, Info } from "@mui/icons-material";
import { API, graphqlOperation } from "aws-amplify";

import DialogComponent from "../../../../../../../DialogComponent";
import { innovoiceCreditRequest } from "../../../../../../../../graphql/queries";
import { devStaging } from "../../../../../../../../features";

function DevelopmentHelper() {
  const isDevStaging = React.useMemo(() => {
    return !!devStaging(process.env.REACT_APP_ENV);
  }, []);
  if (!isDevStaging) return null;
  return (
    <Tooltip
      title={
        <Stack>
          <Typography>Testaus:</Typography>
          <Typography>Puoltava: 010150-123A</Typography>
          <Typography>Kielteinen: 010150-123B</Typography>
        </Stack>
      }
    >
      <Info style={{ color: "grey" }} />
    </Tooltip>
  );
}

function CreditStatusText({ granted, message, creditDecisionText }) {
  if (granted === undefined) return null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ display: "inline-block", fontFamily: "Sofia Pro" }}
        >
          {`Rahoituspäätös`}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginLeft: "5px",
            fontFamily: "Sofia Pro",
            display: "inline-block",
            color: granted ? "green" : "red",
          }}
        >
          {creditDecisionText}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontFamily: "Sofia Pro" }}>{message}</Typography>
      </Grid>
    </Grid>
  );
}

function CreditStatusIcon({ creditGranted }) {
  if (creditGranted === undefined) return null;
  return <CheckCircle color={creditGranted ? "success" : "error"} />;
}

function InnovoiceCreditDialog({ orgBusinessId, initialSsn, open, onClose }) {
  const [ssn, setSsn] = useState(initialSsn);
  const [creditGranted, setCreditGranted] = useState(undefined);
  const [creditMessage, setCreditMessage] = useState("");
  const [creditDecisionText, setCreditDecisionText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleCreditInquiry = async () => {
    setSubmitting(true);
    try {
      const response = (
        await API.graphql(
          graphqlOperation(innovoiceCreditRequest, {
            orgBusinessId,
            ssn,
          })
        )
      ).data.innovoiceCreditRequest;

      if (response) {
        console.log("response", response);

        // Update the UI based on the response
        setCreditGranted(response.granted);
        setCreditMessage(response.message);
        setCreditDecisionText(response.creditDecisionText);
      } else {
        // Handle null response scenario
        console.error("Received null response");
        setCreditMessage("Luottotietojen haku epäonnistui.");
      }
      setCreditGranted(response.granted);
      setCreditMessage(response.message);
      setCreditDecisionText(response.creditDecisionText);
    } catch (error) {
      console.error("Credit check failed:", error);
      setCreditMessage("Credit check failed. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <DialogComponent
      open={open}
      onClose={onClose}
      dialogClose={onClose}
      title="Rahoituskysely"
    >
      <Stack spacing={2} justifyContent="center" alignItems="center">
        Rahoituskysely
        <DevelopmentHelper />
        <TextField
          label="Henkilötunnus"
          value={ssn}
          onChange={(e) => setSsn(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <Button
          variant="contained"
          onClick={handleCreditInquiry}
          disabled={submitting}
        >
          {submitting ? <CircularProgress size={24} /> : "Tee kysely"}
        </Button>
        <CreditStatusIcon creditGranted={creditGranted} />
        <CreditStatusText
          granted={creditGranted}
          message={creditMessage}
          creditDecisionText={creditDecisionText}
        />
      </Stack>
    </DialogComponent>
  );
}

export default InnovoiceCreditDialog;

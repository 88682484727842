import { useContext } from "react";
import { AppContext } from "../../AppContext";
import InsuranceClaimDialog from "./externalBusinessAdmin/InsuranceClaimDialog";
import CustomerInformation from "./common/CustomerInformation";
import ReservationPayment from "./admin/ReservationPayment";
import HanselPage from "./admin/Hansel";
import ReviewExplanation from "./admin/ReviewExplanation";
import ReservationMessages from "./common/ReservationMessages";
import ReservationSummary from "./admin/ReservationSummary";
import AdditionalServicePage from "./admin/AdditionalServices/";
import PaymentSummary from "./admin/Payment";
export const ReservationForm = (props) => {
  const {
    selectedTab,
    adaptiveView,
    FEATURE_INVOICING,
    FEATURE_INNOVOICE,
    setShowInvoiceDialog,
    review,
    setReview,
    setExplanation,
  } = props;
  const { USER_ADMIN_INSURANCE, FEATURE_ENABLE_HANSEL } =
    useContext(AppContext);
  if (USER_ADMIN_INSURANCE) {
    return <InsuranceClaimDialog visibility={USER_ADMIN_INSURANCE} />;
  }

  if (adaptiveView) {
    return (
      <>
        <ReservationSummary />
        <CustomerInformation />
        <AdditionalServicePage />
        <ReservationPayment
          FEATURE_INVOICING={FEATURE_INVOICING}
          FEATURE_INNOVOICE={FEATURE_INNOVOICE}
          setShowInvoiceDialog={setShowInvoiceDialog}
        />
        {FEATURE_ENABLE_HANSEL && (
          <HanselPage FEATURE_INVOICING={FEATURE_INVOICING} />
        )}
        <ReviewExplanation />
        <ReservationMessages />
      </>
    );
  }

  return (
    <TabView
      selectedTab={selectedTab}
      FEATURE_INNOVOICE={FEATURE_INNOVOICE}
      FEATURE_INVOICING={FEATURE_INVOICING}
      setShowInvoiceDialog={setShowInvoiceDialog}
      review={review}
      setReview={setReview}
      setExplanation={setExplanation}
    />
  );
};

function TabView({
  selectedTab,
  FEATURE_INNOVOICE,
  FEATURE_INVOICING,
  setShowInvoiceDialog,
  review,
  setReview,
  setExplanation,
}) {
  switch (selectedTab) {
    default:
      return null;
    case "info":
      return <ReservationSummary />;
    case "additionalServices":
      return <AdditionalServicePage />;
    case "customer":
      return <CustomerInformation />;
    case "payment":
      return (
        <ReservationPayment
          FEATURE_INVOICING={FEATURE_INVOICING}
          FEATURE_INNOVOICE={FEATURE_INNOVOICE}
          setShowInvoiceDialog={setShowInvoiceDialog}
        />
      );
    case "paymentnew":
      return (
        <PaymentSummary
          FEATURE_INVOICING={FEATURE_INVOICING}
          FEATURE_INNOVOICE={FEATURE_INNOVOICE}
          setShowInvoiceDialog={setShowInvoiceDialog}
        />
      );
    case "hansel":
      return <HanselPage FEATURE_INVOICING={FEATURE_INVOICING} />;
    case "delete":
      return <></>;

    case "nps":
      return (
        <ReviewExplanation
          review={review}
          setReview={setReview}
          setExplanation={setExplanation}
        />
      );

    case "messages":
      return <ReservationMessages />;
  }
}

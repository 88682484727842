import { Stack, Checkbox, FormControlLabel } from "@mui/material";
import { FormContainer } from "../../components/FormContainer";
import SearchByPostalCode from "./components/SearchByPostalCode";
import ServiceProviderCard from "./components/ServiceProviderCard";
import SelectVehicleClass from "./components/SelectVehicleClass";
import CustomerInformation from "./components/CustomerInformation";
import InsuranceCompanySelect from "./components/InsuranceCompanySelect";
import { FormInputField } from "../../components/ReservationForm/utils/FormInputField";
import InsuranceProduct from "./components/InsuranceProduct";
import InsuranceCoverage from "./components/InsuranceCoverage";
import InsuranceAdjuster from "./components/InsuranceAdjuster";
import { useField, useFormikContext } from "formik";
import ReservationForm from "./components/ReservationForm";
import { reservationFields } from "../../formik/fields";

export default function ReservationFormInsuranceUsers(props) {
  const formType = "insuranceClaim";

  return (
    <ReservationForm>
      <Content formType={formType} />
    </ReservationForm>
  );
}

function Provider(props) {
  const { formType } = props;
  const { values } = useFormikContext();
  const selectedProvider = values?.orgBusinessId;

  return (
    <Stack direction={"column"} flex={1} spacing={1}>
      {selectedProvider ? (
        <ServiceProviderCard formType={formType} />
      ) : (
        <SearchByPostalCode formType={formType} />
      )}
      <SelectVehicleClass />
    </Stack>
  );
}

function Content(props) {
  const { formType } = props;
  return (
    <Stack
      direction={"row"}
      width={"100%"}
      spacing={5}
      paddingBottom={"50px"}
      overflow={"auto"}
    >
      <Stack direction={"column"} width={"50%"}>
        <FormContainer title={"Palvelun tarjoaja"} visibility={true}>
          <Provider formType={formType} />
        </FormContainer>
        <FormContainer title={"Vahinkotiedot"} visibility={true}>
          <Stack direction={"column"} width={"100%"}>
            <CustomerInformation />
          </Stack>
        </FormContainer>
      </Stack>
      <Stack width={"50%"}>
        <FormContainer title={"Vakuutus"} visibility={true}>
          <InsuranceCompanySelect label="Maksaja" />
          <FormInputField
            label="Laskutuslupatunnus"
            name="insuranceData.billingPermitCode"
          />
          <InsuranceProduct />
          <InsuranceCoverage />
          <InsuranceAdjuster />
          <Stack direction={"row"}>
            <SetUrgentValue />
            <SetVatDeductible />
          </Stack>

          <FormInputField label="Lisätiedot" name="internalNotes" rows={4} />
        </FormContainer>
      </Stack>
    </Stack>
  );
}

function SetUrgentValue(props) {
  const [field, meta, helpers] = useField(reservationFields.insuranceData);
  const { setValue } = helpers;
  const { value } = meta;
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) => setValue({ ...value, urgent: e.target.checked })}
          checked={Boolean(value?.urgent) ?? false}
        />
      }
      label={<>Kiireellinen</>}
    />
  );
}

function SetVatDeductible(props) {
  const [field, meta, helpers] = useField(reservationFields.insuranceData);
  const { setValue } = helpers;
  const { value } = meta;
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) =>
            setValue({ ...value, vatDeductible: e.target.checked })
          }
          checked={Boolean(value?.vatDeductible) ?? false}
        />
      }
      label={<>ALV-vähennys</>}
    />
  );
}

import { Button, Grid, Typography } from "@mui/material";

export default function DeleteProduct({ setShowConfirmation }) {
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography variant="body2">
          Voit poistaa tuotteen tietokannasta.
        </Typography>

        <Typography variant="body2">
          Myyntihistoriassa tuotetiedot poistuvat ja ne korvataan
          <span style={{ fontWeight: 700, marginLeft: 5, marginRight: 5 }}>
            [Poistettu tuote]
          </span>
          nimellä.
        </Typography>
      </Grid>
      <Grid item container justifyContent="center" style={{ marginTop: 30 }}>
        <Button
          onClick={() => setShowConfirmation(true)}
          style={{ backgroundColor: "darkred", color: "white" }}
          variant="contained"
          data-cy="deleteProductButton"
        >
          Poista tuote
        </Button>
      </Grid>
    </Grid>
  );
}

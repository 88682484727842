import { IconButton, useTheme } from "@mui/material";
import { useSetAtom } from "jotai";
import { MdPostAdd } from "react-icons/md";
// @ts-ignore
import { AppContext } from "~/AppContext";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

function NewReservation() {
  const theme = useTheme();
  const setEditReservationData = useSetAtom(reservationDataAtom);

  return (
    <IconButton
      onClick={() => setEditReservationData(true)}
      data-cy="createReservationButton"
    >
      <MdPostAdd
        style={{
          fontSize: 30,
          color: theme.palette.primary.main,
        }}
      />
    </IconButton>
  );
}

export default NewReservation;

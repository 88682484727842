import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
  Skeleton,
  LinearProgress,
} from "@mui/material";

import { useFetchInvoices } from "../../../../../../../../services/fetchInvoices";
import { centsToLocalString } from "../../../../../../../../shared/money";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function Invoices({ setShowInvoiceDialog, FEATURE_INVOICING }) {
  const reservationData = useAtomValue(reservationDataAtom);
  const { invoices, invoicesLoading, invoicesValidating } = useFetchInvoices(
    reservationData?.id,
    FEATURE_INVOICING
  );

  if (invoicesLoading)
    return (
      <Grid item xs={12}>
        <Skeleton height={50} />
      </Grid>
    );
  if (invoices?.length === 0)
    return (
      <Typography variant="h6" my={"10px"}>
        Varauksella ei laskuja
      </Typography>
    );
  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          overflow: "auto",
          marginBottom: "10px",
          marginTop: "10px",
          border: "1px solid #bdbdbd",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <Grid item xs={12}>
          {invoicesValidating && <LinearProgress />}
        </Grid>
        <Typography variant="h6">
          <b>Luodut laskut:</b>
        </Typography>
        {invoices &&
          invoices
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((invoice) => (
              <Grid item xs={12} key={invoice.id}>
                <Card
                  style={{
                    marginBottom: ".3em",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {" "}
                  <CardContent>
                    <Typography variant="body1" fontWeight="bold">
                      {getInvoiceTypeLabel(invoice.type)}
                    </Typography>
                    {invoice.items.map((item) => (
                      <Typography variant="body2">
                        {item.name}: {centsToLocalString(item.unitprice)} €
                      </Typography>
                    ))}
                    <Typography variant="body1">
                      Summa:{" "}
                      {centsToLocalString(calculateInvoiceSum(invoice.items))} €
                    </Typography>
                    <Typography variant="body1">
                      Luotu:{" "}
                      {new Date(invoice.createdAt).toLocaleDateString("fi-FI")}
                    </Typography>
                    <Typography variant="body1">
                      Innovoice ID: {invoice.innovoiceId}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {invoice.type === "INSURANCE_COMPANY" &&
                        (invoice.insurance?.coveragePercentage < 100 ||
                          invoice.items.some(
                            (i) => i.type === "vat_deduction"
                          )) && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                              setShowInvoiceDialog({
                                type: "INSURANCE_CUSTOMER",
                                invoice: invoice,
                              });
                            }}
                          >
                            Luo asiakkaan lasku
                          </Button>
                        )}
                      {false && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            setShowInvoiceDialog({
                              type: "COMPENSATION",
                              invoice: invoice,
                            });
                          }}
                        >
                          Luo hyvityslasku
                        </Button>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
      </Grid>
    </>
  );
}

const getInvoiceTypeLabel = (type) => {
  if (type === "FULL") {
    return "Koko varaus";
  }
  if (type === "EXTRA") {
    return "Lisälasku";
  }
  if (type === "INSURANCE_COMPANY") {
    return "Vakuutusyhtiö";
  }
  if (type === "INSURANCE_CUSTOMER") {
    return "Vakuutusyhtiö asiakkaan osuus";
  }
};

const calculateInvoiceSum = (invoice) => {
  return invoice.reduce(
    (total, item) => total + item.quantity * item.unitprice,
    0
  );
};

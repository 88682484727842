import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

export default function FilterResults(props) {
  const { meta, setMeta } = props;
  const [inputValue, setInputValue] = useState("");
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (inputValue === "") {
      setMeta(meta);
    } else {
      setMeta(() => {
        const results = meta.filter((item) => {
          if (
            item.category?.name.toLowerCase().includes(inputValue.toLowerCase())
          ) {
            return item;
          } else {
            const matchingVehicle = item?.offers?.filter((vehicleOffer) => {
              if (
                vehicleOffer.vehicle?.name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                vehicleOffer.vehicle?.registrationPlate
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                return vehicleOffer;
              }
              return null;
            });

            if (matchingVehicle.length > 0) {
              return { ...item, vehicles: matchingVehicle };
            }
            return null;
          }
        });
        return results;
      });
    }
  }, [inputValue, meta, setMeta]);

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      fullWidth
      label="Hae tuotteita"
    />
  );
}

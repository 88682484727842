import {
  ECompanyFields,
  EContractAgreementFields,
  EContractCommon,
  EContractSummary,
  ECustomerFields,
  EPaymentFields,
  EVehicleFields,
} from "../../../enums/contract-fields";

export const contractFieldCustomerSWE = {
  TITLE: "Uthyrarens uppgifter",
  [ECustomerFields.ADDRESS]: "Kundens adress",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Körkortsklass",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Körkortsnummer",
  [ECustomerFields.EMAIL]: "Kundens e-post",
  [ECustomerFields.NAME]: "Kundens namn",
  [ECustomerFields.PHONE]: "Kundens telefon",
  [ECustomerFields.SSN]: "Kundens personnummer",
  [ECustomerFields.CUSTOMER_NOTES]: "Kundens anteckningar",
  [ECustomerFields.BILLING_REFERENCE]: "Faktureringsreferens",
  ADDITIONAL_INFORMATION: "Ytterligare information",
};

export const contractFieldDriverSWE = {
  TITLE: "Förarens uppgifter",
  [ECustomerFields.ADDRESS]: "Förarens adress",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Körkortsklass",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Körkortsnummer",
  [ECustomerFields.EMAIL]: "Förarens e-post",
  [ECustomerFields.NAME]: "Förarens namn",
  [ECustomerFields.PHONE]: "Förarens telefon",
  [ECustomerFields.SSN]: "Förarens personnummer",
};

export const contractFieldAdditionalDriverSWE = {
  TITLE: "Ytterligare förarens uppgifter",
  [ECustomerFields.ADDRESS]: "Förarens adress",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Körkortsklass",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Körkortsnummer",
  [ECustomerFields.EMAIL]: "Förarens e-post",
  [ECustomerFields.NAME]: "Ytterligare förarens namn",
  [ECustomerFields.PHONE]: "Förarens telefon",
  [ECustomerFields.SSN]: "Förarens personnummer",
};

export const contractFieldCompanySWE = {
  TITLE: "Företagets uppgifter",
  [ECompanyFields.BILLING_ADDRESS]: "Faktureringsadress",
  [ECompanyFields.BUSINESS_ID]: "Organisationsnummer",
  [ECompanyFields.CITY]: "Stad",
  [ECompanyFields.NAME]: "Företagets namn",
  [ECompanyFields.POSTAL_CODE]: "Postnummer",
};

export const contractFieldCommonSWE = {
  [EContractCommon.CONTRACT_SUBTITLE]: "Hyresavtal",
  [EContractCommon.CONTRACT_TITLE]: "HYRESAVTAL",
  [EContractCommon.RESERVATION_NUMBER]: "Bokningsnummer",
  [EContractCommon.RETURN_DATE]: "Överenskommen återlämningsdatum",
  [EContractCommon.RETURN_LOCATION]: "Återlämningsplats",
  [EContractCommon.START_DATE]: "Överenskommen startdatum",
  [EContractCommon.START_LOCATION]: "Hämtplats",
};

export const contractVehicleSWE = {
  TITLE: "uppgifter",
  [EVehicleFields.ADDITIONAL_INFORMATION]: "Ytterligare information",
  [EVehicleFields.DEDUCTIBLE]: "Självrisk",
  [EVehicleFields.DEDUCTIBLE_SIGNATORY]: "Undertecknare av självrisk",
  [EVehicleFields.EXTRAINFO]: "Ytterligare information",
  [EVehicleFields.FUEL_ON_DELIVERY]: "Bränsle vid hämtning",
  [EVehicleFields.FUEL_ON_RETURN]: "Bränsle vid återlämning",
  [EVehicleFields.FILL_UP_FUEL_LABEL]: "Fordonet ska återlämnas tankat.",
  [EVehicleFields.INCLUDED_MILEAGE]: "Inkluderad körsträcka",
  [EVehicleFields.MILEAGE_DRIVEN]: "Körda kilometer",
  [EVehicleFields.MILEAGE_ON_DELIVERY]: "Km vid hämtning",
  [EVehicleFields.MILEAGE_ON_RETURN]: "Km vid återlämning",
  [EVehicleFields.MILEAGE_INCLUDED]: "Inkluderad körsträcka",
  [EVehicleFields.NAME]: "Fordonets märke och modell",
  [EVehicleFields.POWERTYPE]: "Drivmedel",
  [EVehicleFields.REGISTRATION_NUMBER]: "Registreringsnummer",
  [EVehicleFields.EXPORT_LICENSE_CLEARANCE]:
    "Bilen har giltigt exporttillstånd till landet: ",
  [EVehicleFields.EXPORT_LICENSE_NO_CLEARANCE]: "Inget exporttillstånd",
  [EVehicleFields.EXPORT_LICENSE_NO_DETAILS]: "Inget angivet",
  [EVehicleFields.COLLISION_DAMAGE_WAIVER]: "KOLLISIONSSKYDD",
  [EVehicleFields.THEFT_PROTECTION]: "STÖLDSSKYDD",
  [EVehicleFields.VEHICLE_CONDITION]: "Fordonets skick vid uthyrning",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digital signatur",
  [EVehicleFields.VEHICLE_DAMAGE_RECORDS]: "Skaderegistrering:",
  [EVehicleFields.EXPORT_LICENSE_LABEL]: "Exporttillstånd",
  [EVehicleFields.VEHICLE_DAMAGE_CREATED]: "Skapad:",
  [EVehicleFields.VEHICLE_OR_EQUIVALENT_LABEL]: "- eller motsvarande",
};

export const paymentStatusSWE = {
  [EPaymentFields.PAYMENT_STATUS]: "Betalningsstatus",
  [EPaymentFields.PAYMENT_METHOD]: "Betalningssätt",
  [EPaymentFields.PAYMENT_STATUS_COMPLETED]: "Betalning genomförd",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digital signatur",
};

export const contractAgreementSWE = {
  [EContractAgreementFields.AGREEMENT]: "Uthyrningsvillkor",
  [EContractAgreementFields.AGREEMENT_CUSTOMER]: "Hyrestagare",
  [EContractAgreementFields.AGREEMENT_RENTAL_COMPANY]:
    "På uppdrag av uthyrningsföretaget.",
  [EContractAgreementFields.AGREEMENT_TERMS]:
    "Vi accepterar uthyrningsvillkoren och förbinder oss att följa dem.",
  [EContractAgreementFields.AGREEMENT_STRONG_AUTHENTICATION]:
    "Hyrestagaren har elektroniskt undertecknat hyresavtalet, kvittot för självrisk (kollisionsskydd och stöldskydd) med stark autentisering och åtagit sig att följa uthyrningsvillkoren.",
  [EContractAgreementFields.DIGITAL_SIGNATURE]: "Digital signatur",
  [EContractAgreementFields.POST_BILLING]: "Efterfakturering",
  [EContractAgreementFields.POST_BILLING_SIGNATORY]:
    "Undertecknare av efterfakturering",
  [EContractAgreementFields.POST_BILLING_TERMS]:
    "(bränsle, extra hyreskostnader, leverans eller hämtning av bil, parkeringsböter, trafikböter, självrisk för skador)",
  [EContractAgreementFields.POST_BILLING_AUTHORIZED]:
    "Jag ger uthyrningsföretaget tillstånd att debitera eventuella efterfaktureringar från det angivna betalkortet.",
  [EContractAgreementFields.CREDIT_CARD_NUMBER]: "Kreditkortsnummer",
  [EContractAgreementFields.CREDIT_CARD_EXPIRATION]: "Giltighetstid",
  [EContractAgreementFields.POST_BILLING_TITLE]: "Efterfakturering",
  [EContractAgreementFields.AGREEMENT_TITLE]: "Uthyrningsvillkor",
  [EContractAgreementFields.PRODUCT_CONDITION_AGREEMENT]: "Produktvillkor",
};

export const contractSummarySWE = {
  [EContractSummary.TOTAL]: "Totalt",
  [EContractSummary.VAT_EXCLUDED]: "Exklusive moms",
  [EContractSummary.PRODUCT]: "Produkt",
  [EContractSummary.PRICE]: "Pris",
  [EContractSummary.QUANTITY]: "Antal",
  [EContractSummary.VAT]: "Moms",
  [EContractSummary.VAT_INCLUDED]: "(inkl. moms",
  [EContractSummary.TAX]: "Skatt",
};

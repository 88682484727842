import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
//@ts-ignore
import { useFetchReservations } from "~/services/fetchReservations";
import { Reservation } from "~/graphql/API";
import { IconButton } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
export const useGetColumns = () => {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { reservations } = useFetchReservations("existBetween");

  const handleOpenReservation = (id: string) => {
    const reservation = reservations.find((res: Reservation) => res.id === id);

    if (reservation) {
      setEditReservationData(reservation);
    }
  };
  const columns: GridColDef[] = [
    {
      field: "open",
      headerName: "",
      sortable: false,
      flex: 0.1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpenReservation(params.row.id)}>
            <OpenInNew color="success" />
          </IconButton>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Luomisaika",
      sortable: true,
      flex: 0.3,
      minWidth: 150,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm");
      },
    },
    {
      field: "startTime",
      headerName: "Alkamisajankohta",
      sortable: true,
      flex: 0.3,
      minWidth: 150,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm");
      },
    },

    {
      field: "endTime",
      headerName: "Päättymisajankohta",
      sortable: true,
      flex: 0.3,
      minWidth: 150,
      valueFormatter: (params) => {
        return format(new Date(params.value), "dd.MM.yyyy HH:mm");
      },
    },

    {
      field: "name",
      headerName: "Varaajan nimi",
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "companyBillingRef",
      headerName: "Viite",
      width: 200,
    },
  ];

  return columns;
};

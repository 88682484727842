import { API, graphqlOperation } from "aws-amplify";
import { getPriceByVehicleIds } from "../graphql/queries";

export async function fetchPriceByVehicleId(queryResource) {
  try {
    const response = await API.graphql(
      graphqlOperation(getPriceByVehicleIds, queryResource)
    );
    if (response) {
      return response?.data?.getPriceByVehicleIds;
    }
  } catch (error) {
    console.warn("Error: fetchPriceByVehicleId failed", error);
  }
}

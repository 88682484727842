import { SxProps } from "@mui/material";

export const dataGidStyle: SxProps = {
  //   borderColor: "transparent",
  borderRadius: "14px",
  overflow: "hidden",
  height: "500px",
  /*         fontFamily: "Public Sans, sans-serif",
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          }, */
  "& .MuiDataGrid-columnHeadersInner": {
    backgroundColor: "#f7f7f8",
    color: "#4e536c",
    fontFamily: "Sofia Pro",
    fontSize: "16px",
    // boxShadow: theme.shadows[4],
  },
};

export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      deliveryBufferHours
      deliveryFee
      disableReservationCategoryIds
      orderNumberDealer
      offHourAvailabilityFee
      offHourAvailability
      phone
      businessId
      termsUrl
      timezone
      weeklyBusinessHours {
        openAt
        closeAt
      }
      nameInEmail
      signConfirmationEmail
      statusLinkMsg
      confirmationMsg
      feedbackMsg
      createdAt
      updatedAt
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      merchantDetails {
        merchant_id
        cp
      }
      businessHoursExceptions {
        nextToken
      }
      vehicles {
        nextToken
      }
      categories {
        items {
          id
          typeId
          image
          name
          orderNumber
          backgroundColor
          minuteBuffer
          pricing {
            id
            name
            group
            createdAt
            updatedAt
          }
          additionalServices {
            items {
              id
              key
              pricingId
            }
            nextToken
          }
        }
        nextToken
      }
      livionDeviceId
      livionDeviceType
      enableCart
      enableRegistrationPlate
      enableVehicleStartReturnTimes
      enableReservationDeposit
      enableMustSign
      enablePaymentOnlineOnly
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
      }
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
      }
    }
  }
`;

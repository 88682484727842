import { atom } from "jotai";
import { Reservation, Vehicle } from "~/graphql/API";

export type ContextMenuAtom = {
  pos: { top: number; left: number };
  target: HTMLElement;
  reservation?: Reservation;
  vehicle?: Vehicle;
  date?: string;
};

export const contextMenuAtom = atom<null | ContextMenuAtom>(null);

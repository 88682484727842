import { Announcement } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { fi } from "date-fns/locale";

const createdAt = {
  field: "createdAt",
  headerName: "Luotu",
  minWidth: 140,
  flex: 0.5,
  type: "date",
  valueFormatter: (params) => {
    return format(new Date(params.value), "dd.MM.yyyy HH:mm", {
      locale: fi,
    });
  },
};

const urgency = {
  field: "urgency",
  headerName: "Kiirellinen",
  minWidth: 50,
  flex: 0.2,
  renderCell: (params) => {
    if (params.value) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "center",

            alignItems: "center",
          }}
        >
          {<Announcement style={{ color: "rgba(230,0,0,1)" }} />}
        </Box>
      );
    }
    return null;
  },
};

const billingPermitCode = {
  field: "billingPermitCode",
  headerName: "Laskutuslupatunnus",
  minWidth: 160,
  flex: 0.5,
};

const providerCompany = {
  field: "providerCompany",
  headerName: "Maksaja",
  minWidth: 320,
  flex: 1,
};

const customerInformation = {
  field: "customerInformation",
  headerName: "Asiakkaan tiedot",
  sortable: false,
  minWidth: 150,
  flex: 0.5,
  renderCell: (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography noWrap sx={{ fontSize: "15px" }}>
          {params.row?.name}
        </Typography>
        <Typography variant="caption">
          <b>{params.row?.customerCarRegistrationPlate}</b>
        </Typography>
      </Box>
    );
  },
};

const channel = {
  field: "channel",
  headerName: "Kanava",
  minWidth: 200,
  flex: 0.4,
  renderCell: (params) => {
    if (
      params.row.extBusinessId === "77010970-14c1-4936-9a41-3054522a56a8" &&
      params.value === "AUTOLIITTO"
    ) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          LT Hätäpalvelu
        </Box>
      );
    }
    if (params.value === "DEALER_EXTERNAL")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Toimeksianto
        </Box>
      );
    if (params.value === "DEALER")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Yrittäjä
        </Box>
      );
    if (params.value === "DEALER_ORGANIZATION")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Organisaatio
        </Box>
      );
    if (params.value === "CUSTOMER")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Online varaus
        </Box>
      );
  },
};

const status = {
  field: "status",
  headerName: "Tila",
  minWidth: 200,
  flex: 1,
  valueFormatter: (fieldValue) => {
    if (fieldValue.value === null) {
      return "Keskeneräinen";
    } else return ERentalStatus[fieldValue.value] || fieldValue.value;
  },
};

export const columns = [
  createdAt,
  urgency,
  billingPermitCode,
  providerCompany,
  customerInformation,
  channel,
  status,
];

const ERentalStatus = {
  NONE: "Odottaa yrittäjää",
  ACCEPTED: "Hyväksytty",
  RETRIEVED: "Noudettu",
  RETURNED: "Palautettu",
  EXTERNAL_PENDING_DEALER: "Odottaa yrittäjän hyväksyntää",
  ALL_GOOD: "Hyväksytty",
};

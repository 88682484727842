export const ECustomerFields = {
  ADDRESS: "ADDRESS",
  EMAIL: "EMAIL",
  NAME: "NAME",
  PHONE: "PHONE",
  SSN: "SSN",
  DRIVERS_LICENSE_CLASS: "DRIVERS_LICENSE_CLASS",
  DRIVERS_LICENSE_ID: "DRIVERS_LICENSE_ID",
  CUSTOMER_NOTES: "CUSTOMER_NOTES",
  BILLING_REFERENCE: "BILLING_REFERENCE",
};

export const ECompanyFields = {
  BILLING_ADDRESS: "BILLING_ADDRESS",
  BILLING_REFERENCE: "BILLING_REFERENCE",
  BUSINESS_ID: "BUSINESS_ID",
  CITY: "CITY",
  NAME: "NAME",
  POSTAL_CODE: "POSTAL_CODE",
};

export const EVehicleFields = {
  ADDITIONAL_INFORMATION: "ADDITIONAL_INFORMATION",
  DEDUCTIBLE: "DEDUCTIBLE",
  DEDUCTIBLE_SIGNATORY: "DEDUCTIBLE_SIGNATORY",
  EXTRAINFO: "EXTRAINFO",
  FUEL_ON_DELIVERY: "FUEL_ON_DELIVERY",
  FUEL_ON_RETURN: "FUEL_ON_RETURN",
  INCLUDED_MILEAGE: "INCLUDED_MILEAGE",
  MILEAGE_DRIVEN: "MILEAGE_DRIVEN",
  MILEAGE_ON_DELIVERY: "MILEAGE_ON_DELIVERY",
  MILEAGE_INCLUDED: "MILEAGE_INCLUDED",
  MILEAGE_ON_RETURN: "MILEAGE_ON_RETURN",
  NAME: "NAME",
  POWERTYPE: "POWERTYPE",
  REGISTRATION_NUMBER: "REGISTRATION_NUMBER",
  FILL_UP_FUEL_LABEL: "FILL_UP_FUEL_LABEL",
  EXPORT_LICENSE_CLEARANCE: "EXPORT_LICENSE_CLEARANCE",
  EXPORT_LICENSE_NO_CLEARANCE: "EXPORT_LICENSE_NO_CLEARANCE",
  EXPORT_LICENSE_NO_DETAILS: "EXPORT_LICENSE_NO_DETAILS",
  COLLISION_DAMAGE_WAIVER: "COLLISION_DAMAGE_WAIVER",
  THEFT_PROTECTION: "THEFT_PROTECTION",
  VEHICLE_CONDITION: "VEHICLE_CONDITION",
  VEHICLE_DAMAGE_RECORDS: "VEHICLE_DAMAGE_RECORDS",
  EXPORT_LICENSE_LABEL: "EXPORT_LICENSE_LABEL",
  VEHICLE_DAMAGE_CREATED: "VEHICLE_DAMAGE_CREATED",
  VEHICLE_OR_EQUIVALENT_LABEL: "VEHICLE_OR_EQUIVALENT_LABEL",
};

export const ESignatureFields = {
  CONTRACT_AGREEMENT: "CONTRACT_AGREEMENT",
  SIGNATORY_CUSTOMER: "SIGNATORY_CUSTOMER",
  SIGNATORY_RENTAL_COMPANY: "SIGNATORY_RENTAL_COMPANY",
  SIGNATURES: "SIGNATURES",
};

export const EContractProducts = {
  PRICE_NO_VAT: "PRICE_NO_VAT",
  PRICE: "PRICE",
  PRODUCT: "PRODUCT",
  QUANTITY: "QUANTITY",
  TOTAL_NO_VAT: "TOTAL_NO_VAT",
  TOTAL: "TOTAL",
};

export const EContractCommon = {
  CONTRACT_SUBTITLE: "CONTRACT_SUBTITLE",
  CONTRACT_TITLE: "CONTRACT_TITLE",
  RESERVATION_NUMBER: "RESERVATION_NUMBER",
  RETURN_DATE: "RETURN_DATE",
  RETURN_LOCATION: "RETURN_LOCATION",
  START_DATE: "START_DATE",
  START_LOCATION: "START_LOCATION",
};

export const EPaymentFields = {
  PAYMENT_STATUS: "PAYMENT_STATUS",
  PAYMENT_METHOD: "PAYMENT_METHOD",
  PAYMENT_STATUS_COMPLETED: "PAYMENT_STATUS_COMPLETED",
  DIGITAL_SIGNATURE: "DIGITAL_SIGNATURE",
};

export const EContractAgreementFields = {
  AGREEMENT_TITLE: "AGREEMENT_TITLE",
  POST_BILLING_TITLE: "POST_BILLING_TITLE",
  AGREEMENT: "AGREEMENT",
  AGREEMENT_CUSTOMER: "AGREEMENT_CUSTOMER",
  AGREEMENT_RENTAL_COMPANY: "AGREEMENT_RENTAL_COMPANY",
  AGREEMENT_TERMS: "AGREEMENT_TERMS",
  AGREEMENT_STRONG_AUTHENTICATION: "AGREEMENT_STRONG_AUTHENTICATION",
  DIGITAL_SIGNATURE: "DIGITAL_SIGNATURE",
  REFUEL_TERMS: "REFUEL_TERMS",
  POST_BILLING: "POST_BILLING",
  POST_BILLING_SIGNATORY: "POST_BILLING_SIGNATORY",
  POST_BILLING_TERMS: "POST_BILLING_TERMS",
  POST_BILLING_AUTHORIZED: "POST_BILLING_AUTHORIZED",
  CREDIT_CARD_NUMBER: "CREDIT_CARD_NUMBER",
  CREDIT_CARD_EXPIRATION: "CREDIT_CARD_EXPIRATION",
  PRODUCT_CONDITION_AGREEMENT: "PRODUCT_CONDITION_AGREEMENT",
};

export const EContractSummary = {
  TOTAL: "TOTAL",
  VAT: "VAT",
  VAT_PERCENT: "VAT_PERCENT",
  VAT_TOTAL: "VAT_TOTAL",
  PRODUCT_HEADER: "PRODUCT_HEADER",
  PRICE_HEADER: "PRICE_HEADER",
  QUANTITY: "QUANTITY",
  PRICE: "PRICE",
  PRODUCT: "PRODUCT",
  VAT_INCLUDED: "VAT_INCLUDED",
  VAT_EXCLUDED: "VAT_EXCLUDED",
  TAX: "TAX",
};

import { capitalize } from "~/utils/capitalize";

export function getReservationName(reservation: any) {
  if (!reservation) return "";

  // if reservation is made by a company, return the company name instead of the contact person
  if (reservation?.companyName) {
    return reservation.companyName;
    // handle regular reservation name
  } else {
    const name = (reservation?.name || "").trim() as string;

    // reservation name is missing, return email if its found
    if (!name && reservation?.email) {
      return reservation.email;
    }

    // split the name to for the lastName function
    const names = name.split(" ");

    // regular case where user name has firstname & lastname
    if (names.length) {
      return (
        capitalize(names[0].charAt(0)) +
        ". " +
        capitalize(names[names.length - 1])
      );
      // for fancy people without lastname return what ever there might be
    }
  }

  return reservation.name;
}

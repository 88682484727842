import { ReactNode, memo, useEffect, useState } from "react";
import { DateInfo } from "../../../hooks/useDates";
import DateCell from "./DateCell";

type DatesProps = {
  dates: DateInfo[];
  renderAll: boolean;
} & (
  | { override?: Record<string, ReactNode>; render?: never }
  | {
      override?: never;
      render?: (dateInfo: DateInfo, colIndex: number) => ReactNode;
    }
);

const Dates = memo(function Dates({
  dates,
  renderAll,
  override,
  render,
}: DatesProps) {
  const [view, setView] = useState<ReactNode>(null);

  useEffect(() => {
    const renderer = render
      ? makeRenderRender(renderAll, render)
      : override
      ? makeRenderOverride(renderAll, override)
      : makeRenderEmpty(renderAll);

    (async () => setView(await Promise.all(dates.map(renderer))))();
  }, [dates, override, render, renderAll]);

  return <>{view}</>;
});

export default Dates;

function makeRenderEmpty(renderAll: boolean) {
  return async function renderEmpty(dateInfo: DateInfo, i: number) {
    return !renderAll &&
      dateInfo.count !== "even" &&
      !dateInfo.isToday ? null : (
      <DateCell key={dateInfo.stringDate} {...dateInfo} col={i} />
    );
  };
}

function makeRenderOverride(
  renderAll: boolean,
  override: NonNullable<DatesProps["override"]>
) {
  return async function renderOverride(dateInfo: DateInfo, i: number) {
    const val = override[dateInfo.stringDate];

    if (!renderAll && !val && dateInfo.count !== "even" && !dateInfo.isToday) {
      return null;
    }

    return (
      <DateCell key={dateInfo.stringDate} {...dateInfo} col={i}>
        {val}
      </DateCell>
    );
  };
}

function makeRenderRender(
  renderAll: boolean,
  render: NonNullable<DatesProps["render"]>
) {
  return async function renderRender(dateInfo: DateInfo, i: number) {
    const val = render(dateInfo, i);

    if (!renderAll && !val && dateInfo.count !== "even" && !dateInfo.isToday) {
      return null;
    }

    return (
      <DateCell key={dateInfo.stringDate} {...dateInfo} col={i}>
        {val}
      </DateCell>
    );
  };
}

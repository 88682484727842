import { Box, Stack, Typography } from "@mui/material";

import React, { useContext } from "react";

import { format, isValid } from "date-fns";
import { Check, Pending } from "@mui/icons-material";
import { AppContext } from "../../../AppContext";

export function ReservationInfo({ reservation, vehicleData, companies }) {
  const { user } = useContext(AppContext);

  return (
    <Stack
      sx={{
        cursor: "pointer",
        paddingY: "5px",
      }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <ReservationSchedule reservation={reservation} />
      <Company
        vehicleData={vehicleData}
        user={user}
        companies={companies}
        reservation={reservation}
      />
      <CurrentStatus reservation={reservation} />
    </Stack>
  );
}
const ReservationSchedule = ({ reservation }) => {
  if (reservation?.startTime && isValid(new Date(reservation?.startTime))) {
    return (
      <Typography
        style={{
          color: "rgba(0,0,0,0.5)",
        }}
        variant="body1"
      >
        {format(new Date(reservation?.startTime), "dd.MM.yyyy")}
        <span style={{ marginLeft: "5px", marginRight: "5px" }}>-</span>
        {format(new Date(reservation?.returnTime), "dd.MM.yyyy")}
      </Typography>
    );
  } else {
    return (
      <Box>
        <Typography variant="caption">Luomispäivä</Typography>
        <Typography>
          {format(new Date(reservation?.createdAt), "dd.MM.yyyy")}
        </Typography>
      </Box>
    );
  }
};

const Company = ({ vehicleData, user, companies, reservation }) => {
  // Get company object for reservation
  const getCompanyObj = (reservation) => {
    const legacyCompany = !!reservation?.group;
    const organizationCompany = !!reservation?.organizationId;
    let companyId = reservation?.companyId;

    // companyId does not exist for older reservations, use vehicle information

    if (companyId && organizationCompany) {
      const id = companyId;
      for (let business of user?.businessByOrg?.items) {
        const company = business?.companies.items.find((c) => c.id === id);
        if (company) {
          return company;
        }
      }
    }

    if (!companyId && legacyCompany) {
      // companyId does not exist for older reservations, use vehicle information
      let vehicleId = null;
      vehicleId = reservation?.reservationVehicles?.[0].id;
      if (!vehicleId) {
        vehicleId = reservation?.reservationVehicleId;
      }
      companyId = vehicleData.find((v) => v.id === vehicleId)?.companyId;
      return companies.find((c) => c.id === companyId);
    }

    if (companyId && legacyCompany) {
      // companyId does not exist for older reservations, use vehicle information
      return companies.find((c) => c.id === companyId);
    }

    return null;
  };

  const company = getCompanyObj(reservation)?.name ?? null;
  const business = reservation?.orgBusinessId ?? null;

  if (company) return <Typography>{company}</Typography>;
  if (business) {
    const getBusiness = user?.businessByOrg?.items.find(
      (b) => b.id === business
    );

    return <Typography>{getBusiness?.name}</Typography>;
  }
  if (!company && !business) return <Typography>Ei toimipistettä</Typography>;
};

const CurrentStatus = ({ reservation }) => {
  if (!reservation?.status) return null;

  if (reservation?.status === "EXTERNAL_PENDING_DEALER") {
    return <Pending color="warning" sx={{ fontSize: "32px" }} />;
  }
  if (reservation?.status === "COMPLETED") {
    return <Check color="success" sx={{ fontSize: "32px" }} />;
  }

  if (reservation?.status === "ALL_GOOD") {
    return <Check color="success" sx={{ fontSize: "32px" }} />;
  }
};

import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { EDriverInfo } from "./LanguagePack/translatedFields";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import { ContractField } from "./common";

export default function ContractDriverInformation({
  visibility,
  selectedLanguage,
}: {
  visibility: boolean;
  selectedLanguage: string;
}) {
  const reservation = useAtomValue(reservationDataAtom);
  if (!reservation) {
    return null;
  }
  const {
    //@ts-ignore
    driverName,
    //@ts-ignore
    driverSSN,
    //@ts-ignore
    driverAddress,
    //@ts-ignore
    driverPostalCode,
    //@ts-ignore
    driverCity,
    //@ts-ignore
    driverPhone,
    //@ts-ignore
    driverEmail,
  } = reservation;
  const fields = EDriverInfo[selectedLanguage as keyof typeof EDriverInfo];
  const maskedSsn = driverSSN
    ? driverSSN?.slice(0, -4) + driverSSN?.slice(-4).replace(/./g, "*")
    : null;
  if (visibility)
    return (
      <Stack
        sx={{
          border: "1px solid lightgrey",
          padding: "5px",
          borderRadius: "5px",
        }}
        flex={1}
      >
        <Stack flex={1} direction={"column"} spacing={1}>
          <ContractField
            value={driverName}
            label={fields.NAME}
            visibility={true}
          />

          <Stack direction={"row"} flex={1}>
            <ContractField
              value={maskedSsn}
              label={fields.SSN}
              visibility={true}
              sx={{ flex: 0.5 }}
            />
            <ContractField
              value={driverPhone}
              label={fields.PHONE}
              visibility={true}
              sx={{ flex: 0.4 }}
            />
            <ContractField
              value={null}
              label={fields.DRIVERS_LICENSE_CLASS}
              visibility={true}
              sx={{ flex: 0.5 }}
            />
          </Stack>
        </Stack>
        <ContractField
          value={
            driverAddress
              ? `${driverAddress}, ${driverPostalCode}, ${driverCity}`
              : null
          }
          label={fields.ADDRESS}
          visibility={true}
        />
        <Stack flex={1} direction={"column"} spacing={1}>
          <Stack
            direction={"row"}
            flex={1}
            spacing={1}
            justifyContent={"space-between"}
          >
            <ContractField
              value={driverEmail}
              label={fields.EMAIL}
              visibility={true}
              sx={{ flex: 1 }}
            />
          </Stack>
        </Stack>
      </Stack>
    );
}

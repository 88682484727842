import { useFormikContext } from "formik";
import {
  Autocomplete,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../../AppContext";
import { useGetRequiredFields } from "../../../formik/requiredFields";

export default function InsuranceCompanySelect({ label, size }) {
  const { user } = useContext(AppContext);
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext();
  const [inputValue, setInputValue] = useState(
    values?.insuranceData?.providerCompany ?? ""
  );
  const { requiredFields } = useGetRequiredFields();
  const insuranceCompanies = useMemo(() => {
    if (!user?.externalBusinessByOrg?.items) return [];
    // If user belongs to ExternalBusiness, return corresponding insuranceRegionalCompanies
    if (user?.externalBusiness?.insuranceRegionalCompanies) {
      return user.externalBusiness.insuranceRegionalCompanies.map(
        (company) => ({ ...company, groupName: user.externalBusiness.name })
      );
    }
    //  Return insuranceRegionalCompanies from all ExternalBusinesses
    const externalBusinesses = user.externalBusinessByOrg.items;
    const companies = externalBusinesses
      .filter((extBiz) => extBiz.insuranceRegionalCompanies)
      .flatMap((extBiz) => {
        return extBiz.insuranceRegionalCompanies.map((company) => {
          return {
            ...company,
            groupName: extBiz.name,
            orgExternalBusinessId: extBiz.id,
          };
        });
      });
    return companies;
  }, [user]);
  const fieldName = "providerCompany";
  return (
    <Autocomplete
      disablePortal
      freeSolo
      inputValue={inputValue}
      id="combo-box-insurancecompanies"
      options={insuranceCompanies}
      onBlur={handleBlur}
      onInputChange={(e, newInputValue) => {
        if (newInputValue === "") {
          setInputValue(newInputValue);
          setFieldValue(
            "insuranceData",
            {
              ...values.insuranceData,
              providerCompany: null,
              providerBusinessId: null,
            } ?? undefined
          );
        }
        setInputValue(newInputValue);
      }}
      value={values?.insuranceData?.providerCompany ?? null}
      isOptionEqualToValue={(option, value) =>
        option?.companyName === value?.companyName
      }
      filterOptions={(options, state) => {
        return options?.filter((option) => {
          if (typeof option === "string") {
            if (option.toLowerCase().includes(state?.inputValue.toLowerCase()))
              return true;
            else return false;
          } else {
            if (
              option?.name
                .toLowerCase()
                .includes(state?.inputValue.toLowerCase())
            )
              return true;
            else return false;
          }
        });
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.companyName
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={touched["insuranceCompany"] && !!errors["insuranceCompany"]}
          helperText={touched["insuranceCompany"] && errors["insuranceCompany"]}
          label={label ?? "Vakuutusyhtiö"}
          size={size ?? undefined}
          required={requiredFields(values).includes(fieldName)}
        />
      )}
      renderOption={(props, option) => {
        /*     const classObj = classes.find(
            (c) => c.productNro === option.productNro
          );
  */
        return (
          <ListItemButton {...props}>
            <ListItemText primary={option?.companyName} />
          </ListItemButton>
        );
      }}
      noOptionsText="Ei tuloksia"
      groupBy={(option) => option.groupName}
      onChange={(event, value, reason, details) => {
        console.log("value", value);
        setInputValue(value?.companyName ?? "");
        setFieldValue(
          "orgExternalBusinessId",
          value?.orgExternalBusinessId ?? null
        );
        setFieldValue("insuranceData", {
          ...values.insuranceData,
          providerCompany: value?.companyName ?? null,
          providerBusinessId: value?.businessId ?? null,
        });
      }}
      selectOnFocus
      handleHomeEndKeys
      data-cy="reservationDialogInsurancePayerSelect"
      fullWidth
    />
  );
}

import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { getTotalPrice } from "../../../utils/utilities";
import { centsToLocalString } from "../../../../../shared/money";
import { externalUserHanselClasses } from "../../../utils/externalUserHanselClasses";
import { typeOfProducts } from "~/shared/ProductClassification/type-of-products";
import { useCategories } from "../../../../../queries/useCategories";
import { translatedFields } from "../../../formik/fields";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import { useContext } from "react";

export default function OrderSummary() {
  const { values } = useFormikContext();
  const { selectedLanguage } = useContext(ReservationContext);
  const totalPrice = getTotalPrice({
    additionalServices: values.additionalServices,
    reservationVehicles:
      values?.wildCardVehicles?.length > 0
        ? values.wildCardVehicles
        : values.reservationVehicles,
  });
  return (
    <Stack
      height={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
      flex={1}
    >
      <List dense>
        <ListProducts />
        <ListAdditionalServices />
      </List>
      <Stack>
        <Typography
          sx={{
            fontSize: "1.1rem",
          }}
          align="right"
        >
          <b>
            {translatedFields[selectedLanguage].totalPrice}: {totalPrice}€{" "}
          </b>
        </Typography>
        <Typography align="right" variant="caption">
          {translatedFields[selectedLanguage].totalPriceIncludesVAT}
        </Typography>
      </Stack>
    </Stack>
  );
}

function ListProducts() {
  const { values } = useFormikContext();
  const categories = useCategories();
  if (values.wildCardVehicles.length > 0) {
    return values.wildCardVehicles.map((wildCardVehicle, index) => {
      const category = categories.find(
        (c) => c.id === wildCardVehicle?.categoryId
      );
      const image =
        typeOfProducts.find((type) => type.id === category?.typeId)?.image ||
        "";
      const getWildCardData = () => {
        if (values?.hanselData?.hanselClass) {
          const hanselClassData = externalUserHanselClasses.find(
            (wildCard) => wildCard.class === values?.hanselData?.hanselClass
          );

          return {
            hanselClass: hanselClassData?.class,
            ...hanselClassData,
            ...wildCardVehicle,
          };
        } else {
          return wildCardVehicle;
        }
      };

      const wildCardData = getWildCardData();
      return (
        <ListItem key={wildCardVehicle.categoryId} disablePadding divider>
          <ListItemAvatar>
            <Avatar variant="rounded" src={image} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Stack>
                <Typography variant="body1">
                  {wildCardData?.hanselClass
                    ? `Hansel-luokka: ${wildCardData?.hanselClass}`
                    : wildCardData?.name}
                </Typography>
                <Typography variant="body2">
                  {wildCardData?.exampleModel}
                </Typography>
              </Stack>
            }
            secondary={
              <Typography align="right">
                {`${centsToLocalString(wildCardVehicle?.price)} €`}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  }
  if (values.reservationVehicles.length > 0) {
    return values.reservationVehicles.map((vehicle, index) => {
      return (
        <ListItem key={vehicle.id} disablePadding divider>
          <ListItemAvatar>
            <Avatar variant="rounded" />
          </ListItemAvatar>
          <ListItemText
            primary={vehicle?.name}
            secondary={`${centsToLocalString(vehicle?.price)} €`}
          />
        </ListItem>
      );
    });
  }
}

function ListAdditionalServices() {
  const { values } = useFormikContext();
  return values?.additionalServices.map((service, index) => {
    const servicePrice = service?.offerPrice
      ? centsToLocalString(service?.offerPrice * service?.quantity)
      : service?.price;

    return (
      <ListItem key={index} disablePadding divider>
        <ListItemText
          inset
          disableTypography
          primary={
            <Typography>
              {service?.quantity} x {service?.description}
            </Typography>
          }
          secondary={<Typography align="right">{servicePrice} €</Typography>}
        />
      </ListItem>
    );
  });
}

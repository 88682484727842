export const dict = {
  fi: {
    "Password *": "Salasana",
    Password: "Salasana",
    "Confirm Password": "Vahvista salasana",
    "Enter your password": "Anna salasanasi",
    "Sign In": "Kirjaudu",
    "Forgot your password?": "Unohditko salasanasi?",
    "Reset password": "Palauta salasana",
    "Reset your password": "Palauta salasanasi",
    "Sign in to your account": "Kirjaudu palveluun",
    "Back to Sign In": "Takaisin kirjautumiseen",
    "Send Code": "Palauta",
    "No account?": "Uusi käyttäjä?",
    "Have an account?": "Oletko jo rekisteröitynyt?",
    "Create account": "Luo tili",
    "Email Address *": "Sähköpostiosoite *",
    "Username *": "Käyttäjätunnus *",
    "Enter your email address": "Anna sähköpostiosoitteesi",
    "Enter your username": "Anna käyttäjätunnuksesi",
    "Create Account": "Luo tili",
    "Create a new account": "Luo uusi tili",
    "Incorrect username or password.": "Väärä sähköposti tai salasana.",
    "Username cannot be empty": "Sähköposti ei voi olla tyhjä",
    "Sign in": "Kirjaudu",
    "Confirm Sign up": "Vahvista rekisteröityminen",
    "Confirmation Code": "Varmistuskoodi",
    "Lost your code?": "Koodi hävinnyt?",
    "Resend Code": "Lähetä uudestaan",
    "An account with the given email already exists.":
      "Sähköposti on jo käytössä.",
    Confirm: "Vahvista",
    "Change password": "Aseta salasana",
    "Change Password": "Aseta uusi salasana",
    "New password": "Uusi salasana",
    "Enter your new password": "Syötä uusi salasana",
    Change: "Aseta",
    Changing: "Vahvistetaan",
  },
};

export const ECustomerType = {
  COMPANY: "COMPANY",
  BROKER: "BROKER",
  PERSON: "PERSON",
} as const;

export type ECustomerType = keyof typeof ECustomerType;

export const MCustomerType = {
  [ECustomerType.BROKER]: "Agentti",
  [ECustomerType.COMPANY]: "Yritys",
  [ECustomerType.PERSON]: "Henkilö",
} as const;

import { Grid, Typography } from "@mui/material";
import { useModalContext } from "../context";
import { EInvoiceType } from "../enums/InvoiceType";
import { useFormikContext } from "formik";

function InsuranceInput() {
  const { invoiceType } = useModalContext();
  const { values } = useFormikContext<any>();

  return (
    <Grid item xs={12}>
      <Typography
        style={{
          fontFamily: "Sofia Pro",
          fontSize: 20,
        }}
      >
        Laskutettava taho:
      </Typography>
      <Typography
        style={{
          fontFamily: "Sofia Pro",
          fontSize: 17,
        }}
      >
        {invoiceType === EInvoiceType.INSURANCE_COMPANY
          ? `${values.insuranceCompany.companyName}`
          : "Asiakas"}
      </Typography>
    </Grid>
  );
}

export default InsuranceInput;

import { Box, Stack, TextField, Typography } from "@mui/material";
import { FormContainer } from "../../../../../../components/FormContainer";
import { useContext, useState } from "react";
import { ReservationContext } from "../../../../../../contextProviders/ReservationContext";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { useFetchCompanies } from "../../../../../../../../services/fetchCompanies";
import { AppContext } from "../../../../../../../../AppContext";
import { useFormikContext } from "formik";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function AdditionalInformation() {
  const { business, group } = useContext(AppContext);
  const reservationData = useAtomValue(reservationDataAtom);
  const { companies } = useFetchCompanies(group, business?.id);
  return (
    <FormContainer title="Lisätiedot" visibility={true}>
      <Stack flex={1} sx={{ maxWidth: "810px" }}>
        <Stack direction={"row"} spacing={2} flex={1}>
          <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
            Luontipäivä:
          </Typography>
          <Box>
            <Typography>
              {reservationData?.createdAt &&
                format(new Date(reservationData?.createdAt), "dd.MM HH:mm", {
                  locale: fi,
                })}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          width={"100%"}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
              Varauksen toimipiste:
            </Typography>
          </Box>
          <Box>
            <Typography>
              {companies &&
                companies.find((c) => c.id === reservationData?.companyId)
                  ?.name}
            </Typography>
          </Box>
        </Stack>
        <ReservationVehiclesMileageInput />
      </Stack>
    </FormContainer>
  );
}

function ReservationVehiclesMileageInput() {
  const { values } = useFormikContext();

  const vehicles = values?.reservationVehicles;

  return (
    <Stack width={"100%"} sx={{ marginTop: "10px" }} spacing={1}>
      {vehicles.map((vehicle) => (
        <VehicleMileageInput
          key={vehicle?.id}
          vehicle={vehicle}
          vehicles={vehicles}
        />
      ))}
    </Stack>
  );
}

function VehicleMileageInput(props) {
  const { vehicle } = props;

  return (
    <Stack
      key={vehicle?.id}
      direction={{ sm: "column", md: "row" }}
      sx={{
        justifyContent: "space-between",
      }}
      alignItems={"flex-start"}
      flex={1}
    >
      <Stack direction={"column"} minWidth={"40%"}>
        <Typography>{vehicle?.name}</Typography>
        <Typography>
          <b>{vehicle?.registrationPlate}</b>
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={1} width={"100%"}>
        <Box sx={{ width: "50%" }}>
          <MileageInputField
            label={"Aloitus kilometrit"}
            vehicleId={vehicle?.id}
            mileage={"startMileage"}
          />
        </Box>
        <Box sx={{ width: "50%" }}>
          <MileageInputField
            label={"Lopetus kilometrit"}
            vehicleId={vehicle?.id}
            mileage={"endMileage"}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

function MileageInputField(props) {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();
  const { vehicleId, label, required, disabled, rows, mileage } = props;
  const vehicle = values.reservationVehicles.find((v) => v.id === vehicleId);
  const [input, setInput] = useState(() => vehicle[mileage] || "");
  const handleChange = async (vehicle, input, formikValue) => {
    if (input === formikValue) {
      return null;
    } else {
      const updateVehicles = values.reservationVehicles.map((v) => {
        if (v.id === vehicle.id) {
          return { ...v, [mileage]: input };
        }
        return v;
      });

      await setFieldValue("reservationVehicles", updateVehicles);
      await setFieldTouched(vehicle, true, true);
    }
  };

  const cypressTag = `data-cy-reservation-form-input-${mileage}`;

  return (
    <TextField
      disabled={disabled}
      fullWidth
      label={label}
      onChange={(e) => setInput(e.target.value)}
      onBlur={() => handleChange(vehicle, input, vehicle[mileage])}
      value={input}
      name={`${vehicleId}.${mileage}`}
      required={required}
      multiline={rows > 1}
      rows={rows ?? 1}
      data-cy={cypressTag}
    />
  );
}

import { useMemo, useContext } from "react";
import { AppContext } from "~/AppContext";
import { useFetchVehicles } from "~/services/fetchVehicles";
import { Box } from "@mui/material";
import { add, addDays, getDaysInMonth, startOfDay, sub } from "date-fns";
import DayEventsDialog from "~/components/DayEventsDialog";
import Calendar from "./components/Calendar";
import ReservationsCalendar from "~/components/ReservationsCalendar/ReservationsCalendar";
import { EDeviceType } from "~/enums/device-type";
import { pageContainerStyle } from "~/styles/page-container";

export default function ReservationsOverview() {
  const {
    company,
    selectedBusiness,
    selectedView,
    setShowAllCompanies,
    setStartDate,
    showAllCompanies,
    startDate,
    user,
    rowDisplayStyle,
    mobileViewPort,
  } = useContext(AppContext);

  const { vehicleData } = useFetchVehicles();

  const vehiclesByCompany = useMemo(() => {
    if (!vehicleData) return [];
    return vehicleData.filter((v) => v.companyId === company?.id);
  }, [vehicleData, company]);

  if (
    (user?.role === "ORGANIZATION_ADMIN" && !selectedBusiness) ||
    !vehiclesByCompany
  ) {
    return null;
  }

  return (
    <Box sx={pageContainerStyle}>
      {selectedView === "daily" ? (
        <DayEventsDialog
          onNext={() => setStartDate(add(startDate, { days: 1 }))}
          onPrevious={() => setStartDate(sub(startDate, { days: 1 }))}
          selectedView={selectedView}
          setShowAllCompanies={setShowAllCompanies}
          showAllCompanies={showAllCompanies}
        />
      ) : (
        selectedView !== "reservationTable" && (
          <>
            {user?.id === "196f6ad5-1cde-48bd-8b2a-34883570f347" ? (
              <ReservationsCalendar />
            ) : (
              <Calendar
                deviceType={
                  mobileViewPort ? EDeviceType.MOBILE : EDeviceType.DESKTOP
                }
                startDate={startOfDay(startDate).getTime()}
                endDate={startOfDay(
                  addDays(
                    startDate,
                    selectedView === "weekly" ? 7 : getDaysInMonth(startDate)
                  )
                ).getTime()}
                rowVariant={rowDisplayStyle}
              />
            )}
          </>
        )
      )}
    </Box>
  );
}

import {
  EContractAgreement,
  EOrderVehicle,
} from "./LanguagePack/translatedFields";
import { ContractField, formatDateTime } from "./common";
import { Typography, Stack, Box, TextField } from "@mui/material";
import { titleStyle } from "./styles";
import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

export default function ContractAgreement({
  selectedLanguage,
  contractSettings,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const reservation = useAtomValue(reservationDataAtom);
  const [agreementDetails, setAgreementDetails] = useState(
    EOrderVehicle[selectedLanguage as keyof typeof EOrderVehicle]
      .FILL_UP_FUEL_LABEL
  );

  const fields =
    EContractAgreement[selectedLanguage as keyof typeof EOrderVehicle];

  useEffect(() => {
    if (contractSettings?.showContractAgreementDetails) {
      setAgreementDetails(
        EOrderVehicle[selectedLanguage as keyof typeof EOrderVehicle]
          .FILL_UP_FUEL_LABEL
      );
    }
  }, [selectedLanguage, contractSettings?.showContractAgreementDetails]);

  if (!reservation) {
    return null;
  }

  //@ts-ignore
  const { authTime, signerName, ssn } = reservation;

  if (
    //@ts-ignore
    reservation?.signStatus === "PENDING" ||
    //@ts-ignore
    reservation?.signStatus === "COMPLETED"
  ) {
    return "";
  }
  //@ts-ignore
  if (reservation?.authStatus === "COMPLETED") {
    const digitalSignature = authTime ? (
      <div>
        {authTime
          ? `(${formatDateTime(new Date(authTime))}) ${signerName} ${ssn}`
          : ""}
        :
      </div>
    ) : null;
    return (
      <Stack direction={"column"}>
        <ContractField
          label={fields.DIGITAL_SIGNATURE}
          value={digitalSignature}
        />
      </Stack>
    );
  }

  return (
    <Stack direction={"column"} paddingBottom={"15px"}>
      <Typography sx={titleStyle}>{fields.AGREEMENT_TITLE}</Typography>
      <Box sx={{ border: "1px solid lightgrey", padding: "2px" }}>
        {contractSettings?.showContractAgreementDetails && (
          <TextField
            value={agreementDetails}
            onChange={(e) => setAgreementDetails(e.target.value)}
            size="small"
            variant="standard"
            inputProps={{
              style: {
                fontSize: "10px",
                fontWeight: "bolder",
              },
            }}
            fullWidth
          />
        )}

        <Typography sx={{ fontSize: "8px" }}>
          {fields.AGREEMENT_TERMS}
        </Typography>
        <Stack direction={"row"}>
          <ContractField
            label={fields.AGREEMENT_CUSTOMER}
            value={""}
            visibility={true}
          />
          <ContractField
            label={fields.AGREEMENT_RENTAL_COMPANY}
            value={""}
            visibility={true}
          />
        </Stack>
      </Box>
    </Stack>
  );
}

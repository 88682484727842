import { DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "./table";
import { dataGidStyle } from "~/styles/data-grid";
import { ViewProps } from "../../enums";
import { useInvoices } from "~/queries/useInvoices";
import { useState } from "react";
import ViewInvoice from "~/modals/ViewInvoice";
import { Invoice } from "~/graphql/API";

function HistoryView({}: ViewProps) {
  const { data: invoices, isLoading } = useInvoices();
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  return (
    <>
      <DataGridPro
        loading={isLoading}
        rows={invoices}
        columns={columns}
        sx={dataGidStyle}
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
        }}
        onRowClick={({ row }) => {
          setSelectedInvoice(row);
        }}
      />
      {selectedInvoice && (
        <ViewInvoice
          invoice={selectedInvoice}
          setSelectedInvoice={setSelectedInvoice}
        />
      )}
    </>
  );
}

export default HistoryView;

import { useFormikContext } from "formik";
import { useNotification } from "../../../Notification";

export const useValidateForm = () => {
  const { validateForm, setTouched, values } = useFormikContext();
  const notification = useNotification();

  const validate = async () => {
    const isValid = await validateForm();
    setTouched(
      Object.keys(values).reduce((prev, curr) => {
        prev[curr] = true;
        return prev;
      }, {})
    );
    if (Object.values(isValid).length === 0) {
      return true;
    } else {
      // TODO: handle error
      const errors = Object.values(isValid).join(", ");
      notification.show(`Täytä kaikki pakolliset kentät: ${errors}`, "error");
      return false;
    }
  };

  return { validate };
};

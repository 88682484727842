import useSWR from "swr";
import { fetchPriceByCategoryId } from "../services/fetchPriceByCategoryId";
import { fetchPriceByVehicleId } from "../services/fetchPriceByVehicleId";
import { isTimesValid } from "../components/ReservationDialogV2/utils/isTimesValid";

export const useGetOfferPrices = (values) => {
  const { pricingCategoryId, orgExternalBusinessId, companyId } = values;
  const vehicleIds =
    values?.reservationVehicles?.map((vehicle) => vehicle?.id) ?? [];

  const priceByCategory =
    pricingCategoryId ?? values?.wildCardVehicles[0]?.categoryId;

  const queryResourceCategory = {
    categoryIds: [priceByCategory],
    startTime: values.startTime,
    returnTime: values.returnTime,
    externalBusinessId: orgExternalBusinessId,
  };

  const queryResourceVehicle = {
    vehicleIds: vehicleIds,
    startTime: values.startTime,
    returnTime: values.returnTime,
    companyId: companyId,
    externalBusinessId: orgExternalBusinessId,
  };

  const key = isTimesValid(values?.startTime, values?.returnTime)
    ? priceByCategory
      ? [queryResourceCategory]
      : [queryResourceVehicle]
    : null;
  const fetcher = priceByCategory
    ? () => fetchPriceByCategoryId(queryResourceCategory)
    : () => fetchPriceByVehicleId(queryResourceVehicle);
  const { data, error, isLoading } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });

  const offerPrices = data ? data : [];
  return {
    offerPrices: offerPrices,
    offerPriceByCategory: data?.categoryOfferPrices
      ? data?.categoryOfferPrices[0]
      : [],
    offerPricesLoading: isLoading,
    error: error,
  };
};

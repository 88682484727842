import React, { useMemo } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function PaymentStatus() {
  const reservationData = useAtomValue(reservationDataAtom);
  const statusMessages = useMemo(() => {
    let messages = [];
    const paymentStatus = reservationData?.paymentStatus;
    // TODO: Use same getter function in all Reseration dialog. (duplicated information is also shown under the Dialog)
    // Payment information
    if (paymentStatus === "COMPLETED") {
      messages.push({
        msg: "Visma Pay -maksu suoritettu",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "COMPLETED_DEPOSIT") {
      messages.push({
        msg: "Varausmaksu maksettu",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "PENDING") {
      messages.push({
        msg: "Odottaa asiakkaan maksutavan valintaa",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "COMPLETED") {
      messages.push({
        msg: "Visma Pay -maksu suoritettu",
        key: `payment-${paymentStatus}`,
      });
    }

    if (reservationData?.authStatus === "COMPLETED") {
      messages.push({
        msg: `Vahvasti tunnistautunut: ${reservationData.signerName}`,
        key: `auth-${reservationData?.authStatus}`,
      });
    }

    if (reservationData?.signStatus === "COMPLETED") {
      messages.push({
        msg: `Vahvasti allekirjoittanut: ${reservationData.signerName}`,
        key: `sign-${reservationData.signStatus}`,
      });
    }

    if (reservationData?.livionData) {
      for (const livion of reservationData?.livionData) {
        messages.push({
          msg: `Livion pincode ${livion.name || ""}: ${livion.pincode}`,
          key: `livion-${livion.name}-${livion.pincode}`,
        });
      }
    }

    return messages;
  }, [reservationData]);

  // Show nothing when creating a new Reservation
  if (!reservationData?.id) return null;
  return (
    <Box my={"10px"}>
      <Typography variant="h6" my={"10px"}>
        Varauksen tila:
      </Typography>
      {statusMessages.map((statusMsg) => {
        return (
          <Grid key={statusMsg.key}>
            <Typography variant="p">{statusMsg.msg}</Typography>
          </Grid>
        );
      })}
    </Box>
  );
}

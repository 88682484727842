import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { searchReservations as searchReservationsQuery } from "../../../../../../../../graphql/custom-queries";
import { useDebouncedEffect } from "../../../../../../../../utils/use-debounced-effect";
import { distinctBy } from "../../../../../../../../utils/array-utilities";

export default function FieldAutoComplete() {
  const [autofillSearch, setAutofillSearch] = useState("");
  const [autofillValues, setAutofillValues] = useState([]);
  const { setFieldValue, errors, values, setValues } = useFormikContext();

  const [fieldInputValue, setFieldInputValue] = useState(
    () => values?.name ?? ""
  );
  const nameAutoCompleteLoading = autofillSearch !== fieldInputValue;
  useDebouncedEffect(
    () => {
      getAutofillValues(fieldInputValue);
    },
    250,
    [fieldInputValue]
  );

  const getAutofillValues = async (str) => {
    let foundReservations;

    if (str) {
      /*
        Search by all matching all phrases, last one with prefix match
        =>
          Example data: "Firstname Lastname"
          Fill be found by:
            first
            last
            firstname last
            lastname first
          But not by:
            first lastname
      */
      const phrases = str.split(" ").filter((s) => s.length > 0);
      const searchFragments = phrases.map((phrase, pi) => {
        return {
          name:
            pi < phrases.length - 1
              ? {
                  matchPhrase: phrase,
                }
              : {
                  matchPhrasePrefix: phrase,
                },
        };
      });

      const response = await API.graphql(
        graphqlOperation(searchReservationsQuery, {
          filter: {
            and: searchFragments,
          },
          sort: {
            field: "startTime",
            direction: "desc",
          },
        })
      );
      foundReservations = distinctBy(
        response.data.searchReservations.items,
        (r) => r.email.toLowerCase()
      );
    } else {
      foundReservations = [];
    }

    setAutofillValues(foundReservations);
    setAutofillSearch(str ?? "");
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      onChange={(e, value) => {
        setValues({
          ...values,
          name: value.name,
          address: value.address ?? undefined,
          email: value.email ?? undefined,
          postalCode: value.postalCode ?? undefined,
          city: value.city ?? undefined,

          phone: value?.phone ?? undefined,
        });
        setFieldInputValue(value.name);
      }}
      fullWidth
      inputValue={fieldInputValue}
      options={autofillValues}
      filterOptions={(options) => options}
      loading={nameAutoCompleteLoading}
      loadingText="Ladataan..."
      getOptionLabel={(option) => option?.name || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="none"
          label="Vuokraajan nimi"
          onChange={(e) => setFieldInputValue(e.target.value)}
          onBlur={(e) => setFieldValue("name", e.target.value)}
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {nameAutoCompleteLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          data-cy="newReservationDialogNameField"
        />
      )}
      renderOption={(s, o) => {
        return (
          <Box {...s} key={o.id}>
            <Box sx={{ cursor: "pointer", padding: "10px" }}>
              <Typography variant="body1" display="block">
                {o.name}
              </Typography>
              <Typography variant="caption" display="block">
                {o.email}
              </Typography>
              <Typography variant="caption" display="block">
                {o.address}
              </Typography>
              <Typography variant="caption" display="block">
                {o.postalCode} {o.city}
              </Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
}

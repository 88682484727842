import { useMemo } from "react";
import { List } from "@mui/material";
import EventItem from "./EventItem";
import EmptyState from "../../../components/EmptyState";

export default function Departures({ departures }) {
  const showDepartures = useMemo(() => {
    if (!departures || departures.length === 0) return <EmptyState />;

    return departures.map((reservation, index) =>
      reservation.reservationVehicles.map((vehicle, idx) => (
        <EventItem
          reservation={reservation}
          eventTime={"startTime"}
          key={`${reservation?.id}+${vehicle?.id}`}
        />
      ))
    );
  }, [departures]);

  return <List>{showDepartures}</List>;
}

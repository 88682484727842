export const ETabs = {
  CATEGORY_PRICING: "CATEGORY_PRICING",
  PRICING: "PRICING",
  DYNAMIC_PRICING: "DYNAMIC_PRICING",
  EXTERNAL_BUSINESS_PRICING: "EXTERNAL_BUSINESS_PRICING",
  ADDITIONAL_SERVICES: "ADDITIONAL_SERVICES",
};

export const EUserRoles = {
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  BUSINESS_ADMIN: "BUSINESS_ADMIN",
  LEGACY: "LEGACY",
};

export const defaultTab = {
  [EUserRoles.ORGANIZATION_ADMIN]: ETabs.PRICING,
  [EUserRoles.BUSINESS_ADMIN]: ETabs.CATEGORY_PRICING,
  [EUserRoles.LEGACY]: ETabs.PRICING,
};

export const ETabOptions = {
  [ETabs.PRICING]: {
    LABEL: "Hinnastot",
    VALUE: ETabs.PRICING,
  },

  [ETabs.DYNAMIC_PRICING]: {
    LABEL: "Dynaamiset hinnastot",
    VALUE: ETabs.DYNAMIC_PRICING,
  },
  [ETabs.CATEGORY_PRICING]: {
    LABEL: "Autoluokat",
    VALUE: ETabs.CATEGORY_PRICING,
  },

  [ETabs.EXTERNAL_BUSINESS_PRICING]: {
    LABEL: "Sopimushinnastot",

    VALUE: ETabs.EXTERNAL_BUSINESS_PRICING,
  },
  [ETabs.ADDITIONAL_SERVICES]: {
    LABEL: "Lisäpalveluhinnastot",
    VALUE: ETabs.ADDITIONAL_SERVICES,
  },
};

export const EPricingBy = {
  ORGANIZATION: "organization",
  BUSINESS: "business",
};

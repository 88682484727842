import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { FormContainer } from "../../../components/FormContainer";
import SelectVehicleClass from "../../InsuranceUsers/components/SelectVehicleClass";
import CustomerInformation from "../../InsuranceUsers/components/CustomerInformation";
import InsuranceCompanySelect from "../../InsuranceUsers/components/InsuranceCompanySelect";
import { FormInputField } from "../../../components/ReservationForm/utils/FormInputField";
import InsuranceProduct from "../../InsuranceUsers/components/InsuranceProduct";
import InsuranceCoverage from "../../InsuranceUsers/components/InsuranceCoverage";
import InsuranceAdjuster from "../../InsuranceUsers/components/InsuranceAdjuster";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { useSetAtom } from "jotai";
import { insuranceDataAtom } from "../../../../../stores/insuranceData";
import { useNotification } from "../../../../Notification";
import { useField, useFormikContext } from "formik";
import { reservationFields } from "../../../formik/fields";

export default function FormContent() {
  const { business } = useContext(AppContext);
  const setEditInsuranceData = useSetAtom(insuranceDataAtom);

  const handleClose = () => {
    setEditInsuranceData(null);
  };

  return (
    <>
      <Stack
        direction={"row"}
        width={"100%"}
        spacing={5}
        paddingBottom={"50px"}
        overflow={"auto"}
      >
        <Stack direction={"column"} width={"50%"}>
          <FormContainer title={"Palvelun tarjoaja"} visibility={true}>
            <TextField value={business.name} label="Yritys" disabled />
            <SelectVehicleClass />
          </FormContainer>
          <FormContainer title={"Vahinkotiedot"} visibility={true}>
            <Stack direction={"column"} width={"100%"}>
              <CustomerInformation />
            </Stack>
          </FormContainer>
        </Stack>
        <Stack width={"50%"}>
          <FormContainer title={"Vakuutus"} visibility={true}>
            <InsuranceCompanySelect label="Maksaja" />
            <FormInputField
              label="Laskutuslupatunnus"
              name="insuranceData.billingPermitCode"
            />
            <InsuranceProduct />
            <InsuranceCoverage />
            <InsuranceAdjuster />
            <Stack direction={"row"}>
              {" "}
              <SetUrgentValue />
              <SetVatDeductible />
            </Stack>

            <FormInputField label="Lisätiedot" name="internalNotes" rows={4} />
          </FormContainer>
        </Stack>
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <Button>Poista</Button>
        <span style={{ flex: 1 }} />
        <Button onClick={handleClose}>Sulje</Button>
        <SubmitReservation />
      </Stack>
    </>
  );
}

function SubmitReservation() {
  const { submitForm, isSubmitting, errors } = useFormikContext();
  const notification = useNotification();
  const handleSubmit = () => {
    const activeErrors = Object.values(errors).join(", ");
    if (activeErrors) {
      notification.show(
        `Täytä kaikki pakolliset kentät: ${activeErrors}`,
        "error"
      );
      return;
    } else {
      submitForm();
    }
  };

  return (
    <Button
      variant="contained"
      onClick={() => handleSubmit()}
      disabled={isSubmitting}
      type="submit"
    >
      Tallenna
    </Button>
  );
}

function SetUrgentValue(props) {
  const [field, meta, helpers] = useField(reservationFields.insuranceData);
  const { setValue } = helpers;
  const { value } = meta;
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) => setValue({ ...value, urgent: e.target.checked })}
          checked={Boolean(value?.urgent) ?? false}
        />
      }
      label={<>Kiireellinen</>}
    />
  );
}

function SetVatDeductible(props) {
  const [field, meta, helpers] = useField(reservationFields.insuranceData);
  const { setValue } = helpers;
  const { value } = meta;
  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(e) =>
            setValue({ ...value, vatDeductible: e.target.checked })
          }
          checked={Boolean(value?.vatDeductible) ?? false}
        />
      }
      label={<>ALV-vähennys</>}
    />
  );
}

import { Grid, MenuItem, Typography } from "@mui/material";
import { useMemo } from "react";
import FormikInputDate from "~/components/Inputs/FormikInputDate";
import FormikInputSelect from "~/components/Inputs/FormikInputSelect";
import FormikInputText from "~/components/Inputs/FormikInputText";
import { Invoice } from "~/graphql/API";

type InvoiceDetailsProps = { invoice: Invoice };

function InvoiceDetails({ invoice }: InvoiceDetailsProps) {
  const invoiceType = invoice.type!;
  const customerType = invoice.customer.customer_type;

  const isInsuranceType = ["INSURANCE_COMPANY", "AUTOLIITTO"].includes(
    invoiceType
  );

  const paymentTermValues = useMemo(() => {
    let allowedValues = [7, 14, 21, 30, 60, 90];

    if (invoiceType === "INSURANCE_COMPANY") {
      allowedValues = [30];
    } else if (customerType === "person") {
      allowedValues = [14, 21, 30, 60, 90];
    }

    return allowedValues;
  }, [invoiceType, customerType]);

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Laskun tiedot
        </Typography>
      </Grid>

      <FormikInputSelect
        label="Maksuehto"
        md={4}
        readOnly
        value={invoice.payment_terms}
      >
        {paymentTermValues.map((value) => {
          return (
            <MenuItem value={value} key={`paymentTerm-${value}`}>
              {value}
            </MenuItem>
          );
        })}
      </FormikInputSelect>
      <FormikInputDate
        label="Laskun päiväys"
        md={4}
        readOnly
        value={invoice.delivery_date}
      />

      {!isInsuranceType && (
        <FormikInputSelect
          readOnly
          label="Toimitustapa"
          md={4}
          value={invoice.delivery_by}
        >
          <MenuItem value="mail">Posti</MenuItem>
          <MenuItem value="email">Sähköposti</MenuItem>
          {customerType === "company" && (
            <MenuItem value="e_invoice">Verkkolasku</MenuItem>
          )}
        </FormikInputSelect>
      )}

      <FormikInputText
        readOnly
        label="Myyjän viite"
        md={4}
        value={invoice.seller_reference}
      />
      <FormikInputText
        readOnly
        label="Ostajan viite"
        md={4}
        value={invoice.buyer_reference}
      />

      {isInsuranceType && (
        <FormikInputText
          readOnly
          label="Korvattava autoluokka"
          md={4}
          value={invoice.insurance?.vehicle_class}
        />
      )}

      {invoice.delivery_by === "e_invoice" && (
        <>
          <FormikInputText
            readOnly
            name="e_invoice_operator"
            label="Verkkolaskun välitystunnus"
            md={4}
            value={invoice.customer.e_invoice_operator}
          />
          <FormikInputText
            readOnly
            name="e_invoice_address"
            label="Verkkolaskuosoite"
            md={4}
            value={invoice.customer.e_invoice_address}
          />
        </>
      )}

      {/* <Grid mt={2} item xs={12}>
        <Typography> Vuokrapäivät: {"rentalDays"}</Typography>
      </Grid> */}
    </Grid>
  );
}

export default InvoiceDetails;

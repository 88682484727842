import { Avatar, Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { useCategoryImage } from "~/hooks/useCategoryImage";

import React, { useState } from "react";
import { useCategories } from "../../../../../../../../queries/useCategories";

import { useFormikContext } from "formik";
import { ProductCardTemplate } from "../../../../../../components/ProductCardTemplate";
import FieldInput from "./ReservationProducts/components/FieldInput";

export default function SelectedVehicleClass() {
  const categories = useCategories();
  const { values } = useFormikContext();
  const [expanded, setExpanded] = useState(false);
  const selectedVehicleClass =
    values?.wildCardVehicles?.length > 0 &&
    values?.reservationVehicles.length === 0
      ? values.wildCardVehicles[0]
      : null;

  const category = categories.find(
    (c) => c.id === selectedVehicleClass?.categoryId
  );

  if (selectedVehicleClass)
    return (
      <ProductCardTemplate
        expanded={expanded}
        setExpanded={setExpanded}
        summary={<ProductCardTitle category={category} />}
        details={
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            width={"100%"}
          >
            <FieldInput
              expanded={true}
              vehicleId={selectedVehicleClass?.categoryId}
            />
          </Stack>
        }
      />
    );
}

const ProductCardTitle = (props) => {
  const { category } = props;
  const { image } = useCategoryImage(category);
  const mobileViewPort = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const imageSize = mobileViewPort
    ? { height: "50px", width: "50px" }
    : { height: "100px", width: "100px" };
  return (
    <Stack
      direction={"row"}
      flex={1}
      alignItems={"center"}
      spacing={2}
      width={"100%"}
    >
      <Box>
        <Avatar variant="rounded" sx={imageSize} src={image} />
      </Box>
      <Stack width={"100%"}>
        <Typography
          sx={{
            fontSize: { sm: "1.2rem", xs: "1.1rem" },
            fontFamily: "Sofia Pro",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {category?.name}
        </Typography>
      </Stack>
    </Stack>
  );
};

import React from "react";
import { Typography, Grid, Box } from "@mui/material";

function StatsComponent({ change, data }) {
  // Calculate total number of reviews for selected period
  const totalReviews = data.length;

  // Calculate average rating for selected period
  const totalRating = data.reduce((acc, item) => acc + item.rating, 0);
  const averageRating = totalRating / totalReviews;

  // Calculate NPS score for selected period
  const detractors = data.filter(
    (item) => item.rating >= 0 && item.rating <= 6
  ).length;
  const passives = data.filter(
    (item) => item.rating >= 7 && item.rating <= 8
  ).length;
  const promoters = data.filter(
    (item) => item.rating >= 9 && item.rating <= 10
  ).length;
  const npsScore = ((promoters - detractors) / totalReviews) * 100;

  // Calculate percentage of detractors, passives, and promoters
  const detractorsPercentage = (detractors / totalReviews) * 100;
  const passivesPercentage = (passives / totalReviews) * 100;
  const promotersPercentage = (promoters / totalReviews) * 100;

  return (
    <Box
      my={5}
      sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
    >
      <Typography mb={2} variant="h6">
        Stats
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Määrä
            </Typography>
            <Typography variant="subtitle1">{totalReviews}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Keskiarvo
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(averageRating) || averageRating === undefined
                ? "-"
                : averageRating.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Kehitys
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(change) || change === undefined ? "-" : change + "%"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              NPS
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(npsScore) || npsScore === undefined
                ? "-"
                : npsScore.toFixed(2)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" style={{ color: "grey" }}>
              Arvostelijat
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(detractorsPercentage) || detractorsPercentage === undefined
                ? "-"
                : detractors + " (" + detractorsPercentage.toFixed(2) + "%)"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" style={{ color: "grey" }}>
              Passiiviset
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(passivesPercentage) || passivesPercentage === undefined
                ? "-"
                : passives + " (" + passivesPercentage.toFixed(2) + "%)"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{ border: 1, borderColor: "grey.400", borderRadius: 2, p: 2 }}
          >
            <Typography variant="subtitle1" style={{ color: "grey" }}>
              Suosittelijat
            </Typography>
            <Typography variant="subtitle1">
              {isNaN(promotersPercentage) || promotersPercentage === undefined
                ? "-"
                : promoters + " (" + promotersPercentage.toFixed(2) + "%)"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StatsComponent;

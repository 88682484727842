import { useFormikContext } from "formik";
import { defaultHanselCustomers } from "../utils/hanselCustomers";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

export function SelectHanselCustomer(props) {
  const { isDisabled } = props;
  const { setFieldValue, values } = useFormikContext();
  const customerInfo = defaultHanselCustomers.find(
    (customer) => customer?.id === values?.hanselData?.customerId
  );
  const value = values?.hanselData?.customerId || null;
  return (
    <Autocomplete
      disabled={isDisabled}
      fullWidth
      value={value}
      options={defaultHanselCustomers}
      onChange={async (event, newValue) => {
        await setFieldValue("hanselData.customerId", newValue?.id);
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value;
      }}
      filterOptions={(options, state) => {
        return options.filter((option) => {
          const inputValue = state?.inputValue;
          if (
            option.name.toLowerCase().includes(inputValue?.toLowerCase()) ||
            option.VAT.toLowerCase().startsWith(inputValue?.toLowerCase()) ||
            option.id.startsWith(state?.inputValue)
          ) {
            return option;
          }
          return null;
        });
      }}
      getOptionLabel={(option) => option.id ?? option}
      noOptionsText="Ei luokkaa"
      renderOption={(props, option) => {
        return (
          <Box {...props} key={option.id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: "10px",
                cursor: "pointer",
                transition: "250ms ease-in-out",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: 550 }} align="left">
                {option?.id}
              </Typography>
              <Typography>{option?.name}</Typography>
              <Typography sx={{ color: "rgba(0,0,0,0.55)" }}>
                {option?.VAT}
              </Typography>
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Valitse hansel asiakas" />
      )}
    />
  );
}

import { Button, ButtonGroup } from "@mui/material";
import { useField } from "formik";

export default function SelectCustomerType() {
  const [, meta, helpers] = useField("isCompany");
  const { setValue } = helpers;
  return (
    <ButtonGroup sx={{ maxHeight: "40px" }}>
      <Button
        sx={{ textTransform: "none" }}
        onClick={() => setValue(false)}
        variant={Boolean(meta.value) ? "outlined" : "contained"}
      >
        Yksityisasiakas
      </Button>
      <Button
        sx={{ textTransform: "none" }}
        onClick={() => setValue(true)}
        variant={Boolean(meta.value) ? "contained" : "outlined"}
      >
        Yritysasiakas
      </Button>
    </ButtonGroup>
  );
}

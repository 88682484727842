import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormikContext } from "formik";

export default function CheckBoxComponent(props) {
  const { name, label } = props;
  const { values, setFieldValue, handleBlur } = useFormikContext();
  const cypressTag = `data-cy-reservation-form-select-${name}`;
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={Boolean(values[name])}
          color="primary"
          onChange={() => setFieldValue(name, !values[name])}
          onBlur={handleBlur}
          data-cy={cypressTag}
        />
      }
      label={<>{label}</>}
    />
  );
}

import React from "react";
import { TextField, Box, Typography, Skeleton, Button } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { useGetReview } from "../../../../../hooks/useGetReview";
import { useNotification } from "../../../../../../Notification";

export default function ReviewExplanation() {
  const { loading, review, setReview, upsertReview } = useGetReview();

  if (loading) return <Skeleton width={"80%"} height={300} />;

  if (!review) {
    return "Palautetta ei annettu";
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        margin: "1rem",
        border: "1px solid #ddd",
        borderRadius: "0.5rem",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        minWidth: "80%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Arvosana: {review?.rating}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <TextField
          fullWidth
          label="Kommentti"
          multiline
          value={review?.comment}
          disabled
          maxRows={5}
          sx={{
            backgroundColor: "#f8f8f8",
            borderRadius: "0.25rem",
            padding: "0.5rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>
      <Explanation
        review={review}
        setReview={setReview}
        upsertReview={upsertReview}
        loading={loading}
      />
    </Box>
  );
}

function Explanation({ review, setReview, upsertReview, loading }) {
  const theme = useTheme();
  const explanationIsEmpty = !review?.explanation?.trim();
  const notification = useNotification();
  if (review?.rating > 6) return null; // Explanation required for NPS under 7

  const handleUpsert = async () => {
    const response = await upsertReview(review);

    if (response) {
      notification.show("Palautteen tallennus onnistui", "success");
    } else {
      notification.show("Palautteen tallennus epäonnistui", "error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <TextField
        fullWidth
        label="Selite palautteelle"
        multiline
        maxRows={5}
        value={review?.explanation || ""}
        onChange={(event) =>
          setReview({ ...review, explanation: event.target.value })
        }
        color={explanationIsEmpty ? "warning" : ""}
        focused={explanationIsEmpty}
      />
      {explanationIsEmpty && (
        <Typography
          sx={{ color: theme.palette.warning.main, fontSize: "0.8rem" }}
        >
          Täytä kenttä
        </Typography>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleUpsert(review)}
          disabled={loading || explanationIsEmpty}
        >
          Tallenna
        </Button>
      </Box>
    </Box>
  );
}

import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { useValidateChanges } from "../../../hooks/useValidateChanges";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { vehiclePricesAtom } from "../../../../../stores/vehiclePrices";

export default function CloseDialog() {
  const { isSubmitting, values } = useFormikContext();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { validateChanges, validateChangesDialog } = useValidateChanges(
    values,
    [],
    false, // checkProductPrices
    false, // checkServicePrices
    () => setEditReservationData(null)
  );
  const setPrices = useSetAtom(vehiclePricesAtom);
  return (
    <>
      {validateChangesDialog}
      <Button
        onClick={async () => {
          /*
          const isChangesValid = await validateChanges();
          if (isChangesValid) {
          }
         */
          setPrices({});
          return setEditReservationData(null);
        }}
        disabled={isSubmitting}
      >
        Sulje
      </Button>
    </>
  );
}

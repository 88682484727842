import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { useFetchCompanies } from "../../services/fetchCompanies";

const ReviewTable = ({ data, selectedMonth, selectedYear, selectedMode }) => {
  const { business, user } = useContext(AppContext);

  const { companies } = useFetchCompanies(user?.group, business?.id);

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const filteredData = data.filter(
    (d) =>
      (selectedMode === "annual"
        ? d.Date.getFullYear() === selectedYear
        : d.Date.getMonth() === selectedMonth &&
          d.Date.getFullYear() === selectedYear) &&
      d.comment !== null &&
      d.comment !== undefined
  );

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
    setPage(0);
  };

  const sortedData = sortField
    ? filteredData.sort((a, b) =>
        sortOrder === "asc"
          ? a[sortField] - b[sortField]
          : b[sortField] - a[sortField]
      )
    : filteredData;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="review comments table">
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <TableSortLabel
                active={sortField === "Date"}
                direction={sortOrder}
                onClick={() => handleSort("Date")}
              >
                Päivämäärä
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <TableSortLabel
                active={sortField === "Rating"}
                direction={sortOrder}
                onClick={() => handleSort("Rating")}
              >
                NPS
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>
              <TableSortLabel
                active={sortField === "group"}
                direction={sortOrder}
                onClick={() => handleSort("groupId")}
              >
                Toimipiste
              </TableSortLabel>
            </TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}></TableCell>
            <TableCell>Kommentti</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Selite</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, i) => {
              const companyName =
                companies?.find((c) => c?.id === row?.companyId)?.name ??
                "Nimeä ei löydy";
              return (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {new Date(row.Date).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{row.rating}</TableCell>
                  <TableCell>{companyName}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        navigate(`/varaus/${row.reservationId}`);
                      }}
                    >
                      avaa varaus
                    </Button>
                  </TableCell>
                  <TableCell
                  /*            sx={{
                      maxWidth: 450,
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }} */
                  >
                    <FeedbackCell comment={row.comment} />
                  </TableCell>
                  <TableCell style={{ maxWidth: 450 }}>
                    <Typography noWrap>{row.explanation}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Palautteita per sivu:"
      />
    </TableContainer>
  );
};

function FeedbackCell({ comment }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      setShowButton(contentRef.current.scrollHeight > 50);
    }
  }, [comment]); // Depend on comment to recalculate when it changes

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{ maxWidth: 450 }}>
      <Typography
        ref={contentRef}
        style={{
          maxHeight: isExpanded ? "none" : 50,
          overflow: "hidden",
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        {comment}
      </Typography>
      {showButton && (
        <Button onClick={toggleExpand} size="small">
          {isExpanded ? "Piilota viesti" : "Näytä viesti"}
        </Button>
      )}
    </Box>
  );
}

export default ReviewTable;

import { Avatar } from "@mui/material";
import useGetVehicleObjById from "./CustomHooks/useGetVehicleObjById";
import { useVehicleImage } from "~/hooks/useVehicleImage";

export default function ProductImage({ vehicleId, size }) {
  const vehicle = useGetVehicleObjById(vehicleId);

  const { image, icon } = useVehicleImage(vehicle);

  return (
    <Avatar
      // key={`${vehicle.id}-res-vehicle-image-${idx}-${index}`}
      variant="rounded"
      src={image}
      sx={{
        height: size ? size : undefined,
        width: size ? size : undefined,
        marginRight: "10px",
        bgcolor: "#ffffff",
        color: "rgba(0,0,0,0.35)",
        boxShadow: "none",
        border: "1px solid #eaeaea",
        borderRadius: "12px",
        padding: "7px",
      }}
    >
      {!image && icon}
    </Avatar>
  );

  /*   if (signedUrl)
    return (
      // key={`${vehicle.id}-res-vehicle-image-${idx}-${index}`}
      <Box
        sx={{
          display: "flex",
          height: size ? size : undefined,
          width: size ? size : undefined,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt={"kuva"}
          src={signedUrl}
          style={{
            marginRight: "10px",
            bgcolor: "#f7f7f7",
            boxShadow: "none",
            border: "1px solid #eaeaea",
            borderRadius: "12px",
            padding: "5px",
            objectFit: "scale-down",
            height: size ? size - 20 : undefined,
            width: size ? size : undefined,
          }}
        />
      </Box>
    );

  return (
    // key={`${vehicle.id}-res-vehicle-image-${idx}-${index}`}
    <Box
      sx={{
        display: "flex",
        height: size ? size : undefined,
        width: size ? size : undefined,
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#f7f7f7",
        boxShadow: "none",
        border: "1px solid #eaeaea",
        borderRadius: "12px",
        padding: "5px",
        marginRight: "10px",
      }}
    >
      <MdHideImage style={{ fontSize: size, color: "rgba(0,0,0,0.15)" }} />
    </Box>
  ); */
}

import { isBefore, isValid } from "date-fns";
import { validatePhoneNumber } from "../../../utils/validatePhoneNumber";
import { translatedFields } from "./fields";
import { useGetRequiredFields } from "./requiredFields";

export const useValidateFormikFields = () => {
  const { requiredFields } = useGetRequiredFields();
  const validate = (values) => {
    const selectedLanguage = "FIN";
    const errors = requiredFields(values).reduce((acc, field) => {
      // handle case where it is mandatory to select at least one vehicle
      if (field === "mandatoryProduct") {
        if (
          values?.reservationVehicles?.length === 0 &&
          values?.wildCardVehicles?.length === 0
        ) {
          acc[field] = translatedFields[selectedLanguage][field];
        } else {
          return acc;
        }
      }
      if (field === "providerCompany") {
        if (!values?.insuranceData?.providerCompany) {
          acc[field] = translatedFields[selectedLanguage][field];
        } else {
          return acc;
        }
      }
      if (!values[field]) {
        acc[field] = translatedFields[selectedLanguage][field];
      }
      return acc;
    }, {});

    const isPhoneNumberValid = validatePhoneNumber(values?.phone);
    const isDriverPhoneValid = validatePhoneNumber(values?.driverPhone);
    const isAdditionalDriverPhoneValid = validatePhoneNumber(
      values?.additionalDriverPhone
    );

    if (!isPhoneNumberValid) {
      errors.phone = translatedFields[selectedLanguage]["phone"];
    }
    if (!isDriverPhoneValid) {
      errors.driverPhone = translatedFields[selectedLanguage]["driverPhone"];
    }

    if (!isAdditionalDriverPhoneValid) {
      errors.additionalDriverPhone =
        translatedFields[selectedLanguage]["additionalDriverPhone"];
    }
    if (isValid(values?.startTime) && isValid(values?.returnTime)) {
      if (isBefore(values?.returnTime, values?.startTime)) {
        errors.returnTime =
          translatedFields[selectedLanguage]["returnBeforeStart"];
      }
    }

    return errors;
  };

  return { validate };
};

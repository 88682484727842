import { Box, Stack } from "@mui/material";
import { SelectHanselCustomer } from "../../hansel/components/SelectHanselCustomer";
import { SelectHanselClass } from "../../hansel/components/SelectHanselClass";

export default function ShowHanselFields(props) {
  const { visibility } = props;
  if (visibility)
    return (
      <Stack flex={1} spacing={1} direction={"row"}>
        <Box width={"100%"}>
          <SelectHanselCustomer />
        </Box>
        <Box width={"100%"}>
          <SelectHanselClass />
        </Box>
      </Stack>
    );
}

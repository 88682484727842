import { ReservationsGroup } from "./utils/groupReservations";
import HistoryView from "./views/HistoryView";
import OpenOrdersView from "./views/OpenOrdersView";

export const EViews = {
  OPEN_ORDERS: "OPEN_ORDERS",
  HISTORY: "HISTORY",
} as const;

export type EViews = keyof typeof EViews;

export const MViews: Record<
  EViews,
  { label: string; view: (props: ViewProps) => JSX.Element }
> = {
  [EViews.OPEN_ORDERS]: { label: "Avoimet", view: OpenOrdersView },
  [EViews.HISTORY]: { label: "Historia", view: HistoryView },
};

export type ViewProps = {
  onSelect: (reservationsGroup: ReservationsGroup) => void;
};

import React, { useMemo } from "react";
import { Grid, Typography, Divider, Box, IconButton } from "@mui/material";
import { useReservationContext } from "../ReservationContext";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
export default function InsuranceDataList({ visibility }) {
  const { reservationData } = useReservationContext();
  const insuranceData = reservationData?.insuranceData;

  const generalInfo = useMemo(() => {
    if (!insuranceData) return null;
    return {
      Vahinkonumero: insuranceData.claimId,
      Vakuutustuote: insuranceData.product,
      Laskutuslupatunnus: insuranceData.billingPermitCode,
      "Tilauskäsittelijän nimi": insuranceData.claimAdjusterName,
      "Tilauskäsittelijän sähköposti": insuranceData.claimAdjusterEmail,
      Enimmäiskorvausaika: insuranceData.compensatableDays,
      "Laskutuksen maksaja": insuranceData.billingPayer,
      "Korvattava autoluokka": insuranceData.replacementVehicleClass,
      Kiireellinen: insuranceData.urgent ? "Kyllä" : "Ei",
      "ALV -vähennettävä": insuranceData.vatDeductible ? "Kyllä" : "Ei",
    };
  }, [insuranceData]);

  const customerVehicle = useMemo(() => {
    if (!reservationData || !insuranceData) return null;
    return {
      Merkki: insuranceData?.customerCarMaker,
      Malli: insuranceData.customerCarModel,
      Rekisterinumero: reservationData?.customerCarRegistrationPlate,
      "Omistajan nimi": reservationData?.name,
      "Omistajan puhelinnumero": reservationData?.phone,
    };
  }, [insuranceData, reservationData]);

  /*   const locationInfo = (location) => {
    return (
      location && {
        Kuvaus: location.description,
        Katuosoite: location.address,
        Kaupunki: location.city,
        Koordinaatit: location.coords
          ? `${location.coords.lat}, ${location.coords.long}`
          : undefined,
      }
    );
  };
 */
  const DataBox = ({ title, data }) => {
    return (
      data && (
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginBottom: "20px", boxShadow: "none" }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontFamily: "Sofia Pro" }}
          >
            {title}
          </Typography>
          <Divider flexItem variant="horizontal" sx={{ marginY: "5px" }} />
          {Object.entries(data).map(([key, value]) => {
            if (value && key === "Omistajan puhelinnumero") {
              return (
                <Box
                  key={key}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Typography>
                    <strong>{key}:</strong> {value}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{ marginBottom: "3px" }}
                    onClick={() => (window.location.href = `tel:${value}`)}
                  >
                    <LocalPhoneIcon />
                  </IconButton>
                </Box>
              );
            } else {
              if (value) {
                return (
                  <Typography key={key}>
                    <strong>{key}:</strong> {value}
                  </Typography>
                );
              } else {
                return null;
              }
            }
          })}
        </Grid>
      )
    );
  };

  if (visibility)
    return (
      <Grid item container spacing={1} direction={"row"}>
        <DataBox title="Vakuutuksen tiedot" data={generalInfo} />
        <DataBox title="Asiakkaan ajoneuvo" data={customerVehicle} />
      </Grid>
    );
}

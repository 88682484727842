import React, { useState, useRef } from "react";
import { useFormikContext } from "formik";
import { TextField, Button, Box, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO, formatISO, format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

import vanDamageMap from "../../assets/damageMapImages/van_damagemap.webp";
import carDamageMap from "../../assets/damageMapImages/car_damagemap.webp";
import jetskiDamageMap from "../../assets/damageMapImages/jetski_damagemap.webp";
import bicycleDamageMap from "../../assets/damageMapImages/bicycle_damagemap.webp";
import cabinettrolleyDamageMap from "../../assets/damageMapImages/cabinettrolley_damagemap.webp";
import camperDamageMap from "../../assets/damageMapImages/camper_damagemap.webp";
import snowmobileDamageMap from "../../assets/damageMapImages/snowmobile_damagemap.webp";
import trailerDamageMap from "../../assets/damageMapImages/trailer_damagemap.webp";

export default function DamageMap({ data }) {
  const { values, setFieldValue } = useFormikContext();
  const [selectedDotIndex, setSelectedDotIndex] = useState(null);
  const imageRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showLabels, setShowLabels] = useState(true);

  const damageMap = {
    "product-type-vans": vanDamageMap,
    "product-type-minibus": vanDamageMap,
    "product-type-rvs": vanDamageMap,
    "product-type-jetskis": jetskiDamageMap,
    "product-type-cars": carDamageMap,
    "product-type-bicycles": bicycleDamageMap,
    "product-type-trailers": cabinettrolleyDamageMap,
    "product-type-trailer-enclosed": cabinettrolleyDamageMap,
    "product-type-campers": camperDamageMap,
    "product-type-snowmobiles": snowmobileDamageMap,
    "product-type-trailer-open": trailerDamageMap,
    // ... any other product types
  };

  const imageSrc = damageMap[data?.category?.typeId] || null;

  const toggleLabelsVisibility = (event) => {
    setShowLabels(event.target.checked);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const currentDamages = values.damageRecords.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleImageClick = (event) => {
    event.preventDefault();
    const rect = imageRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (selectedDotIndex !== null) {
      setSelectedDotIndex(null);
      return;
    }

    // Determine the next label number by finding the smallest unused number
    const existingLabels = values.damageRecords
      .map((dot) => parseInt(dot.label.replace(/^\D+/g, ""), 10))
      .filter((n) => !isNaN(n))
      .sort((a, b) => a - b);
    let nextLabelNumber = 1;
    for (let i = 0; i < existingLabels.length; i++) {
      if (existingLabels[i] > nextLabelNumber) break;
      nextLabelNumber = existingLabels[i] + 1;
    }

    const newDot = {
      normalizedX: x / rect.width,
      normalizedY: y / rect.height,
      label: `Vaurio ${nextLabelNumber}`,
      description: "",
      createdAt: new Date().toISOString(),
    };

    const newCoordinates = [...values.damageRecords, newDot];
    setFieldValue("damageRecords", newCoordinates);

    setSelectedDotIndex(newCoordinates.length - 1);
  };

  const handleDotClick = (index, event) => {
    event.stopPropagation();
    setSelectedDotIndex(index);
  };

  const handleEditChange = (field, value) => {
    const updatedCoordinates = values.damageRecords.map((coord, i) =>
      i === selectedDotIndex ? { ...coord, [field]: value } : coord
    );
    setFieldValue("damageRecords", updatedCoordinates);
  };

  const handleDateChange = (index, newValue) => {
    if (newValue && !isNaN(newValue.getTime())) {
      // Check if newValue is a valid date
      const updatedCoordinates = values.damageRecords.map(
        (coord, i) =>
          i === index ? { ...coord, createdAt: formatISO(newValue) } : coord // Use formatISO for consistent formatting
      );
      setFieldValue("damageRecords", updatedCoordinates);
    }
  };

  const deleteDot = () => {
    const filteredCoordinates = values.damageRecords.filter(
      (_, i) => i !== selectedDotIndex
    );
    setFieldValue("damageRecords", filteredCoordinates);
    setSelectedDotIndex(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        sx={{ maxWidth: 600, margin: "auto" }}
        justifyContent={"center"}
      >
        <Box
          sx={{
            maxWidth: "100%",
            position: "relative",
            "& img": {
              height: 400,
              width: "auto",
            },
          }}
          ref={imageRef}
          onClick={handleImageClick}
          style={{ userSelect: "none", WebkitUserDrag: "none" }}
        >
          <img
            src={imageSrc}
            alt="Vehicle"
            style={{
              cursor: "crosshair",
              display: "block",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
          {values.damageRecords.map((coord, index) => (
            <React.Fragment key={index}>
              <Box
                onClick={(event) => handleDotClick(index, event)}
                sx={{
                  position: "absolute",
                  left: `${coord.normalizedX * 100}%`,
                  top: `${coord.normalizedY * 100}%`,
                  transform: "translate(-50%, -50%)",
                  width: { xs: 10, sm: 10, md: 15 },
                  height: { xs: 10, sm: 10, md: 15 },
                  borderRadius: "50%",
                  backgroundColor: selectedDotIndex === index ? "blue" : "red",
                  cursor: "pointer",
                }}
              />
              {showLabels && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${coord.normalizedX * 100}%`,
                    top: `${coord.normalizedY * 100 + 2}%`,
                    transform: "translate(-50%, 0)",
                    color: "white",
                    fontSize: "0.75rem",
                    backgroundColor: "rgba(0,0,0,0.7)",
                    borderRadius: "4px",
                    padding: { xs: "1px 2px", sm: "1px 2px", md: "2px 4px" },
                    userSelect: "none",
                  }}
                >
                  {coord.label}
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: 2,
              zIndex: 30,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={showLabels}
                  onChange={toggleLabelsVisibility}
                />
              }
              label="Näytä nimikkeet"
            />
          </Box>
          <Typography>Vaurion tiedot</Typography>
          <Grid container>
            <Grid item xs={12}>
              {" "}
              <TextField
                margin="normal"
                label="Nimike"
                variant="outlined"
                fullWidth
                disabled={selectedDotIndex === null}
                value={
                  selectedDotIndex !== null &&
                  values.damageRecords[selectedDotIndex]
                    ? values.damageRecords[selectedDotIndex].label
                    : ""
                }
                onChange={(e) => handleEditChange("label", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {" "}
              <TextField
                label="Lisätiedot"
                variant="outlined"
                fullWidth
                disabled={selectedDotIndex === null}
                value={
                  selectedDotIndex !== null &&
                  values.damageRecords[selectedDotIndex]
                    ? values.damageRecords[selectedDotIndex].description
                    : ""
                }
                onChange={(e) =>
                  handleEditChange("description", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} marginTop={1}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <DateTimePicker
                  label="Luotu"
                  value={
                    selectedDotIndex !== null &&
                    values.damageRecords[selectedDotIndex]
                      ? parseISO(
                          values.damageRecords[selectedDotIndex].createdAt
                        )
                      : null
                  }
                  disabled={selectedDotIndex === null}
                  ampm={false}
                  onChange={(newValue) => {
                    handleDateChange(selectedDotIndex, newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />

                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={deleteDot}
                  disabled={selectedDotIndex === null}
                  sx={{ marginLeft: "auto", marginTop: 1 }}
                >
                  Poista vaurio
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Box sx={{ maxWidth: 600, margin: "auto", marginTop: 2 }}>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nimike</TableCell>
                        <TableCell align="right">Luotu</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentDamages.map((damage, index) => (
                        <TableRow
                          key={damage.label}
                          hover
                          selected={
                            selectedDotIndex === index + page * rowsPerPage
                          }
                          onClick={() =>
                            setSelectedDotIndex(index + page * rowsPerPage)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" scope="row">
                            {damage.label}
                          </TableCell>
                          <TableCell align="right">
                            {damage.createdAt
                              ? format(parseISO(damage.createdAt), "PPPp")
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={values.damageRecords.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </LocalizationProvider>
  );
}

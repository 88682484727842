import {
  Box,
  Grid,
  TextField,
  Typography,
  useTheme,
  Popper,
  Paper,
  InputAdornment,
  IconButton,
  ClickAwayListener,
  Fade,
  Skeleton,
} from "@mui/material";

import React, { useContext, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";
import { AiOutlineClear } from "react-icons/ai";
import { AppContext } from "../AppContext";
import { useMobileViewPort } from "../utils/mobileViewPort";
import { useDebouncedEffect } from "../utils/use-debounced-effect";
import { useFindVehicle } from "../services/findVehicle";
import VehicleImage from "~/components/VehicleImage";

export default function FindVehicle({
  navBoxRef,
  showFindVehicle,
  setShowFindVehicle,
}) {
  const theme = useTheme();
  const mobileViewPort = useMobileViewPort(theme);
  const [queryResults, setQueryResults] = useState([]);
  const [findVehicleInput, setFindVehicleInput] = useState("");

  const handleClear = () => {
    setFindVehicleInput("");
    setQueryResults([]);
  };

  return (
    <ClickAwayListener onClickAway={() => setShowFindVehicle(false)}>
      <Popper
        open={showFindVehicle}
        anchorEl={navBoxRef.current}
        style={{ zIndex: 1100 }}
      >
        {showFindVehicle && (
          <Paper
            sx={{
              borderRadius: mobileViewPort ? undefined : "14px",
              marginTop: mobileViewPort ? undefined : "20px",
              padding: "15px",
              maxHeight: mobileViewPort ? undefined : "800px",
              overflow: "auto",
              height: mobileViewPort ? "100%" : undefined,
              width: mobileViewPort ? "100vw" : undefined,
              minHeight: mobileViewPort ? undefined : "130px",
              minWidth: mobileViewPort ? undefined : "500px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "10px",
              }}
            >
              <IconButton
                size="small"
                onClick={() => setShowFindVehicle(false)}
              >
                <Close style={{ color: "rgba(0,0,0,0.25)" }} />
              </IconButton>
            </Box>

            <FindVehicleInputField
              findVehicleInput={findVehicleInput}
              setFindVehicleInput={setFindVehicleInput}
              setQueryResults={setQueryResults}
              handleClear={handleClear}
            />

            <FindVehicleResults
              findVehicleInput={findVehicleInput}
              setFindVehicleInput={setFindVehicleInput}
              queryResults={queryResults}
            />
          </Paper>
        )}
      </Popper>
    </ClickAwayListener>
  );
}

function FindVehicleResults(props) {
  const { findVehicleInput, queryResults } = props;
  const { user, setSelectedBusiness } = useContext(AppContext);
  const [input, setInput] = useState(findVehicleInput);
  const { data, isLoading } = useFindVehicle(input);

  useDebouncedEffect(
    () => {
      setInput(findVehicleInput);
    },
    400,
    [findVehicleInput]
  );
  if (isLoading) {
    return (
      <Box sx={{ marginY: "10px" }}>
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={24}
          sx={{ margin: "2px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={15}
          sx={{ margin: "2px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={15}
          sx={{ margin: "2px" }}
        />
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return "Ei tuloksia";
  }

  if (data)
    return data.map((item, idx) => {
      return (
        <Fade in={!!item.name} key={`customers-${idx}`}>
          <Box
            key={item.name + idx}
            sx={{
              display: "flex",
              cursor: "pointer",
              borderBottom:
                idx + 1 !== queryResults.length
                  ? "1px solid rgba(0,0,0,0.07)"
                  : undefined,
              padding: "10px",

              width: "100%",
              height: "100px",
              marginY: "5px",
            }}
            onClick={() => {
              const newBusiness = user?.businessByOrg?.items.find(
                (business) => business.id === item?.orgBusinessId
              );

              if (newBusiness && user?.role === "ORGANIZATION_ADMIN") {
                setSelectedBusiness(newBusiness);
              }
            }}
          >
            <VehicleImage
              vehicle={item}
              variant="rounded"
              sx={{ marginRight: "10px", height: "75px", width: "75px" }}
            />
            <Box
              sx={{
                display: "flex",
                transition: "150ms ease-in-out",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: 550,
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  color: "grey",
                }}
              >
                {item?.registrationPlate}
              </Typography>
              <Typography>
                {
                  user?.businessByOrg?.items.find(
                    (business) => item.orgBusinessId === business.id
                  )?.name
                }
              </Typography>
            </Box>
          </Box>
        </Fade>
      );
    });
}

function FindVehicleInputField(props) {
  const {
    findVehicleInput,
    setFindVehicleInput,
    setQueryResults,
    handleClear,
  } = props;
  return (
    <Box sx={{ padding: "10px" }}>
      <Grid container direction="column" justifyContent="space-between">
        <TextField
          inputMode="search"
          placeholder="Ajoneuvohaku"
          variant="standard"
          value={findVehicleInput}
          data-cy="customerSearchField"
          autoFocus={true}
          onChange={(event) => {
            setFindVehicleInput(event.target.value);

            if (event.target.value.length === 0) {
              setQueryResults([]);
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => handleClear()}>
                  <AiOutlineClear
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Box>
  );
}

import React from "react";
import { Grid, TextField } from "@mui/material";
import { FormContainer } from "../../../../../components/FormContainer";
import { useField, useFormikContext } from "formik";
import { FormInputField } from "../../../../../components/ReservationForm/utils/FormInputField";
import { SelectHanselCustomer } from "./components/SelectHanselCustomer";
import { SelectHanselClass } from "./components/SelectHanselClass";
import { SelectHanselInvoice } from "./components/SelectHanselInvoice";
import { useFetchVehicles } from "../../../../../../../services/fetchVehicles";
import { getReservationDuration } from "../../../../../utils/utilities";

export default function HanselFields() {
  const { values } = useFormikContext();

  const isDisabled = values?.type !== "HANSEL";

  return (
    <FormContainer title={"Hansel-raportti"} visibility={true}>
      <Grid item container spacing={1} direction={"row"}>
        <SelectHanselInvoice />
        <Grid item xs={12} md={6}>
          <SelectHanselCustomer isDisabled={isDisabled} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectHanselClass isDisabled={isDisabled} />
        </Grid>
        <Grid item xs={6} md={4}>
          <TotalMileageField />
        </Grid>
        <Grid item xs={6} md={4}>
          <EmissionsField />
        </Grid>
        <Grid item xs={6} md={4}>
          <DurationField />
        </Grid>

        <Grid item xs={12}>
          <FormInputField
            name="hanselData.desc"
            label="Selite"
            disabled={isDisabled}
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
}

const TotalMileageField = () => {
  const { values } = useFormikContext();
  const mileageFromVehicle =
    values?.reservationVehicles[0]?.endMileage -
    values?.reservationVehicles[0]?.startMileage;
  const totalMileage =
    values?.hanselData?.additionalInfo1 ?? mileageFromVehicle;

  return (
    <TextField
      value={isNaN(totalMileage) ? "" : totalMileage}
      fullWidth
      label="Kilometrit"
    />
  );
};

const EmissionsField = (product) => {
  const { values } = useFormikContext();
  const { vehicleData } = useFetchVehicles();

  const [field, meta] = useField("hanselData.additionalInfo2");
  const value =
    meta?.value ??
    vehicleData
      ?.find((v) => v.id === values?.reservationVehicles[0]?.id)
      ?.additionalFields.find((field) => field.key === "emission")?.value;

  return <TextField {...field} value={value ?? ""} fullWidth label="Päästöt" />;
};

const DurationField = () => {
  const { values } = useFormikContext();
  const duration = getReservationDuration(
    values?.startTime,
    values?.returnTime
  );

  return <TextField value={duration} fullWidth label="Vrk" />;
};

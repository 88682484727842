import Invoices from "./Invoices";
import Invoicing from "./Invoicing";

export default function InvoicingContainer({
  setShowInvoiceDialog,
  business,
  values,
  notification,
  FEATURE_INVOICING,
  FEATURE_INNOVOICE,
}) {
  if (!FEATURE_INNOVOICE) return;
  return (
    <>
      <Invoices
        setShowInvoiceDialog={setShowInvoiceDialog}
        FEATURE_INVOICING={FEATURE_INVOICING}
      />
      <Invoicing
        setShowInvoiceDialog={setShowInvoiceDialog}
        business={business}
        values={values}
        notification={notification}
      />
    </>
  );
}

import { Grid, TextField } from "@mui/material";
import { FormGrid } from "../common/FormComponents";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

export default function BusinessInformation() {
  const { business } = useContext(AppContext);

  return (
    <Grid container spacing={2} direction={"row"}>
      <FormGrid md={6}>
        <TextField
          value={business.name ?? ""}
          name="name"
          label="Nimi"
          variant="outlined"
          disabled
          fullWidth
        />
      </FormGrid>

      <FormGrid md={6}>
        <TextField
          value={business.phone ?? ""}
          name="phone"
          label="Puhelin"
          variant="outlined"
          disabled
          fullWidth
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={business.email ?? ""}
          name="email"
          label="Sähköposti"
          variant="outlined"
          fullWidth
          disabled
        />
      </FormGrid>
    </Grid>
  );
}

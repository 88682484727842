import { BiMinus, BiPlus } from "react-icons/bi";
import { parseToCents } from "../../../shared/money";
import { IconButton, Stack, Typography } from "@mui/material";

// Price of distance and deductible services is by default multiplied by the amount of vehicles
export const getPriceMultiplier = (category, values) => {
  if (["deductible", "distance"].includes(category)) {
    return values?.vehicleIds.length;
  }
  return 1;
};

export const totalPrice = (values, offerPrice) => {
  if (!values) return;
  const additionalServices = values.additionalServices ?? [];
  let effectiveVehicleTotalPrice = 0;
  for (let id of values?.vehicleIds) {
    const effectiveVehiclePrice = getEffectiveVehiclePrice(
      values,
      offerPrice,
      id
    );
    if (effectiveVehiclePrice === null) {
      return null;
    }
    effectiveVehicleTotalPrice += effectiveVehiclePrice;
  }

  return additionalServices.reduce(
    (p, c) => p + c.price,
    effectiveVehicleTotalPrice
  );
};

// Return price entered in to PriceField, otherwise price from the offer
// TODO: get rid of this
export const getEffectiveVehiclePrice = (values, offerPrice, vehicleId) => {
  let vehiclePrice = parseToCents(values["vehiclePrice-" + vehicleId]);
  return vehiclePrice ?? offerPrice?.vehicleOfferPrices[vehicleId]?.offerPrice;
};

// Return price entered in to PriceField, otherwise price from the offer
export const getEffectiveOfferPrice = (offer) => {
  if (offer?.modifiedPrice === "" || !offer?.modifiedPrice) {
    return offer?.price ?? offer?.listingPrice;
  } else {
    return parseToCents(offer?.modifiedPrice);
  }
};

export const nextFullHour = (date) => {
  const hour = 3600000;
  return new Date((Math.floor(date.getTime() / hour) + 1) * hour);
};

export function QuantitySelector(props) {
  const { visibility, quantityModifier, quantity, maxQuantity, targetId } =
    props;

  const size = "30px";

  if (visibility)
    return (
      <Stack
        direction={"row"}
        justifyContent={"space-evenly"}
        sx={{
          border: "1px solid rgba(0,0,0,0.15)",
          borderRadius: "12px",
          overflow: "hidden",
          marginRight: "10px",
          marginBottom: "3px",
        }}
      >
        <IconButton
          size="small"
          variant="contained"
          onClick={() => {
            if (quantity > 1) {
              return quantityModifier(targetId, quantity - 1);
            } else return null;
          }}
          sx={{
            width: size,
            height: size,

            // backgroundColor: theme.palette.primary.decButton,
            //color: theme.palette.primary.buttonText,
            color: "#494848",
            backgroundColor: "transparent",
            borderRadius: 0,
          }}
        >
          <BiMinus style={{ fontSize: "14px" }} />
        </IconButton>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: size,
            height: size,
            fontWeight: "bolder",
            // backgroundColor: theme.palette.primary.qtyBackground,
            // color: theme.palette.primary.mainText,
            color: "#494848",
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "1em" }}>
            {quantity}
          </Typography>
        </div>
        <IconButton
          variant="contained"
          // disabled={alreadySelectedDeductible}
          sx={{
            width: size,
            height: size,
            borderRadius: 0,
            //  backgroundColor: theme.palette.primary.addButton,
            //  color: theme.palette.primary.buttonText,
            backgroundColor: "transparent",
            color: "#494848",
          }}
          onClick={() => {
            // maxQuantity is enabled
            if (maxQuantity) {
              if (quantity < maxQuantity) {
                return quantityModifier(targetId, quantity + 1);
              }
              if (quantity >= maxQuantity) {
                return null;
              }
              // quantity isnt restricted by maxQuantity
            } else {
              return quantityModifier(targetId, quantity + 1);
            }
          }}
        >
          <BiPlus style={{ fontSize: "30px" }} />
        </IconButton>
      </Stack>
    );
}

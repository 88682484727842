import { Box, Button, CircularProgress, Skeleton } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { useRef, useEffect, useState } from "react";
// @ts-ignore
import DialogComponent from "../../../../../../../DialogComponent";
// @ts-ignore
import { useNotification } from "~/components/Notification";
import {
  getReceiptHTML,
  sendReceiptEmail,
} from "../../../../../../../../graphql/queries";
import { GetReceiptHTMLQuery, SendReceiptEmailQuery } from "~/graphql/API";

interface ReceiptDialogProps {
  open: boolean;
  onClose: () => void;
  reservationId: string;
}

function ReceiptDialog({ open, onClose, reservationId }: ReceiptDialogProps) {
  const [html, setHtml] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const notification = useNotification();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    async function startFetching() {
      setHtml("");
      const response = (await API.graphql(
        graphqlOperation(getReceiptHTML, {
          reservationId: reservationId,
        })
      )) as GraphQLResult<GetReceiptHTMLQuery>;
      const { success, receiptHTML, message } =
        response.data?.getReceiptHTML ?? {};
      if (success) {
        if (!ignore && receiptHTML) {
          setHtml(receiptHTML);
        }
      } else {
        notification.show(message ?? "Virhe kuittia muodostaessa", "error");
        onClose();
      }
    }

    let ignore = false;
    startFetching();
    return () => {
      ignore = true;
    };
  }, [reservationId]);

  // Function to print the content of the iframe
  const handlePrint = () => {
    const iframeWindow = iframeRef.current?.contentWindow;
    if (iframeWindow) {
      iframeWindow.focus();
      iframeWindow.print();
    }
  };

  // Function to send the receipt via email
  const handleSendEmail = async () => {
    setLoadingEmail(true);
    try {
      const response = (await API.graphql(
        graphqlOperation(sendReceiptEmail, { reservationId })
      )) as GraphQLResult<SendReceiptEmailQuery>;

      if (response.data?.sendReceiptEmail?.success) {
        notification.show(
          "Sähköposti lähetetty onnistuneesti",
          "success",
          3000
        );
      } else {
        const errorMsg =
          response.data?.sendReceiptEmail?.message ??
          "Sähköpostin lähetys epäonnistui";
        notification.show(errorMsg, "error", 10000);
      }
    } catch (error) {
      notification.show("Jokin meni vikaan", "error");
      console.error("Error sending email:", error);
    } finally {
      setLoadingEmail(false);
    }
  };

  if (!html)
    return (
      <DialogComponent
        open={open}
        onClose={onClose}
        dialogClose={onClose}
        maxWidth={"md"}
      >
        <Skeleton
          variant="rectangular"
          animation="pulse"
          width="100%"
          height={"70vh"}
        />
      </DialogComponent>
    );

  return (
    <DialogComponent
      open={open}
      onClose={onClose}
      dialogClose={onClose}
      maxWidth={"md"}
      title="Kuitti"
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="contained" onClick={handlePrint}>
          Tulosta
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendEmail}
          disabled={loadingEmail}
        >
          {loadingEmail ? (
            <CircularProgress size={24} />
          ) : (
            "Lähetä sähköpostilla"
          )}
        </Button>
      </Box>
      <iframe
        ref={iframeRef}
        style={{ width: "100%", minHeight: "70vh", border: "none" }}
        srcDoc={html}
        title="Receipt Preview"
      />
    </DialogComponent>
  );
}

export default ReceiptDialog;

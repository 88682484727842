import { Box, Grid, Typography } from "@mui/material";
import InvoiceItem from "./components/InvoiceItem";
import { Fragment } from "react";
import { Invoice } from "~/graphql/API";

type InvoiceItemsProps = { invoice: Invoice };

function InvoiceItems({ invoice }: InvoiceItemsProps) {
  return (
    <Grid container item spacing={1}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        {invoice.items.map((item, index) => {
          return (
            <Fragment key={index}>
              {invoice.items[index - 1]?.reservationId !==
                item?.reservationId && (
                <Box sx={{ display: "flex", gap: 3, marginBottom: -2 }}>
                  <Typography
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: 20,
                    }}
                  >
                    Laskurivit
                  </Typography>
                  {/* {item.reservation?.startTime && item.reservation?.endTime && (
                    <Typography
                      style={{
                        fontFamily: "Sofia Pro",
                        fontSize: 20,
                        color: "#545454",
                      }}
                    >
                      {formatDate(item.reservation.startTime as string) +
                        " - " +
                        formatDate(item.reservation.endTime as string)}
                    </Typography>
                  )} */}
                </Box>
              )}

              <InvoiceItem key={index} item={item} />
            </Fragment>
          );
        })}
      </Box>
    </Grid>
  );
}

export default InvoiceItems;

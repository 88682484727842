import { Box, Stack, Typography, useTheme, Link, Grid } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import React, { useMemo } from "react";
import { AppContext } from "../../AppContext";
import { getReservation } from "../../graphql/queries";
import { useFetchReservations } from "../../services/fetchReservations";
import captureError from "../../utils/capture-error";
import { getZonedTime } from "../../utils/common";
import { useNotification } from "../Notification";
import Bulb from "./Bulb";
import Timing from "./Timing";
import TruncatedTypography from "../TruncatedTypography";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";

const NotificationBase = ({ children, notification, markAsRead }) => {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const theme = useTheme();
  const isActive = useMemo(
    () => (notification?.status === "READ" ? false : true),
    [notification]
  );
  const { setShowNews, timezone } = React.useContext(AppContext);
  const notificationToast = useNotification();
  const { reservations } = useFetchReservations("existBetween");

  const setEditReservationDataById = async (id, notificationClick = false) => {
    let reservation = reservations.find((reservation) => reservation.id === id);
    if (!reservation) {
      try {
        reservation = (
          await API.graphql(
            graphqlOperation(getReservation, {
              id: id,
            })
          )
        ).data.getReservation;
        if (!reservation) {
          if (!notificationClick) {
            console.error("reservation not found");
            captureError(
              "Get reservation by id failed",
              "GET_RESERVATION_FAILED",
              `Reservation ${id}  not found`
            );
          } else {
            notificationToast.show("Varausta ei löydy");
          }
          return;
        }
        // Use company timezone

        reservation = {
          ...reservation,
          startTime: getZonedTime(reservation.startTime, timezone),
          returnTime: getZonedTime(reservation.returnTime, timezone),
          endTime: getZonedTime(reservation.endTime, timezone),
        };
      } catch (e) {
        console.error("getReservation failed", e);
        captureError(
          "Get reservation by id failed",
          "GET_RESERVATION_FAILED",
          `getReservation failed for ${id}`
        );
        notificationToast.show("Varauksen haku epäonnistui", "warning");
      }
    }
    setEditReservationData(reservation);
  };

  return (
    <Box
      style={{ cursor: "pointer" }}
      px={1}
      py={0.5}
      height="60px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      position="relative"
      onClick={() => {
        if (notification.type === "NEWS_CREATED") {
          markAsRead(notification);
          setShowNews(true);
          return;
        }
        markAsRead(notification);
        setEditReservationDataById(notification.reservationId, true);
      }}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      }}
    >
      <Box mr={1}>{isActive && <Bulb width="10px" height="10px" />}</Box>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Box>
            {notification.type === "NEWS_CREATED" && (
              <CampaignIcon color="primary" />
            )}
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <Link component="button" underline="none" color="inherit">
              {children}
            </Link>
          </Box>
        </Grid>
        {true && (
          <Grid item>
            <Box
              sx={{
                minWidth: 50,
                textAlign: "right",
                fontWeight: 600,
                color: isActive
                  ? theme.palette.primary.dark
                  : theme.palette.grey[400],
              }}
            >
              <Timing timestamp={notification.createdAt} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const Details = ({ notification }) => {
  //if (!notification?.name) return null;
  let message = "";
  if (notification.name) {
    message = `Varaajalta: ${notification.name ?? ""}`;
  } else if (notification.type === "NEWS_CREATED") {
    message = notification.message;
  }

  return (
    <TruncatedTypography
      text={message}
      variant="subtitle2"
      textOverflow="ellipsis"
      overflow="hidden"
      align="left"
      lineclamp="2"
    />
  );
};

const Message = ({ ...props }) => {
  return (
    <NotificationBase {...props}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex" width="100%">
          <Stack alignItems="flex-start" overflow="hidden">
            <Typography
              variant="body2"
              fontWeight={
                props.notification.type === "NEWS_CREATED" ? "bold" : "normal"
              }
            >
              {props.message}
            </Typography>
            <Details notification={props.notification} />
          </Stack>
        </Box>
      </Box>
    </NotificationBase>
  );
};

const Booking = ({ ...props }) => {
  return (
    <NotificationBase {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Stack ml={1}>
            <Typography variant="body1">Uusi Varaus nimellä</Typography>
          </Stack>
        </Box>
      </Box>
    </NotificationBase>
  );
};

export default function NotificationItem({ type, ...props }) {
  switch (type) {
    case "EXTERNAL_NOTE":
      return <Message message="Viesti vuokraamolle" {...props} />;
    case "LIVION_KEY_FAILURE":
      return (
        <Message message="Livion Key -koodin luonti epäonnistui" {...props} />
      );
    case "TILAKONE_FAILURE":
      return (
        <Message
          message="Tilakone pääsyoikeuden luonti epäonnistui"
          {...props}
        />
      );
    case "ADDITIONAL_SERVICE":
      return <Message message="Huomautus lisäpalvelusta" {...props} />;
    case "EXPLANATION_REQUEST":
      return (
        <Message message="Varauksen arviointi vaatii selitystä" {...props} />
      );
    case "PAYMENT_DEFAULTS":
      return <Message message="Varaus hylätty: maksuhäiriö" {...props} />;
    case "Booking":
      return <Booking {...props} />;
    case "NEWS_CREATED":
      return <Message message="Tiedote" {...props} />;
    case "EXTERNAL_ORDER":
      return <Message message="Uusi ulkoinen varaus" {...props} />;
    case "INSURANCE_EDIT":
      return <Message message="Vakuutusvarausta muokattu" {...props} />;
    case "CUSTOMER_AUTH_BYPASS":
      return (
        <Message message="Asiakas ei vahvasti tunnistautunut" {...props} />
      );
    default:
      return null;
  }
}

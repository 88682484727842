import * as React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Grid, Menu, MenuItem, Typography, Button } from "@mui/material";

export default function CategoryMenu({ categories, changeSelectedCategory }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (!categories) return null;
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    changeSelectedCategory(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Button
        style={{ textTransform: "none", minHeight: 0, minWidth: 0, padding: 0 }}
        onClick={handleClickListItem}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-evenly",
          }}
        >
          <Typography
            style={{
              fontFamily: "Sofia Pro",
              fontSize: 18,

              fontWeight: 550,
            }}
          >
            Kategoria:
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              marginBottom: 2,
              marginLeft: 5,
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {categories[selectedIndex]?.name}
          </Typography>
          <ArrowDropDownIcon style={{ fontSize: 26, marginTop: 3 }} />
        </div>
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
          style: { maxHeight: "300px" },
        }}
      >
        {categories.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(option, index)}
            style={{ fontFamily: "Roboto", fontWeight: 500 }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
}

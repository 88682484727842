import React, { useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  Box,
  Avatar,
} from "@mui/material";

import CategoriesEditDialog from "./CategoriesEditDialog";
import CategoriesCreateDialog from "./CategoriesCreateDialog";
import DialogComponent from "../DialogComponent";
import { AppContext } from "../../AppContext";
import { useCategories } from "~/queries/useCategories";
import { useFetchVehicles } from "../../services/fetchVehicles";
import { useFetchPricings } from "../../services/fetchPricings";
import {
  useContextFetchCompanies,
  useFetchCompanies,
} from "../../services/fetchCompanies";
import { mutate } from "swr";

export default function CategoriesDialog({
  open,
  onClose,
  showPricings,
  openVehicleData,
}) {
  const { user, group, company, business } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const { pricings } = useFetchPricings(group, user);
  const categories = useCategories(companies);
  const [editableCategory, setEditableCategory] = useState();
  const [createNewCategory, setCreateNewCategory] = useState();
  const { vehiclesMutate } = useFetchVehicles();
  const { companiesMutate } = useContextFetchCompanies();

  const disableFields = user?.role === "BUSINESS_ADMIN";

  const createNew = () => {
    setCreateNewCategory({ category: {} });
  };

  return (
    <DialogComponent
      open={open}
      dialogClose={() => onClose()}
      dialogTitle={"Kategoriat"}
      dialogAction={
        disableFields
          ? undefined
          : () => {
              createNew();
            }
      }
      dialogActionText={"Lisää"}
    >
      {categories && (
        <Box style={{ marginTop: "25px" }} data-cy="categoriesList">
          <Table
            sx={{ borderCollapse: "separate", borderSpacing: "0px 4px" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: 0 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Nimi
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    #
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Varoaika
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Tuotteet
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(categories || []).map((c, idx) => {
                return (
                  <TableRow
                    key={idx}
                    hover
                    sx={{ cursor: "pointer" }}
                    onClick={() => setEditableCategory(c)}
                  >
                    <TableCell>
                      <Box
                        display={"flex"}
                        flexDirection="row"
                        alignItems={"center"}
                      >
                        <Avatar
                          sx={{
                            marginRight: "10px",
                            bgcolor: c?.backgroundColor,
                          }}
                          variant="rounded"
                          /*   src={
                            c?.typeId !== undefined
                              ? typeOfProducts.find(
                                  (type) => type.id === c?.typeId
                                ).icon
                              : c.name[0]
                          }  */
                        >
                          {c.name[0]}
                        </Avatar>
                        <Typography variant="body1">{c.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right" sx={{}}>
                      <Typography variant="body1"> {c?.orderNumber}</Typography>
                    </TableCell>
                    <TableCell align="right" sx={{}}>
                      {c?.minuteBuffer}
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body1">
                        {c?.vehicles?.length} {/* /{" "} */}
                        {/*  {calculateProductsFromAllCompanies(c.id)} */}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}

      {editableCategory && (
        <CategoriesEditDialog
          companies={companies}
          category={editableCategory}
          categories={categories}
          openVehicleData={openVehicleData}
          company={company}
          onClose={() => {
            setEditableCategory(false);
            mutate([user?.group, user?.organizationId, "categories"]);
            vehiclesMutate();
          }}
          showPricings={showPricings}
        />
      )}
      {createNewCategory && (
        <CategoriesCreateDialog
          category={createNewCategory}
          company={company}
          onClose={() => {
            setEditableCategory(false);
            setCreateNewCategory(false);
            mutate([user?.group, user?.organizationId, "categories"]);
            vehiclesMutate();
          }}
          pricings={pricings}
          showPricings={showPricings}
        />
      )}
    </DialogComponent>
  );
}

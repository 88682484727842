/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getReservationOffersAdmin = /* GraphQL */ `query GetReservationOffersAdmin(
  $companyIds: [ID!]!
  $reservationCompanyId: ID
  $group: String
  $businessId: ID
  $externalBusinessId: ID
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime!
  $disableCategoryDefaultsStacking: Boolean
) {
  getReservationOffersAdmin(
    companyIds: $companyIds
    reservationCompanyId: $reservationCompanyId
    group: $group
    businessId: $businessId
    externalBusinessId: $externalBusinessId
    startTime: $startTime
    returnTime: $returnTime
    disableCategoryDefaultsStacking: $disableCategoryDefaultsStacking
  ) {
    startTime
    returnTime
    vehicleOfferPrice
    tax
    additionalServiceOffers {
      offerPrice
      tax
      deductibleValue
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      category
      adHocService
      image
      key
      __typename
    }
    freeTimes {
      lastStartTime
      lastReturnTime
      returnTimes {
        end
        start
        __typename
      }
      firstStartTime
      __typename
    }
    reservedTimes {
      end
      start
      __typename
    }
    availableInCategory
    useVehicleTimes
    disableOffHourReturn
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationOffersAdminQueryVariables,
  APITypes.GetReservationOffersAdminQuery
>;
export const getReservations = /* GraphQL */ `query GetReservations(
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime
  $endTime: AWSDateTime
  $group: String!
  $queryMode: String!
) {
  getReservations(
    startTime: $startTime
    returnTime: $returnTime
    endTime: $endTime
    group: $group
    queryMode: $queryMode
  ) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    customerNotes
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationsQueryVariables,
  APITypes.GetReservationsQuery
>;
export const getPriceByCategoryIds = /* GraphQL */ `query GetPriceByCategoryIds(
  $categoryIds: [ID!]!
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime!
  $externalBusinessId: ID
) {
  getPriceByCategoryIds(
    categoryIds: $categoryIds
    startTime: $startTime
    returnTime: $returnTime
    externalBusinessId: $externalBusinessId
  ) {
    categoryOfferPrices {
      price
      tax
      deductible
      id
      __typename
    }
    additionalServiceOffers {
      description
      category
      offerPrice
      deductibleValue
      tax
      image
      enableQuantity
      maxQuantity
      key
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPriceByCategoryIdsQueryVariables,
  APITypes.GetPriceByCategoryIdsQuery
>;
export const operateCarDoorSMS = /* GraphQL */ `query OperateCarDoorSMS($vehicleId: ID!) {
  operateCarDoorSMS(vehicleId: $vehicleId)
}
` as GeneratedQuery<
  APITypes.OperateCarDoorSMSQueryVariables,
  APITypes.OperateCarDoorSMSQuery
>;
export const innovoiceCreditRequest = /* GraphQL */ `query InnovoiceCreditRequest($orgBusinessId: ID!, $ssn: String!) {
  innovoiceCreditRequest(orgBusinessId: $orgBusinessId, ssn: $ssn) {
    granted
    amount
    creditDecisionText
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InnovoiceCreditRequestQueryVariables,
  APITypes.InnovoiceCreditRequestQuery
>;
export const searchReservationsByVehicle = /* GraphQL */ `query SearchReservationsByVehicle(
  $vehicleId: String!
  $group: String
  $orgBusinessId: ID
) {
  searchReservationsByVehicle(
    vehicleId: $vehicleId
    group: $group
    orgBusinessId: $orgBusinessId
  ) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    customerNotes
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReservationsByVehicleQueryVariables,
  APITypes.SearchReservationsByVehicleQuery
>;
export const sendReceiptEmail = /* GraphQL */ `query SendReceiptEmail($reservationId: ID!) {
  sendReceiptEmail(reservationId: $reservationId) {
    success
    message
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SendReceiptEmailQueryVariables,
  APITypes.SendReceiptEmailQuery
>;
export const getReceiptHTML = /* GraphQL */ `query GetReceiptHTML($reservationId: ID!) {
  getReceiptHTML(reservationId: $reservationId) {
    success
    message
    receiptHTML
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReceiptHTMLQueryVariables,
  APITypes.GetReceiptHTMLQuery
>;
export const getNewsItem = /* GraphQL */ `query GetNewsItem($id: ID!) {
  getNewsItem(id: $id) {
    id
    header
    link
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewsItemQueryVariables,
  APITypes.GetNewsItemQuery
>;
export const listNewsItems = /* GraphQL */ `query ListNewsItems(
  $filter: ModelNewsItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listNewsItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      header
      link
      content
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNewsItemsQueryVariables,
  APITypes.ListNewsItemsQuery
>;
export const getReservationOffers = /* GraphQL */ `query GetReservationOffers(
  $companyIds: [ID!]
  $startCompanyId: ID
  $group: String
  $orgBusinessId: ID
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime!
  $timeFlexibility: Int!
  $disableCategoryDefaultsStacking: Boolean
) {
  getReservationOffers(
    companyIds: $companyIds
    startCompanyId: $startCompanyId
    group: $group
    orgBusinessId: $orgBusinessId
    startTime: $startTime
    returnTime: $returnTime
    timeFlexibility: $timeFlexibility
    disableCategoryDefaultsStacking: $disableCategoryDefaultsStacking
  ) {
    startTime
    returnTime
    vehicleOfferPrice
    tax
    additionalServiceOffers {
      offerPrice
      tax
      deductibleValue
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      category
      adHocService
      image
      key
      __typename
    }
    freeTimes {
      lastStartTime
      lastReturnTime
      returnTimes {
        end
        start
        __typename
      }
      firstStartTime
      __typename
    }
    reservedTimes {
      end
      start
      __typename
    }
    availableInCategory
    useVehicleTimes
    disableOffHourReturn
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationOffersQueryVariables,
  APITypes.GetReservationOffersQuery
>;
export const getReservationsMonthly = /* GraphQL */ `query GetReservationsMonthly($companyId: ID!, $dateInMonth: AWSDateTime!) {
  getReservationsMonthly(companyId: $companyId, dateInMonth: $dateInMonth) {
    reservedTimes {
      end
      start
      __typename
    }
    useVehicleTimes
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationsMonthlyQueryVariables,
  APITypes.GetReservationsMonthlyQuery
>;
export const getReservationsByVehicle = /* GraphQL */ `query GetReservationsByVehicle(
  $vehicleId: ID!
  $startTime: AWSDateTime!
  $endTime: AWSDateTime!
) {
  getReservationsByVehicle(
    vehicleId: $vehicleId
    startTime: $startTime
    endTime: $endTime
  ) {
    reservedTimes {
      end
      start
      __typename
    }
    useVehicleTimes
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationsByVehicleQueryVariables,
  APITypes.GetReservationsByVehicleQuery
>;
export const getPriceByVehicleIds = /* GraphQL */ `query GetPriceByVehicleIds(
  $vehicleIds: [ID!]!
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime!
  $companyId: ID
  $externalBusinessId: ID
) {
  getPriceByVehicleIds(
    vehicleIds: $vehicleIds
    startTime: $startTime
    returnTime: $returnTime
    companyId: $companyId
    externalBusinessId: $externalBusinessId
  ) {
    vehicleOfferPrices {
      price
      tax
      deductible
      id
      __typename
    }
    additionalServiceOffers {
      description
      category
      offerPrice
      deductibleValue
      tax
      image
      enableQuantity
      maxQuantity
      key
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPriceByVehicleIdsQueryVariables,
  APITypes.GetPriceByVehicleIdsQuery
>;
export const getPriceByVehicleId = /* GraphQL */ `query GetPriceByVehicleId(
  $vehicleId: ID!
  $startTime: AWSDateTime!
  $returnTime: AWSDateTime!
) {
  getPriceByVehicleId(
    vehicleId: $vehicleId
    startTime: $startTime
    returnTime: $returnTime
  ) {
    vehicleOfferPrice
    additionalServiceOffers {
      description
      category
      offerPrice
      deductibleValue
      tax
      image
      enableQuantity
      maxQuantity
      key
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPriceByVehicleIdQueryVariables,
  APITypes.GetPriceByVehicleIdQuery
>;
export const validateVoucher = /* GraphQL */ `query ValidateVoucher($code: String!, $group: ID, $organizationId: ID) {
  validateVoucher(code: $code, group: $group, organizationId: $organizationId) {
    id
    name
    group
    organizationId
    code
    amount
    startDate
    endDate
    _removed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ValidateVoucherQueryVariables,
  APITypes.ValidateVoucherQuery
>;
export const getPaymentMethods = /* GraphQL */ `query GetPaymentMethods {
  getPaymentMethods {
    selected_value
    group
    min_amount
    max_amount
    img
    img_timestamp
    currency
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentMethodsQueryVariables,
  APITypes.GetPaymentMethodsQuery
>;
export const authPayment = /* GraphQL */ `query AuthPayment(
  $reservationId: ID!
  $paymentMethod: PaymentMethodInput
  $origin: String
  $originPath: String
) {
  authPayment(
    reservationId: $reservationId
    paymentMethod: $paymentMethod
    origin: $origin
    originPath: $originPath
  ) {
    token
    type
    result
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuthPaymentQueryVariables,
  APITypes.AuthPaymentQuery
>;
export const getReservationInfo = /* GraphQL */ `query GetReservationInfo($reservationId: ID!) {
  getReservationInfo(reservationId: $reservationId) {
    id
    shortId
    language
    startTime
    returnTime
    paymentPending
    paymentStatus
    signStatus
    enableSign
    disablePricesInPrintouts
    mustSign
    paymentData {
      paymentTodo
      depositPending
      deposit
      paymentTotal
      __typename
    }
    askSsn
    ssn
    group
    companyTermsUrl
    companyId
    name
    address
    postalCode
    city
    phone
    billingRef
    email
    externalNotes
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    driverSSN
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    allowCancellation
    billPayment
    isCompany
    companyName
    companyBusinessId
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    authStatus
    vehicles {
      group
      organizationId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      orderNumber
      price
      tax
      category {
        name
        typeId
        __typename
      }
      id
      __typename
    }
    services {
      quantity
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      price
      tax
      key
      __typename
    }
    expiryTime
    voucherData {
      amount
      code
      __typename
    }
    enableGTM
    errorMsg
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationInfoQueryVariables,
  APITypes.GetReservationInfoQuery
>;
export const getReviewState = /* GraphQL */ `query GetReviewState($reservationId: ID!) {
  getReviewState(reservationId: $reservationId) {
    reservationInfo {
      id
      shortId
      language
      startTime
      returnTime
      paymentPending
      paymentStatus
      signStatus
      enableSign
      disablePricesInPrintouts
      mustSign
      paymentData {
        paymentTodo
        depositPending
        deposit
        paymentTotal
        __typename
      }
      askSsn
      ssn
      group
      companyTermsUrl
      companyId
      name
      address
      postalCode
      city
      phone
      billingRef
      email
      externalNotes
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      driverSSN
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      allowCancellation
      billPayment
      isCompany
      companyName
      companyBusinessId
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      authStatus
      vehicles {
        group
        organizationId
        companyId
        name
        registrationPlate
        image
        powerType
        deductible
        includedDistance
        orderNumber
        price
        tax
        id
        __typename
      }
      services {
        quantity
        description
        price
        tax
        key
        __typename
      }
      expiryTime
      voucherData {
        amount
        code
        __typename
      }
      enableGTM
      errorMsg
      __typename
    }
    state
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReviewStateQueryVariables,
  APITypes.GetReviewStateQuery
>;
export const companyByOrganization = /* GraphQL */ `query CompanyByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companyByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      currency
      locale
      weeklyBusinessHours {
        openAt
        closeAt
        __typename
      }
      signConfirmationEmail
      nameInEmail
      senderNameSMS
      confirmationMsg
      confirmationMsgT {
        fi
        en
        sv
        __typename
      }
      feedbackMsg
      feedbackMsgT {
        fi
        en
        sv
        __typename
      }
      statusLinkMsg
      statusLinkMsgT {
        fi
        en
        sv
        __typename
      }
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
        __typename
      }
      reservationLinkExpirationHours
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableRegistrationPlate
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      enableNetPromoterScore
      enableNPS
      reservationCancellation {
        cancellationEnabled
        daysBeforeStart
        __typename
      }
      creditCheck {
        enabled
        keyLookup
        userId
        passwordId
        __typename
      }
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
        __typename
      }
      innovoice {
        enabled
        companyId
        uRentCarRentalId
        __typename
      }
      locationType
      offHourAvailability
      offHourAvailabilityFee
      deliveryFee
      deliveryBufferHours
      notAllowedCategoryIds
      importIdentifier
      features {
        enableNPS
        enableVismaPay
        enableReservationDialogV2
        enableTilakone
        enableGTM
        enableSMSConfirmation
        enableMustSignBypass
        __typename
      }
      contractSettings {
        name
        enabled
        input
        __typename
      }
      contractSettingsJSON
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      disableReservationCategoryIds
      locationColor
      createdAt
      updatedAt
      merchantDetails {
        api
        merchant_id
        cp
        __typename
      }
      businessHoursExceptions {
        nextToken
        __typename
      }
      vehicles {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      categoriesByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyByOrganizationQueryVariables,
  APITypes.CompanyByOrganizationQuery
>;
export const companyByGroup = /* GraphQL */ `query CompanyByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companyByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      currency
      locale
      weeklyBusinessHours {
        openAt
        closeAt
        __typename
      }
      signConfirmationEmail
      nameInEmail
      senderNameSMS
      confirmationMsg
      confirmationMsgT {
        fi
        en
        sv
        __typename
      }
      feedbackMsg
      feedbackMsgT {
        fi
        en
        sv
        __typename
      }
      statusLinkMsg
      statusLinkMsgT {
        fi
        en
        sv
        __typename
      }
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
        __typename
      }
      reservationLinkExpirationHours
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableRegistrationPlate
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      enableNetPromoterScore
      enableNPS
      reservationCancellation {
        cancellationEnabled
        daysBeforeStart
        __typename
      }
      creditCheck {
        enabled
        keyLookup
        userId
        passwordId
        __typename
      }
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
        __typename
      }
      innovoice {
        enabled
        companyId
        uRentCarRentalId
        __typename
      }
      locationType
      offHourAvailability
      offHourAvailabilityFee
      deliveryFee
      deliveryBufferHours
      notAllowedCategoryIds
      importIdentifier
      features {
        enableNPS
        enableVismaPay
        enableReservationDialogV2
        enableTilakone
        enableGTM
        enableSMSConfirmation
        enableMustSignBypass
        __typename
      }
      contractSettings {
        name
        enabled
        input
        __typename
      }
      contractSettingsJSON
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      disableReservationCategoryIds
      locationColor
      createdAt
      updatedAt
      merchantDetails {
        api
        merchant_id
        cp
        __typename
      }
      businessHoursExceptions {
        nextToken
        __typename
      }
      vehicles {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      categoriesByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyByGroupQueryVariables,
  APITypes.CompanyByGroupQuery
>;
export const companyByBusiness = /* GraphQL */ `query CompanyByBusiness(
  $orgBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  companyByBusiness(
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      currency
      locale
      weeklyBusinessHours {
        openAt
        closeAt
        __typename
      }
      signConfirmationEmail
      nameInEmail
      senderNameSMS
      confirmationMsg
      confirmationMsgT {
        fi
        en
        sv
        __typename
      }
      feedbackMsg
      feedbackMsgT {
        fi
        en
        sv
        __typename
      }
      statusLinkMsg
      statusLinkMsgT {
        fi
        en
        sv
        __typename
      }
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
        __typename
      }
      reservationLinkExpirationHours
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableRegistrationPlate
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      enableNetPromoterScore
      enableNPS
      reservationCancellation {
        cancellationEnabled
        daysBeforeStart
        __typename
      }
      creditCheck {
        enabled
        keyLookup
        userId
        passwordId
        __typename
      }
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
        __typename
      }
      innovoice {
        enabled
        companyId
        uRentCarRentalId
        __typename
      }
      locationType
      offHourAvailability
      offHourAvailabilityFee
      deliveryFee
      deliveryBufferHours
      notAllowedCategoryIds
      importIdentifier
      features {
        enableNPS
        enableVismaPay
        enableReservationDialogV2
        enableTilakone
        enableGTM
        enableSMSConfirmation
        enableMustSignBypass
        __typename
      }
      contractSettings {
        name
        enabled
        input
        __typename
      }
      contractSettingsJSON
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      disableReservationCategoryIds
      locationColor
      createdAt
      updatedAt
      merchantDetails {
        api
        merchant_id
        cp
        __typename
      }
      businessHoursExceptions {
        nextToken
        __typename
      }
      vehicles {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      categoriesByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CompanyByBusinessQueryVariables,
  APITypes.CompanyByBusinessQuery
>;
export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    organizationId
    orgBusinessId
    group
    name
    displayName
    email
    address
    postalCode
    city
    orderNumber
    orderNumberDealer
    phone
    businessId
    termsUrl
    timezone
    currency
    locale
    weeklyBusinessHours {
      openAt
      closeAt
      __typename
    }
    signConfirmationEmail
    nameInEmail
    senderNameSMS
    confirmationMsg
    confirmationMsgT {
      fi
      en
      sv
      __typename
    }
    feedbackMsg
    feedbackMsgT {
      fi
      en
      sv
      __typename
    }
    statusLinkMsg
    statusLinkMsgT {
      fi
      en
      sv
      __typename
    }
    livionDeviceId
    livionDeviceType
    enableReservationDeposit
    reservationDeposit {
      amount
      reminderBefore
      paymentBefore
      __typename
    }
    reservationLinkExpirationHours
    disableOffHourReturn
    disableMultiday
    disableSameDay
    disableStartDaysFromNow
    enableCart
    enableMonthlyView
    enableRegistrationPlate
    enableVehicleStartReturnTimes
    enableMustSign
    enablePaymentOnlineOnly
    enableGTM
    enableNetPromoterScore
    enableNPS
    reservationCancellation {
      cancellationEnabled
      daysBeforeStart
      __typename
    }
    creditCheck {
      enabled
      keyLookup
      userId
      passwordId
      __typename
    }
    limitReservationOncePerDay
    hidden
    receiptInfo {
      enabled
      name
      address
      city
      postalCode
      __typename
    }
    innovoice {
      enabled
      companyId
      uRentCarRentalId
      __typename
    }
    locationType
    offHourAvailability
    offHourAvailabilityFee
    deliveryFee
    deliveryBufferHours
    notAllowedCategoryIds
    importIdentifier
    features {
      enableNPS
      enableVismaPay
      enableReservationDialogV2
      enableTilakone
      enableGTM
      enableSMSConfirmation
      enableMustSignBypass
      __typename
    }
    contractSettings {
      name
      enabled
      input
      __typename
    }
    contractSettingsJSON
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    disableReservationCategoryIds
    locationColor
    createdAt
    updatedAt
    merchantDetails {
      api
      merchant_id
      cp
      __typename
    }
    businessHoursExceptions {
      items {
        organizationId
        orgBusinessId
        group
        date
        name
        companyId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    vehicles {
      items {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanys = /* GraphQL */ `query ListCompanys(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      orgBusinessId
      group
      name
      displayName
      email
      address
      postalCode
      city
      orderNumber
      orderNumberDealer
      phone
      businessId
      termsUrl
      timezone
      currency
      locale
      weeklyBusinessHours {
        openAt
        closeAt
        __typename
      }
      signConfirmationEmail
      nameInEmail
      senderNameSMS
      confirmationMsg
      confirmationMsgT {
        fi
        en
        sv
        __typename
      }
      feedbackMsg
      feedbackMsgT {
        fi
        en
        sv
        __typename
      }
      statusLinkMsg
      statusLinkMsgT {
        fi
        en
        sv
        __typename
      }
      livionDeviceId
      livionDeviceType
      enableReservationDeposit
      reservationDeposit {
        amount
        reminderBefore
        paymentBefore
        __typename
      }
      reservationLinkExpirationHours
      disableOffHourReturn
      disableMultiday
      disableSameDay
      disableStartDaysFromNow
      enableCart
      enableMonthlyView
      enableRegistrationPlate
      enableVehicleStartReturnTimes
      enableMustSign
      enablePaymentOnlineOnly
      enableGTM
      enableNetPromoterScore
      enableNPS
      reservationCancellation {
        cancellationEnabled
        daysBeforeStart
        __typename
      }
      creditCheck {
        enabled
        keyLookup
        userId
        passwordId
        __typename
      }
      limitReservationOncePerDay
      hidden
      receiptInfo {
        enabled
        name
        address
        city
        postalCode
        __typename
      }
      innovoice {
        enabled
        companyId
        uRentCarRentalId
        __typename
      }
      locationType
      offHourAvailability
      offHourAvailabilityFee
      deliveryFee
      deliveryBufferHours
      notAllowedCategoryIds
      importIdentifier
      features {
        enableNPS
        enableVismaPay
        enableReservationDialogV2
        enableTilakone
        enableGTM
        enableSMSConfirmation
        enableMustSignBypass
        __typename
      }
      contractSettings {
        name
        enabled
        input
        __typename
      }
      contractSettingsJSON
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      disableReservationCategoryIds
      locationColor
      createdAt
      updatedAt
      merchantDetails {
        api
        merchant_id
        cp
        __typename
      }
      businessHoursExceptions {
        nextToken
        __typename
      }
      vehicles {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      categoriesByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompanysQueryVariables,
  APITypes.ListCompanysQuery
>;
export const getBusinessHoursException = /* GraphQL */ `query GetBusinessHoursException($companyId: ID!, $date: AWSDate!) {
  getBusinessHoursException(companyId: $companyId, date: $date) {
    organizationId
    orgBusinessId
    group
    date
    name
    businessHours {
      openAt
      closeAt
      __typename
    }
    companyId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessHoursExceptionQueryVariables,
  APITypes.GetBusinessHoursExceptionQuery
>;
export const listBusinessHoursExceptions = /* GraphQL */ `query ListBusinessHoursExceptions(
  $companyId: ID
  $date: ModelStringKeyConditionInput
  $filter: ModelBusinessHoursExceptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listBusinessHoursExceptions(
    companyId: $companyId
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organizationId
      orgBusinessId
      group
      date
      name
      businessHours {
        openAt
        closeAt
        __typename
      }
      companyId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinessHoursExceptionsQueryVariables,
  APITypes.ListBusinessHoursExceptionsQuery
>;
export const getVehicle = /* GraphQL */ `query GetVehicle($id: ID!) {
  getVehicle(id: $id) {
    id
    group
    organizationId
    orgBusinessId
    companyId
    name
    nameT {
      fi
      en
      sv
      __typename
    }
    registrationPlate
    damages
    damageRecords {
      normalizedX
      normalizedY
      label
      description
      createdAt
      __typename
    }
    additionalFields {
      key
      value
      valueT {
        fi
        en
        sv
        __typename
      }
      __typename
    }
    image
    powerType
    deductible
    includedDistance
    mileage
    orderNumber
    vehicleCategoryId
    useCategoryDefaults
    useStartReturnTimes
    customStartReturns {
      priority
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      __typename
    }
    startReturnInterval {
      start
      end
      __typename
    }
    startReturnTimes {
      start
      return
      __typename
    }
    hideExport
    extraInfo
    extraInfoT
    extraToConfirmation
    extraToConfirmationT
    livionKeyId
    phoneNumber
    importData
    disableOnline
    activeRange {
      start
      end
      __typename
    }
    createdAt
    updatedAt
    category {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    reservations {
      items {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        customerNotes
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVehicleQueryVariables,
  APITypes.GetVehicleQuery
>;
export const listVehicles = /* GraphQL */ `query ListVehicles(
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVehiclesQueryVariables,
  APITypes.ListVehiclesQuery
>;
export const vehicleByCompany = /* GraphQL */ `query VehicleByCompany(
  $companyId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  vehicleByCompany(
    companyId: $companyId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VehicleByCompanyQueryVariables,
  APITypes.VehicleByCompanyQuery
>;
export const vehicleByGroup = /* GraphQL */ `query VehicleByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  vehicleByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VehicleByGroupQueryVariables,
  APITypes.VehicleByGroupQuery
>;
export const vehicleByOrganizaion = /* GraphQL */ `query VehicleByOrganizaion(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  vehicleByOrganizaion(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VehicleByOrganizaionQueryVariables,
  APITypes.VehicleByOrganizaionQuery
>;
export const vehicleByOrgBusinessId = /* GraphQL */ `query VehicleByOrgBusinessId(
  $orgBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  vehicleByOrgBusinessId(
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VehicleByOrgBusinessIdQueryVariables,
  APITypes.VehicleByOrgBusinessIdQuery
>;
export const vehicleByCategory = /* GraphQL */ `query VehicleByCategory(
  $vehicleCategoryId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelVehicleFilterInput
  $limit: Int
  $nextToken: String
) {
  vehicleByCategory(
    vehicleCategoryId: $vehicleCategoryId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VehicleByCategoryQueryVariables,
  APITypes.VehicleByCategoryQuery
>;
export const searchVehicles = /* GraphQL */ `query SearchVehicles(
  $filter: SearchableVehicleFilterInput
  $sort: SearchableVehicleSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchVehicles(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchVehiclesQueryVariables,
  APITypes.SearchVehiclesQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    group
    organizationId
    name
    image
    orderNumber
    backgroundColor
    minuteBuffer
    reservationBlockFromNow
    typeId
    _removed
    oldTujausValue
    exampleModelName
    exampleModelImage
    createdAt
    updatedAt
    additionalServices {
      items {
        id
        group
        organizationId
        orgBusinessId
        key
        vehicleId
        categoryId
        pricingId
        deductibleValue
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    externalBusinessPricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategorys = /* GraphQL */ `query ListCategorys(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategorysQueryVariables,
  APITypes.ListCategorysQuery
>;
export const categoryByOrganization = /* GraphQL */ `query CategoryByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByOrganizationQueryVariables,
  APITypes.CategoryByOrganizationQuery
>;
export const categoryByGroup = /* GraphQL */ `query CategoryByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      name
      image
      orderNumber
      backgroundColor
      minuteBuffer
      reservationBlockFromNow
      typeId
      _removed
      oldTujausValue
      exampleModelName
      exampleModelImage
      createdAt
      updatedAt
      additionalServices {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      externalBusinessPricings {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByGroupQueryVariables,
  APITypes.CategoryByGroupQuery
>;
export const getAdditionalServicePricing = /* GraphQL */ `query GetAdditionalServicePricing($id: ID!) {
  getAdditionalServicePricing(id: $id) {
    id
    group
    organizationId
    orgBusinessId
    key
    vehicleId
    categoryId
    pricingId
    deductibleValue
    createdAt
    updatedAt
    pricing {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdditionalServicePricingQueryVariables,
  APITypes.GetAdditionalServicePricingQuery
>;
export const listAdditionalServicePricings = /* GraphQL */ `query ListAdditionalServicePricings(
  $filter: ModelAdditionalServicePricingFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdditionalServicePricings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      key
      vehicleId
      categoryId
      pricingId
      deductibleValue
      createdAt
      updatedAt
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdditionalServicePricingsQueryVariables,
  APITypes.ListAdditionalServicePricingsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    organizationId
    orgBusinessId
    orgBusinessIds
    externalBusinessId
    role
    permissions {
      userManagement
      __typename
    }
    email
    name
    regionalCompanyName
    createdAt
    updatedAt
    categoriesByOrg {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    organization {
      id
      name
      billingCustomers {
        name
        __typename
      }
      features {
        enableNPS
        enableBusinessWideReservation
        __typename
      }
      currency
      locale
      createdAt
      updatedAt
      businesses {
        nextToken
        __typename
      }
      __typename
    }
    externalBusiness {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    externalBusinessByOrg {
      items {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    business {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    businessByOrg {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      role
      permissions {
        userManagement
        __typename
      }
      email
      name
      regionalCompanyName
      createdAt
      updatedAt
      categoriesByOrg {
        nextToken
        __typename
      }
      organization {
        id
        name
        currency
        locale
        createdAt
        updatedAt
        __typename
      }
      externalBusiness {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      externalBusinessByOrg {
        nextToken
        __typename
      }
      business {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      businessByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const userByOrganization = /* GraphQL */ `query UserByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      role
      permissions {
        userManagement
        __typename
      }
      email
      name
      regionalCompanyName
      createdAt
      updatedAt
      categoriesByOrg {
        nextToken
        __typename
      }
      organization {
        id
        name
        currency
        locale
        createdAt
        updatedAt
        __typename
      }
      externalBusiness {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      externalBusinessByOrg {
        nextToken
        __typename
      }
      business {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      businessByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByOrganizationQueryVariables,
  APITypes.UserByOrganizationQuery
>;
export const userByOrgBusinessId = /* GraphQL */ `query UserByOrgBusinessId(
  $orgBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByOrgBusinessId(
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      role
      permissions {
        userManagement
        __typename
      }
      email
      name
      regionalCompanyName
      createdAt
      updatedAt
      categoriesByOrg {
        nextToken
        __typename
      }
      organization {
        id
        name
        currency
        locale
        createdAt
        updatedAt
        __typename
      }
      externalBusiness {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      externalBusinessByOrg {
        nextToken
        __typename
      }
      business {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      businessByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByOrgBusinessIdQueryVariables,
  APITypes.UserByOrgBusinessIdQuery
>;
export const userByExternalBusiness = /* GraphQL */ `query UserByExternalBusiness(
  $externalBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  userByExternalBusiness(
    externalBusinessId: $externalBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      role
      permissions {
        userManagement
        __typename
      }
      email
      name
      regionalCompanyName
      createdAt
      updatedAt
      categoriesByOrg {
        nextToken
        __typename
      }
      organization {
        id
        name
        currency
        locale
        createdAt
        updatedAt
        __typename
      }
      externalBusiness {
        id
        organizationId
        type
        name
        insuranceCompensatableTimes
        useHansel
        hanselId
        availableOrgBusinessIds
        availableCompanyIds
        availableVehicleClasses
        eBillingRef
        eBillingOperator
        eBillingAddress
        createdAt
        updatedAt
        __typename
      }
      externalBusinessByOrg {
        nextToken
        __typename
      }
      business {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      businessByOrg {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByExternalBusinessQueryVariables,
  APITypes.UserByExternalBusinessQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    billingCustomers {
      name
      __typename
    }
    features {
      enableNPS
      enableBusinessWideReservation
      __typename
    }
    currency
    locale
    createdAt
    updatedAt
    businesses {
      items {
        id
        organizationId
        telegramChatId
        group
        name
        address
        city
        email
        phone
        importIdentifier
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      billingCustomers {
        name
        __typename
      }
      features {
        enableNPS
        enableBusinessWideReservation
        __typename
      }
      currency
      locale
      createdAt
      updatedAt
      businesses {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getExternalBusiness = /* GraphQL */ `query GetExternalBusiness($id: ID!) {
  getExternalBusiness(id: $id) {
    id
    organizationId
    type
    name
    insuranceProducts {
      name
      coveragePercentage
      compensatableDays
      billingPermitCode
      __typename
    }
    insuranceRegionalCompanies {
      name
      companyName
      businessId
      address
      postalCode
      postalArea
      email
      innovoiceId
      __typename
    }
    insuranceCompensatableTimes
    services {
      description
      price
      category
      deductibleValue
      __typename
    }
    useHansel
    hanselId
    availableOrgBusinessIds
    availableCompanyIds
    availableVehicleClasses
    eBillingRef
    eBillingOperator
    eBillingAddress
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        currency
        locale
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        contractSettingsJSON
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    pricings {
      items {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExternalBusinessQueryVariables,
  APITypes.GetExternalBusinessQuery
>;
export const listExternalBusinesss = /* GraphQL */ `query ListExternalBusinesss(
  $filter: ModelExternalBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  listExternalBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListExternalBusinesssQueryVariables,
  APITypes.ListExternalBusinesssQuery
>;
export const externalBusinessByOrganization = /* GraphQL */ `query ExternalBusinessByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelExternalBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  externalBusinessByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      type
      name
      insuranceProducts {
        name
        coveragePercentage
        compensatableDays
        billingPermitCode
        __typename
      }
      insuranceRegionalCompanies {
        name
        companyName
        businessId
        address
        postalCode
        postalArea
        email
        innovoiceId
        __typename
      }
      insuranceCompensatableTimes
      services {
        description
        price
        category
        deductibleValue
        __typename
      }
      useHansel
      hanselId
      availableOrgBusinessIds
      availableCompanyIds
      availableVehicleClasses
      eBillingRef
      eBillingOperator
      eBillingAddress
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      pricings {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExternalBusinessByOrganizationQueryVariables,
  APITypes.ExternalBusinessByOrganizationQuery
>;
export const getBusiness = /* GraphQL */ `query GetBusiness($id: ID!) {
  getBusiness(id: $id) {
    id
    organizationId
    telegramChatId
    telegramConnections {
      chatId
      name
      __typename
    }
    group
    name
    address
    city
    email
    phone
    insuranceCoverageZone {
      start
      end
      __typename
    }
    insuranceCoverageZones {
      start
      end
      __typename
    }
    importIdentifier
    categoryDynamicPricings {
      categoryId
      pricingId
      percentage
      __typename
    }
    startLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    pickupLocations {
      id
      name
      locationColor
      companyId
      __typename
    }
    createdAt
    updatedAt
    companies {
      items {
        id
        organizationId
        orgBusinessId
        group
        name
        displayName
        email
        address
        postalCode
        city
        orderNumber
        orderNumberDealer
        phone
        businessId
        termsUrl
        timezone
        currency
        locale
        signConfirmationEmail
        nameInEmail
        senderNameSMS
        confirmationMsg
        feedbackMsg
        statusLinkMsg
        livionDeviceId
        livionDeviceType
        enableReservationDeposit
        reservationLinkExpirationHours
        disableOffHourReturn
        disableMultiday
        disableSameDay
        disableStartDaysFromNow
        enableCart
        enableMonthlyView
        enableRegistrationPlate
        enableVehicleStartReturnTimes
        enableMustSign
        enablePaymentOnlineOnly
        enableGTM
        enableNetPromoterScore
        enableNPS
        limitReservationOncePerDay
        hidden
        locationType
        offHourAvailability
        offHourAvailabilityFee
        deliveryFee
        deliveryBufferHours
        notAllowedCategoryIds
        importIdentifier
        contractSettingsJSON
        disableReservationCategoryIds
        locationColor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    categories {
      items {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessQueryVariables,
  APITypes.GetBusinessQuery
>;
export const listBusinesss = /* GraphQL */ `query ListBusinesss(
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBusinesssQueryVariables,
  APITypes.ListBusinesssQuery
>;
export const businessByOrganization = /* GraphQL */ `query BusinessByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelBusinessFilterInput
  $limit: Int
  $nextToken: String
) {
  businessByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      telegramChatId
      telegramConnections {
        chatId
        name
        __typename
      }
      group
      name
      address
      city
      email
      phone
      insuranceCoverageZone {
        start
        end
        __typename
      }
      insuranceCoverageZones {
        start
        end
        __typename
      }
      importIdentifier
      categoryDynamicPricings {
        categoryId
        pricingId
        percentage
        __typename
      }
      startLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      pickupLocations {
        id
        name
        locationColor
        companyId
        __typename
      }
      createdAt
      updatedAt
      companies {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.BusinessByOrganizationQueryVariables,
  APITypes.BusinessByOrganizationQuery
>;
export const getArchiveReservation = /* GraphQL */ `query GetArchiveReservation($id: ID!) {
  getArchiveReservation(id: $id) {
    id
    organizationId
    orgBusinessId
    reservationJSON
    archiveMessage
    archivedAt
    archivedByUserId
    startTime
    returnTime
    reservationCreatedAt
    customerBrokerId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetArchiveReservationQueryVariables,
  APITypes.GetArchiveReservationQuery
>;
export const listArchiveReservations = /* GraphQL */ `query ListArchiveReservations(
  $filter: ModelArchiveReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listArchiveReservations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      reservationJSON
      archiveMessage
      archivedAt
      archivedByUserId
      startTime
      returnTime
      reservationCreatedAt
      customerBrokerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListArchiveReservationsQueryVariables,
  APITypes.ListArchiveReservationsQuery
>;
export const searchArchiveReservations = /* GraphQL */ `query SearchArchiveReservations(
  $filter: SearchableArchiveReservationFilterInput
  $sort: SearchableArchiveReservationSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchArchiveReservations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      organizationId
      orgBusinessId
      reservationJSON
      archiveMessage
      archivedAt
      archivedByUserId
      startTime
      returnTime
      reservationCreatedAt
      customerBrokerId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchArchiveReservationsQueryVariables,
  APITypes.SearchArchiveReservationsQuery
>;
export const getReservation = /* GraphQL */ `query GetReservation($id: ID!) {
  getReservation(id: $id) {
    id
    shortId
    language
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    companyId
    returnCompanyId
    startLocation
    returnLocation
    startLocationID
    returnLocationID
    reviewId
    customerBrokerId
    customerCompanyId
    customerPersonalId
    reservationVehicleId
    reservationVehicles {
      id
      price
      tax
      name
      registrationPlate
      deductible
      includedDistance
      powerType
      mileage
      startMileage
      endMileage
      extraInfo
      damages
      hideExport
      createdAt
      addedAt
      categoryName
      __typename
    }
    wildCardVehicles {
      name
      price
      tax
      categoryId
      quantity
      deductible
      __typename
    }
    createdAt
    updatedAt
    startTime
    returnTime
    endTime
    name
    address
    postalCode
    city
    phone
    email
    billingRef
    customerNotes
    ssn
    rentalNotes
    internalNotes
    externalNotes
    outOfCommission
    vehiclePrice
    additionalServices {
      key
      price
      details
      quantity
      type
      deductibleValue
      groupFields
      tax
      enableNotification
      description
      category
      __typename
    }
    driverSSN
    differentDriver
    driverName
    driverAddress
    driverPostalCode
    driverCity
    driverPhone
    driverEmail
    additionalDriver
    additionalDriverName
    additionalDriverPhone
    additionalDriverSSN
    billPayment
    isCompany
    carStatus
    rentalStatus
    innovoiceStatus
    companyName
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyBillingAddress
    companyEBillingOperator
    companyEBillingAddress
    paymentToken
    npsStatus
    paymentStatus
    paymentData {
      paymentTotal
      paymentTodo
      depositPending
      deposit
      __typename
    }
    signStatus
    signDocumentId
    signerName
    reviewStatus
    authStatus
    authTime
    completeBefore
    mustSign
    livionData {
      id
      pincode
      keyId
      name
      registrationPlate
      __typename
    }
    tilakoneData {
      access_id
      begin_timestamp
      end_timestamp
      pincode
      __typename
    }
    voucherData {
      name
      code
      amount
      __typename
    }
    customerCarRegistrationPlate
    insuranceData {
      customerContact
      urgent
      customerCarModel
      customerCarMaker
      product
      coveragePercentage
      compensatableDays
      billingPermitCode
      coverageDuration
      providerCompany
      providerBusinessId
      claimAdjusterName
      claimAdjusterEmail
      receipt
      replacementVehicleClass
      claimId
      vatDeductible
      deliveryCosts
      returnCosts
      __typename
    }
    externalData {
      orderId
      orderReference
      serviceAgreementName
      operatorName
      orderInfo
      billingPayer
      billingDescription
      customerVehicle {
        brand
        model
        registrationPlate
        __typename
      }
      incidentLocation {
        description
        address
        city
        zipCode
        __typename
      }
      repairShop {
        description
        address
        city
        zipCode
        __typename
      }
      __typename
    }
    enableRegistrationPlate
    disablePricesInPrintouts
    hanselData {
      contractNro
      customerInvoiceId
      customerInvoiceDate
      hanselAgent
      hanselClass
      customerId
      subContractorNumber
      subContractorName
      suplierProductId
      suplierProductName
      quantity
      price
      totalPrice
      priceNoVAT
      additionalInfo1
      additionalInfo2
      additionalInfo3
      desc
      __typename
    }
    invoiceIds
    consolidatedInvoiceIds
    channel
    originURL
    type
    status
    reservationStatusInfo {
      deliveryTimestamp
      deliveryAddress
      deliveryZipcode
      deliveryCity
      deliveryCountry
      deliveryDescription
      returnTimestamp
      returnAddress
      returnZipcode
      returnCity
      returnCountry
      returnDescription
      cancelReason
      __typename
    }
    initialCategory {
      id
      name
      hanselClass
      __typename
    }
    initialClassInfo {
      categoryId
      categoryName
      hanselClass
      __typename
    }
    orgInvoiceCustomer {
      name
      businessId
      __typename
    }
    pricingCategoryId
    customImportDate
    vehicle {
      id
      group
      organizationId
      orgBusinessId
      companyId
      name
      nameT {
        fi
        en
        sv
        __typename
      }
      registrationPlate
      damages
      damageRecords {
        normalizedX
        normalizedY
        label
        description
        createdAt
        __typename
      }
      additionalFields {
        key
        value
        __typename
      }
      image
      powerType
      deductible
      includedDistance
      mileage
      orderNumber
      vehicleCategoryId
      useCategoryDefaults
      useStartReturnTimes
      customStartReturns {
        priority
        __typename
      }
      startReturnInterval {
        start
        end
        __typename
      }
      startReturnTimes {
        start
        return
        __typename
      }
      hideExport
      extraInfo
      extraInfoT
      extraToConfirmation
      extraToConfirmationT
      livionKeyId
      phoneNumber
      importData
      disableOnline
      activeRange {
        start
        end
        __typename
      }
      createdAt
      updatedAt
      category {
        id
        group
        organizationId
        name
        image
        orderNumber
        backgroundColor
        minuteBuffer
        reservationBlockFromNow
        typeId
        _removed
        oldTujausValue
        exampleModelName
        exampleModelImage
        createdAt
        updatedAt
        __typename
      }
      additionalServices {
        nextToken
        __typename
      }
      reservations {
        nextToken
        __typename
      }
      pricing {
        id
        name
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        externalBusinessCategoryAssociation
        businessCategoryAssociation
        useNextDayPrice
        useLastDayPrice
        tax
        _removed
        deductible
        reducedDeductible
        reducedDeductiblePrice
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    invoices {
      items {
        id
        createdAt
        innovoiceId
        reservationId
        reservationIds
        companyId
        innovoiceCompanyId
        innovoiceuRentCarRentalId
        innovoiceOrderId
        group
        organizationId
        orgBusinessId
        type
        creditNoteInvoiceId
        payment_terms
        vat_included
        delivery_by
        invoice_type
        delivery_date
        seller_reference
        buyer_reference
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    paymentEvents {
      items {
        id
        channel
        paymentMethod
        paymentTotal
        isDeleted
        deletedBy
        deletedAt
        createdByUserID
        createdByUserName
        reservationId
        organizationId
        orgBusinessId
        group
        msg
        state
        returnCode
        incidentId
        parameters
        apiPath
        paymentStatus
        orderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationQueryVariables,
  APITypes.GetReservationQuery
>;
export const listReservations = /* GraphQL */ `query ListReservations(
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsQueryVariables,
  APITypes.ListReservationsQuery
>;
export const listReservationsByGroup = /* GraphQL */ `query ListReservationsByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByGroupQueryVariables,
  APITypes.ListReservationsByGroupQuery
>;
export const listReservationsByOrgBusinessIdByEndTime = /* GraphQL */ `query ListReservationsByOrgBusinessIdByEndTime(
  $orgBusinessId: ID
  $endTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByOrgBusinessIdByEndTime(
    orgBusinessId: $orgBusinessId
    endTime: $endTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByOrgBusinessIdByEndTimeQueryVariables,
  APITypes.ListReservationsByOrgBusinessIdByEndTimeQuery
>;
export const listReservationsByGroupByEndTime = /* GraphQL */ `query ListReservationsByGroupByEndTime(
  $group: String
  $endTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByGroupByEndTime(
    group: $group
    endTime: $endTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByGroupByEndTimeQueryVariables,
  APITypes.ListReservationsByGroupByEndTimeQuery
>;
export const listReservationsByPaymentStatus = /* GraphQL */ `query ListReservationsByPaymentStatus(
  $paymentStatus: PaymentStatus
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByPaymentStatus(
    paymentStatus: $paymentStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByPaymentStatusQueryVariables,
  APITypes.ListReservationsByPaymentStatusQuery
>;
export const listReservationsByPaymentStatusByOrgBusinessId = /* GraphQL */ `query ListReservationsByPaymentStatusByOrgBusinessId(
  $paymentStatus: PaymentStatus
  $orgBusinessId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByPaymentStatusByOrgBusinessId(
    paymentStatus: $paymentStatus
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByPaymentStatusByOrgBusinessIdQueryVariables,
  APITypes.ListReservationsByPaymentStatusByOrgBusinessIdQuery
>;
export const listReservationsBySignStatus = /* GraphQL */ `query ListReservationsBySignStatus(
  $signStatus: SignStatus
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsBySignStatus(
    signStatus: $signStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsBySignStatusQueryVariables,
  APITypes.ListReservationsBySignStatusQuery
>;
export const listReservationsByAuthStatus = /* GraphQL */ `query ListReservationsByAuthStatus(
  $authStatus: AuthStatus
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByAuthStatus(
    authStatus: $authStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByAuthStatusQueryVariables,
  APITypes.ListReservationsByAuthStatusQuery
>;
export const listReservationsByReviewStatus = /* GraphQL */ `query ListReservationsByReviewStatus(
  $reviewStatus: ReviewStatus
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByReviewStatus(
    reviewStatus: $reviewStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByReviewStatusQueryVariables,
  APITypes.ListReservationsByReviewStatusQuery
>;
export const listReservationsByNpsStatus = /* GraphQL */ `query ListReservationsByNpsStatus(
  $npsStatus: NpsStatus
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByNpsStatus(
    npsStatus: $npsStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByNpsStatusQueryVariables,
  APITypes.ListReservationsByNpsStatusQuery
>;
export const listReservationsByStatus = /* GraphQL */ `query ListReservationsByStatus(
  $status: String
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByStatus(
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByStatusQueryVariables,
  APITypes.ListReservationsByStatusQuery
>;
export const listReservationsByStatusByOrgBusinessId = /* GraphQL */ `query ListReservationsByStatusByOrgBusinessId(
  $status: String
  $orgBusinessId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByStatusByOrgBusinessId(
    status: $status
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByStatusByOrgBusinessIdQueryVariables,
  APITypes.ListReservationsByStatusByOrgBusinessIdQuery
>;
export const listReservationsByStatusByOrgExternalBusinessId = /* GraphQL */ `query ListReservationsByStatusByOrgExternalBusinessId(
  $status: String
  $orgExternalBusinessId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listReservationsByStatusByOrgExternalBusinessId(
    status: $status
    orgExternalBusinessId: $orgExternalBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationsByStatusByOrgExternalBusinessIdQueryVariables,
  APITypes.ListReservationsByStatusByOrgExternalBusinessIdQuery
>;
export const searchReservations = /* GraphQL */ `query SearchReservations(
  $filter: SearchableReservationFilterInput
  $sort: SearchableReservationSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchReservations(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchReservationsQueryVariables,
  APITypes.SearchReservationsQuery
>;
export const getReservationCount = /* GraphQL */ `query GetReservationCount($tenant: String!, $date: AWSDate!) {
  getReservationCount(tenant: $tenant, date: $date) {
    tenant
    date
    count
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReservationCountQueryVariables,
  APITypes.GetReservationCountQuery
>;
export const listReservationCounts = /* GraphQL */ `query ListReservationCounts(
  $tenant: String
  $date: ModelStringKeyConditionInput
  $filter: ModelReservationCountFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listReservationCounts(
    tenant: $tenant
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      tenant
      date
      count
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReservationCountsQueryVariables,
  APITypes.ListReservationCountsQuery
>;
export const getInvoice = /* GraphQL */ `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    createdAt
    innovoiceId
    reservationId
    reservationIds
    companyId
    innovoiceCompanyId
    innovoiceuRentCarRentalId
    innovoiceOrderId
    group
    organizationId
    orgBusinessId
    type
    creditNoteInvoiceId
    payment_terms
    vat_included
    delivery_by
    invoice_type
    delivery_date
    seller_reference
    buyer_reference
    items {
      name
      description
      quantity
      unit
      unitprice
      discount
      vat
      type
      insuranceItemType
      reservationId
      __typename
    }
    customer {
      customer_type
      firstname
      lastname
      ssn
      address
      zipcode
      city
      email
      phone
      company_name
      contact_person
      e_invoice_operator
      e_invoice_address
      __typename
    }
    insurance {
      insurance_company_id
      vehicle_class
      vehicle_regno
      claim_no
      rental_start_date
      rental_end_date
      rental_days
      amount_cost_per_day
      amount_to_pay_customer
      amount_to_pay_insurance
      coveragePercentage
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvoiceQueryVariables,
  APITypes.GetInvoiceQuery
>;
export const listInvoices = /* GraphQL */ `query ListInvoices(
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      innovoiceId
      reservationId
      reservationIds
      companyId
      innovoiceCompanyId
      innovoiceuRentCarRentalId
      innovoiceOrderId
      group
      organizationId
      orgBusinessId
      type
      creditNoteInvoiceId
      payment_terms
      vat_included
      delivery_by
      invoice_type
      delivery_date
      seller_reference
      buyer_reference
      items {
        name
        description
        quantity
        unit
        unitprice
        discount
        vat
        type
        insuranceItemType
        reservationId
        __typename
      }
      customer {
        customer_type
        firstname
        lastname
        ssn
        address
        zipcode
        city
        email
        phone
        company_name
        contact_person
        e_invoice_operator
        e_invoice_address
        __typename
      }
      insurance {
        insurance_company_id
        vehicle_class
        vehicle_regno
        claim_no
        rental_start_date
        rental_end_date
        rental_days
        amount_cost_per_day
        amount_to_pay_customer
        amount_to_pay_insurance
        coveragePercentage
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvoicesQueryVariables,
  APITypes.ListInvoicesQuery
>;
export const invoiceByReservation = /* GraphQL */ `query InvoiceByReservation(
  $reservationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByReservation(
    reservationId: $reservationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      innovoiceId
      reservationId
      reservationIds
      companyId
      innovoiceCompanyId
      innovoiceuRentCarRentalId
      innovoiceOrderId
      group
      organizationId
      orgBusinessId
      type
      creditNoteInvoiceId
      payment_terms
      vat_included
      delivery_by
      invoice_type
      delivery_date
      seller_reference
      buyer_reference
      items {
        name
        description
        quantity
        unit
        unitprice
        discount
        vat
        type
        insuranceItemType
        reservationId
        __typename
      }
      customer {
        customer_type
        firstname
        lastname
        ssn
        address
        zipcode
        city
        email
        phone
        company_name
        contact_person
        e_invoice_operator
        e_invoice_address
        __typename
      }
      insurance {
        insurance_company_id
        vehicle_class
        vehicle_regno
        claim_no
        rental_start_date
        rental_end_date
        rental_days
        amount_cost_per_day
        amount_to_pay_customer
        amount_to_pay_insurance
        coveragePercentage
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByReservationQueryVariables,
  APITypes.InvoiceByReservationQuery
>;
export const invoiceByOrgBusinessId = /* GraphQL */ `query InvoiceByOrgBusinessId(
  $orgBusinessId: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelInvoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  invoiceByOrgBusinessId(
    orgBusinessId: $orgBusinessId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      innovoiceId
      reservationId
      reservationIds
      companyId
      innovoiceCompanyId
      innovoiceuRentCarRentalId
      innovoiceOrderId
      group
      organizationId
      orgBusinessId
      type
      creditNoteInvoiceId
      payment_terms
      vat_included
      delivery_by
      invoice_type
      delivery_date
      seller_reference
      buyer_reference
      items {
        name
        description
        quantity
        unit
        unitprice
        discount
        vat
        type
        insuranceItemType
        reservationId
        __typename
      }
      customer {
        customer_type
        firstname
        lastname
        ssn
        address
        zipcode
        city
        email
        phone
        company_name
        contact_person
        e_invoice_operator
        e_invoice_address
        __typename
      }
      insurance {
        insurance_company_id
        vehicle_class
        vehicle_regno
        claim_no
        rental_start_date
        rental_end_date
        rental_days
        amount_cost_per_day
        amount_to_pay_customer
        amount_to_pay_insurance
        coveragePercentage
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.InvoiceByOrgBusinessIdQueryVariables,
  APITypes.InvoiceByOrgBusinessIdQuery
>;
export const getSignEvent = /* GraphQL */ `query GetSignEvent($id: ID!) {
  getSignEvent(id: $id) {
    id
    reservationId
    company
    organizationId
    orgBusinessId
    group
    status
    documentId
    source
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSignEventQueryVariables,
  APITypes.GetSignEventQuery
>;
export const listSignEvents = /* GraphQL */ `query ListSignEvents(
  $filter: ModelSignEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listSignEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      reservationId
      company
      organizationId
      orgBusinessId
      group
      status
      documentId
      source
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSignEventsQueryVariables,
  APITypes.ListSignEventsQuery
>;
export const getPaymentEvent = /* GraphQL */ `query GetPaymentEvent($id: ID!) {
  getPaymentEvent(id: $id) {
    id
    channel
    paymentMethod
    paymentTotal
    isDeleted
    deletedBy
    deletedAt
    createdByUserID
    createdByUserName
    reservationId
    organizationId
    orgBusinessId
    group
    msg
    state
    returnCode
    incidentId
    parameters
    apiPath
    paymentStatus
    orderNumber
    createdAt
    updatedAt
    reservation {
      id
      shortId
      language
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      companyId
      returnCompanyId
      startLocation
      returnLocation
      startLocationID
      returnLocationID
      reviewId
      customerBrokerId
      customerCompanyId
      customerPersonalId
      reservationVehicleId
      reservationVehicles {
        id
        price
        tax
        name
        registrationPlate
        deductible
        includedDistance
        powerType
        mileage
        startMileage
        endMileage
        extraInfo
        damages
        hideExport
        createdAt
        addedAt
        categoryName
        __typename
      }
      wildCardVehicles {
        name
        price
        tax
        categoryId
        quantity
        deductible
        __typename
      }
      createdAt
      updatedAt
      startTime
      returnTime
      endTime
      name
      address
      postalCode
      city
      phone
      email
      billingRef
      customerNotes
      ssn
      rentalNotes
      internalNotes
      externalNotes
      outOfCommission
      vehiclePrice
      additionalServices {
        key
        price
        details
        quantity
        type
        deductibleValue
        groupFields
        tax
        enableNotification
        description
        category
        __typename
      }
      driverSSN
      differentDriver
      driverName
      driverAddress
      driverPostalCode
      driverCity
      driverPhone
      driverEmail
      additionalDriver
      additionalDriverName
      additionalDriverPhone
      additionalDriverSSN
      billPayment
      isCompany
      carStatus
      rentalStatus
      innovoiceStatus
      companyName
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyBillingAddress
      companyEBillingOperator
      companyEBillingAddress
      paymentToken
      npsStatus
      paymentStatus
      paymentData {
        paymentTotal
        paymentTodo
        depositPending
        deposit
        __typename
      }
      signStatus
      signDocumentId
      signerName
      reviewStatus
      authStatus
      authTime
      completeBefore
      mustSign
      livionData {
        id
        pincode
        keyId
        name
        registrationPlate
        __typename
      }
      tilakoneData {
        access_id
        begin_timestamp
        end_timestamp
        pincode
        __typename
      }
      voucherData {
        name
        code
        amount
        __typename
      }
      customerCarRegistrationPlate
      insuranceData {
        customerContact
        urgent
        customerCarModel
        customerCarMaker
        product
        coveragePercentage
        compensatableDays
        billingPermitCode
        coverageDuration
        providerCompany
        providerBusinessId
        claimAdjusterName
        claimAdjusterEmail
        receipt
        replacementVehicleClass
        claimId
        vatDeductible
        deliveryCosts
        returnCosts
        __typename
      }
      externalData {
        orderId
        orderReference
        serviceAgreementName
        operatorName
        orderInfo
        billingPayer
        billingDescription
        __typename
      }
      enableRegistrationPlate
      disablePricesInPrintouts
      hanselData {
        contractNro
        customerInvoiceId
        customerInvoiceDate
        hanselAgent
        hanselClass
        customerId
        subContractorNumber
        subContractorName
        suplierProductId
        suplierProductName
        quantity
        price
        totalPrice
        priceNoVAT
        additionalInfo1
        additionalInfo2
        additionalInfo3
        desc
        __typename
      }
      invoiceIds
      consolidatedInvoiceIds
      channel
      originURL
      type
      status
      reservationStatusInfo {
        deliveryTimestamp
        deliveryAddress
        deliveryZipcode
        deliveryCity
        deliveryCountry
        deliveryDescription
        returnTimestamp
        returnAddress
        returnZipcode
        returnCity
        returnCountry
        returnDescription
        cancelReason
        __typename
      }
      initialCategory {
        id
        name
        hanselClass
        __typename
      }
      initialClassInfo {
        categoryId
        categoryName
        hanselClass
        __typename
      }
      orgInvoiceCustomer {
        name
        businessId
        __typename
      }
      pricingCategoryId
      customImportDate
      vehicle {
        id
        group
        organizationId
        orgBusinessId
        companyId
        name
        registrationPlate
        damages
        image
        powerType
        deductible
        includedDistance
        mileage
        orderNumber
        vehicleCategoryId
        useCategoryDefaults
        useStartReturnTimes
        hideExport
        extraInfo
        extraInfoT
        extraToConfirmation
        extraToConfirmationT
        livionKeyId
        phoneNumber
        importData
        disableOnline
        createdAt
        updatedAt
        __typename
      }
      invoices {
        nextToken
        __typename
      }
      paymentEvents {
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentEventQueryVariables,
  APITypes.GetPaymentEventQuery
>;
export const listPaymentEvents = /* GraphQL */ `query ListPaymentEvents(
  $filter: ModelPaymentEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      channel
      paymentMethod
      paymentTotal
      isDeleted
      deletedBy
      deletedAt
      createdByUserID
      createdByUserName
      reservationId
      organizationId
      orgBusinessId
      group
      msg
      state
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        customerNotes
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentEventsQueryVariables,
  APITypes.ListPaymentEventsQuery
>;
export const paymentEventByReservation = /* GraphQL */ `query PaymentEventByReservation(
  $reservationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPaymentEventFilterInput
  $limit: Int
  $nextToken: String
) {
  paymentEventByReservation(
    reservationId: $reservationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      channel
      paymentMethod
      paymentTotal
      isDeleted
      deletedBy
      deletedAt
      createdByUserID
      createdByUserName
      reservationId
      organizationId
      orgBusinessId
      group
      msg
      state
      returnCode
      incidentId
      parameters
      apiPath
      paymentStatus
      orderNumber
      createdAt
      updatedAt
      reservation {
        id
        shortId
        language
        group
        organizationId
        orgBusinessId
        orgExternalBusinessId
        companyId
        returnCompanyId
        startLocation
        returnLocation
        startLocationID
        returnLocationID
        reviewId
        customerBrokerId
        customerCompanyId
        customerPersonalId
        reservationVehicleId
        createdAt
        updatedAt
        startTime
        returnTime
        endTime
        name
        address
        postalCode
        city
        phone
        email
        billingRef
        customerNotes
        ssn
        rentalNotes
        internalNotes
        externalNotes
        outOfCommission
        vehiclePrice
        driverSSN
        differentDriver
        driverName
        driverAddress
        driverPostalCode
        driverCity
        driverPhone
        driverEmail
        additionalDriver
        additionalDriverName
        additionalDriverPhone
        additionalDriverSSN
        billPayment
        isCompany
        carStatus
        rentalStatus
        innovoiceStatus
        companyName
        companyBusinessId
        companyCity
        companyPostalCode
        companyBillingRef
        companyBillingAddress
        companyEBillingOperator
        companyEBillingAddress
        paymentToken
        npsStatus
        paymentStatus
        signStatus
        signDocumentId
        signerName
        reviewStatus
        authStatus
        authTime
        completeBefore
        mustSign
        customerCarRegistrationPlate
        enableRegistrationPlate
        disablePricesInPrintouts
        invoiceIds
        consolidatedInvoiceIds
        channel
        originURL
        type
        status
        pricingCategoryId
        customImportDate
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PaymentEventByReservationQueryVariables,
  APITypes.PaymentEventByReservationQuery
>;
export const getAdditionalService = /* GraphQL */ `query GetAdditionalService($id: ID!) {
  getAdditionalService(id: $id) {
    id
    organizationId
    orgBusinessId
    group
    description
    descriptionT {
      fi
      en
      sv
      __typename
    }
    description2
    category
    type
    enableTextInput
    enableQuantity
    enableNotification
    maxQuantity
    inputLabel
    _removed
    isGroupParent
    groupFields
    groupParentId
    groupOrderNumber
    image
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdditionalServiceQueryVariables,
  APITypes.GetAdditionalServiceQuery
>;
export const listAdditionalServices = /* GraphQL */ `query ListAdditionalServices(
  $filter: ModelAdditionalServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdditionalServices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      description2
      category
      type
      enableTextInput
      enableQuantity
      enableNotification
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdditionalServicesQueryVariables,
  APITypes.ListAdditionalServicesQuery
>;
export const additionalServiceByOrganization = /* GraphQL */ `query AdditionalServiceByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelAdditionalServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  additionalServiceByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      description2
      category
      type
      enableTextInput
      enableQuantity
      enableNotification
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdditionalServiceByOrganizationQueryVariables,
  APITypes.AdditionalServiceByOrganizationQuery
>;
export const additionalServiceByGroup = /* GraphQL */ `query AdditionalServiceByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelAdditionalServiceFilterInput
  $limit: Int
  $nextToken: String
) {
  additionalServiceByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      organizationId
      orgBusinessId
      group
      description
      descriptionT {
        fi
        en
        sv
        __typename
      }
      description2
      category
      type
      enableTextInput
      enableQuantity
      enableNotification
      maxQuantity
      inputLabel
      _removed
      isGroupParent
      groupFields
      groupParentId
      groupOrderNumber
      image
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AdditionalServiceByGroupQueryVariables,
  APITypes.AdditionalServiceByGroupQuery
>;
export const getPricing = /* GraphQL */ `query GetPricing($id: ID!) {
  getPricing(id: $id) {
    id
    name
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    externalBusinessCategoryAssociation
    businessCategoryAssociation
    elements {
      duration
      price
      __typename
    }
    useNextDayPrice
    useLastDayPrice
    tax
    _removed
    deductible
    reducedDeductible
    reducedDeductiblePrice
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPricingQueryVariables,
  APITypes.GetPricingQuery
>;
export const listPricings = /* GraphQL */ `query ListPricings(
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  listPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricingsQueryVariables,
  APITypes.ListPricingsQuery
>;
export const pricingByOrganization = /* GraphQL */ `query PricingByOrganization(
  $organizationId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingByOrganization(
    organizationId: $organizationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingByOrganizationQueryVariables,
  APITypes.PricingByOrganizationQuery
>;
export const pricingByOrgBusinessId = /* GraphQL */ `query PricingByOrgBusinessId(
  $orgBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingByOrgBusinessId(
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingByOrgBusinessIdQueryVariables,
  APITypes.PricingByOrgBusinessIdQuery
>;
export const pricingByOrgExternallBusinessId = /* GraphQL */ `query PricingByOrgExternallBusinessId(
  $orgExternalBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingByOrgExternallBusinessId(
    orgExternalBusinessId: $orgExternalBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingByOrgExternallBusinessIdQueryVariables,
  APITypes.PricingByOrgExternallBusinessIdQuery
>;
export const pricingBybyExternalBusinessCategoryAssociation = /* GraphQL */ `query PricingBybyExternalBusinessCategoryAssociation(
  $externalBusinessCategoryAssociation: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingBybyExternalBusinessCategoryAssociation(
    externalBusinessCategoryAssociation: $externalBusinessCategoryAssociation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingBybyExternalBusinessCategoryAssociationQueryVariables,
  APITypes.PricingBybyExternalBusinessCategoryAssociationQuery
>;
export const pricingByGroup = /* GraphQL */ `query PricingByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelPricingFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      externalBusinessCategoryAssociation
      businessCategoryAssociation
      elements {
        duration
        price
        __typename
      }
      useNextDayPrice
      useLastDayPrice
      tax
      _removed
      deductible
      reducedDeductible
      reducedDeductiblePrice
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingByGroupQueryVariables,
  APITypes.PricingByGroupQuery
>;
export const getPricingException = /* GraphQL */ `query GetPricingException($id: ID!) {
  getPricingException(id: $id) {
    id
    name
    group
    organizationId
    orgBusinessId
    type
    amountType
    weeklyAmounts
    monthlyAmountPerDay
    daysBeforeMonthChange
    daysAfterMonthChange
    startDate
    endDate
    pricingIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPricingExceptionQueryVariables,
  APITypes.GetPricingExceptionQuery
>;
export const listPricingExceptions = /* GraphQL */ `query ListPricingExceptions(
  $filter: ModelPricingExceptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listPricingExceptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricingExceptionsQueryVariables,
  APITypes.ListPricingExceptionsQuery
>;
export const pricingExceptionByGroup = /* GraphQL */ `query PricingExceptionByGroup(
  $group: String
  $sortDirection: ModelSortDirection
  $filter: ModelPricingExceptionFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingExceptionByGroup(
    group: $group
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingExceptionByGroupQueryVariables,
  APITypes.PricingExceptionByGroupQuery
>;
export const pricingExceptionByOrgBusinessId = /* GraphQL */ `query PricingExceptionByOrgBusinessId(
  $orgBusinessId: ID
  $sortDirection: ModelSortDirection
  $filter: ModelPricingExceptionFilterInput
  $limit: Int
  $nextToken: String
) {
  pricingExceptionByOrgBusinessId(
    orgBusinessId: $orgBusinessId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      group
      organizationId
      orgBusinessId
      type
      amountType
      weeklyAmounts
      monthlyAmountPerDay
      daysBeforeMonthChange
      daysAfterMonthChange
      startDate
      endDate
      pricingIds
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PricingExceptionByOrgBusinessIdQueryVariables,
  APITypes.PricingExceptionByOrgBusinessIdQuery
>;
export const getVoucher = /* GraphQL */ `query GetVoucher($id: ID!) {
  getVoucher(id: $id) {
    id
    name
    group
    organizationId
    code
    amount
    startDate
    endDate
    _removed
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVoucherQueryVariables,
  APITypes.GetVoucherQuery
>;
export const listVouchers = /* GraphQL */ `query ListVouchers(
  $filter: ModelVoucherFilterInput
  $limit: Int
  $nextToken: String
) {
  listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      group
      organizationId
      code
      amount
      startDate
      endDate
      _removed
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVouchersQueryVariables,
  APITypes.ListVouchersQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    group
    organizationId
    orgBusinessId
    orgExternalBusinessId
    rating
    comment
    explanation
    reservationId
    companyId
    startTime
    returnTime
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const reviewsByGroupByStartTime = /* GraphQL */ `query ReviewsByGroupByStartTime(
  $group: String
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByGroupByStartTime(
    group: $group
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByGroupByStartTimeQueryVariables,
  APITypes.ReviewsByGroupByStartTimeQuery
>;
export const reviewsByOrganizationByStartTime = /* GraphQL */ `query ReviewsByOrganizationByStartTime(
  $organizationId: ID
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByOrganizationByStartTime(
    organizationId: $organizationId
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByOrganizationByStartTimeQueryVariables,
  APITypes.ReviewsByOrganizationByStartTimeQuery
>;
export const reviewsByBusinessByStartTime = /* GraphQL */ `query ReviewsByBusinessByStartTime(
  $orgBusinessId: ID
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByBusinessByStartTime(
    orgBusinessId: $orgBusinessId
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByBusinessByStartTimeQueryVariables,
  APITypes.ReviewsByBusinessByStartTimeQuery
>;
export const reviewsByExternalBusinessByStartTime = /* GraphQL */ `query ReviewsByExternalBusinessByStartTime(
  $orgExternalBusinessId: ID
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByExternalBusinessByStartTime(
    orgExternalBusinessId: $orgExternalBusinessId
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByExternalBusinessByStartTimeQueryVariables,
  APITypes.ReviewsByExternalBusinessByStartTimeQuery
>;
export const listReviewsByReservationId = /* GraphQL */ `query ListReviewsByReservationId(
  $reservationId: String
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviewsByReservationId(
    reservationId: $reservationId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      group
      organizationId
      orgBusinessId
      orgExternalBusinessId
      rating
      comment
      explanation
      reservationId
      companyId
      startTime
      returnTime
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsByReservationIdQueryVariables,
  APITypes.ListReviewsByReservationIdQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($id: ID!) {
  getNotification(id: $id) {
    id
    type
    status
    name
    message
    organizationId
    orgBusinessId
    group
    reservationId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const listNotificationsByGroupByStatus = /* GraphQL */ `query ListNotificationsByGroupByStatus(
  $group: String
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationsByGroupByStatus(
    group: $group
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsByGroupByStatusQueryVariables,
  APITypes.ListNotificationsByGroupByStatusQuery
>;
export const listNotificationsByGroupByCreatedAt = /* GraphQL */ `query ListNotificationsByGroupByCreatedAt(
  $group: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationsByGroupByCreatedAt(
    group: $group
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsByGroupByCreatedAtQueryVariables,
  APITypes.ListNotificationsByGroupByCreatedAtQuery
>;
export const listNotificationsByGroupStatusCreatedAt = /* GraphQL */ `query ListNotificationsByGroupStatusCreatedAt(
  $group: String
  $statusCreatedAt: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationsByGroupStatusCreatedAt(
    group: $group
    statusCreatedAt: $statusCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsByGroupStatusCreatedAtQueryVariables,
  APITypes.ListNotificationsByGroupStatusCreatedAtQuery
>;
export const notificationsByBusinessByStatus = /* GraphQL */ `query NotificationsByBusinessByStatus(
  $orgBusinessId: ID
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByBusinessByStatus(
    orgBusinessId: $orgBusinessId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotificationsByBusinessByStatusQueryVariables,
  APITypes.NotificationsByBusinessByStatusQuery
>;
export const listNotificationsByBusinessStatusCreatedAt = /* GraphQL */ `query ListNotificationsByBusinessStatusCreatedAt(
  $orgBusinessId: ID
  $statusCreatedAt: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotificationsByBusinessStatusCreatedAt(
    orgBusinessId: $orgBusinessId
    statusCreatedAt: $statusCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      status
      name
      message
      organizationId
      orgBusinessId
      group
      reservationId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsByBusinessStatusCreatedAtQueryVariables,
  APITypes.ListNotificationsByBusinessStatusCreatedAtQuery
>;
export const getCustomer = /* GraphQL */ `query GetCustomer($id: ID!) {
  getCustomer(id: $id) {
    id
    organizationId
    orgBusinessId
    orgExternalBusinessId
    group
    companyName
    brokerName
    address
    postalCode
    city
    phone
    email
    companyBillingAddress
    companyBusinessId
    companyCity
    companyPostalCode
    companyBillingRef
    companyEBillingOperator
    companyEBillingAddress
    clientType
    isDeleted
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomerQueryVariables,
  APITypes.GetCustomerQuery
>;
export const listCustomers = /* GraphQL */ `query ListCustomers(
  $filter: ModelCustomerFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationId
      orgBusinessId
      orgExternalBusinessId
      group
      companyName
      brokerName
      address
      postalCode
      city
      phone
      email
      companyBillingAddress
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyEBillingOperator
      companyEBillingAddress
      clientType
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomersQueryVariables,
  APITypes.ListCustomersQuery
>;
export const searchCustomers = /* GraphQL */ `query SearchCustomers(
  $filter: SearchableCustomerFilterInput
  $sort: SearchableCustomerSortInput
  $limit: Int
  $nextToken: String
  $from: Int
) {
  searchCustomers(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    from: $from
  ) {
    items {
      id
      organizationId
      orgBusinessId
      orgExternalBusinessId
      group
      companyName
      brokerName
      address
      postalCode
      city
      phone
      email
      companyBillingAddress
      companyBusinessId
      companyCity
      companyPostalCode
      companyBillingRef
      companyEBillingOperator
      companyEBillingAddress
      clientType
      isDeleted
      createdAt
      updatedAt
      __typename
    }
    nextToken
    total
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SearchCustomersQueryVariables,
  APITypes.SearchCustomersQuery
>;

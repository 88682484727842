import { SxProps } from "@mui/material";

export const pageContainerStyle: SxProps = {
  height: 0,
  flexGrow: 1,
  border: "1px solid #e5e5e5 ",
  borderRadius: "10px",
  overflow: "auto",
  bgcolor: "white",
};

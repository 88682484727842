import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";

import {
  Box,
  Drawer,
  Typography,
  Stack,
  Collapse,
  IconButton,
} from "@mui/material";

import {
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarLeftCollapse,
} from "react-icons/tb";

export function DialogMenu({
  openDrawer,
  handleDrawerToggle,
  selectedProductTab,
  setSelectedProductTab,
  handleSelectTab,
  children,
  MenuOptions,
  menu,
  minHeight,
  adaptiveView,
}) {
  const { mobileViewPort, USER_ADMIN_INSURANCE } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(false);

  const sideMenuVisibility = useMemo(() => {
    if (adaptiveView) return false;
    if (mobileViewPort) return false;
    if (USER_ADMIN_INSURANCE) return false;
    return true;
  }, [USER_ADMIN_INSURANCE, adaptiveView, mobileViewPort]);

  const mobileSideMenuVisibility = useMemo(() => {
    if (adaptiveView) return false;
    if (mobileViewPort) return true;
    if (USER_ADMIN_INSURANCE) return false;
    return false;
  }, [USER_ADMIN_INSURANCE, adaptiveView, mobileViewPort]);

  return (
    <Stack direction={"row"} width={"100%"} height={"100%"}>
      <SideMenu
        selectedProductTab={selectedProductTab}
        setSelectedProductTab={setSelectedProductTab}
        handleSelectTab={handleSelectTab}
        visibility={sideMenuVisibility}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        menu={menu}
      />
      <MobileSideMenu
        openDrawer={openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        selectedProductTab={selectedProductTab}
        setSelectedProductTab={setSelectedProductTab}
        handleSelectTab={handleSelectTab}
        visibility={mobileSideMenuVisibility}
        menu={menu}
      />
      <Box
        sx={{
          // minHeight: !mobileViewPort ? minHeight : undefined,
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "flex-start",
          maxHeight: "90%",
          overflow: "auto",
          paddingY: "10px",
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

function MenuOptions(props) {
  const {
    handleSelectTab,
    selectedProductTab,
    collapsed,
    setSelectedProductTab,
    menu,
  } = props;
  /*   const { values, errors } = useFormikContext();
    const { user } = useContext(AppContext);
   */
  return menu.map((item, index) => {
    if (!item.visibility) return null;
    return (
      <NavListItem
        item={item}
        key={item.title}
        handleSelectTab={handleSelectTab}
        selectedProductTab={selectedProductTab}
        setSelectedProductTab={setSelectedProductTab}
        collapsed={collapsed}
      />
    );
  });
}

function SideMenu(props) {
  const {
    selectedProductTab,
    setSelectedProductTab,
    handleSelectTab,
    visibility,
    collapsed,
    setCollapsed,
    menu,
  } = props;

  if (visibility)
    return (
      <Box sx={{ maxWidth: collapsed ? "50px" : "100%" }}>
        <Box sx={{ marginY: "10px" }}>
          {collapsed && (
            <IconButton onClick={() => setCollapsed((prevState) => !prevState)}>
              <TbLayoutSidebarRightCollapse style={{ fontSize: "28px" }} />
            </IconButton>
          )}

          {!collapsed && (
            <IconButton onClick={() => setCollapsed((prevState) => !prevState)}>
              <TbLayoutSidebarLeftCollapse style={{ fontSize: "28px" }} />
            </IconButton>
          )}
        </Box>

        <MenuOptions
          handleSelectTab={handleSelectTab}
          selectedProductTab={selectedProductTab}
          collapsed={collapsed}
          setSelectedProductTab={setSelectedProductTab}
          menu={menu}
        />
      </Box>
    );
}

function MobileSideMenu(props) {
  const {
    openDrawer,
    handleDrawerToggle,
    selectedProductTab,
    setSelectedProductTab,
    handleSelectTab,
    visibility,
    menu,
  } = props;
  const { mobileViewPort } = useContext(AppContext);

  if (visibility) {
    return (
      <Drawer
        /*  container={container} */
        PaperProps={{
          style: {
            position: "absolute",
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderBottomWidth: 0,
            borderRadius: 0,
            WebkitBoxShadow: "none",
            boxShadow: "none",
            padding: "10px",
          },
        }}
        variant={mobileViewPort ? "temporary" : "permanent"}
        anchor="left"
        open={openDrawer}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{ zIndex: 9999 }}
      >
        <MenuOptions
          handleSelectTab={handleSelectTab}
          selectedProductTab={selectedProductTab}
          collapsed={false}
          setSelectedProductTab={setSelectedProductTab}
          menu={menu}
        />
      </Drawer>
    );
  } else {
    return null;
  }
}

function NavListItem({
  item,
  handleSelectTab,
  selectedProductTab,
  setSelectedProductTab,
  collapsed,
}) {
  const {
    title,
    subTitle,
    icon,
    tab,

    active,
  } = item;

  const itemStyles = useMemo(() => {
    if (!active) {
      return {
        backgroundColor: "transparent",
        color: "rgba(0,0,0,0.25)",
        subColor: "rgba(0,0,0,0.25)",
      };
    }
    if (selectedProductTab === tab) {
      return {
        backgroundColor: "#efefef",
        color: "#000000",
        subColor: "rgba(0,0,0,0.55)",
      };
    }
    return {
      backgroundColor: "transparent",
      color: "#000000",
      subColor: "rgba(0,0,0,0.45)",
    };
  }, [active, selectedProductTab, tab]);

  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: itemStyles.backgroundColor,
        transition: "ease-in-out 150ms",
        borderRadius: "10px",
        cursor: active ? "pointer" : undefined,
        alignItems: "center",
        height: "75px",
      }}
      onClick={active ? () => handleSelectTab(tab) : null}
    >
      <Box sx={{ padding: "10px", color: itemStyles.color }}> {icon}</Box>
      <Collapse in={!collapsed} orientation="horizontal">
        <Box sx={{ width: "200px" }}>
          {!collapsed && (
            <Typography
              sx={{
                fontFamily: "Sofia Pro",
                fontSize: "18px",
                color: itemStyles.color,
              }}
            >
              {title}
            </Typography>
          )}
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "14px",
              color: itemStyles.subColor,
            }}
          >
            {subTitle}
          </Typography>
        </Box>
      </Collapse>
    </Stack>
  );
}

import { Stack } from "@mui/material";
import { FormContainer } from "../../../../../components/FormContainer";
import { FormInputField } from "../../../../../components/ReservationForm/utils/FormInputField";

export default function ReservationMessages() {
  return (
    <FormContainer title={"Viestit"} visibility={true}>
      <Stack flex={1} spacing={2}>
        <FormInputField
          name="internalNotes"
          label="Sisäiset muistiinpanot"
          rows={5}
        />

        <FormInputField
          name="rentalNotes"
          label="Vuokraamon viesti asiakkaalle"
          rows={5}
        />

        <FormInputField
          name="externalNotes"
          disabled
          label="Asiakkaan viesti vuokraamolle"
          rows={5}
        />
      </Stack>
    </FormContainer>
  );
}

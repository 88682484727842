import { Grid } from "@mui/material";
import PaymentStatusFilters from "./PaymentStatusFilters";
import InvoiceItemsTable from "./InvoiceItemsTable";
import PaymentEventsTable from "./PaymentEventsTable";
import ActionButtons from "./ActionButtons";
import InsuranceBillingSettings from "./InsuranceBillingSettings";
import { useMemo } from "react";
import { useReservationContext } from "../../ReservationContext";
import { parseToCents } from "../../../../shared/money";
import { useFetchInvoices } from "../../../../services/fetchInvoices";
import { useFetchPaymentEvents } from "../../../../services/fetchPaymentEvents";

const PaymentSummary = ({ setShowInvoiceDialog, FEATURE_INVOICING }) => {
  const { reservationData } = useReservationContext();
  const { selectedServiceOffers, selectedOffers } = useReservationContext();
  const { invoices, invoicesLoading, invoicesMutate } = useFetchInvoices(
    reservationData?.id,
    FEATURE_INVOICING
  );

  const getInvoiceTypeLabel = (type) => {
    switch (type) {
      case "FULL":
        return "Koko varaus";
      case "EXTRA":
        return "Lisälasku";
      case "INSURANCE_COMPANY":
        return "Vakuutusyhtiö";
      case "INSURANCE_CUSTOMER":
        return "Vakuutusyhtiö asiakkaan osuus";
      default:
        return "";
    }
  };

  const calculateInvoiceSum = (items) => {
    return items?.reduce(
      (total, item) => total + item.quantity * item.unitprice,
      0
    );
  };

  const { paymentEvents, paymentEventsLoading, paymentEventsMutate } =
    useFetchPaymentEvents(reservationData?.id, FEATURE_INVOICING);

  const invoiceItems = useMemo(() => {
    const additionalServicesItems = selectedServiceOffers?.map((service) => {
      const taxRate = service.tax ?? 24; // Default tax rate is 24%

      const priceInCents = service.modifiedPrice
        ? parseToCents(service.modifiedPrice)
        : service?.listingPrice ?? service?.price;
      const priceWithoutTax = priceInCents / (1 + taxRate / 100);
      return {
        id: service.key,
        description: service.description,
        quantity: service.quantity,
        priceExVAT: priceWithoutTax,
        VAT: `${taxRate}%`,
        priceIncVAT: priceInCents,
      };
    });

    const reservationVehiclesItems = selectedOffers?.map((vehicle) => {
      const taxRate = vehicle.tax ?? 24; // Default tax rate is 24%

      const priceInCents = vehicle.modifiedPrice
        ? parseToCents(vehicle.modifiedPrice)
        : vehicle?.listingPrice ?? vehicle?.price;

      const priceWithoutTax = priceInCents / (1 + taxRate / 100);
      return {
        id: vehicle.id,
        description: `${vehicle.name}, Rekisterinumero: ${vehicle.registrationPlate}`,
        quantity: 1, // Assuming quantity is 1 for vehicles
        priceExVAT: priceWithoutTax,
        VAT: `${taxRate}%`,
        priceIncVAT: priceInCents,
      };
    });

    return [...additionalServicesItems, ...reservationVehiclesItems];
  }, [selectedOffers, selectedServiceOffers]);

  const invoiceItemsSum = useMemo(() => {
    return invoiceItems.reduce(
      (acc, item) => acc + parseFloat(item.priceIncVAT) * item.quantity,
      0
    );
  }, [invoiceItems]);

  const combinedEvents = useMemo(() => {
    if (!invoices || !paymentEvents) {
      return [];
    }

    const invoiceEvents = invoices.map((invoice) => {
      const sum = calculateInvoiceSum(invoice.items);
      const formattedDate = new Date(invoice.createdAt).toLocaleDateString(
        "fi-FI"
      );
      const innovoiceIdDisplay = invoice.innovoiceId
        ? ` - Innovoice ID: ${invoice.innovoiceId}`
        : "";
      const label = `Lasku: ${getInvoiceTypeLabel(
        invoice.type
      )} - Luotu: ${formattedDate}${innovoiceIdDisplay}`;
      return {
        id: invoice.id,
        type: invoice.type,
        innovoiceId: invoice.innovoiceId,
        label: label,
        sum: sum,
        createdAt: new Date(invoice.createdAt).toLocaleDateString("fi-FI"),
        channel: "Invoice",
        invoice: invoice, // Necessary for invoiceDialog (for now)
      };
    });

    const paymentEventItems = paymentEvents
      .filter((event) => event.returnCode === null || event.returnCode === 0)
      .map((event) => {
        const formattedDate = new Date(event.createdAt).toLocaleDateString(
          "fi-FI"
        );
        const formattedTime = new Date(event.createdAt).toLocaleTimeString(
          "fi-FI"
        );
        const innovoiceIdDisplay = event.innovoiceId
          ? ` - Innovoice ID: ${event.innovoiceId}`
          : "";
        const label =
          event.channel === "MANUAL"
            ? `Käteis-/korttimaksu vastaanotettu ${formattedDate} ${formattedTime} (${event.createdByUserName})${innovoiceIdDisplay}`
            : `Visma pay - Luotu: ${formattedDate}${innovoiceIdDisplay}`;
        return {
          id: event.id,
          type: event.channel,
          label: label,
          sum: event.paymentTotal,
          createdAt: new Date(event.createdAt).toLocaleDateString("fi-FI"),
          createdByUserName: event.createdByUserName,
          channel: "Payment",
          isDeleted: event.isDeleted || false,
          deletedBy: event.deletedBy || "",
          deletedAt: event.deletedAt || "",
        };
      });

    return [...invoiceEvents, ...paymentEventItems];
  }, [invoices, paymentEvents]);

  const unpaidAmount = useMemo(() => {
    const totalPaid = combinedEvents.reduce((acc, event) => {
      if (!event.isDeleted) {
        const numericSum = event.sum;
        return acc + numericSum;
      }
      return acc;
    }, 0);

    return invoiceItemsSum - totalPaid;
  }, [invoiceItemsSum, combinedEvents]);

  return (
    <Grid lg={12} container>
      <Grid item xs={12}>
        <PaymentStatusFilters />
      </Grid>
      <Grid item xs={12}>
        <InvoiceItemsTable
          FEATURE_INVOICING={FEATURE_INVOICING}
          invoiceItems={invoiceItems}
          invoiceItemsSum={invoiceItemsSum}
        />
      </Grid>
      <Grid item xs={12}>
        <PaymentEventsTable
          setShowInvoiceDialog={setShowInvoiceDialog}
          unpaidAmount={unpaidAmount}
          showActions={true}
          invoicesMutate={invoicesMutate}
          invoicesLoading={invoicesLoading}
          paymentEventsMutate={paymentEventsMutate}
          paymentEventsLoading={paymentEventsLoading}
          combinedEvents={combinedEvents}
        />
      </Grid>
      <Grid item xs={12}>
        <InsuranceBillingSettings />
      </Grid>
      <Grid item xs={12}>
        <ActionButtons
          setShowInvoiceDialog={setShowInvoiceDialog}
          FEATURE_INVOICING={FEATURE_INVOICING}
          invoiceItems={invoiceItems}
          invoiceItemsSum={invoiceItemsSum}
          unpaidAmount={unpaidAmount}
          showActions={true}
          invoicesMutate={invoicesMutate}
          invoicesLoading={invoicesLoading}
          paymentEventsMutate={paymentEventsMutate}
          paymentEventsLoading={paymentEventsLoading}
          combinedEvents={combinedEvents}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;

import { Form, Formik } from "formik";
import { useGetInitialFormData } from "../../../formik/hooks/useGetInitialFormData";
import { reservationFields } from "../../../formik/fields";
import {
  handleInsuranceData,
  handleReservationFailedSuccessfully,
} from "../../../formik/utils";
import { API, graphqlOperation } from "aws-amplify";
import { upsertReservationAdmin } from "../../../../../graphql/mutations";
import { captureError } from "../../../../../utilities";
import { useAtomValue, useSetAtom } from "jotai";
import { insuranceDataAtom } from "../../../../../stores/insuranceData";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { useValidateFormikFields } from "../../../formik/validate";
import { useNotification } from "../../../../Notification";
import { useMutateLocalReservations } from "../../../hooks/useMutateLocalReservations";

export default function ReservationForm({ children }) {
  const { user } = useContext(AppContext);
  const initialFormValues = useGetInitialFormData({});
  const initialValues = {
    ...initialFormValues,
    status: "EXTERNAL_PENDING_DEALER",
  };
  const notification = useNotification();

  const insuranceData = useAtomValue(insuranceDataAtom);
  const setEditInsuranceData = useSetAtom(insuranceDataAtom);
  const { validate } = useValidateFormikFields();
  const { mutateLocalReservations } = useMutateLocalReservations();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        try {
          const response = await submit(values, insuranceData, user);
          if (response?.error) {
            notification.show("Varauksen luonti epäonnistui", "error");
          }

          if (response?.success) {
            const getNotification = handleReservationFailedSuccessfully(
              response?.data?.code,
              response?.data?.emailConfirmationSuccess,
              response?.data?.reservation?.email
            );
            notification.show(getNotification.message, getNotification.variant);
            await mutateLocalReservations(response?.data?.reservation);
            setEditInsuranceData(null);
          }
        } catch (err) {
          captureError(
            "Submit insurance_reservation failed",
            "SUBMIT_INSURANCE_RESERVATION_FAILED",
            err
          );
          notification.show("Varauksen luonti epäonnistui", "error");
        }
      }}
      validate={(values) => validate(values)}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {children}
      </Form>
    </Formik>
  );
}
const submit = async (values, reservationData, user) => {
  const reservation = {
    [reservationFields.id]: values.id,
    [reservationFields.channel]: reservationData?.channel ?? "DEALER",

    /* DEALER FIELDS */
    [reservationFields.group]: values.group,
    [reservationFields.organizationId]: values.organizationId,
    [reservationFields.orgBusinessId]: values.orgBusinessId,
    [reservationFields.orgExternalBusinessId]: values?.orgExternalBusinessId,

    [reservationFields.type]: "INSURANCE",
    [reservationFields.status]:
      reservationData?.status ?? "EXTERNAL_PENDING_DEALER",

    [reservationFields.reservationStatusInfo]: values.reservationStatusInfo,

    // INSURANCE
    [reservationFields.insuranceData]: handleInsuranceData(
      values?.insuranceData,
      user
    ),
    [reservationFields.customerCarRegistrationPlate]:
      values.customerCarRegistrationPlate,

    /* CUSTOMER FIELDS */
    [reservationFields.email]: values?.email?.trim(),
    [reservationFields.name]: values.name,
    [reservationFields.address]: values.address,
    [reservationFields.ssn]: values.ssn,
    [reservationFields.phone]: values?.phone,

    /* RESERVATION NOTES */
    [reservationFields.internalNotes]: values.internalNotes,
    [reservationFields.externalNotes]: values.externalNotes,
    [reservationFields.rentalNotes]: values.rentalNotes,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(upsertReservationAdmin, {
        reservation: reservation,
        sendStatusToCustomer: values.sendStatusToCustomer,
        disableConfirmationEmail: values.disableConfirmationEmail,
      })
    );

    return { success: true, data: response.data.upsertReservationAdmin };
  } catch (err) {
    captureError("Upsert reservation failed", "UPSERT_RESERVATION_FAILED", err);
    //   notification.show("Varauksen luonti epäonnistui", "error");
    console.log("UPSERT", err);
    return { success: false, error: err };
  }
};

import React, { useContext } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { devStaging } from "~/features";
import { AppContext } from "~/AppContext";

export default function IsBrokerCheckBox() {
  const { user } = useContext(AppContext);
  const [field, meta, helpers] = useField("isBroker");
  const { setFieldValue } = useFormikContext();
  const cypressTag = `data-cy-reservation-form-select-isBroker`;
  const handleChange = (e, checked) => {
    if (checked) {
      setFieldValue("isBroker", true);
    } else {
      setFieldValue("customerBrokerId", null);
      helpers.setValue(false);
    }
  };

  const FEATURE_BROKER_RESERVATION_ENABLED = isBrokerEnabled(user);

  if (!FEATURE_BROKER_RESERVATION_ENABLED) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={field.name}
          checked={!!meta.value}
          color="primary"
          onChange={handleChange}
          onBlur={field.handleBlur}
          data-cy={cypressTag}
        />
      }
      label={<>{"Agenttivaraus"}</>}
    />
  );
}

const isBrokerEnabled = (user) => {
  const isDevelopment = devStaging(process.env.REACT_APP_ENV);
  const allowedOrganizations = [
    "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent
  ];

  if (isDevelopment) {
    return true;
  }
  return allowedOrganizations.some((org) => org === user?.organizationId);
};

export const EProductType = {
  CARS: "product-type-cars",
  SUVS: "product-type-suvs",
  MINIVAN: "product-type-minivan",
  MINIBUS: "product-type-minibus",
  TRUCK: "product-type-truck",
  VANS: "product-type-vans",
  RVS: "product-type-rvs",
  CAMPERS: "product-type-campers",
  TRAILERS: "product-type-trailers",
  TRAILER_OPEN: "product-type-trailer-open",
  TRAILER_ENCLOSED: "product-type-trailer-enclosed",
  SNOWMOBILES: "product-type-snowmobiles",
  JETSKIS: "product-type-jetskis",
  BICYCLES: "product-type-bicycles",
  SAUNABOATS: "product-type-saunaboats",
  ACCOMMODATIONS: "product-type-accommodations",
  SCOOTER: "product-type-scooter",
  OTHER: "product-type-other",
};

export type EProductType = keyof typeof EProductType;

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ETabOptions, ETabs, EUserRoles } from "../utils/tabOptions";
import { useContext, useMemo, useState } from "react";
import { AppContext } from "../../../AppContext";
import DefaultPricingsView from "./DefaultPricingsView";
import DynamicPricingsView from "./DynamicPricingsView";
import ContractPricingView from "./ContractPricingsView";
import AdditionalServicePricingsView from "./AdditionalServicePricingsView";
import { useFetchCompanies } from "../../../services/fetchCompanies";
import CategoryPricingsView from "./CategoryPricingsView/";
import { Info } from "@mui/icons-material";

export default function TabbedContent(props) {
  const { setEditablePricing } = props;
  const { user, business, company } = useContext(AppContext);
  const [selectedTab, setSelectedTab] = useState(
    business ? ETabs.CATEGORY_PRICING : ETabs.PRICING
  );
  const [selectedCompany, setSelectedCompany] = useState(() => company);
  const userRole = user?.group ? EUserRoles.LEGACY : EUserRoles[user?.role];
  const theme = useTheme();
  const mobileViewPort = useMediaQuery(theme.breakpoints.down("sm"));

  const EOptionsByUser = useMemo(() => {
    const options = {
      [EUserRoles.ORGANIZATION_ADMIN]: [
        ETabOptions[ETabs.PRICING],
        ETabOptions[ETabs.EXTERNAL_BUSINESS_PRICING],
      ],
      [EUserRoles.BUSINESS_ADMIN]: [
        ETabOptions[ETabs.CATEGORY_PRICING],
        ETabOptions[ETabs.PRICING],
        ETabOptions[ETabs.DYNAMIC_PRICING],
      ],

      [EUserRoles.LEGACY]: [
        ETabOptions[ETabs.PRICING],
        ETabOptions[ETabs.DYNAMIC_PRICING],
      ],
    };
    if (EUserRoles.ORGANIZATION_ADMIN && business) {
      options.ORGANIZATION_ADMIN = [
        ETabOptions[ETabs.CATEGORY_PRICING],
        ETabOptions[ETabs.PRICING],
        ETabOptions[ETabs.DYNAMIC_PRICING],
      ];
    }
    return options;
  }, [business]);

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {EOptionsByUser[userRole].map((option) => (
          <Tab key={option.VALUE} label={option.LABEL} value={option.VALUE} />
        ))}
      </Tabs>
      <Stack
        direction={mobileViewPort ? "column" : "row"}
        alignItems={mobileViewPort ? "flex-start" : "center"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          flex={1}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ paddingY: "20px" }}
        >
          <ViewTitle />
          <CompanySelector
            visibility={selectedTab === ETabs.CATEGORY_PRICING}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
          <AddNewPricingButton
            setEditablePricing={setEditablePricing}
            selectedTab={selectedTab}
          />
        </Stack>
      </Stack>
      <Divider />
      <Content
        selectedTab={selectedTab}
        setEditablePricing={setEditablePricing}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
    </Box>
  );
}

function ViewTitle() {
  const { user, business } = useContext(AppContext);
  if (user?.role === EUserRoles.ORGANIZATION_ADMIN && !business?.id) {
    return (
      <Typography variant="h5" align="center" sx={{ fontFamily: "Sofia Pro" }}>
        {`${user?.organization?.name ?? ""} - Hinnastot`}
      </Typography>
    );
  }
  if (business?.id) {
    return (
      <Typography variant="h5" align="center" sx={{ fontFamily: "Sofia Pro" }}>
        {`${business?.name ?? ""} - Hinnastot`}
      </Typography>
    );
  }
}

function CompanySelector(props) {
  const { visibility, selectedCompany, setSelectedCompany } = props;
  const { business } = useContext(AppContext);
  const { companies } = useFetchCompanies(null, business?.id);
  const [value, setvalue] = useState(selectedCompany?.id);
  if (visibility)
    return (
      <Stack sx={{ width: "320px" }} direction={"row"} spacing={1}>
        <Select
          value={value ?? ""}
          onChange={(e) => {
            setSelectedCompany(
              companies.find((item) => item.id === e.target.value)
            );
            setvalue(e.target.value);
          }}
          renderValue={(value) =>
            companies.find((item) => item.id === value)?.name
          }
          fullWidth
        >
          {companies.map((company) => (
            <MenuItem key={company?.id} value={company.id}>
              {company?.name}
            </MenuItem>
          ))}
        </Select>
        <Tooltip title="Dynaaminen hinta on toimipistekohtainen">
          <Info color="info" />
        </Tooltip>
      </Stack>
    );
}

function Content(props) {
  const {
    selectedTab,
    setEditablePricing,
    selectedCompany,
    setSelectedCompany,
  } = props;
  switch (selectedTab) {
    case ETabs.PRICING:
      return <DefaultPricingsView setEditablePricing={setEditablePricing} />;
    case ETabs.DYNAMIC_PRICING:
      return <DynamicPricingsView />;
    case ETabs.CATEGORY_PRICING:
      return (
        <CategoryPricingsView
          setEditablePricing={setEditablePricing}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      );
    case ETabs.EXTERNAL_BUSINESS_PRICING:
      return <ContractPricingView setEditablePricing={setEditablePricing} />;
    case ETabs.ADDITIONAL_SERVICES:
      return (
        <AdditionalServicePricingsView
          setEditablePricing={setEditablePricing}
        />
      );
    default:
      return <DefaultPricingsView setEditablePricing={setEditablePricing} />;
  }
}

function AddNewPricingButton({ setEditablePricing, selectedTab }) {
  const visibility = selectedTab === ETabs.PRICING;
  if (visibility)
    return (
      <Button
        variant="contained"
        color="primary"
        disabled={null}
        onClick={() =>
          setEditablePricing({
            name: "",
            elements: [],
          })
        }
        sx={{
          textTransform: "none",
          width: "200px",
        }}
      >
        Lisää uusi hinnasto
      </Button>
    );
}

export function checkNumericInput(input) {
  // If the input is empty, return it as is
  if (input === "") {
    return input;
  }

  // Verify that inputted value is numerical with max one ",". E.g 10,50
  if ((input.match(/,/g) || []).length <= 1) {
    const numValue = input.replace(",", ".");
    // If the numValue is not an empty string and it's not a number, return
    if (numValue !== "" && isNaN(numValue)) {
      return;
    }
  } else {
    return;
  }
  return input;
}

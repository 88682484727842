import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { useCategoryImage } from "~/hooks/useCategoryImage";

export const CustomMenuItem = (props) => {
  const { category } = props;
  const theme = useTheme();
  const { image, backgroundColor } = useCategoryImage(category);

  return (
    <MenuItem {...props}>
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginX: "10px",
            height: "60px",
            width: "60px",
            bgcolor: "white",
          }}
        >
          <img
            style={{
              objectFit: "scale-down",
              height: "100%",
              width: "100%",
            }}
            alt=""
            src={image}
          ></img>
        </Box>
        <Typography sx={{ marginRight: "15px" }}>
          <b>{props.itemcontent?.class}</b>
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            <b>{category?.name ?? props.itemcontent?.name}</b>
          </Typography>

          <Typography>{props.itemcontent?.exampleModel}</Typography>
        </Box>
      </Stack>
    </MenuItem>
  );
};

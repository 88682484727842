import { GridEditInputCell } from "@mui/x-data-grid";
import { centsToLocalString } from "../../../shared/money";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

export const pricingHourly = {
  field: "pricingHour",
  headerName: "Tunti",
  flex: 0.5,
  minWidth: 100,
  type: "number",
  renderCell: (params) => {
    //  console.log("params", params.row.pricing);
    if (params.row?.percentage) {
      const rowPricingHour = () => {
        const userDefinedPrice =
          params.row?.pricingHour +
          (params.row?.pricingHour * params.row?.percentage) / 100;
        if (!!userDefinedPrice) {
          return centsToLocalString(userDefinedPrice);
        } else return params.row?.pricingHour;
      };

      return rowPricingHour();
    } else {
      if (isNaN(params.row.pricingHour)) {
        return "";
      } else {
        return centsToLocalString(params.row.pricingHour);
      }
    }
  },
};

export const pricingDaily = {
  field: "pricingDay",
  headerName: "Vuorokausi",
  type: "number",
  flex: 0.5,
  minWidth: 100,
  renderCell: (params) => {
    if (params.row?.percentage) {
      const rowPricingDay = () => {
        const userDefinedPrice =
          params.row?.pricingDay +
          (params.row?.pricingDay * params.row?.percentage) / 100;
        if (!!userDefinedPrice) {
          return centsToLocalString(userDefinedPrice);
        } else return params.row?.pricingDay;
      };
      return rowPricingDay();
    } else {
      if (isNaN(params.row.pricingDay)) {
        return "";
      } else {
        return centsToLocalString(params.row.pricingDay);
      }
    }
  },
};

export const percentage = {
  field: "percentage",
  headerName: "Hinnan muutos",
  type: "number",
  flex: 0.5,
  minWidth: 100,
  renderEditCell: renderEditPercentage,
  editable: true,
  valueGetter: (params) => params.value ?? "",
  valueFormatter: (params) => {
    if (!params.value || params.value === "") {
      return "";
    }
    return `${params.value} %`;
  },
};

function renderEditPercentage(params) {
  return (
    <GridEditInputCell
      {...params}
      disabled={params.row?.businessCategoryAssociation}
    />
  );
}

export const pricingNameField = {
  field: "name",
  headerName: "Hinnasto",
  flex: 0.5,
  minWidth: 100,
};

export const servicePriceField = {
  field: "description",
  headerName: "Nimi",
  flex: 0.5,
  minWidth: 100,
};

export const VAT = {
  field: "VAT",
  headerName: "Verokanta",
  flex: 0.5,
  minWidth: 100,
  type: "number",
  valueFormatter: (params) => {
    if (params.value == null) {
      return "";
    }
    return `${params.value.toLocaleString()} %`;
  },
};

export const editPricing = {
  field: "editPricing",
  headerName: " ",
  width: 70,
  renderCell: (params) => {
    if (params.row?.editPricing) {
      return (
        <IconButton
        //   onClick={() => setEditablePricing(params.row.editPricing)}
        >
          <Edit />
        </IconButton>
      );
    }
  },
};

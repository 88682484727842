import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  TextField,
} from "@mui/material";

import DisableProductDialog from "./DisableProductDialog";

import { CloudUpload, Delete } from "@mui/icons-material";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { useFormikContext, Field } from "formik";
import { FormGrid } from "../common/FormComponents";
import { DatePicker } from "@mui/x-date-pickers-pro";

const FormikDateField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md}>
      <DatePicker
        {...field}
        label={props.label}
        inputVariant="outlined"
        onChange={(date) => {
          setFieldValue(field.name, date);
        }}
        inputFormat="dd.MM.yyyy"
        fullWidth
        cancelLabel="Peruuta"
        required
        componentsProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
        referenceDate={props.referenceDate}
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </Grid>
  );
};

function LivionKeyField({ formik, company }) {
  const { handleBlur, handleChange, values, touched, errors } =
    useFormikContext();

  if (company.livionDeviceType === "KEYPAD") {
    // DeviceId does not exist. Use checkbox to mark if Vehicle should be linked to the keypad
    return (
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="livionKeyId"
              checked={!!values.livionKeyId ?? false}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={"Yhdistä Livion Key Pad:iin"}
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <TextField
        value={values.livionKeyId ?? ""}
        name="livionKeyId"
        label="Livion Key Id"
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        variant="outlined"
        error={touched.livionKeyId && !!errors.livionKeyId}
        helperText={touched.livionKeyId && errors.livionKeyId}
      />
    </Grid>
  );
}

export default function ProductInformation({
  data,

  image,
  setImage,
  onChange,
  fileInputRef,
  signedUrl,
  setSignedUrl,
  imageLoading,
  setImageLoading,
  categories,
  showCategories,
  showCategoryStacking,
  selectedCategory,
  FEATURE_CHANGE_COMPANY,
  showChangeCompany,
  companies,
  setShowChangeCompany,
  mobileViewPort,
  company,
}) {
  const { handleBlur, handleChange, values, touched, errors, setFieldValue } =
    useFormikContext();
  const [showDisableProductDialog, setShowDisableProductDialog] =
    useState(false);
  const { user } = useContext(AppContext);

  return (
    <Grid
      container
      direction={mobileViewPort ? "column" : "row"}
      spacing={1}
      justifyContent={"center"}
    >
      <Grid
        container
        direction={mobileViewPort ? "column" : "row"}
        justifyContent="space-evenly"
        item
        xs={12}
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          style={{ maxWidth: mobileViewPort ? undefined : 250 }}
        >
          <Grid container justifyContent="center">
            <Grid
              item
              xs={8}
              sm={9}
              md={12}
              style={{
                borderStyle: "solid",
                width: "100%",
                borderWidth: 1,
                borderColor: "lightgrey",
                borderRadius: 10,
                maxHeight: mobileViewPort ? "20vh" : 200,
                minHeight: 200,
              }}
              container
              justifyContent="center"
              alignItems="center"
            >
              {imageLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 200,
                    width: 200,
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              )}

              {!imageLoading && signedUrl && (
                <img
                  style={{
                    maxWidth: "100%",
                    height: mobileViewPort ? "70%" : 200,
                    maxHeight: 200,
                    /*    width: mobileViewPort ? "100%" : 200, */
                    objectFit: "scale-down",
                  }}
                  alt="kuva"
                  src={signedUrl}
                />
              )}
              {!imageLoading && !signedUrl && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 200,
                    width: 200,
                  }}
                >
                  <Typography variant="body2"> Ei kuvaa</Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            justifyContent="space-evenly"
          >
            {image && (
              <IconButton
                onClick={() => {
                  setImage(null);
                  setSignedUrl(null);
                }}
                size="large"
              >
                <Delete />
              </IconButton>
            )}
            <IconButton component="label" size="large">
              <CloudUpload />
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(evt) => onChange(evt)}
                ref={fileInputRef}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent={mobileViewPort ? "center" : "flex-start"}
          >
            <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Grid item xs={11} md={8} data-cy="productDialogNameField">
                <TextField
                  name="name"
                  label="Malli/nimi"
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  value={values.name ?? ""}
                  error={touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  fullWidth
                />
              </Grid>

              <Grid
                item
                xs={11}
                md={4}
                data-cy="productDialogRegistrationPlateField"
              >
                <TextField
                  onChange={(e) =>
                    setFieldValue(
                      "registrationPlate",
                      e.target.value.toUpperCase()
                    )
                  }
                  value={values.registrationPlate ?? ""}
                  name="registrationPlate"
                  label="Rekisterinumero"
                  fullWidth
                />
              </Grid>
            </Grid>
            {company.livionDeviceId && <LivionKeyField company={company} />}

            <Grid item xs={11} md={12} data-cy="productDialogCategorySelect">
              <CategorySelection
                showCategories={showCategories}
                categories={categories}
                name={"vehicleCategoryId"}
                label="Kategoria"
                required={!!user?.organizationId}
              />
            </Grid>
            <Grid item xs={11} md={6} data-cy="productDialogOrderNumberField">
              <TextField
                value={values.orderNumber ?? ""}
                name="orderNumber"
                label="Järjestysnumero"
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid item xs={11} md={6} data-cy="productDialogMileageField">
              <TextField
                value={values.mileage ?? ""}
                name="mileage"
                label="Ajoneuvon kilometrit"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            {showCategoryStacking && selectedCategory && (
              <FormGrid>
                <FormControlLabel
                  control={
                    <Switch
                      name={"useCategoryDefaults"}
                      checked={values["useCategoryDefaults"] || false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={
                        !!!selectedCategory?.pricing || !!user?.organizationId
                      }
                    />
                  }
                  label={
                    <Typography variant="body2">
                      Käytä oletuksia ja niputa asiakashaussa
                    </Typography>
                  }
                />
                <>
                  {errors.categoryStartReturnTimeConflict && (
                    <FormControl
                      error={!!errors.categoryStartReturnTimeConflict}
                    >
                      <FormHelperText>
                        Ei mahdollista käyttää erikoisaikojen kanssa
                      </FormHelperText>
                    </FormControl>
                  )}
                </>
              </FormGrid>
            )}

            {mobileViewPort && (
              <Grid
                container
                spacing={2}
                sx={{
                  flexDirection: "row",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
                xs={12}
              >
                <Grid item xs={11} data-cy="productDialogExtraInfoField">
                  <TextField
                    fullWidth
                    value={values.extraInfo ?? ""}
                    name="extraInfo"
                    label="Lisätietoja sopimukselle"
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid
                  item
                  xs={11}
                  data-cy="productDialogExtraConfirmationInfoField"
                >
                  <TextField
                    fullWidth
                    value={values.extraToConfirmation ?? ""}
                    name="extraToConfirmation"
                    label="Lisätietoja varausvahvistukselle"
                    multiline
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={11} data-cy="productDialogDamagesField">
                  <TextField
                    fullWidth
                    name="damages"
                    label="Vauriot"
                    value={values.damages ?? ""}
                    multiline
                    rows={4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {!mobileViewPort && (
        <>
          {FEATURE_CHANGE_COMPANY && data && (
            <>
              {showChangeCompany ? (
                <FormGrid>
                  <FormControl fullWidth>
                    <InputLabel id="companyIdLabel">Toimipiste</InputLabel>
                    <Select
                      labelId="categoryLabel"
                      value={values.companyId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"companyId"}
                      label="Toimipiste"
                    >
                      {(companies || []).map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </FormGrid>
              ) : (
                <FormGrid>
                  <Button
                    onClick={() => setShowChangeCompany(true)}
                    sx={{ textTransform: "none", height: "50px" }}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    <Typography variant="body1">
                      Vaihda toimipistettä
                    </Typography>
                  </Button>
                </FormGrid>
              )}
            </>
          )}
          <FormGrid data-cy="productDialogExtraInfoField">
            <TextField
              value={values.extraInfo ?? ""}
              name="extraInfo"
              label="Lisätietoja sopimukselle"
              multiline
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGrid>
          <FormGrid data-cy="productDialogExtraConfirmationInfoField">
            <TextField
              value={values.extraToConfirmation ?? ""}
              name="extraToConfirmation"
              label="Lisätietoja varausvahvistukselle"
              multiline
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGrid>

          <FormGrid data-cy="productDialogDamagesField">
            <TextField
              name="damages"
              label="Vauriot"
              value={values.damages ?? ""}
              multiline
              rows={4}
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGrid>

          <FormGrid>
            <Grid>
              <Typography mb={1} variant="body1" fontWeight="bold">
                Käytössäoloaika
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Field
                name="activeRange.start"
                label="Alkaa"
                component={FormikDateField}
                md={4}
              />
              <Field
                name="activeRange.end"
                label="Päättyy"
                referenceDate={values.activeRange.start ?? undefined}
                component={FormikDateField}
                md={4}
              />
            </Grid>
            {errors.activeRange && (
              <Grid xs={12}>
                <FormControl error={!!errors.activeRange}>
                  <FormHelperText>{errors.activeRange}</FormHelperText>
                </FormControl>
              </Grid>
            )}
          </FormGrid>

          <FormGrid>
            <FormControlLabel
              control={
                <Switch
                  name={"disableOnline"}
                  checked={values["disableOnline"] || false}
                  onChange={handleChange}
                  //onChange={(e) => console.log(e)}
                  onBlur={handleBlur}
                />
              }
              label={
                <Typography variant="body2">
                  Piilota Online -varauskoneesta
                </Typography>
              }
            />
          </FormGrid>
        </>
      )}

      <FormGrid
        data-cy="productDialogRemoveFromUseButton"
        container
        sx={{ justifyContent: "center", marginY: "20px" }}
      >
        <Button
          onClick={() => setShowDisableProductDialog(true)}
          style={{ textTransform: "none" }}
          variant="contained"
          color="warning"
        >
          <Typography variant="body2">Poista käytöstä</Typography>
        </Button>
      </FormGrid>

      {showDisableProductDialog && (
        <>
          <DisableProductDialog
            onClose={() => setShowDisableProductDialog(false)}
            vehicle={data}
          />
        </>
      )}
    </Grid>
  );
}

const CategorySelection = ({
  showCategories,
  categories,
  name,
  label,
  required,
}) => {
  const { handleBlur, handleChange, values, touched, errors } =
    useFormikContext();
  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
      error={touched[name] && !!errors[name]}
    >
      <InputLabel id="categoryLabel">{label}</InputLabel>
      <Select
        labelId="categoryLabel"
        value={values[name] || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        label={label}
        MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
      >
        <MenuItem value="">Ei kategoriaa</MenuItem>
        {(categories || []).map((category) => {
          return (
            <MenuItem
              key={category.id}
              value={category.id}
              data-cy="productDialogCategoryList"
            >
              {category.name}
            </MenuItem>
          );
        })}
      </Select>
      {touched[name] && errors[name] && (
        <FormHelperText>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

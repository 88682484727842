import {
  contractFieldCustomerENG,
  contractFieldAdditionalDriverENG,
  contractFieldCommonENG,
  contractFieldDriverENG,
  contractVehicleENG,
  contractFieldCompanyENG,
  paymentStatusENG,
  contractSummaryENG,
  contractAgreementENG,
} from "./english";
import {
  contractFieldCustomerFIN,
  contractFieldAdditionalDriverFIN,
  contractFieldCommonFIN,
  contractFieldDriverFIN,
  contractVehicleFIN,
  contractFieldCompanyFIN,
  paymentStatusFIN,
  contractSummaryFIN,
  contractAgreementFIN,
} from "./finnish";
import {
  contractAgreementSWE,
  contractFieldAdditionalDriverSWE,
  contractFieldCommonSWE,
  contractFieldCompanySWE,
  contractFieldCustomerSWE,
  contractFieldDriverSWE,
  contractSummarySWE,
  contractVehicleSWE,
  paymentStatusSWE,
} from "./swedish";

import {
  contractAgreementGER,
  contractFieldAdditionalDriverGER,
  contractFieldCommonGER,
  contractFieldCompanyGER,
  contractFieldCustomerGER,
  contractFieldDriverGER,
  contractSummaryGER,
  contractVehicleGER,
  paymentStatusGER,
} from "./german";

export const EAdditionalDriverInfo = {
  FIN: contractFieldAdditionalDriverFIN,
  ENG: contractFieldAdditionalDriverENG,
  SWE: contractFieldAdditionalDriverSWE,
  GER: contractFieldAdditionalDriverGER,
};
export const EDriverInfo = {
  FIN: contractFieldDriverFIN,
  ENG: contractFieldDriverENG,
  SWE: contractFieldDriverSWE,
  GER: contractFieldDriverGER,
};
export const ECustomerInfo = {
  FIN: contractFieldCustomerFIN,
  ENG: contractFieldCustomerENG,
  SWE: contractFieldCustomerSWE,
  GER: contractFieldCustomerGER,
};
export const EOrderDetails = {
  FIN: contractFieldCommonFIN,
  ENG: contractFieldCommonENG,
  SWE: contractFieldCommonSWE,
  GER: contractFieldCommonGER,
};
export const EOrderVehicle = {
  FIN: contractVehicleFIN,
  ENG: contractVehicleENG,
  SWE: contractVehicleSWE,
  GER: contractVehicleGER,
};
export const ECompanyInfo = {
  FIN: contractFieldCompanyFIN,
  ENG: contractFieldCompanyENG,
  SWE: contractFieldCompanySWE,
  GER: contractFieldCompanyGER,
};
export const EPaymentStatus = {
  FIN: paymentStatusFIN,
  ENG: paymentStatusENG,
  SWE: paymentStatusSWE,
  GER: paymentStatusGER,
};
export const EContractAgreement = {
  FIN: contractAgreementFIN,
  ENG: contractAgreementENG,
  SWE: contractAgreementSWE,
  GER: contractAgreementGER,
};
export const EContractSummary = {
  FIN: contractSummaryFIN,
  ENG: contractSummaryENG,
  SWE: contractSummarySWE,
  GER: contractSummaryGER,
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { updatePricing } from "../../../graphql/mutations";
import { useState } from "react";
import { useFetchPricingsOrganization } from "../../../services/fetchPricings";

export default function ConfirmDeleteDialog(props) {
  const { open, onClose, pricing, onCloseDeleted } = props;
  const { pricingsMutate } = useFetchPricingsOrganization("pricingListDialog");
  const [submitting, setSubmitting] = useState(false);
  const onDelete = async (pricingId, onCloseDeleted) => {
    try {
      setSubmitting(true);
      const response = await API.graphql(
        graphqlOperation(updatePricing, {
          input: {
            _removed: true,
            id: pricingId,
          },
        })
      );
      if (response) {
        pricingsMutate();
        onCloseDeleted();
      }
    } catch (error) {
      console.log("Error deleting pricing", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Poistetaanko hinnasto {pricing?.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Oletko varma, että haluat poistaa tämän hinnaston?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peruuta</Button>
        <Button
          onClick={() => onDelete(pricing?.id, onCloseDeleted)}
          color="error"
          variant="contained"
          disabled={submitting}
        >
          Poista
        </Button>
      </DialogActions>
    </Dialog>
  );
}

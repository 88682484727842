type TimeCellsProps = { className?: string };

function TimeCells({ className }: TimeCellsProps) {
  return (
    <div className={className}>
      {Array(24)
        .fill(undefined)
        .map((_, i) => (
          <div key={i}>{`${i}`.padStart(2, "0")}:00</div>
        ))}
    </div>
  );
}

export default TimeCells;

import { Box } from "@mui/material";
import { useFetchAdditionalServices } from "../../../services/fetchAdditionalServices";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { servicePriceField } from "./columns";

export default function AdditionalServicePricingsView(props) {
  const { additionalServices, additionalServicesLoading } =
    useFetchAdditionalServices();
  console.log("additionalServices", additionalServices);
  const rows = additionalServices || [];
  const columns = [servicePriceField];
  return (
    <Box>
      <DataGridPro
        loading={additionalServicesLoading}
        //  getRowId={(row) => row.key}
        sx={{
          //   borderColor: "transparent",
          borderRadius: "14px",
          overflow: "hidden",
          height: "500px",
          /*         fontFamily: "Public Sans, sans-serif",
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        }, */
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "#f7f7f8",
            color: "#4e536c",
            fontFamily: "Sofia Pro",
            fontSize: "16px",
            // boxShadow: theme.shadows[4],
          },
        }}
        rows={rows}
        columns={columns}
        pagination
        pageSize={10}
        rowsPerPageOptions={[2]}
        experimentalFeatures={{ newEditingApi: true }}
        disableRowSelectionOnClick
        disableSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }],
          },
        }}
      />
    </Box>
  );
}

import { format } from "date-fns";
import { fi } from "date-fns/locale";

type DateData = number | Date | string;

export function formatDate(date: DateData) {
  return format(
    typeof date === "string" ? new Date(date) : date,
    "dd.MM.yyyy",
    { locale: fi }
  );
}

export function formatDateTime(date: DateData) {
  return format(
    typeof date === "string" ? new Date(date) : date,
    "dd.MM.yyyy HH:mm",
    { locale: fi }
  );
}

export function formatISODate(date: DateData) {
  return format(
    typeof date === "string" ? new Date(date) : date,
    "yyyy-MM-dd",
    { locale: fi }
  );
}

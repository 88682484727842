import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { Drawer } from "@mui/material";

export default function MobileSideMenu(props) {
  const { openDrawer, handleDrawerToggle, menuOptions, mobileViewPort } = props;
  useContext(AppContext);

  return (
    <Drawer
      /*  container={container} */
      PaperProps={{
        style: {
          position: "absolute",
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderBottomWidth: 0,
          borderRadius: 0,
          WebkitBoxShadow: "none",
          boxShadow: "none",
          padding: "10px",
        },
      }}
      variant={mobileViewPort ? "temporary" : "permanent"}
      anchor="left"
      open={openDrawer}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{ zIndex: 9999 }}
    >
      {menuOptions}
    </Drawer>
  );
}

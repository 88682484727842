import { EInvoiceType } from "../enums/InvoiceType";

export const MODAL_LABEL = {
  [EInvoiceType.FULL]: "Koko varaus",
  [EInvoiceType.AUTOLIITTO]: "Autoliitto",
  [EInvoiceType.COMPENSATION]: "Hyvityslasku",
  [EInvoiceType.EXTRA]: "Jälkilaskutus",
  [EInvoiceType.INSURANCE_COMPANY]: "VAKUUTUS",
  [EInvoiceType.INSURANCE_CUSTOMER]: "VAKUUTUS",
};

import { Grid, MenuItem, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useMemo } from "react";
import FormikInputDate from "~/components/Inputs/FormikInputDate";
import FormikInputSelect from "~/components/Inputs/FormikInputSelect";
import FormikInputText from "~/components/Inputs/FormikInputText";
import { useModalContext } from "../context";

function InvoiceDetails() {
  const { invoiceType } = useModalContext();
  const { values } = useFormikContext<any>();

  const isInsuranceType = ["INSURANCE_COMPANY", "AUTOLIITTO"].includes(
    invoiceType
  );

  const paymentTermValues = useMemo(() => {
    let allowedValues = [7, 14, 21, 30, 60, 90];

    if (invoiceType === "INSURANCE_COMPANY") {
      allowedValues = [30];
    } else if (values.customer_type === "person") {
      allowedValues = [14, 21, 30, 60, 90];
    }

    return allowedValues;
  }, [invoiceType, values.customer_type]);

  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Laskun tiedot
        </Typography>
      </Grid>

      <Field
        name="payment_terms"
        label="Maksuehto"
        component={FormikInputSelect}
        md={4}
      >
        {paymentTermValues.map((value) => {
          return (
            <MenuItem value={value} key={`paymentTerm-${value}`}>
              {value}
            </MenuItem>
          );
        })}
      </Field>
      <Field
        name="delivery_date"
        label="Laskun päiväys"
        component={FormikInputDate}
        md={4}
      />

      {!isInsuranceType && (
        <Field
          name="delivery_by"
          label="Toimitustapa"
          component={FormikInputSelect}
          md={4}
        >
          <MenuItem value="mail">Posti</MenuItem>
          <MenuItem value="email">Sähköposti</MenuItem>
          {values.customer_type === "company" && (
            <MenuItem value="e_invoice">Verkkolasku</MenuItem>
          )}
        </Field>
      )}

      <Field
        name="seller_reference"
        label="Myyjän viite"
        component={FormikInputText}
        md={4}
      />
      <Field
        name="buyer_reference"
        label="Ostajan viite"
        component={FormikInputText}
        md={4}
      />

      {isInsuranceType && (
        <Field
          name="vehicle_class"
          label="Korvattava autoluokka"
          component={FormikInputText}
          md={4}
        />
      )}

      {values.delivery_by === "e_invoice" && (
        <>
          <Field
            name="e_invoice_operator"
            label="Verkkolaskun välitystunnus"
            component={FormikInputText}
            md={4}
          />
          <Field
            name="e_invoice_address"
            label="Verkkolaskuosoite"
            component={FormikInputText}
            md={4}
          />
        </>
      )}

      {/* <Grid mt={2} item xs={12}>
        <Typography> Vuokrapäivät: {"rentalDays"}</Typography>
      </Grid> */}
    </Grid>
  );
}

export default InvoiceDetails;

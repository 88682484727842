import { Box, useTheme } from "@mui/material";
import { useMoveReservation } from "../../hooks/useMoveReservation";
import styles from "./styles.module.scss";
import { Category, Vehicle } from "~/graphql/API";
import CancelIcon from "@mui/icons-material/Cancel";

type RowSelectorProps = { vehicle?: Vehicle; category?: Category };

function RowSelector({ vehicle, category }: RowSelectorProps) {
  const theme = useTheme();
  const { move, isMoving, moveToCategory, moveToVehicle, cancel } =
    useMoveReservation();

  if (!isMoving) {
    return null;
  }

  if (
    (move?.vehicle?.id && move?.vehicle?.id === vehicle?.id) ||
    move?.reservation.wildCardVehicles?.some(
      (wcv) => wcv?.categoryId === category?.id
    )
  ) {
    return (
      <button
        type="button"
        className={styles.root}
        onClick={cancel}
        style={{
          background: theme.palette.error.light,
          opacity: 0.8,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={1}
          color="white"
        >
          <CancelIcon />
          <Box fontWeight="bold">Peruuta</Box>
        </Box>
      </button>
    );
  }

  return (
    <button
      type="button"
      className={styles.root}
      onClick={() => {
        if (vehicle) {
          moveToVehicle(vehicle);
        } else if (category) {
          moveToCategory(category);
        }
      }}
      style={{
        background: theme.palette.primary.light,
      }}
    />
  );
}

export default RowSelector;

import React from "react";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import PhoneNumberField from "../../../../../../components/PhoneNumberField";
import { FormInputField } from "../../../../../../components/ReservationForm/utils/FormInputField";
import { useGetRequiredFields } from "../../../../../../formik/requiredFields";

export default function ShowAdditionalDriverFields() {
  const selectedLanguage = "FIN";
  const { requiredFields } = useGetRequiredFields();
  const { values } = useFormikContext();
  if (values?.additionalDriver)
    return (
      <Grid container item flex={1} spacing={1} xs={12}>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={EFormFields.additionalDriverName}
            label={fieldLabel[selectedLanguage].additionalDriverName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={EFormFields.additionalDriverSSN}
            label={fieldLabel[selectedLanguage].additionalDriverSSN}
            password={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PhoneNumberField
            fieldName={"additionalDriverPhone"}
            required={requiredFields(values).includes("additionalDriverPhone")}
          />
        </Grid>
      </Grid>
    );
}

const EFormFields = {
  additionalDriverName: "additionalDriverName",
  additionalDriverSSN: "additionalDriverSSN",
};

const formFieldsFIN = {
  [EFormFields.additionalDriverName]: "Lisäkuljettajan nimi",
  [EFormFields.additionalDriverSSN]: "Lisäkuljettajan henkilötunnus",
};

const fieldLabel = {
  FIN: formFieldsFIN,
};

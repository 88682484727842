import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import Status from "./components/Status";
import Locations from "./components/Locations";
import { ComponentProps, useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { contextMenuAtom } from "../../../ContextMenu/store";
import { Reservation, Vehicle } from "~/graphql/API";

type ReservationTooltipProps = {
  reservation: Pick<
    Reservation,
    | "startLocation"
    | "returnLocation"
    | "companyName"
    | "name"
    | "startTime"
    | "returnTime"
    | "companyId"
    | "authStatus"
    | "signStatus"
    | "paymentStatus"
  >;
  vehicle?: Vehicle;
} & Pick<ComponentProps<typeof Tooltip>, "children">;

function ReservationTooltip({
  reservation,
  vehicle,
  children,
}: ReservationTooltipProps) {
  const theme = useTheme();

  const contextMenu = useAtomValue(contextMenuAtom);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [contextMenu]);

  return (
    <Tooltip
      open={contextMenu ? false : open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            border: "solid 1px rgba(0,0,0,1)",
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
            color: "rgba(0,0,0,0.65)",
            backgroundColor: "#29293e",
            borderRadius: "10px",
          },
        },
      }}
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              color: "#ffffff",
            }}
          >
            <Typography variant="h6">Varaaja:</Typography>
            <Typography variant="h6" sx={{ marginLeft: "5px" }}>
              {reservation.companyName || reservation.name}
            </Typography>
          </Box>

          {reservation.startTime && reservation.returnTime && (
            <Box>
              <Typography variant="body1" sx={{ color: "#ceced2" }}>
                {format(
                  reservation.startTime as unknown as Date,
                  "dd.MM HH:mm"
                ) +
                  " - " +
                  format(
                    reservation.returnTime as unknown as Date,
                    "dd.MM HH:mm"
                  )}
              </Typography>
            </Box>
          )}

          {reservation.companyId && (
            <Locations reservation={reservation} vehicle={vehicle} />
          )}

          <Status reservation={reservation} vehicle={vehicle} />
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
}

export default ReservationTooltip;

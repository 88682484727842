import { format } from "date-fns";
import {
  calculateReservationTotalPrice,
  translateChannel,
  valueExcludedVAT,
} from "../utils";
import { centsToLocalString } from "../../../../shared/money";
import { customers } from "../../../Reservation/admin/HanselCustomers";

export const hanselRows = (reservationsData, companies) => {
  let productRows = [];

  reservationsData.forEach((reservation, idx) => {
    productRows.push({
      id: reservation.id,
      contractId: reservation?.hanselData?.contractNro,
      subcontractorNumber: "",
      subcontractor: "",
      hanselCustomerId: reservation?.hanselData?.customerId,
      customerOrganizationName: customers.find(
        (customer) => customer.id === reservation?.hanselData?.customerId
      )?.name,
      invoiceId: reservation?.hanselData?.customerInvoiceId,
      invoiceDate: reservation?.hanselData?.customerInvoiceDate
        ? format(
            new Date(reservation?.hanselData?.customerInvoiceDate),
            "dd.MM.yyyy"
          )
        : null,
      hanselProductId: reservation?.hanselData?.hanselClass,
      supplierProductId: reservation?.hanselData?.suplierProductId,
      supplierProductName: reservation?.hanselData?.suplierProductName,
      productQuantity: reservation?.reservationVehicles?.length,
      priceNoVAT: reservation?.reservationVehicles[0]
        ? valueExcludedVAT(reservation?.reservationVehicles[0]?.price)
        : 0,
      hanselPrice: reservation?.reservationVehicles[0]
        ? centsToLocalString(reservation?.reservationVehicles[0]?.price)
        : 0,
      emission: reservation?.hanselData?.additionalInfo2,
      openReservation: reservation?.id,
      mileage: reservation?.hanselData?.additionalInfo1,
      rentalDays: reservation?.hanselData?.additionalInfo3,
      desc: reservation?.hanselData?.desc,

      //  company: companies.find((c) => c.id === reservation?.companyId)?.name,
      additionalServices: reservation?.additionalServices?.map((s) => s.key),
      company: companies
        ? companies.find((c) => c.id === reservation?.companyId)?.name
        : null,
      channel: translateChannel[reservation?.channel] ?? null,
      type: reservation?.type,
    });
  });
  return productRows;
};

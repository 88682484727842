import { isBefore, isValid } from "date-fns";

export const isTimesValid = (startTime, returnTime) => {
  if (
    !startTime ||
    !returnTime ||
    !isValid(startTime) ||
    !isValid(returnTime)
  ) {
    return false;
  }

  if (isBefore(returnTime, startTime)) {
    return false;
  }
  return true;
};

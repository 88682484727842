import { useState } from "react";
import {
  Box,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  Autocomplete,
  Avatar,
  TextField,
} from "@mui/material";
import { Home, LocationOn } from "@mui/icons-material";
import { useCategoryImage } from "~/hooks/useCategoryImage";

export const CustomMenuItem = (props) => {
  const { name, category } = props;
  const theme = useTheme();

  const { image } = useCategoryImage(category);

  return (
    <MenuItem {...props}>
      <Stack
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginX: "10px",
            height: "60px",
            width: "60px",
            bgcolor: "white",
          }}
        >
          <img
            style={{
              objectFit: "scale-down",
              height: "100%",
              width: "100%",
            }}
            alt=""
            src={image}
          />
        </Box>
        <Typography sx={{ marginRight: "15px" }}>
          <b>{props.itemcontent?.class}</b>
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: theme.palette.primary.main }}>
            <b>{name ?? props.itemcontent?.name}</b>
          </Typography>

          <Typography>{props.itemcontent?.exampleModel}</Typography>
        </Box>
      </Stack>
    </MenuItem>
  );
};

export function AutoCompleteComponent(props) {
  const {
    defaultOptions,
    error,
    errorHelperText,
    freeSolo,
    label,
    onChange,
    setValue,
    subTitle,
    value,
  } = props;
  const [pickupLocationInput, setPickupLocationInput] = useState("");

  return (
    <Autocomplete
      freeSolo={freeSolo}
      fullWidth
      value={value ?? ""}
      inputValue={pickupLocationInput || ""}
      options={defaultOptions}
      //  loading={nameAutoCompleteLoading}
      onChange={onChange}
      filterOptions={(options, state) => {
        return options.filter((option) => {
          if (typeof option === "string") {
            if (option.toLowerCase().includes(state?.inputValue.toLowerCase()))
              return true;
            else return false;
          } else {
            if (
              option?.name
                .toLowerCase()
                .includes(state?.inputValue.toLowerCase())
            )
              return true;
            else return false;
          }
        });
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      noOptionsText="Ei tuloksia"
      onInputChange={(event, newInputValue) => {
        setPickupLocationInput(newInputValue);
        setValue(newInputValue);
      }}
      renderOption={(props, option) => {
        /*     const classObj = classes.find(
          (c) => c.productNro === option.productNro
        );
*/
        return (
          <Box {...props} key={option?.id}>
            <Stack
              sx={{
                flexDirection: "row",
                transition: "250ms ease-in-out",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box sx={{ marginX: "10px" }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: option?.locationColor ?? undefined,
                    cursor: "pointer",
                  }}
                >
                  {option?.companyId ? <LocationOn /> : <Home />}
                </Avatar>
              </Box>

              <Stack direction={"column"}>
                <Typography sx={{ fontWeight: 550 }} align="left">
                  {option?.name}
                </Typography>
                <Typography align="left" variant="caption">
                  {subTitle && subTitle}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={error}
          helperText={errorHelperText}
        />
      )}
    />
  );
}

// search for company or pickup location by id
export const findItemById = (user, targetId) => {
  // Search in companies.items
  for (const businessItem of user?.businessByOrg?.items || []) {
    for (const company of businessItem.companies.items || []) {
      if (company.id === targetId) {
        return { ...company, orgBusinessId: businessItem.id };
      }
      if (businessItem?.pickupLocations) {
        for (const pickupLocation of businessItem.pickupLocations) {
          if (pickupLocation.id === targetId) {
            return { ...pickupLocation, orgBusinessId: businessItem.id };
          }
        }
      }
    }
  }

  // Search in pickuplocations
  for (const pickupLocation of user?.businessByOrg?.items || []) {
    if (pickupLocation.pickuplocationstargetId) {
      return { ...pickupLocation, orgBusinessId: user?.orgBusinessId };
    }
  }

  return null; // Return null if no match is found
};

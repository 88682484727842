import { Box, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
// @ts-ignore
import { AppContext } from "~/AppContext";
import { Company, Reservation, Vehicle } from "~/graphql/API";
// @ts-ignore
import { useFetchCompanies } from "~/services/fetchCompanies";

type LocationsProps = {
  reservation: Pick<Reservation, "startLocation" | "returnLocation">;
  vehicle?: Vehicle;
};

function Locations({ reservation, vehicle }: LocationsProps) {
  const theme = useTheme();

  const { user, business } = useContext<any>(AppContext);
  const companies = useFetchCompanies(user?.group, business?.id).companies as
    | Company[]
    | undefined;

  const companyName = companies?.find((c) => c.id === vehicle?.companyId)?.name;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginY: "5px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography variant="body1" sx={{ color: theme.palette.info.light }}>
          Noutopaikka:
        </Typography>
        <Typography
          sx={{ marginLeft: "5px", color: theme.palette.info.light }}
          noWrap
        >
          {reservation.startLocation || companyName}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          color: theme.palette.info.light,
        }}
      >
        <Typography variant="body1">Palautuspaikka:</Typography>
        <Typography
          sx={{ marginLeft: "5px", color: theme.palette.info.light }}
          noWrap
        >
          {reservation.returnLocation || companyName}
        </Typography>
      </Box>
    </Box>
  );
}

export default Locations;

import { getPriceByVehicleIds } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import useSWR from "swr";
import { useMemo } from "react";
import { getPriceByCategoryIds } from "../../graphql/queries";
import { isValid } from "date-fns";

export function useGetOfferPrices(
  startTime,
  returnTime,
  queryResource,
  vehicleIds,
  selectedContractVehicleClass
) {
  const fetchParams = useMemo(() => {
    const fetchPriceByCategory = () =>
      fetchGetPriceByCategoryIds(
        [selectedContractVehicleClass],
        startTime,
        returnTime,
        queryResource?.extBusinessId
      );

    const fetchPriceByVehicle = () =>
      fetcGetOfferPriceById(
        vehicleIds,
        startTime,
        returnTime,
        null,
        queryResource?.extBusinessId
      );

    let key;
    let options;

    if (!isValid(startTime) && !isValid(returnTime)) {
      return [null, fetchPriceByCategory];
    }

    // fetch price by category id for contract customers
    if (selectedContractVehicleClass && queryResource?.extBusinessId) {
      key = [
        [selectedContractVehicleClass],
        startTime,
        returnTime,
        queryResource?.extBusinessId,
      ];
      options = {
        revalidateOnFocus: false,
        //  revalidateIfStale: false,
      };

      return [key, fetchPriceByCategory, options];
      // fetch price by vehicle id
    } else {
      key = [vehicleIds, startTime, returnTime, queryResource?.extBusinessId];
      if (!vehicleIds || !startTime || !returnTime) {
        // Required paramters missing
        key = null;
      }
      options = {
        revalidateOnFocus: false,
        //  revalidateIfStale: false,
      };

      return [key, fetchPriceByVehicle, options];
    }
  }, [
    queryResource?.extBusinessId,
    returnTime,
    startTime,
    selectedContractVehicleClass,
    vehicleIds,
  ]);
  const { data, isLoading } = useSWR(...fetchParams);
  const newPrices = useMemo(() => {
    const result = [];
    if (vehicleIds) {
      vehicleIds.forEach((selectedOffer) => {
        const getPrice = () => {
          if (data) {
            // handle price by category: is an object and the same price goes for all the selected offers
            if (data?.priceBy === "category") {
              return data?.value?.price;
            }
            // handle price by vehicle id: is an array and price is searched by an id
            if (data?.priceBy === "vehicleIds") {
              const price = data?.value.find(
                (offer) => offer.id === selectedOffer
              )?.price;
              return price;
            }
          }
        };
        result.push({ id: selectedOffer, listingPrice: getPrice() });
      });
    }
    return result;
  }, [vehicleIds, data]);
  return { offerPrices: newPrices, offerPricesLoading: isLoading };
}

const fetchGetPriceByCategoryIds = async (
  categoryIds,
  startTime,
  returnTime,
  externalBusinessId
) => {
  let response;

  try {
    response = await API.graphql(
      graphqlOperation(getPriceByCategoryIds, {
        categoryIds,
        startTime,
        returnTime,
        externalBusinessId: externalBusinessId,
      })
    );
  } catch (error) {
    console.warn(error);
  }
  if (response) {
    return {
      priceBy: "category",
      value: response?.data?.getPriceByCategoryIds?.categoryOfferPrices[0],
    };
  }
};

const fetcGetOfferPriceById = async (
  vehicleIds,
  startTime,
  returnTime,
  companyId,
  externalBusinessId
) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getPriceByVehicleIds, {
        vehicleIds,
        startTime,
        returnTime,
        companyId,
        externalBusinessId,
      })
    );
    const newOfferPrices = response.data.getPriceByVehicleIds;
    return {
      priceBy: "vehicleIds",
      value: newOfferPrices.vehicleOfferPrices.map((offer) => offer),
    };
  } catch (e) {
    console.warn(e);
  }
};

import { centsToLocalString } from "../../../shared/money";

export const translateChannel = {
  DEALER_EXTERNAL: "YRITYSASIAKAS",
  DEALER: "YRITTÄJÄ",
  DEALER_ORGANIZATION: "SCREDO",
  CUSTOMER: "ONLINE",
};

export const filteringOptions = {
  resultsByCash: (reservation) =>
    reservation.paymentStatus === "CANCELLED" ||
    !reservation?.paymentStatus ||
    reservation.paymentStatus === "COMPLETED_MANUAL",
  resultsByOnline: (reservation) => reservation.paymentStatus === "COMPLETED",
  resultsByInvoice: (reservation) =>
    reservation.paymentStatus === "PENDING_INVOICE" ||
    reservation.paymentStatus === "COMPLETED_INVOICE",
};

export const calculateReservationTotalPrice = (res) => {
  // handle totalPrice
  const calcVehiclePrices = res?.reservationVehicles?.reduce(
    (accumulator, currentProduct) => {
      const quantity = currentProduct?.quantity ?? 1;

      return accumulator + currentProduct?.price * quantity;
    },
    0
  );

  const calcServicePrices = res?.additionalServices?.reduce(
    (accumulator, currentProduct) => {
      const quantity = currentProduct?.quantity ?? 1;

      return accumulator + currentProduct.price * quantity;
    },
    0
  );

  if (!calcServicePrices && !calcVehiclePrices) {
    return 0;
  }
  return calcVehiclePrices + calcServicePrices;
};

export const calculateVAT = (value) => {
  const tax = 24; // TODO: Read from product, defaults to 24
  const taxValue = tax / 100 + 1;
  const taxPercentage = 1 - 1 / taxValue;
  return value * taxPercentage;
};

export const valueExcludedVAT = (value) => {
  return centsToLocalString(value - calculateVAT(value));
};

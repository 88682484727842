import React from "react";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

export default function SideMenuItem({
  handleSelectTab,
  item,
  selectedProductTab,
}) {
  const { TITLE, ICON, TAB, ACTIVE } = item;

  const itemState = !ACTIVE
    ? "DISABLED"
    : selectedProductTab === TAB
    ? "SELECTED"
    : "ACTIVE";

  const itemStyles = MenuStyles[itemState];

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        backgroundColor: itemStyles.backgroundColor,
        borderRadius: "10px",
        boxShadow: itemStyles.boxShadow,
        cursor: ACTIVE ? "pointer" : undefined,
        minHeight: "75px",
        paddingY: "5px",
        transition: "ease-in-out 150ms",
      }}
      onClick={ACTIVE ? () => handleSelectTab(TAB) : null}
      spacing={1}
    >
      <Box sx={{ color: itemStyles.color }}> {ICON}</Box>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            color: itemStyles.color,
            fontFamily: "Sofia Pro",
            fontSize: "1rem",
          }}
        >
          {TITLE}
        </Typography>
        <Subtitle item={item} />
        {item.BETA && <Chip size="small" label="BETA" color="primary" />}
      </Box>
    </Stack>
  );
}

function Subtitle(props) {
  const { item } = props;
  const { errors } = useFormikContext();
  const activeErrors = [];
  item.FIELD_ERRORS.forEach((key) => {
    if (errors[key]) activeErrors.push({ [key]: errors[key] });
  });
  if (activeErrors.length === 0) {
    return <ValidSubtitle subtitle={item.VALID_MESSAGE} />;
  } else {
    return <ErrorSubtitle errors={activeErrors} />;
  }
}

function ValidSubtitle(props) {
  const { subtitle } = props;
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Typography sx={{ color: "grey" }} variant="caption">
        {subtitle}
      </Typography>
    </Stack>
  );
}

function ErrorSubtitle(props) {
  const { errors } = props;

  return (
    <Stack spacing={1}>
      {errors.map((error, index) => (
        <Typography
          key={`error-${index}-${Object.keys(error)}`}
          sx={{ color: "red" }}
          variant="caption"
        >{`Täytä tämä kenttä: ${Object.values(error)}`}</Typography>
      ))}
    </Stack>
  );
}

const EMenuStyles = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  SELECTED: "SELECTED",
};

const MenuStyles = {
  [EMenuStyles.ACTIVE]: {
    backgroundColor: "transparent",
    color: "#000000",
    subColor: "rgba(0,0,0,0.45)",
  },
  [EMenuStyles.DISABLED]: {
    backgroundColor: "transparent",
    color: "rgba(0,0,0,0.25)",
    subColor: "rgba(0,0,0,0.25)",
  },
  [EMenuStyles.SELECTED]: {
    backgroundColor: "rgba(0,0,0,0.07)",
    border: "1px solid lightgrey",
    boxShadow: "0px 0px 7px 0px rgba(0,0,0,0.35)",
    color: "#000000",
    subColor: "rgba(0,0,0,0.55)",
  },
};

import { useAtomValue, useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { Box, Button } from "@mui/material";

export function DialogFooter() {
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const reservationData = useAtomValue(reservationDataAtom);
  if (typeof reservationData === "object") {
    return (
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        position={"sticky"}
        bottom={0}
        bgcolor={"background.paper"}
        marginTop="10px"
      >
        <Button onClick={() => setEditReservationData(null)}>Sulje</Button>
      </Box>
    );
  } else {
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      position={"sticky"}
      bottom={0}
      bgcolor={"background.paper"}
      marginTop="10px"
      zIndex={99}
    >
      <Button onClick={() => setEditReservationData(null)}>Sulje</Button>
    </Box>;
  }
}

import { Box, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useFormikContext } from "formik";
import { translatedFields } from "../../../formik/fields";
import { useContext } from "react";
import { ReservationContext } from "../../../contextProviders/ReservationContext";

export default function ScheduleInfo(props) {
  const { values } = useFormikContext();
  const { selectedLanguage } = useContext(ReservationContext);
  const startTime = values?.startTime
    ? format(values?.startTime, "dd.MM.yyyy HH:mm")
    : null;
  const returnTime = values?.returnTime
    ? format(values?.returnTime, "dd.MM.yyyy HH:mm")
    : null;

  return (
    <Stack
      direction={"column"}
      width={"100%"}
      marginBottom={"10px"}
      spacing={1}
    >
      <Box>
        <Stack direction={"row"} width={"100%"}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ color: "grey" }}>
              {translatedFields[selectedLanguage]?.startLocation}:
            </Typography>
          </Box>
          <Box>{values?.startLocation}</Box>
        </Stack>
        <Stack direction={"row"} width={"100%"}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ color: "grey" }}>
              {translatedFields[selectedLanguage]?.returnLocation}:
            </Typography>
          </Box>
          <Box>{values?.returnLocation}</Box>
        </Stack>
      </Box>

      <Box>
        <Stack direction={"row"} width={"100%"}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ color: "grey" }}>
              {translatedFields[selectedLanguage]?.startTime}:
            </Typography>
          </Box>
          <Box>{startTime}</Box>
        </Stack>
        <Stack direction={"row"} width={"100%"}>
          <Box sx={{ width: "200px" }}>
            <Typography sx={{ color: "grey" }}>
              {translatedFields[selectedLanguage]?.returnTime}:
            </Typography>
          </Box>

          <Box>{returnTime}</Box>
        </Stack>
      </Box>
    </Stack>
  );
}

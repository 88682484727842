import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const MonthlyChart = ({ data, yAxisMax }) => {
  // group data by review number
  const groupedData = data.reduce((acc, curr) => {
    const reviewNumber = curr.rating;
    acc[reviewNumber] = acc[reviewNumber] ? acc[reviewNumber] + 1 : 1;
    return acc;
  }, {});

  // create data for the chart
  const chartData = Array.from({ length: 11 }, (_, i) => ({
    Rating: i,
    Amount: groupedData[i] || 0,
  }));

  function calculateTickCount(yMin, yMax, maxTicks) {
    const tickSpacing = Math.ceil((yMax - yMin) / maxTicks);
    return Math.floor((yMax - yMin) / tickSpacing) + 1;
  }

  return (
    <ResponsiveContainer width="100%" maxHeight={800} aspect={4.0 / 2.0}>
      <BarChart
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Rating" />
        <YAxis
          domain={[0, yAxisMax]}
          tickCount={calculateTickCount(0, yAxisMax, 10)}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="Amount" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlyChart;

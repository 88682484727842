const contractIdField = {
  field: "contractId",
  headerName: "Sopimusnumero",
  sortable: true,
  type: "number",
  flex: 1,
  minWidth: 200,
};
const subcontractorNameField = {
  field: "subcontrator",
  headerName: "Raportoitavan alihankkijan nimi",
  sortable: true,
  flex: 1,
  minWidth: 200,
};
const subcontractorNumberField = {
  field: "subcontratorNumber",
  headerName: "Raportoitavan alihankkijan nro",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const hanselCustomerId = {
  field: "hanselCustomerId",
  headerName: "Asiakasnumero",
  sortable: true,
  type: "number",
  flex: 1,
  minWidth: 200,
};

const customerOrganizationNameField = {
  field: "customerOrganizationName",
  headerName: "Asiakasorganisaation nimi",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const invoiceIdField = {
  field: "invoiceId",
  headerName: "Asiakkaalle lähetetyn laskun nro",
  sortable: true,
  type: "number",
  flex: 1,
  minWidth: 200,
};

const invoiceDateField = {
  field: "invoiceDate",
  headerName: "Asiakkalle lähetetyn laskun päivämäärä",
  type: "number",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const supplierProductIdField = {
  field: "supplierProductId",
  headerName: "Toimittajan tuotenumero",
  sortable: true,
  type: "number",
  flex: 1,
  minWidth: 200,
};

const hanselProductIdField = {
  field: "hanselProductId",
  headerName: "Hanselin tuotenro",
  sortable: true,
  flex: 1,
  type: "number",
  minWidth: 200,
};

const supplierProductNameField = {
  headerName: "Toimittajan tuotenimi/selite",
  field: "supplierProductName",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const productQuantityField = {
  headerName: "kappalemäärä",
  type: "number",
  field: "productQuantity",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const priceNoVATField = {
  headerName: "Kappalehinta alv 0%",
  type: "number",
  field: "priceNoVAT",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const hanselPriceField = {
  headerName: "Laskutetun tuotteen euromäärä yhteensä",
  field: "hanselPrice",
  type: "number",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const emissionField = {
  headerName: "EU yhd.päästötaso CO2 (WLTP)",
  field: "emission",
  type: "number",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const mileageField = {
  headerName: "Ajetut kilometrit",
  field: "mileage",
  sortable: true,
  type: "number",
  flex: 1,
  minWidth: 200,
};

const rentalDays = {
  headerName: "Vuokraus-vuorokaudet",
  field: "rentalDays",
  type: "number",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

const creditInvoiceId = {
  headerName: "Vapaa esim hyvityslaskun vastaavan laskun numero",
  field: "desc",
  sortable: true,
  flex: 1,
  minWidth: 200,
};

export const hanselColumns = [
  contractIdField,
  subcontractorNumberField,
  subcontractorNameField,
  hanselCustomerId,
  customerOrganizationNameField,
  invoiceIdField,
  invoiceDateField,
  hanselProductIdField,
  supplierProductIdField,
  supplierProductNameField,
  productQuantityField,
  priceNoVATField,
  hanselPriceField,
  emissionField,
  mileageField,
  rentalDays,
  creditInvoiceId,
];

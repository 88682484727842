export const listUsers = `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        orgBusinessId
        externalBusinessId
        role
        name
        email
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getUser = `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      organizationId
      orgBusinessId
      orgBusinessIds
      externalBusinessId
      role
    }
  }
`;

export const userByOrganization = /* GraphQL */ `
  query UserByOrganization(
    $organizationId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOrganization(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        orgBusinessId
        orgBusinessIds
        externalBusinessId
        role
        email
        name
        createdAt
        updatedAt
        externalBusiness {
          id
          organizationId
          type
          name
          insuranceCompensatableTimes
          createdAt
          updatedAt
          __typename
        }
        externalBusinessByOrg {
          nextToken
          __typename
        }
        business {
          id
          organizationId
          group
          name
          createdAt
          updatedAt
          __typename
        }
        businessByOrg {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const userByOrgBusinessId = /* GraphQL */ `
  query UserByOrgBusinessId(
    $orgBusinessId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOrgBusinessId(
      orgBusinessId: $orgBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        orgBusinessId
        orgBusinessIds
        externalBusinessId
        role
        email
        name
        createdAt
        updatedAt
        externalBusiness {
          id
          organizationId
          type
          name
          insuranceCompensatableTimes
          createdAt
          updatedAt
          __typename
        }
        externalBusinessByOrg {
          nextToken
          __typename
        }
        business {
          id
          organizationId
          group
          name
          createdAt
          updatedAt
          __typename
        }
        businessByOrg {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const userByExternalBusiness = /* GraphQL */ `
  query UserByExternalBusiness(
    $externalBusinessId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByExternalBusiness(
      externalBusinessId: $externalBusinessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        orgBusinessId
        externalBusinessId
        role
        email
        name
        createdAt
        updatedAt
        externalBusiness {
          id
          organizationId
          type
          name
          insuranceCompensatableTimes
          createdAt
          updatedAt
          __typename
        }
        externalBusinessByOrg {
          nextToken
          __typename
        }
        business {
          id
          organizationId
          group
          name
          createdAt
          updatedAt
          __typename
        }
        businessByOrg {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

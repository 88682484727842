import React, { useContext, useState } from "react";
import { useFetchExternalReservations } from "~/services/fetchReservations";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/system";
import {
  Typography,
  Box,
  Tooltip,
  IconButton,
  Button,
  Autocomplete,
  TextField,
  Grid,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { Announcement, OpenInNew } from "@mui/icons-material";
import { AppContext } from "~/AppContext";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { useMemo } from "react";
import DialogComponent from "~/components/DialogComponent";
import { API, graphqlOperation } from "aws-amplify";
import { transferReservationToBusiness } from "~/graphql/mutations";
import { useNavigate } from "react-router-dom";
import { useNotification } from "~/components/Notification";
import { pageContainerStyle } from "~/styles/page-container";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";
import { insuranceDataAtom } from "~/stores/insuranceData";
import { devStaging } from "../../features";
export default function PendingReservations() {
  const { user, USER_ADMIN_GENERIC } = useContext(AppContext);
  const {
    externalReservations,
    externalReservationsLoading,
    externalReservationsMutate,
  } = useFetchExternalReservations();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const theme = useTheme();
  const [updatingExternalReservations, setUpdatingExternalReserations] =
    useState(false);
  const [reassignReservation, setReassignReservation] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedTab, setSelectedTab] = useState(ETabs.PENDING_INSURANCE);
  const setEditInsuranceData = useSetAtom(insuranceDataAtom);
  const navigate = useNavigate();
  const notification = useNotification();
  const FEATURE_INSURANCE_CLAIM_BY_BUSINESS_USER =
    !!devStaging(process.env.REACT_APP_ENV) ||
    user?.organizationId === "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1";

  const rows = useMemo(() => {
    if (externalReservations) {
      // First, sort orders by createdAt in descending order
      /*      externalReservations.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ); */

      // Next, prioritize orders with status "EXTERNAL_PENDING_DEALER"
      externalReservations.sort((a, b) => {
        if (
          a.status === "EXTERNAL_PENDING_DEALER" &&
          b.status !== "EXTERNAL_PENDING_DEALER"
        ) {
          return -1;
        } else if (
          a.status !== "EXTERNAL_PENDING_DEALER" &&
          b.status === "EXTERNAL_PENDING_DEALER"
        ) {
          return 1;
        } else {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        }
      });

      const data = externalReservations.map((order, index) => {
        return {
          id: order?.id,
          createdAt: order?.createdAt,
          client: user?.externalBusinessByOrg?.items.find(
            (b) => b.id === order?.orgExternalBusinessId
          )?.name,
          billingPermitCode: order?.insuranceData?.billingPermitCode,
          claimAdjusterEmail: order?.insuranceData?.claimAdjusterEmail,
          providerCompany: order?.insuranceData?.providerCompany,
          customerCarRegistrationPlate: order?.customerCarRegistrationPlate,
          name: order.name,
          status: order?.status,
          channel: order?.channel,
          transferReservation: order?.businessId,
          urgency: order?.insuranceData?.urgent,
          extBusinessId: order?.orgExternalBusinessId,
        };
      });

      return data;
    }
  }, [externalReservations, user?.externalBusinessByOrg]);

  const columns = useMemo(() => {
    const handleOpenReservation = (id) => {
      const reservation = externalReservations.find((res) => res.id === id);

      if (reservation) {
        return setEditReservationData(reservation);
      }

      return null;
    };
    const openReservation = {
      field: "openReservation",
      headerName: " ",
      sortable: false,
      headerAlign: "center",
      width: 80,
      renderCell: (params) => {
        return (
          <Box data-cy="openExternalReservationButton">
            <Tooltip title="Avaa varaus" placement="left">
              <IconButton
                variant="contained"
                onClick={() => {
                  handleOpenReservation(params.row.id);
                }}
              >
                <OpenInNew sx={{ color: theme.palette.success.light }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    };

    const columnsForRecipient = [
      openReservation,
      createdAt,
      urgency,
      billingPermitCode,
      client,
      providerCompany,
      customerInformation,
      channel,
      transferReservation(setReassignReservation, externalReservations),
    ];

    if (USER_ADMIN_GENERIC) {
      return columnsForRecipient;
    }

    return columnsForRecipient;
  }, [
    USER_ADMIN_GENERIC,
    externalReservations,
    theme.palette.success.light,
    navigate,
  ]);

  // Update local state instantly
  const mutateLocalReservations = (reservationId) => {
    console.log("mutatelocalreserataweosin", reservationId);
    let oldReservations = externalReservations;
    let mutateFunction = externalReservationsMutate;

    mutateFunction(
      oldReservations.filter((r) => r.id !== reservationId),
      { revalidate: false }
    );
  };

  const submitReservation = async () => {
    setUpdatingExternalReserations(true);

    let reassignedReservation = null;
    try {
      const reservation = reassignReservation;
      // TODO: These are preventing submitting the reservation

      reservation.orgBusinessId = selectedBusiness.id;

      const response = await API.graphql(
        graphqlOperation(transferReservationToBusiness, {
          reservationId: reservation.id,
          toBusinessId: selectedBusiness.id,
        })
      );
      const result = response.data.transferReservationToBusiness;

      if (result.success) {
        notification.show("Siirto onnistui", "success");
        mutateLocalReservations(reservation.id);
        setReassignReservation(null);
      } else {
        throw new Error();
      }
    } catch (err) {
      notification.show("Siirto ei onnistunut", "error");
      console.log("failed", err);
    }
    setUpdatingExternalReserations(false);
  };

  return (
    <React.Fragment>
      <Stack
        sx={{
          ...pageContainerStyle,
          position: "relative",
          paddingX: "10px",
          paddingBottom: "10px",
        }}
        direction={"column"}
      >
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => setSelectedTab(newValue)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            sx={{ marginBottom: "15px" }}
          >
            {options.map((option) => (
              <Tab
                key={option.VALUE}
                label={option.LABEL}
                value={option.VALUE}
              />
            ))}
          </Tabs>
          {FEATURE_INSURANCE_CLAIM_BY_BUSINESS_USER && (
            <Button onClick={() => setEditInsuranceData(true)}>
              <Typography>Lisää uusi vakuutusyhtiövaraus</Typography>
            </Button>
          )}
        </Stack>

        <Box
          sx={{
            height: 0,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pagination
            loading={externalReservationsLoading}
            pageSize={10}
            rowsPerPageOptions={[2]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            sx={{
              height: 0,
              flexGrow: 1,
              "& .MuiDataGrid-columnHeadersInner": {
                backgroundColor: "#f7f7f8",
                color: "#4e536c",
                fontFamily: "Sofia Pro",
                fontSize: "16px",
              },
            }}
          />
        </Box>
      </Stack>
      <ReassignReservationDialog
        updatingExternalReservations={updatingExternalReservations}
        reassignReservation={reassignReservation}
        setReassignReservation={setReassignReservation}
        submitReservation={submitReservation}
        selectedBusiness={selectedBusiness}
        setSelectedBusiness={setSelectedBusiness}
      />
    </React.Fragment>
  );
}

const createdAt = {
  field: "createdAt",
  headerName: "Luotu",
  minWidth: 140,
  flex: 0.5,
  type: "date",
  valueFormatter: (params) => {
    return format(new Date(params.value), "dd.MM.yyyy HH:mm", {
      locale: fi,
    });
  },
};

const urgency = {
  field: "urgency",
  headerName: "Kiirellinen",
  minWidth: 50,
  flex: 0.2,
  renderCell: (params) => {
    if (params.value) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            justifyContent: "center",

            alignItems: "center",
          }}
        >
          {<Announcement style={{ color: "rgba(230,0,0,1)" }} />}
        </Box>
      );
    }
    return null;
  },
};

const billingPermitCode = {
  field: "billingPermitCode",
  headerName: "Laskutuslupatunnus",
  minWidth: 160,
  flex: 0.5,
};

const providerCompany = {
  field: "providerCompany",
  headerName: "Maksaja",
  minWidth: 320,
  flex: 1,
};

const customerInformation = {
  field: "customerInformation",
  headerName: "Asiakkaan tiedot",
  sortable: false,
  minWidth: 150,
  flex: 0.5,
  renderCell: (params) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography noWrap sx={{ fontSize: "15px" }}>
          {params.row?.name}
        </Typography>
        <Typography variant="caption">
          <b>{params.row?.customerCarRegistrationPlate}</b>
        </Typography>
      </Box>
    );
  },
};

const channel = {
  field: "channel",
  headerName: "Kanava",
  minWidth: 200,
  flex: 0.4,
  renderCell: (params) => {
    if (
      params.row.extBusinessId === "77010970-14c1-4936-9a41-3054522a56a8" &&
      params.value === "AUTOLIITTO"
    ) {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          LT Hätäpalvelu
        </Box>
      );
    }
    if (params.value === "DEALER_EXTERNAL")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Toimeksianto
        </Box>
      );
    if (params.value === "DEALER")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Yrittäjä
        </Box>
      );
    if (params.value === "DEALER_ORGANIZATION")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Organisaatio
        </Box>
      );
    if (params.value === "CUSTOMER")
      return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          Online varaus
        </Box>
      );
  },
};

const transferReservation = (setReassignReservation, externalReservations) => {
  return {
    field: "transferReservation",
    headerName: "",
    minWidth: 100,
    flex: 0.3,
    renderCell: (params) => {
      if (params.row.channel !== "AUTOLIITTO")
        return (
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Button
              onClick={() =>
                setReassignReservation(
                  externalReservations.find((res) => res.id === params.id)
                )
              }
              variant="contained"
              sx={{ maxHeight: "42px", textTransform: "none" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption">Siirrä</Typography>
                <Typography variant="caption">varaus</Typography>
              </Box>
            </Button>
          </Box>
        );
    },
  };
};

const client = {
  field: "client",
  headerName: "Varaaja",
  minWidth: 200,
  flex: 1,
};

function ReassignReservationDialog(props) {
  const {
    updatingExternalReservations,
    reassignReservation,
    setReassignReservation,
    submitReservation,
    selectedBusiness,
    setSelectedBusiness,
  } = props;
  const [searchValue, setSearchValue] = useState();
  const { user } = useContext(AppContext);

  const businesses = useMemo(() => {
    const listBusiness = user?.businessByOrg?.items.filter(
      (b) => reassignReservation?.orgBusinessId !== b.id
    );
    return listBusiness;
  }, [user, reassignReservation]);
  return (
    <DialogComponent
      open={!!reassignReservation}
      dialogClose={
        !updatingExternalReservations
          ? () => setReassignReservation(null)
          : null
      }
      dialogActionText={"Tallenna"}
      dialogTitle={" Toimeksiannon siirto "}
      dialogActionSubmitting={updatingExternalReservations}
      maxWidth={"sm"}
      noMobileFullScreen={true}
    >
      <Grid
        container
        alignItems={"center"}
        sx={{ height: "100%", marginY: "25px", justifyContent: "center" }}
        spacing={2}
      >
        <Grid item xs={11}>
          <Autocomplete
            freeSolo
            fullWidth
            value={selectedBusiness}
            inputValue={searchValue || ""}
            options={businesses}
            //  loading={nameAutoCompleteLoading}

            onInputChange={(event, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option?.name || ""}
            renderOption={(props, option) => {
              return (
                <Box {...props}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      paddingX: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedBusiness(option);
                    }}
                    key={option.id}
                  >
                    <Typography sx={{ fontWeight: 550 }}>
                      {option?.name}
                    </Typography>
                    <Typography>{option?.desc}</Typography>
                    <Typography sx={{ color: "rgba(0,0,0,0.55)" }}>
                      {option?.city}
                    </Typography>
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                //     error={touched.orgBusinessId && !!errors.orgBusinessId}
                //   helperText={touched.orgBusinessId && errors.orgBusinessId}

                label="Valitse kohde"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={11}>
          <Button
            onClick={() => submitReservation()}
            variant="contained"
            fullWidth
            disabled={updatingExternalReservations || !selectedBusiness}
          >
            {updatingExternalReservations ? "Päivitetään..." : "Siirrä varaus"}
          </Button>
        </Grid>
      </Grid>
    </DialogComponent>
  );
}

const ETabs = {
  PENDING_INSURANCE: "PENDING_INSURANCE",
  PENDING_DEALER: "PENDING_DEALER",
  PENDING_EXTERNAL: "PENDING_EXTERNAL",
};

const ETabOptions = {
  [ETabs.PENDING_INSURANCE]: {
    VALUE: ETabs.PENDING_INSURANCE,
    LABEL: "Vakuutustoimeksiannot",
  },
  [ETabs.PENDING_DEALER]: {
    VALUE: ETabs.PENDING_DEALER,
    LABEL: "Yrittäjän keskeneräiset",
  },
  [ETabs.PENDING_EXTERNAL]: {
    VALUE: ETabs.PENDING_EXTERNAL,
    LABEL: "Yritysasiakkaan keskeneräiset",
  },
};

const options = [
  ETabOptions.PENDING_INSURANCE,
  /*   ETabOptions.PENDING_DEALER,
  ETabOptions.PENDING_EXTERNAL, */
];

import React from "react";
import { Grid, Button } from "@mui/material";
import { useFormikContext } from "formik";
import { FormInputField } from "../../../../../../../components/ReservationForm/utils/FormInputField";
import CompanyCustomerNameField from "./CompanyCustomerNameField";
import CompanyCustomerBusinessIdField from "./CompanyCustomerBusinessIdField";

export default function ShowCompanyCustomerFields() {
  const selectedLanguage = "FIN";
  const { values } = useFormikContext();

  if (!values?.isCompany) {
    return null;
  }

  return (
    <Grid item container spacing={1}>
      <Grid item xs={12} md={6}>
        <CompanyCustomerNameField />
      </Grid>
      <Grid item xs={12} md={6}>
        <CompanyCustomerBusinessIdField />
      </Grid>

      {!values?.isBroker && (
        <>
          <Grid item xs={12} md={6}>
            <FormInputField
              name="companyCity"
              label={FieldLabel[selectedLanguage].companyCity}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputField
              name="companyPostalCode"
              label={FieldLabel[selectedLanguage].companyPostalCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputField
              name="companyBillingAddress"
              label={FieldLabel[selectedLanguage].companyBillingAddress}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormInputField
              name="companyEBillingOperator"
              label={FieldLabel[selectedLanguage].companyEBillingOperator}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormInputField
              name="companyEBillingAddress"
              label={FieldLabel[selectedLanguage].companyEBillingAddress}
            />
            <Button
              component="a"
              href="https://www.verkkolaskuosoite.fi"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                mt: { xs: 2, sm: 0 },
                width: { xs: "100%", sm: "auto" },
                textTransform: "none",
              }}
            >
              Tarkasta verkkolaskutusosoite
            </Button>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={values?.isBroker ? 12 : 6}>
        <FormInputField
          name="companyBillingRef"
          label={FieldLabel[selectedLanguage].companyBillingRef}
        />
      </Grid>
    </Grid>
  );
}

const EFormFields = {
  COMPANYNAME: "companyName",
  BUSINESS_ID: "businessId",
  COMPANY_CITY: "companyCity",
  COMPANY_POSTAL_CODE: "companyPostalCode",
  BILLING_ADDRESS: "companyBillingAddress",
  BILLING_REFERENCE: "companyBillingRef",
  E_BILLING_OPERATOR: "companyEBillingOperator",
  COMPANY_BILLING_ADDRESS: "companyBillingAddress",
  E_BILLING_ADDRESS: "companyEBillingAddress",
};

const formFieldsFIN = {
  [EFormFields.COMPANYNAME]: "Yrityksen nimi",
  [EFormFields.BUSINESS_ID]: "Y-tunnus",
  [EFormFields.COMPANY_CITY]: "Yrityksen postitoimipaikka",
  [EFormFields.COMPANY_POSTAL_CODE]: "Yrityksen postinumero",
  [EFormFields.COMPANY_BILLING_ADDRESS]: "Laskutusosoite",
  [EFormFields.BILLING_REFERENCE]: "Laskun viite",
  [EFormFields.E_BILLING_OPERATOR]: "Verkkolaskuoperaattori",
  [EFormFields.E_BILLING_ADDRESS]: "Verkkolaskutusosoite",
};

const formFieldsENG = {
  [EFormFields.COMPANYNAME]: "Company name",
  [EFormFields.BUSINESS_ID]: "Business ID",
  [EFormFields.COMPANY_CITY]: "Company's postal city",
  [EFormFields.COMPANY_POSTAL_CODE]: "Company's ZIP code",
  [EFormFields.COMPANY_BILLING_ADDRESS]: "Billing address",
  [EFormFields.BILLING_REFERENCE]: "Billing reference",
  [EFormFields.E_BILLING_OPERATOR]: "E-billing operator",
  [EFormFields.E_BILLING_ADDRESS]: "E-billing address",
};

const formFieldsSWE = {
  [EFormFields.COMPANYNAME]: "Företagets namn",
  [EFormFields.BUSINESS_ID]: "FO-nummer",
  [EFormFields.COMPANY_CITY]: "Företagets postort",
  [EFormFields.COMPANY_POSTAL_CODE]: "Företagets postnummer",
  [EFormFields.COMPANY_BILLING_ADDRESS]: "Faktureringsadress",
  [EFormFields.BILLING_REFERENCE]: "Fakturareferens",
  [EFormFields.E_BILLING_OPERATOR]: "E-fakturaoperatör",
  [EFormFields.E_BILLING_ADDRESS]: "E-fakturaadress",
};

const FieldLabel = {
  FIN: formFieldsFIN,
  ENG: formFieldsENG,
  SWE: formFieldsSWE,
};

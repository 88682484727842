//@ts-ignore
import { centsToLocalString } from "../../../shared/money";
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  EContractSummary,
  EOrderVehicle,
} from "./LanguagePack/translatedFields";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import {
  AdditionalService,
  AdditionalServiceReservation,
  Reservation,
  ReservationVehicle,
  Vehicle,
} from "~/graphql/API";
//@ts-ignore
import { useFetchVehicles } from "~/services/fetchVehicles";
//@ts-ignore
import { useFetchAdditionalServices } from "~/services/fetchAdditionalServices";
import { settingsPreviewVehicleData } from "./settings-preview-vehicle-data";

export default function ContractItemsBreakdown({
  selectedLanguage,
  contractSettings,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const fields =
    EContractSummary[selectedLanguage as keyof typeof EContractSummary];
  const reservation = useAtomValue(reservationDataAtom);
  //@ts-ignore

  //@ts-ignore
  if (!reservation?.reservationVehicles) return null;
  //@ts-ignore

  return (
    <Box sx={{ marginTop: "10px" }}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: "none",
          },
          margin: 0,
        }}
        size="small"
      >
        <TableHead
          sx={{
            borderTop: "2px solid lightgrey",
            borderBottom: " 2px solid lightgrey",
          }}
        >
          <TableRow>
            <TableCell sx={{ width: "40%", ...cellStyles }}>
              <Typography sx={headerTextStyles}>{fields.PRODUCT}</Typography>
            </TableCell>
            <TableCell align="right" sx={cellStyles}>
              <Typography sx={headerTextStyles}>{fields.QUANTITY}</Typography>
            </TableCell>
            <PriceColumns
              fields={fields}
              visibility={!!contractSettings.showPrices}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <ReservationProducts
            reservation={reservation}
            contractSettings={contractSettings}
            selectedLanguage={selectedLanguage}
          />
          <ReservationAdditionalServices
            reservation={reservation}
            contractSettings={contractSettings}
            selectedLanguage={selectedLanguage}
          />
          <ReservationTotalPrices
            reservation={reservation}
            selectedLanguage={selectedLanguage}
            contractSettings={contractSettings}
          />
        </TableBody>
      </Table>
    </Box>
  );
}
const headerTextStyles = {
  fontWeight: "bolder",
  fontSize: "12px",
  paddingY: "2px",
  margin: "0",
};

const cellStyles = {
  padding: "2px",
};

const fontSize = { fontSize: "12px" };

const PriceColumns = ({
  visibility,
  fields,
}: {
  visibility: boolean;
  fields: any;
}) => {
  if (visibility)
    return (
      <>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.PRICE}</Typography>
        </TableCell>

        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.VAT_EXCLUDED}</Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.VAT}</Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={headerTextStyles}>{fields.TOTAL}</Typography>
        </TableCell>
      </>
    );
};

const PriceRows = ({
  visibility,
  price,
  tax,
}: {
  price: number | null | undefined;
  tax: number | null | undefined;
  visibility: boolean;
}) => {
  if (visibility)
    return (
      <>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>{centsToLocalString(price)} €</Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {centsToLocalString(taxlessPrice(price, tax))} €
          </Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>
            {`${centsToLocalString(taxOfPrice(price, tax))}€ (${tax ?? 24}%)`}
          </Typography>
        </TableCell>
        <TableCell align="right" sx={cellStyles}>
          <Typography sx={fontSize}>{centsToLocalString(price)} €</Typography>
        </TableCell>
      </>
    );
};

function VehicleRow({
  item,
  contractSettings,
  selectedLanguage,
}: {
  item: ReservationVehicle;
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
}) {
  const { vehicleData } = useFetchVehicles();
  const itemData = getVehicle(item?.id, vehicleData);
  const nameField =
    contractSettings.contractProductTitle === "vehicle"
      ? itemData?.name
      : `${itemData?.name} ${
          EOrderVehicle[selectedLanguage as keyof typeof EOrderVehicle]
            .VEHICLE_OR_EQUIVALENT_LABEL
        }`;

  return (
    <TableRow>
      <TableCell sx={cellStyles}>
        <Typography sx={fontSize}>{nameField}</Typography>
      </TableCell>
      <TableCell sx={cellStyles} align="right">
        <Typography sx={fontSize}>
          {
            //@ts-ignore
            item?.quantity ?? 1
          }
        </Typography>
      </TableCell>
      <PriceRows
        visibility={!!contractSettings?.showPrices}
        price={item?.price}
        tax={item?.tax}
      />
    </TableRow>
  );
}

function ReservationTotalPrices({
  selectedLanguage,
  contractSettings,
  reservation,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
  reservation: Reservation | true;
}) {
  const fields =
    EContractSummary[selectedLanguage as keyof typeof EContractSummary];
  const totalPrice = getTotalPriceOfReservation(reservation);
  const totalTaxlessPrice = getTaxlessTotalAmount(reservation);
  const totalAmountOfTax = getTotalAmountOfTax(reservation);
  if (!contractSettings?.showPrices) return null;
  return (
    <TableRow sx={{ borderTop: "2px solid lightgrey" }}>
      <TableCell sx={cellStyles} />
      <TableCell sx={cellStyles} />
      <TableCell sx={cellStyles}>
        <Typography sx={headerTextStyles} align="right">
          {fields.TOTAL}
        </Typography>
      </TableCell>
      <TableCell align="right" sx={cellStyles}>
        <Typography sx={headerTextStyles}>
          {centsToLocalString(totalTaxlessPrice)} €
        </Typography>
      </TableCell>
      <TableCell align="right" sx={cellStyles}>
        <Typography sx={headerTextStyles}>
          {centsToLocalString(totalAmountOfTax)}€
        </Typography>
      </TableCell>
      <TableCell align="right" sx={cellStyles}>
        <Typography sx={headerTextStyles}>
          {centsToLocalString(totalPrice)} €
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function ReservationProducts({
  reservation,
  contractSettings,
  selectedLanguage,
}: {
  reservation: Reservation | true;
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
}) {
  //@ts-ignore
  if (!reservation?.reservationVehicles) return null;
  //@ts-ignore
  return reservation?.reservationVehicles
    ?.filter(
      (item: ReservationVehicle): item is ReservationVehicle => item !== null
    )
    .map((item: ReservationVehicle) => (
      <VehicleRow
        key={item?.id}
        item={item}
        contractSettings={contractSettings}
        selectedLanguage={selectedLanguage}
      />
    ));
}

function ReservationAdditionalServices({
  reservation,
  contractSettings,
  selectedLanguage,
}: {
  reservation: Reservation | true;
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
}) {
  console.log("TODO: add selectedLanguage to services", selectedLanguage);

  const { additionalServices: additionalServicesData } =
    useFetchAdditionalServices();
  //@ts-ignore
  const additionalServices = reservation?.additionalServices;
  if (!additionalServices) return null;

  return additionalServices?.map((item: AdditionalServiceReservation) => {
    const serviceData = additionalServicesData?.find(
      (service: AdditionalService) =>
        //@ts-ignore
        service?.key === item?.key
    );

    return (
      <ServiceRow
        key={item?.key}
        item={item}
        serviceDescription={serviceData?.description}
        quantity={item?.quantity}
        contractSettings={contractSettings}
        selectedLanguage={selectedLanguage}
      />
    );
  });
}

function ServiceRow({
  item,
  quantity,
  contractSettings,
  selectedLanguage,
}: {
  item: AdditionalServiceReservation;
  serviceDescription: string;
  quantity: number | null | undefined;
  contractSettings: Record<string, string | boolean>;
  selectedLanguage: string;
}) {
  const { additionalServices: additionalServicesData } =
    useFetchAdditionalServices();
  const serviceData =
    additionalServicesData?.find(
      (service: AdditionalService) =>
        //@ts-ignore
        service?.key === item?.key
    ) ?? item;
  const getFixedLanguage = () => {
    if (selectedLanguage === "SWE") {
      return "sv";
    }
    if (selectedLanguage === "ENG") {
      return "en";
    }
    return "fi";
  };

  const language = getFixedLanguage();
  return (
    <TableRow>
      <TranslatedServiceDescription
        //@ts-ignore
        serviceData={serviceData}
        selectedLanguage={language}
      />

      <TableCell align="right" sx={cellStyles}>
        <Typography sx={fontSize}>{quantity ?? 1}</Typography>
      </TableCell>
      <PriceRows
        price={item?.price}
        tax={item?.tax}
        visibility={!!contractSettings.showPrices}
      />
    </TableRow>
  );
}

function TranslatedServiceDescription({
  serviceData,
  selectedLanguage,
}: {
  serviceData: any;
  selectedLanguage: string;
}) {
  return (
    <TableCell sx={cellStyles}>
      <Typography sx={fontSize}>
        {serviceData?.descriptionT?.[selectedLanguage] ??
          serviceData?.description ??
          "Service description missing"}
      </Typography>
    </TableCell>
  );
}

const getTotalPriceOfReservation = (reservation: Reservation | true) => {
  //@ts-ignore
  const products = reservation?.reservationVehicles?.reduce(
    (total: any, vehicle: any) => {
      return total + vehicle.price;
    },
    0
  );
  //@ts-ignore
  const services = reservation?.additionalServices?.reduce(
    (total: any, service: any) => {
      return total + service.price;
    },
    0
  );

  const result = products + services;
  return result;
};

const getTaxlessTotalOfServices = (reservation: Reservation | true) => {
  //@ts-ignore
  const taxByServices = reservation?.additionalServices?.reduce(
    (total: any, service: any) => {
      return total + getTaxlessPrice(service.price, service.tax);
    },
    0
  );
  return taxByServices;
};
const getTaxlessTotalOfProducts = (reservation: Reservation | true) => {
  //@ts-ignore
  const taxByProducts = reservation?.reservationVehicles?.reduce(
    (total: any, vehicle: any) => {
      return total + getTaxlessPrice(vehicle.price, vehicle.tax);
    },
    0
  );
  return taxByProducts;
};

const getTaxlessTotalAmount = (reservation: Reservation | true) => {
  const products = getTaxlessTotalOfProducts(reservation);
  const services = getTaxlessTotalOfServices(reservation);

  const result = products + services;
  return result;
};

const getVehicle = (id: string | undefined | null, vehicleData: Vehicle[]) => {
  if (!id) return null;
  if (id === "settings-preview-reservation-vehicle") {
    return settingsPreviewVehicleData;
  }

  return vehicleData?.find((v: any) => v.id === id);
};

const getTaxlessPrice = (price: any, tax: any) => {
  const taxValue = tax ?? 24;
  return price / (taxValue / 100 + 1);
};

const getTotalAmountOfTax = (reservation: Reservation | true) => {
  //@ts-ignore
  const products = reservation?.reservationVehicles?.reduce(
    (total: any, vehicle: any) => {
      return total + taxOfPrice(vehicle.price, vehicle.tax);
    },
    0
  );
  //@ts-ignore
  const services = reservation?.additionalServices?.reduce(
    (total: any, service: any) => {
      return total + taxOfPrice(service.price, service.tax);
    },
    0
  );

  const result = products + services;
  return result;
};

const taxOfPrice = (price: any, tax: any) => {
  var taxValue = tax ?? 24;
  taxValue = taxValue / 100 + 1;

  const taxPercentage = 1 - 1 / taxValue;
  return price * taxPercentage;
};

const taxlessPrice = (price: any, tax: any) => {
  const taxValue = tax ?? 24;
  return price / (taxValue / 100 + 1);
};

import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import { listVouchers } from "../graphql/queries";
import captureError from "../utils/capture-error";
import { useContext } from "react";
import { AppContext } from "../AppContext";

const fetch = async (operation) => {
  try {
    const vouchers = await listAll(graphqlOperation(listVouchers), operation);
    return vouchers;
  } catch (e) {
    console.log("Get vouchers failed", "GET_VOUCHERS_FAILED", e);
    captureError("Get vouchers failed", "GET_VOUCHERS_FAILED", e);
  }
};

export function useFetchVouchers(user, businessId) {
  const condition = user;
  const operation = "listVouchers";
  const { data, error, isLoading, mutate } = useSWR(
    condition ? [operation, condition] : null,
    () => fetch(operation),
    {
      revalidateOnFocus: false,
    }
  );

  const voucherData = data
    ? data.map((voucher) => {
        const startDate = voucher?.startDate
          ? new Date(voucher?.startDate)
          : null;

        const endDate = voucher?.endDate ? new Date(voucher?.endDate) : null;

        return { ...voucher, startDate, endDate };
      })
    : [];

  return {
    vouchers: voucherData,
    vouchersLoading: isLoading,
    vouchersError: error,
    vouchersMutate: mutate,
  };
}

export function useContextFetchVouchers() {
  const { user, business } = useContext(AppContext);
  return useFetchVouchers(user, business?.id);
}

import React, { useState, useEffect, useContext } from "react";
import { TextField, Box, Autocomplete, Grid } from "@mui/material";
import parsePhoneNumberFromString, {
  getCountryCallingCode,
  AsYouType,
} from "libphonenumber-js";
import metadata from "libphonenumber-js/metadata.min.json";
import { Metadata } from "libphonenumber-js";
import { AppContext } from "../AppContext";

const metadataInstance = new Metadata(metadata);

const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

const countryList = [
  { value: "FI", countryCode: "+358", emoji: getFlagEmoji("FI") },
  { value: "EE", countryCode: "+372", emoji: getFlagEmoji("EE") },
  { value: "SE", countryCode: "+46", emoji: getFlagEmoji("SE") },
  { value: "NO", countryCode: "+47", emoji: getFlagEmoji("NO") },
  { value: "DK", countryCode: "+45", emoji: getFlagEmoji("DK") },
  { value: "RU", countryCode: "+7", emoji: getFlagEmoji("RU") },
];
const PhoneNumberField = ({
  defaultCountryCode = "FI",
  required,
  translation,
  formik,
  fieldConfig,
  separateDriver,
  ...props
}) => {
  const { setFieldValue, values, errors, touched, handleBlur } = formik;
  const { USER_ADMIN_INSURANCE } = useContext(AppContext);

  const [country, setCountry] = useState(
    countryList.find((c) => c.value === defaultCountryCode)
  );

  const [customCountryList, setCustomCountryList] = useState([...countryList]);
  const [inputValue, setInputValue] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");

  const ADD_NEW_OPTION = {
    value: "ADD_NEW",
    countryCode: "Lisää uusi",
    emoji: "➕",
  };

  useEffect(() => {
    const phoneNumber = values[fieldConfig.name];
    if (phoneNumber && phoneNumber.trim() !== "") {
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      if (parsedNumber) {
        setNationalNumber(parsedNumber.nationalNumber);
      } else {
        setNationalNumber(phoneNumber);
      }
    } else {
      setNationalNumber("");
    }
  }, [values.phone, fieldConfig.name, values]);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      if (newValue.value === ADD_NEW_OPTION.value) {
        setInputValue("");
        setTimeout(() => {
          event.target.focus();
        }, 0);
      } else {
        setCountry(newValue);
        setFieldValue(fieldConfig.name, nationalNumber);
        setFieldValue(fieldConfig.name + "CountryCode", newValue.countryCode);

        // Clear the input value if the new country is in the default countryList
        if (countryList.some((c) => c.value === newValue.value)) {
          setInputValue("");
        }
      }
    }
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const addCustomCountry = () => {
    if (inputValue.startsWith("+") && !isNaN(inputValue.slice(1))) {
      const formatter = new AsYouType();
      formatter.input(inputValue);

      let countryCode = formatter.country;
      if (!countryCode) {
        // Find the country code using the Metadata instance
        const callingCode = inputValue.slice(1);
        countryCode =
          metadataInstance.getCountryCodeForCallingCode(callingCode);
      }

      if (countryCode) {
        const callingCodeValue = `+${getCountryCallingCode(countryCode)}`;
        const flagEmoji = getFlagEmoji(countryCode);

        // Check if the country already exists in the customCountryList
        const existingCountry = customCountryList.find(
          (c) => c.value === countryCode
        );
        if (!existingCountry) {
          const customCountry = {
            value: countryCode,
            countryCode: callingCodeValue,
            emoji: flagEmoji,
          };

          setCustomCountryList([...customCountryList, customCountry]);
        }

        setCountry({
          value: countryCode,
          countryCode: callingCodeValue,
          emoji: flagEmoji,
        });
        setInputValue("");
        setFieldValue(fieldConfig.name, nationalNumber);
        setFieldValue(fieldConfig.name + "CountryCode", callingCodeValue);
      }
    }
  };

  const handleAddCustomCountry = (event) => {
    if (event.key === "Enter") {
      addCustomCountry();
    }
  };

  useEffect(() => {
    const initialPhoneNumber = values[fieldConfig.name] || "";
    const parsedNumber = parsePhoneNumberFromString(initialPhoneNumber);

    if (parsedNumber) {
      const { countryCallingCode, nationalNumber } = parsedNumber;
      setNationalNumber(nationalNumber);

      let countryCode = parsedNumber.country;
      let flagEmoji = "";

      if (!countryCode) {
        // Find the country code using the Metadata instance
        countryCode =
          metadataInstance.getCountryCodeForCallingCode(countryCallingCode);
        // When countryCode is still undefined, assign empty string to it
        countryCode = countryCode ? countryCode : "";
      }

      if (countryCode) {
        flagEmoji = getFlagEmoji(countryCode);
      }

      const callingCodeValue = `+${countryCallingCode}`;

      // Check if the country already exists in the customCountryList
      const existingCountry = customCountryList.find(
        (c) => c.value === countryCode
      );

      if (!existingCountry) {
        const customCountry = {
          value: countryCode,
          countryCode: callingCodeValue,
          emoji: flagEmoji,
        };

        setCustomCountryList((prevList) => [...prevList, customCountry]);
      }

      setFieldValue(fieldConfig.name, parsedNumber.nationalNumber);
      setFieldValue(fieldConfig.name + "CountryCode", callingCodeValue);

      setCountry({
        value: countryCode,
        countryCode: callingCodeValue,
        emoji: flagEmoji,
      });
    } else {
      setNationalNumber(initialPhoneNumber);
    }
  }, [values[fieldConfig.name]]);

  useEffect(() => {
    const initialPhoneNumber = values[fieldConfig.name] || "";
    const parsedNumber = parsePhoneNumberFromString(initialPhoneNumber);

    if (!parsedNumber) {
      setFieldValue(fieldConfig.name + "CountryCode", "+358");
    }
  }, []);

  const handlePhoneNumberChange = (event) => {
    const phoneNumber = event.target.value;
    setNationalNumber(phoneNumber);
    setFieldValue(fieldConfig.name, phoneNumber);
  };

  // Take out css that has color: white etc.
  const { InputProps, ...params } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4} md={6} lg={5}>
        <Autocomplete
          value={country}
          style={{ minWidth: "auto" }}
          disableClearable
          inputValue={inputValue}
          required={required}
          onInputChange={handleInputChange}
          onChange={handleCountryChange}
          options={[...customCountryList, ADD_NEW_OPTION]}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => `${option.emoji} (${option.countryCode})`}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Box display="flex" alignItems="center">
                <Box>{option.emoji}</Box>
                <Box
                  ml={1}
                  data-cy={
                    separateDriver
                      ? "newReservationDialogDriverCountryCodeOption"
                      : "newReservationDialogCountryCodeOption"
                  }
                >{`${option.countryCode}`}</Box>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Suuntanro"
              style={{ marginRight: 8, minWidth: "auto" }} // Add minWidth here
              onKeyDown={handleAddCustomCountry}
              onBlur={addCustomCountry}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {params.InputProps.startAdornment}
                  </React.Fragment>
                ),
              }}
              data-cy={
                separateDriver
                  ? "newReservationDialogDriverCountryCode"
                  : "newReservationDialogCountryCode"
              }
            />
          )}
          renderTags={() => null}
        />
      </Grid>
      <Grid item xs={8} md={6} lg={7}>
        <TextField
          error={touched[fieldConfig.name] && !!errors[fieldConfig.name]}
          helperText={touched[fieldConfig.name] && errors[fieldConfig.name]}
          value={nationalNumber}
          onChange={handlePhoneNumberChange}
          onBlur={() => handleBlur(props.name)}
          required={
            fieldConfig.name === "additionalDriverPhone" && USER_ADMIN_INSURANCE
              ? required
              : false
          }
          label={fieldConfig.label}
          {...params}
          data-cy={
            separateDriver
              ? "newReservationDialogDriverPhone"
              : "newReservationDialogPhoneField"
          }
        />
      </Grid>
    </Grid>
  );
};

export default PhoneNumberField;

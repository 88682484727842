import {
  enableContractCustomers,
  enableWildCardReservations,
} from "../../../features";

export const EMenuItems = {
  TITLE: "TITLE",
  SUBTITLE: "SUBTITLE",
  ICON: "ICON",
  VISIBILITY: "VISIBILITY",
  VISIBILITY_VALUE: "VISIBILITY_VALUE",
  VISIBILITY_FIELD: "VISIBILITY_FIELD",
  ACTIVE: "ACTIVE",
  TAB: "TAB",
  FIELD_ERRORS: "FIELD_ERRORS",
  VALID_MESSAGE: "VALID_MESSAGE",
  COMPONENT: "COMPONENT",
  BETA: "BETA",
};

const EFeatures = {
  ENABLE_CONTRACT_CUSTOMER: "ENABLE_CONTRACT_CUSTOMER",
  ENABLE_WILDCARD_RESERVATIONS: "ENABLE_WILDCARD_RESERVATIONS",
};

export const Features = {
  [EFeatures.ENABLE_CONTRACT_CUSTOMER]: (user) => enableContractCustomers(user),
  [EFeatures.ENABLE_WILDCARD_RESERVATIONS]: (organizationId) =>
    enableWildCardReservations(organizationId),
};

export const EServiceCategories = {
  NORMAL: "normal",
  DEDUCTIBLE: "deductible",
  DISTANCE: "distance",
  EXTERNAL: "external", //deprecated (use INSURANCE instead)
  INSURANCE: "insurance",
  SCANDIARENT: "scandiaRent",
  EXPENDABLE: "expendable",
};

export const vehicleClasses = [
  { type: "A", id: 1 },
  { type: "B", id: 35 },
  { type: "C", id: 3 },
  { type: "C automaatti", id: 19 },
  { type: "C farmari", id: 40 },
  { type: "P", id: 13 },
  { type: "X", id: 53 },
  //{ type: "X automaatti", id: 7 },
];

export const vehicleClassInternal = [
  { type: "A", id: 1 },
  { type: "B", id: 35 },
  { type: "C", id: 3 },
  { type: "P", id: 13 },
  { type: "X", id: 53 },
  //{ type: "X automaatti", id: 7 },
];

import React from "react";
import { Stack } from "@mui/material";

import EventsContainer from "./components/EventContainer";
import Departures from "./components/Departures";
import Arrivals from "./components/Arrivals";

export default function DayEventsDialogMobileView({
  loading,
  departures,
  arrivals,
}) {
  return (
    <Stack direction={"column"} spacing={1} sx={{ marginTop: "20px" }}>
      <EventsContainer event={"Lähtevät"} loading={loading}>
        <Departures departures={departures} />
      </EventsContainer>
      <EventsContainer event={"Tulevat"} loading={loading}>
        <Arrivals arrivals={arrivals} />
      </EventsContainer>
    </Stack>
  );
}

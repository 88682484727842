import { Box, Typography, Skeleton, Stack } from "@mui/material";

import React, { useContext, useState } from "react";

import { format } from "date-fns";
import { AppContext } from "../../../AppContext";
import { useDebouncedEffect } from "../../../utils/use-debounced-effect";

import { fi } from "date-fns/locale";
import { useSearchCustomers } from "../hooks/useSearchCustomers";

export function SearchResultsCustomers(props) {
  const {
    searchInput,
    queryResults,
    setSelectedCustomer,
    setSearchInput,
    selectedCustomer,
  } = props;
  const { USER_ADMIN_INSURANCE } = useContext(AppContext);
  const [input, setInput] = useState(searchInput);
  const { listOfCustomers, listOfCustomersLoading } = useSearchCustomers(input);

  useDebouncedEffect(
    () => {
      setInput(searchInput);
    },
    250,
    [searchInput]
  );

  if (listOfCustomersLoading) {
    return (
      <Box sx={{ marginY: "10px" }}>
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={24}
          sx={{ margin: "2px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={15}
          sx={{ margin: "2px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={15}
          sx={{ margin: "2px" }}
        />
      </Box>
    );
  }
  if (listOfCustomers.length === 0 && searchInput.length > 3) {
    return "Ei hakutuloksia";
  }
  if (selectedCustomer) {
    return (
      <Stack
        width={"100%"}
        sx={{
          border: "1px solid lightgrey",
          padding: "10px",
          borderRadius: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box>
          <Typography>{selectedCustomer.name}</Typography>
          <Typography>{selectedCustomer?.email}</Typography>
        </Box>
      </Stack>
    );
  } else {
    return listOfCustomers.map((item, idx) => {
      if (USER_ADMIN_INSURANCE) {
        return (
          <Box
            key={item.name + idx}
            sx={{
              display: "flex",
              cursor: "pointer",
              borderBottom:
                idx + 1 !== queryResults.length
                  ? "1px solid rgba(0,0,0,0.07)"
                  : undefined,
              padding: "10px",

              width: "100%",
              height: "100px",
              marginY: "5px",
            }}
            onClick={() => {
              setSelectedCustomer({
                name: item.name,
                email: item?.email,
                companyName: item?.companyName,
              });
              setSearchInput(item.name);
            }}
          >
            <Box
              sx={{
                display: "flex",
                transition: "150ms ease-in-out",
                justifyContent: "center",
                flexDirection: "column",

                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    color: "black",
                    fontWeight: 550,
                  }}
                >
                  {item?.insuranceData?.billingPermitCode}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  {format(new Date(item?.createdAt), "dd.MM.yyyy HH:mm", {
                    locale: fi,
                  })}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "16px",
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  color: "grey",
                }}
              >
                {item?.customerCarRegistrationPlate}
              </Typography>
            </Box>
          </Box>
        );
      }
      return (
        <Box
          key={item.name + idx}
          sx={{
            display: "flex",
            cursor: "pointer",
            borderBottom:
              idx + 1 !== queryResults.length
                ? "1px solid rgba(0,0,0,0.07)"
                : undefined,
            padding: "10px",

            width: "100%",
            height: "66px",
            marginY: "5px",
          }}
          onClick={() => {
            setSelectedCustomer({
              name: item.name,
              email: item?.email,
              companyName: item?.companyName,
            });
          }}
        >
          <Box
            sx={{
              display: "flex",
              transition: "150ms ease-in-out",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 550,
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "grey",
              }}
            >
              {item?.email}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                color: "black",
              }}
            >
              {item?.phone}
            </Typography>
          </Box>
        </Box>
      );
    });
  }
}

import { API, graphqlOperation } from "aws-amplify";
import { getPriceByCategoryIds } from "../graphql/queries";

export async function fetchPriceByCategoryId(queryResource) {
  try {
    const response = await API.graphql(
      graphqlOperation(getPriceByCategoryIds, queryResource)
    );
    if (response) {
      return response?.data?.getPriceByCategoryIds;
    }
  } catch (error) {
    console.warn("Error: fetchPriceByCategoryId failed", error, queryResource);
  }
}

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { AppContext } from "../../AppContext";

function CustomerForm({ customer, onSave, onCancel, loading }) {
  const { user, business } = useContext(AppContext);

  const [formData, setFormData] = useState({
    clientType: "COMPANY",
    companyName: "",
    brokerName: "",
    companyBillingAddress: "",
    companyBusinessId: "",
    companyBillingRef: "",
    companyEBillingOperator: "",
    companyEBillingAddress: "",
    companyCity: "",
    companyPostalCode: "",
    orgExternalBusinessId: "",
  });
  const [formErrors, setFormErrors] = useState({
    companyName: false,
    companyBusinessId: false,
    companyBillingAddress: false,
    companyEBillingOperator: false,
    companyEBillingAddress: false,
  });

  useEffect(() => {
    if (customer) {
      setFormData({
        id: customer.id || null,
        clientType: customer.clientType || "COMPANY",
        brokerName: customer.brokerName || "",
        companyName: customer.companyName || "",
        companyBillingAddress: customer.companyBillingAddress || "",
        companyBusinessId: customer.companyBusinessId || "",
        companyBillingRef: customer.companyBillingRef || "",
        companyEBillingOperator: customer.companyEBillingOperator || "",
        companyEBillingAddress: customer.companyEBillingAddress || "",
        companyCity: customer.companyCity || "",
        companyPostalCode: customer.companyPostalCode || "",
        orgExternalBusinessId: customer.orgExternalBusinessId || "",
      });
      setFormErrors({
        companyName: false,
        companyBusinessId: false,
        companyBillingAddress: false,
      });
    } else {
      setFormData({
        clientType: "COMPANY",
        companyName: "",
        companyBillingAddress: "",
        companyBusinessId: "",
        companyBillingRef: "",
        companyEBillingOperator: "",
        companyEBillingAddress: "",
        companyCity: "",
        companyPostalCode: "",
        orgExternalBusinessId: "",
      });
    }
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === "clientType") {
      setFormErrors({
        companyName: false,
        companyBusinessId: false,
        companyBillingAddress: false,
        companyEBillingOperator: false,
        companyEBillingAddress: false,
      });
    }
    if (
      formData.clientType === "BROKER" ||
      ["companyName", "companyBusinessId"].includes(name)
    ) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: !value.trim(),
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: false,
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.companyName.trim()) errors.companyName = true;
    if (!formData.companyBusinessId.trim()) errors.companyBusinessId = true;
    if (formData.clientType === "BROKER") {
      if (!formData.brokerName.trim()) errors.brokerName = true;
      if (!formData.companyBillingAddress.trim())
        errors.companyBillingAddress = true;
      if (!formData.companyEBillingOperator.trim())
        errors.companyEBillingOperator = true;
      if (!formData.companyEBillingAddress.trim())
        errors.companyEBillingAddress = true;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSave(formData);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            select
            label="Asiakkaan tyyppi"
            name="clientType"
            value={formData.clientType}
            onChange={handleChange}
          >
            <MenuItem value="COMPANY">Yritys</MenuItem>
            {user?.role === "ORGANIZATION_ADMIN" && !business && (
              <MenuItem value="BROKER">Agentti</MenuItem>
            )}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          {formData.clientType === "BROKER" && (
            <TextField
              fullWidth
              required
              label="Agentin nimi"
              name="brokerName"
              value={formData.brokerName}
              onChange={handleChange}
              error={formErrors.brokerName}
              helperText={formErrors.brokerName && "Agentin nimi on vaadittu"}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label={
              formData.clientType === "BROKER"
                ? "Laskutettavan yrityksen nimi"
                : "Yrityksen nimi"
            }
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            error={formErrors.companyName}
            helperText={formErrors.companyName && "Yrityksen nimi on vaadittu"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            label="Y-tunnus"
            name="companyBusinessId"
            value={formData.companyBusinessId}
            onChange={handleChange}
            error={formErrors.companyBusinessId}
            helperText={formErrors.companyBusinessId && "Y-tunnus on vaadittu"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Postitoimipaikka"
            name="companyCity"
            value={formData.companyCity}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Postinumero"
            name="companyPostalCode"
            value={formData.companyPostalCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required={formData.clientType === "BROKER"}
            fullWidth
            label="Laskutusosoite"
            name="companyBillingAddress"
            value={formData.companyBillingAddress}
            onChange={handleChange}
            error={formErrors.companyBillingAddress}
            helperText={
              formErrors.companyBillingAddress &&
              "Laskutusosoite on vaadittu agenteille"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Laskun viite"
            name="companyBillingRef"
            value={formData.companyBillingRef}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required={formData.clientType === "BROKER"}
            fullWidth
            label="Verkkolasku välitystunnus"
            name="companyEBillingOperator"
            value={formData.companyEBillingOperator}
            onChange={handleChange}
            error={formErrors.companyEBillingOperator}
            helperText={
              formErrors.companyEBillingOperator &&
              "Verkkolaskun välitystunnus on vaadittu agenteille"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required={formData.clientType === "BROKER"}
            fullWidth
            label="Verkkolasku osoite"
            name="companyEBillingAddress"
            value={formData.companyEBillingAddress}
            onChange={handleChange}
            error={formErrors.companyEBillingAddress}
            helperText={
              formErrors.companyEBillingAddress &&
              "Verkkolaskun osoite on vaadittu agenteille"
            }
          />
        </Grid>
        <LinkCustomerToExternalBusiness
          setFormData={setFormData}
          formData={formData}
        />
      </Grid>
      <Grid
        container
        marginTop={3}
        spacing={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item xs={12} md={3} sx={{ order: { xs: 3, md: 0 } }}>
          <Button
            component="a"
            href="https://www.verkkolaskuosoite.fi"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: { xs: "100%", md: "auto" }, // fullWidth on xs, auto width on md and up
              position: { xs: "absolute", md: "static" },
              bottom: { xs: "0px", md: false },
              left: { xs: "0px", md: false },
            }}
          >
            Tarkasta verkkolaskuosoite
          </Button>
        </Grid>
        <Grid item xs={false} md={0.97} display={{ xs: "none", md: "block" }} />
        <Grid item xs={12} md={2}>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Tallenna"}
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={onCancel} fullWidth>
            Peruuta
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function LinkCustomerToExternalBusiness({ setFormData, formData }) {
  const { user } = useContext(AppContext);
  const options = user?.externalBusinessByOrg?.items?.filter(
    (business) => business?.availableOrgBusinessIds
  );
  if (user?.role !== "ORGANIZATION_ADMIN" || options?.length === 0) return null;

  if (!options) return null;
  return (
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel id="link-customer-to-external-business">
          Liitä asiakas yritysportaali yritykseen
        </InputLabel>
        <Select
          labelId="link-customer-to-external-business"
          id="link-customer-to-external-business"
          label=" Liitä asiakas yritysportaali yritykseen"
          value={formData.orgExternalBusinessId}
          onChange={(event) => {
            setFormData((prev) => ({
              ...prev,
              orgExternalBusinessId: event.target.value,
            }));
          }}
          renderValue={(value) => {
            const business = options.find((b) => b.id === value);
            return business?.name || "Valitse yritys";
          }}
        >
          <MenuItem value={null}>
            <Typography>
              <strong>Tyhjennä valinta</strong>
            </Typography>
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option?.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default CustomerForm;

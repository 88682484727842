import "./contract.css";
import React from "react";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { centsToLocalString } from "../shared/money";
//import additionalServices from "../shared/additional-services";

const formatDate = (date) => {
  return format(date, "dd.MM.yyyy", { locale: fi });
};

const formatDateTime = (date) => {
  return format(date, "dd.MM.yyyy HH:mm", { locale: fi });
};

const Label = (props) => {
  return <div className="label" {...props}></div>;
};

const Empty = () => {
  return <div style={{ height: "1.3em" }} />;
};

const Customer = ({ customer }) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th colSpan={8}>Vuokralleottaja</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>
              <Label>Asiakkaan nimi</Label>
              {customer.name}
            </td>
            <td colSpan={4}>
              <Label>Kuljettajan nimi</Label>
              {customer.differentDriver ? customer.driverName : customer.name}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <Label>Asiakkaan Osoite</Label>
              {customer.address}, {customer.postalCode}, {customer.city}
            </td>
            <td colSpan={4}>
              <Label>Kuljettajan Osoite</Label>
              {customer.differentDriver
                ? customer.driverAddress +
                  " " +
                  customer.driverPostalCode +
                  " " +
                  customer.driverCity
                : customer.address +
                  ", " +
                  customer.postalCode +
                  ", " +
                  customer.city}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <Label>Asiakkaan Puhelin</Label>
              {customer.phone}
            </td>
            <td colSpan={4}>
              <Label>Kuljettajan Puhelin</Label>
              {customer.differentDriver ? customer.driverPhone : customer.phone}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Label>Asiakkaan Sähköposti</Label>
              {customer.email}
            </td>
            <td colSpan={2}>
              <Label>Asiakkaan hlötunnus</Label>
              {customer.ssn}
            </td>
            <td colSpan={2}>
              <Label>Kuljettajan Sähköposti</Label>
              {customer.differentDriver ? customer.driverEmail : customer.email}
            </td>
            <td colSpan={2}>
              <Label>Kuljettajan hlötunnus</Label>
              {customer.driverSSN}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Label>Luottokortin nro</Label>
              <Empty />
            </td>
            <td colSpan={1}>
              <Label>Voimassa</Label>
              <Empty />
            </td>
            <td colSpan={4}>
              <Label>Ajokortin numero ja luokka</Label>
              <Empty />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Label>Asiakkaan viite</Label>
              <Empty />
            </td>
            <td colSpan={1}>
              <Label>Maksutapa</Label>
              {customer.paymentStatus === "COMPLETED" ? "Maksettu" : <Empty />}
            </td>
            <td colSpan={5}>
              <Label>
                Lisäkuljettaja (Nimi, puhelin, hlötunnus/ajokortin numero)
              </Label>
              {customer.differentDriver && customer.additionalDriver
                ? customer.additionalDriverName +
                  ", " +
                  customer.additionalDriverPhone +
                  ", " +
                  (customer.additionalDriverSSN
                    ? customer.additionalDriverSSN
                    : "")
                : ""}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Label>Yrityksen viite</Label>
              {customer.isCompany ? customer.companyBillingRef : <Empty />}
            </td>
            <td colSpan={1}>
              <Label>Y-tunnus</Label>
              {customer.isCompany ? customer.companyBusinessId : <Empty />}
            </td>
            <td colSpan={2}>
              <Label>Laskutusosoite</Label>
              {customer.isCompany ? customer.companyBillingAddress : <Empty />}
            </td>
            <td colSpan={3}>
              <Label>Yrityksen nimi</Label>
              {customer.isCompany ? customer.companyName : <Empty />}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const CompanyTiny = ({ company }) => {
  return (
    <div className="company-info">
      <p>{company.name}</p>
      <p>
        {company.address} {company.postalCode} {company.city}
      </p>
      <p>Puh: {company.phone}</p>
      <p>Y-tunnus: {company.businessId}</p>
    </div>
  );
};

/* const Company = ({ company }) => {
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Vuokralleantaja</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Label>Nimi</Label>
            {company.name}
          </td>
          <td>
            <Label>Y-Tunnus</Label>
            {company.businessId}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <Label>Katuosoite</Label>
            {company.address}
          </td>
        </tr>
        <tr>
          <td>
            <Label>Kaupunki</Label>
            {company.city}
          </td>
          <td>
            <Label>Postinumero</Label>
            {company.postalCode}
          </td>
        </tr>
        <tr>
          <td>
            <Label>Puhelin</Label>
            {company.phone}
          </td>
          <td>
            <Label>Sähköposti</Label>
            {company.email}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
 */
const getDeductible = (as, vehicle) => {
  // New way of setting deducible service, value found on the service itself
  const deductibleFromService = as.find((s) => s.deductibleValue);
  if (deductibleFromService)
    return centsToLocalString(deductibleFromService.deductibleValue);

  // Old way of setting deductible services
  const noDeductible = as.find((s) => s.key === "noDeductible") ? true : false;
  const halvedDeductible = as.find((s) => s.key === "halvedDeductible")
    ? true
    : false;
  let deductible = vehicle.deductible;
  if (noDeductible || !deductible) {
    deductible = "";
  } else if (halvedDeductible) {
    deductible = centsToLocalString(vehicle.deductible / 2, "fi-FI");
  } else {
    deductible = centsToLocalString(vehicle.deductible, "fi-FI");
  }
  return deductible;
};

const Vehicle = ({ reservation, additionalServices, vehicle }) => {
  const as = reservation.additionalServices;
  const deductible = getDeductible(as, vehicle);

  /*   const enabledServices = reservation.additionalServices.map(
    (reservationService) => {
      const service = additionalServices.find(
        (as) => as.id === reservationService.key
      );
      return {
        ...reservationService,
        category: service.category,
        description: service.description,
      };
    }
  ); */

  /*   const includedDistance =
    enabledServices.findIndex((as) => as.category === "distance") > -1
      ? enabledServices.find((as) => as.category === "distance").description
      : vehicle.includedDistance ?? "";

  const exportLicense = enabledServices.find(
    (as) => as.key === "exportLicense"
  ); */
  /*   const exportLabel = exportLicense
    ? "Autolla voimassa maastavientilupa maahan: " + exportLicense.details
    : "Ei maastavientilupaa";
 */
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={8}>Vuokrauksen kohde</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={6}>
            <Label>Merkki ja malli</Label>
            {vehicle.name}
          </td>
          <td colSpan={2}>
            <Label>Rekisterinumero</Label>
            {typeof reservation.enableRegistrationPlate === "boolean"
              ? reservation.enableRegistrationPlate
                ? vehicle.registrationPlate
                : ""
              : vehicle.registrationPlate}
          </td>
        </tr>
        <tr>
          <td colSpan={8}>
            <Label>Sovittu alkamispvm klo</Label>
            {formatDateTime(reservation.startTime)}
          </td>
        </tr>
        <tr>
          <td colSpan={8}>
            <Label>Sovittu palautuspvm klo</Label>
            {formatDateTime(reservation.returnTime)}
          </td>
        </tr>
        <tr>
          <td colSpan={8}>
            <Label>KOLARITURVA omavastuu per vahinkotapaus</Label>
            {deductible}
          </td>
        </tr>
        <tr>
          <td colSpan={8}>
            <Label>VARKAUSTURVA omavastuu varkaustapauksissa</Label>
            {deductible}
            <Empty />
          </td>
        </tr>

        <tr>
          <td colSpan={8} style={{ height: 30 }}>
            <Label>Lisätietoja</Label>
            {vehicle.extraInfo}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const Prices = ({ reservation, additionalServices, getVehicle }) => {
  if (reservation.disablePricesInPrintouts === true) return "";
  var sumByTax = reservation.reservationVehicles.reduce((acc, curr) => {
    curr.tax = curr.tax ?? 24;

    if (acc[curr.tax]) {
      acc[curr.tax].total += curr.price;
      acc[curr.tax].taxless += curr.price / (curr.tax / 100 + 1);
    } else {
      acc[curr.tax] = {
        total: curr.price,
        tax: curr.tax,
        taxless: curr.price / (curr.tax / 100 + 1),
      };
    }

    return acc;
  }, {});

  reservation.additionalServices.forEach((obj) => {
    obj.tax = obj.tax ?? 24;
    if (sumByTax[obj.tax]) {
      sumByTax[obj.tax].total += obj.price;
      sumByTax[obj.tax].taxless += obj.price / (obj.tax / 100 + 1);
    } else {
      sumByTax[obj.tax] = {
        total: obj.price,
        tax: obj.tax,
        taxless: obj.price / (obj.tax / 100 + 1),
      };
    }
  });

  sumByTax = Object.values(sumByTax);
  const totalVehiclesPrice = reservation.reservationVehicles.reduce(
    (p, c) => p + c.price,
    0
  );
  const totalPrice = reservation.additionalServices.reduce(
    (p, c) => p + c.price,
    totalVehiclesPrice
  );

  const taxlessPrice = sumByTax.reduce((p, c) => p + c.taxless, 0);

  const taxOfPrice = (price, tax) => {
    var taxValue = tax ?? 24;
    taxValue = taxValue / 100 + 1;

    const taxPercentage = 1 - 1 / taxValue;
    return price * taxPercentage;
  };

  return (
    <table>
      <thead>
        <tr>
          <th colSpan={1}>Tuote</th>
          <th colSpan={1}>Hinta</th>
        </tr>
      </thead>
      <tbody>
        {reservation.reservationVehicles.map((v) => (
          <tr key={v.id}>
            <td>{getVehicle(v.id).name}</td>
            <td>{centsToLocalString(v.price)} €</td>
          </tr>
        ))}

        {reservation.additionalServices.map((service) => {
          // Use description saved to the Reservation if available. Secondarily (older reservations)
          // use the actual Service fetched from DDB. Lastly as a fallback use the key.
          const description = service.description
            ? service.description
            : additionalServices.find((s) => s.id === service.key)
                ?.description ?? service.key;

          return (
            <tr key={service.key}>
              <td>
                {service.quantity > 1
                  ? service.quantity + "x " + description
                  : description}
              </td>
              <td>{centsToLocalString(service.price)} €</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={1}>Summa (sis ALV)</th>
          <th colSpan={1}>
            <p>{centsToLocalString(totalPrice)} €</p>
            <p style={{ fontSize: "75%" }}>
              Veroton {centsToLocalString(taxlessPrice)} €
            </p>
            <p style={{ fontSize: "75%" }}>
              {sumByTax.map((v) => (
                <>
                  {`Vero ${centsToLocalString(
                    taxOfPrice(v.total, v.tax)
                  )} € (ALV. ${v.tax} %)`}
                </>
              ))}
            </p>
          </th>
        </tr>
      </tfoot>
    </table>
  );
};

const Agreement = ({ reservation }) => {
  if (
    reservation?.signStatus === "PENDING" ||
    reservation?.signStatus === "COMPLETED"
  ) {
    return (
      <table>
        <thead>
          <tr>
            <th colSpan={1}>Sähköinen allekirjoitus</th>
          </tr>
        </thead>
      </table>
    );
  }
  return (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>Allekirjoitukset</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="grey-background">
            <Label>
              Hyväksymme vuokrausehdot ja sitoudumme niitä noudattamaan.
            </Label>
            <div className="sign-box"></div>
            <Label>Vuokralleottaja</Label>
          </td>
          <td className="grey-background">
            <Label>Vuokraamon virkailijan puolesta.</Label>
            <div className="sign-box"></div>
            <Label>Vuokraamonpuolesta</Label>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const PageBreak = () => {
  return <div className="pageBreak"></div>;
};

const template = ({
  reservation,
  company,
  additionalServices,
  vehicles,
  callback,
}) => {
  const getVehicle = (id) => {
    return vehicles.find((v) => v.id === id);
  };
  const css = `
@page { size: auto;  margin: 0mm; }

.contract {
  font-size: 8pt;
}

.contract h1 {
  margin: 1;
  font-size: 140%;
  text-align: center;
}

.contract table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 94%;
  break-inside: avoid;
  margin-top: 12px;
  margin-right: 3%;
  margin-left: 3%;
}

.contract th,
.contract td {
  border: 1px solid black;
  padding: 2px;
  text-align: left;
  vertical-align: top;
}

.contract th {
  background-color: #eeeeee;
}
.contract td.grey-background {
  background-color: #e8e8e8;
}
.contract .label {
  font-size: 70%;
  margin: 0px;
}

.contract .sign-box {
  height: 3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.contract table p {
  margin: 0px;
}

.contract .company-info p {
  margin: 0;
  margin-left: 3%;
}
                  `;

  return (
    <>
      <style>{css}</style>
      <html ref={callback}>
        <head>
          <meta charSet="UTF-8" />
        </head>
        <div className="contract">
          <p
            className="date"
            style={{ float: "right", margin: 0, marginRight: "3%" }}
          >
            {formatDate(new Date())}
          </p>

          <h1>Vuokrasopimus</h1>
          <CompanyTiny company={company} />
          <Customer customer={reservation} />
          {reservation.reservationVehicles.map((v) => (
            <Vehicle
              key={v.id}
              reservation={reservation}
              additionalServices={additionalServices}
              vehicle={getVehicle(v.id)}
            />
          ))}
          <Prices
            reservation={reservation}
            additionalServices={additionalServices}
            getVehicle={getVehicle}
          />
          <Agreement reservation={reservation} />
          <PageBreak />
        </div>
      </html>
    </>
  );
};

export default template;

import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

type FormikInputDateProps = any;

function FormikInputDate({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  readOnly,
}: FormikInputDateProps) {
  return (
    <Grid item xs={xs} md={md}>
      <DatePicker
        {...field}
        label="Laskun päiväys"
        inputVariant="outlined"
        onChange={(date) => {
          form?.setFieldValue("delivery_date", date);
        }}
        inputFormat="dd.MM.yyyy"
        fullWidth
        cancelLabel="Peruuta"
        required
        disabled={readOnly}
        renderInput={(params: any) => <TextField {...params} size="small" />}
      />
    </Grid>
  );
}

export default FormikInputDate;

import { Avatar } from "@mui/material";
import { ComponentProps } from "react";
import { Vehicle } from "~/graphql/API";
import { useVehicleImage } from "~/hooks/useVehicleImage";

type VehicleImageProps = Omit<
  ComponentProps<typeof Avatar>,
  "children" | "src"
> & { vehicle: Vehicle };

function VehicleImage({ vehicle, ...props }: VehicleImageProps) {
  const { image, icon } = useVehicleImage(vehicle);

  return (
    <Avatar {...props} src={image}>
      {!image && icon}
    </Avatar>
  );
}

export default VehicleImage;

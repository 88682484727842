import React, { useCallback, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import { Button, Menu } from "@mui/material";
import { useCategories } from "../../../../../../../../queries/useCategories";
import { externalUserHanselClasses } from "../../../../../../utils/externalUserHanselClasses";
import { CustomMenuItem } from "../../../../../../components/CustomMenuItem";
import { useSetAtom } from "jotai";
import { vehiclePricesAtom } from "../../../../../../../../stores/vehiclePrices";

export default function SelectVehicleClass(props) {
  const { visibility } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  if (visibility)
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ textTransform: "none" }}
        >
          Siirrä varaus autoluokalle
        </Button>
        {anchorEl && (
          <VehicleClassMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        )}
      </React.Fragment>
    );
}

function VehicleClassMenu(props) {
  const { anchorEl, setAnchorEl } = props;
  const { values, setFieldValue } = useFormikContext();
  const categories = useCategories();
  const setPrices = useSetAtom(vehiclePricesAtom);
  const setFormikValues = useCallback(
    async (categoryId) => {
      const category = categories.find((c) => c.id === categoryId);
      const hanselClass = externalUserHanselClasses?.find((hanselClass) =>
        hanselClass.vehicleClasses.includes(category?.name)
      );

      setPrices({
        [categoryId]: {
          name: category?.name,
          deductible: undefined,
          price: undefined,
          startTime: values.startTime,
          returnTime: values.returnTime,
          pricingCategoryId: values.pricingCategoryId,
        },
      });
      await setFieldValue("selectedVehicleClass", categoryId);
      await setFieldValue(
        "hanselData",
        values?.type === "HANSEL"
          ? { ...values.hanselData, hanselClass: hanselClass?.class }
          : null
      );
      if (!values?.id) {
        await setFieldValue("initialClassInfo", {
          categoryId: category?.id,
          categoryName: category?.name,
          hanselClass: hanselClass?.class,
        });
      }
      await setFieldValue("wildCardVehicles", [
        {
          categoryId: category?.id,
          name: category?.name,
        },
      ]);
      await setFieldValue("reservationVehicles", []);
    },
    [
      categories,
      values.type,
      values.hanselData,
      values.reservationVehicles,
      values?.id,
    ]
  );

  const listCategories = useMemo(() => {
    const handleChange = (categoryId) => {
      setFormikValues(categoryId);
      setAnchorEl(null);
    };
    return categories.map((category) => {
      return (
        <CustomMenuItem
          key={`menu-item-${category.id}`}
          category={category}
          value={category?.id}
          image={category?.categoryImage}
          onClick={() => {
            handleChange(category?.id);
          }}
        />
      );
    });
  }, [setFormikValues, categories, setAnchorEl]);

  return (
    <Menu
      id="select-category"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      sx={{ maxHeight: { xs: "70vh", sm: "700px" } }}
      PaperProps={{ style: { width: "500px" } }}
    >
      {listCategories}
    </Menu>
  );
}

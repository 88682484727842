import {
  Avatar,
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import FINflag from "~/assets/flags/fin.png";
import SWEflag from "~//assets/flags/swe.png";
import ENGflag from "~/assets/flags/eng.png";

import { useField, useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";

export default function ProductAttributes({
  ADDITIONAL_FIELDS,

  categories,
  mobileViewPort,
}) {
  const { handleChange, handleBlur, values } = useFormikContext();
  const filteredFields = ADDITIONAL_FIELDS.filter(
    (field) =>
      field.key !== "other" &&
      field.key !== "equipments" &&
      field.key !== "luggage"
  );

  const selectedLanguage = "fi";
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      sx={{ marginTop: "25px" }}
    >
      <FormGrid container direction={"row"} spacing={1}>
        <FormGrid md={6} xs={12}>
          <TextField
            size="small"
            name="deductible"
            label="Omavastuu"
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
            fullWidth
            value={values.deductible ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGrid>
        <FormGrid md={6} xs={12}>
          <TextField
            size="small"
            value={values.includedDistance ?? ""}
            name="includedDistance"
            label="Sisältyvät kilometrit"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGrid>

        {filteredFields.map((field, index) => {
          return (
            <FormGrid key={field?.key} md={6} xs={12}>
              <ProductAttribute
                field={field?.key}
                fieldData={field}
                index={index}
                selectedLanguage={selectedLanguage}
              />
            </FormGrid>
          );
        })}
        <FormGrid key={"equipments"} md={12} xs={12}>
          <ProductAttribute
            field={"equipments"}
            fieldData={ADDITIONAL_FIELDS?.find(
              (field) => field.key === "equipments"
            )}
            selectedLanguage={selectedLanguage}
          />
        </FormGrid>
        <FormGrid key={"other"} md={12} xs={12}>
          <ProductAttribute
            field={"other"}
            fieldData={ADDITIONAL_FIELDS?.find(
              (field) => field.key === "other"
            )}
            selectedLanguage={selectedLanguage}
          />
        </FormGrid>
      </FormGrid>
    </Grid>
  );
}

function ProductAttribute({ field, fieldData, selectedLanguage }) {
  if (!fieldData) {
    console.debug("missing fieldData", field);
    return;
  }

  return (
    <Stack
      direction={"column"}
      flex={1}
      spacing={1}
      sx={{
        padding: "5px",
      }}
    >
      <Box display={"flex"}>
        <Typography variant="caption">
          {fieldData?.labelT?.[selectedLanguage]}
        </Typography>
      </Box>
      <AttributeComponentByType
        fieldData={fieldData}
        selectedLanguage={selectedLanguage}
      />
    </Stack>
  );
}

function AttributeComponentByType({ fieldData, selectedLanguage }) {
  const fieldType = fieldData?.optionsT ? "options" : "textfield";

  switch (fieldType) {
    case "options":
      return (
        <AttributeOptions
          fieldData={fieldData}
          selectedLanguage={selectedLanguage}
        />
      );
    case "textfield":
      return (
        <AttributeTextField
          fieldData={fieldData}
          selectedLanguage={selectedLanguage}
        />
      );
    default:
      return null;
  }
}

function AttributeTextField({ fieldData, selectedLanguage }) {
  const [field, meta, helpers] = useField("additionalFields");

  const fieldDataValue = meta.value?.find(
    (item) => item.key === fieldData?.key
  )?.value;

  if (!fieldData?.translatedValue) {
    return (
      <TextField
        name={fieldData.key}
        value={fieldDataValue ?? ""}
        onChange={(e) => {
          // TODO: enable this when value can be null
          //    const value = e.target.value === "" ? null : e.target.value;
          const newValues = meta.value.map((item) => {
            if (item.key === fieldData.key) {
              return {
                ...item,
                value: e.target.value,
              };
            }
            return item;
          });
          helpers.setValue(newValues);
        }}
        onBlur={field.onBlur}
        size="small"
        InputProps={
          fieldData.unit
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {fieldData?.unitT?.[selectedLanguage]}
                  </InputAdornment>
                ),
              }
            : null
        }
        inputProps={{
          style: { textAlign: "right" },
        }}
        fullWidth
      />
    );
  } else {
    const getTranslatedValue = (language) => {
      return meta.value?.find((item) => item.key === fieldData.key)?.valueT?.[
        language
      ];
    };

    return (
      <Stack spacing={1} flex={1}>
        <TextField
          fullWidth
          name={fieldData.key}
          value={getTranslatedValue("fi") ?? ""}
          onChange={(e) => {
            const value = e.target.value;
            const newValues = meta.value.map((item) => {
              if (item.key === fieldData.key) {
                return {
                  ...item,
                  valueT: {
                    ...item.valueT,
                    fi: value,
                  },
                  value: null,
                };
              }
              return item;
            });
            helpers.setValue(newValues);
          }}
          onBlur={field.onBlur}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  src={FINflag}
                  sx={{
                    border: "1px solid lightgrey",
                    height: 26,
                    width: 26,
                  }}
                />
              </InputAdornment>
            ),
          }}
          multiline
        />
        <TextField
          fullWidth
          name={fieldData.key}
          value={getTranslatedValue("sv") ?? ""}
          onChange={(e) => {
            const value = e.target.value;
            const newValues = meta.value.map((item) => {
              if (item.key === fieldData.key) {
                return {
                  ...item,
                  valueT: {
                    ...item.valueT,
                    sv: value,
                  },
                  value: null,
                };
              }
              return item;
            });
            helpers.setValue(newValues);
          }}
          onBlur={field.onBlur}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  src={SWEflag}
                  sx={{
                    border: "1px solid lightgrey",
                    height: 26,
                    width: 26,
                  }}
                />
              </InputAdornment>
            ),
          }}
          multiline
        />
        <TextField
          fullWidth
          name={fieldData.key}
          value={getTranslatedValue("en") ?? ""}
          onChange={(e) => {
            const value = e.target.value;
            const newValues = meta.value.map((item) => {
              if (item.key === fieldData.key) {
                return {
                  ...item,
                  valueT: {
                    ...item.valueT,
                    en: value,
                  },
                  value: null,
                };
              }
              return item;
            });
            helpers.setValue(newValues);
          }}
          onBlur={field.onBlur}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Avatar
                  src={ENGflag}
                  sx={{
                    border: "1px solid lightgrey",
                    height: 26,
                    width: 26,
                  }}
                />
              </InputAdornment>
            ),
          }}
          multiline
        />
      </Stack>
    );
  }
}

function AttributeOptions({ fieldData, selectedLanguage }) {
  const [, meta, helpers] = useField("additionalFields");

  return (
    <Box display={"flex"} flex={1}>
      <Select
        size="small"
        value={
          meta.value?.find((item) => item.key === fieldData.key)?.value ?? ""
        }
        onChange={(e) => {
          const newValues = meta.value.map((item) => {
            if (item.key === fieldData.key) {
              return {
                ...item,
                value: e.target.value,
                valueT: null,
              };
            }
            return item;
          });
          helpers.setValue(newValues);
        }}
        fullWidth
      >
        <MenuItem value="">Ei valittu</MenuItem>
        {Object.entries(fieldData?.optionsT[selectedLanguage]).map(
          ([key, value]) => {
            const getNullValue = () => {
              if (
                key === "null" ||
                key === null ||
                key === "undefined" ||
                key === "" ||
                key === undefined
              ) {
                return null;
              } else {
                return key;
              }
            };
            const keyValue = getNullValue();

            return (
              <MenuItem key={key} value={keyValue}>
                {value}
              </MenuItem>
            );
          }
        )}
      </Select>
    </Box>
  );
}

import { useMemo } from "react";
import { List } from "@mui/material";
import EventItem from "./EventItem";
import EmptyState from "../../../components/EmptyState";

export default function Arrivals({ arrivals }) {
  const showDepartures = useMemo(() => {
    if (!arrivals || arrivals.length === 0) return <EmptyState />;

    return arrivals.map((reservation, index) =>
      reservation.reservationVehicles.map((vehicle, idx) => {
        return (
          <EventItem
            reservation={reservation}
            eventTime={"returnTime"}
            key={`${reservation?.id}+${vehicle?.id}`}
          />
        );
      })
    );
  }, [arrivals]);

  return <List>{showDepartures}</List>;
}

import React from "react";
import DialogComponent from "./DialogComponent";
import NewsItem from "./NewsItem";
import { useFetchNews } from "../services/fetchNews";
import { FormattedDate } from "../utils/common";
import { Skeleton, Box } from "@mui/material";

export default function NewsDialog({ open, onClose }) {
  const { newsItems, newsItemsLoading } = useFetchNews();

  return (
    <DialogComponent open={open} dialogClose={() => onClose()} maxWidth="md">
      {newsItemsLoading ? (
        <Box>
          <Skeleton />
        </Box>
      ) : (
        newsItems.map((newsItem) => {
          return (
            <NewsItem
              title={newsItem.header}
              description={newsItem.content}
              date={<FormattedDate date={new Date(newsItem.createdAt)} />}
              link={newsItem.link}
              key={newsItem.id}
            />
          );
        })
      )}
    </DialogComponent>
  );
}

import useSWR from "swr";
import { API, graphqlOperation } from "aws-amplify";
import { getPriceByVehicleIds } from "../graphql/queries";

export function useGetOfferPriceByIds(
  vehicleIds,
  startTime,
  returnTime,
  companyId,
  externalBusinessId
) {
  let key = null;
  let fetcher = null;

  key = [vehicleIds, startTime, returnTime, companyId, externalBusinessId];
  fetcher = () =>
    fetchGetPrices(
      vehicleIds,
      startTime,
      returnTime,
      companyId,
      externalBusinessId
    );

  /*   if (user?.organizationId) {
    key = ["additionalServiceByOrganization", business?.id];
    fetcher = () => fetchByOrganization(user?.organizationId, business?.id);
  }
 */
  const { data, error, isLoading, mutate } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    offerPrices: data ? data : [],
    offerPricesLoading: isLoading,
    offerPricesError: error,
    offerPricesMutate: mutate,
  };
}

const fetchGetPrices = async (
  vehicleIds,
  startTime,
  returnTime,
  companyId,
  externalBusinessId
) => {
  const response = await API.graphql(
    graphqlOperation(getPriceByVehicleIds, {
      vehicleIds,
      startTime,
      returnTime,
      companyId,
      externalBusinessId,
    })
  );

  const newOfferPrices =
    response.data.getPriceByVehicleIds.vehicleOfferPrices.map((offer) => {
      return { ...offer, price: offer?.price };
    });

  const newServiceOffers =
    response.data.getPriceByVehicleIds.additionalServiceOffers;

  return {
    vehicleOfferPrices: newOfferPrices,
    serviceOfferPrices: newServiceOffers,
  };
};

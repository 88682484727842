import { motion } from "framer-motion";
import { timelineContainer } from "../../../constants";
import { Typography } from "@mui/material";

export default function EventsContainer(props) {
  const { event, children, loading } = props;

  return (
    <motion.div
      initial="hidden"
      animate="show"
      variants={timelineContainer}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1.2rem" }}>
        {event}
      </Typography>
      {loading ? <Loading /> : children}
    </motion.div>
  );
}

function Loading(props) {
  return (
    <motion.div
      key={"departuresLoading"}
      variants={timelineContainer}
      initial="hidden"
      animate="show"
      exit={{ opacity: 0 }}
    >
      <Typography>Haetaan tapahtumia...</Typography>
    </motion.div>
  );
}

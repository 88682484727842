// UserTable.jsx

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Avatar,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

const UserTable = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleOpenDeleteDialog,
  currentUser,
  loading,
}) => {
  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!data || data.length === 0) {
    return <p>Ei käyttäjiä.</p>;
  }

  // Calculate the start and end indices for the current page
  const startIdx = page * rowsPerPage;
  const endIdx = startIdx + rowsPerPage;

  // Slice the data array to only get the rows for the current page
  const pageData = data.slice(startIdx, endIdx);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (name && name.includes(" ")) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      // Handle the case where the name doesn't contain a space
      // You can return a default avatar or a single character, for example
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: name ? name[0] : "", // Default to the first character of the name
      };
    }
  }

  return (
    <TableContainer>
      <Table
        aria-label="user management table"
        data-cy="userManagementUserTable"
      >
        <TableHead>
          <TableRow>
            <TableCell>Nimi</TableCell>
            <TableCell>Käyttäjärooli</TableCell>
            <TableCell>Poista</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageData.map((user, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Avatar {...stringAvatar(user?.name)} />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography>
                      <b>{user.name}</b>
                    </Typography>
                    <Typography
                      sx={{ color: "rgba(0,0,0,0.55)", fontSize: "14px" }}
                    >
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>{getUserRole(user)}</TableCell>
              <TableCell>
                {currentUser.id !== user.id && (
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(user)}
                    data-cy="deleteUserButton"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, { value: -1, label: "Kaikki" }]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default UserTable;

function getUserRole(user) {
  if (user?.role === "ORGANIZATION_ADMIN") {
    return "Organisaatio admin";
  }
  if (user?.role === "BUSINESS_ADMIN") {
    return "Admin";
  }
}

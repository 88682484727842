import { Stack, Typography } from "@mui/material";
import { ContractField } from "./common";
import { titleStyle } from "./styles";
import { EContractAgreement } from "./LanguagePack/translatedFields";

export default function ContractPostDebit({
  selectedLanguage,
  contractSettings,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  if (!contractSettings.showPostDebit) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography sx={titleStyle}>
        {
          EContractAgreement[
            selectedLanguage as keyof typeof EContractAgreement
          ].POST_BILLING
        }
      </Typography>

      <Stack sx={{ border: "1px solid lightgrey", borderRadius: "5px" }}>
        <Stack direction={"row"} width={"100%"}>
          <ContractField
            label={
              EContractAgreement[
                selectedLanguage as keyof typeof EContractAgreement
              ].CREDIT_CARD_NUMBER
            }
            value={""}
            visibility={true}
          />
          <ContractField
            label={
              EContractAgreement[
                selectedLanguage as keyof typeof EContractAgreement
              ].CREDIT_CARD_EXPIRATION
            }
            value={""}
            visibility={true}
          />
          <ContractField label="" value="" />
        </Stack>

        <Stack direction={"row"}>
          <ContractField
            label={`${
              EContractAgreement[
                selectedLanguage as keyof typeof EContractAgreement
              ].POST_BILLING
            } ${
              EContractAgreement[
                selectedLanguage as keyof typeof EContractAgreement
              ].POST_BILLING_TERMS
            }`}
            value={
              EContractAgreement[
                selectedLanguage as keyof typeof EContractAgreement
              ].POST_BILLING_AUTHORIZED
            }
            visibility={true}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Skeleton } from "@mui/material";
import SpecialHoursEditDialog from "./SpecialHoursEditDialog";
import DialogComponent from "./DialogComponent";
import { useContextFetchSpecialHours } from "../services/fetchSpecialHours";

export default function SpecialHoursDialog({ open, onClose, company }) {
  const { specialHours, specialHoursMutate, specialHoursLoading } =
    useContextFetchSpecialHours();

  const [editableHours, setEditableHours] = useState(null);

  const specialHoursFiltered = useMemo(() => {
    if (!specialHours) return;
    return specialHours.filter((hour) => hour.companyId === company.id);
  }, [specialHours, company]);

  const createNew = () => {
    setEditableHours({
      date: null,
    });
  };

  return (
    <DialogComponent
      open={open}
      dialogClose={() => onClose()}
      dialogAction={() => createNew()}
      dialogActionText={"Lisää"}
      dialogTitle={"Erikoisaukioloajat"}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nimi</TableCell>
            <TableCell>Päivä</TableCell>
            <TableCell sx={{ textAlign: "right" }}>Aukeaa</TableCell>
            <TableCell sx={{ textAlign: "right" }}>Sulkeutuu</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {specialHoursLoading ? (
            <>
              <RowSkeleton />
              <RowSkeleton />
              <RowSkeleton />
            </>
          ) : (
            (specialHoursFiltered || []).map((row, idx) => (
              <TableRow
                key={idx}
                hover
                onClick={() => setEditableHours(row)}
                sx={{ cursor: "pointer" }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>{row.date}</TableCell>
                {row.businessHours ? (
                  <>
                    <TableCell sx={{ textAlign: "right" }}>
                      {row.businessHours.openAt}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {row.businessHours.closeAt}
                    </TableCell>
                  </>
                ) : (
                  <TableCell sx={{ textAlign: "center" }} colSpan={2}>
                    {"Suljettu"}
                  </TableCell>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {editableHours && (
        <SpecialHoursEditDialog
          selected={editableHours}
          specialHours={specialHoursFiltered}
          company={company}
          onClose={(created) => {
            setEditableHours(null);
            if (created) {
              specialHoursMutate();
            }
          }}
        />
      )}
    </DialogComponent>
  );
}

function RowSkeleton() {
  return (
    <TableRow hover>
      <TableCell>
        <Skeleton width={70} />
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Skeleton width={50} />
      </TableCell>
      <TableCell sx={{ textAlign: "right" }}>
        <Skeleton width={60} />
      </TableCell>
    </TableRow>
  );
}

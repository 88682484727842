import { API, graphqlOperation } from "aws-amplify";
import { setReservationVehicles } from "../graphql/mutations";

export default async function useTransferReservation(
  reservation,
  transferToVehicle,
  fromVehicle,
  fromWildCard,
  transferToCategory
) {
  let response;

  // Extracting specific vehicle or wildcard vehicle data early based on the given identifiers
  let fromVehicleData = fromVehicle
    ? reservation.reservationVehicles.find(
        (vehicle) => vehicle.id === fromVehicle.id
      )
    : null;

  if (fromVehicle && !transferToCategory) {
    // Case: Transferring within vehicles, not changing to a new category
    const reservationVehicles = reservation.reservationVehicles.map(
      (resVehicle) => {
        if (resVehicle.id === fromVehicle.id) {
          // Explicitly setting each field required by the schema
          return {
            id: transferToVehicle?.id,
            price: resVehicle?.price, // Keeping the original price
            categoryName: transferToVehicle?.categoryName,
            damages: transferToVehicle?.damages,
            deductible: fromVehicleData?.deductible, // Keeping the original deductible
            extraInfo: transferToVehicle?.extraInfo,
            hideExport: transferToVehicle?.hideExport,
            includedDistance: transferToVehicle?.includedDistance,
            mileage: transferToVehicle?.mileage,
            name: transferToVehicle?.name,
            powerType: transferToVehicle?.powerType,
            quantity: transferToVehicle?.quantity,
            registrationPlate: transferToVehicle?.registrationPlate,
            tax: transferToVehicle?.tax,
          };
        }
        return resVehicle;
      }
    );

    try {
      response = await API.graphql(
        graphqlOperation(setReservationVehicles, {
          reservationId: reservation.id,
          reservationVehicles: reservationVehicles,
          wildCardVehicles: [],
        })
      );
    } catch (error) {
      console.warn(error);
    }

    return response?.data?.setReservationVehicles;
  }

  if (fromVehicle && transferToCategory) {
    // Case: Transferring a vehicle to a new category
    try {
      response = await API.graphql(
        graphqlOperation(setReservationVehicles, {
          reservationId: reservation.id,
          reservationVehicles: [],
          wildCardVehicles: [
            {
              name: transferToCategory?.name,
              price: fromVehicleData?.price, // Use the price from the original vehicle
              categoryId: transferToCategory?.id,
              deductible: fromVehicleData?.deductible, // Use the deductible from the original vehicle
              quantity: 1, // Assuming a default quantity, adjust as needed
            },
          ],
        })
      );
    } catch (error) {
      console.warn(error);
    }

    return response?.data?.setReservationVehicles;
  }

  if (fromWildCard && transferToVehicle) {
    // Case: Transferring from a wildcard to a specific vehicle
    const wildCardVehicleData = reservation.wildCardVehicles.find(
      (wildcard) => wildcard.id === fromWildCard.id
    );
    const reservationVehicles = [
      {
        id: transferToVehicle?.id,
        price: wildCardVehicleData?.price, // Use the price from the wildcard entry
        deductible: wildCardVehicleData?.deductible, // Use the deductible from the wildcard entry
        // Explicitly set other fields as required by schema from transferToVehicle
        categoryName: transferToVehicle?.categoryName,
        damages: transferToVehicle?.damages,
        extraInfo: transferToVehicle?.extraInfo,
        hideExport: transferToVehicle?.hideExport,
        includedDistance: transferToVehicle?.includedDistance,
        mileage: transferToVehicle?.mileage,
        name: transferToVehicle?.name,
        powerType: transferToVehicle?.powerType,
        quantity: transferToVehicle?.quantity,
        registrationPlate: transferToVehicle?.registrationPlate,
        tax: transferToVehicle?.tax,
      },
    ];

    try {
      response = await API.graphql(
        graphqlOperation(setReservationVehicles, {
          reservationId: reservation.id,
          reservationVehicles: reservationVehicles,
          wildCardVehicles: [],
        })
      );
    } catch (error) {
      console.warn(error);
    }
    return response?.data?.setReservationVehicles;
  }
}

import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, isValid } from "date-fns";
import { fi } from "date-fns/locale";
import { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { useFetchCompanies } from "../../../../../services/fetchCompanies";
import { OpenInNew } from "@mui/icons-material";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useSetAtom } from "jotai";
import { useFetchVehicles } from "../../../../../services/fetchVehicles";

export default function EventItem(props) {
  const { reservation, eventTime } = props;
  const { user, business, showAllCompanies } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const handleNameTitle = () => {
    if (reservation?.companyName) {
      return reservation?.companyName;
    }
    if (reservation?.name) {
      return reservation?.name;
    }
    if (reservation?.email) {
      return reservation?.email;
    }
  };

  const getCompanyName = (id) => {
    if (!showAllCompanies) return;
    const companName = companies.find((v) => v.id === id)?.name;
    return companName ?? "";
  };

  const setEditReservationData = useSetAtom(reservationDataAtom);

  return (
    <ListItem
      disableGutters
      disablePadding
      sx={{ borderBottom: "1px solid lightgrey" }}
    >
      <ListItemAvatar>
        <Typography>
          {isValid(reservation[eventTime])
            ? format(new Date(reservation[eventTime]), "HH:mm", {
                locale: fi,
              })
            : ""}
        </Typography>
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={reservation?.reservationVehicles.map((item) => (
          <EventProduct key={item.id} item={item} />
        ))}
        secondary={
          <Stack>
            <Typography variant="body2">{handleNameTitle()}</Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>
              {getCompanyName(reservation?.companyId)}
            </Typography>
          </Stack>
        }
      ></ListItemText>

      <ListItemSecondaryAction>
        <Tooltip title="Avaa varaus">
          <IconButton onClick={() => setEditReservationData(reservation)}>
            <OpenInNew color="primary" />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function EventProduct(props) {
  const { item } = props;
  const { vehicleData } = useFetchVehicles();
  const getVehicleName = () => {
    if (props?.name) {
      return props?.name;
    } else {
      const vehicle = vehicleData.find((v) => v.id === item?.id);
      return vehicle?.name ?? "";
    }
  };
  const getRegistrationPlate = () => {
    if (item?.registrationPlate) {
      return (
        <span style={{ marginRight: "5px", color: "black" }}>
          {item?.registrationPlate}
        </span>
      );
    } else {
      const vehicle = vehicleData.find((v) => v.id === item?.id);
      if (vehicle?.registrationPlate) {
        return (
          <span style={{ marginRight: "5px", color: "black" }}>
            {vehicle?.registrationPlate}
          </span>
        );
      }
      return null;
    }
  };
  return (
    <Typography sx={{ color: "grey" }} noWrap>
      {getRegistrationPlate()}
      {getVehicleName()}
    </Typography>
  );
}

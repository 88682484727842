import { Box, Grid, Typography, AvatarGroup } from "@mui/material";

import React from "react";

import ProductImage from "../../ProductImage";
import useGetVehicleObjById from "../../CustomHooks/useGetVehicleObjById";

export function ReservationVehicleCard({ r, idx }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <AvatarGroup max={2} sx={{ marginRight: "5px" }}>
        {r.map((vehicle, index) => {
          return (
            <ProductImage
              key={`product-image-key-${index}`}
              vehicleId={vehicle?.id}
            />
          );
        })}
      </AvatarGroup>
      <Grid container direction={"column"} sx={{ alignItems: "flex-start" }}>
        {r.map((vehicle, index) => {
          if (index < 2) {
            return (
              <VehicleName vehicle={vehicle} key={`vehicle-name-${index}`} />
            );
          }
          return null; // Add a return statement here
        })}
      </Grid>
    </Box>
  );
}

function VehicleName({ vehicle }) {
  const vehicleFromVehicleData = useGetVehicleObjById(vehicle?.id);
  const vehicleName = vehicle?.name ?? vehicleFromVehicleData?.name;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="caption">
        {vehicleName ?? <span>[POISTETTU TUOTE]</span>}
      </Typography>
      <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.4)" }}>
        {vehicle?.registrationPlate}
      </Typography>
    </Box>
  );
}

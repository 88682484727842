import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { useFormikContext } from "formik";
import { useMemo } from "react";

export default function InsuranceCoverage() {
  const { values, setFieldValue, touched, errors } = useFormikContext();

  // handle maximum policy duration by policy
  const threshold = values?.insuranceData?.maximumPolicyDuration;
  const coverageOptions = useMemo(() => {
    // add coverage for IF insurance company
    // ext id = db474c68-5c03-45d4-a331-d4ea35070316
    const initialValues = [1, 2, 3, 5, 7, 14, 30];
    if (threshold === "") {
      return initialValues;
    }
    return initialValues;
  }, [threshold]);

  const handleChange = (value) => {
    setFieldValue("insuranceData.compensatableDays", value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="insuranceData.compensatableDays">
        Enimmäiskorvausaika (vrk)
      </InputLabel>

      <Select
        disabled={!values?.insuranceData?.product}
        labelId="insuranceData.compensatableDays"
        id="insuranceData.compensatableDays"
        value={
          values?.insuranceData?.compensatableDays
            ? values?.insuranceData?.compensatableDays
            : ""
        }
        label="Enimmäiskorvausaika (vrk)"
        name="insuranceData.compensatableDays"
        onChange={(e) => handleChange(e.target.value)}
        sx={{ bgcolor: "white" }}
        error={
          touched?.insuranceData?.compensatableDays &&
          !!errors?.compensatableDays
        }
      >
        {coverageOptions.map((option, index) => (
          <MenuItem value={option} key={`${option}-${index}`}>
            {option} vrk
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

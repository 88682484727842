import { Button } from "@mui/material";
import { useFormikContext } from "formik";
import { useValidateForm } from "../../../formik/hooks/useValidateForm";
import { useValidateChanges } from "../../../hooks/useValidateChanges";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { vehiclePricesAtom } from "../../../../../stores/vehiclePrices";

export default function SubmitReservation() {
  const { submitForm, values, isSubmitting } = useFormikContext();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { validate } = useValidateForm();
  const setPrices = useSetAtom(vehiclePricesAtom);
  const { validateChanges, validateChangesDialog } = useValidateChanges(
    values,
    [],
    false, // checkProductPrices
    false, // checkServicePrices
    null
  );
  return (
    <>
      {validateChangesDialog}
      <Button
        disabled={isSubmitting}
        variant="contained"
        color="success"
        onClick={async () => {
          const isValid = await validate();
          if (!isValid) return;
          const isChangesValid = await validateChanges();
          if (isChangesValid) {
            try {
              const result = await submitForm();
              if (result?.success) {
                setPrices({});
                setEditReservationData(null);
              } else {
                // TODO: handle error
              }
            } catch (error) {
              console.error(error);
            }
          }
        }}
      >
        Vahvista
      </Button>
    </>
  );
}

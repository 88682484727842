import { CircularProgress, Typography } from "@mui/material";
import { centsToLocalString } from "../../../../../../../../../../shared/money";

export default function ListingPrice(props) {
  const { listingPrice, loading } = props;

  return (
    <Typography variant="caption" sx={{ marginBottom: "5px" }}>
      Listahinta:{" "}
      {loading ? (
        <CircularProgress size={14} />
      ) : (
        `${centsToLocalString(listingPrice)} €`
      )}
    </Typography>
  );
}

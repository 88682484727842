import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "~/utils/list-all";
import {
  listVehiclesByGroupWithAdditionalServices as listVehiclesByGroup,
  listVehiclesByBusinessWithAdditionalServices as listVehiclesByBusiness,
} from "~/graphql/custom-queries";
import captureError from "~/utils/capture-error";
import { vehicleByOrganizaion } from "~/graphql/queries";
import { useContext } from "react";
import { AppContext } from "~/AppContext";
import { useCategories } from "~/queries/useCategories";
import { useMemo } from "react";

const fetchByGroup = async (group) => {
  console.log("fetch vehicles by group", group);
  try {
    return await listAll(
      graphqlOperation(listVehiclesByGroup, { group: group }),
      "vehicleByGroup"
    );
  } catch (e) {
    console.error("Fetch vehicles error", e);
    captureError("Get vehicles failed", "GET_VEHICLES_FAILED", e);
  }
};

const fetchByBusiness = async (businessId) => {
  console.log("fetch vehicles by business", businessId);
  try {
    return await listAll(
      graphqlOperation(listVehiclesByBusiness, { orgBusinessId: businessId }),
      "vehicleByOrgBusinessId"
    );
  } catch (e) {
    console.error("Fetch vehicles error", e);
    captureError("Get vehicles failed", "GET_VEHICLES_FAILED", e);
  }
};

export function useFetchVehicles() {
  const { group, business, showAllCompanies, company } = useContext(AppContext);

  const resourceID = business?.id;
  let key = null;
  let fetcher = null;

  if (group) {
    key = ["vehicleByGroup", group];
    fetcher = ([key, group]) => fetchByGroup(group);
  }
  // for organization users
  if (resourceID) {
    key = ["vehicleByBusiness", resourceID];
    fetcher = () => fetchByBusiness(resourceID);
  }

  const { data, error, isLoading, mutate } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });

  const categories = useCategories();

  const vehiclesByCategory = useMemo(() => {
    if (!data) {
      return [];
    }
    // Only execute after both are initialized and not null

    // Add unassigned reservations to category
    const categoriesData = categories.map((category) => {
      const vehicles = data
        .filter((v) => v?.category?.id === category.id)
        .filter((v) => (showAllCompanies ? v : v.companyId === company?.id))
        .sort((v1, v2) => {
          return (
            (v1.orderNumber === null) - (v2.orderNumber === null) ||
            v1.orderNumber - v2.orderNumber
          );
        });

      return {
        categoryData: category,
        vehicles: vehicles,
      };
    });

    const noCategory = {
      categoryData: { name: "Ei kategoriaa", orderNumber: null, id: null },
      vehicles: [],
    };

    data.forEach((v) => {
      if (!v?.category?.id) {
        noCategory.vehicles.push(v);
      }
    });

    const combinedCategories = [...categoriesData, noCategory].filter(
      (c) => c.unassignedReservations !== null || c.vehicles?.length > 0
    );

    return combinedCategories;
  }, [categories, data, showAllCompanies, company?.id]);

  const vehiclesByCategoryNew = useMemo(() => {
    if (!data) return [];
    const availableCategories = categories?.filter((c) =>
      data.find(
        (vehicle) =>
          vehicle?.category?.id === c.id &&
          (showAllCompanies || vehicle?.companyId === company?.id)
      )
    );
    const vehicles = [];

    for (let item of availableCategories) {
      vehicles.push({ categoryTitle: item.name, category: item });
      const filteredVehicles = data.filter(
        (vehicle) => vehicle?.category?.id === item.id
      );
      for (let vehicle of filteredVehicles) {
        vehicles.push(vehicle);
      }
    }

    return vehicles;
  }, [data, categories, showAllCompanies, company?.id]);

  return {
    vehicleData: data,
    vehiclesByCategory: vehiclesByCategory,
    vehiclesByCategoryNew: vehiclesByCategoryNew,
    vehiclesLoading: isLoading,
    vehiclesError: error,
    vehiclesMutate: mutate,
  };
}

export function useContextFetchVehicles() {
  const { group, business } = useContext(AppContext);
  return useFetchVehicles(group, business);
}

export function useFetchVehiclesByOrganization(resourceID) {
  let key = null;
  let fetcher = null;

  // for organization users
  if (resourceID) {
    key = resourceID ? resourceID : null;
    fetcher = () => fetchByOrganization(resourceID);
  }

  const { data, error, isLoading, mutate } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    vehicleData: data,
    vehiclesLoading: isLoading,
    vehiclesError: error,
    vehiclesMutate: mutate,
  };
}

const fetchByOrganization = async (organizationId) => {
  console.log("fetch vehicles by organization", organizationId);
  try {
    const vehicles = await listAll(
      graphqlOperation(vehicleByOrganizaion, {
        organizationId: organizationId,
      }),
      "vehicleByOrganizaion"
    );
    return vehicles;
  } catch (e) {
    console.error("Fetch vehicles error", e);
    captureError("Get vehicles failed", "GET_VEHICLES_FAILED", e);
  }
};

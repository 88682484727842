import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";
import captureError from "../utils/capture-error";
import { paymentEventByReservation } from "../graphql/queries";

const fetchPaymentEvents = async (reservationId) => {
  try {
    const paymentEvents = await listAll(
      graphqlOperation(paymentEventByReservation, {
        reservationId: reservationId,
        filter: {
          or: [
            { channel: { eq: "MANUAL" } },
            { and: [{ channel: { eq: "VISMA" } }, { returnCode: { eq: 0 } }] },
          ],
        },
      }),
      "paymentEventByReservation"
    );

    return paymentEvents;
  } catch (e) {
    console.error("Get paymentEvents failed", "GET_PAYMENTEVENTS_FAILED", e);
    captureError("Get paymentEvents failed", "GET_PAYMENTEVENTS_FAILED", e);
    return [];
  }
};

export function useFetchPaymentEvents(reservationId, featureInvoicing) {
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    featureInvoicing ? ["fetchPaymentEvents", reservationId] : null,
    () => fetchPaymentEvents(reservationId),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    }
  );

  return {
    paymentEvents: data,
    paymentEventsLoading: isLoading,
    paymentEventsValidating: isValidating,
    paymentEventsError: error,
    paymentEventsMutate: mutate,
  };
}

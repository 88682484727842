import { useContext } from "react";
import { ContextMenuAtom, contextMenuAtom } from "../store";
import { useSetAtom } from "jotai";
import { RowContext } from "../../VehicleRow/RowContext";

export function useHandleContextMenu() {
  const setContextMenu = useSetAtom(contextMenuAtom);
  const rowContext = useContext(RowContext);

  return {
    handleContextMenu: <Element extends HTMLElement>(
      data: Omit<ContextMenuAtom, "pos" | "target">
    ) => {
      return (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        setContextMenu({
          ...rowContext,
          ...data,
          pos: { left: event.clientX, top: event.clientY },
          target: event.currentTarget,
        });
      };
    },
  } as const;
}

import { useField } from "formik";
import { useCallback } from "react";

export const useAddNewService = () => {
  const [field, meta, helpers] = useField("additionalServices");
  const { setValue } = helpers;

  const addService = useCallback(
    (serviceItem, setAnchorEl) => {
      if (setAnchorEl) {
        setAnchorEl(null);
      }
      setValue([...meta.value, { ...serviceItem, quantity: 1 }]);
    },
    [meta.value, setValue]
  );

  return { addService };
};

import "../app-theme.css";
import React, { useContext } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
} from "@mui/material";

import { isSameDay } from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import ForwardIcon from "@mui/icons-material/Forward";

import EventsContainer from "./EventsContainer";
import { useMemo } from "react";
import { AppContext } from "../../AppContext";

import { useFetchReservations } from "../../services/fetchReservations";
import { useFetchCompanies } from "../../services/fetchCompanies";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";
import DayEventsDialogMobileView from "./views/MobileView";
import { tableHeadVariant, timelineContainer, timelineItem } from "./constants";

export default function DayEventsDialog() {
  const { company, setCompany, startDate, showAllCompanies, business, user } =
    useContext(AppContext);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const { reservations, reservationsLoading } =
    useFetchReservations("startOrEndBetween");
  const theme = useTheme();
  const arrivals = useMemo(() => {
    if (!reservations) return [];

    if (showAllCompanies) {
      return reservations
        .filter((res) => isSameDay(res.returnTime, startDate))
        .sort((a, b) =>
          a.returnTime.toString().localeCompare(b.returnTime.toString())
        );
    }
    return reservations
      .filter(
        (res) =>
          isSameDay(res.returnTime, startDate) && res?.companyId === company?.id
      )
      .sort((a, b) =>
        a.returnTime.toString().localeCompare(b.returnTime.toString())
      );
  }, [reservations, company, showAllCompanies, startDate]);

  const departures = useMemo(() => {
    if (!reservations) return [];

    if (showAllCompanies) {
      return reservations
        .filter((res) => isSameDay(res.startTime, startDate))
        .sort((a, b) =>
          a.startTime.toString().localeCompare(b.startTime.toString())
        );
    }

    return reservations
      .filter(
        (res) =>
          isSameDay(res.startTime, startDate) && res?.companyId === company?.id
      )
      .sort((a, b) =>
        a.startTime.toString().localeCompare(b.startTime.toString())
      );
  }, [reservations, company, showAllCompanies, startDate]);

  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const getCompanyObjById = (id) => {
    return companies.find((v) => v.id === id);
  };

  // This function changes selected company when opening reservation
  const handleOpenReservation = (reservation) => {
    if (!reservation) return;
    return (
      setCompany(
        companies.find((company) => company.id === reservation.companyId)
      ),
      setEditReservationData({
        ...reservation,
        startTime: new Date(reservation.startTime),
        returnTime: new Date(reservation.returnTime),
        endTime: new Date(reservation.endTime),
      })
    );
  };

  return (
    <Container maxWidth="false" sx={{ height: "70vh" }}>
      {!mobileView && (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <AnimatePresence mode="wait">
              <motion.div
                variants={timelineContainer}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  className="infoContainer-small-light"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    align="center"
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: 24,
                    }}
                  >
                    Lähtevät
                  </Typography>
                </Grid>

                {!reservationsLoading && (
                  <EventsContainer
                    handleOpenReservation={handleOpenReservation}
                    getCompanyObjById={getCompanyObjById}
                    timelineContainer={timelineContainer}
                    timelineItem={timelineItem}
                    events={departures}
                    time={"startTime"}
                    eventColor={"primary"}
                    eventIcon={
                      <ForwardIcon
                        style={{ fontSize: 24, transform: " scaleX(-1)" }}
                        color="primary"
                      />
                    }
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </Grid>
          <Grid item xs={12} lg={6}>
            <AnimatePresence mode="wait">
              <motion.div
                variants={timelineContainer}
                initial="hidden"
                animate="show"
                exit={{ opacity: 0 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  item
                  className="infoContainer-small-dark"
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    align="center"
                    style={{
                      fontFamily: "Sofia Pro",
                      fontSize: 24,
                    }}
                  >
                    Tulevat
                  </Typography>
                </Grid>

                {!reservationsLoading && (
                  <EventsContainer
                    handleOpenReservation={handleOpenReservation}
                    getCompanyObjById={getCompanyObjById}
                    timelineContainer={timelineContainer}
                    timelineItem={timelineItem}
                    events={arrivals}
                    time={"returnTime"}
                    eventColor={"secondary"}
                    eventIcon={
                      <ForwardIcon style={{ fontSize: 24 }} color="secondary" />
                    }
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </Grid>
        </Grid>
      )}
      {mobileView && (
        <DayEventsDialogMobileView
          loading={reservationsLoading}
          departures={departures}
          arrivals={arrivals}
          tableHeadVariant={tableHeadVariant}
          selectedCompany={company}
          handleOpenReservation={handleOpenReservation}
          companies={companies}
          timelineItem={timelineItem}
          timelineContainer={timelineContainer}
        />
      )}
    </Container>
  );
}

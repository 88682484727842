import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import listAll from "../utils/list-all";

import {
  pricingByGroup,
  pricingByOrgExternallBusinessId,
  pricingByOrganization,
} from "../graphql/queries";
import captureError from "../utils/capture-error";
import { useContext } from "react";
import { AppContext } from "../AppContext";

const fetchByGroup = async (group) => {
  console.log("fetch pricings by group");
  try {
    const pricings = await listAll(
      graphqlOperation(pricingByGroup, { group: group }),
      "pricingByGroup"
    );
    return pricings;
  } catch (e) {
    console.error("Get pricings failed", e);
    captureError("Get pricings failed", "GET_PRICINGS_FAILED", e);
  }
};

const fetchByOrganization = async (organizationId, excludeExtPricings) => {
  console.log("fetch pricings by organization");
  try {
    const filter = excludeExtPricings
      ? {
          orgExternalBusinessId: {
            attributeExists: false,
          },
        }
      : undefined;
    const pricings = await listAll(
      graphqlOperation(pricingByOrganization, {
        organizationId: organizationId,
        filter: filter,
        limit: 1000,
      }),
      "pricingByOrganization"
    );
    return pricings;
  } catch (e) {
    console.error("Get pricings failed", e);
    captureError("Get pricings failed", "GET_PRICINGS_FAILED", e);
  }
};

const fetchByExternalBusiness = async (externalBusinessId) => {
  console.log("fetch pricings by external business");
  try {
    const pricings = await listAll(
      graphqlOperation(pricingByOrgExternallBusinessId, {
        orgExternalBusinessId: externalBusinessId,

        limit: 1000,
      }),
      "pricingByOrgExternallBusinessId"
    );
    return pricings;
  } catch (e) {
    console.error("Get pricings failed", e);
    captureError("Get pricings failed", "GET_PRICINGS_FAILED", e);
  }
};

export function useFetchExternalBusinessPricings(externalBusinessId) {
  let key = externalBusinessId;
  let fetcher = () => fetchByExternalBusiness(externalBusinessId);

  const { data, error, isLoading, isValidating } = useSWR(key, fetcher, {
    revalidateOnFocus: false,
  });

  const sortedPricings = data?.sort((a, b) => a.name.localeCompare(b.name));

  return {
    externalBusinessPricings: sortedPricings,
    externalBusinessPricingsLoading: isLoading,
    externalBusinessPricingsValidating: isValidating,
    externalBusinessPricingsError: error,
  };
}

export function useFetchPricings(group, user, options, source) {
  let key = null;
  let fetcher = null;

  if (group) {
    key = ["pricingByGroup", group];
    fetcher = () => fetchByGroup(group);
  }
  if (user?.organizationId) {
    key = ["pricingByOrganization"];
    let excludeExtPricings;
    if (user.role === "ORGANIZATION_ADMIN" && source === "pricingListDialog") {
      key = ["pricingByOrganizationIncludingExt"];
      excludeExtPricings = false;
    } else {
      excludeExtPricings = true;
    }
    fetcher = () =>
      fetchByOrganization(user.organizationId, excludeExtPricings);
  }

  // TODO (CU-2g00z7z): In case of business user, add a filter so that only
  // Organizatio top-level pricings and pricings with the correct orgBusinessId
  // are returned. (No pricings from other Businesses)

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    fetcher,
    {
      //  revalidateOnFocus: false,
      ...options,
      onSuccess: (data) =>
        data
          .filter((item) => !item?._removed)
          .sort((a, b) => a.name.localeCompare(b.name)),
    }
  );

  return {
    pricings: data ?? [],
    pricingsLoading: isLoading,
    pricingsValidating: isValidating,
    pricingsError: error,
    pricingsMutate: mutate,
  };
}

export function useFetchPricingsOrganization(source) {
  const { user, business } = useContext(AppContext);
  let key = null;
  let fetcher = null;

  if (user?.organizationId) {
    key = ["pricingByOrganization"];
    let excludeExtPricings;
    if (user.role === "ORGANIZATION_ADMIN" && source === "pricingListDialog") {
      key = ["pricingByOrganizationIncludingExt"];
      excludeExtPricings = false;
    } else {
      excludeExtPricings = true;
    }
    fetcher = () =>
      fetchByOrganization(user.organizationId, excludeExtPricings);
  }

  // TODO (CU-2g00z7z): In case of business user, add a filter so that only
  // Organizatio top-level pricings and pricings with the correct orgBusinessId
  // are returned. (No pricings from other Businesses)

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    fetcher,
    {
      //  revalidateOnFocus: false,
      onSuccess: (data) => {
        return data
          .filter((item) => !item?._removed)
          .sort((a, b) => a.name.localeCompare(b.name));
      },
    }
  );

  const businessPricings = data
    ? data.filter(
        (item) =>
          item.orgBusinessId === business?.id &&
          !item.orgExternalBusinessId &&
          !item._removed
      )
    : null;
  const organizationPricings = data
    ? data.filter(
        (item) =>
          !item.orgBusinessId && !item.orgExternalBusinessId && !item._removed
      )
    : null;

  const pricings = data
    ? data
        .filter((item) => !item?._removed)
        .sort((a, b) => a.name.localeCompare(b.name))
    : [];
  const externalPricings = data
    ? data.filter((item) => !!item.orgExternalBusinessId)
    : [];
  return {
    pricings: pricings,
    organizationPricings: organizationPricings,
    businessPricings: businessPricings,
    externalPricings: externalPricings,
    pricingsLoading: isLoading,
    pricingsValidating: isValidating,
    pricingsError: error,
    pricingsMutate: mutate,
  };
}

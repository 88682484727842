import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { useContext } from "react";
import { AppContext } from "../AppContext";

export function EconomyDatePicker() {
  const { economyQueryDates, setEconomyQueryDates } = useContext(AppContext);
  return (
    <DateRangePicker
      slots={{ field: SingleInputDateRangeField }}
      value={economyQueryDates}
      onChange={(newValue) => setEconomyQueryDates(newValue)}
    />
  );
}

import { API } from "aws-amplify";

/*
  Amplify GraphQL API may return results as paginated.
  This helper does multiple requests until really all results are loaded or fail.
*/
export default async function listAll(query, setName) {
  const itemsList = [];
  let gqlOperation = query;
  let lastNextToken;
  while (true) {
    const response = await API.graphql(gqlOperation);
    const result = response.data[setName];
    itemsList.push(result.items);
    if (result.nextToken) {
      //console.log("query", itemsList.length);
      if (itemsList.length > 50) {
        //Arbitary maxium count. If this hits, something is done wrong.
        throw new Error("Maxium query count exceeded");
      } else if (result.nextToken === lastNextToken) {
        //Should not never happen
        throw new Error("Next token repeated error");
      }
      lastNextToken = result.nextToken;

      gqlOperation = {
        ...gqlOperation,
        variables: {
          ...gqlOperation.variables,
          nextToken: result.nextToken,
        },
      };
    } else {
      break;
    }
  }

  return [].concat(...itemsList);
}

import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";

import { useContext } from "react";
import captureError from "../utils/capture-error";

import { listAll } from "../utilities.js";
import { AppContext } from "../AppContext";
import { searchVehicles } from "../graphql/queries";

// Uses the Lambda function and byGroupByEndTime GSI
const search = async (searchInput, group, business, user) => {
  if (!searchInput || searchInput.length < 3) {
    return [];
  } // group is mandatory for the fetch

  try {
    const filter = {
      and: [
        { organizationId: { eq: user?.organizationId } },
        { registrationPlate: { matchPhrasePrefix: searchInput } },
      ],
    };

    const response = await listAll(
      graphqlOperation(searchVehicles, {
        limit: 1000,
        filter: filter,
        sort: {
          direction: "desc",
        },
      }),
      "searchVehicles"
    );

    if (response == null) {
      return;
    }

    return response;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

export function useFindVehicle(query) {
  const { group, business, user } = useContext(AppContext);
  let fetcher = null;

  if (query) {
    fetcher = () => search(query, group, business, user);
  }

  const { data, isLoading } = useSWR(!!query ? query : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  return {
    data: data,
    isLoading: isLoading,
  };
}

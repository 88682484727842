import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useContext, useState } from "react";
import { FaCalendarAlt, FaCalendarWeek, FaCalendarDay } from "react-icons/fa";
import { TbReportSearch } from "react-icons/tb";
import { AppContext } from "../AppContext";
import {
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
} from "date-fns";

export default function SelectCalendarView() {
  const { selectedView, setSelectedView, setEndDate, setStartDate, startDate } =
    useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenCalendarViewMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeView = (view) => {
    if (view === "daily") {
      setStartDate(startOfDay(new Date()));
      setEndDate(endOfDay(new Date()));
    }

    if (view === "weekly") {
      setStartDate(startOfISOWeek(new Date()));
      setEndDate(endOfISOWeek(new Date()));
    }
    if (view === "monthly") {
      setEndDate(endOfMonth(startDate));
      setStartDate(startOfMonth(startDate));
    }

    setSelectedView(view);
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton onClick={handleOpenCalendarViewMenu}>
        <FaCalendarAlt />
      </IconButton>

      <Menu
        id="row-display-style"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          value="weekly"
          selected={selectedView === "weekly"}
          onClick={() => handleChangeView("weekly")}
        >
          <ListItemIcon>
            <FaCalendarWeek />
          </ListItemIcon>
          <ListItemText>Viikkonäkymä</ListItemText>
        </MenuItem>
        <MenuItem
          value="monthly"
          selected={selectedView === "monthly"}
          onClick={() => handleChangeView("monthly")}
        >
          <ListItemIcon>
            <FaCalendarAlt />
          </ListItemIcon>
          <ListItemText>Kuukausinäkymä</ListItemText>
        </MenuItem>
        <MenuItem
          value={"daily"}
          selected={selectedView === "daily"}
          onClick={() => handleChangeView("daily")}
        >
          <ListItemIcon>
            <FaCalendarDay />
          </ListItemIcon>
          <ListItemText>Päivän tapahtumat</ListItemText>
        </MenuItem>
        {/* <MenuItem
          value={"reservationTable"}
          selected={selectedView === "reservationTable"}
          onClick={() => handleChangeView("reservationTable")}
        >
          <ListItemIcon>
            <TbReportSearch />
          </ListItemIcon>
          <ListItemText>Varaukset lista</ListItemText>
        </MenuItem> */}
      </Menu>
    </Box>
  );
}

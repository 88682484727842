import { Customer, Reservation } from "~/graphql/API";
import { ECustomerType } from "../views/OpenOrdersView/enums";

export type ReservationsGroup = {
  customerType: ECustomerType;
  customer: undefined | Customer;
  items: Reservation[];
};

type ReservationsMap = Record<string, ReservationsGroup>;

export function groupReservations(reservations: Reservation[]) {
  reservations.sort((a, b) => b.createdAt?.localeCompare(a.createdAt));

  return reservations.reduce((acc, item) => {
    const key =
      item.customerBrokerId ||
      item.customerCompanyId ||
      item.customerPersonalId ||
      item.email!;

    if (!acc[key]) {
      acc[key] = {
        customerType: item.customerCompanyId
          ? ECustomerType.COMPANY
          : item.customerBrokerId
          ? ECustomerType.BROKER
          : ECustomerType.PERSON,
        customer: undefined,
        items: [],
      };
    }

    acc[key].items.push(item);

    return acc;
  }, {} as ReservationsMap);
}

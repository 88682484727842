import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import { IconButton, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGetRequiredFields } from "../../../formik/requiredFields";

export function FormInputField(props) {
  const { name, label, disabled, rows, password, size } = props;
  const [field, meta, helpers] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;
  const [input, setInput] = useState(value);
  const [focused, setFocused] = useState(false);
  const { values } = useFormikContext();
  const { requiredFields } = useGetRequiredFields();

  // prevent re-rendering when input is the same as formik value
  const isRequired = requiredFields(values).includes(name);

  const cypressTag = `data-cy-reservation-form-input-${name}`;
  // make sure the input value stays in sync with formik value
  useEffect(() => {
    if (value !== input && !focused) {
      setInput(value);
    }
  }, [value, input, focused]);

  const handleChange = async () => {
    if (input === value) {
      return null;
    } else {
      await setValue(input);
      await setTouched(true, true);
      setFocused(false);
    }
  };

  return (
    <TextField
      disabled={disabled}
      fullWidth
      type={password ? (showPassword ? "text" : "password") : "text"}
      onFocus={() => setFocused(true)}
      label={label}
      onChange={(e) => setInput(e.target.value)}
      onBlur={() => handleChange()}
      autoComplete="off"
      value={input ?? ""}
      size={size}
      name={name}
      error={!!error && touched}
      helperText={touched && error}
      required={isRequired}
      multiline={rows > 1}
      rows={rows ?? 1}
      data-cy={cypressTag}
      InputProps={{
        endAdornment: password ? (
          <IconButton
            onClick={() => setShowPassword((prevState) => !prevState)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ) : null,
      }}
    />
  );
}

import { Divider, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import NotificationItem from "./NotificationItem";

export default function NotificationsContainer({
  notifications,
  readNotifications,
  markAsRead,
}) {
  const theme = useTheme();

  return (
    <Stack>
      {notifications?.length > 0 && (
        <>
          <Divider />
          <Box bgcolor={theme.palette.grey[300]} px={1}>
            Uusi
          </Box>
          <Divider />
        </>
      )}
      {notifications.map((notification, index) => (
        <div key={index}>
          <NotificationItem
            type={notification.type}
            notification={notification}
            markAsRead={markAsRead}
          />
          <Divider />
        </div>
      ))}

      {readNotifications?.length > 0 && (
        <>
          <Divider />
          <Box bgcolor={theme.palette.grey[300]} px={1}>
            Luetut (viimeinen 30 päivää)
          </Box>
          <Divider />
        </>
      )}

      {readNotifications?.map((notification, index) => (
        <div key={index}>
          <NotificationItem
            type={notification.type}
            notification={notification}
            markAsRead={() => {
              // Do nothing, notification is already read
            }}
          />
          <Divider />
        </div>
      ))}
    </Stack>
  );
}

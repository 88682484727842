import { useContext } from "react";

//@ts-ignore
import { AppContext } from "../../../AppContext";

import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";

//@ts-ignore
import { useFetchReservations } from "~/services/fetchReservations";
import { pageContainerStyle } from "~/styles/page-container";
import { useGetColumns } from "../components/columns";

export default function ReservationTableExternalUsers({
  visibility,
}: {
  visibility: boolean;
}) {
  const { appBarHeight } = useContext<any>(AppContext);

  const { reservations, reservationsLoading } =
    useFetchReservations("existBetween");

  const columns = useGetColumns();

  if (visibility)
    return (
      <Box sx={{ ...pageContainerStyle, marginX: { sm: "2%" } }}>
        <DataGridPro
          //  getRowId={(row) => row.key}
          sx={{
            //   borderColor: "transparent",
            border: "none",
            overflow: "hidden",
            /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              // boxShadow: theme.shadows[4],
            },
            height: `calc(100vh - ${appBarHeight}px)`,
          }}
          loading={reservationsLoading}
          rows={reservations}
          columns={columns}
          pagination
          disableRowSelectionOnClick
        />
      </Box>
    );
}

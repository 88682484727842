import React, { useState, useMemo, useContext, useRef } from "react";

import captureError from "../../utils/capture-error";
import * as Sentry from "@sentry/react";
import { Cache } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCustomer,
  deleteReservation,
  sendEmailVerification,
  upsertReservationAdmin,
  upsertReview,
} from "../../graphql/mutations";

import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { format, isValid } from "date-fns";
import { fi } from "date-fns/locale";
import { zonedTimeToUtc } from "date-fns-tz";
import { Formik } from "formik";
import { AppContext } from "../../AppContext";
import { useReservationContext } from "./ReservationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification } from "../Notification";
import { useCategories } from "~/queries/useCategories";
import { useFetchReservations } from "../../services/fetchReservations";
import { centsToLocalString, parseToCents } from "../../shared/money";
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
  parsePhoneNumberWithError,
  ParseError,
} from "libphonenumber-js";
import { enableNewPaymentTab, isOrganizationUser } from "../../features";

import DialogComponent from "../DialogComponent";
import InvoiceDialog from "../InvoiceDialog";
import { DialogMenu } from "../common/MenuAndContent";

import {
  TbApps,
  TbChartHistogram,
  TbCurrencyEuro,
  TbFileText,
  TbKey,
  TbLayoutBoardSplit,
  TbMailFast,
  TbMenu2,
  TbMessage2,
  TbSpy,
  TbUserPlus,
} from "react-icons/tb";
import { Check } from "@mui/icons-material";
import { getEffectiveOfferPrice } from "./common/utils";
import { PrintingComponent } from "./ReservationButtons";
import { ReservationForm } from "./ReservationForm";
import { useEffect } from "react";
import {
  AuthStatusCompletedIcon,
  OnlinePaymentCompletedIcon,
} from "../common/StatusIcons";
import { useFetchInsuranceEntries } from "../../services/fetchInsuranceEntries";

export default function ReservationDialogNew(props) {
  const {
    reservationData,
    onClose,
    initializeFormikData,
    adaptiveView,
    setAdaptiveView,
  } = props;
  const {
    company,
    group,
    selectedView,
    timezone,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_INSURANCE,
    user,
  } = useContext(AppContext);
  const categories = useCategories();
  const queryMode =
    selectedView === "daily" ? "startOrEndBetween" : "existBetween";
  const { reservations, reservationsMutate } = useFetchReservations(queryMode);
  const { externalReservations, externalReservationsMutate } =
    useFetchInsuranceEntries();
  const {
    selectedOffers,
    selectedServiceOffers,
    totalPrice,
    queryResource,
    startTime,
    returnTime,
    endTime,
    queryCompanies,
    disableDialogAction,
    submitting,
    setSubmitting,
    selectedContractVehicleClass,
    vehicleIds,
  } = useReservationContext();

  // Update local state instantly
  const mutateLocalReservations = (operation, reservation) => {
    let oldReservations = reservations;
    let mutateFunction = reservationsMutate;
    if (USER_ADMIN_INSURANCE) {
      // At least for now, USER_ADMIN_INSURANCE only has external reservations visible
      oldReservations = externalReservations;
      mutateFunction = externalReservationsMutate;
    }
    if (operation === "create") {
      mutateFunction([...oldReservations, reservation], {
        revalidate: false,
      });
    }
    if (operation === "update") {
      mutateFunction(
        oldReservations.map((r) => (r.id === reservation.id ? reservation : r)),
        { revalidate: false }
      );
    }
    if (operation === "delete") {
      mutateFunction(
        oldReservations.filter((r) => r.id !== reservation.id),
        { revalidate: false }
      );
    }
  };

  const [review, setReview] = useState(undefined);
  const [closeDialog, setCloseDialog] = useState(false);
  const notification = useNotification();
  const [explanation, setExplanation] = useState(undefined);
  const [showPriceConfirmation, setShowPriceConfirmation] = useState(false);
  const [formikErrors, setFormikErrors] = useState(false);
  const formikRef = useRef();

  const FEATURE_INVOICING = useMemo(() => {
    return isOrganizationUser(user);
  }, [user]);

  const getBillingInfo = (values) => {
    // Is it necessary to set companyName / businessId etc. if no company fields are populated?
    if (values.brokerCustomer) {
      return {
        companyBillingAddress: values?.brokerCustomer?.companyBillingAddress,
        companyEBillingAddress: values?.brokerCustomer?.companyEBillingAddress,
        companyEBillingOperator:
          values?.brokerCustomer?.companyEBillingOperator,
      };
    } else {
      // Directly use the provided values if not a broker reservation
      return {
        companyBillingAddress: values?.companyBillingAddress,
        companyEBillingAddress: values?.companyEBillingAddress,
        companyEBillingOperator: values?.companyEBillingOperator,
      };
    }
  };

  const onSubmit = async (values, confirmPriceChange) => {
    const {
      additionalDriver,
      additionalDriverName,
      additionalDriverPhone,
      additionalDriverPhoneCountryCode,
      additionalDriverSSN,
      address,
      billPayment,
      city,
      customerBrokerId,
      customerCompanyId,
      customerPersonalId,
      companyBillingRef,
      companyBusinessId,
      companyId,
      companyName,
      companyCity,
      companyPostalCode,
      completeBefore,
      customerCarRegistrationPlate,
      differentDriver,
      driverAddress,
      driverCity,
      driverEmail,
      driverName,
      driverPhone,
      driverPhoneCountryCode,
      driverPostalCode,
      driverSSN,
      email,
      enableRegistrationPlate,
      disablePricesInPrintouts,
      externalNotes,
      hanselData,
      id,
      initialClassInfo,
      insuranceCompany,
      insuranceData,
      internalNotes,
      isCompany,
      name,
      phone,
      phoneCountryCode,
      postalCode,
      rentalNotes,
      rentalStatus,
      returnLocation,
      returnLocationID,
      selectedVehicleClass,
      ssn,
      startLocation,
      startLocationID,
      status,
    } = values;

    // Handle reservation vehicles
    const reservedVehicles = selectedOffers?.map((vehicle) => {
      if (USER_ADMIN_INSURANCE) return null; // Insurance user cannot modify reservationVehicles
      const id = vehicle?.id;
      if (id) {
        // Only update tax information, if a new Vehicle is added onto Reservation
        /*       const vehicleDataFromReservation = findVehicleData(id);
      const tax = vehicleDataFromReservation
        ? vehicleDataFromReservation?.tax
        : selectedOffers?.vehicleOfferPrices[id]?.tax;
 */

        return {
          // for HANSEL report
          /*   emission: allVehicles
            .find((v) => v.id === id)
            ?.additionalFields.find((v) => v.key === "emission").value, */
          // Fields by ReservationVehicleAdminInput

          category: { id: vehicle?.vehicleCategoryId },
          categoryName: vehicle?.categoryName,
          damages: vehicle?.damages,
          deductible:
            parseToCents(vehicle?.modifiedDeductible) ?? vehicle?.deductible,
          endMileage: vehicle?.endMileage,
          extraInfo: vehicle?.extraInfo,
          hideExport: vehicle?.hideExport,
          id: id,
          includedDistance:
            values?.type === "HANSEL"
              ? "Vapaat kilometrit"
              : vehicle?.includedDistance,
          mileage: vehicle?.mileage,
          name: vehicle?.name,
          powerType: vehicle?.powerType,
          price: getEffectiveOfferPrice(vehicle),

          quantity: vehicle?.quantity,
          registrationPlate: vehicle?.registrationPlate,
          startMileage: vehicle?.startMileage,
          tax: vehicle?.tax,
          useCategoryDefaults: !!vehicle?.quantity,
        };
      }
      return null;
    });

    // Handle reservation additional services
    const selectedAdditionalServices = selectedServiceOffers.map((service) => {
      if (USER_ADMIN_INSURANCE) return null; // Insurance user cannot modify additionalServices

      return {
        key: service.key,
        category: service?.category,
        description: service?.description,
        price: parseToCents(service.modifiedPrice) ?? service.price,
        details: service.details,
        deductibleValue: service?.deductibleValue,
        quantity: service.quantity,
        tax: service.tax,
      };
    });

    const startTimeUTC = zonedTimeToUtc(startTime, timezone);
    const returnTimeUTC = zonedTimeToUtc(returnTime, timezone);
    const endTimeUTC = zonedTimeToUtc(endTime || returnTime, timezone);

    const parsedPhone = phone
      ? parsePhoneNumberFromString(phoneCountryCode + phone).number
      : "";
    const parsedDriverPhone = driverPhone
      ? parsePhoneNumberFromString(driverPhoneCountryCode + driverPhone).number
      : "";
    const parsedAdditionalDriverPhone = additionalDriverPhone
      ? parsePhoneNumberFromString(
          additionalDriverPhoneCountryCode + additionalDriverPhone
        ).number
      : "";

    const handleCompanyId = () => {
      if (USER_ADMIN_CORPORATE) {
        return companyId;
      }

      if (USER_ADMIN_GENERIC) {
        if (values?.enableWildCard) {
          // for wild card reservations, set the company id from queryCompanies.
          return queryCompanies[0];
        } else {
          // for regular card reservations, set the company id from the first selected product.
          return selectedOffers[0]?.companyId;
        }
      }
    };

    // external business id is either by user or by the selected contract customer
    const externalBusinessId =
      user?.externalBusinessId ?? values.orgExternalBusinessId;

    const billingInfo = getBillingInfo(values);

    try {
      const reservation = {
        additionalDriver,
        additionalDriverName,
        additionalDriverPhone: parsedAdditionalDriverPhone,
        additionalDriverSSN,
        address,
        billPayment,
        city,
        customerBrokerId,
        customerCompanyId,
        customerPersonalId,
        ...billingInfo,
        companyBillingRef,
        companyBusinessId,
        companyId: handleCompanyId(),
        companyName,
        companyCity,
        companyPostalCode,
        differentDriver,
        driverAddress,
        driverCity,
        driverEmail,
        driverName,
        driverPhone: parsedDriverPhone,
        driverPostalCode,
        driverSSN,
        email: email.trim(), // Remove leading and trailing white spaces
        enableRegistrationPlate,
        disablePricesInPrintouts,
        id,
        internalNotes,
        isCompany,
        name,
        phone: parsedPhone,
        postalCode,
        rentalNotes,
        rentalStatus,
        ssn,
        status: status,
        startLocation: startLocation ?? null,
        returnLocation: returnLocation ?? null,
        externalNotes: externalNotes,
        startLocationID: startLocationID ?? null,
        returnLocationID: returnLocationID ?? null,
        pricingCategoryId: selectedContractVehicleClass ?? null,
        // Add 'type' only if it has a value
        ...(values.type && { type: values.type }),
        // Add 'channel' only if it has a value
        ...(values.channel && { channel: values.channel }),
      };

      // Insurance user never modifies these fields
      if (!USER_ADMIN_INSURANCE) {
        reservation.reservationVehicles = reservedVehicles;
        reservation.additionalServices = selectedAdditionalServices;
        reservation.startTime = startTimeUTC;
        reservation.returnTime = returnTimeUTC;
        reservation.endTime = endTimeUTC;
      }

      // for legacy users
      if (group) {
        reservation.group = group;
      }

      //for organization users
      // queryResource is defined by organization admin
      const businessId = queryResource?.orgBusinessId ?? company?.orgBusinessId;
      // Add Organization identifiers if available
      if (company?.organizationId) {
        reservation.organizationId = company?.organizationId;
      }
      if (businessId) {
        reservation.orgBusinessId = businessId;
      }

      if (USER_ADMIN_CORPORATE && !reservation.orgBusinessId) {
        reservation.orgBusinessId = user?.businessByOrg.items.find((b) =>
          b.companies.items.find((c) => c.id === queryCompanies[0])
        )?.id;
      }

      // Fields we want to add only when adding a new Reservation
      if (!id) {
        if (user?.role === "ORGANIZATION_ADMIN") {
          reservation.channel = "DEALER_ORGANIZATION";
        } else if (USER_ADMIN_INSURANCE) {
          reservation.status = "EXTERNAL_PENDING_DEALER";
          reservation.channel = "DEALER_EXTERNAL";
        } else if (user?.role === "EXTERNAL_BUSINESS_ADMIN") {
          reservation.channel = "DEALER_EXTERNAL";
        } else {
          reservation.channel = "DEALER";
        }
      }

      if (
        user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
        user?.externalBusiness?.type === "INSURANCE"
      ) {
        reservation.type = "INSURANCE"; // Allows us to Upsert without times or Vehicles at backend
        reservation.customerCarRegistrationPlate = customerCarRegistrationPlate;
        // Insurance user never  modifies start- or endtimes of the Reservation
        delete reservation.startTime;
        delete reservation.returnTime;
        delete reservation.endTime;

        reservation.orgBusinessId = values.orgBusinessId;
        reservation.organizationId = user?.organizationId;
      }

      // contract customer id is stored here also so, good luck with that.

      if (externalBusinessId === null) {
        if (reservationData) {
          reservation.orgExternalBusinessId = null;
        }
      }

      if (externalBusinessId) {
        reservation.orgExternalBusinessId = externalBusinessId;
      }

      if (
        reservation.type === "INSURANCE" ||
        reservationData?.type === "INSURANCE"
      ) {
        reservation.insuranceData = insuranceData;
        delete reservation.insuranceData.__typename; // Make sure we don't try to save __typename
        reservation.insuranceData.providerCompany =
          insuranceCompany?.companyName;
        reservation.insuranceData.providerBusinessId =
          insuranceCompany?.businessId;
      }

      // EXTERNAL_PENDING_DEALER status doesnt include reservation vehicles or wildcards
      if (
        (reservedVehicles.length > 0 &&
          reservation.status === "EXTERNAL_PENDING_DEALER") ||
        (values?.enableWildCard &&
          reservation.status === "EXTERNAL_PENDING_DEALER")
      ) {
        reservation.status = "ALL_GOOD";
      }

      // Add external business admin (CORPORATE) id
      if (
        (user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
          user?.externalBusiness?.type === "STANDARD") ||
        (user?.role === "EXTERNAL_BUSINESS_ADMIN" &&
          user?.externalBusiness?.type === "NORMAL")
      ) {
        reservation.orgExternalBusinessId = user?.externalBusinessId;
        reservation.organizationId = user?.organizationId;
      }

      if (
        reservationData?.paymentData?.paymentTotal &&
        reservationData.paymentData.paymentTotal !== totalPrice
      ) {
        // See if user has confirmed that the total price changes (related to Visma pay, paymentTodo)
        if (!confirmPriceChange) {
          setShowPriceConfirmation(true);
          setSubmitting(false);
          return false; // Update not succesful
        } else {
          const oldTotal = reservationData.paymentData.paymentTotal;
          const oldTodo = reservationData.paymentData.paymentTodo;
          const difference = oldTotal - totalPrice;
          reservation.paymentData = reservationData.paymentData;
          delete reservation.paymentData.__typename; // __typename may be included in Reservation queries, but does not exist on PaymentDataInputAdmin
          reservation.paymentData.paymentTotal = totalPrice;
          let newTodo = oldTodo - difference;
          if (newTodo < 0) newTodo = 0;
          reservation.paymentData.paymentTodo = newTodo;
          setShowPriceConfirmation(false);
        }
      }

      // Send link to user, through which the reservation can be
      // filled, paid and signed.
      if (values.sendStatusToCustomer) {
        reservation.paymentStatus = "PENDING";
        reservation.completeBefore = completeBefore;
        reservation.paymentData = {
          paymentTotal: totalPrice,
          paymentTodo: totalPrice,
        };
        if (company?.enableMustSign) {
          reservation.authStatus = "PENDING_MUST";
        }
      } else {
        // paymentStatus set by User
        if (values.paymentMethod === "none") {
          if (reservationData?.paymentStatus) {
            // TODO: Make sure this works in all scenarios, and maybe implement a check on backend
            reservation.paymentStatus = null;
          } else {
            reservation.paymentStatus = undefined;
          }
        }
        if (values.paymentMethod === "PENDING_INVOICE") {
          reservation.paymentStatus = "PENDING_INVOICE";
        }
        if (values.paymentMethod === "COMPLETED_INVOICE") {
          reservation.paymentStatus = "COMPLETED_INVOICE";
        }
        if (values.paymentMethod === "COMPLETED_MANUAL") {
          reservation.paymentStatus = "COMPLETED_MANUAL";
        }
      }

      // handle wildcard reservations
      if (values?.enableWildCard) {
        const wildcardVehicle = {
          name:
            categories.find(
              (c) =>
                c.id === selectedVehicleClass || c.name === selectedVehicleClass
            )?.name ?? selectedVehicleClass,
          price: values?.wildCardVehicles[0]?.price,
          categoryId: values?.wildCardVehicles[0]?.categoryId,
          quantity: 1,
          tax: values?.wildCardVehicles[0]?.tax,
        };
        reservation.wildCardVehicles = [wildcardVehicle];
        reservation.initialClassInfo = initialClassInfo;
      }

      // handle HANSEL report
      if (values.type === "HANSEL") {
        reservation.type = values.type;
        reservation.hanselData = hanselData;
        delete reservation.hanselData.__typename;
        // add initial information of the selected vehicle class
        if (!reservationData) {
          const wildcardVehicle = {
            name:
              categories.find(
                (c) =>
                  c.id === selectedVehicleClass ||
                  c.name === selectedVehicleClass
              )?.name ?? selectedVehicleClass,
            price: values?.wildCardVehicles[0]?.price,
            categoryId: values?.wildCardVehicles[0]?.categoryId,
            quantity: 1,
          };
          if (wildcardVehicle?.name) {
            reservation.wildCardVehicles = [wildcardVehicle];
          }
          reservation.initialClassInfo = {
            categoryId: initialClassInfo?.categoryId,
            categoryName: initialClassInfo?.categoryName,
            hanselClass: initialClassInfo?.hanselClass,
          };
        }
      }

      // TODO: Should it be possible to use sendStatusToCustomer, billPayment and automatic removal together?
      if (reservationData?.id && FEATURE_INVOICING && billPayment) {
        console.log("Set paymentStatus to PENDING_INVOICE");
        reservation.paymentStatus = "PENDING_INVOICE";
      }

      if (!customerCompanyId && isCompany) {
        const customerInput = {
          group: user?.group ?? undefined,
          orgBusinessId: user?.orgBusinessId ?? undefined,
          organizationId: user?.organizationId ?? undefined,
          companyName: values.companyName,
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          phone: values.phone,
          email: values.email,
          companyBillingAddress: values.companyBillingAddress,
          companyBusinessId: values.companyBusinessId,
          companyEBillingOperator: values.companyEBillingOperator,
          companyEBillingAddress: values.companyEBillingAddress,
          companyBillingRef: values.companyBillingRef,
          clientType: "COMPANY", // Later change to values.clientType
        };

        try {
          const createCustomerResponse = await API.graphql(
            graphqlOperation(createCustomer, {
              input: customerInput,
            })
          );

          reservation.customerCompanyId =
            createCustomerResponse.data.createCustomer.id;
        } catch (error) {
          console.error("Failed to create customer:", error);
        }
      }

      const response = await API.graphql(
        graphqlOperation(upsertReservationAdmin, {
          reservation: reservation,
          sendStatusToCustomer: values.sendStatusToCustomer,
          disableConfirmationEmail: values.disableConfirmationEmail,
        })
      );

      const result = response.data.upsertReservationAdmin;

      if (explanation !== review?.explanation) {
        try {
          await API.graphql(
            graphqlOperation(upsertReview, {
              review: {
                id: review.id,
                reservationId: review.reservationId,
                rating: review.rating,
                comment: review.comment,
                explanation: review.explanation,
              },
            })
          );
        } catch (err) {
          captureError("Upsert review failed", "UPSERT_REVIEW_FAILED", err);
          notification.show("Arvosanan syyn päivitys epäonnistui.");
        }
      }

      if (result.code != null) {
        Sentry.captureMessage("Reservation failed", {
          tags: {
            code: result.code,
          },
        });
        if (result.code === "VEHICLE_NOT_AVAILABLE") {
          notification.show("Ajoneuvo ei ole enää vapaana");
        } else {
          notification.show("Jokin meni vikaan");
        }
      } else {
        if (!result.emailConfirmationSuccess) {
          Sentry.captureMessage("Send confirmation email failed", {
            tags: {
              code: "CONFIRMATION_EMAIL_FAILED",
              destinationEmail: email,
            },
          });

          notification.show(
            "Sähköposti vahvistuksta ei voitu toimittaa asiakkaalle."
          );
        }

        // TODO: Is this ever called nowdays? Do we always use onClose located at DialogComponents dialogAction
        if (closeDialog) {
          onClose(true, true);
        }
      }

      if (result.reservation) {
        // Update local state instantly after upsert
        if (!reservationData || !reservationData?.id) {
          // New Reservation
          mutateLocalReservations("create", result.reservation);
        } else {
          mutateLocalReservations("update", result.reservation);
        }
      }
      notification.show("Varauksen tallennus onnistui", "success");
      return true; // Success
    } catch (err) {
      captureError(
        "Upsert reservation failed",
        "UPSERT_RESERVATION_FAILED",
        err
      );
      notification.show("Varauksen tallennus epäonnistui", "error");
      console.log("UPSERT", err);
    }

    if (explanation !== review?.explanation) {
      try {
        await API.graphql(
          graphqlOperation(upsertReview, {
            review: {
              id: review.id,
              reservationId: review.reservationId,
              rating: review.rating,
              comment: review.comment,
              explanation: review.explanation,
            },
          })
        );
      } catch (err) {
        captureError("Upsert review failed", "UPSERT_REVIEW_FAILED", err);
        notification.show("Arvosanan syyn päivitys epäonnistui.");
      }
    }
  };

  const validate = (values, userRole) => {
    const errors = {};
    const GENERAL_ERROR_MSG = "Täytä tämä kenttä";
    const TIME_ERROR_MSG = "Virheellinen aikamuoto";

    if (
      userRole?.role === "EXTERNAL_BUSINESS_ADMIN" &&
      userRole?.externalBusiness?.type === "INSURANCE"
    ) {
      // TODO: add errors for this role
      if (!values.orgBusinessId) {
        errors.orgBusinessId = "Lisää puuttuva toimipiste";
      }

      if (!values.name) {
        errors.name = GENERAL_ERROR_MSG;
      }
      if (!values.customerCarRegistrationPlate) {
        errors.customerCarRegistrationPlate = GENERAL_ERROR_MSG;
      }

      if (!values.customerCarRegistrationPlate) {
        errors.customerCarRegistrationPlate = GENERAL_ERROR_MSG;
      }
      if (!values.insuranceData.replacementVehicleClass) {
        errors.replacementVehicleClass = GENERAL_ERROR_MSG;
      }
      if (!values.insuranceData.product) {
        errors.insuranceDataProduct = GENERAL_ERROR_MSG;
      }

      if (!values.insuranceData.billingPermitCode) {
        errors.insuranceDataBillingPermitCode = GENERAL_ERROR_MSG;
      }
      if (!values.insuranceCompany) {
        errors.insuranceCompany = "Valitse yhtiö";
      }

      if (!values.phone) {
        errors.phone = GENERAL_ERROR_MSG;
      }

      return errors;
    }

    // TODO: reservations: vehicles
    if (!values.startLocation && USER_ADMIN_CORPORATE) {
      errors.startLocation = GENERAL_ERROR_MSG;
    }

    if (!values.returnLocation && USER_ADMIN_CORPORATE) {
      errors.returnLocation = GENERAL_ERROR_MSG;
    }

    if (values.type !== "HANSEL") {
      if (!values.selectedVehicleClass)
        if (
          vehicleIds.length === 0 &&
          userRole?.role !== "EXTERNAL_BUSINESS_ADMIN"
        ) {
          errors.vehicleIds = "Valitse tuote";
        }
    }
    if (
      values.type === "HANSEL" &&
      !values?.hanselData?.hanselClass &&
      USER_ADMIN_CORPORATE &&
      !reservationData
    ) {
      errors.vehicleIds = "Valitse Hansel luokka";
    }

    if (
      values.type === "HANSEL" &&
      USER_ADMIN_CORPORATE &&
      !values.companyBillingRef
    ) {
      errors.companyBillingRef = "Lisää viite";
    }

    if (
      values.paymentMethod === "COMPLETED_INVOICE" &&
      values.type === "HANSEL"
    ) {
      if (
        !values?.hanselData?.hanselClass ||
        !values?.hanselData?.customerInvoiceId ||
        !values?.hanselData?.customerId
      ) {
        errors.hansel = "Täytä Hansel tiedot";
      }
    }
    if (!startTime) {
      errors.startTime = GENERAL_ERROR_MSG;
    }

    if (!returnTime) {
      errors.returnTime = GENERAL_ERROR_MSG;
    }

    if (!endTime) {
      errors.endTime = GENERAL_ERROR_MSG;
    }

    if (values.sendStatusToCustomer) {
      if (!values.completeBefore) {
        errors.completeBefore = GENERAL_ERROR_MSG;
      }

      if (values.completeBefore < new Date()) {
        errors.completeBefore = "Et voi asettaa aikaa menneisyyteen";
      }
      if (!isValid(values.completeBefore)) {
        errors.completeBefore = TIME_ERROR_MSG;
      }
    }

    if (!isValid(startTime)) {
      errors.startTime = TIME_ERROR_MSG;
    }

    if (!isValid(returnTime)) {
      errors.returnTime = TIME_ERROR_MSG;
    }

    if (!isValid(endTime)) {
      errors.endTime = TIME_ERROR_MSG;
    }

    if (startTime?.getTime() === returnTime?.getTime()) {
      errors.returnTime = "Palautusaika sama kuin noutoaika";
      errors.startTime = "Palautusaika sama kuin noutoaika";
    }

    if (!values.email) {
      errors.email = GENERAL_ERROR_MSG;
    }

    const phoneFields = [
      {
        field: "phone",
        countryCodeField: "phoneCountryCode",
        error: "Tarkista puhelinnumero",
        isEnabled: true,
      },
      {
        field: "driverPhone",
        countryCodeField: "driverPhoneCountryCode",
        error: "Tarkista kuljettajan puhelinnumero",
        isEnabled: values.differentDriver,
      },
      {
        field: "additionalDriverPhone",
        countryCodeField: "additionalDriverPhoneCountryCode",
        error: "Tarkista lisäkuljettajan puhelinnumero",
        isEnabled: values.additionalDriver,
      },
    ];

    phoneFields.forEach(({ field, countryCodeField, error, isEnabled }) => {
      //   If the phone field and the corresponding countryCode field are not empty, do the validation

      if (isEnabled && values[field] && values[field] !== "") {
        try {
          const phoneNumber = parsePhoneNumberWithError(
            values[countryCodeField] + values[field]
          );
          const validNumber =
            isValidPhoneNumber(phoneNumber?.number, phoneNumber?.country) ??
            false;

          if (!validNumber) {
            errors[field] = error;
          }
        } catch (e) {
          if (e instanceof ParseError) {
            // Not a phone number, non-existent country, etc.
            console.log(
              "Error parsing the phone number of field " + field + ":",
              e.message,
              "value:",
              values[countryCodeField],
              values[field]
            );
            errors[field] = error;
          } else {
            throw error;
          }
        }
      }
    });

    if (values.isCompany) {
      if (!values.companyName) {
        errors.companyName = GENERAL_ERROR_MSG;
      }
      if (!values.companyBusinessId) {
        errors.companyBusinessId = GENERAL_ERROR_MSG;
      }
    }

    if (values.isBroker) {
      if (!values.customerBrokerId) {
        errors.customerBrokerId = GENERAL_ERROR_MSG;
      }
    }

    if (values.differentDriver) {
      if (!values.driverName) {
        errors.driverName = GENERAL_ERROR_MSG;
      }

      if (values.additionalDriver) {
        if (!values.additionalDriverName) {
          errors.additionalDriverName = GENERAL_ERROR_MSG;
        }

        if (!values.additionalDriverPhone) {
          errors.additionalDriverPhone = GENERAL_ERROR_MSG;
        }
      }
    }

    return errors;
  };

  const {
    mobileSmViewPort,
    mobileViewPort,
    FEATURE_ENABLE_HANSEL,
    USER_ADMIN_GENERIC,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("info");
  const handleDrawerToggle = () => setOpenDrawer((prevState) => !prevState);

  const FEATURE_INNOVOICE = useMemo(() => {
    return FEATURE_INVOICING;
  }, [FEATURE_INVOICING]);
  /*   const FEATURE_REVIEWS_REPORT = useMemo(() => {
    return devStaging(process.env.REACT_APP_ENV, company);
  }, [company]);
 */
  const FEATURE_LIVION_KEY = React.useMemo(() => {
    // Vuokraapaku REDI
    return !!company?.livionDeviceId;
  }, [company]);

  /*   const FEATURE_ENABLE_VISMA_SIGN = useMemo(() => {
    return enableVismaSign(import.meta.env.REACT_APP_ENV, group);
  }, [group]);
 */
  const storeValuesToCache = async (value) => {
    try {
      await Cache.setItem("adaptiveView", value);
    } catch (error) {
      console.error("Error storing data to cache:", error);
    }
  };

  const menu = [
    {
      title: "Varauksen tiedot",
      subTitle: (
        <>
          <SubMenuScheduleState
            errors={formikRef?.current?.errors}
            values={formikRef?.current?.values}
          />
          {formikRef?.current?.errors?.vehicleIds ? (
            <span style={{ display: "flex", color: "red" }}>
              {formikRef?.current?.errors.vehicleIds}
            </span>
          ) : (
            <span style={{ display: "flex", alignItems: "center" }}>
              Varauksen tuotteet
              <Check
                sx={{ fontSize: "14px", marginLeft: "5px" }}
                color="success"
              />
            </span>
          )}
        </>
      ),
      icon: <TbFileText style={{ fontSize: 28 }} />,
      tab: "info",
      visibility: true,
      active: true,
    },

    {
      title: "Lisäpalvelut",
      subTitle: <>Tuotteilla olevat lisäpalvelut</>,
      icon: <TbApps style={{ fontSize: 28 }} />,
      tab: "additionalServices",
      visibility: true,
      active: true,
    },
    {
      title: "Asiakkaan tiedot",
      subTitle: (
        <>
          {" "}
          <SubMenuCustomerInformation
            errors={formikRef?.current?.errors}
          />{" "}
        </>
      ),
      icon: <TbUserPlus style={{ fontSize: 28 }} />,
      tab: "customer",
      visibility: true,
      active: true,
    },
    {
      title: "Varauksen maksu",
      subTitle: <>Maksun tila:</>,
      icon: <TbCurrencyEuro style={{ fontSize: 28 }} />,
      tab: "payment",
      visibility: USER_ADMIN_GENERIC,
      active: true,
    },

    {
      title: "Varauksen maksu uusi",
      subTitle: <>Maksun tila:</>,
      icon: <TbCurrencyEuro style={{ fontSize: 28 }} />,
      tab: "paymentnew",
      visibility: enableNewPaymentTab(company?.group ?? user?.organizationId),
      active: true,
    },

    {
      title: "Viestit",
      subTitle: <>Sisäiset muistiinpanot & muut viestit</>,
      icon: <TbMessage2 style={{ fontSize: 28 }} />,
      tab: "messages",
      visibility: true,
      active: true,
    },
    {
      title: "Hansel",
      /*    subTitle: errors?.hansel ? (
        <span style={{ color: "red" }}>{errors?.hansel}</span>
      ) : (
        <>Luo Hansel raportti</>
      ), */
      icon: <TbSpy style={{ fontSize: 28 }} />,
      tab: "hansel",
      visibility: FEATURE_ENABLE_HANSEL && !user?.externalBusiness?.type,
      active: FEATURE_ENABLE_HANSEL,
    },
    {
      title: "NPS",
      subTitle: "",
      icon: <TbChartHistogram style={{ fontSize: 28 }} />,
      tab: "nps",
      visibility: !user?.externalBusiness?.type,
      active: true,
    },
  ];

  // EXTERNAL_BUSINESS_ADMIN reservation is always set to company customer, and can only adjust the company & differentDriver information.
  // Insuranceadjuster is the contact person so the fields name & email is autofilled and cannot be modified.
  // differentDriver values are for the insurance customer, additional information is stored in the insuranceData object

  // Set services that are enabled for the Vehicle,
  // or that are set on the reservation.

  const onRemove = async (mutateLocalReservations) => {
    setSubmitting(true);
    try {
      const response = await API.graphql(
        graphqlOperation(deleteReservation, {
          input: {
            id: reservationData?.id,
          },
        })
      );
      const result = response.data.deleteReservation;
      if (result.id) {
        // Update local state instantly after deletion
        mutateLocalReservations("delete", result);
      }
      return onClose(true, true);
    } catch (e) {
      captureError("Delete reservation failed", "DELETE_RESERVATION_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setShowDeleteConfirmation(false);
    setSubmitting(false);
  };

  const sendEmail = async () => {
    setSubmitting(true);
    //  await submitForm();
    await reservationsMutate();
    try {
      const result = await API.graphql(
        graphqlOperation(sendEmailVerification, {
          reservationId: reservationData?.id,
        })
      );
      if (result.data.sendEmailVerification) {
        notification.show("Sähköposti lähetetty");
      } else {
        throw new Error("Email send failed", reservationData?.id);
      }
    } catch (e) {
      console.log(e);
      captureError("Send email verification failed", "SEND_EMAIL_FAILED", e);
      notification.show("Sähköpostin lähetys epäonnistui");
    }
    setSubmitting(false);
    setSendEmailConfirmation(false);
  };

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    if (mobileViewPort) {
      setOpenDrawer((prevState) => !prevState);
    }
  };
  const currentLocation = location.pathname;

  useEffect(() => {
    if (formikRef?.current?.errors) {
      if (Object.keys(formikRef?.current?.errors)?.length > 0) {
        setFormikErrors(true);
      } else {
        setFormikErrors(false);
      }
    } else {
      return setFormikErrors(false);
    }
  }, []);

  return (
    <DialogComponent
      open={true}
      dialogClose={() => {
        if (currentLocation.startsWith("/varaus/")) {
          if (location.state?.from) {
            // Check if there's a previous history stack
            navigate(-1);
          } else {
            navigate("/");
          }
        } else {
          return onClose();
        }
      }}
      dialogDelete={
        reservationData?.id ? () => setShowDeleteConfirmation(true) : null
      }
      maxWidth={"xl"}
      dialogAction={async () => {
        setSubmitting(true);
        if (currentLocation.startsWith("/varaus/")) {
          try {
            const submit = await formikRef.current.submitForm(); // This does not trigger form validation, but it should not be necessary as this dialog should only be visible after user has tried to submit the form the correct way (submitForm())

            if (submit) {
              return navigate("/");
            }
          } catch (e) {
            console.log("error", e);
          }
        } else {
          try {
            const submit = await formikRef.current.submitForm(); // This does not trigger form validation, but it should not be necessary as this dialog should only be visible after user has tried to submit the form the correct way (submitForm())

            if (submit) {
              return onClose(true, true);
            }
          } catch (e) {
            console.log("error", e);
          }
        }
        setSubmitting(false);
      }}
      dialogActionText={"Vahvista"}
      dialogActionColor={formikErrors ? "warning" : "success"}
      dialogActionSubmitting={submitting}
      dialogActionDisabled={disableDialogAction}
      additionalComponent={
        <>
          <span style={{ flex: 1 }} />

          <QuickInfoPayment
            data={reservationData}
            company={company}
            values={formikRef?.current?.values}
          />

          <span style={{ flex: 1 }} />
        </>
      }
      testName="newReservationDialog"
      removePadding={USER_ADMIN_INSURANCE ? 0 : undefined}
      height={!mobileSmViewPort && "90vh"}
    >
      {!USER_ADMIN_INSURANCE && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            direction: "row",
            position: mobileSmViewPort ? "sticky" : undefined,
            top: 1,
            zIndex: 999,
            bgcolor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            borderWidth: "0 0 1px 0 ",
          }}
        >
          {mobileSmViewPort && !adaptiveView && (
            <IconButton
              disabled={submitting}
              onClick={() => {
                handleDrawerToggle();
              }}
              data-cy="reservationDialogOpenSidebarMobile"
            >
              <TbMenu2 color="primary" style={{ fontSize: "30px" }} />
            </IconButton>
          )}
          <span style={{ flex: 1 }} />
          <ReservationTitle data={reservationData} visibility={true} />
          {USER_ADMIN_GENERIC && (
            <Stack direction={"row"} alignItems={"center"}>
              {FEATURE_LIVION_KEY && (
                <Box>
                  <Tooltip title={"Luo uusi Livion Key koodi"} placement="top">
                    <IconButton
                      disabled={submitting}
                      onClick={() => {
                        //   initLivionKey();
                      }}
                    >
                      <TbKey style={{ fontSize: "30px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <PrintingComponent
                setCloseDialog={setCloseDialog}
                handleSubmit={() => formikRef?.current?.submitForm()}
                values={formikRef?.current?.values}
                errors={formikRef?.current?.errors}
              />
              <Box>
                <Tooltip
                  title={"Tallenna ja lähetä varausvahvistus"}
                  placement="top"
                >
                  <IconButton
                    disabled={submitting}
                    onClick={() => {
                      setSendEmailConfirmation(true);
                    }}
                  >
                    <TbMailFast style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Box data-cy="changeReservationDialogView">
                <Tooltip title={"Vaihda näkymä"} placement="top">
                  <IconButton
                    disabled={submitting}
                    onClick={() => {
                      setAdaptiveView((prevState) => !prevState);
                      storeValuesToCache(!adaptiveView);
                    }}
                  >
                    <TbLayoutBoardSplit style={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          )}
        </Box>
      )}

      <DialogMenu
        openDrawer={openDrawer}
        handleDrawerToggle={handleDrawerToggle}
        selectedProductTab={selectedTab}
        setSelectedProductTab={selectedTab}
        handleSelectTab={handleSelectTab}
        menu={menu}
        minHeight={"750px"}
        adaptiveView={adaptiveView}
      >
        <Formik
          innerRef={formikRef}
          //          validateOnChange={false}
          onSubmit={(values, actions) => onSubmit(values)}
          initialValues={initializeFormikData}
          validate={(formikValues) => {
            const errors = validate(formikValues, user);
            // console.log("errors", errors);
            if (Object.keys(errors)?.length > 0) {
              setFormikErrors(true);
            } else {
              setFormikErrors(false);
            }
            return errors;
          }}
        >
          {({ handleSubmit }) => (
            <Box sx={{ display: "flex", width: "100%", padding: "5px" }}>
              <form onSubmit={handleSubmit}>
                <ReservationForm
                  selectedTab={selectedTab}
                  adaptiveView={adaptiveView}
                  FEATURE_INVOICING={FEATURE_INVOICING}
                  FEATURE_INNOVOICE={FEATURE_INNOVOICE}
                  setShowInvoiceDialog={setShowInvoiceDialog}
                  review={review}
                  setReview={setReview}
                  setExplanation={setExplanation}
                />
              </form>
            </Box>
          )}
        </Formik>
      </DialogMenu>

      <DialogComponent
        open={showDeleteConfirmation}
        dialogClose={() => setShowDeleteConfirmation(false)}
        dialogAction={() => onRemove(mutateLocalReservations)}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
        testName="newDeleteReservationDialog"
      >
        Haluatko varmasti poistaa varauksen?
      </DialogComponent>
      <DialogComponent
        open={showPriceConfirmation}
        dialogClose={() => setShowPriceConfirmation(false)}
        dialogAction={async () => {
          setSubmitting(true);
          await formikRef.current.submitForm();
          setSubmitting(false);
          closeDialog();
        }} // This does not trigger form validation, but it should not be necessary as this dialog should only be visible after user has tried to submit the form the correct way (submitForm())
        dialogActionText={"Vahvista"}
        dialogCloseText={"Peruuta"}
        dialogActionColor={"primary"}
        dialogTitle={"Vahvista muuttunut hinta"}
      >
        {`Varauksen kokonaishinta on muuttunut ${centsToLocalString(
          reservationData?.paymentData?.paymentTotal
        )} -> ${centsToLocalString(totalPrice)}`}
      </DialogComponent>

      <DialogComponent
        open={sendEmailConfirmation}
        dialogClose={() => setSendEmailConfirmation(false)}
        dialogAction={() => sendEmail()}
        dialogActionText={"Lähetä"}
        dialogActionColor={"success"}
        dialogActionDisabled={submitting}
        dialogActionSubmitting={submitting}
      >
        Haluatko varmasti lähettää varausvahvistuksen?
      </DialogComponent>
      {!!showInvoiceDialog && (
        <InvoiceDialog
          title="Laskutus"
          onClose={() => setShowInvoiceDialog(false)}
          invoiceState={showInvoiceDialog}
          reservationId={reservationData?.id}
          reservationValues={formikRef?.current?.values}
          FEATURE_INVOICING={FEATURE_INVOICING}
        />
      )}
    </DialogComponent>
  );
}

const ReservationTitle = ({ data, visibility }) => {
  const { USER_ADMIN_INSURANCE, mobileSmViewPort } = useContext(AppContext);

  if (data && !mobileSmViewPort && visibility)
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {data?.startTime && data?.returnTime && (
          <Stack direction={mobileSmViewPort ? "column " : "row"}>
            <Typography sx={{ fontFamily: " Sofia Pro", fontSize: "1.4rem" }}>
              Varaus:{" "}
            </Typography>

            <Typography sx={{ fontFamily: " Sofia Pro", fontSize: "1.4rem" }}>
              {isValid(data?.startTime) &&
                format(data?.startTime, "dd.MM.yyyy", {
                  locale: fi,
                })}{" "}
              -{" "}
              {isValid(data?.returnTime) &&
                format(data?.returnTime, "dd.MM.yyyy", {
                  locale: fi,
                })}
            </Typography>
          </Stack>
        )}

        {data?.status === "EXTERNAL_PENDING_DEALER" && (
          <Typography sx={{ fontFamily: " Sofia Pro", fontSize: "1.4rem" }}>
            Toimeksianto
          </Typography>
        )}
        {!USER_ADMIN_INSURANCE && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography sx={{ fontWeight: 600, color: "rgba(0,0,0,0.45)" }}>
              Asiakas:{" "}
            </Typography>
            <Typography>{data?.name}</Typography>
          </Box>
        )}
      </Box>
    );
  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography sx={{ fontFamily: "Sofia Pro", fontSize: "1.4rem" }}>
          Uusi varaus
        </Typography>
      </Box>
    );
  }
};

const PaymentStatusComponent = ({ status }) => {
  const { mobileSmViewPort } = useContext(AppContext);

  if (status !== "COMPLETED") return null;

  return mobileSmViewPort ? (
    <OnlinePaymentCompletedIcon />
  ) : (
    <Typography variant="h6" align="center">
      Maksu suoritettu (Visma pay)
    </Typography>
  );
};

const AuthStatusComponent = ({ status, signerName }) => {
  const { mobileSmViewPort } = useContext(AppContext);

  if (status !== "COMPLETED") return null;

  return mobileSmViewPort ? (
    <AuthStatusCompletedIcon />
  ) : (
    <Typography variant="h6" align="center">
      {`Telia vahvasti tunnistautunut: ${signerName}`}
    </Typography>
  );
};

const StatusDisplay = ({ paymentStatus, authStatus, signerName }) => {
  const { mobileSmViewPort } = useContext(AppContext);

  return (
    <Box
      display={mobileSmViewPort ? "flex" : "block"}
      justifyContent="center"
      alignItems="center"
    >
      <PaymentStatusComponent status={paymentStatus} />
      <AuthStatusComponent status={authStatus} signerName={signerName} />
    </Box>
  );
};

function QuickInfoPayment({ data, company, values }) {
  const { USER_ADMIN_INSURANCE, USER_ADMIN_CORPORATE } = useContext(AppContext);
  const { totalPrice, reservationData } = useReservationContext();
  if (USER_ADMIN_INSURANCE) return null;
  if (USER_ADMIN_CORPORATE) return null;
  return (
    <Box>
      <PendingStatuses data={data} company={company} values={values} />
      <Typography variant="h6" align="center">
        <b>Yhteensä:</b>{" "}
        {totalPrice != null && Number.isInteger(totalPrice)
          ? centsToLocalString(totalPrice) + " €"
          : "-"}
      </Typography>
      <StatusDisplay
        paymentStatus={reservationData?.paymentStatus}
        authStatus={reservationData?.authStatus}
        signerName={reservationData?.signerName}
      />
    </Box>
  );
}

const PendingStatuses = ({ data, company, values }) => {
  if (!values) return null;
  let completeBefore = data?.completeBefore;
  let sendStatus = false;
  if (values?.sendStatusToCustomer || values?.paymentMethod === "online") {
    completeBefore = values?.completeBefore;
    sendStatus = true;
  }
  if (
    (data?.authStatus === "PENDING_MUST" &&
      data?.paymentStatus === "PENDING") ||
    (sendStatus && company?.enableMustSign && company?.enablePaymentOnlineOnly)
  ) {
    return (
      <Typography variant="h6">
        {`Odottaa vahvaa tunnistautumista ja maksutavan valintaa ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  // This state should not happen currently
  if (
    data?.authStatus === "PENDING_MUST" ||
    (sendStatus && company?.enableMustSign)
  ) {
    return (
      <Typography variant="h6">
        {`Odottaa vahvaa tunnistautumista ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  if (sendStatus && isValid(completeBefore)) {
    return (
      <Typography variant="h6">
        {`Odottaa varauksen täydentämistä ${format(
          new Date(completeBefore),
          "Pp",
          { locale: fi }
        )} mennessä`}
      </Typography>
    );
  }
  return <PaymentStatus data={data} company={company} values={values} />;
};

const PaymentStatus = ({ data, company, values }) => {
  const paymentStatusMsg = useMemo(() => {
    if (data?.paymentStatus === "COMPLETED") {
      return null;
    } else if (data?.paymentStatus === "PENDING") {
      // Reservations in PENDING state will be removed if not completed in time
      let deadlineDate = new Date(
        new Date(data?.updatedAt).getTime() + 60000 * 25
      ); // 25minutes
      if (data?.completeBefore) {
        const completeBeforeDate = new Date(data?.completeBefore);
        if (completeBeforeDate > deadlineDate) {
          deadlineDate = completeBeforeDate;
        }
      }
      const paymentBefore = new Date(deadlineDate);
      if (company?.enablePaymentOnlineOnly) {
        return `Odottaa verkkomaksua ${format(
          paymentBefore,
          "HH:mm"
        )} mennessä`;
      }
      return `Odottaa maksutavan valintaa ${format(
        paymentBefore,
        "HH:mm"
      )} mennessä`;
    } else if (data?.paymentStatus === "COMPLETED_DEPOSIT") {
      return "Varausmaksu suoritettu";
    } else if (data?.paymentStatus === "PENDING_FULL") {
      return "Varausmaksu suoritettu. Odottaa verkkomaksua lopuista.";
    } else if (values.billPayment === true) {
      return "";
    } else if (
      data?.paymentStatus === "PENDING_INVOICE" ||
      values?.paymentMethod === "invoice"
    ) {
      return "Odottaa laskutusta";
    } else {
      return "Maksu noudettaessa";
    }
  }, [company, data, values.billPayment, values.paymentMethod]);

  return <Typography variant="h6">{paymentStatusMsg}</Typography>;
};

function SubMenuScheduleState(props) {
  const { startTime, returnTime } = useReservationContext();
  if (!startTime || !returnTime) {
    return <span style={{ color: "red" }}>Valittu aika on virheellinen</span>;
  }

  if (!startTime) {
    return <span style={{ color: "red" }}>Ei valittua aloitusaikaa</span>;
  }

  if (!returnTime) {
    return <span style={{ color: "red" }}>Ei valittua palautusaikaa</span>;
  }

  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      Ajankohta
      <Check sx={{ fontSize: "14px", marginLeft: "5px" }} color="success" />
    </span>
  );
}

function SubMenuCustomerInformation({ errors }) {
  if (errors?.email) {
    return <span style={{ color: "red" }}>Tarkista asiakkaan sähköposti</span>;
  }

  if (errors?.phone) {
    return <span style={{ color: "red" }}>{errors?.phone}</span>;
  }

  if (errors?.driverPhone) {
    return <span style={{ color: "red" }}>{errors?.driverPhone}</span>;
  }
  if (errors?.companyBillingRef) {
    return <span style={{ color: "red" }}>{errors?.companyBillingRef}</span>;
  }
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      Sähköposti
      <Check sx={{ fontSize: "14px", marginLeft: "5px" }} color="success" />
    </span>
  );
}

/*

  const resetSign = async () => {
    setSubmitting(true);
    await submitForm();
    try {
      const result = await API.graphql(
        graphqlOperation(resetAndInitSign, {
          reservationId: reservationData.id,
        })
      );
      if (result.data.resetAndInitSign.success) {
        notification.show(
          "Uusi sopimus on luotu ja allekirjoituspyyntö lähetetty"
        );
        await reservationsMutate();
      } else {
        throw new Error("Reset and init visma sign failed", reservationData.id);
      }
    } catch (e) {
      console.log(e);
      captureError(
        "Reset and init Visma Sign failed",
        "RESET_VISMA_SIGN_FAILED",
        e
      );
      notification.show("Uutta sopimusta luodessa tapahtui virhe");
    }
    setSubmitting(false);
  };

*/

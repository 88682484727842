import { useContext } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { AppContext } from "../../../../../../../../AppContext";
import { useFormikContext } from "formik";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function SelectContractCustomer(props) {
  const { visibility } = props;
  const { user } = useContext(AppContext);
  const { setFieldValue } = useFormikContext();
  const listOfCustomers = user?.externalBusinessByOrg?.items.sort((a, b) =>
    a?.name.toString().localeCompare(b?.name.toString())
  );
  const { values } = useFormikContext();
  const reservationData = useAtomValue(reservationDataAtom);

  if (visibility)
    return (
      <FormControl fullWidth>
        <InputLabel id="select-franchising-label">
          Valitse sopimushinta-asiakas
        </InputLabel>
        <Select
          // disabled={!enableContractCustomer}
          labelId="select-franchising-label"
          id="select-franchising"
          label=" Valitse sopimushinta-asiakas"
          value={values.orgExternalBusinessId || ""}
          disabled={
            // disable select component for external reservations from LÄHITAPIOLA
            reservationData?.type === "INSURANCE" &&
            reservationData?.orgExternalBusinessId ===
              "77010970-14c1-4936-9a41-3054522a56a8"
              ? true
              : false
          }
          onChange={async (e) => {
            await setFieldValue("orgExternalBusinessId", e.target.value);
            await setFieldValue("pricingCategoryId", null);
          }}
          MenuProps={{
            PaperProps: { sx: { maxHeight: { xs: "40vh", sm: "100%" } } },
          }}
        >
          <MenuItem value={null}>
            <b>Ei asiakasta</b>
          </MenuItem>
          {listOfCustomers &&
            listOfCustomers.map((customer) => (
              <MenuItem value={customer?.id} key={customer?.id}>
                {customer?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}

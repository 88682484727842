import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import ProductImage from "./ProductImage";
import { centsToLocalString } from "../shared/money";
import { Stack } from "@mui/system";
import { Add, Check } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function ProductCard(props) {
  const {
    productData,
    offerPrice,
    loading,
    selectedProducts,
    setSelectedProducts,
  } = props;
  const isSelected = !!selectedProducts?.find((p) => p.id === productData.id);

  function handleClickProduct(id) {
    if (isSelected) {
      const productRemoved = selectedProducts.filter((p) => p.id !== id);

      return setSelectedProducts(productRemoved);
    }
    return setSelectedProducts((prevState) => [
      ...prevState,
      { ...productData, price: offerPrice },
    ]);
  }

  return (
    <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          m: 2,
          p: 1,
          border: "1px solid rgba(0,0,0,0.17)",
          borderRadius: "10px",
          transition: "150ms ease-in-out",
        }}
      >
        <Image vehicleId={productData?.id} loading={loading} />
        <ProductTitle productData={productData} loading={loading} />
        <ProductPriceField offerPrice={offerPrice} loading={loading} />
      </Box>
      <IconButton onClick={() => handleClickProduct(productData?.id)}>
        <SelectedStateIcon isSelected={isSelected} />
      </IconButton>
    </Stack>
  );
}

function OfferPrice(props) {
  const { offerPrice } = props;

  if (offerPrice) {
    return (
      <Typography variant="body2" sx={{ fontWeight: 550 }}>
        {centsToLocalString(offerPrice)} €
      </Typography>
    );
  }
}

function Image(props) {
  const { loading, vehicleId } = props;

  if (loading) {
    return (
      <Skeleton
        sx={{ marginRight: "10px" }}
        variant="circular"
        width={40}
        height={40}
      />
    );
  }

  return <ProductImage vehicleId={vehicleId} />;
}

function ProductTitle(props) {
  const { productData, loading } = props;

  if (loading)
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Skeleton
          variant="rectangular"
          width={210}
          height={20}
          sx={{ margin: "2px" }}
        />
        <Skeleton
          variant="rectangular"
          width={210}
          height={10}
          sx={{ margin: "2px" }}
        />
      </Box>
    );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="body1">{productData?.name}</Typography>
      <Typography variant="caption">
        {productData?.registrationPlate}
      </Typography>
    </Box>
  );
}

function ProductPriceField(props) {
  const { offerPrice, loading } = props;

  if (loading) {
    return (
      <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <Skeleton
          variant="rectangular"
          width={65}
          height={30}
          sx={{ margin: "2px" }}
        />
      </Box>
    );
  }
  return (
    <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
      <OfferPrice offerPrice={offerPrice} />
    </Box>
  );
}

function SelectedStateIcon(props) {
  const { isSelected } = props;
  const theme = useTheme();

  if (isSelected) {
    return (
      <Check sx={{ color: theme.palette.success.main, fontSize: "32px" }} />
    );
  }

  return <Add sx={{ color: theme.palette.success.main, fontSize: "32px" }} />;
}

import CustomerInformationTab from "./tabs/customerInformation";
import Summary from "./tabs/summary";
import ReservationPayment from "./tabs/payment";
import ReservationPaymentNew from "./tabs/payment_new";
import ReservationMessages from "./tabs/messages";
import ReviewExplanation from "./tabs/review/ReviewExplanation";
import AdditionalServicePage from "../../../components/AdditionalServices/";
import { Stack } from "@mui/material";
import HanselFields from "./tabs/hansel";
import { useContext } from "react";
import { ReservationContext } from "../../../contextProviders/ReservationContext";
import ReservationSchedule from "../../../components/ReservationForm/ReservationSchedule";
import PickupLocations from "./tabs/summary/components/PickupLocations";
import SelectContractCustomer from "./tabs/summary/components/SelectContracCustomer";
import SelectContractVehicleClass from "./tabs/summary/components/SelectContractVehicleClass";

export default function Content(props) {
  const { selectedTab, showAllContent, containerRef } = props;
  const { summaryView } = useContext(ReservationContext);
  if (summaryView) {
    return (
      <Stack spacing={1} sx={{ marginTop: "25px" }}>
        <ReservationSchedule />
        <PickupLocations visibility={true} />
        <Stack direction={"row"} spacing={1}>
          <SelectContractCustomer visibility={true} />
          <SelectContractVehicleClass visibility={true} />
        </Stack>
        <CustomerInformationTab />
        <ReservationMessages />
        <ReviewExplanation />
      </Stack>
    );
  }

  if (showAllContent) {
    return (
      <Stack spacing={1}>
        <CustomerInformationTab />
        <Summary containerRef={containerRef} />
        <AdditionalServicePage />
        <ReservationPayment />
        <ReservationMessages />
        <ReviewExplanation />
      </Stack>
    );
  }
  switch (selectedTab) {
    case "additionalServices":
      return <AdditionalServicePage />;

    case "customer":
      return <CustomerInformationTab />;

    case "summary":
      return <Summary containerRef={containerRef} />;

    case "payment":
      return <ReservationPayment />;

    case "paymentNew":
      return <ReservationPaymentNew />;

    case "messages":
      return <ReservationMessages />;

    case "nps":
      return <ReviewExplanation />;
    case "hansel":
      return <HanselFields />;

    default:
      return <CustomerInformationTab />;
  }
}

export const contentSteps = [
  { key: "customer", value: <CustomerInformationTab /> },
  { key: "summary", value: <Summary /> },
  { key: "additionalServices", value: <AdditionalServicePage /> },
  { key: "payment", value: <ReservationPayment /> },
  { key: "nps", value: <ReviewExplanation /> },
  { key: "hansel", value: <HanselFields /> },
  { key: "messages", value: <ReservationMessages /> },
];

import { Box, Button, Stack, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import SpecialHoursDialog from "../SpecialHoursDialog";
import { useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

const FormikHourTextField = ({ name, index, ...props }) => {
  const path = ["weeklyBusinessHours", index, name];
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext();
  const get = (obj, properties) => {
    return properties.reduce((value, property) => value?.[property], obj);
  };

  return (
    <TextField
      value={values.weeklyBusinessHours[index][name]}
      onChange={handleChange}
      onBlur={handleBlur}
      name={"weeklyBusinessHours." + index + "." + name}
      variant="outlined"
      error={get(touched, path) && !!get(errors, path)}
      helperText={get(touched, path) && get(errors, path)}
      fullWidth
      {...props}
    />
  );
};

export default function CompanyBusinessHours({ weekdays }) {
  const { company } = useContext(AppContext);

  const [showSpecialHours, setShowSpecialHours] = useState(false);
  return (
    <>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {weekdays.map((w) => (
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "12px",
              marginBottom: "12px",
            }}
            key={w.index}
          >
            <Box
              sx={{
                width: "40px",
                display: "flex",
                height: "56px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {w.label}
            </Box>
            <Box sx={{ width: "100px", marginLeft: "10px" }}>
              <FormikHourTextField
                name="openAt"
                index={w.index}
                label="Aukeaa"
              />
            </Box>
            <Box sx={{ marginLeft: "10px", marginRight: "10px" }}>-</Box>
            <Box sx={{ width: "100px" }}>
              <FormikHourTextField
                name="closeAt"
                index={w.index}
                label="Sulkeutuu"
              />
            </Box>
          </Stack>
        ))}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            onClick={() => setShowSpecialHours((prevState) => !prevState)}
          >
            Erikoisaukioloajat
          </Button>
        </Box>
      </Box>
      {showSpecialHours && (
        <SpecialHoursDialog
          open
          onClose={(success) => {
            setShowSpecialHours(false);
            if (success) {
            }
          }}
          company={company}
        />
      )}
    </>
  );
}

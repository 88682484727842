import React, { useMemo, useState, useContext } from "react";
import { useSWRConfig } from "swr";
import { AppContext } from "~/AppContext";
import { useNavigate } from "react-router-dom";

import {
  Badge,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";

import { GetLogo } from "~/components/getLogo";

import { useFetchCompanies } from "~/services/fetchCompanies";
import { useFetchExternalReservations } from "~/services/fetchReservations";

import {
  MdAssignmentAdd,
  MdBarChart,
  MdBusinessCenter,
  MdCalendarToday,
  MdLibraryAdd,
  MdLogout,
  MdManageSearch,
  MdOutlineSettings,
  MdPostAdd,
} from "react-icons/md";
import {
  AccountBox,
  Article,
  BarChart,
  DynamicFeed,
  EditAttributes,
  Euro,
  Home,
  LocalOffer,
  People,
  FileCopyOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { GiPlasticDuck } from "react-icons/gi";
import { useModal } from "~/modals/hooks";
import { EModal } from "~/modals/enums";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

export default function SideMenu(props) {
  const {
    drawerOpen,
    handleNavigate,
    iconSize,
    selectedPage,
    setCustomerSearch,
    setDrawerOpen,
    setShowNews,
    setShowAdditionalServices,
    setShowCategories,
    setShowHansel,
    showExternalReservationsLink,
    setShowPricing,
    setShowProductAvailabilityWidget,
    setShowReviewsReport,
    setShowUserManagementDialog,
    setShowVouchers,
    signOut,
  } = props;
  const {
    business,
    company,
    enableNPS,
    setCompany,
    setSelectedBusiness,
    setShowAllCompanies,

    setEditVehicleData,
    showAllCompanies,
    setShowCustomerManagementDialog,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    USER_ADMIN_INSURANCE,
    user,
    legacyUser,
  } = useContext(AppContext);

  const { openModal } = useModal();

  const setEditReservationData = useSetAtom(reservationDataAtom);
  const { companies } = useFetchCompanies(user?.group, business?.id);
  const { externalReservations } = useFetchExternalReservations();
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const theme = useTheme();
  const businesses = user?.businessByOrg?.items;

  const companySettingsVisibility = useMemo(() => {
    if (USER_ADMIN_INSURANCE) {
      return false;
    }
    if (USER_ADMIN_CORPORATE) {
      return false;
    }
    if (USER_ADMIN_GENERIC) {
      if (user?.role !== "ORGANIZATION_ADMIN") {
        return true;
      } else {
        if (!business) {
          return false;
        }
        return true;
      }
    }
    return false;
  }, [
    USER_ADMIN_CORPORATE,
    USER_ADMIN_INSURANCE,
    USER_ADMIN_GENERIC,
    business,
    user?.role,
  ]);

  const accessUserManagement = useMemo(() => {
    const accessGranted = user?.permissions?.userManagement;
    if (user?.organizationId) {
      if (accessGranted === undefined || accessGranted) {
        // Access granted or property doesn't exist
        return true;
      } else {
        // Access denied
        return false;
      }
    } else {
      // No organization id, no access
      return false;
    }
  }, [user]);

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.drawerBackground,
          borderRadius: 0,
        },
      }}
      data-cy="sideMenu"
    >
      <Box
        sx={{
          display: "flex",
          width: "250px",
          alignSelf: "center",
          marginY: "15px",
          bgcolor: "white",
          borderRadius: "7px",
        }}
      >
        <GetLogo />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "12px",
          minWidth: "300px",
          flex: 1,
        }}
      >
        <DrawerTitle title={"TOIMIPISTE"} visibility={true} />

        <List>
          <CompaniesMenu
            companies={companies}
            company={company}
            selectedPage={selectedPage}
            setCompany={setCompany}
            setShowAllCompanies={setShowAllCompanies}
            showAllCompanies={showAllCompanies}
            visibility={USER_ADMIN_GENERIC}
          />
          <BusinessMenu
            businesses={businesses}
            business={business}
            setShowAllCompanies={setShowAllCompanies}
            setSelectedBusiness={setSelectedBusiness}
            selectedPage={selectedPage}
            visibility={user?.role === "ORGANIZATION_ADMIN"}
          />
        </List>

        <DrawerTitle title={"NAVIGOINTI"} visibility={true} />
        <List>
          <DrawerMenuItem
            title={"Varauskalenteri"}
            icon={<MdCalendarToday style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            navigateTo={"eventsCalendar"}
            onClick={() => {
              setDrawerOpen(false);
              handleNavigate("eventsCalendar");
            }}
            visibility={USER_ADMIN_GENERIC || USER_ADMIN_INSURANCE}
          />
          <DrawerMenuItem
            title={"Toimeksiannot"}
            icon={
              <Badge color="error" badgeContent={externalReservations?.length}>
                <MdAssignmentAdd style={{ fontSize: 24 }} />
              </Badge>
            } // replace with the desired icon
            selectedPage={selectedPage}
            navigateTo={"externalReservations"}
            onClick={() => {
              setDrawerOpen(false);
              handleNavigate("externalReservations");
            }}
            visibility={showExternalReservationsLink}
          />
          <DrawerMenuItem
            title={"Raportointi"}
            icon={<MdBarChart style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            navigateTo={"economy"}
            onClick={() => {
              setDrawerOpen(false);
              handleNavigate("economy");
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          <DrawerMenuItem
            title={"Asiakashaku"}
            icon={<SearchIcon style={{ fontSize: iconSize }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setCustomerSearch((prevState) => !prevState);
            }}
            visibility={true}
          />
          <DrawerTitle title={"TOIMINNOT"} visibility={true} />
          <DrawerMenuItem
            title={"Uusi varaus"}
            icon={<MdPostAdd style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setEditReservationData(true);
            }}
            visibility={true}
          />

          <DrawerMenuItem
            title={"Lisää tuote"}
            icon={<MdLibraryAdd style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setEditVehicleData(true);
            }}
            visibility={USER_ADMIN_GENERIC && (business || user?.group)}
          />
          <DrawerMenuItem
            title={"Tuotehaku"}
            icon={<MdManageSearch style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowProductAvailabilityWidget(true);
            }}
            visibility={
              user?.organizationId &&
              !USER_ADMIN_INSURANCE &&
              !user?.externalBusinessId
            }
          />
          <DrawerMenuItem
            title={"Hinnastot"}
            icon={<Euro style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowPricing(true);
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          <DrawerMenuItem
            title={"Lisäpalvelut"}
            icon={<EditAttributes style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowAdditionalServices(true);
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          <DrawerMenuItem
            title={"Alekoodit"}
            icon={<LocalOffer style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowVouchers(true);
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          <DrawerMenuItem
            title={"Kategoriat"}
            icon={<DynamicFeed style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowCategories(true);
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          {USER_ADMIN_GENERIC && user.organizationId && (
            <DrawerMenuItem
              title="Laskutettavat"
              disabled={!business?.id}
              icon={<FileCopyOutlined style={{ fontSize: 24 }} />}
              selectedPage={selectedPage}
              onClick={() => {
                setDrawerOpen(false);
                openModal(EModal.CONSOLIDATED_INVOICES);
              }}
              visibility={USER_ADMIN_GENERIC}
            />
          )}
          <DrawerMenuItem
            title={"NPS"}
            icon={<BarChart style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowReviewsReport(true);
            }}
            visibility={enableNPS}
          />

          <DrawerMenuItem
            title={"Hansel: Asiakkaat & Tuotteet"}
            icon={<GiPlasticDuck style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowHansel(true);
            }}
            visibility={false}
          />

          <DrawerMenuItem
            title={"Toimipisteen asetukset"}
            icon={<MdOutlineSettings style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              navigate("/asetukset");
            }}
            visibility={companySettingsVisibility}
          />

          <DrawerMenuItem
            title={"Asiakasrekisteri"}
            icon={<People style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowCustomerManagementDialog(true);
            }}
            visibility={
              legacyUser ||
              (!!business && user?.role === "BUSINESS_ADMIN") ||
              user?.role === "ORGANIZATION_ADMIN"
            }
          />

          <DrawerMenuItem
            title={"Käyttäjien hallinta"}
            icon={<AccountBox style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowUserManagementDialog(true);
            }}
            visibility={accessUserManagement}
          />

          <DrawerMenuItem
            title={"Tiedotteet"}
            icon={<Article style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              setDrawerOpen(false);
              setShowNews(true);
            }}
            visibility={USER_ADMIN_GENERIC}
          />
          {/*   <DrawerMenuItem
              title={mobileViewPort ? "Tiivisnäkymä" : "Työpöytänäkymä"}
              icon={
                mobileViewPort ? (
                  <FaMobileRetro style={{ fontSize: 24 }} />
                ) : (
                  <FaDesktop style={{ fontSize: 24 }} />
                )
              }
              selectedPage={selectedPage}
              onClick={() => {
                if (manualMobileView === "disabled") {
                  storeValuesToCache("enabled");
                  setManualMobileView("enabled");
                }

                if (manualMobileView === "enabled") {
                  storeValuesToCache("disabled");
                  setManualMobileView("disabled");
                }
                setDrawerOpen(false);
              }}
              visibility={USER_ADMIN_GENERIC}
            /> */}
          <DrawerMenuItem
            title={"Kirjaudu ulos"}
            icon={<MdLogout style={{ fontSize: 24 }} />}
            selectedPage={selectedPage}
            onClick={() => {
              // Invalidate all SWR keys on logout
              mutate(
                (key) => true, // which cache keys are updated
                undefined, // update cache data to `undefined`
                { revalidate: false } // do not revalidate
              );
              setDrawerOpen(false);
              signOut();
            }}
            visibility={true}
          />
        </List>
        <Box
          sx={{
            display: "flex",
            flexBasis: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 1,
            marginBottom: "25px",
          }}
        >
          <Typography
            variant="body2"
            color={theme.palette.primary.drawerText}
            sx={{
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: "3px",
            }}
          >
            Asiakaspalvelu
          </Typography>

          <Typography variant="caption">
            <a
              style={{ color: "grey", textDecoration: "unset" }}
              href="mailto:tuki@rowly.fi"
            >
              tuki@rowly.fi
            </a>
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
function DrawerTitle({ title, visibility }) {
  const theme = useTheme();

  if (!visibility) return;

  return (
    <Typography
      variant="caption"
      sx={{
        fontWeight: 700,
        marginLeft: "20px",

        color: theme.palette.primary.drawerTitle,
      }}
    >
      {title}
    </Typography>
  );
}

function DrawerMenuItem({
  title,
  icon,
  selectedPage,
  navigateTo,
  onClick,
  visibility,
  disabled,
}) {
  const theme = useTheme();

  if (!visibility) return;

  return (
    <ListItem
      onClick={disabled ? undefined : onClick}
      sx={
        disabled
          ? {
              opacity: 0.5,
              color: theme.palette.primary.drawerText,
            }
          : {
              transition: "150ms ease-in-out",
              "&:hover": {
                bgcolor: "rgba(0,0,0,0.07)",
                color: theme.palette.primary.drawerText,
              },
              cursor: "pointer",
              bgcolor:
                selectedPage === navigateTo
                  ? theme.palette.primary.main
                  : "transparent",
              borderRadius: "10px",
              color:
                selectedPage === navigateTo
                  ? theme.palette.primary.drawerSelected
                  : theme.palette.primary.drawerText,
            }
      }
    >
      <ListItemIcon
        sx={{
          color:
            selectedPage === navigateTo
              ? theme.palette.primary.drawerSelected
              : theme.palette.primary.drawerText,
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText>
        <b>{title}</b>
      </ListItemText>
    </ListItem>
  );
}

function CompaniesMenu(props) {
  const {
    companies,
    company,
    selectedPage,
    setCompany,
    setShowAllCompanies,
    showAllCompanies,
    visibility,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (e) => {
    return setAnchorEl(e.currentTarget);
  };

  if (visibility)
    return (
      <React.Fragment>
        <DrawerMenuItem
          title={
            showAllCompanies
              ? "Näytä kaikki toimipisteet"
              : ` ${company?.name ? company?.name : ""}`
          }
          icon={<Home style={{ fontSize: 24 }} />}
          onClick={handleOpenMenu}
          visibility={true}
          selectedPage={selectedPage}
        />

        <Menu
          id="providers-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "300px",
              backgroundColor: "white",
            },
          }}
        >
          <MenuItem
            value={"allCompanies"}
            onClick={() => {
              setShowAllCompanies(true);
              setAnchorEl(null);
            }}
          >
            <Typography sx={{ fontWeight: 550 }}>
              Kaikki toimipisteet
            </Typography>
          </MenuItem>
          {companies &&
            companies
              // Do not list selected company
              .map((c) => {
                return (
                  <MenuItem
                    key={c?.id}
                    onClick={(e) => {
                      setCompany(c);
                      setShowAllCompanies(false);

                      setAnchorEl(null);
                    }}
                    selected={c?.id === company?.id}
                    value={c}
                  >
                    <Typography>{c?.name}</Typography>
                  </MenuItem>
                );
              })}
        </Menu>
      </React.Fragment>
    );
}

function BusinessMenu(props) {
  const {
    businesses,
    business,
    setShowAllCompanies,
    setSelectedBusiness,
    selectedPage,
    visibility,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleChangeBusiness = (value) => {
    if (value === "organizationAdmin") {
      setShowAllCompanies(true);

      setSelectedBusiness(null);
    } else {
      setSelectedBusiness(value);
    }
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  if (visibility)
    return (
      <React.Fragment>
        <DrawerMenuItem
          title={"Valitse yritys"}
          icon={<MdBusinessCenter style={{ fontSize: 24 }} />}
          onClick={handleOpenMenu}
          visibility={true}
          selectedPage={selectedPage}
        />

        <Menu
          id="providers-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "300px",
              maxHeight: "300px",
              backgroundColor: "white",
            },
          }}
        >
          <MenuItem
            value={"allCompanies"}
            onClick={() => {
              setShowAllCompanies(true);
              setAnchorEl(null);
            }}
          >
            <Typography sx={{ fontWeight: 550 }}>
              Kaikki toimipisteet
            </Typography>
          </MenuItem>
          {businesses &&
            businesses
              .sort((b1, b2) => {
                if (b1.name > b2.name) {
                  return 1;
                } else if (b2.name > b1.name) {
                  return -1;
                }
                return 0;
              })
              .map((b) => {
                return (
                  <MenuItem
                    key={b?.id}
                    onClick={() => {
                      handleChangeBusiness(b);
                      setShowAllCompanies(true);
                      setAnchorEl(null);
                    }}
                    selected={b?.id === business?.id}
                    value={b}
                  >
                    <Typography>{b?.name}</Typography>
                  </MenuItem>
                );
              })}
        </Menu>
      </React.Fragment>
    );
}

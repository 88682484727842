import React from "react";
import { Typography, useTheme, Box } from "@mui/material";

import { BsClockHistory } from "react-icons/bs";
import { useMobileViewPort } from "../utils/mobileViewPort";

export default function DateInput({ props, type }) {
  const customProps = {
    ...props,
    label:
      props.label === "Noutopäivä" ? "Alkamisajankohta" : "Päättymisajankohta",
  };
  const theme = useTheme();
  const mobileViewPort = useMobileViewPort(theme);

  return (
    <Box
      onClick={customProps.onClick}
      sx={{
        display: "flex",
        flexDirection: " row",
        alignItems: "center",
        height: "100%",
        overflow: "hidden",
        cursor: "pointer",

        borderRadius: "14px",
      }}
      //  onClick={(e) => handleOpenCompanyMenu(e)}
    >
      {type === "start" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: customProps?.focused
              ? theme.palette.info.dark
              : theme.palette.primary.main,
            borderTopLeftRadius: "14px",
            borderBottomLeftRadius: "14px",
            height: "56px",
            width: mobileViewPort ? "40px" : "50px",
          }}
        >
          <BsClockHistory
            style={{
              fontSize: mobileViewPort ? "20px" : "26px",
              color: "white",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: customProps?.focused ? "white" : "black",
          bgcolor: customProps?.focused
            ? theme.palette.info.dark
            : "transparent",
          padding: mobileViewPort ? "2px" : "7px",
          paddingLeft: mobileViewPort ? "2px" : "12px",
          paddingRight: mobileViewPort ? "2px" : "12px",
          transition: "250ms ease-in-out",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 550,
              fontFamily: "Sofia Pro",
              textTransform: "uppercase",
            }}
          >
            {customProps?.label}
          </Typography>
          {/*  <ExpandMore
              sx={{
                fontSize: "16px",
                marginLeft: "7px",
              }}
            /> */}
        </Box>
        <Typography
          sx={{
            fontSize: "16px",

            fontFamily: "Roboto",
          }}
        >
          {customProps?.inputProps?.value}
        </Typography>
      </Box>

      {type === "end" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: customProps?.focused
              ? theme.palette.info.dark
              : theme.palette.primary.main,
            borderTopRightRadius: "14px",
            borderBottomRightRadius: "14px",
            height: "56px",
            width: mobileViewPort ? "40px" : "50px",
          }}
        >
          <BsClockHistory
            style={{
              fontSize: mobileViewPort ? "20px" : "24px",
              color: "white",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

import {
  BsFillCloudHaze2Fill,
  BsPersonCheckFill,
  BsBox,
  BsPeopleFill,
} from "react-icons/bs";
import { TbManualGearbox, TbDimensions } from "react-icons/tb";
import {
  FaIdCard,
  FaCarBattery,
  FaInbox,
  FaBriefcase,
  FaArrowsAltV,
  FaSnowflake,
  FaStickyNote,
  FaListAlt,
  FaParking,
  FaHotTub,
  FaWifi,
  FaGasPump,
} from "react-icons/fa";
import { GiCarDoor, GiMeal } from "react-icons/gi";
import { MdFastfood, MdPets, MdRvHookup } from "react-icons/md";
import { TbEngine } from "react-icons/tb";

const translatedOptions = {
  fi: {
    true: "Kyllä",
    false: "Ei",
  },
  en: {
    true: "Yes",
    false: "No",
  },
  sv: {
    true: "Ja",
    false: "Nej",
  },
};
const airCondition = {
  icon: <FaSnowflake />,
  key: "ac",
  labelT: {
    en: "AC",
    fi: "Ilmastointi",
    sv: "Luftkonditionering",
  },
  optionsT: translatedOptions,
  primaryFilter: true,
  translatedValue: false,
  type: Boolean,
};

const ageLimit = {
  icon: <BsPersonCheckFill />,
  key: "ageLimit",
  labelT: {
    en: "Age limit",
    fi: "Vähimmäis ajoikäraja",
    sv: "Åldersgräns",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "vuotta",
  unitT: {
    en: "years",
    fi: "vuotta",
    sv: "år",
  },
};

const batteryLife = {
  icon: <FaCarBattery />,
  key: "batteryLife",
  labelT: {
    en: "Battery life",
    fi: "Akun kesto",
    sv: "Batteritid",
  },
  primaryFilter: true,
  translatedValue: false,
  unit: "tuntia",
  unitT: {
    en: "hours",
    fi: "tuntia",
    sv: "timmar",
  },
};

const breakfast = {
  icon: <GiMeal />,
  key: "includeBreakfast",
  labelT: {
    en: "Includes breakfast",
    fi: "Sisältää aamiaisen",
    sv: "Inkluderar frukost",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

const cookingOption = {
  icon: <MdFastfood />,
  key: "cookingOption",
  labelT: {
    en: "Cooking option",
    fi: "Ruoanlaitto",
    sv: "Matlagningsalternativ",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

const cubicCapacity = {
  icon: <TbEngine />,
  key: "cubicCapacity",
  labelT: {
    en: "Cubic capacity",
    fi: "Kuutiotilavuus",
    sv: "Cylindervolym",
  },
  primaryFilter: true,
  translatedValue: false,
  unit: "cc",
  unitT: {
    en: "cc",
    fi: "cc",
    sv: "cc",
  },
};

const doors = {
  icon: <GiCarDoor />,
  key: "doors",
  labelT: {
    en: "Doors",
    fi: "Ovet",
    sv: "Dörrar",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "ovinen",
  unitT: {
    en: "doors",
    fi: "ovinen",
    sv: "dörrar",
  },
};

const emission = {
  icon: <BsFillCloudHaze2Fill />,
  key: "emission",
  labelT: {
    en: "Emission",
    fi: "Päästöt",
    sv: "Utsläpp",
  },
  primaryFilter: false,
  translateValue: false,
  unit: "CO2 g/km",
  unitT: {
    en: "CO2 g/km",
    fi: "CO2 g/km",
    sv: "CO2 g/km",
  },
};

const equipments = {
  icon: <FaListAlt />,
  key: "equipments",
  labelT: {
    en: "Equipments",
    fi: "Varusteet",
    sv: "Utrustning",
  },
  primaryFilter: false,
  translatedValue: true,
};

const fuelConsumption = {
  icon: <FaGasPump />,
  key: "fuelConsumption",
  labelT: {
    en: "Fuel consumption",
    fi: "Kulutus",
    sv: "Bränsleförbrukning",
  },
  primaryFilter: true,
  translatedValue: false,
  unit: "L / 100km",
  unitT: {
    en: "L / 100km",
    fi: "L / 100km",
    sv: "L / 100km",
  },
};

const innerSize = {
  icon: <FaInbox />,
  key: "innerSize",
  labelT: {
    en: "Trunk dimensions",
    fi: "Tavaratilan sisämitat",
    sv: "Bagageutrymmets dimensioner",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "p. x l. x k.",
  unitT: {
    en: "l. x w. x h.",
    fi: "p. x l. x k.",
    sv: "l. x b. x h.",
  },
};

const luggage = {
  icon: <FaBriefcase />,
  key: "luggage",
  labelT: {
    en: "Luggages",
    fi: "Matkatavarat",
    sv: "Bagage",
  },
  primaryFilter: false,
  translatedValue: true,
};

const maxGuests = {
  icon: <BsPeopleFill />,
  key: "maxGuests",
  labelT: {
    en: "Max. number of guests",
    fi: "Max. majoittuja määrä",
    sv: "Max. antal gäster",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "henkeä",
  unitT: {
    en: "persons",
    fi: "henkeä",
    sv: "personer",
  },
};

const other = {
  icon: <FaStickyNote />,
  key: "other",
  labelT: {
    en: "Other info",
    fi: "Muut tiedot",
    sv: "annan information",
  },
  primaryFilter: false,
  translatedValue: true,
};

const outerSize = {
  icon: <TbDimensions />,
  key: "outerSize",
  labelT: {
    en: "Outer dimensions",
    fi: "Ulkomitat",
    sv: "Yttre dimensioner",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "p. x l. x k.",
  unitT: {
    en: "l. x w. x h.",
    fi: "p. x l. x k.",
    sv: "l. x b. x h.",
  },
};

const parking = {
  icon: <FaParking />,
  key: "availableParking",
  labelT: {
    en: "Parking",
    fi: "Pysäköinti",
    sv: "Parkering",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

const passangers = {
  icon: <BsPeopleFill />,
  key: "passengers",
  labelT: {
    en: "Passangers",
    fi: "Matkustajien lukumäärä",
    sv: "Passagerare",
  },
  primaryFilter: true,
  translatedValue: false,
  unit: "henkeä",
  unitT: {
    en: "persons",
    fi: "henkeä",
    sv: "personer",
  },
};

const petsAllowed = {
  icon: <MdPets />,
  key: "petsAllowed",
  labelT: {
    en: "Pets allowed",
    fi: "Lemmikkieläimet sallittu",
    sv: "Husdjur tillåtna",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

const powerType = {
  icon: <TbEngine />,
  key: "powerType",
  labelT: {
    en: "Fuel",
    fi: "Käyttövoima",
    sv: "Bränsle",
  },
  optionsT: {
    en: {
      B: "Gasoline",
      B95E: "Gasoline 95E",
      B95E10: "Gasoline 95 E10",
      B98: "Gasoline 98 E5",
      BH: "Gasoline-Hybrid",
      D: "Diesel",
      DH: "Diesel-Hybrid",
      E: "Electric",
      other: "other",
    },
    fi: {
      B: "Bensiini",
      B95E: "Bensiini 95E",
      B95E10: "Bensiini 95 E10",
      B98: "Bensiini 98 E5",
      BH: "Bensiini-hybridi",
      D: "Diesel",
      DH: "Diesel-hybridi",
      E: "Sähkö",
      other: "muu",
    },
    sv: {
      B: "Bensin",
      B95E: "Bensin 95E",
      B95E10: "Bensin 95 E10",
      B98: "Bensin 98 E5",
      BH: "Bensin-Hybrid",
      D: "Diesel",
      DH: "Diesel-Hybrid",
      E: "Elektrisk",
      other: "annan",
    },
  },
  primaryFilter: false,
  translatedValue: false,
};

const requiredLicense = {
  icon: <FaIdCard />,
  key: "requiredLicense",
  labelT: {
    en: "Required license",
    fi: "Vaadittu ajo-oikeus",
    sv: "Nödvändigt körkort",
  },
  primaryFilter: true,
  translatedValue: false,
  unit: "-kortti",
  unitT: {
    en: "-license",
    fi: "-kortti",
    sv: "-kort",
  },
};

const saunaOption = {
  icon: <FaHotTub />,
  key: "sauna",
  labelT: {
    en: "Sauna",
    fi: "Sauna",
    sv: "Bastu",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

const size = {
  icon: <FaArrowsAltV />,
  key: "size",
  labelT: {
    en: "Size",
    fi: "Koko",
    sv: "Storlek",
  },
  primaryFilter: true,
  translatedValue: false,
};

const towingCapacity = {
  icon: <MdRvHookup />,
  key: "towingCapacity",
  labelT: {
    en: "Towing capacity",
    fi: "Vetomassa",
    sv: "Dragkapacitet",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "kg",
  unitT: {
    en: "kg",
    fi: "kg",
    sv: "kg",
  },
};

const towingHook = {
  icon: <MdRvHookup />,
  key: "towingHook",
  labelT: {
    en: "Towinghook",
    fi: "Vetokoukku",
    sv: "Dragkrok",
  },
  optionsT: translatedOptions,
  primaryFilter: true,
  translatedValue: false,
};

const transmission = {
  icon: <TbManualGearbox />,
  key: "transmission",
  labelT: {
    en: "Transmission",
    fi: "Vaihteisto",
    sv: "Överföring",
  },
  optionsT: {
    en: {
      A: "Automatic",
      M: "Manual",
    },
    fi: {
      A: "Automaatti",
      M: "Manuaali",
    },
    sv: {
      A: "Automatisk",
      M: "Växellåda",
    },
  },
  primaryFilter: true,
  translatedValue: false,
};

const volume = {
  icon: <BsBox />,
  key: "volume",
  labelT: {
    en: "Volume",
    fi: "Tilavuus",
    sv: "kapacitet",
  },
  primaryFilter: false,
  translatedValue: false,
  unit: "m³",
  unitT: {
    en: "m³",
    fi: "m³",
    sv: "m³",
  },
};

const wheelType = {
  icon: <FaSnowflake />,
  key: "wheels",
  labelT: {
    en: "Wheeltype",
    fi: "Rengastyyppi",
    sv: "Hjultyp",
  },
  optionsT: {
    en: {
      null: "Not defined",
      summer: "Summer tires",
      traction: "Winter friction tires",
      winter: "Snow tires",
    },
    fi: {
      null: "Ei valittu",
      summer: "Kesärenkaat",
      traction: "Kitkarenkaat",
      winter: "Talvirenkaat",
    },
    sv: {
      null: "Inte vald",
      summer: "Sommardäck",
      traction: "Vinterfriktionsdäck",
      winter: "Vinterdäck",
    },
  },
  primaryFilter: false,
  translatedValue: false,
};

const wifiAvailable = {
  icon: <FaWifi />,
  key: "wifi",
  labelT: {
    en: "Wifi",
    fi: "Wifi",
    sv: "Wifi",
  },
  optionsT: translatedOptions,
  translatedValue: false,
};

export const ADDITIONAL_FIELDS_ALL = [
  ageLimit,
  airCondition,
  batteryLife,
  breakfast,
  cookingOption,
  cubicCapacity,
  doors,
  emission,
  equipments,
  fuelConsumption,
  innerSize,
  luggage,
  maxGuests,
  other,
  outerSize,
  parking,
  passangers,
  petsAllowed,
  powerType,
  requiredLicense,
  saunaOption,
  size,
  towingCapacity,
  towingHook,
  transmission,
  volume,
  wheelType,
  wifiAvailable,
];

export const ADDITIONAL_FIELDS_CAR = [
  ageLimit,
  airCondition,
  doors,
  emission,
  equipments,
  fuelConsumption,
  luggage,
  other,
  outerSize,
  passangers,
  powerType,
  requiredLicense,
  towingCapacity,
  towingHook,
  transmission,
  wheelType,
];

export const ADDITIONAL_FIELDS_RV = [
  ageLimit,
  airCondition,
  doors,
  emission,
  equipments,
  fuelConsumption,
  innerSize,
  luggage,
  other,
  outerSize,
  passangers,
  powerType,
  requiredLicense,
  towingCapacity,
  towingHook,
  transmission,
  wheelType,
];
export const ADDITIONAL_FIELDS_CAMPER = [
  ageLimit,
  doors,
  emission,
  equipments,
  luggage,
  other,
  passangers,
  requiredLicense,
  volume,
  wheelType,
];

export const ADDITIONAL_FIELDS_SNOWMOBILE = [
  ageLimit,
  emission,
  equipments,
  fuelConsumption,
  luggage,
  other,
  passangers,
  requiredLicense,
];
export const ADDITIONAL_FIELDS_VAN = [
  ageLimit,
  airCondition,
  doors,
  emission,
  equipments,
  fuelConsumption,
  innerSize,
  other,
  outerSize,
  passangers,
  powerType,
  requiredLicense,
  towingCapacity,
  towingHook,
  transmission,
  volume,
  wheelType,
];
export const ADDITIONAL_FIELDS_TRAILER = [
  ageLimit,
  equipments,
  other,
  outerSize,
  requiredLicense,
  volume,
];
export const ADDITIONAL_FIELDS_BICYCLE = [batteryLife, size, equipments, other];
export const ADDITIONAL_FIELDS_SAUNABOAT = [passangers, equipments, other];
export const ADDITIONAL_FIELDS_JETSKI = [
  ageLimit,
  emission,
  equipments,
  other,
  passangers,
  requiredLicense,
];

export const ADDITIONAL_FIELDS_ACCOMMODATION = [
  breakfast,
  cookingOption,
  equipments,
  maxGuests,
  other,
  parking,
  petsAllowed,
  saunaOption,
  wifiAvailable,
];

export const ADDITIONAL_FIELDS_SCOOTER = [
  ageLimit,
  cubicCapacity,
  equipments,
  other,
  passangers,
  requiredLicense,
];

export const ADDITIONAL_FIELDS_OTHER = [other];

import { useSetAtom } from "jotai";
import { modalAtom } from "./store";
import { EModal } from "./enums";
import { ComponentProps } from "react";
import { modals } from ".";
import OptionalObject from "~/types/OptionalObject";

export function useModal() {
  const setModal = useSetAtom(modalAtom);

  return {
    openModal<
      ModalKey extends EModal,
      Props = ComponentProps<typeof modals[ModalKey]["component"]>
    >(
      ...[modalKey, props]: OptionalObject<Props> extends true
        ? [modalKey: ModalKey, props?: Props]
        : [modalKey: ModalKey, props: Props]
    ) {
      setModal({ modal: modalKey, props: props as any });
    },
    closeModal() {
      setModal(null);
    },
  } as const;
}

import React, { useMemo, useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { FormContent, FormGrid } from "../../common/FormComponents";
import { centsToLocalString, parseToCents } from "../../../shared/money";

import { classes } from "./hanselClasses";
import { customers } from "./HanselCustomers";

import { useFormikContext } from "formik";
import { useFetchInvoices } from "../../../services/fetchInvoices";
import { useReservationContext } from "../ReservationContext";

import { differenceInDays, isValid, parse } from "date-fns";
import { useFetchVehicles } from "../../../services/fetchVehicles";

export default function HanselPage(props) {
  const { FEATURE_INVOICING } = props;
  const { values, setFieldValue, handleChange, handleBlur, setValues } =
    useFormikContext();

  const { vehicleData: allVehicles } = useFetchVehicles();

  const { reservationData, startTime, returnTime, vehicleIds } =
    useReservationContext();
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const { invoices } = useFetchInvoices(reservationData?.id, FEATURE_INVOICING);
  const [toggleShowContent, setToggleShowContent] = useState(true);
  const [hanselClass, setHanselClass] = useState(null);
  const [searchClass, setSearchClass] = useState("");
  const [hanselClasses, setHanselClasses] = useState(classes);
  const [hanselCustomers, setHanselCustomers] = useState(customers);
  const [hanselCustomer, setHanselCustomer] = useState(null);
  const [searchCustomer, setSearchCustomer] = useState("");

  useEffect(() => {
    if (searchClass === "") {
      setHanselClasses(classes);
    }
    if (isNaN(searchClass)) {
      const result = classes.filter((c) => {
        if (
          c.desc.toLowerCase().includes(searchClass.toLowerCase()) ||
          c.class.toLowerCase().startsWith(searchClass.toLowerCase())
        ) {
          return c;
        }
        return null;
      });
      setHanselClasses(result);
    }

    if (!isNaN(searchClass) && searchClass !== "") {
      const result = classes.filter((c) => {
        if (c.productNro.startsWith(searchClass)) {
          return c;
        }
        return null;
      });

      setHanselClasses(result);
    }
  }, [searchClass]);

  useEffect(() => {
    if (searchCustomer === "") {
      setHanselCustomers(customers);
    }

    const result = customers.filter((c) => {
      if (
        c?.name.toLowerCase().includes(searchCustomer.toLowerCase()) ||
        c.id.toLowerCase().startsWith(searchCustomer.toLowerCase()) ||
        c.VAT.toLowerCase().startsWith(searchCustomer.toLowerCase())
      ) {
        return c;
      }
      return null;
    });
    setHanselCustomers(result);
  }, [searchCustomer]);

  const enableHansel = useMemo(() => {
    if (values.type === "HANSEL") {
      return true;
    }
    return false;
  }, [values.type]);

  useEffect(() => {
    if (enableHansel) {
      if (values?.hanselData?.customerId && !hanselCustomer) {
        setHanselCustomer(
          customers.find((c) => c.id === values.hanselData?.customerId ?? null)
        );
      }
      if (values?.hanselData?.hanselClass && !hanselClass) {
        const classByProductNro = classes.find(
          (c) => c.productNro === values?.hanselData?.hanselClass ?? null
        );
        const classByInitialInfo =
          classes.find(
            (c) => c.class === `luokka ${values?.initialClassInfo?.hanselClass}`
          ) ?? null;

        setHanselClass(classByProductNro ?? classByInitialInfo);
      }

      // setFieldValue("hanselData.contractNro", "6004");
      /*  setFieldValue("hanselData.customerInvoiceId", "");
      setFieldValue("hanselData.customerInvoiceDate", "");

      //     setFieldValue("hanselData.subContractorNumber", "");
      //   setFieldValue("hanselData.subContractorName", "");
      //     setFieldValue("hanselData.suplierProductId", "");
      //      setFieldValue("hanselData.suplierProductName", "");
      //  setFieldValue("hanselData.quantity", "");
      setFieldValue("hanselData.price", "");

      setFieldValue("hanselData.additionalInfo2", "");
      setFieldValue("hanselData.additionalInfo3", ""); */
    }
  }, [
    enableHansel,
    hanselClass,
    hanselCustomer,
    values.hanselData?.customerId,
    values.hanselData?.hanselClass,
    values?.initialClassInfo?.hanselClass,
  ]);

  useEffect(() => {
    if (isValid(startTime) && isValid(returnTime)) {
      const duration = differenceInDays(returnTime, startTime) + 1;
      setFieldValue("hanselData.additionalInfo3", duration);
    }
  }, [returnTime, startTime, setFieldValue]);

  const handleReservationType = (event) => {
    setFieldValue("type", event.target.checked ? "HANSEL" : undefined);
  };

  // for HANSEL report
  /*   emission: allVehicles
            .find((v) => v.id === id)
            ?.additionalFields.find((v) => v.key === "emission").value, */

  return (
    <Grid
      item
      container
      md={12}
      sm={12}
      xs={12}
      lg={12}
      sx={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12}>
        <FormContent
          title={"Hansel raportti"}
          visibility={true}
          expanded={toggleShowContent}
          setExpanded={setToggleShowContent}
        >
          <Grid container sx={{ marginY: "10px" }}>
            <FormGrid sx={{ marginBottom: "20px" }}>
              <Switch
                checked={values?.type === "HANSEL"}
                onChange={(event) => handleReservationType(event)}
              />
              Luo Hansel varaus
            </FormGrid>

            <Grid
              container
              sx={{ direction: "row", justifyContent: "center" }}
              spacing={2}
            >
              <FormGrid>
                <FormControl fullWidth>
                  <InputLabel id={"select-categories-label"}>
                    Valitse lasku
                  </InputLabel>
                  <Select
                    labelId="select-invoice-label"
                    id="select-invoice"
                    disabled={values?.type !== "HANSEL"}
                    label="Valitse lasku"
                    value={selectedInvoice ?? ""}
                    onChange={(e) => {
                      const invoiceData = e.target.value;

                      setSelectedInvoice(invoiceData);
                      setValues({
                        ...values,
                        hanselData: {
                          ...values.hanselData,
                          contractNro: "6004",
                          customerInvoiceId: invoiceData?.innovoiceId,
                          additionalInfo1:
                            differenceInDays(returnTime, startTime) + 1,
                          customerInvoiceDate: parse(
                            invoiceData?.delivery_date,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          desc: "",
                          additionalInfo2: allVehicles
                            .find((v) => v.id === vehicleIds[0])
                            ?.additionalFields.find((v) => v.key === "emission")
                            ?.value,
                        },
                      });
                    }}
                  >
                    {invoices &&
                      invoices.map((invoice) => (
                        <MenuItem value={invoice} key={invoice.id}>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography> {invoice?.innovoiceId}</Typography>
                            <Typography>
                              {invoice?.customer?.firstname}
                              {invoice?.customer?.lastname}
                            </Typography>
                            <Typography>{invoice?.customer?.email}</Typography>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </FormGrid>
              <FormGrid>
                <Grid container spacing={2}>
                  <Grid item xs={11} md={4}>
                    <TextField
                      disabled={!enableHansel}
                      value={values?.hanselData?.contractNro ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="hanselData.contractNro"
                      label="SopimusNro"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} md={4}>
                    <TextField
                      disabled={!enableHansel}
                      value={values?.hanselData?.customerInvoiceId ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="hanselData.customerInvoiceId"
                      label="Asiakkaalle lähetetyn laskun nro"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} md={4}>
                    <DatePicker
                      disabled={!enableHansel}
                      label="Asiakkaalle lähetetyn laskun pvm"
                      inputVariant="outlined"
                      name="hanselData.customerInvoiceDate"
                      value={
                        values?.hanselData?.customerInvoiceDate
                          ? new Date(values?.hanselData?.customerInvoiceDate)
                          : undefined
                      }
                      onChange={(date) => {
                        setFieldValue("hanselData.customerInvoiceDate", date);
                      }}
                      inputFormat="dd.MM.yyyy"
                      cancelLabel="Peruuta"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormGrid>

              <FormGrid container spacing={2}>
                <Grid item xs={11} md={12}>
                  <Autocomplete
                    disabled={!enableHansel}
                    freeSolo
                    fullWidth
                    value={hanselClass ?? ""}
                    inputValue={searchClass || ""}
                    options={hanselClasses}
                    //  loading={nameAutoCompleteLoading}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "hanselData.hanselClass",
                        newValue?.productNro ? newValue?.productNro : null
                      );
                      setHanselClass(newValue);
                    }}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option.productNro ?? ""}
                    noOptionsText="Ei luokkaa"
                    onInputChange={(event, newInputValue) => {
                      setSearchClass(newInputValue);
                    }}
                    renderOption={(props, option) => {
                      const classObj = classes.find(
                        (c) => c.productNro === option.productNro
                      );

                      return (
                        <Box {...props} key={option.productNro}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingX: "10px",
                              cursor: "pointer",
                              transition: "250ms ease-in-out",
                              width: "100%",
                              justifyContent: "space-between",
                            }}

                            /*   onClick={() => {
                            setFieldValue(
                              "hanselData.hanselClass",
                              classObj?.productNro
                            );
                            console.log(
                              "  classObj?.productNro",
                              classObj?.productNro
                            );
                          }} */
                          >
                            <Typography sx={{ fontWeight: 550 }} align="left">
                              {classObj?.productNro}
                            </Typography>
                            <Typography>{classObj?.desc}</Typography>
                            <Typography sx={{ color: "rgba(0,0,0,0.55)" }}>
                              {classObj?.class}
                            </Typography>
                            <Divider />
                          </Box>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Valitse hansel luokka"
                        /*     label="Search input"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }} */
                      />
                    )}
                  />
                </Grid>
              </FormGrid>

              <FormGrid container spacing={2}>
                <Grid item xs={11} md={3}>
                  <TextField
                    value={values?.hanselData?.customerId ?? ""}
                    //    onChange={handleChange}
                    // onBlur={handleBlur}
                    name="hanselData.customerId"
                    placeholder="Asiakasnro"
                    label="Asiakasnro"
                    variant="outlined"
                    fullWidth
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={11} md={9}>
                  <Autocomplete
                    disabled={!enableHansel}
                    freeSolo
                    fullWidth
                    value={hanselCustomer ?? ""}
                    inputValue={searchCustomer || ""}
                    options={hanselCustomers}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "hanselData.customerId",
                        newValue?.id ? newValue?.id : null
                      );
                      setHanselCustomer(newValue);
                    }}
                    filterOptions={(options) => options}
                    getOptionLabel={(option) => option?.name ?? ""}
                    noOptionsText="Ei luokkaa"
                    onInputChange={(event, newInputValue) => {
                      setSearchCustomer(newInputValue);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box {...props} key={option?.id}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              paddingX: "10px",
                              cursor: "pointer",
                              transition: "250ms ease-in-out",
                            }}
                          >
                            <Typography sx={{ fontWeight: 550 }}>
                              {option?.id}
                            </Typography>
                            <Typography>{option?.name}</Typography>
                            <Typography sx={{ color: "rgba(0,0,0,0.55)" }}>
                              {option?.VAT}
                            </Typography>
                            <Divider />
                          </Box>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Valitse hansel asiakas" />
                    )}
                  />
                </Grid>
              </FormGrid>

              <FormGrid container spacing={1}>
                <FormGrid md={4}>
                  <TextField
                    disabled={!enableHansel}
                    value={values?.hanselData?.additionalInfo1 ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="hanselData.additionalInfo1"
                    label="Kilometrit"
                    variant="outlined"
                    fullWidth
                  />
                </FormGrid>
                <FormGrid md={4}>
                  <TextField
                    disabled={!enableHansel}
                    value={values?.hanselData?.additionalInfo2 ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="hanselData.additionalInfo2"
                    label="Päästöt"
                    variant="outlined"
                    fullWidth
                  />
                </FormGrid>
                <FormGrid md={4}>
                  {" "}
                  <TextField
                    disabled={!enableHansel}
                    value={values?.hanselData?.additionalInfo3 ?? ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="hanselData.additionalInfo3"
                    label="Vrk"
                    variant="outlined"
                    fullWidth
                  />
                </FormGrid>
              </FormGrid>

              <FormGrid>
                <TextField
                  disabled={!enableHansel}
                  value={values?.hanselData?.desc ?? ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="hanselData.desc"
                  label="Selite"
                  variant="outlined"
                  fullWidth
                />
              </FormGrid>
            </Grid>
          </Grid>
        </FormContent>
      </Grid>
    </Grid>
  );
}

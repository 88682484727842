import * as Sentry from "@sentry/react";

export default function captureError(defaultMessage, code, e, errorId) {
  let json;
  try {
    json = JSON.stringify(e);
  } catch {
    json = "Error serialization failed";
  }

  if (typeof e === "object" && e.errors != null) {
    //Amplify AppSync error
    const hasNetworkError = e.errors.some(
      (error) => error.message === "Network Error"
    );
    Sentry.withScope((scope) => {
      if (hasNetworkError) {
        // Group together all Errors that originate from "Network Error"
        defaultMessage = "Network Error";
        scope.setFingerprint("network-error");
      }
      scope.setExtra("errors", e.errors);
      scope.setExtra("json", json);
      Sentry.captureMessage(defaultMessage, {
        tags: {
          code: code,
          errorId: errorId,
        },
      });
    });
  } else if (e instanceof Error) {
    Sentry.withScope((scope) => {
      scope.setExtra("contextMessage", defaultMessage);
      scope.setExtra("json", json);
      Sentry.captureException(e, {
        tags: {
          code: code,
          errorId: errorId,
        },
      });
    });
  } else if (typeof e === "string") {
    Sentry.withScope((scope) => {
      scope.setExtra("contextMessage", defaultMessage);
      Sentry.captureMessage(e, {
        tags: {
          code: code,
          errorId: errorId,
        },
      });
    });
  } else {
    Sentry.withScope((scope) => {
      scope.setExtra("json", json);
      Sentry.captureMessage(defaultMessage, {
        tags: {
          code: code,
          errorId: errorId,
        },
      });
    });
  }
}

// @ts-ignore
import DialogComponent from "~/components/DialogComponent";
import { useModal } from "../hooks";
import { Box, Divider, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { EViews, MViews } from "./enums";
import { useState } from "react";
import InvoiceList from "./components/InvoiceList";
import { ReservationsGroup } from "./utils/groupReservations";

function ConsolidatedInvoices() {
  const { closeModal } = useModal();
  const theme = useTheme();

  const [reservationsGroup, setReservationsGroup] = useState<
    undefined | ReservationsGroup
  >();
  const [activeTab, setActiveTab] = useState(EViews.OPEN_ORDERS);

  const View = MViews[activeTab].view;

  return (
    <DialogComponent
      open
      dialogClose={closeModal}
      maxWidth="lg"
      //   height={!mobileViewPort ? 900 : undefined}
    >
      <Box style={{ padding: theme.spacing(4) }}>
        <Typography
          variant="h5"
          align="center"
          sx={{ fontFamily: "Sofia Pro" }}
        >
          Laskutettavat
        </Typography>
        <Divider variant="middle" />

        {!reservationsGroup && (
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {Object.entries(MViews).map(([key, { label }]) => (
              <Tab key={key} label={label} value={key} />
            ))}
          </Tabs>
        )}
      </Box>

      <Box>
        {reservationsGroup ? (
          <InvoiceList
            reservationsGroup={reservationsGroup}
            onBack={() => setReservationsGroup(undefined)}
          />
        ) : (
          <View onSelect={setReservationsGroup} />
        )}
      </Box>
    </DialogComponent>
  );
}

export default ConsolidatedInvoices;

import { Box, IconButton, Tooltip } from "@mui/material";
import { useReservationContext } from "./ReservationContext";
import { useNotification } from "../Notification";
import { TbPrinter } from "react-icons/tb";
import print from "../../print-contracts/print-contract";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { getReservation } from "../../graphql/queries";
import { getZonedTime } from "../../utils/common";

import ContractTemplateEbikeRental from "../../print-contracts/ContractTemplateEbikeRental";
import ContractTemplateJetRental from "../../print-contracts/ContractTemplateJetRental";
import { useFetchVehicles } from "../../services/fetchVehicles";

import vanDamageMap from "../../assets/damageMapImages/van_damagemap.webp";
import carDamageMap from "../../assets/damageMapImages/car_damagemap.webp";
import jetskiDamageMap from "../../assets/damageMapImages/jetski_damagemap.webp";
import bicycleDamageMap from "../../assets/damageMapImages/bicycle_damagemap.webp";
import boattrailerDamageMap from "../../assets/damageMapImages/boattrailer_damagemap.webp";
import cabinettrolleyDamageMap from "../../assets/damageMapImages/cabinettrolley_damagemap.webp";
import snowmobileDamageMap from "../../assets/damageMapImages/snowmobile_damagemap.webp";
import trailerDamageMap from "../../assets/damageMapImages/trailer_damagemap.webp";
import { clientLogoData } from "../../customerLogos/ClientLogoData";
import OldContractTemplate from "../../print-contracts/OldContractTemplate";

export const PrintingComponent = (props) => {
  const { setCloseDialog, handleSubmit, values, errors } = props;
  const { group, timezone, company, user } = useContext(AppContext);
  const { vehicleData: allVehicles } = useFetchVehicles();

  const { reservationData, selectedServiceOffers } = useReservationContext();
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  if (!reservationData?.id) {
    return null;
  }

  return (
    <Box>
      <Tooltip title={"Tulosta sopimus"} placement="top">
        <IconButton
          disabled={submitting}
          onClick={async () => {
            setSubmitting(true);
            setCloseDialog(false);
            let submitReservation;
            try {
              submitReservation = await handleSubmit();
            } catch (e) {
              console.log("trigger", errors);
              console.error("onSubmit error", e);
              notification.show("Sopimuksen tulostus epäonnistui.", "warning");
            }

            if (values.channel === "DEALER_EXTERNAL") {
              submitReservation = true;
            }

            if (submitReservation) {
              // Workaround that preloads the images for cache so they're loaded properly on chromium browsers
              const userEntity = company?.group ?? user?.organizationId;
              const logo = clientLogoData.find(
                (item) => item.id === userEntity
              )?.logo;

              // This removes any undefineds etc. from the list that might prevent the printing to go through.
              const imageUrls = [
                vanDamageMap,
                carDamageMap,
                jetskiDamageMap,
                bicycleDamageMap,
                boattrailerDamageMap,
                cabinettrolleyDamageMap,
                snowmobileDamageMap,
                trailerDamageMap,
                logo,
              ].filter((url) => Boolean(url));
              await Promise.all(
                imageUrls.map((url) => {
                  return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = resolve;
                    img.onerror = reject;
                    img.src = url;
                  });
                })
              );
              const updatedReservation = await getUpdatedReservation(
                reservationData,
                timezone
              );
              print(
                getContract(
                  updatedReservation,
                  allVehicles,
                  group,
                  user,
                  company,
                  selectedServiceOffers
                )
              );
            } else {
              // Handle form validation errors if needed

              console.error("Form validation failed:");
              notification.show("Sopimuksen tulostus epäonnistui.", "warning");
            }
            setSubmitting(false);
          }}
        >
          <TbPrinter style={{ fontSize: "30px" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const getContract = (
  reservation,
  allVehicles,
  group,
  user,
  company,
  selectedServiceOffers
) => {
  //const userEntity = "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1";
  const userEntity = user?.organizationId ?? group;
  const vehicles = reservation?.reservationVehicles
    ? reservation.reservationVehicles.map((rv) => {
        const vehicle = allVehicles.find((v) => v.id === rv.id);
        // Idea is that primarily use data from reservationVehicle, but use "real" Vehicle as a backup (older reservations)
        return {
          ...vehicle,
          ...rv, // Priorize information saved onto reservationVehicles
        };
      })
    : null;

  const typesOfContract = {
    FEATURE_EBIKERENTAL_CONTRACT: () => (
      <ContractTemplateEbikeRental
        reservation={reservation}
        company={company}
        additionalServices={selectedServiceOffers}
        vehicles={vehicles}
      />
    ),
    FEATURE_JET_CONTRACT: () => (
      <ContractTemplateJetRental
        reservation={reservation}
        company={company}
        additionalServices={selectedServiceOffers}
        vehicles={vehicles}
      />
    ),
  };

  const isFeatureContract = Object.keys(EFeatureContracts).find((key) =>
    EFeatureContracts[key].includes(userEntity)
  );

  const featureContract = typesOfContract[isFeatureContract];

  if (featureContract) {
    return featureContract();
  }
  // return default contract template
  return (
    <OldContractTemplate
      reservation={reservation}
      company={company}
      vehicles={vehicles}
      additionalServices={selectedServiceOffers}
    />
  );
};

const getUpdatedReservation = async (reservationData, timezone) => {
  const result = await API.graphql(
    graphqlOperation(getReservation, {
      id: reservationData.id,
    })
  );
  const reservation = result.data.getReservation;
  const updatedReservation = {
    ...reservation,
    startTime: getZonedTime(reservation.startTime, timezone),
    returnTime: getZonedTime(reservation.returnTime, timezone),
    endTime: getZonedTime(reservation.endTime, timezone),
  };

  return updatedReservation;
};

// TODO: move this to a more appropriate place

const EFeatureContracts = {
  FEATURE_EBIKERENTAL_CONTRACT: ["ebikerental", "elämysristeilyt"],
  FEATURE_JET_CONTRACT: [
    "jyrkila",
    "greattime",
    "jettirentti",
    "majakkawatersportslohja",
    "majakkawatersports",
  ],
  FEATURE_NEW_CONTRACT: [
    "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent,
    "d90a0808-7f16-41af-a374-0244e5fe9df1", // Dev organization
    "0f460230-e4f3-4cc7-bc4d-997077d90067",
    "9e615947-75c4-44cf-bd89-fd4917d83a11", // Ville dev organization
  ],
};

import { createContext, useContext, useMemo, useState } from "react";
import { AppContext } from "../../../../AppContext";
import { isOrganizationUser } from "../../../../features";
const ReservationContext = createContext();

const ReservationDataProvider = ({ children }) => {
  const { user, company } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState("FIN");
  const [summaryView, setSummaryView] = useState(false);
  const insuranceCompanies = useMemo(() => {
    if (!user?.externalBusinessByOrg?.items) return [];
    // If user belongs to ExternalBusiness, return corresponding insuranceRegionalCompanies
    if (user?.externalBusiness?.insuranceRegionalCompanies) {
      return user.externalBusiness.insuranceRegionalCompanies.map(
        (company) => ({ ...company, groupName: user.externalBusiness.name })
      );
    }
    //  Return insuranceRegionalCompanies from all ExternalBusinesses
    const externalBusinesses = user.externalBusinessByOrg.items;
    const companies = externalBusinesses
      .filter((extBiz) => extBiz.insuranceRegionalCompanies)
      .flatMap((extBiz) => {
        return extBiz.insuranceRegionalCompanies.map((company) => {
          return {
            ...company,
            groupName: extBiz.name,
          };
        });
      });
    return companies;
  }, [user]);

  const FEATURE_INVOICING = useMemo(() => {
    return isOrganizationUser(user);
  }, [user]);

  const FEATURE_INNOVOICE = useMemo(() => {
    return FEATURE_INVOICING;
  }, [FEATURE_INVOICING]);

  const FEATURE_LIVION_KEY = useMemo(() => {
    // Vuokraapaku REDI
    return !!company?.livionDeviceId;
  }, [company]);

  return (
    <ReservationContext.Provider
      value={{
        FEATURE_INNOVOICE,
        FEATURE_INVOICING,
        FEATURE_LIVION_KEY,
        insuranceCompanies,
        selectedLanguage,
        setSelectedLanguage,
        setSummaryView,
        summaryView,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export { ReservationContext, ReservationDataProvider };

import { API, graphqlOperation } from "aws-amplify";
import { atom, useAtom } from "jotai";
import { setReservationVehicles } from "~/graphql/mutations";
// @ts-ignore
import { useNotification } from "~/components/Notification";
// @ts-ignore
import { useFetchReservations } from "~/services/fetchReservations";
import {
  Category,
  Reservation,
  ReservationVehicleAdminInput,
  SetReservationVehiclesMutationVariables,
  Vehicle,
  WildCardVehicleInput,
} from "~/graphql/API";

export const moveReservationAtom = atom<null | {
  reservation: Reservation;
  vehicle?: Vehicle | null;
}>(null);

export function useMoveReservation() {
  const [move, setMove] = useAtom(moveReservationAtom);
  const { reservation, vehicle: currentVehicle } = move || {};

  const { show } = useNotification();
  const { reservations, reservationsMutate } = useFetchReservations(
    "existBetween",
    { revalidateIfStale: false }
  );

  async function handleUpdate(
    reservation: Parameters<typeof updateReservation>[0]
  ) {
    const result = await updateReservation(reservation);

    if (result?.success) {
      const updated = [...reservations] as Reservation[];
      const index = updated.findIndex(
        (res) => res.id === result.reservation.id
      );
      if (index >= 0) {
        updated.splice(index, 1, result.reservation);
      } else {
        updated.push(result.reservation);
      }

      reservationsMutate(updated, {
        revalidate: false,
      });

      show("Varaus siirretty!", "success");
    } else {
      show(result?.message ?? "Varauksen siirto epäonnistui!", "error");
    }

    setMove(null);
  }

  return {
    move,
    isMoving: !!move,
    cancel() {
      setMove(null);
    },
    async moveToVehicle(vehicle: Vehicle) {
      if (!reservation) {
        show("Valitse varaus!", "error");
        return;
      }

      const reservationVehicles = [
        ...(reservation?.reservationVehicles?.filter(Boolean) || []),
      ] as ReservationVehicleAdminInput[];

      const vehicleIndex = reservationVehicles.findIndex(
        ({ id }) => id === currentVehicle?.id
      );

      const previousEntry =
        vehicleIndex >= 0
          ? reservationVehicles.splice(vehicleIndex, 1)[0]
          : reservation?.wildCardVehicles?.[0] || undefined;

      // Prefer the deductible from the reservation's existing vehicle entry if available
      // Otherwise, use the vehicle's deductible, defaulting to undefined if not a number
      const deductiblePreference =
        previousEntry?.deductible ?? vehicle.deductible ?? undefined;

      reservationVehicles.push({
        id: vehicle.id,
        price:
          previousEntry?.price ??
          reservation?.wildCardVehicles?.[0]?.price ??
          0,
        tax: previousEntry?.tax ?? 24,
        categoryName: vehicle.category?.name,
        damages: vehicle.damages,
        deductible: deductiblePreference,
        extraInfo: vehicle.extraInfo,
        hideExport: vehicle.hideExport,
        includedDistance: vehicle.includedDistance,
        mileage: vehicle.mileage,
        name: vehicle.name,
        powerType: vehicle.powerType,
        registrationPlate: vehicle.registrationPlate,
      });

      handleUpdate({
        reservationId: reservation?.id,
        reservationVehicles,
        wildCardVehicles: [],
      });
    },
    async moveToCategory(category: Category) {
      if (!reservation) {
        show("Valitse varaus!", "error");
        return;
      }

      // Defaults to using values from the first reservation vehicle or wildcard vehicle as fallbacks.
      const wildCardVehicle: WildCardVehicleInput = {
        name: category.name,
        price:
          reservation.reservationVehicles?.[0]?.price ??
          reservation.wildCardVehicles?.[0]?.price ??
          0,
        tax:
          reservation.reservationVehicles?.[0]?.tax ??
          reservation.wildCardVehicles?.[0]?.tax ??
          0,
        deductible:
          reservation.reservationVehicles?.[0]?.deductible ??
          reservation.wildCardVehicles?.[0]?.deductible ??
          0,
        categoryId: category.id,
        quantity: 1,
      };

      const wildCardVehiclesToUpdate: WildCardVehicleInput[] = [
        wildCardVehicle,
      ];

      handleUpdate({
        reservationId: reservation.id,
        reservationVehicles: [],
        wildCardVehicles: wildCardVehiclesToUpdate,
      });
    },
  } as const;
}

async function updateReservation(
  reservation: SetReservationVehiclesMutationVariables
) {
  try {
    return (
      (await API.graphql(
        graphqlOperation(setReservationVehicles, reservation)
      )) as any
    )?.data?.setReservationVehicles;
  } catch (error) {
    console.warn(error);
  }
}

import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import useSWR from "swr";
import captureError from "../utils/capture-error";
import listAll from "../utils/list-all";
import { searchReservations } from "../graphql/custom-queries";
import { getZonedTime } from "../utils/common";
import { graphqlOperation } from "aws-amplify";
import { isValid } from "date-fns";

// used by external users INSURANCE
export function useFetchInsuranceEntries() {
  const { user, timezone, startDate, endDate } = useContext(AppContext);
  const start = new Date(startDate).setHours(0, 0, 0, 0);
  const end = new Date(endDate).setHours(23, 59, 59, 999);
  const filter = useMemo(() => {
    const filters = {};
    if (!user?.externalBusinessId || !isValid(start) || !isValid(end))
      return null;

    filters.orgExternalBusinessId = { eq: user?.externalBusinessId };
    filters.or = [{ createdAt: { range: [start, end] } }];
    return filters;
  }, [user, start, end]);

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    filter ? [filter, "reservationsByStatus"] : null,
    () => fetchExternalReservations(filter)
  );

  const externalReservationData = useMemo(() => {
    if (!data) return [];
    return data.map((item) => ({
      ...item,
      createdAt: getZonedTime(item.createdAt, timezone),
      startTime: getZonedTime(item.startTime, timezone),
      returnTime: getZonedTime(item.returnTime, timezone),
      endTime: getZonedTime(item.endTime, timezone),
    }));
  }, [data, timezone]);

  return {
    externalReservations: externalReservationData,
    externalReservationsLoading: isLoading,
    externalReservationsValidating: isValidating,
    externalReservationsError: error,
    externalReservationsMutate: mutate,
  };
}

const fetchExternalReservations = async (filter) => {
  console.log("fetching insurance orders");
  try {
    const reservations = await listAll(
      graphqlOperation(searchReservations, {
        limit: 1000,
        filter: filter,
      }),
      "searchReservations"
    );

    if (reservations == null) {
      return;
    }

    return reservations;
  } catch (e) {
    console.error("fetchExternalReservations error", e);
    captureError(
      "Get insurance orders failed",
      "GET_INSURANCE_ORDERS_FAILED",
      e
    );
  }
};

import { useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "~/AppContext";
export default function InsuranceAdjuster() {
  const { values } = useFormikContext();
  const { user } = useContext(AppContext);
  return (
    <>
      <TextField
        disabled
        value={values?.insuranceData?.claimAdjusterName ?? user?.name}
        label="Korvauskäsittelijä"
        variant="outlined"
        fullWidth
      />

      <TextField
        disabled
        value={values?.insuranceData?.claimAdjusterEmail ?? user?.email}
        name="insuranceData.insuranceAdjusterEmail"
        label="Sähköposti"
        variant="outlined"
        fullWidth
      />
    </>
  );
}

import { Reservation, Vehicle } from "~/graphql/API";
import { RowContext } from "./RowContext";
import DatesRow from "../DatesRow";
import ReservationsHourlyVertical from "./components/ReservationsHourlyVertical";
import VehicleCard from "./components/VehicleCard";
import { ComponentProps } from "react";
import RowSelector from "../RowSelector";
import { SortedReservations } from "../../utils/sortReservationsReducer";
import { useAtomValue } from "jotai";
import { showHourlyReservations } from "./store";

type makeVehicleRowArgs = Pick<
  ComponentProps<typeof DatesRow>,
  "dates" | "className"
> & {
  reservations?: Reservation[];
  sortedReservations?: SortedReservations["vehicle"];
};

function makeVehicleRow({
  reservations,
  sortedReservations,
  ...args
}: makeVehicleRowArgs) {
  return async function VehicleRow(vehicle: Vehicle) {
    return (
      <RowContext.Provider key={vehicle.id} value={{ vehicle }}>
        <DatesRow
          {...args}
          override={sortedReservations?.[vehicle.id]}
          firstCell={<VehicleCard vehicle={vehicle} />}
          append={
            <>
              <RowSelector vehicle={vehicle} />
              <Hourly vehicle={vehicle} reservations={reservations} />
            </>
          }
        />
      </RowContext.Provider>
    );
  };
}

export default makeVehicleRow;

type HourlyProps = { vehicle: Vehicle; reservations?: Reservation[] };

function Hourly({ vehicle, reservations: _reservations = [] }: HourlyProps) {
  const show = useAtomValue(showHourlyReservations);

  if (show !== vehicle) {
    return null;
  }

  const reservations = _reservations?.filter((res) =>
    res.reservationVehicles?.some(
      (reservationVehicle) => reservationVehicle?.id === vehicle.id
    )
  );

  return <ReservationsHourlyVertical reservations={reservations} />;
}

import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  Typography,
} from "@mui/material";

import { centsToLocalString } from "../../../../shared/money";
import { Euro } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "../../../../AppContext";
import { useReservationContext } from "../../ReservationContext";

export default function DeductiblePriceField({ offer }) {
  const { USER_ADMIN_CORPORATE } = useContext(AppContext);
  const { modifyOfferDeductible, selectedServiceOffers } =
    useReservationContext();

  const deductibleValueFromServiceOffer = selectedServiceOffers?.find(
    (service) => service?.category === "deductible"
  )?.deductibleValue;

  const pricePlaceHolder = () => {
    // if pricefield is cleared, show the default price as placeholder
    if (!offer) return null;

    if (deductibleValueFromServiceOffer) {
      return centsToLocalString(deductibleValueFromServiceOffer);
    }

    if (offer?.modifiedDeductible === "" || !offer?.modifiedDeductible) {
      return centsToLocalString(offer?.deductible);
    } else {
      return offer?.modifiedPrice;
    }
  };

  const fieldValue =
    offer?.modifiedDeductible && offer?.modifiedDeductible !== ""
      ? offer?.modifiedDeductible
      : "";

  return (
    <>
      <FieldCaption
        offerId={offer?.id}
        visibility={!USER_ADMIN_CORPORATE}
        deductibleValueFromServiceOffer={deductibleValueFromServiceOffer}
      />
      <TextField
        disabled={Boolean(deductibleValueFromServiceOffer)}
        placeholder={pricePlaceHolder()}
        value={fieldValue}
        onChange={(e) => {
          modifyOfferDeductible(offer?.id, e.target.value);
        }}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box sx={{ display: "flex" }}>
                <Euro sx={{ fontSize: "18px", cursor: "pointer" }} />
              </Box>
            </InputAdornment>
          ),
        }}
        fullWidth
        size="small"
        data-cy="reservationDialogDeductiblePriceField"
      />
    </>
  );
}

function FieldCaption(props) {
  const { visibility, deductibleValueFromServiceOffer } = props;

  if (deductibleValueFromServiceOffer) {
    return (
      <Grid item>
        <Typography variant="caption">Omavastuu lisäpalvelusta</Typography>
      </Grid>
    );
  }

  if (visibility)
    return (
      <Grid item>
        <Typography variant="caption">Omavastuu</Typography>
      </Grid>
    );
}

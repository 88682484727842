import React from "react";
import { createRoot } from "react-dom/client";
import "./print-rules.css";

let printRoot = null;
let printRootDup = null;
function clearPrintRoot() {
  if (printRoot != null) {
    printRoot.remove();
    printRoot = null;
  }
  if (printRootDup != null) {
    printRootDup.remove();
    printRootDup = null;
  }
}

//Warning: Clear is also called before print as a workaround since afterprint-event is pseudo randomly ignored on Chrome.
window.addEventListener("afterprint", function (event) {
  clearPrintRoot();
});

// Print contract on two pages, so dealers will have their own copy.
export default function print(reactElement) {
  clearPrintRoot();

  const domNode = document.createElement("div");
  domNode.className = "print-root";
  printRoot = domNode;

  const root = createRoot(domNode);
  const elWithCallback = React.cloneElement(reactElement, {
    callback: () => {
      // Use callback function to make sure DOM we are printing is populated
      const dup = domNode.cloneNode(true);
      printRootDup = dup;
      document.body.appendChild(domNode);
      document.body.appendChild(dup);
      window.print();
    },
  });

  root.render(elWithCallback);
}

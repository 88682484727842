import {
  Autocomplete,
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { pageContainerStyle } from "~/styles/page-container";
import { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { OpenInNew } from "@mui/icons-material";
import { columns } from "./components/columns";
import { useFetchInsuranceEntries } from "../../services/fetchInsuranceEntries";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";

export default function InsuranceAssignments() {
  const { user } = useContext(AppContext);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const theme = useTheme();
  const [selectedRegionalCompany, setSelectedRegionalCompany] = useState(null);
  const { externalReservations, externalReservationsLoading } =
    useFetchInsuranceEntries();

  const handleOpenReservation = (id) => {
    const reservation = externalReservations.find((res) => res.id === id);

    if (reservation) {
      return setEditReservationData(reservation);
    }

    return null;
  };

  const openReservation = {
    field: "openReservation",
    headerName: " ",
    sortable: false,
    headerAlign: "center",
    width: 80,
    renderCell: (params) => {
      return (
        <Box data-cy="openExternalReservationButton">
          <Tooltip title="Avaa varaus" placement="left">
            <IconButton
              variant="contained"
              onClick={() => {
                handleOpenReservation(params.row.id);
              }}
            >
              <OpenInNew sx={{ color: theme.palette.success.light }} />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  };

  const rows = externalReservations
    .map((assignment) => {
      return {
        ...assignment,
        client: user?.externalBusinessByOrg?.items.find(
          (b) => b.id === assignment?.orgExternalBusinessId
        )?.name,
        billingPermitCode: assignment?.insuranceData?.billingPermitCode,
        claimAdjusterEmail: assignment?.insuranceData?.claimAdjusterEmail,
        providerCompany: assignment?.insuranceData?.providerCompany,
        customerCarRegistrationPlate: assignment?.customerCarRegistrationPlate,
        name: assignment.name,
        status: assignment?.rentalStatus ?? assignment?.status,
        channel: assignment?.channel,
        transferReservation: assignment?.businessId,
        urgency: assignment?.insuranceData?.urgent,
        extBusinessId: assignment?.orgExternalBusinessId,
      };
    })
    .filter((row) => {
      if (!selectedRegionalCompany) return true;
      return row?.providerCompany === selectedRegionalCompany?.companyName;
    });
  return (
    <Stack sx={{ ...pageContainerStyle, padding: "14px" }} spacing={2}>
      <FilterByRegionalCompany
        selectedRegionalCompany={selectedRegionalCompany}
        setSelectedRegionalCompany={setSelectedRegionalCompany}
      />
      <DataGridPro
        sx={{
          //   borderColor: "transparent",
          borderRadius: "14px",
          border: "none",
          overflow: "hidden",
          /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
          "& .MuiDataGrid-columnHeadersInner": {
            backgroundColor: "#f7f7f8",
            color: "#4e536c",
            fontFamily: "Sofia Pro",
            fontSize: "16px",
            // boxShadow: theme.shadows[4],
          },
        }}
        loading={externalReservationsLoading}
        rows={rows}
        columns={[openReservation, ...columns]}
        pagination
        pageSize={10}
        rowsPerPageOptions={[2]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        disableRowSelectionOnClick
      />
    </Stack>
  );
}

function FilterByRegionalCompany(props) {
  const { selectedRegionalCompany, setSelectedRegionalCompany } = props;
  const { user } = useContext(AppContext);
  const regionalCompanies =
    user?.externalBusiness?.insuranceRegionalCompanies.sort((c1, c2) => {
      if (c1.name < c2.name) {
        return -1;
      }
      if (c1.name > c2.name) {
        return 1;
      }
      return 0; // Names are equal
    });
  const [inputValue, setInputValue] = useState("");
  if (regionalCompanies)
    return (
      <Autocomplete
        options={regionalCompanies}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue);
          if (newInputValue === "") setSelectedRegionalCompany(null);
        }}
        value={selectedRegionalCompany}
        getOptionLabel={(option) => option?.companyName ?? ""}
        isOptionEqualToValue={(option, value) => {
          return option?.companyName === value?.companyName;
        }}
        onChange={(e, value) => {
          setInputValue(value?.companyName ?? "");
          setSelectedRegionalCompany(value);
        }}
        filterOptions={
          (options, state) =>
            options.filter((option) =>
              option?.companyName
                ?.toLowerCase()
                .includes(state?.inputValue?.toLowerCase())
            )
          /* {
          const filtered = options.filter((option) => {
            return option?.companyName
              ?.toLowerCase()
              .includes(params.inputValue.toLowerCase());
          });

          if (params.inputValue !== "") {
            filtered.push({
              companyName: params.inputValue,
            });
          }

          return filtered;
        } */
        }
        renderOption={(props, option) => (
          <ListItemButton {...props}>
            <ListItemText primary={option?.companyName} />
          </ListItemButton>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Valitse vakuutusyhtiö" />
        )}
      />
    );
}

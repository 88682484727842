import { ComponentProps, ReactNode, useState } from "react";
import CategoryLabel from "./components/CategoryLabel";

type CategoryContainerProps = {
  visible?: ReactNode;
  hidable?: ReactNode;
} & Pick<ComponentProps<typeof CategoryLabel>, "category">;

function CategoryContainer({
  visible,
  hidable,
  ...props
}: CategoryContainerProps) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <CategoryLabel
        {...props}
        isOpen={open}
        onClick={() => setOpen((p) => !p)}
      />

      {visible}
      {open && hidable}
    </>
  );
}

export default CategoryContainer;

import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Typography,
  MenuItem,
  Skeleton,
} from "@mui/material";
import parsePhoneNumberFromString from "libphonenumber-js";
import { Formik, FieldArray, Field } from "formik";
import { useNotification } from "./Notification";
import DialogComponent from "./DialogComponent";
import { isValid } from "date-fns";
import { centsToLocalString, parseToCents } from "../shared/money";
import { createInnovoiceInvoice } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useFetchAdditionalServices } from "../services/fetchAdditionalServices";
import React, { useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { useFetchInvoices } from "../services/fetchInvoices";
import { useReservationContext } from "./Reservation/ReservationContext";
import { getEffectiveOfferPrice } from "./Reservation/common/utils";
import { useFetchVehicles } from "../services/fetchVehicles";
import FormikInputTextArray from "~/components/Inputs/FormikInputTextArray";
import FormikInputText from "~/components/Inputs/FormikInputText";
import FormikInputDate from "~/components/Inputs/FormikInputDate";
import FormikInputSelectArray from "~/components/Inputs/FormikInputSelectArray";
import FormikInputSelect from "~/components/Inputs/FormikInputSelect";
import { formatDate, formatISODate } from "~/utils/date";
import { VAT_VALUES } from "~/configs/vat-values";

const INSURANCE_TYPES = ["INSURANCE_COMPANY", "INSURANCE_CUSTOMER"];

const getServiceName = (resService, service) => {
  try {
    // Primarily use descrption saved on Reservation, if available
    const description = resService.description
      ? resService.description
      : service.description;

    if (resService.quantity > 1) {
      return `${resService.quantity} x ${description}`;
    }
    return description;
  } catch (e) {
    console.error("Failed to get service description", resService, service);
    return resService.key;
  }
};

const daysDifferenceRoundedUp = (date1, date2) => {
  // Calculate the difference in milliseconds between the two dates.
  const diffMilliseconds = date2 - date1;
  // Convert milliseconds to days and round up to the next integer.
  const daysDifference = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));
  return daysDifference;
};

const getTotalVehiclePrice = (items) => {
  return items
    .filter((i) => i.isVehicle)
    .reduce((accumulator, item) => {
      return accumulator + parseToCents(item.unitprice);
    }, 0);
};

// Add insurance items dynamically when necessary
const getFinalItems = (values, rentalDays, paymentType, invoice) => {
  const items = values.items?.map((i) => ({
    ...i,
    priceperday: centsToLocalString(parseToCents(i.unitprice) / rentalDays),
  }));

  if (INSURANCE_TYPES.includes(paymentType)) {
    if (paymentType === "INSURANCE_COMPANY") {
      const vat = items[0]?.vat ?? 24;
      const totalVehiclePrice = getTotalVehiclePrice(items);
      const coveragePercentage = parseInt(values.insuranceCoveragePercentage); // May be String
      if (coveragePercentage < 100) {
        const customerPortionPercentage = 100 - coveragePercentage;
        const customerPortion = {
          name: "Asiakkaan osuus vähennys",
          description: `Asiakkaan osuus ${customerPortionPercentage}%, Kokonaishinta sis. Alv ${vat}% ${centsToLocalString(
            totalVehiclePrice
          )}`,
          quantity: 1,
          unit: "kpl",
          unitprice: centsToLocalString(
            -totalVehiclePrice * (customerPortionPercentage / 100)
          ),
          vat: vat,
          editable: false,
          insuranceNonEditable: true,
          insuranceItemType: "CUSTOMER_PORTION",
        };
        items.push(customerPortion);
      }
      if (values.insuranceVatDeductible) {
        // Insurance Company pays Vat 0% from their portion
        const insurancePortionCents = items.reduce((accumulator, item) => {
          return accumulator + parseToCents(item.unitprice);
        }, 0);
        const vatCents = (-insurancePortionCents * vat) / (100 + vat);
        const vatDeductibleItem = {
          name: "ALV-vähennys",
          description: "",
          quantity: 1,
          unit: "kpl",
          unitprice: centsToLocalString(vatCents),
          vat: vat,
          editable: false,
          type: "vat_deduction",
          insuranceNonEditable: true,
          insuranceItemType: "VAT_DEDUCTION",
        };
        items.push(vatDeductibleItem);
      }
    }
    if (paymentType === "INSURANCE_CUSTOMER") {
      const coveragePercentage = invoice.insurance.coveragePercentage;
      const customerCoveragePercentage = 100 - coveragePercentage; // Percentage for customer
      // Get Customer portion by CUSTOMER_PORTION identifier, abs(unitprice)
      const customerPortionItem = invoice.items.find(
        (item) => item.insuranceItemType === "CUSTOMER_PORTION"
      );
      if (customerPortionItem) {
        const customerPortion = {
          ...customerPortionItem,
          unitprice: centsToLocalString(
            Math.abs(customerPortionItem.unitprice)
          ),
          insuranceNonEditable: true,
          insuranceItemType: "CUSTOMER_PORTION",
          name: invoice.insurance.vehicle_regno,
          description: `${formatDate(
            invoice.insurance.rental_start_date
          )} - ${formatDate(
            invoice.insurance.rental_end_date
          )}. Asiakkaan osuus ${customerCoveragePercentage}%`,
        };
        items.push(customerPortion);
      }
      const vatDeductionItem = invoice.items.find(
        (item) => item.insuranceItemType === "VAT_DEDUCTION"
      );
      if (vatDeductionItem) {
        const vatDeduction = {
          ...vatDeductionItem,
          unitprice: centsToLocalString(Math.abs(vatDeductionItem.unitprice)),
          insuranceNonEditable: true,
          insuranceItemType: "VAT_EXCLUDED_DEDUCTION",
          type: "vat_excluded_deduction",
          name: "Veron osuus",
          description: `${formatDate(
            invoice.insurance.rental_start_date
          )} - ${formatDate(
            invoice.insurance.rental_end_date
          )}. Veron osuus (alv. ${vatDeductionItem.vat}%)`,
        };
        items.push(vatDeduction);
      }

      // If Vat deductible, get insurance portion and vat deduction.
    }
  }

  return items;
};

const parseItemForSubmit = (item, rentalDays) => {
  const quantity = item.unit === "pv" ? rentalDays : item.quantity;
  const unitprice = item.unit === "pv" ? item.priceperday : item.unitprice;
  return {
    name: item.name,
    description: item.description,
    quantity: quantity,
    unit: item.unit,
    vat: item.vat,
    unitprice: parseToCents(unitprice),
    type: item.type,
    insuranceItemType: item.insuranceItemType,
  };
};

export default function InvoiceDialog({
  title,
  onClose,
  invoiceState,
  reservationId,
  reservationValues,
  FEATURE_INVOICING,
}) {
  const paymentType = invoiceState.type;
  const readOnly = invoiceState.readOnly;
  const { vehicleData: allVehicles } = useFetchVehicles();
  const { additionalServices, additionalServicesLoading } =
    useFetchAdditionalServices();
  const { selectedOffers, selectedServiceOffers, startTime, returnTime } =
    useReservationContext();
  const { invoicesMutate } = useFetchInvoices(reservationId, FEATURE_INVOICING);
  const notification = useNotification();

  const rentalDays = useMemo(() => {
    return daysDifferenceRoundedUp(startTime, returnTime);
  }, [startTime, returnTime]);

  const getVehicleObjById = (id) => {
    if (!id || !allVehicles) return null;
    const vehicleObj = allVehicles.find((v) => v.id === id);
    return vehicleObj;
  };

  const isAutoliitto = useMemo(() => {
    return paymentType === "AUTOLIITTO";
  }, [paymentType]);

  let modeLabel;
  if (paymentType === "FULL") {
    modeLabel = "Koko varaus";
  } else if (INSURANCE_TYPES.includes(paymentType)) {
    modeLabel = "VAKUUTUS";
  } else if (paymentType === "AUTOLIITTO") {
    modeLabel = "Autoliitto";
  } else if (paymentType === "EXTRA") {
    modeLabel = "Jälkilaskutus";
  } else if (paymentType === "COMPENSATION") {
    modeLabel = "Hyvityslasku";
  } else {
    // Unknown state
    console.log("Unknown paymentType");
    return null;
  }

  const getInitialItems = () => {
    const description = `Vuokraus välillä ${formatDate(
      startTime
    )} - ${formatDate(returnTime)}`;

    if (paymentType === "EXTRA" && !readOnly) {
      return [
        {
          name: "",
          description: "",
          quantity: 1,
          unit: "kpl",
          unitprice: "",
          vat: 24,
        },
      ];
    }

    if (paymentType === "COMPENSATION" || readOnly) {
      console.log("Invoice: ", invoiceState.invoice);
      return invoiceState.invoice.items.map((item) => {
        return {
          ...item,
          unitprice: centsToLocalString(item.unitprice * -1),
        };
      });
    }

    const vehicles =
      selectedOffers?.map((v) => {
        const vehicle = getVehicleObjById(v.id);
        return {
          name: `${vehicle.name} ${vehicle.registrationPlate}`,
          description: description,
          quantity: 1,
          unit: "pv",
          //unitprice: v.price,
          unitprice: centsToLocalString(getEffectiveOfferPrice(v)),
          vat: v.tax ?? 24,
          insuranceNonRemovable: true,
          isVehicle: true,
        };
      }) ?? [];
    let services = [];
    const filteredServices = selectedServiceOffers?.filter((service) => {
      if (
        paymentType === "INSURANCE_COMPANY" &&
        service.category !== "insurance"
      ) {
        // Insurance company pays only for the insurance Services
        return false;
      }
      if (
        paymentType === "INSURANCE_CUSTOMER" &&
        service.category === "insurance"
      ) {
        // Insurance customer does not pay for the insurance Services
        return false;
      }

      return true;
    });
    services =
      filteredServices?.map((reservationService) => {
        const service = additionalServices?.find(
          (serviceObj) => reservationService.key === serviceObj.id
        );

        return {
          name: getServiceName(reservationService, service),
          description: description,
          quantity: reservationService.quantity,
          unit: "kpl",
          //unitprice: reservationService.price,
          unitprice: centsToLocalString(
            getEffectiveOfferPrice(reservationService)
          ),

          vat: reservationService.tax ?? 24,
        };
      }) ?? [];

    // Read services from the Reservation, rest comes from the Invoice sent to insurance company
    if (paymentType === "INSURANCE_CUSTOMER") return services;

    const items = [...vehicles, ...services];
    return items;
  };

  const {
    name,
    address,
    postalCode,
    city,
    email,
    phoneCountryCode,
    phone,
    ssn,
    companyName,
    companyBillingAddress,
    companyCity,
    companyPostalCode,
    companyBusinessId,
    companyBillingRef,
    companyEBillingOperator,
    companyEBillingAddress,
    insuranceData,
    customerCarRegistrationPlate,
  } = reservationValues;

  const firstname = name?.split(" ")?.[0];
  const lastname = name?.split(" ")?.[1];
  const contact_person = name;

  const parsedPhone = phone
    ? parsePhoneNumberFromString(phoneCountryCode + phone).number
    : "";

  let buyer_reference = companyBillingRef;
  if (paymentType === "INSURANCE_COMPANY") {
    buyer_reference = `${insuranceData?.billingPermitCode} / ${customerCarRegistrationPlate}`;
  }

  const customerType =
    reservationValues.isCompany || isAutoliitto ? "company" : "person";

  const INITIAL_FORM_DATA = {
    items: getInitialItems(
      insuranceData?.coveragePercentage,
      insuranceData?.vatDeductible
    ),
    customer_type: customerType,
    payment_terms: "30",
    delivery_date: new Date(),
    seller_reference: "",
    buyer_reference: buyer_reference,
    e_invoice_operator: companyEBillingOperator,
    e_invoice_address: companyEBillingAddress,
    vat_included: 1,
    delivery_by: "mail",
    insuranceCompany: reservationValues?.insuranceCompany ?? null,
    insuranceCoveragePercentage: insuranceData?.coveragePercentage ?? 100,
    insuranceVatDeductible: insuranceData?.vatDeductible ?? false,
    vehicle_class: reservationValues.reservationVehicles?.[0]?.categoryName,
  };

  if (customerType === "person") {
    INITIAL_FORM_DATA.firstname = firstname;
    INITIAL_FORM_DATA.lastname = lastname;
    INITIAL_FORM_DATA.address = address;
    INITIAL_FORM_DATA.postalCode = postalCode;
    INITIAL_FORM_DATA.city = city;
    INITIAL_FORM_DATA.email = email;
    INITIAL_FORM_DATA.phone = parsedPhone;
    INITIAL_FORM_DATA.ssn = ssn;
  } else {
    INITIAL_FORM_DATA.address = companyBillingAddress;
    INITIAL_FORM_DATA.postalCode = companyPostalCode;
    INITIAL_FORM_DATA.city = companyCity;
    INITIAL_FORM_DATA.email = email;
    // INITIAL_FORM_DATA.phone = parsedPhone;
    INITIAL_FORM_DATA.contact_person = contact_person;
    INITIAL_FORM_DATA.companyName = companyName;
    INITIAL_FORM_DATA.companyBusinessId = companyBusinessId;
  }

  // Modify base data if readOnly is true using invoice data
  if (readOnly) {
    INITIAL_FORM_DATA.firstname =
      invoiceState.invoice.customer.firstname || INITIAL_FORM_DATA.firstname;
    INITIAL_FORM_DATA.lastname =
      invoiceState.invoice.customer.lastname || INITIAL_FORM_DATA.lastname;
    INITIAL_FORM_DATA.address =
      invoiceState.invoice.customer.address || INITIAL_FORM_DATA.address;
    INITIAL_FORM_DATA.postalCode =
      invoiceState.invoice.customer.zipcode || INITIAL_FORM_DATA.postalCode;
    INITIAL_FORM_DATA.city =
      invoiceState.invoice.customer.city || INITIAL_FORM_DATA.city;
    INITIAL_FORM_DATA.email =
      invoiceState.invoice.customer.email || INITIAL_FORM_DATA.email;
    INITIAL_FORM_DATA.phone =
      invoiceState.invoice.customer.phone || INITIAL_FORM_DATA.phone;
    INITIAL_FORM_DATA.ssn =
      invoiceState.invoice.customer.ssn || INITIAL_FORM_DATA.ssn;
    INITIAL_FORM_DATA.contact_person =
      invoiceState.invoice.customer.contact_person ||
      INITIAL_FORM_DATA.contact_person;
    INITIAL_FORM_DATA.companyName =
      invoiceState.invoice.customer.companyName ||
      INITIAL_FORM_DATA.companyName;
    INITIAL_FORM_DATA.companyBusinessId =
      invoiceState.invoice.customer.companyBusinessId ||
      INITIAL_FORM_DATA.companyBusinessId;
    INITIAL_FORM_DATA.customer_type =
      invoiceState.invoice.customer_type || INITIAL_FORM_DATA.customer_type;
    INITIAL_FORM_DATA.payment_terms =
      invoiceState.invoice.payment_terms || INITIAL_FORM_DATA.payment_terms;
    INITIAL_FORM_DATA.delivery_date =
      new Date(invoiceState.invoice.delivery_date) ||
      INITIAL_FORM_DATA.delivery_date;
    INITIAL_FORM_DATA.seller_reference =
      invoiceState.invoice.seller_reference ||
      INITIAL_FORM_DATA.seller_reference;
    INITIAL_FORM_DATA.buyer_reference =
      invoiceState.invoice.buyer_reference || INITIAL_FORM_DATA.buyer_reference;
    INITIAL_FORM_DATA.e_invoice_operator =
      invoiceState.invoice.e_invoice_operator ||
      INITIAL_FORM_DATA.e_invoice_operator;
    INITIAL_FORM_DATA.e_invoice_address =
      invoiceState.invoice.e_invoice_address ||
      INITIAL_FORM_DATA.e_invoice_address;
    INITIAL_FORM_DATA.vat_included =
      invoiceState.invoice.vat_included || INITIAL_FORM_DATA.vat_included;
    INITIAL_FORM_DATA.delivery_by =
      invoiceState.invoice.delivery_by || INITIAL_FORM_DATA.delivery_by;
    INITIAL_FORM_DATA.insuranceCompany =
      invoiceState.invoice.insuranceCompany ||
      INITIAL_FORM_DATA.insuranceCompany;
    INITIAL_FORM_DATA.insuranceCoveragePercentage =
      invoiceState.invoice.insuranceCoveragePercentage ||
      INITIAL_FORM_DATA.insuranceCoveragePercentage;
    INITIAL_FORM_DATA.insuranceVatDeductible =
      invoiceState.invoice.insuranceVatDeductible ||
      INITIAL_FORM_DATA.insuranceVatDeductible;
    INITIAL_FORM_DATA.vehicle_class =
      invoiceState.invoice.vehicle_class || INITIAL_FORM_DATA.vehicle_class;
    INITIAL_FORM_DATA.items =
      invoiceState.invoice.items.map((item) => ({
        ...item,
        unitprice: centsToLocalString(item.unitprice),
      })) || INITIAL_FORM_DATA.items;
  }
  // TODO: Add similar block to SOS. Their companyBusinessId is 2083957-5
  if (isAutoliitto) {
    INITIAL_FORM_DATA.firstname = "";
    INITIAL_FORM_DATA.lastname = "";
    INITIAL_FORM_DATA.address = "";
    INITIAL_FORM_DATA.postalCode = "";
    INITIAL_FORM_DATA.city = "";
    INITIAL_FORM_DATA.email = "";
    INITIAL_FORM_DATA.phone = "";
    INITIAL_FORM_DATA.ssn = "";
    INITIAL_FORM_DATA.contact_person = "";
    INITIAL_FORM_DATA.companyName = "";
    INITIAL_FORM_DATA.companyBusinessId = "0606127-7"; // Innovoice uses this Company ID to tie the Invoice into Autoliitto
  }

  const validate = (values) => {
    const errors = {};
    const LABEL_REQUIRED = "Täyty kenttä";
    // Invoice
    if (!values.payment_terms) {
      errors.payment_terms = LABEL_REQUIRED;
    }
    if (!values.vat_included) {
      errors.vat_included = LABEL_REQUIRED;
    }
    //if (!values.invoice_type) {
    //errors.invoice_type = LABEL_REQUIRED;
    //}
    if (!values.delivery_by) {
      errors.delivery_by = LABEL_REQUIRED;
    }
    if (values.delivery_by === "e_invoice") {
      if (!values.e_invoice_operator) {
        errors.e_invoice_operator = LABEL_REQUIRED;
      }
      if (!values.e_invoice_address) {
        errors.e_invoice_address = LABEL_REQUIRED;
      }
    }
    if (!values.delivery_date) {
      errors.delivery_date = LABEL_REQUIRED;
    }
    if (!isValid(values.delivery_date)) {
      errors.delivery_date = "Virheellinen aikamuoto";
    }
    //if (!values.seller_reference) {
    //errors.seller_reference = LABEL_REQUIRED;
    //}
    //if (!values.buyer_reference) {
    //errors.buyer_reference = LABEL_REQUIRED;
    //}
    // Customer
    if (values.customer_type === "person") {
      if (!values.firstname) {
        errors.firstname = LABEL_REQUIRED;
      }
      if (!values.lastname) {
        errors.lastname = LABEL_REQUIRED;
      }
      //if (!values.ssn) {
      //errors.ssn = LABEL_REQUIRED;
      //}
    }
    if (values.customer_type === "company") {
      if (!values.companyName && !isAutoliitto) {
        errors.companyName = LABEL_REQUIRED;
      }
      if (!values.companyBusinessId) {
        errors.companyBusinessId = LABEL_REQUIRED;
      }
    }
    if (!values.address && !isAutoliitto) {
      errors.address = LABEL_REQUIRED;
    }
    if (!values.postalCode && !isAutoliitto) {
      errors.postalCode = LABEL_REQUIRED;
    }
    if (values.delivery_by === "email" && !values.email) {
      errors.email = LABEL_REQUIRED;
    }
    //if (!values.phone) {
    //errors.phone = LABEL_REQUIRED;
    //}
    if (!values.city && !isAutoliitto) {
      errors.city = LABEL_REQUIRED;
    }
    // Items
    errors.items = [];
    for (let i = 0; i < values.items.length; i++) {
      if (!errors.items[i]) {
        errors.items[i] = {};
      }
      if (!values.items[i].name) {
        errors.items[i].name = LABEL_REQUIRED;
      }
      if (!values.items[i].unitprice) {
        errors.items[i].unitprice = LABEL_REQUIRED;
      } else if (parseToCents(values.items[i].unitprice) === null) {
        errors.items[i].unitprice = `Anna hinta muodossa "123,45"`;
      }
      if (!values.items[i].quantity) {
        errors.items[i].quantity = LABEL_REQUIRED;
      } else if (!Number.isInteger(values.items[i].quantity)) {
        errors.items[i].quantity = "Syötä kokonaisluku";
      }
      if (!values.items[i].vat) {
        errors.items[i].vat = LABEL_REQUIRED;
      }
      if (Object.keys(errors.items[i]).length === 0) delete errors.items[i];
    }
    // Insurance
    if (paymentType === "INSURANCE_COMPANY") {
      if (!values.insuranceCompany) {
        errors.insuranceCompany = "Valitse vakuutusyhtiö";
      }
    }
    if (paymentType === "INSURANCE_COMPANY" || paymentType === "AUTOLIITTO") {
      if (!values.vehicle_class) {
        errors.vehicle_class = LABEL_REQUIRED;
      }
    }
    if (Object.keys(errors.items).length === 0) delete errors.items; // Convert to empty object if no errors are found
    //console.log(errors);
    return errors;
  };

  if (additionalServicesLoading) {
    return (
      <DialogComponent
        open={paymentType !== false}
        dialogClose={() => onClose()}
        dialogTitle={`${title} - ${modeLabel}`}
        maxWidth="md"
        disableAction={true}
        dialogActionDisabled={true}
      >
        <Box>
          <Skeleton height={150} />
          <Skeleton height={50} />
          <Skeleton height={150} />
        </Box>
      </DialogComponent>
    );
  }

  return (
    <div>
      <Formik
        initialValues={{ ...INITIAL_FORM_DATA }}
        validate={validate}
        onSubmit={async (values, actions) => {
          if (paymentType === "COMPENSATION") {
            notification.show("Work in progress");
            return;
          }
          const customer = {
            customer_type: values.customer_type,
            address: values.address,
            zipcode: values.postalCode,
            email: values.email,
            phone: values.phone,
            city: values.city,
          };
          if (values.customer_type === "person") {
            customer.firstname = values.firstname;
            customer.lastname = values.lastname;
            customer.ssn = values.ssn;
          }
          if (values.customer_type === "company") {
            customer.company_name = values.companyName;
            customer.business_id = values.companyBusinessId;
            customer.e_invoice_address = values.e_invoice_address;
            customer.e_invoice_operator = values.e_invoice_operator;
            customer.contact_person = values.contact_person;
          }

          const items = getFinalItems(
            values,
            rentalDays,
            paymentType,
            invoiceState.invoice
          );

          const input = {
            invoice: {
              invoice: {
                payment_terms: values.payment_terms,
                vat_included: values.vat_included,
                invoice_type: "normal",
                delivery_by: values.delivery_by,
                delivery_date: formatISODate(values.delivery_date),
                seller_reference: values.seller_reference,
                buyer_reference: values.buyer_reference,
              },
              items: items.map((item) => parseItemForSubmit(item, rentalDays)),
              customer: customer,
            },
            reservationId: reservationId,
            paymentType: paymentType === "AUTOLIITTO" ? "FULL" : paymentType, // For now use FULL to send autoliitto invoices
          };

          // Autoliitto invoices also requires insurance object. insurance_company_id is not needed, but it will be undefined
          if (
            paymentType === "INSURANCE_COMPANY" ||
            paymentType === "AUTOLIITTO"
          ) {
            const coveragePercentage = parseInt(
              values.insuranceCoveragePercentage
            ); // May be String
            const totalVehiclePrice = getTotalVehiclePrice(values.items);

            const insurance = {
              amount_to_pay_insurance:
                totalVehiclePrice * (coveragePercentage / 100),
              amount_to_pay_customer:
                totalVehiclePrice * ((100 - coveragePercentage) / 100),
              amount_cost_per_day: totalVehiclePrice / rentalDays,
              rental_days: rentalDays,
              rental_end_date: formatISODate(returnTime),
              rental_start_date: formatISODate(startTime),
              claim_no: reservationValues.insuranceData?.billingPermitCode,
              vehicle_regno: customerCarRegistrationPlate,
              vehicle_class: values.vehicle_class,
              insurance_company_id: values.insuranceCompany?.innovoiceId,
              coveragePercentage: values.insuranceCoveragePercentage,
            };
            input.invoice.insurance = insurance;
            input.invoice.invoice.invoice_type = "insurance";
            if (paymentType === "AUTOLIITTO") {
              input.invoice.invoice.invoice_type = "normal";
            }
          }

          try {
            const response = await API.graphql(
              graphqlOperation(createInnovoiceInvoice, input)
            );
            if (response.data.createInnovoiceInvoice.success) {
              console.log("Invoice created: ", response);
              notification.show("Lasku luotu onnistuneesti.", "success");
              await invoicesMutate();
              onClose();
            } else {
              if (response.data.createInnovoiceInvoice.message) {
                notification.show(response.data.createInnovoiceInvoice.message);
              } else {
                notification.show(
                  "Jokin meni vikaan, ota yhteys asiakaspalveluun"
                );
              }
            }

            actions.setSubmitting(false);
          } catch (error) {
            console.error("Invoice creation failed", error);
            notification.show("Jokin meni vikaan, ota yhteys asiakaspalveluun");
            actions.setSubmitting(false);
          }
        }}
      >
        {(formikProps) => (
          <DialogComponent
            open={paymentType !== false}
            dialogClose={() => onClose()}
            dialogAction={() => {
              formikProps.submitForm();
            }}
            dialogActionText={
              formikProps.isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                "Lähetä"
              )
            }
            dialogTitle={`${title} - ${modeLabel}`}
            maxWidth="md"
            disableAction={formikProps.isSubmitting}
            dialogActionDisabled={formikProps.isSubmitting || readOnly}
          >
            <form onSubmit={formikProps.handleSubmit}>
              <Box sx={{ flexGrow: 1 }}>
                <FormGrid
                  formikProps={formikProps}
                  paymentType={paymentType}
                  invoice={invoiceState.invoice}
                  rentalDays={rentalDays}
                  isAutoliitto={isAutoliitto}
                  readOnly={readOnly}
                  onClose={onClose}
                />
              </Box>
            </form>
          </DialogComponent>
        )}
      </Formik>
    </div>
  );
}

function InsuranceInformation({ formikProps, paymentType }) {
  const { values } = formikProps;

  if (!INSURANCE_TYPES.includes(paymentType)) return null;
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Laskutettava taho:
        </Typography>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 17,
          }}
        >
          {paymentType === "INSURANCE_COMPANY"
            ? `${values.insuranceCompany.companyName}`
            : "Asiakas"}
        </Typography>
      </Grid>
    </Grid>
  );
}

function CustomerInformation({
  formikProps,
  visibility,
  readOnly,
  innovoiceId,
}) {
  if (!visibility) return null;
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={8}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Asiakkaan tiedot -{" "}
          {formikProps.values.customer_type === "person"
            ? "Kuluttaja"
            : "Yritys"}
        </Typography>
      </Grid>
      <Grid item xs={4} style={{ textAlign: "right" }}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          {innovoiceId ? `Innovoice ID: ${innovoiceId}` : ""}
        </Typography>
      </Grid>
      {formikProps.values.customer_type === "person" ? (
        <>
          <Field
            name="firstname"
            label="Etunimi"
            component={FormikInputText}
            required
            readOnly={readOnly}
          />
          <Field
            name="lastname"
            label="Sukunimi"
            component={FormikInputText}
            required
            readOnly={readOnly}
          />
          <Field
            name="ssn"
            label="Henkilötunnus"
            component={FormikInputText}
            readOnly={readOnly}
          />
        </>
      ) : (
        <>
          <Field
            name="companyName"
            label="Yrityksen nimi"
            component={FormikInputText}
            required
            readOnly={readOnly}
          />
          <Field
            name="companyBusinessId"
            label="Y-tunnus"
            component={FormikInputText}
            required
            readOnly={readOnly}
          />
          <Field
            name="contact_person"
            label="Yhteyshenkilö"
            component={FormikInputText}
            readOnly={readOnly}
          />
        </>
      )}
      <Field
        name="address"
        label="Osoite"
        component={FormikInputText}
        required
        readOnly={readOnly}
      />
      <Field
        name="postalCode"
        label="Postinumero"
        component={FormikInputText}
        required
        readOnly={readOnly}
      />
      <Field
        name="city"
        label="Postitoimipaikka"
        component={FormikInputText}
        required
        readOnly={readOnly}
      />
      <Field
        name="email"
        label="Sähköposti"
        component={FormikInputText}
        readOnly={readOnly}
      />
      <Field
        name="phone"
        label="Puhelin"
        component={FormikInputText}
        readOnly={readOnly}
      />
    </Grid>
  );
}

function InvoiceItems({
  paymentType,
  invoice,
  formikProps,
  visibility,
  rentalDays,
  onClose,
  readOnly,
}) {
  const notification = useNotification();
  const items = React.useMemo(() => {
    if (readOnly && invoice) {
      // If read-only, use items directly from the invoice without modification
      return invoice.items.map((item) => ({
        ...item,
        unitprice: centsToLocalString(item.unitprice),
      }));
    }
    try {
      return getFinalItems(
        formikProps.values,
        rentalDays,
        paymentType,
        invoice
      );
    } catch (e) {
      onClose();
      notification.show("Jokin meni vikaan, ota  yhteys asiakaspalveluun");
      return [];
    }
  }, [
    formikProps.values,
    paymentType,
    rentalDays,
    invoice,
    notification,
    onClose,
    readOnly,
  ]);

  const itemsPriceTotal = React.useMemo(() => {
    const totalCents = items.reduce(
      (total, item) => total + parseToCents(item.unitprice) * item.quantity,
      0
    );
    return centsToLocalString(totalCents);
  }, [items]);

  if (!visibility) return null;
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Laskurivit
        </Typography>
      </Grid>
      <FieldArray
        name="items"
        render={(arrayHelpers) => (
          <Grid container item spacing={3}>
            {items.map((item, index) => {
              if (item.insuranceNonEditable) {
                return (
                  <Grid container item spacing={1} key={index}>
                    <Field
                      readOnly={readOnly}
                      index={index}
                      label="Tuotenimi"
                      disabled
                      value={item.name}
                      component={FormikInputTextArray}
                      xs={12}
                    />
                    <Field
                      readOnly={readOnly}
                      index={index}
                      label="Selite"
                      disabled
                      value={item.description}
                      component={FormikInputTextArray}
                      xs={12}
                    />
                    <Field
                      readOnly={readOnly}
                      index={index}
                      label="Hinta"
                      disabled
                      value={item.unitprice}
                      component={FormikInputTextArray}
                      xs={6}
                      md={2}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                );
              }
              return (
                <Grid container item spacing={1} key={index}>
                  <Field
                    readOnly={readOnly}
                    index={index}
                    arrayName={"items"}
                    attributeName={`name`}
                    name={`items.${index}.name`}
                    label="Tuotenimi"
                    component={FormikInputTextArray}
                    required
                    xs={6}
                  />
                  <Field
                    readOnly={readOnly}
                    index={index}
                    arrayName={"items"}
                    attributeName={`description`}
                    name={`items.${index}.description`}
                    label="Tuoteselite"
                    component={FormikInputTextArray}
                    xs={6}
                  />
                  <Field
                    index={index}
                    arrayName={"items"}
                    attributeName={`unitprice`}
                    name={`items.${index}.unitprice`}
                    label={item.unit === "pv" ? "Kokonaishinta" : "Hinta"}
                    component={FormikInputTextArray}
                    required
                    xs={6}
                    md={2}
                    disabled={item.editable === false || readOnly}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                  <Field
                    readOnly={readOnly}
                    index={index}
                    label="Osuus"
                    component={FormikInputTextArray}
                    required
                    xs={6}
                    md={2}
                    disabled
                    value={item.pricePortion}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    hidden={true} // Not is use, at least for now
                  />
                  <Field
                    readOnly={readOnly}
                    index={index}
                    arrayName={"items"}
                    attributeName={`quantity`}
                    name={`items.${index}.quantity`}
                    label="Määrä"
                    component={FormikInputTextArray}
                    type="number"
                    required
                    xs={6}
                    md={2}
                    disabled={item.editable === false || readOnly}
                    hidden={item.unit === "pv"}
                  />
                  <Field
                    readOnly={readOnly}
                    label="Päiviä"
                    component={FormikInputTextArray}
                    type="number"
                    value={rentalDays}
                    required
                    xs={6}
                    md={2}
                    disabled={true}
                    hidden={item.unit !== "pv"}
                  />
                  <Field
                    readOnly={readOnly}
                    index={index}
                    arrayName={"items"}
                    attributeName={`unit`}
                    name={`items.${index}.unit`}
                    label="Yksikkö"
                    component={FormikInputSelectArray}
                    xs={6}
                    md={2}
                    disabled={item.editable === false || readOnly}
                  >
                    <MenuItem value="kpl">kpl</MenuItem>
                    <MenuItem value="pv">pv</MenuItem>
                  </Field>
                  <Field
                    readOnly={readOnly}
                    label="Päivähinta"
                    component={FormikInputTextArray}
                    required
                    xs={6}
                    md={2}
                    disabled
                    value={item.priceperday}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                    hidden={item.unit !== "pv"}
                  />
                  <Field
                    readOnly={readOnly}
                    index={index}
                    arrayName={"items"}
                    attributeName={`vat`}
                    name={`items.${index}.vat`}
                    label="ALV %"
                    component={FormikInputSelectArray}
                    type="number"
                    xs={6}
                    md={2}
                    disabled={item.editable === false || readOnly}
                  >
                    {Object.entries(VAT_VALUES).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => arrayHelpers.remove(index)}
                      disabled={
                        item.editable === false ||
                        item.insuranceNonRemovable ||
                        readOnly
                      }
                    >
                      -
                    </Button>
                  </Grid>
                </Grid>
              );
            })}

            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  arrayHelpers.push({
                    name: "",
                    description: "",
                    unitprice: "",
                    quantity: 1,
                    unit: "kpl",
                    vat: 24,
                  })
                }
                disabled={readOnly}
              >
                Lisää rivi
              </Button>
            </Grid>
          </Grid>
        )}
      />
      <Grid container item>
        <Typography fontWeight="bold">Yhteensä: {itemsPriceTotal}</Typography>
      </Grid>
    </Grid>
  );
}

function InvoiceInformation({
  formikProps,
  visibility,
  paymentType,
  rentalDays,
  readOnly,
}) {
  const isInsuranceType = useMemo(() => {
    if (paymentType === "INSURANCE_COMPANY" || paymentType === "AUTOLIITTO") {
      return true;
    }
    return false;
  }, [paymentType]);

  const paymentTermValues = useMemo(() => {
    let allowedValues = [7, 14, 21, 30, 60, 90];
    if (paymentType === "INSURANCE_COMPANY") {
      allowedValues = [30];
    } else if (formikProps.values.customer_type === "person") {
      allowedValues = [14, 21, 30, 60, 90];
    }
    return allowedValues;
  }, [paymentType, formikProps.values.customer_type]);

  if (!visibility) return null;
  return (
    <Grid container item spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Laskun tiedot
        </Typography>
      </Grid>
      <Field
        readOnly={readOnly}
        name="payment_terms"
        label="Maksuehto"
        component={FormikInputSelect}
        md={4}
      >
        {paymentTermValues.map((value) => {
          return (
            <MenuItem value={value} key={`paymentTerm-${value}`}>
              {value}
            </MenuItem>
          );
        })}
      </Field>
      <Field
        readOnly={readOnly}
        name="delivery_date"
        label="Laskun päiväys"
        component={FormikInputDate}
        md={4}
      />
      {!isInsuranceType && (
        <Field
          readOnly={readOnly}
          name="delivery_by"
          label="Toimitustapa"
          component={FormikInputSelect}
          md={4}
        >
          <MenuItem value="mail">Posti</MenuItem>
          <MenuItem value="email">Sähköposti</MenuItem>
          {formikProps.values.customer_type === "company" && (
            <MenuItem value="e_invoice">Verkkolasku</MenuItem>
          )}
        </Field>
      )}
      <Field
        readOnly={readOnly}
        name="seller_reference"
        label="Myyjän viite"
        component={FormikInputText}
        md={4}
      />
      <Field
        readOnly={readOnly}
        name="buyer_reference"
        label="Ostajan viite"
        component={FormikInputText}
        md={4}
      />
      {isInsuranceType && (
        <Field
          readOnly={readOnly}
          name="vehicle_class"
          label="Korvattava autoluokka"
          component={FormikInputText}
          md={4}
        />
      )}
      {paymentType === "INSURANCE_COMPANY"}
      {formikProps.values.delivery_by === "e_invoice" && (
        <>
          <Field
            readOnly={readOnly}
            name="e_invoice_operator"
            label="Verkkolaskun välitystunnus"
            component={FormikInputText}
            md={4}
          />
          <Field
            readOnly={readOnly}
            name="e_invoice_address"
            label="Verkkolaskuosoite"
            component={FormikInputText}
            md={4}
          />
        </>
      )}
      <Grid mt={2} item xs={12}>
        <Typography> Vuokrapäivät: {rentalDays}</Typography>
      </Grid>
    </Grid>
  );
}

function FormGrid({
  formikProps,
  paymentType,
  invoice,
  rentalDays,
  isAutoliitto,
  onClose,
  readOnly,
}) {
  return (
    <Grid container spacing={2} sx={{ padding: "10px" }}>
      <InsuranceInformation
        formikProps={formikProps}
        paymentType={paymentType}
      />
      <CustomerInformation
        formikProps={formikProps}
        visibility={!isAutoliitto}
        readOnly={readOnly}
        innovoiceId={invoice?.innovoiceId}
      />
      <InvoiceItems
        formikProps={formikProps}
        //vatDeductible={vatDeductible}
        visibility={true}
        paymentType={paymentType}
        invoice={invoice}
        rentalDays={rentalDays}
        onClose={onClose}
        readOnly={readOnly}
      />
      <InvoiceInformation
        formikProps={formikProps}
        visibility={true}
        paymentType={paymentType}
        rentalDays={rentalDays}
        readOnly={readOnly}
      />
    </Grid>
  );
}

import { createContext, useContext } from "react";
import { EDeviceType } from "~/enums/device-type";

export type Settings = {
  deviceType: EDeviceType;
  rowVariant: "regular" | "compact";
  colVariant: "regular" | "compact";
  calendar: { startDate: number; endDate: number; daysCount: number };
};

export const SettingsContext = createContext<Settings>({
  deviceType: EDeviceType.DESKTOP,
  rowVariant: "regular",
  colVariant: "regular",
  calendar: {
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    daysCount: 1,
  },
});

export const useSettingsContext = () => useContext(SettingsContext);

import React from "react";
import { EMenuItems } from "./enums";
import {
  TbChartHistogram,
  TbCurrencyEuro,
  TbFileText,
  TbHexagonLetterH,
  TbMessage2,
  TbUserPlus,
} from "react-icons/tb";
import CustomerInformationTab from "../views/BusinessUsers/content/tabs/customerInformation";
import Summary from "../views/BusinessUsers/content/tabs/summary";
import ReservationPayment from "../views/BusinessUsers/content/tabs/payment";
import ReservationPaymentNew from "../views/BusinessUsers/content/tabs/payment_new";
import ReservationMessages from "../views/BusinessUsers/content/tabs/messages";
import { reservationFields } from "../formik/fields";
import { devStaging } from "../../../features";

const isDevStaging = !!devStaging(process.env.REACT_APP_ENV);

const menuIconSize = "28px";

const customerInformation = {
  [EMenuItems.TITLE]: "Asiakkaan tiedot",
  [EMenuItems.ICON]: <TbFileText style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.TAB]: "customer",
  [EMenuItems.FIELD_ERRORS]: [
    reservationFields.name,
    reservationFields.email,
    reservationFields.phone,
    reservationFields.companyName,
    reservationFields.customerBrokerId,
    reservationFields.driverPhone,
    reservationFields.additionalDriverPhone,
  ],
  [EMenuItems.VALID_MESSAGE]: "Yhteystiedot täytetty",
  [EMenuItems.COMPONENT]: CustomerInformationTab,
};

const summary = {
  [EMenuItems.TITLE]: "Varauksen tiedot",
  [EMenuItems.ICON]: <TbUserPlus style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.TAB]: "summary",
  [EMenuItems.FIELD_ERRORS]: [
    "mandatoryProduct",
    reservationFields.startTime,
    reservationFields.returnTime,
    reservationFields.endTime,
  ],
  [EMenuItems.VALID_MESSAGE]: "Ajankohta & tuotteet",
  [EMenuItems.COMPONENT]: Summary,
};

const additionalServices = {
  [EMenuItems.TITLE]: "Lisäpalvelut",
  [EMenuItems.ICON]: <TbUserPlus style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.TAB]: "additionalServices",
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.VALID_MESSAGE]: "Valitse varauksen lisäpalvelut",
  [EMenuItems.COMPONENT]: Summary,
};

const reservationPayment = {
  [EMenuItems.TITLE]: "Varauksen maksu (vanha)",
  [EMenuItems.ICON]: <TbCurrencyEuro style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: false,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.TAB]: "payment",
  [EMenuItems.VALID_MESSAGE]: "Maksun tila: ",
  [EMenuItems.COMPONENT]: ReservationPayment,
};

const reservationPaymentNew = {
  [EMenuItems.TITLE]: "Varauksen maksu",
  [EMenuItems.ICON]: <TbCurrencyEuro style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.TAB]: "paymentNew",
  [EMenuItems.VALID_MESSAGE]: "",
  [EMenuItems.COMPONENT]: ReservationPaymentNew,
};

const messages = {
  [EMenuItems.TITLE]: "Viestit",
  [EMenuItems.ICON]: <TbMessage2 style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.TAB]: "messages",
  [EMenuItems.VALID_MESSAGE]: "Varauksen viestit",
  [EMenuItems.COMPONENT]: ReservationMessages,
};

const hansel = {
  [EMenuItems.TITLE]: "Hansel-raportti",
  [EMenuItems.ICON]: <TbHexagonLetterH style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: false,
  [EMenuItems.VISIBILITY_VALUE]: "HANSEL",
  [EMenuItems.VISIBILITY_FIELD]: "type",
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.TAB]: "hansel",
  [EMenuItems.VALID_MESSAGE]: "",
};

const nps = {
  [EMenuItems.TITLE]: "NPS",
  [EMenuItems.ICON]: <TbChartHistogram style={{ fontSize: menuIconSize }} />,
  [EMenuItems.VISIBILITY]: true,
  [EMenuItems.ACTIVE]: true,
  [EMenuItems.FIELD_ERRORS]: [],
  [EMenuItems.TAB]: "nps",
  [EMenuItems.VALID_MESSAGE]: "NPS Selite",
};

export const menu = {
  [summary.TAB]: summary,
  [additionalServices.TAB]: additionalServices,
  [customerInformation.TAB]: customerInformation,
  [reservationPayment.TAB]: reservationPayment,
  [reservationPaymentNew.TAB]: reservationPaymentNew,
  [messages.TAB]: messages,
  [hansel.TAB]: hansel,
  [nps.TAB]: nps,
};

import { ReactNode, useContext } from "react";
import { useHandleContextMenu } from "../../ContextMenu/hooks/useHandleContextMenu";
import { DateInfo } from "../../../hooks/useDates";
import { RowContext } from "../../VehicleRow/RowContext";

type DateCellProps = DateInfo & {
  col: number;
  colOffset?: number;
  children?: ReactNode;
  className?: string;
};

function DateCell({
  col,
  count,
  isToday,
  stringDate,
  colOffset = 2,
  children,
  className,
}: DateCellProps) {
  const { vehicle } = useContext(RowContext);
  const { handleContextMenu } = useHandleContextMenu();

  return (
    <div
      className={className}
      data-cell={count}
      data-is-today={isToday || undefined}
      style={{ gridColumnStart: col + colOffset }}
      onContextMenu={
        vehicle ? handleContextMenu({ date: stringDate }) : undefined
      }
    >
      {children}
    </div>
  );
}

export default DateCell;

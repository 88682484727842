import React, { useContext, useMemo, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import InsuranceCompanySelect from "../common/InsuranceRegionalCompanySelect";
import InnovoiceCredit from "../../InnovoiceCredit";

import { FormContent, FormGrid } from "../../common/FormComponents";

import { centsToLocalString } from "../../../shared/money";

import { AppContext } from "../../../AppContext";
import { useFetchInvoices } from "../../../services/fetchInvoices";
import { useFormikContext, Field } from "formik";
import { useNotification } from "../../Notification";
import { useReservationContext } from "../ReservationContext";

const FormikTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md}>
      <TextField
        {...field}
        variant="outlined"
        error={touched[field.name] && !!errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        size="small"
        fullWidth
        {...props}
      />
    </Grid>
  );
};

const calculateInvoiceSum = (invoice) => {
  return invoice.reduce(
    (total, item) => total + item.quantity * item.unitprice,
    0
  );
};

const getInvoiceTypeLabel = (type) => {
  if (type === "FULL") {
    return "Koko varaus";
  }
  if (type === "EXTRA") {
    return "Lisälasku";
  }
  if (type === "INSURANCE_COMPANY") {
    return "Vakuutusyhtiö";
  }
  if (type === "INSURANCE_CUSTOMER") {
    return "Vakuutusyhtiö asiakkaan osuus";
  }
};

function Invoicing({ setShowInvoiceDialog, business, values, notification }) {
  const { reservationData } = useReservationContext();
  if (!reservationData?.id) return; // Reservation must exist before createing Invoice is possible
  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2} direction="row">
          {reservationData?.type !== "INSURANCE" && (
            <Button
              variant="contained"
              onClick={(e) => {
                setShowInvoiceDialog({ type: "FULL" });
              }}
              disabled={reservationData?.type === "INSURANCE"}
            >
              {" "}
              Lasku asiakkaalle{" "}
            </Button>
          )}
          {reservationData?.channel === "AUTOLIITTO" &&
            reservationData?.type !== "INSURANCE" && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setShowInvoiceDialog({ type: "AUTOLIITTO" });
                }}
              >
                Lasku autoliitolle
              </Button>
            )}
          {reservationData?.type === "INSURANCE" && (
            <Button
              variant="contained"
              onClick={(e) => {
                if (!values.insuranceCompany) {
                  return notification.show("Valitse ensin vakuutusyhtiö");
                }
                if (!values.insuranceData?.billingPermitCode) {
                  return notification.show("Aseta lasutuslupatunnus");
                }
                setShowInvoiceDialog({ type: "INSURANCE_COMPANY" });
              }}
              disabled={reservationData?.type !== "INSURANCE"}
            >
              Vakuutusyhtiölasku{" "}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={(e) => {
              setShowInvoiceDialog({ type: "EXTRA" });
            }}
          >
            Lisälaskutus{" "}
          </Button>
        </Stack>
      </Grid>
      <InsuranceInvoicingSettings />
      <Grid item xs={12}>
        <InnovoiceCredit orgBusinessId={business?.id} initialSsn={values.ssn} />
      </Grid>
    </Grid>
  );
}

function Invoices({ setShowInvoiceDialog, FEATURE_INVOICING }) {
  const { reservationData } = useReservationContext();
  const { invoices, invoicesLoading, invoicesValidating } = useFetchInvoices(
    reservationData?.id,
    FEATURE_INVOICING
  );
  if (invoicesLoading)
    return (
      <Grid item xs={12}>
        <Skeleton height={50} />
      </Grid>
    );
  if (invoices?.length === 0)
    return (
      <Typography variant="h6" my={"10px"}>
        Varauksella ei laskuja
      </Typography>
    );
  return (
    <>
      <Grid
        container
        direction="row"
        style={{
          overflow: "auto",
          marginBottom: "10px",
          marginTop: "10px",
          border: "1px solid #bdbdbd",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <Grid item xs={12}>
          {invoicesValidating && <LinearProgress />}
        </Grid>
        <Typography variant="h6">
          <b>Luodut laskut:</b>
        </Typography>
        {invoices &&
          invoices
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((invoice) => (
              <Grid item xs={12} key={invoice.id}>
                <Card
                  style={{
                    marginBottom: ".3em",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  {" "}
                  <CardContent>
                    <Typography variant="body1" fontWeight="bold">
                      {getInvoiceTypeLabel(invoice.type)}
                    </Typography>
                    {invoice.items.map((item) => (
                      <Typography
                        variant="body2"
                        key={`${item.name}-${item.description}-${item.unitprice}`}
                      >
                        {item.name}: {centsToLocalString(item.unitprice)} €
                      </Typography>
                    ))}
                    <Typography variant="body1">
                      Summa:{" "}
                      {centsToLocalString(calculateInvoiceSum(invoice.items))} €
                    </Typography>
                    <Typography variant="body1">
                      Luotu:{" "}
                      {new Date(invoice.createdAt).toLocaleDateString("fi-FI")}
                    </Typography>
                    <Typography variant="body1">
                      Innovoice ID: {invoice.innovoiceId}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      {invoice.type === "INSURANCE_COMPANY" &&
                        (invoice.insurance?.coveragePercentage < 100 ||
                          invoice.items.some(
                            (i) => i.type === "vat_deduction"
                          )) && (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                              setShowInvoiceDialog({
                                type: "INSURANCE_CUSTOMER",
                                invoice: invoice,
                              });
                            }}
                          >
                            Luo asiakkaan lasku
                          </Button>
                        )}
                      {false && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            setShowInvoiceDialog({
                              type: "COMPENSATION",
                              invoice: invoice,
                            });
                          }}
                        >
                          Hyvitä lasku
                        </Button>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
      </Grid>
    </>
  );
}

function InvoicingContainer({
  setShowInvoiceDialog,
  business,
  values,
  notification,
  FEATURE_INVOICING,
  FEATURE_INNOVOICE,
}) {
  if (!FEATURE_INNOVOICE) return;
  return (
    <>
      <Invoices
        setShowInvoiceDialog={setShowInvoiceDialog}
        FEATURE_INVOICING={FEATURE_INVOICING}
      />
      <Invoicing
        setShowInvoiceDialog={setShowInvoiceDialog}
        business={business}
        values={values}
        notification={notification}
      />
    </>
  );
}

function PaymentMethodSelect() {
  const { values, handleChange } = useFormikContext();
  const { reservationData } = useReservationContext();
  if (
    ["COMPLETED", "COMPLETED_DEPOSIT", "PENDING_FULL", "PENDING"].includes(
      reservationData?.paymentStatus
    )
  ) {
    // PaymentStatus is controller by Visma Pay payment process.
    return null;
  }

  if (values.sendStatusToCustomer) {
    return "Lähetetään asiakkaalle täydennettäväksi, asiakas valitsee maksutavan";
  }

  return (
    <FormGrid>
      <FormControl fullWidth>
        <InputLabel id="payment-select-label">Maksun tila</InputLabel>
        <Select
          labelId="payment-select-label"
          id="payment-select"
          value={values.paymentMethod}
          label="Maksutapa"
          name="paymentMethod"
          onChange={handleChange}
        >
          <MenuItem value={"none"}>Noudettaessa</MenuItem>
          <MenuItem value={"PENDING_INVOICE"}>Laskutettava</MenuItem>
          <MenuItem value={"COMPLETED_INVOICE"}>Laskutettu</MenuItem>
          <MenuItem value={"COMPLETED_MANUAL"}>Maksettu</MenuItem>
        </Select>
      </FormControl>
    </FormGrid>
  );
}

function PaymentStatus() {
  const { reservationData } = useReservationContext();
  const statusMessages = useMemo(() => {
    let messages = [];
    const paymentStatus = reservationData?.paymentStatus;
    // TODO: Use same getter function in all Reseration dialog. (duplicated information is also shown under the Dialog)
    // Payment information
    if (paymentStatus === "COMPLETED") {
      messages.push({
        msg: "Visma Pay -maksu suoritettu",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "COMPLETED_DEPOSIT") {
      messages.push({
        msg: "Varausmaksu maksettu",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "PENDING") {
      messages.push({
        msg: "Odottaa asiakkaan maksutavan valintaa",
        key: `payment-${paymentStatus}`,
      });
    } else if (paymentStatus === "COMPLETED") {
      messages.push({
        msg: "Visma Pay -maksu suoritettu",
        key: `payment-${paymentStatus}`,
      });
    }

    if (reservationData?.authStatus === "COMPLETED") {
      messages.push({
        msg: `Vahvasti tunnistautunut: ${reservationData.signerName}`,
        key: `auth-${reservationData?.authStatus}`,
      });
    }

    if (reservationData?.signStatus === "COMPLETED") {
      messages.push({
        msg: `Vahvasti allekirjoittanut: ${reservationData.signerName}`,
        key: `sign-${reservationData.signStatus}`,
      });
    }

    if (reservationData?.livionData) {
      for (const livion of reservationData?.livionData) {
        messages.push({
          msg: `Livion pincode ${livion.name || ""}: ${livion.pincode}`,
          key: `livion-${livion.name}-${livion.pincode}`,
        });
      }
    }

    return messages;
  }, [reservationData]);

  // Show nothing when creating a new Reservation
  if (!reservationData?.id) return null;
  return (
    <Box my={"10px"}>
      <Typography variant="h6" my={"10px"}>
        Varauksen tila:
      </Typography>
      {statusMessages.map((statusMsg) => {
        return (
          <Grid key={statusMsg.key}>
            <Typography variant="p">{statusMsg.msg}</Typography>
          </Grid>
        );
      })}
    </Box>
  );
}

export default function ReservationPayment({
  FEATURE_INVOICING,
  FEATURE_INNOVOICE,
  setShowInvoiceDialog,
}) {
  const [toggleShowContent, setToggleShowContent] = useState(true);

  const notification = useNotification();
  const { USER_ADMIN_GENERIC, USER_ADMIN_INSURANCE, business } =
    useContext(AppContext);
  const { values, totalPrice } = useFormikContext();

  return (
    <Grid
      item
      xs={12}
      sx={{ justifyContent: "flex-start" }}
      container
      direction={"row"}
    >
      <FormContent
        title={"Varauksen maksu"}
        visibility={true}
        expanded={toggleShowContent}
        setExpanded={setToggleShowContent}
      >
        <Grid container spacing={1} sx={{ justifyContent: "center" }}>
          <FormGrid>
            {USER_ADMIN_GENERIC && (
              <>
                <PaymentMethodSelect />
                <InvoicingContainer
                  setShowInvoiceDialog={setShowInvoiceDialog}
                  business={business}
                  values={values}
                  notification={notification}
                  FEATURE_INVOICING={FEATURE_INVOICING}
                  FEATURE_INNOVOICE={FEATURE_INNOVOICE}
                />

                <PaymentStatus />
              </>
            )}
          </FormGrid>
          {/* <FormGrid>
          <Autocomplete
            fullWidth
            value={selectedAgent}
            inputValue={searchValue || ""}
            options={defaultAgents}
            //  loading={nameAutoCompleteLoading}
            onChange={(event, newValue) => {
              setSelectedAgent(newValue);
              setFieldValue("hanselData.hanselAgent", newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setSearchValue(newInputValue);
            }}
            getOptionLabel={(option) => option || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                //     error={touched.orgBusinessId && !!errors.orgBusinessId}
                //   helperText={touched.orgBusinessId && errors.orgBusinessId}

                label="Valitse agentti"
                variant="outlined"
              />
            )}
          />
        </FormGrid> */}
          <FormGrid>
            {USER_ADMIN_INSURANCE && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                  padding: "5px",
                }}
              >
                <Typography variant="h6" align="center">
                  <b>Yhteensä:</b>{" "}
                  {totalPrice != null && Number.isInteger(totalPrice)
                    ? centsToLocalString(totalPrice) + " €"
                    : "-"}
                </Typography>
              </Box>
            )}
          </FormGrid>
        </Grid>
      </FormContent>
    </Grid>
  );
}

function InsuranceInvoicingSettings() {
  const { values, handleChange, handleBlur } = useFormikContext();
  const { reservationData } = useReservationContext();
  if (reservationData.type !== "INSURANCE") return null;
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography style={{ fontFamily: "Sofia Pro", fontSize: 18 }}>
          Vakuutuslaskutuksen asetukset
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="insuranceData.vatDeductible"
              checked={values?.insuranceData?.vatDeductible ?? false}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={<>ALV-vähennys</>}
        />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Field
          name="insuranceData.coveragePercentage"
          label="Korvausjako %"
          component={FormikTextField}
        />
      </Grid>
      <Grid item xs={12} lg={10}>
        <InsuranceCompanySelect size="small" />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Field
          name="insuranceData.billingPermitCode"
          label="Laskutuslupatunnus"
          component={FormikTextField}
        />
      </Grid>
    </Grid>
  );
}

import React, { useContext, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { API, graphqlOperation } from "aws-amplify";

import { centsToLocalString } from "~/shared/money";
import {
  createInnovoiceCreditNote,
  updateInvoice,
  updatePaymentEvent,
} from "../../../../../../../../graphql/mutations";
import { devStaging } from "../../../../../../../../features";
import { AppContext } from "../../../../../../../../AppContext";
import PaymentEventDialog from "./PaymentEventDialog";
import { formatDate } from "~/utils/date";

const PaymentEventsTable = ({
  setShowInvoiceDialog,
  unpaidAmount,
  showActions,
  invoicesMutate,
  invoicesLoading,
  paymentEventsMutate,
  paymentEventsLoading,
  combinedEvents,
  reservationId,
}) => {
  const { user } = useContext(AppContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(null); // 'delete' or 'details' or 'refund'
  const open = Boolean(anchorEl);
  const [submitting, setSubmitting] = useState(false);

  const handleClick = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedEvent(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOnClose = () => {
    setDialogType(null);
    setSelectedEvent(null);
    setDialogOpen(false);
  };

  const openDetailsDialog = (event, type) => {
    setSelectedEvent(event);
    setDialogType(type);
    setDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedEvent.channel === "Invoice") {
      await API.graphql(
        graphqlOperation(updateInvoice, {
          input: {
            id: selectedEvent.id,
            isDeleted: true,
            deletedBy: user?.name,
          },
        })
      );
      invoicesMutate();
    } else if (selectedEvent.channel === "Payment") {
      setSubmitting(true);
      await API.graphql(
        graphqlOperation(updatePaymentEvent, {
          input: {
            id: selectedEvent.id,
            isDeleted: true,
            deletedBy: user?.name,
            deletedAt: new Date().toISOString(),
          },
        })
      );
      setSubmitting(false);
      paymentEventsMutate();
    }

    setDialogOpen(false);
  };

  const confirmRefund = async () => {
    setSubmitting(true);
    await API.graphql(
      graphqlOperation(createInnovoiceCreditNote, {
        reservationId,
        invoiceId: selectedEvent.id,
      })
    );
    setSubmitting(false);
    invoicesMutate();
    setDialogOpen(false);
  };

  if (invoicesLoading || paymentEventsLoading) {
    return (
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  if (combinedEvents.length === 0) {
    return (
      <Typography variant="h6" my={"10px"}>
        Varaukselle ei löytynyt maksutapahtumia.
      </Typography>
    );
  }

  const renderRow = (event) => {
    const { id, label, sum, isDeleted, type, channel, invoice, isRefunded } =
      event;

    // Don't show the refund invoice
    if (type === "CREDIT_NOTE") return;

    const showOptionsMenu = !(type !== "MANUAL" && channel === "Payment");
    const textDecoration = isDeleted || isRefunded ? "line-through" : "none";
    const invoiceCreditNote =
      invoice?.creditNoteInvoiceId &&
      combinedEvents.find((e) => e.invoice?.id === invoice.creditNoteInvoiceId);
    const handleMenuAction = (actionType) => {
      switch (actionType) {
        case "details":
          openDetailsDialog(event, "details");
          break;
        case "showInvoice":
          setShowInvoiceDialog({
            type,
            invoice: invoice || selectedEvent,
            readOnly: true,
          });
          break;
        case "refund":
          openDetailsDialog(event, "refund");
          break;
        case "insuranceCustomerInvoice":
          setShowInvoiceDialog({
            type: "INSURANCE_CUSTOMER",
            invoice: invoice,
          });
          break;
        case "delete":
          openDetailsDialog(event, "delete");
          break;
        default:
          console.warn("Unhandled action type:", actionType);
      }
      handleCloseMenu();
    };

    return (
      <TableRow key={id}>
        <TableCell>
          <span style={{ textDecoration }}>{label}</span>
          {invoiceCreditNote &&
            ` Hyvitetty ${invoiceCreditNote.innovoiceId} (${formatDate(
              invoiceCreditNote.invoice.createdAt
            )})`}
        </TableCell>
        <TableCell align="right">{centsToLocalString(sum)} €</TableCell>
        {showOptionsMenu && showActions && (
          <TableCell align="right">
            <IconButton size="small" onClick={(e) => handleClick(e, event)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open && selectedEvent === event}
              onClose={handleCloseMenu}
            >
              {(type === "MANUAL" || invoice) && (
                <MenuItem
                  onClick={() =>
                    handleMenuAction(
                      type === "MANUAL" ? "details" : "showInvoice"
                    )
                  }
                >
                  Tiedot
                </MenuItem>
              )}
              {channel === "Invoice" && !isRefunded && (
                <MenuItem onClick={() => handleMenuAction("refund")}>
                  Luo hyvityslasku
                </MenuItem>
              )}
              {channel === "Invoice" &&
                invoice.type === "INSURANCE_COMPANY" && (
                  <MenuItem
                    onClick={() => handleMenuAction("insuranceCustomerInvoice")}
                  >
                    Luo asiakkaan lasku
                  </MenuItem>
                )}
              {type === "MANUAL" && !isDeleted && (
                <MenuItem onClick={() => handleMenuAction("delete")}>
                  Poista Maksu
                </MenuItem>
              )}
            </Menu>
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 1, mb: 1 }}>
        <Typography variant="body1" sx={{ ml: 2, my: 1, fontWeight: "bold" }}>
          Maksutapahtumat
        </Typography>
        <Table aria-label="payment events" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Tyyppi</TableCell>
              <TableCell align="right">Summa</TableCell>
              {showActions && <TableCell align="right">Toiminnot</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedEvents.map(renderRow)}
            <TableRow>
              <TableCell
                colSpan={1}
                align="right"
                style={{ fontWeight: "bold" }}
              >
                Maksamatta:
              </TableCell>
              <TableCell align="right" style={{ fontWeight: "bold" }}>
                {centsToLocalString(unpaidAmount)} €
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <PaymentEventDialog
        open={dialogOpen}
        onClose={handleOnClose}
        event={selectedEvent}
        dialogType={dialogType}
        confirmDelete={confirmDelete}
        confirmRefund={confirmRefund}
        handleClose={() => setDialogOpen(false)}
        submitting={submitting}
      />
    </>
  );
};

export default PaymentEventsTable;

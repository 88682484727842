import { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Box,
  Button,
  InputLabel,
} from "@mui/material";

import { Stack } from "@mui/system";
import { useFormikContext } from "formik";

export default function CopyProduct({ companies, mobileViewPort }) {
  const { values, setFieldValue, handleBlur, submitForm } = useFormikContext();
  const handleCopy = () => {
    setFieldValue("id", null);
    setFieldValue("companyId", selectedCompany);
    submitForm();
  };
  const [selectedCompany, setSelectedCompany] = useState(values.companyId);
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: !mobileViewPort ? "flex-start" : "center",
      }}
    >
      <Grid item xs={12} sm={6}>
        <Stack direction="column" justifyContent="center" spacing={2}>
          <Box display={"flex"} sx={{ flexDirection: "column" }}>
            <Typography
              variant="body2"
              align="center"
              sx={{ fontSize: "16px", marginBottom: "15px" }}
            >
              Luo tuoteesta
              <span style={{ fontWeight: 650 }}> {values.name} </span>
              kopio.
            </Typography>
            <Typography variant="body2">
              Voit nimetä kopion uudelleen ja siirtää sen toiselle
              toimipisteelle.
            </Typography>
          </Box>
          <Box display={"flex"}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Toimipiste</InputLabel>
              <Select
                //   placeholder="Valitse toimipiste"
                //   label="Valitse toimipiste"
                fullWidth
                label="Toimipiste"
                value={selectedCompany || ""}
                onChange={(e) => {
                  setSelectedCompany(
                    companies.find((company) => company.id === e.target.value)
                      .id
                  );
                }}
              >
                {companies &&
                  companies.map((company, idx) => (
                    <MenuItem value={company.id} key={idx}>
                      {company.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box display={"flex"}>
            <TextField
              value={values.name}
              label={"Kopion nimi"}
              onChange={(event) => setFieldValue("name", event.target.value)}
              onBlur={handleBlur}
              name={"new name"}
              variant="outlined"
              /*   error={touched[name] && !!errors[name]}
            helperText={touched[name] && errors[name]} */
              fullWidth
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCopy()}
          >
            {" "}
            Kopioi tuote
          </Button>
          <Box>
            <Typography variant="body2" style={{ marginBottom: 25 }}>
              <span style={{ fontWeight: 650 }}>HUOM! </span>
              Jos teet muutoksia tuotteen ominaisuuksiin tai tietoihin, ne
              tallentuvat vain kopioon, eivät alkuperäiseen tuotteeseen.
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from "react";
import { API, graphqlOperation, Auth, Storage } from "aws-amplify";
import { updateCategory, deleteCategory } from "../../graphql/mutations";
import { useNotification } from "./../Notification";
import { Formik, useFormikContext } from "formik";
import captureError from "../../utils/capture-error";
import PricingSelection from "../PricingSelection";
import AdditionalServicePricing from "../AdditionalServicePricing";
import { mapMaybe } from "../../utils/array-utilities";
import {
  createAdditionalServicePricing,
  updateAdditionalServicePricing,
  deleteAdditionalServicePricing,
} from "../../graphql/mutations";
import { typeOfProducts } from "../../shared/ProductClassification/type-of-products";
import { CloudUpload, Delete } from "@mui/icons-material";
import { vehicleByCategory } from "../../graphql/queries";
import listAll from "../../utils/list-all";
import InfoIcon from "@mui/icons-material/Info";
import { v4 } from "uuid";
import DialogComponent from "../DialogComponent";
import { Box } from "@mui/system";
import { useFetchAdditionalServices } from "../../services/fetchAdditionalServices";

import { AppContext } from "../../AppContext";
import { useFetchCompanies } from "../../services/fetchCompanies";

const DEFAULT_COLOR = "#f1faff";
const INITIAL_FORM_DATA = {
  name: "",
  orderNumber: null,
  typeId: null,
  minuteBuffer: null,
  reservationBlockFromNow: null,
  backgroundColor: DEFAULT_COLOR,
  categoryPricingId: "",
};

const validate = (values) => {
  const errors = {};

  if (!values?.name) {
    errors.name = "Täytä tämä kenttä";
  }
  return errors;
};

const ProductTypeSelection = ({ label, required, typeId, name, disabled }) => {
  const { touched, values, errors, handleBlur, handleChange } =
    useFormikContext();
  return (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
      error={touched[name] && !!errors[name]}
    >
      <InputLabel id="productTypeLabel">{label}</InputLabel>
      <Select
        value={values[name] || ""}
        labelId="productTypeLabel"
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        label={label}
        disabled={disabled}
        data-cy="categoryEditDialogCategorySelect"
      >
        {typeOfProducts.map((type) => {
          return (
            <MenuItem
              key={type.id}
              value={type.id}
              data-cy="categoryEditDialogCategoryOption"
            >
              {type.name}
            </MenuItem>
          );
        })}
      </Select>
      {touched[name] && errors[name] && (
        <FormHelperText>{errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default function CategoriesEditDialog({
  onClose,
  category,
  company,
  showPricings,
}) {
  const { user, group, business } = useContext(AppContext);

  const { companies } = useFetchCompanies(user?.group, business?.id);

  const { additionalServices } = useFetchAdditionalServices();

  const isCategoryEmpty = useMemo(() => {
    if (companies) {
      for (let c of companies) {
        const checkCategory = c?.categories.items.find(
          (cat) => cat.id === category?.id
        );

        if (checkCategory?.vehicles?.items.length > 0) {
          return false;
        }
      }
      return true;
    }
    return true;
  }, [companies, category?.id]);

  const disableFields = user?.role === "BUSINESS_ADMIN";
  const fileInputRef = useRef();
  const [image, setImage] = useState(category?.image);
  const [signedUrl, setSignedUrl] = useState(null);
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const fetchAllProductsInCategory = useCallback(async () => {
    try {
      const result = await listAll(
        graphqlOperation(vehicleByCategory, {
          vehicleCategoryId: category.id,
        }),
        "vehicleByCategory"
      );

      setAllProducts(result);
    } catch (e) {
      captureError("Get allProducts failed", "GET_ALLPRODUCTS_FAILED", e);
    }
  }, [category?.id]);

  const [allProducts, setAllProducts] = useState(() =>
    fetchAllProductsInCategory()
  );

  const getSignedUrl = async (value) => {
    if (!value) {
      return;
    }

    const [identityId, key] = value.split("/");
    const url = key
      ? await Storage.get(key, { identityId, level: "protected" })
      : null;
    setSignedUrl(url);
  };

  const onSubmit = async (values) => {
    setSubmitting(true);

    const {
      name,
      minuteBuffer,
      reservationBlockFromNow,
      orderNumber,
      backgroundColor,
      categoryPricingId,
      typeId,
    } = values;

    let categoryId;
    try {
      const response = await API.graphql(
        graphqlOperation(updateCategory, {
          input: {
            id: category.id,
            typeId: typeId,
            group: group ?? undefined,
            organizationId: user?.organizationId ?? undefined,
            name: name,
            image: image,
            orderNumber: orderNumber || null,
            minuteBuffer: minuteBuffer || null,
            reservationBlockFromNow: reservationBlockFromNow || null,
            backgroundColor: backgroundColor || DEFAULT_COLOR,
            categoryPricingId: categoryPricingId || null,
          },
        })
      );
      const updatedCategory = response.data.updateCategory;
      categoryId = updatedCategory.id;
      const additionalServiceFields = mapMaybe(additionalServices, (k) => {
        const fieldValue = values[k.id];
        if (fieldValue) {
          return {
            group: group ?? undefined,
            organizationId: user?.organizationId ?? undefined,
            key: k.id,
            categoryId: categoryId,
            pricingId: fieldValue,
          };
        }
      });

      const oldAdditionalServices = category?.additionalServices?.items ?? [];

      const findOldField = (newFieldValue) =>
        oldAdditionalServices.find(
          (oldFieldValue) => newFieldValue.key === oldFieldValue.key
        );

      const createPromises = additionalServiceFields
        .filter((newFieldValue) => findOldField(newFieldValue) == null)
        .map(async (newFieldValue) => {
          await API.graphql(
            graphqlOperation(createAdditionalServicePricing, {
              input: newFieldValue,
            })
          );
        });

      const updatePromises = additionalServiceFields.map(
        async (newFieldValue) => {
          const oldFieldValue = findOldField(newFieldValue);
          if (
            oldFieldValue != null &&
            oldFieldValue.pricingId !== newFieldValue.pricingId
          ) {
            await API.graphql(
              graphqlOperation(updateAdditionalServicePricing, {
                input: {
                  id: oldFieldValue.id,
                  pricingId: newFieldValue.pricingId,
                },
              })
            );
          }
        }
      );

      const deletePromises = oldAdditionalServices
        .filter((oldFieldValue) => !values[oldFieldValue.key])
        .map(async (oldFieldValue) => {
          await API.graphql(
            graphqlOperation(deleteAdditionalServicePricing, {
              input: { id: oldFieldValue.id },
            })
          );
        });

      Promise.all([...createPromises, ...updatePromises, ...deletePromises]);
      onClose();
      return;
    } catch (e) {
      captureError("Upsert Category failed", "UPSERT_CATEGORY_FAILED", e);
      console.log("🚀 ~ file: CategoriesEditDialog.jsx:369 ~ onSubmit ~ e:", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const [categoryImage, setCategoryImage] = useState(
    category.image ? "userImage" : "productTypeImage"
  );

  useEffect(() => {
    getSignedUrl(image);
  }, [image]);

  useEffect(() => {
    if (category?.image) {
      getSignedUrl(category.image);
    }
  }, [category]);

  // if user changes from own provided image back to productType image, user image needs to be removed.
  const [showImageProviderInfo, setShowImageProviderInfo] = useState(false);

  const handleCategoryImageChange = (provider) => {
    if (
      categoryImage === "userImage" &&
      category.image &&
      provider === "productTypeImage"
    ) {
      setShowImageProviderInfo(true);
    } else {
      setCategoryImage(provider);
    }
  };
  const handleRemoveUserImage = () => {
    setImage(null);
    setSignedUrl(null);
    setCategoryImage("productTypeImage");
    setShowImageProviderInfo(false);
  };

  const initializeFormikData = () => {
    if (category?.id) {
      const selectedAdditionalServicePricings = Object.fromEntries(
        (category?.additionalServices?.items ?? []).map((as) => [
          as.key,
          as.pricingId,
        ])
      );
      return {
        ...INITIAL_FORM_DATA,
        ...category,
        ...selectedAdditionalServicePricings,
        categoryPricingId: category.pricing?.id,
      };
    } else {
      return INITIAL_FORM_DATA;
    }
  };

  return (
    <Formik
      validate={(values) => validate(values)}
      onSubmit={onSubmit}
      noValidate
      initialValues={initializeFormikData()}
    >
      <DialogForm
        isCategoryEmpty={isCategoryEmpty}
        showPricings={showPricings}
        company={company}
        additionalServices={additionalServices}
        setShowProducts={setShowProducts}
        showProducts={showProducts}
        allProducts={allProducts}
        showConfirmation={showConfirmation}
        setShowImageProviderInfo={setShowImageProviderInfo}
        showImageProviderInfo={showImageProviderInfo}
        setImage={setImage}
        notification={notification}
        setShowConfirmation={setShowConfirmation}
        disableFields={disableFields}
        onClose={onClose}
        submitting={submitting}
        category={category}
        categoryImage={categoryImage}
        handleCategoryImageChange={handleCategoryImageChange}
        fileInputRef={fileInputRef}
        handleRemoveUserImage={handleRemoveUserImage}
        signedUrl={signedUrl}
      />
    </Formik>
  );
}

function DialogForm(props) {
  const {
    isCategoryEmpty,
    showPricings,
    company,
    additionalServices,
    setShowProducts,
    showProducts,
    allProducts,
    showConfirmation,
    setShowImageProviderInfo,
    showImageProviderInfo,
    setImage,
    notification,
    setShowConfirmation,
    disableFields,
    onClose,
    submitting,
    category,
    categoryImage,
    handleCategoryImageChange,
    fileInputRef,
    handleRemoveUserImage,
    signedUrl,
  } = props;
  const { user, companies, setEditVehicleData } = useContext(AppContext);
  const { submitForm, values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();
  const findCompany = (id) => {
    if (!companies) return null;
    return companies.find((c) => c.id === id);
  };

  const productType = useMemo(
    () => typeOfProducts.find((type) => type.id === values?.typeId),
    [values?.typeId]
  );

  const onRemove = async () => {
    await API.graphql(
      graphqlOperation(deleteCategory, {
        input: {
          id: values?.id,
        },
      })
    );
    setShowConfirmation(false);
    onClose(true);
  };

  const onChange = async (e) => {
    setImage(null);
    const file = e.target.files[0];
    const type = file.type;
    const ext = type === "image/jpeg" ? "jpg" : "png";
    try {
      const result = await Storage.put(v4() + "." + ext, file, {
        contentType: type,
        level: "protected",
      });

      const creds = await Auth.currentCredentials();
      const imageValue = creds.identityId + "/" + result.key;
      setImage(imageValue);
    } catch (e) {
      captureError("Upload image failed", "UPLOAD_IMAGE_FAILED", e);
      console.log(e);
      notification.show("Kuvan lataus epäonnistui");
    }
    e.target.value = null;
  };

  const debounceColorChange = useCallback(() => {
    // Chrome click&drag style colorpicker's value can change often and slow things down, hence the debounce
    function debounce(func, timeout = 300) {
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    }
    const setBackgroundColor = (nextValue) => {
      setFieldValue("backgroundColor", nextValue);
    };

    return debounce((nextValue) => setBackgroundColor(nextValue), 50);
  }, [setFieldValue]);
  const onInputChangeHandler = (e) => {
    const nextValue = e.target.value;
    debounceColorChange(nextValue);
  };

  return (
    <>
      <DialogComponent
        open={true}
        dialogDelete={
          isCategoryEmpty && !disableFields
            ? () => setShowConfirmation(true)
            : null
        }
        dialogAction={disableFields ? null : submitForm}
        dialogActionText={"Tallenna"}
        dialogClose={() => onClose()}
        maxWidth={"sm"}
        dialogTitle={"Muokkaa kategoriaa: " + category.name}
        dialogActionSubmitting={submitting}
      >
        <Grid container item spacing={2} xs={12} alignItems="center">
          <Grid
            container
            item
            xs={12}
            direction="row"
            justifyContent="space-between"
          ></Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-evenly"
          >
            {productType?.image && (
              <Grid
                item
                container
                xs={5}
                alignItems="center"
                direction="column"
                style={{
                  borderStyle: "solid",
                  borderWidth: categoryImage === "productTypeImage" ? 2 : 1,
                  borderColor:
                    categoryImage === "productTypeImage"
                      ? "limegreen"
                      : "lightgrey",
                  borderRadius: 7,
                  padding: 10,
                  cursor: "pointer",
                }}
                onClick={
                  disableFields
                    ? null
                    : () => handleCategoryImageChange("productTypeImage")
                }
              >
                <Typography style={{ fontSize: 14 }}>
                  Käytä tuoteryhmän kuvaa
                </Typography>
                <div
                  style={{
                    display: "flex",
                    marginTop: 10,
                    borderRadius: 7,
                    justifyContent: "center",
                    alignItems: "center",
                    height: 85,
                    width: 85,
                    backgroundColor: values?.backgroundColor || "white",
                  }}
                >
                  {productType && (
                    <img
                      src={productType.image}
                      style={{ width: 125 }}
                      alt="product-type-img"
                    />
                  )}
                </div>
              </Grid>
            )}
            <Grid
              item
              container
              xs={5}
              alignItems="center"
              direction="column"
              style={{
                borderStyle: "solid",
                borderWidth: categoryImage === "userImage" ? 2 : 1,
                borderColor:
                  categoryImage === "userImage" ? "limegreen" : "lightgrey",
                borderRadius: 7,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={
                disableFields
                  ? null
                  : () => handleCategoryImageChange("userImage")
              }
            >
              {categoryImage !== "userImage" && (
                <Typography style={{ fontSize: 14 }}>
                  Käytä omaa kuvaa
                </Typography>
              )}
              {categoryImage === "userImage" && (
                <Grid container direction="row" justifyContent="space-evenly">
                  <IconButton
                    component="label"
                    style={{ padding: 0 }}
                    size="large"
                  >
                    <CloudUpload
                      style={{ fontSize: 20, color: "dodgerblue" }}
                    />
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(evt) => onChange(evt)}
                      ref={fileInputRef}
                    />
                  </IconButton>
                  {category.image && (
                    <IconButton
                      onClick={() => handleRemoveUserImage}
                      style={{ padding: 0 }}
                      size="large"
                    >
                      <Delete style={{ fontSize: 20, color: "darkgrey" }} />
                    </IconButton>
                  )}
                </Grid>
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: 10,
                  height: 85,
                  width: 85,
                  backgroundColor: values?.backgroundColor || "white",
                  justifyContent: "center",
                }}
              >
                {signedUrl && (
                  <img
                    alt="category-img"
                    src={signedUrl}
                    style={{ height: 85 }}
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="colorPicker">Taustaväri: </label>
            <input
              id="colorPicker"
              type="color"
              style={{ marginLeft: ".4rem" }}
              value={values?.backgroundColor || DEFAULT_COLOR}
              disabled={disableFields}
              onChange={(e) => onInputChangeHandler(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <ProductTypeSelection
              label="Tuoteryhmä"
              typeId={category?.typeId}
              name={"typeId"}
              disabled={disableFields}
            />
          </Grid>
          <Grid item xs={12} md={7} data-cy="editCategoryDialogNameField">
            <TextField
              name="name"
              label="Nimi"
              value={values?.name ?? ""}
              required
              autoFocus
              disabled={disableFields}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            data-cy="editCategoryDialogOrderNumberField"
          >
            <TextField
              value={values?.orderNumber || ""}
              name="orderNumber"
              label="Järjestysnumero"
              type="number"
              disabled={disableFields}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} data-cy="editCategoryDialogPricingField">
            <PricingSelection
              showPricings={showPricings}
              name={"categoryPricingId"}
              label="Hinnasto"
              disabled={disableFields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values?.reservationBlockFromNow || ""}
              name="reservationBlockFromNow"
              label="Varoaika nykyhetkestä (min)"
              type="number"
              disabled={disableFields}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} data-cy="editCategoryDialogMinuteField">
            <TextField
              value={values?.minuteBuffer || ""}
              name="minuteBuffer"
              label="Varoaika ennen ja jälkeen (min)"
              type="number"
              disabled={disableFields}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <AdditionalServicePricing
              additionalServices={additionalServices}
              showPricings={showPricings}
              company={company}
              enabledServices={category?.additionalServices?.items}
              disabled={disableFields}
              //label={service.description}
            />
          </Grid>
        </Grid>

        {!isCategoryEmpty && !user?.role === "ORGANZATION_ADMIN" && (
          <Grid
            item
            xs={12}
            container
            style={{ marginTop: 20 }}
            direction="column"
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{ marginBottom: "10px" }}
            >
              <InfoIcon color="info" />
              <Typography variant="caption" sx={{ marginLeft: "10px" }}>
                Voit poistaa kategorian vain jos se ei sisällä tuotteita
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowProducts(true)}
            >
              Tuotteet kategoriassa
            </Button>
            {showProducts && (
              <Grid container style={{ marginTop: 20 }}>
                {allProducts.map((product) => {
                  return (
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      key={product.id}
                      style={{
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "lightgrey",
                        backgroundColor: "#e9e9ed",
                        padding: 10,
                        margin: 5,
                        borderRadius: 7,
                        cursor: "pointer",
                      }}
                      onClick={() => setEditVehicleData(product)}
                    >
                      <Typography>{product.name}</Typography>
                      <Typography>
                        {findCompany(product.companyId).name}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
        )}
      </DialogComponent>
      <DialogComponent
        open={showConfirmation}
        dialogClose={() => setShowConfirmation(false)}
        dialogAction={() => onRemove()}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
        testName="deleteCategoryDialog"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body1">
            <b>Haluatko varmasti poistaa kategorian?</b>
          </Typography>
        </Box>
      </DialogComponent>

      <DialogComponent
        open={showImageProviderInfo}
        dialogClose={() => setShowImageProviderInfo(false)}
        dialogAction={() => handleRemoveUserImage()}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
      >
        <Typography variant="body1">
          Jos haluat käyttää tuoteryhmän kuvaa oman kuvan sijasta, ladattu kuva
          on ensin poistettava.
        </Typography>
      </DialogComponent>
    </>
  );
}

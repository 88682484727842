import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Alert,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  createVoucher,
  updateVoucher,
  deleteVoucher,
} from "../graphql/mutations";
import { useNotification } from "./Notification";
import { useFormik } from "formik";
import captureError from "../utils/capture-error";
import { useConfirmation } from "./Confirmation";
import DialogComponent from "./DialogComponent";
import { AppContext } from "../AppContext";
import { localeText } from "../utils/common";
import { format } from "date-fns";

const INITIAL_FORM_DATA = {
  name: "",
  code: "",
  amount: "",
  validForNow: "",
  startDate: null,
  endDate: null,
};

const validate = (values, vouchers) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Täytä tämä kenttä";
  }

  if (!values.code) {
    errors.code = "Täytä tämä kenttä";
  } else if (
    vouchers.find(
      (voucher) =>
        voucher.code.toLowerCase() === values.code.toLowerCase() &&
        values.id !== voucher.id
    )
  ) {
    errors.code = "Koodi on jo käytössä";
  }

  if (!values.amount) {
    errors.amount = "Täytä tämä kenttä";
  } else if (isNaN(parseInt(values.amount))) {
    errors.amount = "Anna alennusprosentti numeroina";
  }

  return errors;
};

const FormikTextField = ({ formik, name, disabled, ...props }) => {
  return (
    <TextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      name={name}
      variant="outlined"
      error={formik.touched[name] && !!formik.errors[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      fullWidth
      disabled={disabled}
      {...props}
    />
  );
};

export default function VoucherEditDialog({
  onClose,
  voucher,
  vouchers,
  disabled,
}) {
  const { user } = React.useContext(AppContext);
  const notification = useNotification();
  const [submitting, setSubmitting] = useState(false);
  const [validForNow, setValidForNow] = useState(false);
  const [dateError, setDateError] = useState("");

  const onSubmit = async () => {
    if (!validForNow && (!formik.values.startDate || !formik.values.endDate)) {
      setDateError("Valitse joko toistaiseksi voimassa oleva tai päivämäärät.");
      return;
    } else if (
      new Date(formik.values.startDate) > new Date(formik.values.endDate)
    ) {
      setDateError("Aloitusaika ei voi olla pienempi kuin päättymisaika.");
      return;
    }

    const data = validForNow
      ? { startDate: null, endDate: null }
      : formik.values;
    const { startDate, endDate } = data;

    // voucher date is date string in the format YYYY-MM-DD
    const awsStartDate = startDate ? format(startDate, "yyyy-MM-dd") : null;
    const awsEndDate = endDate ? format(endDate, "yyyy-MM-dd") : null;

    setDateError("");
    setSubmitting(true);

    const { name, code, amount } = formik.values;

    try {
      if (voucher.id) {
        await API.graphql(
          graphqlOperation(updateVoucher, {
            input: {
              group: user.group ?? undefined,
              organizationId: user.organizationId ?? undefined,
              name,
              code,
              startDate: awsStartDate,
              endDate: awsEndDate,
              amount: parseInt(amount),
              id: voucher.id,
            },
          })
        );
      } else {
        await API.graphql(
          graphqlOperation(createVoucher, {
            input: {
              group: user.group ?? undefined,
              organizationId: user.organizationId ?? undefined,
              name,
              code,
              amount,
              startDate: awsStartDate,
              endDate: awsEndDate,
            },
          })
        );
      }

      onClose(true);
      return;
    } catch (e) {
      captureError("Upsert voucher failed", "UPSERT_VOUCHER_FAILED", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validate: (values) => validate(values, vouchers),
    onSubmit,
  });

  useEffect(() => {
    if (voucher) {
      formik.setValues({
        ...INITIAL_FORM_DATA,
        ...voucher,
      });

      voucher.startDate && voucher.endDate
        ? setValidForNow(false)
        : setValidForNow(true);
    } else {
      formik.setValues(INITIAL_FORM_DATA);
    }
    formik.setErrors({});
    formik.setTouched({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher]);

  const [showDeleteConfirmation, DeleteConfirmationDialog] = useConfirmation(
    async () => {
      await API.graphql(
        graphqlOperation(deleteVoucher, {
          input: {
            id: voucher.id,
          },
        })
      );

      //   fetchPricingExceptions();
      onClose(true);
    }
  );

  return (
    <>
      <DialogComponent
        open
        dialogClose={() => onClose()}
        maxWidth={"xs"}
        dialogDelete={
          voucher?.id && !disabled ? () => showDeleteConfirmation() : null
        }
        dialogAction={disabled ? null : formik.submitForm}
        dialogActionText={"Tallenna"}
        dialogTitle={"Alekoodin tiedot"}
        dialogActionSubmitting={submitting}
      >
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid
            item
            container
            direction="column"
            spacing={4}
            xs={12}
            data-cy="voucherEditDialog"
          >
            <Grid item>
              <Typography variant="h6">Alekoodi:</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                name="name"
                label="Nimi"
                required
                disabled={disabled}
                autoFocus
                data-cy="voucherNameField"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                name="code"
                label="Koodi"
                required
                disabled={disabled}
                onChange={(evt) => {
                  formik.setFieldValue(
                    evt.target.name,
                    evt.target.value.toUpperCase()
                  );
                }}
                data-cy="voucherCodeField"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                formik={formik}
                name="amount"
                label="Määrä"
                disabled={disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                required
                data-cy="voucherAmountField"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="validForNow"
                    color="primary"
                    disabled={disabled}
                    checked={validForNow}
                    onClick={() => setValidForNow(!validForNow)}
                  />
                }
                label={<>Toistaiseksi voimassa oleva</>}
              />
            </Grid>
            {!validForNow && (
              <>
                {dateError && <Alert severity="error">{dateError}</Alert>}
                <Grid item xs={12}>
                  <DatePicker
                    label="Alkaa"
                    inputVariant="outlined"
                    name={"startDate"}
                    disabled={disabled}
                    value={formik.values.startDate}
                    onChange={(date) => {
                      formik.setFieldValue("startDate", date);
                    }}
                    onBlur={formik.handleBlur}
                    localeText={localeText}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    localeText={localeText}
                    label="Päättyy"
                    inputVariant="outlined"
                    name={"endDate"}
                    disabled={disabled}
                    value={formik.values.endDate}
                    onChange={(date) => {
                      formik.setFieldValue("endDate", date);
                    }}
                    onBlur={formik.handleBlur}
                    required
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogComponent>
      <DeleteConfirmationDialog
        message="Haluatko varmasti poistaa alekoodin ?"
        confirmLabel="Poista"
        testName="deleteVoucherDialog"
      />
    </>
  );
}

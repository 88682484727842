import React from "react";

export default function RegistrationPlate({ style, children }) {
  return children?.length < 2 ? null : (
    <span
      style={{
        ...style,
        fontFamily: "monospace",
        borderTop: "2px solid",
        overflow: "hidden",
        display: "inline-flex",
        alignItems: "center",
      }}
    >
      <span style={{ fontWeight: "bold", padding: 1 }}>{children}</span>
    </span>
  );
}

import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useField } from "formik";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../../../../../../atoms/reservationDataAtom";

export default function DisableConfirmationEmail() {
  const reservationData = useAtomValue(reservationDataAtom);
  const [, meta, helpers] = useField("disableConfirmationEmail");
  const { value } = meta;
  const { handleBlur, setValue } = helpers;
  if (!reservationData?.id)
    return (
      <FormControlLabel
        control={
          <Checkbox
            name="disableConfirmationEmail"
            checked={Boolean(value)}
            color="primary"
            onChange={(e) => setValue(e.target.checked)}
            onBlur={handleBlur}
          />
        }
        label="Älä lähetä varausvahvistusta"
      />
    );
}

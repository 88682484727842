import { Skeleton, TableCell } from "@mui/material";
import { useFetchPricingsOrganization } from "../../../../../services/fetchPricings";
import { EPricingBy } from "../../../utils/tabOptions";
import { centsToLocalString } from "../../../../../shared/money";
import useGetDynamicPricingPercentage from "../../../hooks/useGetDynamicPricingPercentage";

export default function HourlyPricingRate(props) {
  const { pricing, activePricing, selectedCompany } = props;
  const cellWidth = "150px";
  const { pricingsLoading } = useFetchPricingsOrganization("pricingListDialog");

  const { dynamicPricing } = useGetDynamicPricingPercentage(
    selectedCompany?.id,
    pricing?.id
  );

  if (pricingsLoading) {
    return (
      <TableCell sx={{ width: cellWidth }}>
        <Skeleton sx={{ height: "50px" }} />
      </TableCell>
    );
  }

  if (pricing) {
    const pricingHour = pricing.elements.find(
      (el) => el.duration === "h0"
    )?.price;
    if (activePricing === EPricingBy.ORGANIZATION) {
      const price = dynamicPricing?.percentage
        ? pricingHour + (pricingHour * dynamicPricing.percentage) / 100
        : pricingHour;
      return (
        <TableCell width={cellWidth} align="right">
          {centsToLocalString(price)} €
        </TableCell>
      );
    }

    return (
      <TableCell width={cellWidth} align="right">
        {centsToLocalString(pricingHour)} €
      </TableCell>
    );
  } else {
    return <TableCell />;
  }
}

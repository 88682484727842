import { Grid, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

export default function HanselData() {
  const { values } = useFormikContext();
  return (
    <>
      {values.type === "HANSEL" && (
        <Stack
          sx={{
            justifyContent: "space-between",
            marginLeft: "5%",
            maxWidth: "805px",
            marginTop: "10px",
          }}
          flex={1}
          direction={"row"}
        >
          <Typography sx={{ fontWeight: "bolder", marginRight: "5px" }}>
            Valittu hansel luokka:
          </Typography>
          <Typography>{values?.initialClassInfo?.hanselClass}</Typography>
        </Stack>
      )}
    </>
  );
}

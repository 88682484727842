import { useContext } from "react";
import { reservationFields } from "./fields";
import { AppContext } from "../../../AppContext";

const requiredFields_GENERIC = [reservationFields.email];

const requiredFields_INSURANCE = [
  reservationFields.email,
  "providerCompany",
  "customerCarRegistrationPlate",
  reservationFields.phone,
];
const requiredFields_CORPORATE = [
  reservationFields.email,
  reservationFields.startLocation,
  reservationFields.returnLocation,
  reservationFields.startTime,
  reservationFields.returnTime,
  reservationFields.companyBillingRef,
];

const requiredFieldsByUserRole = {
  GENERIC: requiredFields_GENERIC,
  INSURANCE: requiredFields_INSURANCE,
  CORPORATE: requiredFields_CORPORATE,
};

export const useGetRequiredFields = () => {
  const { USER_ADMIN_TYPE, USER_ADMIN_CORPORATE } = useContext(AppContext);
  const requiredFields = (values) => {
    const required = [...requiredFieldsByUserRole[USER_ADMIN_TYPE]];

    if (
      values?.status !== "EXTERNAL_PENDING_DEALER" &&
      USER_ADMIN_TYPE !== "INSURANCE"
    ) {
      required.push("mandatoryProduct");
      required.push(reservationFields.startTime);
      required.push(reservationFields.returnTime);
      if (USER_ADMIN_TYPE === "GENERIC") {
        required.push(reservationFields.endTime);
      }
    }
    if (values?.isCompany && !required.includes(requiredCompanyFields)) {
      required.push(requiredCompanyFields);
    }
    if (values?.isBroker && !required.includes(requiredBrokerFields)) {
      required.push(requiredBrokerFields);
    }

    // required.push("externalUser");
    if (
      values?.status === "EXTERNAL_PENDING_DEALER" &&
      !!USER_ADMIN_CORPORATE
    ) {
      if (!values.customerCompanyId) {
        required.push(reservationFields.customerCompanyId);
      }

      required.push("mandatoryProduct");
    }

    if (!!values?.isCompany && !values?.companyBusinessId) {
      required.push("companyBusinessId");
    }

    return required;
  };
  return { requiredFields };
};

const requiredCompanyFields = reservationFields.companyName;

const requiredBrokerFields = reservationFields.customerBrokerId;

import React from "react";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { FormInputField } from "../../../../../../components/ReservationForm/utils/FormInputField";
import PhoneNumberField from "../../../../../../components/PhoneNumberField";
import { useGetRequiredFields } from "../../../../../../formik/requiredFields";

export default function ShowDifferentDriverFields() {
  const selectedLanguage = "FIN";

  const { values } = useFormikContext();
  const { requiredFields } = useGetRequiredFields();
  if (values?.differentDriver)
    return (
      <Grid container item flex={1} spacing={1} xs={12}>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={EFormFields.driverName}
            label={FieldLabel[selectedLanguage].driverName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputField
            name={EFormFields.driverSSN}
            label={FieldLabel[selectedLanguage].driverSSN}
            password={true}
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={8}>
            <FormInputField
              name={EFormFields.driverAddress}
              label={FieldLabel[selectedLanguage].driverAddress}
            />
          </Grid>
          <Grid item xs={4}>
            <FormInputField
              name={EFormFields.driverPostalCode}
              label={FieldLabel[selectedLanguage].driverPostalCode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneNumberField
            fieldName={"driverPhone"}
            required={requiredFields(values).includes("driverPhone")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormInputField
            name={EFormFields.driverEmail}
            label={FieldLabel[selectedLanguage].driverEmail}
          />
        </Grid>
      </Grid>
    );
}

const EFormFields = {
  driverName: "driverName",
  driverAddress: "driverAddress",
  driverPostalCode: "driverPostalCode",
  driverPhone: "driverPhone",
  driverEmail: "driverEmail",
  driverSSN: "driverSSN",
};

const formFieldsFIN = {
  [EFormFields.driverName]: "Kuljettajan nimi",
  [EFormFields.driverAddress]: "Kuljettajan osoite",
  [EFormFields.driverPhone]: "Kuljettajan puhelinnumero",
  [EFormFields.driverEmail]: "Kuljettajan sähköposti",
  [EFormFields.driverSSN]: "Kuljettajan henkilötunnus",
  [EFormFields.driverPostalCode]: "Kuljettajan postinumero",
};

const FieldLabel = {
  FIN: formFieldsFIN,
};

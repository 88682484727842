import { Form, Formik } from "formik";

import { handleReservationFailedSuccessfully } from "../../../formik/utils";

import { useGetInitialFormData } from "../../../formik/hooks/useGetInitialFormData";
import { useHandleSubmitForm } from "../../../hooks/useSubmitFormBusinessUsers";
import { useMutateLocalReservations } from "../../../hooks/useMutateLocalReservations";
import { useNotification } from "../../../../Notification";
import { useUpdateClientDirectory } from "../../../hooks/useUpdateClientDirectory";
import { useValidateFormikFields } from "../../../formik/validate";
import { reservationDataAtom } from "../../../../../atoms/reservationDataAtom";
import { useAtomValue } from "jotai";

export function ReservationForm(props) {
  const { children } = props;
  const reservationData = useAtomValue(reservationDataAtom);

  const intialFormData = useGetInitialFormData(reservationData);
  const { submit } = useHandleSubmitForm();
  const { mutateLocalReservations } = useMutateLocalReservations();
  const notification = useNotification();
  const { validate } = useValidateFormikFields();
  const { checkClientEntry } = useUpdateClientDirectory();
  return (
    <Formik
      initialValues={intialFormData}
      onSubmit={async (values, formikHelpers) =>
        //TODO: add companyId based on start location

        {
          if (Object.values(formikHelpers.validateForm()).length === 0) {
            try {
              const customerCompanyId = await checkClientEntry(values);
              const valuesWithCustomerCompanyId = {
                ...values,
                customerCompanyId: customerCompanyId,
              };
              const response = await submit(valuesWithCustomerCompanyId);

              if (response?.error) {
                notification.show("Varauksen tallennus epäonnistui", "error");
              }

              if (response?.success) {
                const getNotification = handleReservationFailedSuccessfully(
                  response?.data?.code,
                  response?.data?.emailConfirmationSuccess,
                  response?.data?.reservation?.email
                );
                notification.show(
                  getNotification.message,
                  getNotification.variant
                );
                await mutateLocalReservations(response?.data?.reservation);
                return response;
              }
            } catch (error) {
              console.warn("Error: Formik onSubmit failed", error);
              notification.show("Varauksen tallennus epäonnistui.", "error");
            }
          } else {
            const errors = Object.values(validate).join(", ");
            notification.show(
              `Täytä kaikki pakolliset kentät: ${errors}`,
              "error"
            );
          }
        }
      }
      validate={(values) => validate(values)}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {children}
      </Form>
    </Formik>
  );
}

import {
  ECompanyFields,
  EContractAgreementFields,
  EContractCommon,
  EContractSummary,
  ECustomerFields,
  EPaymentFields,
  EVehicleFields,
} from "../../../enums/contract-fields";

export const contractFieldCustomerENG = {
  TITLE: "Customer Information",
  [ECustomerFields.ADDRESS]: "Customer Address",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Driver's License Class",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Driver's License Number",
  [ECustomerFields.EMAIL]: "Customer Email",
  [ECustomerFields.NAME]: "Customer Name",
  [ECustomerFields.PHONE]: "Customer Phone",
  [ECustomerFields.SSN]: "Customer SSN",
  [ECustomerFields.CUSTOMER_NOTES]: "Customer Notes",
  [ECustomerFields.BILLING_REFERENCE]: "Billing Reference",
  ADDITIONAL_INFORMATION: "Additional Information",
};
export const contractFieldDriverENG = {
  TITLE: "Driver Information",
  [ECustomerFields.ADDRESS]: "Driver Address",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Driver's License Class",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Driver's License Number",
  [ECustomerFields.EMAIL]: "Driver Email",
  [ECustomerFields.NAME]: "Driver Name",
  [ECustomerFields.PHONE]: "Driver Phone",
  [ECustomerFields.SSN]: "Driver SSN",
};
export const contractFieldAdditionalDriverENG = {
  TITLE: "Additional Driver Information",
  [ECustomerFields.ADDRESS]: "Driver Address",
  [ECustomerFields.DRIVERS_LICENSE_CLASS]: "Driver's License Class",
  [ECustomerFields.DRIVERS_LICENSE_ID]: "Driver's License Number",
  [ECustomerFields.EMAIL]: "Driver Email",
  [ECustomerFields.NAME]: "Additional Driver Name",
  [ECustomerFields.PHONE]: "Driver Phone",
  [ECustomerFields.SSN]: "Driver SSN",
};
export const contractFieldCompanyENG = {
  TITLE: "Company Information",
  [ECompanyFields.BILLING_ADDRESS]: "Billing Address",
  [ECompanyFields.BUSINESS_ID]: "Business ID",
  [ECompanyFields.CITY]: "City",
  [ECompanyFields.NAME]: "Company Name",
  [ECompanyFields.POSTAL_CODE]: "Postal Code",
};
export const contractFieldCommonENG = {
  [EContractCommon.CONTRACT_SUBTITLE]: "Rental Agreement",
  [EContractCommon.CONTRACT_TITLE]: "RENTAL AGREEMENT",
  [EContractCommon.RESERVATION_NUMBER]: "Reservation Number",
  [EContractCommon.RETURN_DATE]: "Agreed Return Date",
  [EContractCommon.RETURN_LOCATION]: "Return Location",
  [EContractCommon.START_DATE]: "Agreed Start Date",
  [EContractCommon.START_LOCATION]: "Pickup Location",
};
export const contractVehicleENG = {
  TITLE: "information",
  [EVehicleFields.ADDITIONAL_INFORMATION]: "Additional Information",
  [EVehicleFields.DEDUCTIBLE]: "Deductible",
  [EVehicleFields.DEDUCTIBLE_SIGNATORY]: "Deductible Signatory",
  [EVehicleFields.EXTRAINFO]: "Additional Information",
  [EVehicleFields.FUEL_ON_DELIVERY]: "Fuel on Delivery",
  [EVehicleFields.FUEL_ON_RETURN]: "Fuel on Return",
  [EVehicleFields.FILL_UP_FUEL_LABEL]:
    "Vehicle must be returned with a full tank of fuel.",
  [EVehicleFields.INCLUDED_MILEAGE]: "Included Mileage",
  [EVehicleFields.MILEAGE_DRIVEN]: "Mileage Driven",
  [EVehicleFields.MILEAGE_ON_DELIVERY]: "Mileage on Delivery",
  [EVehicleFields.MILEAGE_ON_RETURN]: "Mileage on Return",
  [EVehicleFields.MILEAGE_INCLUDED]: "Included Mileage",
  [EVehicleFields.NAME]: "Vehicle Make and Model",
  [EVehicleFields.POWERTYPE]: "Power Type",
  [EVehicleFields.REGISTRATION_NUMBER]: "Registration Number",
  [EVehicleFields.EXPORT_LICENSE_CLEARANCE]:
    "Valid Export License to country: ",
  [EVehicleFields.EXPORT_LICENSE_NO_CLEARANCE]: "No Export License",
  [EVehicleFields.EXPORT_LICENSE_NO_DETAILS]: "Not specified",
  [EVehicleFields.COLLISION_DAMAGE_WAIVER]: "COLLISION DAMAGE WAIVER",
  [EVehicleFields.THEFT_PROTECTION]: "THEFT PROTECTION",
  [EVehicleFields.VEHICLE_CONDITION]: "Condition before Rental",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digital Signature",
  [EVehicleFields.VEHICLE_DAMAGE_RECORDS]: "Damage Records:",
  [EVehicleFields.EXPORT_LICENSE_LABEL]: "Export License",
  [EVehicleFields.VEHICLE_DAMAGE_CREATED]: "Created:",
  [EVehicleFields.VEHICLE_OR_EQUIVALENT_LABEL]: "- or equivalent",
};
export const paymentStatusENG = {
  [EPaymentFields.PAYMENT_STATUS]: "Payment Status",
  [EPaymentFields.PAYMENT_METHOD]: "Payment Method",
  [EPaymentFields.PAYMENT_STATUS_COMPLETED]: "Payment Completed",
  [EPaymentFields.DIGITAL_SIGNATURE]: "Digital Signature",
};
export const contractAgreementENG = {
  [EContractAgreementFields.AGREEMENT]: "Rental Agreement",
  [EContractAgreementFields.AGREEMENT_CUSTOMER]: "Renter",
  [EContractAgreementFields.AGREEMENT_RENTAL_COMPANY]:
    "On behalf of the Rental Company.",
  [EContractAgreementFields.AGREEMENT_TERMS]:
    "We accept the rental terms and agree to comply with them.",
  [EContractAgreementFields.AGREEMENT_STRONG_AUTHENTICATION]:
    "The Renter has electronically signed the rental agreement, deductible receipt (collision and theft protection), and committed to comply with the rental terms by strong authentication.",
  [EContractAgreementFields.DIGITAL_SIGNATURE]: "Digital Signature",
  [EContractAgreementFields.POST_BILLING]: "Retroactive Billing",
  [EContractAgreementFields.POST_BILLING_SIGNATORY]:
    "Retroactive Billing Signatory",
  [EContractAgreementFields.POST_BILLING_TERMS]:
    "(fuel, additional rental costs, vehicle delivery or pickup, parking fines, traffic fines, deductible for damages)",
  [EContractAgreementFields.POST_BILLING_AUTHORIZED]:
    "I authorize the Rental Company to make any necessary retroactive charges from the above-mentioned payment card.",
  [EContractAgreementFields.CREDIT_CARD_NUMBER]: "Credit Card Number",
  [EContractAgreementFields.CREDIT_CARD_EXPIRATION]: "Expiration Date",
  [EContractAgreementFields.POST_BILLING_TITLE]: "Retroactive Billing",
  [EContractAgreementFields.AGREEMENT_TITLE]: "Rental Agreement",
  [EContractAgreementFields.PRODUCT_CONDITION_AGREEMENT]:
    "Product condition agreement",
};
export const contractSummaryENG = {
  [EContractSummary.TOTAL]: "Total",
  [EContractSummary.VAT_EXCLUDED]: "VAT Excluded",
  [EContractSummary.PRODUCT]: "Product",
  [EContractSummary.PRICE]: "Price",
  [EContractSummary.QUANTITY]: "Quantity",
  [EContractSummary.VAT]: "VAT",
  [EContractSummary.VAT_INCLUDED]: "(VAT included)",
  [EContractSummary.TAX]: "Tax",
};

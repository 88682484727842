import { Box, Tooltip, useTheme } from "@mui/material";
import { BsPatchExclamationFill } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Reservation, Vehicle } from "~/graphql/API";
import { FaFileImport } from "react-icons/fa";
import { format } from "date-fns";

type StatusProps = {
  reservation: Pick<
    Reservation,
    | "companyId"
    | "authStatus"
    | "signStatus"
    | "paymentStatus"
    | "customImportDate"
  >;
  vehicle?: Vehicle;
};

function Status({ reservation, vehicle }: StatusProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginY: "5px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {!!reservation.customImportDate && (
        <Tooltip
          title={
            "Tujaus: tuotu " +
            format(new Date(reservation.customImportDate), "dd.MM.yyyy")
          }
        >
          <Box
            sx={{
              margin: "5px",
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaFileImport
              style={{
                fontSize: "24px",
                color: "white",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {vehicle?.companyId !== reservation.companyId && (
        <Tooltip title="HUOM! varauksella on eri palautuspiste">
          <Box
            sx={{
              border: "2px solid #ffffff",
              borderRadius: "50%",
              margin: "5px",
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BsPatchExclamationFill
              style={{
                fontSize: "24px",
                color: "yellow",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {reservation.authStatus === "COMPLETED" && (
        <Tooltip title="Telia vahva tunnistus">
          <Box
            sx={{
              border: "2px solid #ffffff",
              borderRadius: "50%",
              margin: "5px",
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaFileSignature
              style={{
                color: "#ffffff",
                fontSize: "24px",
                marginLeft: "3px",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {reservation.signStatus === "COMPLETED" && (
        <Tooltip title="Visma Sign allekirjoitettu">
          <BorderColorRoundedIcon
            style={{ color: theme.palette.success.main }}
          />
        </Tooltip>
      )}

      {reservation.paymentStatus === "COMPLETED" && (
        <Tooltip title="Varaus maksettu">
          <Box
            sx={{
              border: "2px solid #ffffff",

              borderRadius: "50%",
              margin: "5px",
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MonetizationOnIcon
              style={{
                color: theme.palette.success.light,
                fontSize: "24px",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {(reservation.paymentStatus === "COMPLETED_DEPOSIT" ||
        reservation.paymentStatus === "PENDING_FULL") && (
        <Tooltip title="Varausmaksu maksettu">
          <Box
            sx={{
              border: "2px solid #ffffff",

              borderRadius: "50%",
              margin: "5px",
              padding: "7px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MonetizationOnIcon
              style={{
                color: "gold",
                fontSize: "24px",
              }}
            />
          </Box>
        </Tooltip>
      )}

      {reservation.paymentStatus === "PENDING_INVOICE" && (
        <Box
          sx={{
            border: "2px solid #ffffff",

            borderRadius: "50%",
            margin: "5px",
            padding: "7px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MonetizationOnIcon
            style={{
              color: "tomato",
              fontSize: "24px",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Status;

import React, { useEffect, useState } from "react";
import { TextField, Box, Typography, Skeleton } from "@mui/material";
import { getReview } from "../../../graphql/queries";

import { API, graphqlOperation } from "aws-amplify";

import { useTheme } from "@mui/material/styles";
import { useReservationContext } from "../ReservationContext";

export default function ReviewExplanation({
  review,
  setReview,
  setExplanation,
}) {
  const { reservationData } = useReservationContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchReview() {
      setLoading(true);
      try {
        const response = await API.graphql(
          graphqlOperation(getReview, {
            id: reservationData?.reviewId,
          })
        );
        const review = response.data.getReview;
        setExplanation(review?.explanation);

        if (review) setReview(review);
        setLoading(false);
      } catch (e) {
        console.error("error fetching review", e);
        setLoading(false);
        throw new Error("Failed to fetch review.");
      }
    }

    if (reservationData?.reviewId) fetchReview();
  }, [reservationData?.reviewId, setExplanation, setReview]);

  if (loading) return <Skeleton width={"80%"} height={300} />;

  if (!review) {
    return "Palautetta ei annettu";
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        margin: "1rem",
        border: "1px solid #ddd",
        borderRadius: "0.5rem",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        minWidth: "80%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Arvosana: {review.rating}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <TextField
          fullWidth
          label="Kommentti"
          multiline
          value={review.comment}
          disabled
          maxRows={5}
          sx={{
            backgroundColor: "#f8f8f8",
            borderRadius: "0.25rem",
            padding: "0.5rem",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        />
      </Box>
      <Explanation review={review} setReview={setReview} />
    </Box>
  );
}

function Explanation({ review, setReview }) {
  const theme = useTheme();
  const explanationIsEmpty = !review?.explanation?.trim();

  if (review.rating > 6) return null; // Explanation required for NPS under 7

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <TextField
        fullWidth
        label="Selite palautteelle"
        multiline
        maxRows={5}
        value={review?.explanation || ""}
        onChange={(event) =>
          setReview({ ...review, explanation: event.target.value })
        }
        color={explanationIsEmpty ? "warning" : ""}
        focused={explanationIsEmpty}
      />
      {explanationIsEmpty && (
        <Typography
          sx={{ color: theme.palette.warning.main, fontSize: "0.8rem" }}
        >
          Täytä kenttä
        </Typography>
      )}
    </Box>
  );
}

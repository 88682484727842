import {
  IconButton,
  Box,
  Button,
  Divider,
  ListItem,
  Chip,
  List,
} from "@mui/material";
import { DateRangePicker, DesktopDatePicker } from "@mui/x-date-pickers-pro";
import {
  add,
  sub,
  startOfMonth,
  startOfISOWeek,
  subMonths,
  addMonths,
  endOfMonth,
  endOfISOWeek,
  isValid,
  startOfDay,
  endOfDay,
  format,
  getISOWeek,
  addDays,
  subDays,
} from "date-fns";
import React, { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import { fi } from "date-fns/locale";
import useForkRef from "@mui/utils/useForkRef";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function IndexPageDatePicker({
  reservationsLoading,
  selectedView,
  changeDateByPicker,
  dateRange,
  daysVisible,
  iconSize,
}) {
  const { setEndDate, setStartDate, startDate, mobileViewPort } =
    useContext(AppContext);

  const handleChangeDate = (newValue) => {
    // handle substract date based on selected view

    if (newValue === "subDay") {
      setStartDate((prevState) => subDays(prevState, 1));
      setEndDate((prevState) => subDays(endOfDay(prevState), 1));
    }

    if (newValue === "addDay") {
      setStartDate((prevState) => addDays(prevState, 1));
      setEndDate((prevState) => addDays(endOfDay(prevState), 1));
    }

    if (newValue === "sub") {
      if (selectedView === "monthly") {
        const newDate = startOfMonth(subMonths(startDate, 1));
        setEndDate(endOfMonth(endOfDay(newDate)));
        setStartDate(startOfDay(newDate));
      }
      if (selectedView === "weekly") {
        const newDate = sub(startDate, { days: 6 });
        setEndDate(endOfISOWeek(endOfDay(newDate)));
        setStartDate(startOfDay(newDate));
      }
      if (selectedView === "daily") {
        const newDate = sub(startDate, { days: 1 });
        setEndDate(endOfDay(endOfDay(newDate)));
        setStartDate(startOfDay(newDate));
      }
    }

    // handle add date based on selected view
    if (newValue === "add") {
      if (selectedView === "monthly") {
        const newDate = startOfMonth(addMonths(startDate, 1));
        setEndDate(endOfMonth(endOfDay(newDate)));
        setStartDate(newDate);
      }
      if (selectedView === "weekly") {
        const newDate = add(startDate, { days: 6 });
        setEndDate(endOfISOWeek(endOfDay(newDate)));
        setStartDate(newDate);
      }
      if (selectedView === "daily") {
        const newDate = add(startDate, { days: 1 });

        setEndDate(endOfDay(newDate));
        setStartDate(startOfDay(newDate));
      }
    }

    if (isValid(newValue)) {
      if (selectedView === "daily") {
        setEndDate(endOfDay(newValue));
        setStartDate(startOfDay(newValue));
      }

      if (selectedView === "weekly") {
        setEndDate(endOfISOWeek(newValue));
        setStartDate(startOfISOWeek(newValue));
      }
      if (selectedView === "monthly") {
        setEndDate(endOfMonth(newValue));
        setStartDate(startOfMonth(newValue));
      }
    }
  };

  /*   if (selectedView === "reservationTable") {
    return (
      <DateRangePicker
        inputFormat="dd.MM.yyyy"
        value={dateRange}
        onChange={(newValue) => {
          changeDateByPicker(newValue);
        }}
        slotProps={{ fieldSeparator: { children: "" } }}
      />
    );
  }
  if (selectedView !== "reservationTable") */

  const dateValue = useMemo(() => {
    if (selectedView === "reservationTable") {
      return dateRange;
    } else {
      return startDate;
    }
  }, [selectedView, dateRange, startDate]);

  if (selectedView !== "reservationTable")
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton
          edge={mobileViewPort ? "end" : "start"}
          disabled={reservationsLoading}
          onClick={() => {
            handleChangeDate("sub");
          }}
          size={"small"}
        >
          <FastRewindIcon style={{ fontSize: 32 }} />
        </IconButton>

        {!mobileViewPort && (
          <IconButton
            edge={mobileViewPort ? "end" : "start"}
            disabled={reservationsLoading}
            onClick={() => {
              handleChangeDate("subDay");
            }}
            size={"small"}
          >
            <ArrowLeftIcon style={{ fontSize: 32 }} />
          </IconButton>
        )}

        <ButtonDateRangePicker
          value={dateValue}
          onChange={(newValue) => handleChangeDate(newValue)}
        />
        {!mobileViewPort && (
          <IconButton
            edge={mobileViewPort ? "start" : "end"}
            disabled={reservationsLoading}
            onClick={() => {
              handleChangeDate("addDay");
            }}
            size={"small"}
          >
            <ArrowRightIcon style={{ fontSize: 32 }} />
          </IconButton>
        )}

        <IconButton
          edge={mobileViewPort ? "start" : "end"}
          disabled={reservationsLoading}
          onClick={() => {
            handleChangeDate("add");
          }}
          size={"small"}
        >
          <FastForwardIcon style={{ fontSize: 32 }} />
        </IconButton>
      </Box>
    );
  else {
    return (
      <ButtonDateRangePicker
        value={dateValue}
        onChange={(newValue) => handleChangeDate(newValue)}
      />
    );
  }
}

const DateRangeButtonField = React.forwardRef((props, ref) => {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref: containerRef } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;
  const { startDate, selectedView } = useContext(AppContext);
  const handleRef = useForkRef(ref, containerRef);

  // navigation text changes based on the selectedView
  const navigationText = useMemo(() => {
    if (selectedView === "monthly")
      return format(startDate, "LLLL", { locale: fi });
    if (selectedView === "weekly") return "Viikko: " + getISOWeek(startDate);
    if (selectedView === "daily")
      return format(startDate, "cccc dd.MM.", { locale: fi });
    if (selectedView === "reservationTable") {
      return "Valitse ajanjakso";
    }
  }, [selectedView, startDate]);

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={handleRef}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{ width: "150px", borderRadius: "10px", textTransform: "none" }}
    >
      {navigationText}
    </Button>
  );
});

DateRangeButtonField.fieldType = "single-input";

const shortcutsItems = [
  {
    label: "Tänään",
    getValue: () => {
      const today = new Date(); // Use the native JavaScript Date object

      return today;
    },
  },
  {
    label: "Tämä viikko",
    getValue: () => {
      const today = new Date(); // Use the native JavaScript Date object
      const start = startOfISOWeek(today); // Customize weekStartsOn to your locale (0 = Sunday, 1 = Monday, etc.)

      return start;
    },
  },
  {
    label: "Tämä kuukausi",
    getValue: () => {
      const today = new Date(); // Use the native JavaScript Date object
      const start = startOfMonth(today); // Customize weekStartsOn to your locale (0 = Sunday, 1 = Monday, etc.)

      return start;
    },
  },
];

const ButtonDateRangePicker = React.forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const { setEndDate, setStartDate, selectedView, mobileViewPort } =
    useContext(AppContext);
  if (selectedView !== "reservationTable") {
    return (
      <DesktopDatePicker
        displayWeekNumber
        slots={{
          field: DateRangeButtonField,
          ...props.slots,
          shortcuts: CustomRangeShortcuts,
        }}
        ref={ref}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={(newValue) => {
          setStartDate(newValue);
          if (selectedView === "weekly") {
            console.log("trigger", addDays(endOfDay(newValue), 6));
            setEndDate(addDays(endOfDay(newValue), 6));
          }
          if (selectedView === "monthly") {
            setEndDate(endOfMonth(endOfDay(newValue)));
          }
        }}
        slotProps={{
          field: { setOpen },
          shortcuts: {
            items: shortcutsItems,
          },
          actionBar: { actions: [] },
        }}
      />
    );
  } else {
    return (
      <DateRangePicker
        calendars={mobileViewPort ? 1 : 2}
        slots={{ field: DateRangeButtonField, ...props.slots }}
        slotProps={{ field: { setOpen } }}
        ref={ref}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    );
  }
});

function CustomRangeShortcuts(props) {
  const { items, onChange, isValid } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map((item) => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: 2,
      }}
    >
      <List
        dense
        sx={(theme) => ({
          display: "flex",
          px: theme.spacing(4),
          "& .MuiListItem-root": {
            pt: 0,
            pl: 0,
            pr: theme.spacing(1),
          },
        })}
      >
        {resolvedItems.map((item) => {
          return (
            <ListItem key={item.label}>
              <Chip {...item} />
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </Box>
  );
}

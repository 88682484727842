import React, { useContext, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { useField } from "formik";
import { AppContext } from "../../../../../../../../AppContext";
import { useFetchCompanies } from "../../../../../../../../services/fetchCompanies";
import { AutoCompleteComponent } from "../../../../../../components/AutoCompleteComponent";

export default function PickupLocations() {
  const { mobileViewPort, business, user } = useContext(AppContext);
  const { companies } = useFetchCompanies(user?.group, business?.id);

  const combinedLocations = useMemo(() => {
    let result = [];
    if (!companies) {
      return null;
    }
    for (let company of companies) {
      result.push(company);
      if (business?.pickupLocations) {
        for (let location of business?.pickupLocations) {
          if (location?.companyId === company.id) {
            result.push(location);
          }
        }
      }
    }
    return result;
  }, [companies, business]);
  return (
    <Stack direction={mobileViewPort ? "column" : "row"} spacing={1} flex={1}>
      <Box width={mobileViewPort ? "100%" : "50%"} maxWidth={"400px"}>
        <StartLocation combinedLocations={combinedLocations} />
      </Box>
      <Box width={mobileViewPort ? "100%" : "50%"} maxWidth={"400px"}>
        <ReturnLocation combinedLocations={combinedLocations} />
      </Box>
    </Stack>
  );
}

function StartLocation(props) {
  const { combinedLocations } = props;
  const [, startLocationMeta, startLocationHelpers] = useField("startLocation");
  const { value, error } = startLocationMeta;
  const [, startLocationIDMeta, startLocationIDHelpers] =
    useField("startLocationID");
  const [, , companyHelpers] = useField("companyId");
  const { setValue: setStartLocationID } = startLocationIDHelpers;
  const { setValue: setStartLocationValue } = startLocationHelpers;
  const { setValue: setCompanyId } = companyHelpers;
  const { value: valueID } = startLocationIDMeta;
  const { company } = useContext(AppContext);

  const onInputChange = async (event) => {
    await setStartLocationValue(event.target.value);
    // handle start location ID when user types in the input field
    // for now, use the company ID from the context as the start location ID
    if (valueID !== company?.id) {
      await setStartLocationID(company?.id);
      await setCompanyId(company?.id);
    }
  };
  return (
    <AutoCompleteComponent
      defaultOptions={combinedLocations ?? []}
      label={"Valitse noutopiste"}
      inputValue={value ?? ""}
      onInputChange={onInputChange}
      value={value}
      onChange={async (event, newValue) => {
        await setStartLocationValue(newValue?.name);
        await setStartLocationID(newValue?.companyId ?? newValue?.id);
        // make sure there is a company id to set
        if (newValue) {
          const companyId = newValue?.companyId ?? newValue?.id;

          await setCompanyId(companyId);
        } else {
          await setCompanyId(company?.id);
        }
      }}
      freeSolo={true}
      errors={error}
    />
  );
}

function ReturnLocation(props) {
  const { combinedLocations } = props;

  const [, returnLocationMeta, returnLocationHelpers] =
    useField("returnLocation");
  const { value, error } = returnLocationMeta;
  const [, returnLocationIDMeta, returnLocationIDHelpers] =
    useField("returnLocationID");
  const { setValue: setReturnLocationID } = returnLocationIDHelpers;
  const { setValue: setReturnLocationValue } = returnLocationHelpers;
  const { value: valueID } = returnLocationIDMeta;
  const { company } = useContext(AppContext);

  const onInputChange = async (event) => {
    await setReturnLocationValue(event.target.value);
    // handle start location ID when user types in the input field
    // for now, use the company ID from the context as the start location ID
    if (valueID !== company?.id) {
      await setReturnLocationID(company?.id);
    }
  };
  return (
    <AutoCompleteComponent
      defaultOptions={combinedLocations ?? []}
      errors={error}
      freeSolo={true}
      inputValue={value ?? ""}
      label={"Valitse palautuspiste"}
      onChange={async (event, newValue) => {
        await setReturnLocationValue(newValue?.name);
        await setReturnLocationID(newValue?.companyId ?? newValue?.id);
      }}
      onInputChange={onInputChange}
      value={value}
    />
  );
}

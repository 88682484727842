import { useContext } from "react";
import styles from "./styles.module.scss";
import VehiclePicture from "./components/VehiclePicture";
import { Typography, useTheme } from "@mui/material";
import Dropdown from "./components/Dropdown";
import { EDeviceType } from "~/enums/device-type";
// @ts-ignore
import { AppContext } from "~/AppContext";
import { Vehicle } from "~/graphql/API";
import { useSettingsContext } from "../../../../SettingsContext";
// @ts-ignore
import { useFetchCompanies } from "~/services/fetchCompanies";

type VehicleCardProps = {
  vehicle: Vehicle;
};

function VehicleCard({ vehicle }: VehicleCardProps) {
  const theme = useTheme();
  const { deviceType, rowVariant } = useSettingsContext();
  const { setEditVehicleData, user, business } = useContext<any>(AppContext);

  const { selectedCompany } = useFetchCompanies(
    user?.group,
    business?.id,
    vehicle.companyId
  );

  const isDesktop = deviceType !== EDeviceType.MOBILE;

  return (
    <div
      className={styles.root}
      data-device-type={deviceType}
      tabIndex={0}
      onClick={() => setEditVehicleData(vehicle)}
      style={{ "--onHover": theme.palette.primary.main }}
    >
      <div className={styles.infoWrapper}>
        {rowVariant === "regular" && <VehiclePicture vehicle={vehicle} />}

        <div className={styles.labels}>
          {isDesktop && (
            <Typography
              variant="body1"
              sx={{
                color: "black",
                fontSize: "15px",
              }}
              title={vehicle.name}
            >
              {vehicle.name}
            </Typography>
          )}

          <Typography
            variant="body1"
            sx={{ fontSize: "13px", fontWeight: 600 }}
            title={vehicle.registrationPlate || ""}
          >
            {vehicle.registrationPlate}
          </Typography>

          {isDesktop && rowVariant === "regular" && (
            <Typography
              variant="body1"
              sx={{
                fontSize: "14px",
                fontStyle: "italic",
                color: "rgba(0, 0, 0, 0.5)",
              }}
              title={selectedCompany?.name}
            >
              {selectedCompany?.name}
            </Typography>
          )}
        </div>
      </div>

      <Dropdown vehicle={vehicle} />
    </div>
  );
}

export default VehicleCard;

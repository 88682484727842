export const defaultHanselClasses = [
  {
    productNro: "7001572",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 1",
  },
  {
    productNro: "7001573",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 2",
  },
  {
    productNro: "7001574",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 3",
  },
  {
    productNro: "7001575",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 4",
  },
  {
    productNro: "7001576",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 5",
  },
  {
    productNro: "7001577",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 6",
  },
  {
    productNro: "7001578",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 7",
  },
  {
    productNro: "7001579",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 8",
  },
  {
    productNro: "7001580",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 9",
  },
  {
    productNro: "7001581",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 10 A",
  },
  {
    productNro: "7001582",
    desc: "Autonvuokraus bensiini- tai dieselauto",
    class: "luokka 10 B",
  },
  {
    productNro: "7001583",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 1",
  },
  {
    productNro: "7001584",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 2",
  },
  {
    productNro: "7001585",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 3",
  },
  {
    productNro: "7001586",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 4",
  },
  {
    productNro: "7001587",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 5",
  },
  {
    productNro: "7001588",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 6",
  },
  {
    productNro: "7001589",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 7",
  },
  {
    productNro: "7001590",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 8",
  },
  {
    productNro: "7001591",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 9",
  },
  {
    productNro: "7001592",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 10 A",
  },
  {
    productNro: "7001593",
    desc: "Autonvuokraus ladattava hybridiauto PHEV",
    class: "luokka 10 B",
  },
  {
    productNro: "7001594",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 1",
  },
  {
    productNro: "7001595",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 2",
  },
  {
    productNro: "7001596",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 3",
  },
  {
    productNro: "7001597",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 4",
  },
  {
    productNro: "7001598",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 5",
  },
  {
    productNro: "7001599",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 6",
  },
  {
    productNro: "7001600",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 7",
  },
  {
    productNro: "7001601",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 8",
  },
  {
    productNro: "7001602",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 9",
  },
  {
    productNro: "7001603",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 10 A",
  },
  {
    productNro: "7001604",
    desc: "Autonvuokraus täyssähköauto BEV",
    class: "luokka 10 B",
  },
  {
    productNro: "7001605",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 1",
  },
  {
    productNro: "7001606",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 2",
  },
  {
    productNro: "7001607",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 3",
  },
  {
    productNro: "7001608",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 4",
  },
  {
    productNro: "7001609",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 5",
  },
  {
    productNro: "7001610",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 6",
  },
  {
    productNro: "7001611",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 7",
  },
  {
    productNro: "7001612",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 8",
  },
  {
    productNro: "7001613",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 9",
  },
  {
    productNro: "7001614",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 10 A",
  },
  {
    productNro: "7001615",
    desc: "Autonvuokraus muun käyttövoiman auto",
    class: "luokka 10 B",
  },
  {
    productNro: "7001616",
    desc: "Autonvuokraus kevyt perävaunu",
    class: "luokka 10 C",
  },
  {
    productNro: "7001617",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 1",
  },
  {
    productNro: "7001618",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 2",
  },
  {
    productNro: "7001619",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 3",
  },
  {
    productNro: "7001620",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 4",
  },
  {
    productNro: "7001621",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 5",
  },
  {
    productNro: "7001622",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 6",
  },
  {
    productNro: "7001623",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 7",
  },
  {
    productNro: "7001624",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 8",
  },
  {
    productNro: "7001625",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 9",
  },
  {
    productNro: "7001626",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 10 A",
  },
  {
    productNro: "7001627",
    desc: "Minileasing bensiini- tai dieselauto",
    class: "luokka 10 B",
  },
  {
    productNro: "7001628",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 1",
  },
  {
    productNro: "7001629",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 2",
  },
  {
    productNro: "7001630",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 3",
  },
  {
    productNro: "7001631",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 4",
  },
  {
    productNro: "7001632",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 5",
  },
  {
    productNro: "7001633",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 6",
  },
  {
    productNro: "7001634",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 7",
  },
  {
    productNro: "7001635",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 8",
  },
  {
    productNro: "7001636",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 9",
  },
  {
    productNro: "7001637",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 10 A",
  },
  {
    productNro: "7001638",
    desc: "Minileasing ladattava hybridiauto  PHEV",
    class: "luokka 10 B",
  },
  {
    productNro: "7001639",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 1",
  },
  {
    productNro: "7001640",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 2",
  },
  {
    productNro: "7001641",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 3",
  },
  {
    productNro: "7001642",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 4",
  },
  {
    productNro: "7001643",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 5",
  },
  {
    productNro: "7001644",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 6",
  },
  {
    productNro: "7001645",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 7",
  },
  {
    productNro: "7001646",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 8",
  },
  {
    productNro: "7001647",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 9",
  },
  {
    productNro: "7001648",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 10 A",
  },
  {
    productNro: "7001649",
    desc: "Minileasing täyssähköauto  BEV",
    class: "luokka 10 B",
  },
  {
    productNro: "7001650",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 1",
  },
  {
    productNro: "7001651",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 2",
  },
  {
    productNro: "7001652",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 3",
  },
  {
    productNro: "7001653",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 4",
  },
  {
    productNro: "7001654",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 5",
  },
  {
    productNro: "7001655",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 6",
  },
  {
    productNro: "7001656",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 7",
  },
  {
    productNro: "7001657",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 8",
  },
  {
    productNro: "7001658",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 9",
  },
  {
    productNro: "7001659",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 10 A",
  },
  {
    productNro: "7001660",
    desc: "Minileasing muun käyttövoiman auto",
    class: "luokka 10 B",
  },
  {
    productNro: "7001661",
    desc: "Minileasing kevyt perävaunu",
    class: "luokka 10 C",
  },
];

export const externalUserHanselClasses = [
  {
    class: "1",
    contractVehicleClass: "A",
    name: "Pienet autot",
    exampleModel: "Volkswagen Polo tai vastaava",
    productNro: "7001650",
    vehicleClasses: ["B-EDMR"],
  },

  {
    class: "2",
    contractVehicleClass: "C",
    name: "Pieni keskiluokka",
    exampleModel: "Peugeot 308 tai vastaava",
    productNro: "7001651",
    vehicleClasses: ["CWA-CWAR"],
  },
  {
    class: "3",
    contractVehicleClass: "D",
    name: "Suuri keskiluokka",
    exampleModel: "Skoda Octavia tai vastaava",
    productNro: "7001652",
    vehicleClasses: ["DWA-IWAR"],
  },
  {
    class: "4",
    contractVehicleClass: "F",
    name: "Suuret autot",
    exampleModel: "BMW 5-sarja tai vastaava",
    productNro: "7001653",
    vehicleClasses: ["FW-FWAR"],
  },
  {
    class: "5",
    contractVehicleClass: "I",
    name: "SUV",
    exampleModel: "Peugeot 3008 tai vastaava",
    productNro: "7001654",
    vehicleClasses: ["IA-IFAR"],
  },
  {
    class: "6",
    contractVehicleClass: "X",
    name: "Neliveto SUV",
    exampleModel: "BMW X3 tai vastaava",
    productNro: "7001655",
    vehicleClasses: ["X-PFBR"],
  },
  {
    class: "7",
    contractVehicleClass: "H",
    name: "Suuret tila-autot ja pikkubussit",
    exampleModel: "Volkswagen Transporter Kombi tai vastaava",
    productNro: "7001656",
    vehicleClasses: ["H2A-LVAR"],
  },
  {
    class: "8",
    contractVehicleClass: "K",
    name: "Pakettiautot",
    exampleModel: "Peugeot Expert tai vastaava",
    productNro: "7001657",
    vehicleClasses: ["K-CKMD"],
  },
  {
    class: "9",
    contractVehicleClass: "R",
    name: "Isot pakettiautot",
    exampleModel: "Ford Transit tai vastaava",
    productNro: "7001658",
    vehicleClasses: ["P-DKMD", "PA-DKAD"],
  },
  {
    class: "10A",
    contractVehicleClass: "M-FKMD",
    name: "Muuttoautot",
    exampleModel: "Volkswagen Crafter tai vastaava",
    productNro: "7001659",
    vehicleClasses: ["M-FKMD"],
  },
  {
    class: "10B",
    contractVehicleClass: "W",
    name: "Neliveto pick-up",
    exampleModel: "Volkswagen Amarok tai vastaava",
    productNro: "7001660",
    vehicleClasses: ["W-XPAD"],
  },
  {
    class: "10C",
    contractVehicleClass: "",
    name: "Peräkärry",
    productNro: "7001661",
    vehicleClasses: ["PE-KUOMU", "AVOKÄR1", "AVOKÄR2"],
  },
];

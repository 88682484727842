import { calculateReservationTotalPrice, translateChannel } from "../utils";

export const defaultRows = (
  reservationsData,
  companies,
  setEditReservationData
) => {
  let productRows = [];

  reservationsData.forEach((reservation, idx) => {
    if (reservation?.reservationVehicles?.length > 0) {
      const vehicleName = `${reservation?.reservationVehicles.map(
        (item) => item?.name
      )}`;
      const registrationPlates = `${reservation?.reservationVehicles.map(
        (item) => item?.registrationPlate
      )}`;
      productRows.push({
        id: reservation.id,
        hierarchy: [`Varaus-${idx + 1}`],
        startTime: reservation.startTime,
        endTime: reservation.endTime,
        paymentStatus: reservation.paymentStatus,
        totalPrice: calculateReservationTotalPrice(reservation),
        vehicle: registrationPlates,
        vehicleName: vehicleName,
        price: reservation?.reservationVehicles,
        billPayment: reservation.billPayment,
        signStatus: reservation.signStatus,
        authStatus: reservation.authStatus,
        livionData: reservation.livionData,
        name: reservation.name,
        varaus: reservation,
        openReservation: () => setEditReservationData(reservation),
        additionalServices: reservation?.additionalServices?.map((s) => s.key),
        company: companies
          ? companies.find((c) => c.id === reservation?.companyId)?.name
          : null,
        channel: translateChannel[reservation?.channel] ?? null,
        type: reservation?.type,
      });

      reservation?.additionalServices?.map((service, index) => {
        const hierarchy = [
          `Varaus-${idx + 1}`,
          `${service?.description}-${index}` ?? `Tuote - ${service?.id}`,
        ];

        return productRows.push({
          id: `${reservation.id}+${service?.key}+${index}`,
          resId: reservation.id,
          vehicle: null, // this is the registration plate field for the vehicle. not used for the services.
          vehicleName:
            `${service?.description} x ${service?.quantity}` ??
            "Tuotteen tietoja ei löydy.",
          price: [service],
          hierarchy: hierarchy,
        });
      });
    }
  });
  return productRows;
};

import { TextField } from "@mui/material";
import { FormGrid } from "../../common/FormComponents";
import { useFormikContext } from "formik";
export default function InsuranceAdjuster() {
  const { values } = useFormikContext();
  return (
    <>
      <FormGrid>
        <TextField
          disabled
          value={values?.insuranceData?.claimAdjusterName}
          name="insuranceData.insuranceAdjuster"
          label="Korvauskäsittelijä"
          variant="outlined"
          fullWidth
        />
      </FormGrid>

      <FormGrid>
        <TextField
          disabled
          value={values?.insuranceData?.claimAdjusterEmail}
          name="insuranceData.insuranceAdjusterEmail"
          label="Sähköposti"
          variant="outlined"
          fullWidth
        />
      </FormGrid>
    </>
  );
}

import ReservationCard from "../../../../../ReservationCard";
import { CSSProperties, useContext } from "react";
import { RowContext } from "../../../../../VehicleRow/RowContext";
import { Reservation } from "~/graphql/API";
import ReservationCardLabel from "../../../../../ReservationCardLabel";
import { differenceInCalendarDays } from "date-fns";
import ReservationTooltip from "../../../../../ReservationCard/components/ReservationTooltip";
import styles from "./styles.module.scss";
import { SettingsContext } from "../../../../../../SettingsContext";

type ReservationCardVerticalProps = {
  reservation: Reservation;
};

function ReservationCardVertical({
  reservation,
}: ReservationCardVerticalProps) {
  const { vehicle } = useContext(RowContext);
  const { calendar } = useContext(SettingsContext);

  const startTime = reservation.startTime as unknown as Date;
  const startTime_time = startTime.getTime();
  const endTime = reservation.endTime as unknown as Date;
  const endTime_time = endTime.getTime();

  const isStartingBefore = startTime_time < calendar.startDate;
  const isEndingAfter = endTime_time > calendar.endDate;

  if (
    (isStartingBefore && endTime_time < calendar.startDate) ||
    (isEndingAfter && startTime_time > calendar.endDate)
  ) {
    return null;
  }

  const days =
    isStartingBefore && isEndingAfter
      ? calendar.daysCount - 1
      : isStartingBefore
      ? differenceInCalendarDays(endTime, calendar.startDate)
      : isEndingAfter
      ? differenceInCalendarDays(calendar.endDate, startTime) - 1
      : differenceInCalendarDays(endTime, startTime);

  const datePos: CSSProperties = {
    gridColumnStart: isStartingBefore
      ? 1
      : differenceInCalendarDays(startTime, calendar.startDate) + 1,
    gridColumnEnd: isEndingAfter
      ? -1
      : differenceInCalendarDays(endTime, calendar.startDate) + 2,
  };

  return (
    <ReservationTooltip reservation={reservation} vehicle={vehicle}>
      {days ? (
        <div
          className={styles.root}
          style={{
            gridTemplateColumns: `repeat(${days + 1}, 1fr)`,
            ...datePos,
          }}
        >
          {Array(days + 1)
            .fill(undefined)
            .map((_, i) => {
              const isStart = !i && !isStartingBefore;
              const isEnd = !(i < days) && !isEndingAfter;

              return (
                <ReservationCard
                  key={i}
                  direction="vertical"
                  reservation={reservation}
                  data-has-start={isStart ? "" : undefined}
                  data-has-end={isEnd ? "" : undefined}
                  className={styles.reservation}
                  style={{
                    gridColumnStart: i + 1,
                    gridRowStart: isStart ? (startTime.getHours() + 1) * 2 : 1,
                    gridRowEnd: isEnd ? (endTime.getHours() + 1) * 2 : -1,
                  }}
                >
                  {!i && (
                    <ReservationCardLabel
                      reservation={reservation}
                      vehicle={vehicle}
                      showTime={false}
                    />
                  )}
                </ReservationCard>
              );
            })}
        </div>
      ) : (
        <ReservationCard
          direction="vertical"
          reservation={reservation}
          data-has-start={isStartingBefore ? undefined : ""}
          data-has-end={isEndingAfter ? undefined : ""}
          className={styles.reservation}
          style={{
            ...datePos,
            gridRowStart: isStartingBefore ? 1 : (startTime.getHours() + 1) * 2,
            gridRowEnd: isEndingAfter ? -1 : (endTime.getHours() + 1) * 2,
          }}
        >
          <ReservationCardLabel
            reservation={reservation}
            vehicle={vehicle}
            showTime={false}
          />
        </ReservationCard>
      )}
    </ReservationTooltip>
  );
}

export default ReservationCardVertical;

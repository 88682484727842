import { format, isValid } from "date-fns";
import { EOrderDetails } from "./LanguagePack/translatedFields";
import { Stack } from "@mui/material";
import { ContractField } from "./common";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";

export default function ContractReservationSchedule({
  selectedLanguage,
  contractSettings,
}: {
  selectedLanguage: string;
  contractSettings: Record<string, string | boolean>;
}) {
  const reservation = useAtomValue(reservationDataAtom);

  if (!reservation) {
    return null;
  }

  //@ts-ignore
  const { startLocation, returnLocation } = reservation;
  //@ts-ignore
  const startTime = reservation?.startTime
    ? //@ts-ignore
      new Date(reservation?.startTime)
    : null;
  //@ts-ignore
  const returnTime = reservation?.returnTime
    ? //@ts-ignore
      new Date(reservation?.returnTime)
    : null;
  console.log("startTime", startTime);
  console.log("returnTime", returnTime);
  const fields = EOrderDetails[selectedLanguage as keyof typeof EOrderDetails];
  return (
    <Stack
      style={{
        justifyContent: "space-between",
      }}
      flex={0.5}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
        flex={1}
      >
        <ContractField
          value={startLocation}
          label={fields.START_LOCATION}
          visibility={!!contractSettings.showLocation}
        />
        <ContractField
          value={
            //@ts-ignore
            isValid(startTime) ? format(startTime, "dd.MM.yyyy HH:mm") : null
          }
          label={fields.START_DATE}
          visibility={!!startTime}
          sx={{ alignItems: "flex-end" }}
        />
      </Stack>
      <Stack direction={"row"}>
        <ContractField
          value={returnLocation}
          label={fields.RETURN_LOCATION}
          visibility={!!contractSettings.showLocation}
        />
        <ContractField
          value={
            //@ts-ignore
            isValid(returnTime) ? format(returnTime, "dd.MM.yyyy HH:mm") : null
          }
          label={fields.RETURN_DATE}
          visibility={!!returnTime}
          sx={{ alignItems: "flex-end" }}
        />
      </Stack>
    </Stack>
  );
}

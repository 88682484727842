import {
  ListReservationsByPaymentStatusByOrgBusinessIdQueryVariables,
  Reservation,
} from "~/graphql/API";
import useSWR from "swr";
import { graphqlOperation } from "aws-amplify";
import { listReservationsByPaymentStatusByOrgBusinessId } from "~/graphql/queries";
// @ts-ignore
import { listAll } from "~/utilities";

const fallbackData: Reservation[] = [];

async function fetchReservationsByPaymentStatus(
  variables?: ListReservationsByPaymentStatusByOrgBusinessIdQueryVariables
) {
  const result = await listAll(
    graphqlOperation(listReservationsByPaymentStatusByOrgBusinessId, variables),
    "listReservationsByPaymentStatusByOrgBusinessId"
  );

  return result.filter(Boolean) as Reservation[];
}

export function useReservationsByPaymentStatus(
  ...params: Parameters<typeof fetchReservationsByPaymentStatus>
) {
  return useSWR(
    ["reservations", ...params],
    async () => await fetchReservationsByPaymentStatus(...params),
    {
      fallbackData,
      revalidateOnFocus: false,
    }
  );
}

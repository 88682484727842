import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { Reservation } from "~/graphql/API";
import { getReservationTotal } from "~/utils/getReservationTotal";
import { getTotal } from "~/utils/getTotal";

export const columns: GridColDef<Reservation>[] = [
  {
    headerName: "Luontipäivämäärä",
    field: "creation_date",
    width: 160,
    align: "center",
    valueGetter({ row }) {
      return format(new Date(row.createdAt), "PPP");
    },
  },
  {
    headerName: "Toimipiste",
    field: "location",
    flex: 1,
    valueGetter({ row }) {
      return row.startLocation;
    },
  },
  {
    headerName: "Aloitusaika",
    field: "pick_up_date",
    width: 160,
    align: "center",
    valueGetter({ row }) {
      return format(new Date(row.startTime!), "PPP");
    },
  },
  {
    headerName: "Palautusaika",
    field: "return_date",
    width: 160,
    align: "center",
    valueGetter({ row }) {
      return format(new Date(row.endTime!), "PPP");
    },
  },
  {
    headerName: "Noutopiste",
    field: "pick_up_location",
    flex: 1,
    valueGetter({ row }) {
      return row.startLocation;
    },
  },
  {
    headerName: "Asiakkaan viite",
    field: "customer_reference",
    flex: 1,
    valueGetter({ row }) {
      const billingRef = row.isCompany ? row.companyBillingRef : row.billingRef;
      return billingRef;
    },
  },
  {
    headerName: "Yhteensä",
    field: "order_sum",
    width: 100,
    align: "right",
    valueGetter({ row }) {
      return getTotal(getReservationTotal(row));
    },
    renderCell({ value }) {
      return value.toFixed(2);
    },
  },
];

import { TextField, Box, Typography, Divider } from "@mui/material";

export default function VismaSignSettings({ formik }) {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "25px",
        width: "100%",
        alignItems: "center",

        flexDirection: "column",
      }}
    >
      <Typography
        align="center"
        sx={{ fontFamily: "Sofia Pro", fontSize: "20px" }}
      >
        Visma Sign asetukset
      </Typography>
      <Divider sx={{ margin: "15px" }} flexItem />
      <Box
        sx={{
          display: "flex",

          width: "80%",
          flexDirection: "column",
        }}
      >
        <TextField
          value={formik.values.nameInEmail ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="nameInEmail"
          label="Visma Sign kutsussa näkyvä yrityksen nimi"
          variant="outlined"
          size="small"
          fullWidth
          sx={{ marginBottom: "15px", marginTop: "15px" }}
        />

        <TextField
          value={formik.values.signConfirmationEmail ?? ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="signConfirmationEmail"
          label="Sähköposti vahvistusviesteille"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Box>
    </Box>
  );
}

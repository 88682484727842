import { Box, Stack, SxProps, Typography } from "@mui/material";
import { format } from "date-fns";
import { fi } from "date-fns/locale";
import { contractFontSize } from "../contract-template-preview";
export const ContractField = ({
  label,
  value,
  sx,
  visibility,
}: {
  label: string;
  value: any;
  sx?: SxProps;
  visibility?: boolean;
}) => {
  if (!visibility) {
    return null;
  }
  const getFieldValue = () => {
    if (value === 0) {
      return 0;
    }
    if (value === "" || value === null || value === undefined) {
      return <Empty />;
    }
    return value;
  };
  return (
    <Stack
      sx={{
        flex: 1,
        fontSize: contractFontSize,
        ...sx,
      }}
    >
      <FieldLabel label={label} />
      <Box sx={{ marginLeft: "5px" }}>{getFieldValue()}</Box>
    </Stack>
  );
};

const FieldLabel = ({ label }: { label: string }) => {
  return (
    <Typography
      variant="caption"
      sx={{ color: "grey", marginLeft: "5px", fontSize: "10px" }}
    >
      {label}
    </Typography>
  );
};

const Empty = () => {
  return <Box sx={{ height: "15px" }} />;
};

export const formatDateTime = (date: Date) => {
  return format(date, "dd.MM.yyyy HH:mm", { locale: fi });
};

import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { createCustomer } from "../../../graphql/mutations";
import { useNotification } from "../../Notification";

export const useUpdateClientDirectory = () => {
  const { user, business } = useContext(AppContext);
  // Check if customerCompanyId is present, if not, create a new customer
  const notification = useNotification();
  const checkClientEntry = async (values) => {
    if (!values.isCompany) return undefined;
    if (values.customerCompanyId) {
      return values.customerCompanyId; // ID already exists
    }
    if (!values.customerCompanyId && values.isCompany) {
      try {
        const customerInput = {
          organizationId: user?.organizationId ?? undefined,
          group: user?.group ?? undefined,
          orgBusinessId: business?.id ?? undefined,
          brokerName: values.brokerName,
          companyName: values.companyName,
          address: values.address,
          postalCode: values.postalCode,
          city: values.city,
          phone: values.phone,
          email: values.email.trim(),
          companyBillingAddress: values.companyBillingAddress,
          companyBusinessId: values.companyBusinessId,
          companyEBillingOperator: values.companyEBillingOperator,
          companyEBillingAddress: values.companyEBillingAddress,
          companyBillingRef: values.companyBillingRef,
          companyCity: values.companyCity,
          companyPostalCode: values.companyPostalCode,
          clientType: "COMPANY",
          isDeleted: false,
        };

        const createCustomerResponse = await API.graphql(
          graphqlOperation(createCustomer, { input: customerInput })
        );
        notification.show("Uusi asiakas luotiin onnistuneesti.", "success");
        return createCustomerResponse.data.createCustomer.id;
      } catch (error) {
        notification.show("Uuden asiakkaan luonti epäonnistui.", "error");
        console.error("Error creating customer:", error);
        return { success: false, error };
      }
    }
  };

  return { checkClientEntry };
};

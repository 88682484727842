import { ECustomerInfo } from "./LanguagePack/translatedFields";
import { Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { ContractField } from "./common";
import { ReservationVehicle } from "~/graphql/API";

export default function ContractCustomerInformation({
  selectedLanguage,
}: {
  selectedLanguage: string;
}) {
  const reservation = useAtomValue(reservationDataAtom);

  if (!reservation) {
    return null;
  }

  const {
    //@ts-ignore
    name,
    //@ts-ignore
    ssn,
    //@ts-ignore
    address,
    //@ts-ignore
    postalCode,
    //@ts-ignore
    city,
    //@ts-ignore
    phone,
    //@ts-ignore
    email,
    //@ts-ignore
    customerNotes,
    //@ts-ignore
    billingRef,
  } = reservation;
  const fields = ECustomerInfo[selectedLanguage as keyof typeof ECustomerInfo];

  const maskedSsn = ssn
    ? ssn.slice(0, -4) + ssn.slice(-4).replace(/./g, "*")
    : "";
  // @ts-ignore
  const hasRequiredLicense = reservation?.reservationVehicles?.some(
    (vehicle: any) =>
      vehicle?.additionalFields?.find(
        (field: any) => field?.key === "requiredLicense"
      )?.value
  );

  return (
    <Stack
      sx={{
        border: "1px solid lightgrey",
        padding: "5px",
        borderRadius: "5px",
      }}
      flex={1}
    >
      <Stack flex={1} direction={"column"} spacing={1}>
        <ContractField
          value={name}
          label={fields.NAME}
          visibility={true}
          sx={{ flex: 0.7 }}
        />
        <Stack direction={"row"} flex={0.3}>
          <ContractField
            value={maskedSsn}
            label={fields.SSN}
            visibility={true}
            sx={{ flex: 0.6 }}
          />
          <ContractField
            value={phone}
            label={fields.PHONE}
            visibility={true}
            sx={{ flex: 0.5 }}
          />{" "}
          {hasRequiredLicense && (
            <RequiredLicenseField reservation={reservation} fields={fields} />
          )}
        </Stack>
      </Stack>
      <ContractField
        value={
          address && postalCode && city
            ? `${address}, ${postalCode}, ${city}`
            : ""
        }
        label={fields.ADDRESS}
        visibility={true}
      />

      <ContractField value={email} label={fields.EMAIL} visibility={true} />
      {customerNotes && (
        <ContractField
          value={customerNotes}
          label={fields.CUSTOMER_NOTES}
          visibility={true}
        />
      )}
      {billingRef && (
        <ContractField
          value={billingRef}
          label={fields.BILLING_REFERENCE}
          visibility={true}
        />
      )}
    </Stack>
  );
}

const RequiredLicenseField = ({
  reservation,
  fields,
}: {
  reservation: any;
  fields: any;
}) => {
  const requiredLicenseValue = reservation?.reservationVehicles
    // @ts-ignore
    ?.flatMap((vehicle: ReservationVehicle) => vehicle?.additionalFields || [])
    // @ts-ignore
    ?.find((field) => field?.key === "requiredLicense")?.value;

  return (
    <ContractField
      value={requiredLicenseValue}
      label={fields.DRIVERS_LICENSE_CLASS}
      visibility={true}
      sx={{ flex: 0.4 }}
    />
  );
};

import { Amplify, I18n, Auth as AmplifyAuth } from "aws-amplify";
import { dict } from "./amplify-i18n";
import App from "./App";
import {
  Authenticator,
  Heading,
  useTheme,
  Text,
  View,
  Image,
  Button,
  useAuthenticator,
  ThemeProvider,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import logo from "./customerLogos/rowly/rowlygo_logo_lila_rgb.png";

Amplify.configure({
  API: {
    graphql_headers: async () => ({
      Authorization: (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken(),
      "Urent-Build-Date": import.meta.env.VITE_BUILD_DATE ?? "",
    }),
  },
  ...awsExports,
});

I18n.putVocabularies(dict);
I18n.setLanguage("fi");

const theme = {
  name: "custom-theme",
  tokens: {
    components: {
      button: {
        backgroundColor: { value: "#4a00b3" },
      },
    },
    colors: {
      brand: {
        primary: {
          10: "rgba(60, 26, 91, 0.2)",
          20: "rgba(60, 26, 91, 1)",
          40: "rgba(60, 26, 91, 1)",
          60: "rgba(60, 26, 91, 1)",
          80: "rgba(60, 26, 91, 1)",
          90: "rgba(60, 26, 91, 0.8)",
          100: "#3C1A5B",
        },
      },
    },
  },
};

const components = {
  SignIn: {
    Header() {
      return (
        <View textAlign="center">
          <Image objectFit="contain" alt="RowlyGO" src={logo} />
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
            style={{
              marginBottom: "10px",
            }}
          >
            Unohtuiko salasana?
          </Button>
        </View>
      );
    },
  },

  ResetPassword: {
    Header() {
      return (
        <Heading level={3}>
          <Image objectFit="contain" alt="RowlyGO" src={logo} />
        </Heading>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return <Heading level={3}>Syötä sähköpostiosoite</Heading>;
    },
    Footer() {
      return <Text> </Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      label: " ",
      placeholder: "Sähköposti",
    },
    password: {
      label: " ",
      placeholder: "Salasana ",
    },
  },
  resetPassword: {
    username: {
      label: " ",
      placeholder: "Käyttäjätunnus tai sähköpostiosoite",
    },
  },
};

export default function Auth() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        hideSignUp={true}
        formFields={formFields}
        components={components}
        variation="modal"
      >
        {({ signOut, user }) => <App signOut={signOut} cognitoUser={user} />}
      </Authenticator>
    </ThemeProvider>
  );
}

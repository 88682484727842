import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext } from "react";
import { Cancel, Email, LocationOn, Phone } from "@mui/icons-material";
import { useFormikContext } from "formik";
import { AppContext } from "../../../../../AppContext";

export default function ServiceProviderCard({ formType }) {
  const theme = useTheme();
  const { setFieldValue, values } = useFormikContext();

  const { user } = useContext(AppContext);

  const iconSize = "1.4rem";
  const selectedBusiness = user?.businessByOrg?.items.find(
    (b) => b.id === values?.orgBusinessId
  );

  return (
    <Box
      sx={{
        padding: "14px",
        border: "1px solid rgba(0,0,0,0.15)",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "185px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ fontSize: "1.2rem", fontWeight: 550 }}>
          {selectedBusiness?.name}
        </Typography>

        {formType === "insuranceClaim" && (
          <IconButton
            onClick={() => {
              setFieldValue("orgBusinessId", "");
            }}
          >
            <Cancel />
          </IconButton>
        )}
      </Box>
      <Divider sx={{ marginBottom: "10px" }} />
      <Stack direction={"column"} spacing={1}>
        <ContactInfo
          icon={<Phone sx={{ fontSize: iconSize }} />}
          text={selectedBusiness?.phone}
          visibility={selectedBusiness?.phone}
        />
        <ContactInfo
          icon={<Email sx={{ fontSize: iconSize }} />}
          text={selectedBusiness?.email}
          visibility={selectedBusiness?.email}
        />
        <ContactInfo
          icon={<LocationOn sx={{ fontSize: iconSize }} />}
          text={` ${selectedBusiness?.address}, ${selectedBusiness?.city}`}
          visibility={selectedBusiness?.address}
        />
      </Stack>
    </Box>
  );
}

function ContactInfo({ icon, text, visibility }) {
  if (visibility)
    return (
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgba(185,185,185,1)",
          }}
        >
          {icon}
        </Box>

        <Typography>{text}</Typography>
      </Stack>
    );
}

import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../../AppContext";
import { API, graphqlOperation } from "aws-amplify";
import { searchCustomers } from "../../../graphql/queries";

export function useSearchCustomerCompany() {
  const { user, business } = useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchCustomerCompany = useCallback(
    async (searchString, type) => {
      const searchField = type;
      const searchFragments = searchString
        .trim()
        .split(" ")
        .map((phrase, index, array) => ({
          [searchField]: {
            [index < array.length - 1 ? "matchPhrase" : "matchPhrasePrefix"]:
              phrase,
          },
        }));

      const userFilters = [];
      userFilters.push({
        or: [
          {
            and: [
              { organizationId: { eq: business?.organizationId } },
              { orgBusinessId: { exists: false } },
            ],
          },
          { orgBusinessId: { eq: business?.id } },
        ],
      });

      const clientTypeFilter = user?.externalBusinessId
        ? {
            clientType: { eq: "COMPANY" }, // Specify client types when necessary
          }
        : {
            clientType: { eq: "COMPANY" },
          };

      const notDeletedFilter = { isDeleted: { ne: true } };

      try {
        setLoading(true);
        const response = await API.graphql(
          graphqlOperation(searchCustomers, {
            filter: {
              and: [
                clientTypeFilter,
                notDeletedFilter,
                ...searchFragments,
                ...userFilters,
              ],
            },
          })
        );
        if (response?.data?.searchCustomers?.items) {
          setOptions(response.data.searchCustomers.items);
        } else {
          setOptions([]);
        }
        // return response.data.searchCustomers.items;
      } catch (error) {
        console.error(`Error searching ${type}s:`, error);
      } finally {
        setLoading(false);
      }
    },
    [business, user?.organizationId, user?.role]
  );

  return { searchCustomerCompany, options, loading };
}

/* 
 const fetchData = async (searchString, type) => {
        const loaderMap = {
          companyName: () => setCompanyNameLoading(true),
          companyBusinessId: () => setBusinessIdLoading(true),
        };
    
        const setterMap = {
          companyName: (data) => {
            setCompanyAutofillValues(data);
            setCompanyNameLoading(false);
          },
          companyBusinessId: (data) => {
            setCompanyBusinessIdAutofillValues(data);
            setBusinessIdLoading(false);
          },
        };
    
        loaderMap[type]();
    
        const searchField = type;
        let searchFragments = searchString
          .trim()
          .split(" ")
          .map((phrase, index, array) => ({
            [searchField]: {
              [index < array.length - 1 ? "matchPhrase" : "matchPhrasePrefix"]:
                phrase,
            },
          }));
    
        let userFilters = [];
        if (user.role === "BUSINESS_ADMIN") {
          userFilters.push({
            or: [
              { organizationId: { eq: user.organizationId } },
              { orgBusinessId: { eq: user.orgBusinessId } },
            ],
          });
        } else if (user.role === "ORGANIZATION_ADMIN") {
          userFilters.push({ organizationId: { eq: user.organizationId } });
        }
    
        const clientTypeFilter = {
          clientType: { eq: "COMPANY" }, // Specify client types when necessary
        };
    
        const notDeletedFilter = { isDeleted: { ne: true } };
    
        try {
          const response = await API.graphql(
            graphqlOperation(searchCustomersQuery, {
              filter: {
                and: [
                  clientTypeFilter,
                  notDeletedFilter,
                  ...searchFragments,
                  ...userFilters,
                ],
              },
            })
          );
          setterMap[type](response.data.searchCustomers.items);
        } catch (error) {
          console.error(`Error searching ${type}s:`, error);
          setterMap[type]([]); // Handle error by setting empty array for autofill values
        }
      };



*/

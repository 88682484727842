import { useContext, useMemo, useState, useEffect } from "react";
import { AppContext } from "../AppContext";

import {
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { API, graphqlOperation } from "aws-amplify";

import {
  DataGridPro,
  gridFilteredDescendantCountLookupSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";

import { ChevronRight } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React from "react";
import { useFetchCompanies } from "../services/fetchCompanies";
import {
  columnsExternalBusinessAdmin,
  defaultColumns,
} from "./EconomyDialog/components/DataGridColumns/defaultColumns";
import { hanselColumns } from "./EconomyDialog/components/DataGridColumns/hanselColumns";
import { FilterOptions } from "./EconomyDialog/components/Filters/FilterOptions";
import { defaultRows } from "./EconomyDialog/components/DataGridRows/defaultRows";
import { filteringOptions } from "./EconomyDialog/components/utils";
import { hanselRows } from "./EconomyDialog/components/DataGridRows/hanselRows";
import { TbLetterH } from "react-icons/tb";
import { FaStar } from "react-icons/fa";
import { searchCustomers as searchCustomersQuery } from "../graphql/queries";
import { format, isValid } from "date-fns";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../atoms/reservationDataAtom";

export default function ReservationTable({
  visibility,
  economyReservations,
  reservationsLoading,
}) {
  const {
    business,
    company,
    showAllCompanies,
    USER_ADMIN_CORPORATE,
    USER_ADMIN_GENERIC,
    user,
    economyQueryDates,
  } = useContext(AppContext);

  const { companies } = useFetchCompanies(user?.group, business?.id);
  const reservations = economyReservations;
  const theme = useTheme();
  const [providerFilter, setProviderFilter] = useState(""); // filter results by provider
  const [showProducts, setShowProducts] = useState(0);
  const [activeFilter, setActiveFilter] = useState(null); // filter results by payment status
  const [selectedInsuranceCompany, setSelectedInsuranceCompany] = useState("");
  const [selectedBrokerCustomer, setSelectedBrokerCustomer] = useState([]);
  const [brokers, setBrokers] = useState();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  useEffect(() => {
    const fetchBrokers = async () => {
      try {
        let filter = {
          and: [{ clientType: { eq: "BROKER" } }, { isDeleted: { ne: true } }],
        };

        if (user.role === "BUSINESS_ADMIN") {
          filter.and.push({
            or: [
              { organizationId: { eq: user.organizationId } },
              { orgBusinessId: { eq: business.id } },
            ],
          });
        } else if (user.role === "ORGANIZATION_ADMIN") {
          filter.and.push({ organizationId: { eq: user.organizationId } });
        }

        const response = await API.graphql(
          graphqlOperation(searchCustomersQuery, { filter })
        );
        const fetchedBrokers = response.data.searchCustomers.items;

        setBrokers(
          fetchedBrokers.sort((a, b) =>
            a.companyName.localeCompare(b.companyName, "fi")
          )
        );
      } catch (error) {
        console.error("Error fetching agents:", error);
      }
    };

    fetchBrokers();
  }, []);

  const disableTreeData = Boolean(
    providerFilter === "resultsByHanselReservations"
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    type: false,
    authStatus: false,
    company: user?.organizationId ? false : true,
    channel: false,
    vehicleCategoryName: false,
  });

  const handleOpenReservation = (id) => {
    const reservation = reservations.find((res) => res.id === id);

    if (reservation) {
      setEditReservationData(reservation);
    }
  };

  const columns = useMemo(() => {
    if (USER_ADMIN_GENERIC) {
      if (providerFilter === "resultsByHanselReservations") {
        return hanselColumns;
      }
      return defaultColumns;
    }

    if (USER_ADMIN_CORPORATE) {
      return columnsExternalBusinessAdmin;
    }
    return defaultColumns;
  }, [USER_ADMIN_CORPORATE, USER_ADMIN_GENERIC, providerFilter]);

  /*
  reservationData is filtered by the paymentStatus.
  CANCELLED
  PENDING
  PENDING_FULL
  PENDING_INVOICE # Invoicing to be done
  COMPLETED_DEPOSIT
  COMPLETED_MANUAL
  COMPLETED_INVOICE # Invoice succesfully sent
  COMPLETED # Online payment completed

  */

  const reservationsByCompany = showAllCompanies
    ? reservations
    : reservations.filter(
        (reservation) =>
          reservation?.companyId === company?.id &&
          reservation?.status !== "EXTERNAL_PENDING_DEALER"
      );

  const reservationsData = useMemo(() => {
    let results = reservations;
    if (!showAllCompanies) {
      results = results.filter(
        (reservation) =>
          reservation?.companyId === company?.id &&
          reservation?.status !== "EXTERNAL_PENDING_DEALER"
      );
    }

    if (USER_ADMIN_GENERIC && filteringOptions[activeFilter]) {
      results = results.filter(filteringOptions[activeFilter]);
    }

    if (EFiltersList[providerFilter]) {
      let filterFunc = EFiltersList[providerFilter].filter;
      let filterValue =
        providerFilter === "resultsByBrokerCustomer"
          ? selectedBrokerCustomer
          : selectedInsuranceCompany;

      if (providerFilter === "resultsByBrokerCustomer") {
        // Check if any brokers are selected; if not, skip filtering by brokers
        if (filterValue.length > 0) {
          results = results.filter(
            (reservation) =>
              reservation.customerBrokerId &&
              filterValue.includes(reservation.customerBrokerId)
          );
        } else {
          // If no brokers are selected and filterValue is empty, filter out reservations without a customerBrokerId
          results = results.filter(
            (reservation) => reservation.customerBrokerId
          );
        }
      } else {
        results = results.filter((reservation) =>
          filterFunc(reservation, filterValue)
        );
      }
    }

    return results;
  }, [
    reservations,
    showAllCompanies,
    company?.id,
    USER_ADMIN_GENERIC,
    activeFilter,
    providerFilter,
    selectedInsuranceCompany,
    selectedBrokerCustomer,
  ]);

  const rows = useMemo(() => {
    if (!reservationsData) {
      return [];
    }
    if (providerFilter === "resultsByHanselReservations") {
      return hanselRows(reservationsData, companies);
    }
    return defaultRows(reservationsData, companies, setEditReservationData);
  }, [reservationsData, companies, providerFilter, setEditReservationData]);
  const getTreeDataPath = (row) => row?.hierarchy;

  function CustomGridTreeDataGroupingCell(props) {
    const { id, field, rowNode, row } = props;

    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event) => {
      if (rowNode.type !== "group") {
        return;
      }

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          {filteredDescendantCount > 0 ? (
            <>
              {rowNode.childrenExpanded ? (
                <IconButton onClick={handleClick} tabIndex={-1}>
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleClick} tabIndex={-1}>
                  <ChevronRight />
                </IconButton>
              )}
            </>
          ) : null}
        </Box>
      </Box>
    );
  }

  const groupingColDef = {
    headerName: "Hierarchy",
    width: 120,
    renderHeader: () => (
      <FormControlLabel
        control={
          <Switch
            checked={!!showProducts}
            onChange={() =>
              setShowProducts((prevState) => (!prevState ? 1 : 0))
            }
          />
        }
        // label={<Typography variant="caption">Näytä kaikki tuotteet</Typography>}
        sx={{ marginLeft: "1px" }}
      />
    ),
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  if (visibility)
    return (
      <>
        {USER_ADMIN_GENERIC && (
          <>
            <Stack
              justifyContent={"center"}
              direction={"row"}
              spacing={2}
              sx={{ padding: "5px", marginBottom: "10px" }}
              alignItems={"center"}
            >
              <FilterOptions
                visibility={USER_ADMIN_GENERIC}
                reservationsByCompany={reservationsByCompany}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
            </Stack>
            <Divider variant="middle" />
            <Stack direction={"row"}>
              <ProviderFilter
                providerFilter={providerFilter}
                setProviderFilter={setProviderFilter}
                visibility={user?.organizationId}
              />
              <FilterByInsuranceCompany
                insuranceCompanies={user?.externalBusinessByOrg?.items.filter(
                  (item) => item.type === "INSURANCE"
                )}
                visibility={
                  EFiltersList[providerFilter]?.id ===
                  EFilters.resultsByInsuranceCompanies
                }
                selectedInsuranceCompany={selectedInsuranceCompany}
                setSelectedInsuranceCompany={setSelectedInsuranceCompany}
              />
              <FilterByBrokerCustomer
                brokers={brokers}
                visibility={
                  EFiltersList[providerFilter]?.id ===
                  EFilters.resultsByBrokerCustomer
                }
                selectedBrokerCustomer={selectedBrokerCustomer}
                setSelectedBrokerCustomer={setSelectedBrokerCustomer}
              />
            </Stack>
          </>
        )}
        <DataGridPro
          treeData={!disableTreeData}
          getTreeDataPath={!disableTreeData ? getTreeDataPath : () => []}
          sx={{
            height: 0,
            flexGrow: 1,
            borderRadius: "14px",
            border: "none",
            overflow: "auto",
            maxWidth: "100%",
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
            },
          }}
          loading={reservationsLoading}
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[2]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          defaultGroupingExpansionDepth={showProducts}
          groupingColDef={groupingColDef}
          disableRowSelectionOnClick
          slots={{ toolbar: USER_ADMIN_GENERIC && GridToolbar }}
          columnVisibilityModel={columnVisibilityModel}
          onRowDoubleClick={(row) => handleOpenReservation(row.id)}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </>
    );
}

function ProviderFilter(props) {
  const { providerFilter, setProviderFilter, visibility } = props;
  const handleChange = (event) => {
    if (event.target.value === "all-results") {
      setProviderFilter("");
      return;
    } else {
      setProviderFilter(event.target.value);
    }
  };

  if (visibility)
    return (
      <Stack direction={"row"}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="filter-by-provider-label">
            Suodata tulokset
          </InputLabel>
          <Select
            labelId="filter-by-provider-label"
            id="filter-by-provider"
            value={providerFilter}
            onChange={handleChange}
            size="small"
            input={
              <OutlinedInput
                id="select-multiple-filters"
                label="Suodata tulokset"
              />
            }
            renderValue={(selected) => {
              return filters.find((filter) => filter.id === selected)?.name;
            }}
            MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
          >
            {filters.map((filter) => (
              <MenuItem key={filter.id} value={filter.id}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
}

const FilterByBrokerCustomer = ({
  brokers,
  visibility,
  selectedBrokerCustomer,
  setSelectedBrokerCustomer,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBrokerCustomer(
      typeof value === "string" ? value.split(",") : value
    );
  };

  if (visibility)
    return (
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="filter-by-broker-customer-label">Agentit</InputLabel>
        <Select
          labelId="filter-by-broker-customer-label"
          id="filter-by-broker-customer"
          multiple
          value={selectedBrokerCustomer}
          onChange={handleChange}
          input={<OutlinedInput label="Agentit" />}
          size="small"
          renderValue={(selected) =>
            selected
              .map(
                (id) => brokers.find((broker) => broker.id === id)?.companyName
              )
              .join(", ")
          }
          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
        >
          {brokers.map((broker) => (
            <MenuItem key={broker.id} value={broker.id}>
              <Checkbox
                checked={selectedBrokerCustomer.indexOf(broker.id) > -1}
              />
              <ListItemText primary={broker.companyName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};

const FilterByInsuranceCompany = ({
  insuranceCompanies,
  visibility,
  selectedInsuranceCompany,
  setSelectedInsuranceCompany,
}) => {
  const handleChange = (event) => {
    if (event.target.value === "all-results") {
      setSelectedInsuranceCompany("");
      return;
    } else {
      setSelectedInsuranceCompany(event.target.value);
    }
  };

  if (visibility)
    return (
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="filter-by-insurance-company-label">
          Vakuutusyhtiöt
        </InputLabel>
        <Select
          labelId="filter-by-insurance-company-label"
          id="filter-by-insurance-company"
          value={selectedInsuranceCompany}
          onChange={handleChange}
          size="small"
          input={
            <OutlinedInput
              id="select-insurance-company"
              label="Vakuutusyhtiöt"
            />
          }
          renderValue={(selected) => {
            return insuranceCompanies.find((filter) => filter.id === selected)
              ?.name;
          }}
          MenuProps={{ PaperProps: { style: { maxHeight: "300px" } } }}
        >
          <MenuItem value="all-results">Kaikki yhtiöt</MenuItem>
          {insuranceCompanies.map((company) => (
            <MenuItem key={company.id} value={company.id}>
              <ListItemText primary={company.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
};

const EFilters = {
  allResults: "all-results",
  resultsByHanseReservations: "resultsByHanselReservations",
  resultsByOnlineReservations: "resultsByOnlineReservations",
  resultsByAutoliitto: "resultsByAutoliitto",
  resultsByInsuranceCompanies: "resultsByInsuranceCompanies",
  resultsByBrokerCustomer: "resultsByBrokerCustomer",
};

const filters = [
  {
    id: EFilters.allResults,
    name: "Kaikki",
    icon: <TbLetterH />,
    iconColor: "darkcyan",
  },
  {
    id: EFilters.resultsByBrokerCustomer,
    name: "Agentti",
    icon: <TbLetterH />,
    iconColor: "darkcyan",
  },
  {
    id: EFilters.resultsByHanseReservations,
    name: "Hansel",
    icon: <TbLetterH />,
    iconColor: "darkcyan",
  },
  {
    id: EFilters.resultsByOnlineReservations,
    name: "Online",
    icon: <FaStar />,
    iconColor: "#FFD700",
    value: undefined,
    amount: undefined,
  },
  {
    id: EFilters.resultsByAutoliitto,
    name: "Autoliitto",
    icon: <FaStar />,
    iconColor: "#FFD700",
    value: undefined,
    amount: undefined,
  },

  {
    id: EFilters.resultsByInsuranceCompanies,
    name: "Vakuutusyhtiö",
    icon: <FaStar />,
    iconColor: "#FFD700",
    value: undefined,
    amount: undefined,
  },
];

const EFiltersList = {
  [EFilters.resultsByBrokerCustomer]: {
    id: "resultsByBrokerCustomer",
    name: "Agentti",
    icon: "TbLetterH",
    iconColor: "darkcyan  ",
    filter: (reservation, id) => {
      if (id) {
        return reservation?.customerBrokerId === id;
      } else {
        return !!reservation?.customerBrokerId;
      }
    },
  },
  [EFilters.resultsByHanseReservations]: {
    id: "resultsByHanselReservations",
    name: "Hansel",
    icon: "TbLetterH",
    iconColor: "darkcyan  ",
    filter: (reservation) => reservation?.type === "HANSEL",
  },
  [EFilters.resultsByOnlineReservations]: {
    id: "resultsByOnlineReservations",
    name: "Tilauskanava: Online varaukset",
    icon: "FaStar",
    iconColor: "#FFD700",
    filter: (reservation) => reservation?.channel === "CUSTOMER",
  },
  [EFilters.resultsByAutoliitto]: {
    id: "resultsByAutoliitto",
    name: "Autoliitto",
    icon: "FaStar",
    iconColor: "#FFD700",
    filter: (reservation) => reservation?.channel === "AUTOLIITTO",
  },
  [EFilters.resultsByInsuranceCompanies]: {
    id: "resultsByInsuranceCompanies",
    name: "Vakuutusyhtiö",
    icon: "FaStar",
    iconColor: "#FFD700",
    filter: (reservation, id) => {
      if (id) {
        return reservation?.orgExternalBusinessId === id;
      } else {
        return reservation?.type === "INSURANCE";
      }
    },
  },
};

import styles from "./styles.module.scss";

function GridBg() {
  return (
    <div className={styles.root}>
      {Array(24)
        .fill(undefined)
        .map((_, i) => (
          <div key={i} style={{ height: 1, backgroundColor: "#f0f0f0" }} />
        ))}
    </div>
  );
}

export default GridBg;

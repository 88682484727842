import { useFetchVehicles } from "../../services/fetchVehicles";
const useGetVehicleObjById = (id) => {
  const { vehicleData } = useFetchVehicles();
  if (!vehicleData) return null;
  // if (!id) return null;

  const vehicleObj = vehicleData.find((v) => v.id === id);

  return vehicleObj;
};

export default useGetVehicleObjById;

import React from "react";
import { useFormikContext } from "formik";
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { useAtomValue } from "jotai";

function PaymentMethodSelect() {
  const { values, setFieldValue } = useFormikContext();
  const reservationData = useAtomValue(reservationDataAtom);

  if (
    ["COMPLETED", "COMPLETED_DEPOSIT", "PENDING_FULL", "PENDING"].includes(
      reservationData?.paymentStatus
    )
  ) {
    return null; // Payment status controlled by external process, no interaction needed
  }

  if (values.sendStatusToCustomer) {
    return "Lähetetään asiakkaalle täydennettäväksi, asiakas valitsee maksutavan";
  }

  const handleCheckboxChange = (event) => {
    const newPaymentMethod = event.target.value;
    if (values.paymentStatus === newPaymentMethod) {
      setFieldValue("paymentStatus", ""); // Uncheck and clear the field if the same checkbox was clicked
    } else {
      setFieldValue("paymentStatus", newPaymentMethod); // Set new payment method, automatically unchecking the previous
    }
  };

  // Function to generate checkbox properties
  const checkboxProps = (method) => ({
    checked: values.paymentStatus === method,
    onChange: handleCheckboxChange,
    value: method,
    sx: { py: 0 },
  });

  return (
    <FormControl fullWidth>
      <FormLabel component="legend">Maksutapa</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...checkboxProps("")} />}
          label="Noudettaessa"
        />
        <FormControlLabel
          control={<Checkbox {...checkboxProps("PENDING_INVOICE")} />}
          label="Laskutettava"
        />
        <FormControlLabel
          control={<Checkbox {...checkboxProps("COMPLETED_INVOICE")} />}
          label="Laskutettu"
        />
        <FormControlLabel
          control={<Checkbox {...checkboxProps("COMPLETED_MANUAL")} />}
          label="Maksettu"
        />
      </FormGroup>
    </FormControl>
  );
}

export default PaymentMethodSelect;

import { GraphQLResult } from "@aws-amplify/api";
import { API, graphqlOperation } from "aws-amplify";
import useSWR from "swr";
import { useContext } from "react";
//@ts-ignore
import { AppContext } from "~/AppContext";
import { searchArchiveReservations } from "~/graphql/queries";
import {
  ArchiveReservation,
  Reservation,
  SearchArchiveReservationsQuery,
  SearchReservationsQueryVariables,
  SearchableArchiveReservationFilterInput,
} from "~/graphql/API";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { IconButton } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
//@ts-ignore
import { getZonedTime } from "~/utils/common";
import { formatDateTime } from "~/utils/date";
//@ts-ignore
import { calculateReservationTotalPrice } from "./components/utils";
//@ts-ignore
import { centsToLocalString } from "~/shared/money";
import { useGetBrokers } from "~/hooks/useGetBrokers";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { useSetAtom } from "jotai";

const generateFilters = (
  searchType: string,
  start: string,
  end: string,
  orgBusinessId: string
) => {
  const filters: SearchableArchiveReservationFilterInput = {
    orgBusinessId: { eq: orgBusinessId },
  };
  if (searchType === "startAt") {
    filters.or = [{ startTime: { range: [start, end] } }];
  } else if (searchType === "createdAt") {
    filters.or = [{ reservationCreatedAt: { range: [start, end] } }];
  } else if (searchType === "endsAt") {
    filters.or = [{ returnTime: { range: [start, end] } }];
  }
  return filters;
};

type Dates = [string, string];
type SearchType = "startAt" | "createdAt" | "endsAt";

type ReservationExtended = Reservation &
  Pick<ArchiveReservation, "archivedAt" | "archiveMessage"> & {
    // Additional fields if needed
  };

const getZonedTimes = (
  reservation: Reservation,
  archivedAt: string,
  timezone: string
) => {
  const getZonedDateTime = (date?: string | null) => {
    return getZonedTime(date, timezone)?.toISOString();
  };

  const { startTime, returnTime, endTime } = reservation;
  return {
    startTime: getZonedDateTime(startTime),
    returnTime: getZonedDateTime(returnTime),
    endTime: getZonedDateTime(endTime),
    archivedAt: getZonedDateTime(archivedAt),
  };
};

const useFetchArchiveReservations = (searchType: SearchType, dates: Dates) => {
  const start = dates?.[0];
  const end = dates?.[1];
  //@ts-ignore
  const { business, timezone } = useContext(AppContext);

  // Fetcher function for SWR
  const fetcher = async (fetcherArgs: {
    query: string;
    variables: SearchReservationsQueryVariables;
  }) => {
    try {
      const { query, variables } = fetcherArgs;
      console.debug("Run fetcher", { fetcherArgs }, { query }, { variables });
      const response = (await API.graphql(
        graphqlOperation(query, variables)
      )) as GraphQLResult<SearchArchiveReservationsQuery>;
      console.debug("Fetcher after API call", { response });

      const archiveReservations = (
        response.data?.searchArchiveReservations?.items || []
      ).filter(Boolean) as ArchiveReservation[];

      const reservations: ReservationExtended[] = archiveReservations.map(
        (ar) => {
          const reservationJSON = JSON.parse(ar.reservationJSON) as Reservation;
          const zonedTimes = getZonedTimes(
            reservationJSON,
            ar.archivedAt,
            timezone
          );
          reservationJSON.startTime = zonedTimes.startTime;
          reservationJSON.returnTime = zonedTimes.returnTime;
          reservationJSON.endTime = zonedTimes.endTime;
          return {
            ...reservationJSON,
            archivedAt: zonedTimes.archivedAt,
            archiveMessage: ar.archiveMessage,
          };
        }
      );
      console.debug("return reservations", { reservations });

      return reservations;
    } catch (e) {
      console.error("searchArchiveReservations failed", e);
      throw new Error("searchArchiveReservations failed");
    }
  };

  // Only fetch if start, end, and business exist
  const shouldFetch = !!(start && end && business);
  const variables: SearchReservationsQueryVariables = {}; // Filters etc.
  variables.filter = generateFilters(searchType, start, end, business.id);
  const key = shouldFetch
    ? { query: searchArchiveReservations, variables }
    : null;

  // SWR for data fetching
  const { data, error, isLoading, isValidating } = useSWR(key, fetcher);

  console.debug("swr", data, error, isLoading, isValidating);

  return {
    data,
    isLoading: isLoading || isValidating,
    error: error,
  };
};

const dateTimeGetter = (date?: string | null | undefined) => {
  if (!date) return "";
  return formatDateTime(date);
};

type ReservationArchiveTableProps = {
  searchType: SearchType;
  economyQueryDates: Dates;
};

const ReservationArchiveTable = ({
  searchType,
  economyQueryDates,
}: ReservationArchiveTableProps) => {
  const {
    data: archiveReservations,
    error,
    isLoading,
  } = useFetchArchiveReservations(searchType, economyQueryDates);
  const { data: brokers } = useGetBrokers();
  console.debug("data received", archiveReservations);
  const setEditReservationData = useSetAtom(reservationDataAtom);

  // Define the columns for the DataGrid
  const columns: GridColDef<ReservationExtended>[] = [
    {
      field: "varaus",
      headerName: "",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setEditReservationData(params.row);
            }}
          >
            <OpenInNew color="success" />
          </IconButton>
        );
      },
    },
    {
      field: "startTime",
      headerName: "Aloitus",
      width: 150,
      valueGetter: (params) => params.row?.startTime,
      valueFormatter: (params) => dateTimeGetter(params?.value),
    },
    {
      field: "endTime",
      headerName: "Lopetus",
      width: 150,
      valueGetter: (params) => params.row?.endTime,
      valueFormatter: (params) => dateTimeGetter(params.value),
    },
    {
      field: "registrationPlate",
      headerName: "Rekisterinumero",
      width: 150,
      valueGetter: (params) =>
        params.row.reservationVehicles?.[0]?.registrationPlate,
    },
    {
      field: "vehicleName",
      headerName: "Tuote",
      width: 200,
      valueGetter: (params) => params.row.reservationVehicles?.[0]?.name,
    },
    {
      field: "totalPrice",
      headerName: "Kokonaissumma",
      width: 200,
      valueGetter: (params) => calculateReservationTotalPrice(params.row),
      valueFormatter: (params) => `${centsToLocalString(params?.value)} €`,
    },
    {
      field: "name",
      headerName: "Asiakkaan nimi",
      width: 200,
      valueGetter: (params) => params.row?.name,
    },
    {
      field: "broker",
      headerName: "Välittäjä",
      width: 200,
      valueGetter: (params) =>
        brokers?.find((b) => b.id === params.row?.customerBrokerId)
          ?.brokerName ?? "",
    },
    {
      field: "archivedAt",
      headerName: "Peruutusaika",
      width: 200,
      valueGetter: (params) => params.row?.archivedAt,
      valueFormatter: (params) => dateTimeGetter(params?.value),
    },
    {
      field: "archiveMessage",
      headerName: "Peruutuksen syy",
      width: 200,
      valueGetter: (params) => params.row?.archiveMessage,
    },
  ];

  if (error) return <div>Haussa tapahtui virhe</div>;

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGridPro
        loading={isLoading}
        rows={archiveReservations ?? []}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default ReservationArchiveTable;

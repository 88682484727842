import React, { useState, useContext, useMemo } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  ListItem,
  List,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AppContext } from "../../AppContext";
import {
  removeTelegramConnection,
  fetchTelegramConnections,
} from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { useNotification } from "../Notification";
import DialogComponent from "../DialogComponent";

function TelegramConnection({ connection, removeConnection, loading }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <Card sx={{ borderRadius: 1, mr: 1, minWidth: 160 }}>
      <CardContent>
        <Typography>Nimi: {connection.name}</Typography>{" "}
      </CardContent>
      <CardActions>
        <Button
          onClick={() => setShowDeleteConfirmation(true)}
          //onClick={() => removeConnection(connection.chatId)}
          disabled={loading}
        >
          Poista linkitys
        </Button>
      </CardActions>
      <DialogComponent
        open={showDeleteConfirmation}
        dialogClose={() => setShowDeleteConfirmation(false)}
        dialogAction={() => removeConnection(connection.chatId)}
        dialogActionText={"Poista"}
        dialogActionColor={"error"}
        dialogActionSubmitting={loading}
      >
        Haluatko varmasti poistaa linkityksen?
      </DialogComponent>
    </Card>
  );
}

function TelegramConnections({ business, removeConnection, loading }) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography fontWeight="bold">Linkitetyt käyttäjät</Typography>{" "}
      <Box sx={{ display: "flex" }}>
        {business.telegramConnections?.map((connection) => (
          <TelegramConnection
            key={connection.chatId}
            connection={connection}
            removeConnection={removeConnection}
            loading={loading}
          />
        ))}
      </Box>
    </Box>
  );
}

const TelegramMessageSettings = () => {
  const { business, setUser, selectedBusiness, setSelectedBusiness } =
    useContext(AppContext);
  const notification = useNotification();

  const businessId = useMemo(() => {
    return business?.id;
  }, [business]);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCopy = () => {
    if (businessId) {
      navigator.clipboard.writeText(businessId).then(() => {
        setCopySnackbarOpen(true);
      });
    }
  };

  const handleInitiateLink = async () => {
    setLoading(true);
    try {
      const response = (
        await API.graphql(
          graphqlOperation(fetchTelegramConnections, { businessId })
        )
      ).data.fetchTelegramConnections;

      if (response && response.newConnectionsCount > 0) {
        console.log("old: ", business.telegramConnections);
        console.log("new: ", response.connections);
        // Update connections
        setUser((prevUser) => {
          return {
            ...prevUser,
            businessByOrg: {
              ...prevUser.businessByOrg,
              items: prevUser.businessByOrg.items.map((business) => {
                // Update correct business with new connections
                if (business.id !== businessId) return business;
                return {
                  ...business,
                  telegramConnections: response.connections,
                };
              }),
            },
          };
        });
        //
        if (selectedBusiness) {
          setSelectedBusiness((prevBusiness) => {
            return {
              ...prevBusiness,
              telegramConnections: response.connections,
            };
          });
        }
        setMessage(response.message);
        setMessageType("success");
      } else {
        setMessage(response.message);
        setMessageType("error");
      }
    } catch (error) {
      console.log("error: ", error);
      setMessage("Jokin meni pieleen, ota yhteys tukeen.");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const removeConnection = async (chatId) => {
    setLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(removeTelegramConnection, {
          businessId,
          chatId,
        })
      );

      if (response.data.removeTelegramConnection) {
        notification.show("Linkitys poistettu", "success");
        setUser((prevUser) => {
          return {
            ...prevUser,
            businessByOrg: {
              ...prevUser.businessByOrg,
              items: prevUser.businessByOrg.items.map((b) => {
                // Update correct business with new connections
                if (b.id !== businessId) return b;
                return {
                  ...b,
                  telegramConnections: b.telegramConnections.filter(
                    (connection) => connection.chatId !== chatId
                  ),
                };
              }),
            },
          };
        });
        if (selectedBusiness) {
          setSelectedBusiness((prevBusiness) => {
            return {
              ...prevBusiness,
              telegramConnections: prevBusiness.telegramConnections.filter(
                (connection) => connection.chatId !== chatId
              ),
            };
          });
        }
      } else {
        notification.show("Linkityksen poisto epäonnistui", "error");
        //setMessage("Linkityksen peruuttaminen epäonnistui.");
        //setMessageType("error");
      }
    } catch (error) {
      notification.show("Jokin meni pieleen, ota yhteys tukeen.", "error");
      setMessage("Jokin meni pieleen. Yritä myöhemmin uudelleen.");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Telegramin ilmoitusten asetus:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Ohjeet:</Typography>
        <List>
          <ListItem>1. Kopioi yrityksen ID</ListItem>
          <TextField
            label="Yrityksen ID"
            variant="outlined"
            value={businessId}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <>
                  <IconButton onClick={handleCopy} color="primary">
                    <ContentCopyIcon />
                  </IconButton>
                </>
              ),
            }}
          />
          <ListItem>
            2. Avaa&nbsp;
            <a
              target="_blank"
              href="https://web.telegram.org/k/#@uRent_Messager_bot"
            >
              linkki
            </a>
          </ListItem>
          <ListItem>3. Aloita keskustelu botin kanssa.</ListItem>
          <ListItem>4. Syötä yrityksen ID viestinä botille.</ListItem>
          <ListItem>
            <Typography variant="body1">
              5. Linkitä telegram painamalla alla olevasta&nbsp;
              <span style={{ fontWeight: "bold" }}>Lisää linkitys</span>
              -napista.
            </Typography>
          </ListItem>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={handleInitiateLink}
                  disabled={loading}
                  fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : "Lisää linkitys"}
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
      {message && (
        <Grid item ml={2} xs={12}>
          <Typography
            variant="body1"
            style={{ color: messageType === "success" ? "green" : "red" }}
          >
            {message}
          </Typography>
        </Grid>
      )}
      <Grid container item xs={12}>
        <TelegramConnections
          business={business}
          removeConnection={removeConnection}
          loading={loading}
        />
      </Grid>
      <Snackbar
        message="ID kopioitu leikepöydälle"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setCopySnackbarOpen(false)}
        open={copySnackbarOpen}
      />
    </Grid>
  );
};

export default TelegramMessageSettings;

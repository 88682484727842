import { Box, Typography } from "@mui/material";
import FormikInputText from "~/components/Inputs/FormikInputText";
import { Invoice } from "~/graphql/API";

type CustomerDetailsProps = { invoice: Invoice };

function CustomerDetails({ invoice }: CustomerDetailsProps) {
  const customerType = invoice.customer.customer_type;

  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 1 }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Asiakkaan tiedot -{" "}
          {customerType === "person" ? "Kuluttaja" : "Yritys"}
        </Typography>

        <Typography
          sx={{
            textAlign: "right",
            fontFamily: "Sofia Pro",
            fontSize: 20,
          }}
        >
          Innovoice ID: {invoice.innovoiceId}
        </Typography>
      </Box>

      {customerType === "person" ? (
        <>
          <FormikInputText
            label="Etunimi"
            readOnly
            value={invoice.customer.firstname || ""}
          />
          <FormikInputText
            label="Sukunimi"
            readOnly
            value={invoice.customer.lastname || ""}
          />
          <FormikInputText
            label="Henkilötunnus"
            readOnly
            value={invoice.customer.ssn || ""}
          />
        </>
      ) : (
        <>
          <FormikInputText
            label="Yrityksen nimi"
            readOnly
            value={invoice.customer.company_name || ""}
          />
          <FormikInputText
            name="companyBusinessId"
            label="Y-tunnus"
            readOnly
            value={invoice.innovoiceCompanyId || ""}
          />
          <FormikInputText
            label="Yhteyshenkilö"
            readOnly
            value={invoice.customer.contact_person || ""}
          />
        </>
      )}
      <FormikInputText
        name="address"
        label="Osoite"
        readOnly
        value={invoice.customer.address || ""}
      />
      <FormikInputText
        label="Postinumero"
        readOnly
        value={invoice.customer.zipcode || ""}
      />
      <FormikInputText
        name="city"
        label="Postitoimipaikka"
        readOnly
        value={invoice.customer.city || ""}
      />
      <FormikInputText
        label="Sähköposti"
        readOnly
        value={invoice.customer.email || ""}
      />
      <FormikInputText
        label="Puhelin"
        readOnly
        value={invoice.customer.phone || ""}
      />
    </Box>
  );
}

export default CustomerDetails;

import ReservationCard from "../ReservationCard";
import { differenceInHours, startOfDay } from "date-fns";
import { useContext } from "react";
import { RowContext } from "../VehicleRow/RowContext";
import styles from "./styles.module.scss";
import { Reservation } from "~/graphql/API";
import { useHandleContextMenu } from "../ContextMenu/hooks/useHandleContextMenu";
import ReservationCardLabel from "../ReservationCardLabel";
import ReservationTooltip from "../ReservationCard/components/ReservationTooltip";

type ReservationCardHorizontalProps = {
  reservation: Reservation;
  hourOffset: number;
};

function ReservationCardHorizontal({
  reservation,
  hourOffset,
}: ReservationCardHorizontalProps) {
  const { vehicle } = useContext(RowContext);
  const { handleContextMenu } = useHandleContextMenu();

  const startTime = reservation.startTime as unknown as Date;
  const dayStart = startOfDay(startTime);

  return (
    <div
      className={styles.root}
      style={{
        gridColumnStart: hourOffset
          ? 1
          : differenceInHours(startTime, dayStart) + 1,
      }}
    >
      <ReservationTooltip reservation={reservation} vehicle={vehicle}>
        <ReservationCard
          direction="horizontal"
          reservation={reservation}
          hourOffset={hourOffset}
          onContextMenu={handleContextMenu({ reservation, vehicle })}
          data-offset={hourOffset > 1 ? hourOffset : undefined}
          data-vehicle={vehicle ? "" : undefined}
          className={styles.reservation}
        >
          <ReservationCardLabel reservation={reservation} vehicle={vehicle} />
        </ReservationCard>
      </ReservationTooltip>
    </div>
  );
}

export default ReservationCardHorizontal;

const scandiaRelatedOrgs = [
  "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", //Scandia Rent
  "c98555b2-980e-44b3-b95d-ebb4cd21d4ac", // Staging Scandia rent
  "d90a0808-7f16-41af-a374-0244e5fe9df1", // dev organization
  "9e615947-75c4-44cf-bd89-fd4917d83a11", // another dev organization
];

export const showExportLicenseService = (env, group) => {
  if (env === "local") return true;
  const featureGroups = ["kuopionpakut", "vuokraapaku"];
  const enabled = featureGroups.includes(group);
  return enabled;
};

export const enableVismaSign = (env, group) => {
  // Visma Sign not in use
  return false;
  /*   if (env === "local" || env === "staging") {
    return true;
  }
  try {
    const featureGroups = import.meta.env.REACT_APP_FEATURE_SIGN.split(" ");
    const enabled = featureGroups.includes(group);
    return enabled;
  } catch (e) {
    console.error(e);
    return false;
  } */
};

export const enableDynamicPricing = (env, group) => {
  if (env === "local" || env === "staging") {
    return true;
  }
  try {
    const featureGroups =
      import.meta.env.REACT_APP_FEATURE_DYNAMIC_PRICING.split(" ");
    const enabled = featureGroups.includes(group);
    return enabled;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const showEconomyDialog = (env, company) => {
  if (env === "local" || env === "dev" || env === "staging") {
    return true;
  }
  if (company?.id === "760ebb6e-ab28-4b81-863c-21b92199198a") return true;
  return false;
};

export const development = (env) => {
  if (env === "local" || env === "dev") {
    return true;
  }
};

export const devStaging = (env) => {
  if (env === "local" || env === "dev" || env === "staging") {
    return true;
  }
};

export const showSearchReservations = (env, company) => {
  return true; // Allow for everyone
  /* if (env === "local" || env === "dev" || env === "staging") {
    return true;
  } */
  //  return false;
};

export const enableCustomBikeContract = (group) => {
  if (group === "ebikerental" || group === "elämysristeilyt") return true;
  return false;
};

export const enableJetContract = (group) => {
  if (
    group === "jyrkila" ||
    group === "greattime" ||
    group === "jetrent" ||
    group === "jettirentti" ||
    group === "majakkawatersportslohja" ||
    group === "majakkawatersports"
  )
    return true;
  return false;
};

export const showChangeCompanyFeat = (env, group) => {
  if (env === "local" || env === "dev" || env === "staging") {
    return true;
  }
  if (group === "vuokraapaku" || group === "kuopionpakut") {
    return true;
  }
  return false;
};

export const isOrganizationUser = (user) => {
  if (user?.organizationId) return true;
  return false;
};

export const enableNPSfeature = (company, organization) => {
  if (organization?.features?.enableNPS) return true;
  if (company?.features?.enableNPS) return true;
  return false;
};

export const enableContractCustomers = (user) => {
  if (!user?.organizationId || user?.externalBusinessId) return false;

  return true;
};

export const enableVehicleDamageMap = (userEntity, typeId) => {
  const allowedEntities = scandiaRelatedOrgs;

  const allowedTypeIds = [
    "product-type-vans",
    "product-type-jetskis",
    "product-type-cars",
    "product-type-minibus",
    "product-type-rvs",
    "product-type-bicycles",
    "product-type-trailers",
    "product-type-trailer-enclosed",
    "product-type-campers",
    "product-type-snowmobiles",
    "product-type-trailer-open",
    // Add any other allowed typeIds here
  ];

  return (
    allowedEntities.includes(userEntity) && allowedTypeIds.includes(typeId)
  );
};

export const enableNewPaymentTab = (userEntity) => {
  const allowedEntities = [
    // "aaa9a975-fbcb-41b4-a2dd-3f404f6920a1", // Scandia Rent
    "c98555b2-980e-44b3-b95d-ebb4cd21d4ac", // Staging Scandia rent
    "d90a0808-7f16-41af-a374-0244e5fe9df1", // Dev organization
    "9e615947-75c4-44cf-bd89-fd4917d83a11", // Dev organization
  ];

  return allowedEntities.includes(userEntity);
};

export const enableWildCardReservations = (organization) => {
  if (!organization) return false;
  return true;
};

export const enableExternalReservations = (organizationId) => {
  const enabledForOrganization = scandiaRelatedOrgs;

  return enabledForOrganization.includes(organizationId);
};

export const enableCustomAdditionalService = (organizationId) => {
  const enabledForOrganization = scandiaRelatedOrgs;

  return enabledForOrganization.includes(organizationId);
};

export const enableExternalServices = (organizationId) => {
  const enabledForOrganization = scandiaRelatedOrgs;

  return enabledForOrganization.includes(organizationId);
};

export const enableReservationDialogV2 = (USER_ADMIN_TYPE, business, user) => {
  if (USER_ADMIN_TYPE === "GENERIC" && user?.organizationId) {
    return true;
  }
  if (
    user?.externalBusinessId &&
    user?.externalBusiness?.type !== "INSURANCE"
  ) {
    return true;
  }
  return EReservationDialogFeatureIds.includes(
    EIdSource[USER_ADMIN_TYPE](business, user)
  );
};

const EReservationDialogFeatureIds = [
  "0998e5c8-95ed-41d0-be1c-3834f56359ec", // DEV BUSINESS
  "f5aae216-9e5f-4b3f-b92d-28d51dbef856", // DEV INSURANCE
  "19217645-248c-4836-946e-53c46f8a9e7b", // DEV CORPORATE (lapin yliopisto)
  "1734e497-f7b3-4f61-8ce2-4c606d35070e", // Staging scandiarenet
  "94b78174-1df6-4206-83ed-028bf923dc14", // Staging scandiarenet
  "84070542-48eb-4892-b604-134328515826", // Staging scandiarenet
  "eed66269-5e5d-4d80-bbe5-32a1e3044464", // Ville dev business
  "55583547-e285-44ae-ae26-8fedee197690", // Kajaani Scandia Rent
];

const EIdSource = {
  GENERIC: (business, user) => user?.organizationId,
  INSURANCE: (business, user) => user?.externalBusiness?.id,
  CORPORATE: (business, user) => user?.externalBusiness?.id,
};

import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
} from "@mui/material";

export default function Loading() {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="rounded" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton width={150} />} />
        <ListItemSecondaryAction>
          <Skeleton width={100} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="rounded" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton width={150} />} />
        <ListItemSecondaryAction>
          <Skeleton width={100} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant="rounded" width={40} height={40} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton width={150} />} />
        <ListItemSecondaryAction>
          <Skeleton width={100} />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

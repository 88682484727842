import { Cache, Storage } from "aws-amplify";
import { addMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { Category } from "~/graphql/API";
import { typeOfProducts } from "~/shared/ProductClassification/type-of-products";

export function useCategoryImage(category: undefined | Category) {
  const [image, setImage] = useState(
    typeOfProducts.find((type) => type.id === category?.typeId)?.image || ""
  );

  useEffect(() => {
    (async () => {
      if (category?.image) {
        const [identityId, key] = category.image.split("/");

        let url = Cache.getItem(key);

        if (!url) {
          url = await Storage.get(key, {
            identityId,
            level: "protected",
          });

          Cache.setItem(key, url, {
            expires: addMinutes(new Date(), 15).getTime(),
          });
        }

        setImage(url);
      }
    })();
  }, [category?.image]);

  return { image, backgroundColor: category?.backgroundColor };
}

import React, { useState, useMemo } from "react";
import { Box, Button, Tabs, Tab } from "@mui/material";

import DialogComponent from "./DialogComponent";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

export default function HanselDialog({ open, onClose, loading }) {
  const [customersFile, setCustomersFile] = useState();
  const [classes, setClasses] = useState(null);
  const [customers, setCustomers] = useState(null);

  const [classesFile, setClassesFile] = useState();

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const fileReader = new FileReader();

  const handleOnChangeCustomers = (e) => {
    setCustomersFile(e.target.files[0]);
  };
  const handleOnChangeClasses = (e) => {
    setClassesFile(e.target.files[0]);
  };

  const handleAddCustomers = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setCustomers(array);
  };

  const handleAddClasses = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setClasses(array);
  };

  const handleOnSubmitCustomers = (e) => {
    e.preventDefault();

    if (customersFile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        handleAddCustomers(text);
      };

      fileReader.readAsText(customersFile);
    }
  };

  const handleOnSubmitClasses = (e) => {
    e.preventDefault();

    if (classesFile) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        handleAddClasses(text);
      };

      fileReader.readAsText(classesFile);
    }
  };

  const columns = useMemo(() => {
    const customerNumber = {
      field: "Asiakas",
      headerName: "Asiakas",
      sortable: true,
      flex: 0.3,
      minWidth: 100,
    };

    const name = {
      field: "Organisatio nimi",
      headerName: "Organisatio nimi",
      sortable: true,
      flex: 1,
      minWidth: 200,
    };

    const vat = {
      field: "VAT",
      headerName: "VAT",
      sortable: true,
      flex: 0.4,
      minWidth: 100,
    };

    const classId = {
      field: "Id",
      headerName: "Id",
      sortable: true,
      flex: 0.2,
      minWidth: 50,
    };

    const className = {
      field: "Nimi",
      headerName: "Nimi",
      sortable: true,
      flex: 1,
      minWidth: 100,
    };
    const classType = {
      field: "Luokka",
      headerName: "Luokka",
      sortable: true,
      flex: 0.3,
      minWidth: 50,
    };

    // columns for customers view
    if (selectedTab === 0) {
      return [customerNumber, name, vat];
    }

    // columns for classes view
    if (selectedTab === 1) {
      return [classId, className, classType];
    }
  }, [selectedTab]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const rows = useMemo(() => {
    if (selectedTab === 0) {
      if (!customers) {
        return [];
      }
      return customers;
    }
    if (selectedTab === 1) {
      if (!classes) {
        return [];
      }
      return classes;
    }
    return [];
  }, [selectedTab, classes, customers]);
  console.log("rows", rows);
  return (
    <DialogComponent
      open={open}
      dialogClose={() => onClose()}
      dialogActionText={"Lisää"}
      // dialogAction={disableFields ? null : () => createNew()}
      dialogTitle={"Hansel: Asiakkaat & Tuotteet"}
      maxWidth={"lg"}
      height={"700px"}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        scrollButtons="auto"
        aria-label="hansel-customers-and-products"
        variant="fullWidth"
      >
        <Tab label="Asiakkaat" />
        <Tab label="Tuotteet" />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <Box sx={{ marginY: "15px" }}>
          <form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <InputButton handleOnChange={handleOnChangeCustomers} />

              <Button
                onClick={(e) => {
                  handleOnSubmitCustomers(e);
                }}
                variant="contained"
                disabled={!customersFile}
              >
                Lisää asiakaslista
              </Button>
            </Box>
          </form>
        </Box>
        <DataGridPro
          //  getRowId={(row) => row.key}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            overflow: "hidden",
            height: "450px",
            /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          rows={customers ?? []}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[2]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => row?.Asiakas}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <Box sx={{ marginY: "15px" }}>
          <form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <InputButton handleOnChange={handleOnChangeClasses} />
              {/*     <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
          /> */}

              <Button
                onClick={(e) => {
                  handleOnSubmitClasses(e);
                }}
                variant="contained"
                disabled={!classesFile}
              >
                Lisää tuotelista
              </Button>
            </Box>
          </form>
        </Box>
        <DataGridPro
          //  getRowId={(row) => row.key}
          sx={{
            //   borderColor: "transparent",
            borderRadius: "14px",
            height: "450px",
            overflow: "hidden",
            /*         fontFamily: "Public Sans, sans-serif",
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            }, */
            "& .MuiDataGrid-columnHeadersInner": {
              backgroundColor: "#f7f7f8",
              color: "#4e536c",
              fontFamily: "Sofia Pro",
              fontSize: "16px",
              // boxShadow: theme.shadows[4],
            },
          }}
          rows={classes ?? []}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[2]}
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          getRowId={(row) => row?.Nimi}
        />
      </TabPanel>
    </DialogComponent>
  );
}

function InputButton(props) {
  const { handleOnChange } = props;
  const fileInput = React.useRef();

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fileInput.current.click()}
      >
        Lataa tiedosto
      </Button>

      <input
        ref={fileInput}
        type={"file"}
        id={"csvFileInput"}
        accept={".csv"}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />
    </div>
  );
}

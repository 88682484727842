import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Box,
  FormHelperText,
  Button,
  Divider,
} from "@mui/material";

import { API, graphqlOperation } from "aws-amplify";
import { getReservationOffersAdmin } from "../graphql/queries";

import DialogComponent from "./DialogComponent";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppContext } from "../AppContext";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { captureError, useEffectAsync } from "../utilities";
import ProductCard from "./ProductCard";
import { isBefore } from "date-fns";
import { nextFullHour } from "./Reservation/common/utils";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../atoms/reservationDataAtom";

export default function ProductAvailabilityWidget(props) {
  const { open, onClose } = props;
  const { user } = useContext(AppContext);
  const businessByOrg = user?.businessByOrg;
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [companiesByBusiness, setCompaniesByBusiness] = useState();
  const [queryCompanies, setQueryCompanies] = useState([]);
  const [searchStart, setSearchStart] = useState(new Date());
  const [searchEnd, setSearchEnd] = useState(nextFullHour(new Date()));
  const [availableOffers, setAvailableOffers] = useState([]);
  const [searchOffers, setSearchOffers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const categories = useMemo(() => {
    return user?.categoriesByOrg.items;
  }, [user]);

  const hasErrors = useMemo(() => {
    let errors = [];
    if (selectedBusiness && queryCompanies.length > 0) return false;
    if (!selectedBusiness) {
      errors.push("select-franchising");
    }
    if (queryCompanies.length === 0) {
      errors.push("select-franchising-company");
    }
    if (searchOffers) return errors;
  }, [selectedBusiness, queryCompanies, searchOffers]);

  const handleChangeBusiness = (value) => {
    setSelectedBusiness(value);
    setCompaniesByBusiness(
      businessByOrg.items.find((b) => b.id === value)?.companies?.items
    );
  };

  useEffectAsync(async () => {
    if (searchOffers && !hasErrors) {
      setLoading(true);
      setAvailableOffers([]);
      setOffers([]);
      try {
        const results = await fetchGetReservationOffers(
          queryCompanies.map((c) => c),
          searchStart,
          searchEnd,
          selectedBusiness
        );
        setAvailableOffers(results);
        setOffers(results);
      } catch (e) {
        console.log("ERROR", e);
        captureError("Get reservation offers failed", "GET_OFFERS_FAILED", e);
      }
      setSearchOffers(false);
      setLoading(false);
    }
  }, [searchOffers, searchStart, searchEnd, queryCompanies, hasErrors]);

  const handleEndDate = (date) => {
    if (isBefore(date, searchStart)) return;
    return setSearchEnd(date);
  };

  const handleStartTime = (date) => {
    setSearchStart(date);
    setSearchEnd(nextFullHour(date));
  };

  //handle filtering offers by category or show all results
  useEffect(() => {
    if (selectedCategories.length === 0) {
      setOffers(availableOffers);
    } else {
      const filteredOffers = availableOffers.filter((offer) =>
        selectedCategories.includes(offer.vehicle?.category?.id)
      );
      setOffers(filteredOffers);
    }
  }, [selectedCategories, availableOffers]);

  function advanceToReservationDialog() {
    const vehicleIds = selectedProducts.map((product) => product.id);
    const data = {
      additionalDriver: false,
      additionalDriverName: "",
      additionalDriverPhone: "",
      additionalDriverSSN: "",
      address: "",
      billPayment: true,
      companyBillingAddress: "",
      companyBillingRef: "",
      companyBusinessId: "",
      companyId: selectedBusiness?.id,
      differentDriver: true,
      name: "Anssi asiakas",
      vehicleIds: vehicleIds,
      orgBusinessId: "48c1c700-1fce-11ee-be56-0242ac120002",
      organizationId: "8b9da22a-1fcd-11ee-be56-0242ac120002",
      reservationVehicles: selectedProducts,
      reservationVehicleData: selectedProducts,
      returnTime: searchEnd,
      startTime: searchStart,
    };

    return setEditReservationData(data);
  }
  return (
    <DialogComponent
      open={open}
      dialogCloseText={"Sulje"}
      dialogClose={onClose}
      dialogTitle={"Tuotehaku"}
      /*    dialogAction={() => setSearchOffers(true)}
      dialogActionText={"Hae"} */
      dialogActionSubmitting={loading}
      height={"80vh"}
      testName="productSearchDialog"
    >
      <Box>
        <Stack spacing={2} sx={{ marginTop: "25px" }}>
          <Stack direction={"row"} spacing={2}>
            <DateTimePicker
              inputFormat="dd.MM.yyyy HH:mm"
              ampm={false}
              disablePast
              label="Alkamisaika"
              value={searchStart}
              onChange={(newValue) => handleStartTime(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DateTimePicker
              inputFormat="dd.MM.yyyy HH:mm"
              ampm={false}
              label="Päättymisaika"
              value={searchEnd}
              onChange={(newValue) => handleEndDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <FormControl
              fullWidth
              error={
                Array.isArray(hasErrors) &&
                hasErrors?.includes("select-franchising")
              }
            >
              <InputLabel id={"select-franchising-label"}>
                Valitse franchising-yrittäjä
              </InputLabel>
              <Select
                labelId="select-franchising-label"
                id="select-franchising"
                label="Valitse franchising-yrittäjä"
                value={selectedBusiness || ""}
                onChange={(e) => handleChangeBusiness(e.target.value)}
                data-cy="productSearchBusinessSelect"
                MenuProps={{ style: { maxHeight: "400px" } }}
              >
                {businessByOrg.items.map((business) => (
                  <MenuItem
                    value={business.id}
                    key={business.id}
                    data-cy="productSearchBusinessOption"
                  >
                    {business?.name}
                  </MenuItem>
                ))}
              </Select>
              {Array.isArray(hasErrors) &&
                hasErrors?.includes("select-franchising") && (
                  <FormHelperText>Valitse franchising-yrittäjä</FormHelperText>
                )}
            </FormControl>
            <FormControl
              fullWidth
              error={
                Array.isArray(hasErrors) &&
                hasErrors?.includes("select-franchising-company")
              }
            >
              <InputLabel id="select-franchising-company-label">
                Valitse toimipiste
              </InputLabel>
              <Select
                labelId="select-franchising-company-label"
                id="select-franchising-company"
                disabled={!companiesByBusiness}
                label="Valitse toimipiste"
                value={queryCompanies}
                multiple
                onChange={(e) => {
                  setQueryCompanies(e.target.value);
                }}
                data-cy="productSearchCompanySelect"
              >
                {companiesByBusiness &&
                  companiesByBusiness.map((company) => (
                    <MenuItem
                      value={company.id}
                      key={company.id}
                      data-cy="productSearchCompanyOption"
                    >
                      {company?.name}
                    </MenuItem>
                  ))}
              </Select>
              {Array.isArray(hasErrors) &&
                hasErrors?.includes("select-franchising-company") && (
                  <FormHelperText>Valitse toimpiste</FormHelperText>
                )}
            </FormControl>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",

                paddingX: "25px",
                paddingY: "10px",
              }}
              onClick={() => setSearchOffers(true)}
            >
              Hae tuotteita
            </Button>
            {availableOffers.length > 0 && (
              <FormControl fullWidth>
                <InputLabel id={"select-categories-label"}>
                  Tuotteet kategorioittain
                </InputLabel>
                <Select
                  labelId="select-categories-label"
                  id="select-categories"
                  disabled={categories.length === 0}
                  label="Tuotteet kategorioittain"
                  value={selectedCategories}
                  multiple
                  onChange={(e) => {
                    setSelectedCategories(e.target.value);
                  }}
                  MenuProps={{ style: { maxHeight: "400px" } }}
                >
                  {categories
                    .sort((c1, c2) => {
                      if (c1?.name < c2?.name) {
                        return -1;
                      }
                      if (c1?.name > c2?.name) {
                        return 1;
                      }
                      return 0; // Names are equal
                    })
                    .map((category) => (
                      <MenuItem value={category.id} key={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Stack>
        <Box
          sx={{
            maxHeight: " 520px",
            overflow: "auto",
            marginY: "10px",
          }}
        >
          <Box sx={{ padding: "25px" }}>
            {!loading &&
              offers.map((offer) => (
                <ProductCard
                  productData={offer.vehicle}
                  offerPrice={offer?.vehicleOfferPrice}
                  loading={loading}
                  setSelectedProducts={setSelectedProducts}
                  selectedProducts={selectedProducts}
                />
              ))}
            {loading && (
              <>
                <ProductCard loading={loading} />
                <ProductCard loading={loading} />
              </>
            )}
          </Box>
        </Box>
      </Box>
      {selectedProducts.length > 0 && (
        <>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                margin: "15px",
                paddingX: "25px",
                paddingY: "10px",
              }}
              onClick={() => advanceToReservationDialog()}
            >
              Luo varaus
            </Button>
          </Box>
        </>
      )}
    </DialogComponent>
  );
}

const fetchGetReservationOffers = async (
  companyIds,
  startTime,
  returnTime,
  businessId
) => {
  const response = await API.graphql(
    graphqlOperation(getReservationOffersAdmin, {
      companyIds,
      startTime: startTime.toJSON(),
      returnTime: returnTime.toJSON(),
      businessId: businessId,
      disableCategoryDefaultsStacking: true,
    })
  );

  const result = response.data.getReservationOffersAdmin.map((r) => ({
    ...r,
    startTime: new Date(r.startTime),
    returnTime: new Date(r.returnTime),
    freeTimes: r.freeTimes.map((f) => ({
      firstStartTime: new Date(f.firstStartTime),
      lastStartTime: new Date(f.lastStartTime),
      lastReturnTime: new Date(f.lastReturnTime),
    })),
  }));

  if (result) {
    return result;
  }
  return null;
};

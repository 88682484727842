import React from "react";
import { Autocomplete } from "@mui/material";
import { Avatar, Box, Stack, TextField, Typography } from "@mui/material";
import { Home, LocationOn } from "@mui/icons-material";
import { useGetRequiredFields } from "../formik/requiredFields";
import { useFormikContext } from "formik";

export function AutoCompleteComponent(props) {
  const {
    defaultOptions,
    error,
    errorHelperText,
    freeSolo,
    inputValue,
    label,
    name,
    onChange,
    onInputChange,
    subTitle,
    value,
    onBlur,
    disabled,
    size,
  } = props;
  const { values } = useFormikContext();
  const { requiredFields } = useGetRequiredFields();
  return (
    <Autocomplete
      freeSolo={freeSolo}
      fullWidth
      disabled={disabled}
      value={value ?? ""}
      inputValue={inputValue}
      onInputChange={onInputChange}
      size={size}
      options={defaultOptions}
      //  loading={nameAutoCompleteLoading}
      onChange={onChange}
      onBlur={onBlur}
      filterOptions={(options, state) => {
        return options?.filter((option) => {
          if (typeof option === "string") {
            if (option.toLowerCase().includes(state?.inputValue.toLowerCase()))
              return true;
            else return false;
          } else {
            if (
              option?.name
                .toLowerCase()
                .includes(state?.inputValue.toLowerCase())
            )
              return true;
            else return false;
          }
        });
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.name
      }
      noOptionsText="Ei tuloksia"
      renderOption={(props, option) => {
        /*     const classObj = classes.find(
            (c) => c.productNro === option.productNro
          );
  */
        return (
          <Box {...props} key={option?.id}>
            <Stack
              sx={{
                flexDirection: "row",
                transition: "250ms ease-in-out",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box sx={{ marginX: "10px" }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    bgcolor: option?.locationColor ?? undefined,
                    cursor: "pointer",
                  }}
                >
                  {option?.companyId ? <LocationOn /> : <Home />}
                </Avatar>
              </Box>

              <Stack direction={"column"}>
                <Typography sx={{ fontWeight: 550 }} align="left">
                  {option?.name}
                </Typography>
                <Typography align="left" variant="caption">
                  {subTitle && subTitle}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          error={error}
          helperText={errorHelperText}
          required={requiredFields(values).includes(name)}
        />
      )}
    />
  );
}

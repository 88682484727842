import { API, graphqlOperation } from "aws-amplify";
import { createInnovoiceConsolidatedInvoice } from "~/graphql/mutations.ts";
import { formatISODate } from "~/utils/date";
// @ts-ignore
import { parseToCents } from "~/shared/money";
// @ts-ignore
import { useNotification } from "~/components/Notification";
import {
  CreateConsolidatedInnvoiceInput,
  InvoiceCustomerInput,
  InvoiceItemInput,
} from "~/graphql/API";

type Options = { onSuccess: () => void };

function useSubmit({ onSuccess }: Options) {
  const notification = useNotification();

  return async function submit(values: any, actions: any) {
    const customer: InvoiceCustomerInput = {
      customer_type: values.customer_type,
      address: values.address,
      zipcode: values.postalCode,
      email: values.email,
      phone: values.phone,
      city: values.city,
    };
    if (values.customer_type === "person") {
      customer.firstname = values.firstname;
      customer.lastname = values.lastname;
      customer.ssn = values.ssn;
    }
    if (values.customer_type === "company") {
      customer.company_name = values.companyName;
      customer.business_id = values.companyBusinessId;
      customer.e_invoice_address = values.e_invoice_address;
      customer.e_invoice_operator = values.e_invoice_operator;
    }

    const input: CreateConsolidatedInnvoiceInput = {
      invoice: {
        payment_terms: values.payment_terms,
        vat_included: values.vat_included,
        invoice_type: "normal",
        delivery_by: values.delivery_by,
        delivery_date: formatISODate(values.delivery_date),
        seller_reference: values.seller_reference,
        buyer_reference: values.buyer_reference,
      },
      items: values.items?.map((item: any): InvoiceItemInput => {
        const quantity = item.unit === "pv" ? item.rentalDays : item.quantity;
        const unitprice =
          item.unit === "pv" ? item.priceperday : item.unitprice;

        return {
          reservationId: item.reservation?.id,
          name: item.name,
          description: item.description,
          quantity: quantity,
          unit: item.unit,
          vat: item.vat,
          unitprice: parseToCents(unitprice),
          type: item.type,
          insuranceItemType: item.insuranceItemType,
        };
      }),
      customer: customer,
    };

    try {
      const response = (await API.graphql(
        graphqlOperation(createInnovoiceConsolidatedInvoice, { invoice: input })
      )) as any;

      if (response.data.createInnovoiceConsolidatedInvoice.success) {
        console.log("Invoice created: ", response);
        notification.show("Lasku luotu onnistuneesti.", "success");

        onSuccess();
      } else {
        if (response.data.createInnovoiceConsolidatedInvoice.message) {
          notification.show(
            response.data.createInnovoiceConsolidatedInvoice.message
          );
        } else {
          notification.show("Jokin meni vikaan, ota yhteys asiakaspalveluun");
        }
      }

      actions.setSubmitting(false);
    } catch (error) {
      console.error("Invoice creation failed", error);
      notification.show("Jokin meni vikaan, ota yhteys asiakaspalveluun");
      actions.setSubmitting(false);
    }
  };
}

export default useSubmit;

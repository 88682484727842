import { API, graphqlOperation } from "aws-amplify";
import { getArchiveReservation, getReservation } from "../graphql/queries";

export const fetchReservation = async (id) => {
  if (id) {
    try {
      const response = await API.graphql(
        graphqlOperation(getReservation, {
          id: id,
        })
      );
      if (response.data.getReservation) {
        return response.data.getReservation;
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};

export const fetchArchiveReservation = async (id) => {
  if (id) {
    try {
      const response = await API.graphql(
        graphqlOperation(getArchiveReservation, {
          id: id,
        })
      );
      if (response.data.getArchiveReservation) {
        return response.data.getArchiveReservation;
      }
    } catch (error) {
      console.log("error", error);
    }
  }
};

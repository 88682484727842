// import examples from "libphonenumber-js/mobile/examples";
import React, { useMemo } from "react";
import { TextField, Box, Grid } from "@mui/material";
import { AsYouType } from "libphonenumber-js";
import { useField } from "formik";
import { translatedFields } from "../formik/fields";
import { validatePhoneNumber } from "../../../utils/validatePhoneNumber";

const getFlagEmoji = (countryCode) => {
  if (!countryCode) return null;
  const codePoints = countryCode

    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export default function PhoneNumberField({
  fieldName,
  required,
  selectedLanguage,
  size,
}) {
  const [, meta, helpers] = useField(fieldName);
  const { value, error, touched } = meta;
  const { setValue, setTouched } = helpers;
  const language = selectedLanguage ?? "FIN";
  const countryCode = useMemo(() => {
    if (value === null || value === "" || value === undefined) return null;
    const inputValue = value ?? "";
    const asYouType = new AsYouType();
    asYouType.input(inputValue);
    const countryCode = asYouType.getCountry();
    return countryCode;
  }, [value]);

  //  console.log(getExampleNumber("BR", examples));
  const fieldColor = useMemo(() => {
    if (value === "" || value === null || value === undefined) return "primary";
    if (!!error) return "warning";
    return "success";
  }, [error, value]);
  return (
    <Grid item xs={12}>
      <TextField
        color={fieldColor}
        InputProps={{
          startAdornment: getFlagEmoji(countryCode) ? (
            <Box pr={1}>{getFlagEmoji(countryCode)}</Box>
          ) : null,
        }}
        size={size}
        value={value ?? ""}
        label={translatedFields[language][fieldName]}
        fullWidth
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={(e) => {
          setTouched(true, true);
          const isValidNumber = validatePhoneNumber(e.target.value);
          if (typeof isValidNumber === "string") {
            setValue(isValidNumber);
          }
        }}
        required={required}
        error={!!touched && !!error}
      />
    </Grid>
  );
}

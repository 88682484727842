import { API, graphqlOperation } from "aws-amplify";

import { useContext } from "react";
import { AppContext } from "../../../AppContext";
import {
  getHanselData,
  getReservationChannel,
  getReservationPaymentData,
  getReservationStatus,
  handleCompanyId,
  handleServices,
  setTimeZones,
  getCustomerCompanyData,
  getPaymentStatus,
} from "../formik/utils";
import { reservationFields } from "../formik/fields";
import { upsertReservationAdmin } from "../../../graphql/mutations";
import { captureError } from "../../../utilities";
import { useFetchVehicles } from "../../../services/fetchVehicles";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import { vehiclePricesAtom } from "../../../stores/vehiclePrices";
import { reservationTotalPriceAtom } from "../../../stores/reservationTotalPrice";

export const useHandleSubmitForm = () => {
  const reservationData = useAtomValue(reservationDataAtom);
  const { company, user, group } = useContext(AppContext);
  const { vehicleData } = useFetchVehicles();
  const vehiclePrices = useAtomValue(vehiclePricesAtom);
  // EXTERNAL_PENDING_DEALER status doesnt include reservation vehicles or wildcards
  const totalPrice = useAtomValue(reservationTotalPriceAtom);
  // TODO: Should it be possible to use sendStatusToCustomer, billPayment and automatic removal together?
  /*     if (reservationData?.id && FEATURE_INVOICING && billPayment) {
          console.log("Set paymentStatus to PENDING_INVOICE");
          reservation.paymentStatus = "PENDING_INVOICE";
        } */

  const submit = async (values) => {
    const reservationStatus = getReservationStatus(values);
    const reservationChannel = getReservationChannel(
      reservationData,
      user?.role
    );

    const reservationPaymentData = getReservationPaymentData(
      values,
      reservationData,
      totalPrice,
      company
    );
    const hanselData = getHanselData(values, vehicleData);
    const customerCompanyData = getCustomerCompanyData(values);
    const vehicles = values?.reservationVehicles?.map((vehicle) => {
      return {
        category: { id: vehicle?.category?.id }, // category id is stored in object
        categoryName: vehicle?.category?.name,
        createdAt: vehicle?.createdAt,
        damages: vehicle?.damages,
        deductible: vehiclePrices[vehicle?.id]?.deductible,
        endMileage: vehicle?.endMileage,
        extraInfo: vehicle?.extraInfo,
        hideExport: vehicle?.hideExport,
        id: vehicle?.id,
        includedDistance: vehicle?.includedDistance,
        mileage: vehicle?.mileage,
        name: vehicle?.name,
        powerType: vehicle?.powerType,
        price: vehiclePrices[vehicle?.id]?.price,
        quantity: vehicle?.quantity,
        registrationPlate: vehicle?.registrationPlate,
        startMileage: vehicle?.startMileage,
        tax: vehiclePrices[vehicle?.id]?.tax,
        useCategoryDefaults: !!vehicle?.quantity, //
      };
    });

    const wildCard = vehiclePrices
      ? {
          categoryId: Object.keys(vehiclePrices)[0],
          price: Object.values(vehiclePrices)[0]?.price,
          deductible: Object.values(vehiclePrices)[0]?.deductible,
          tax: Object.values(vehiclePrices)[0]?.tax,
          name: Object.values(vehiclePrices)[0]?.name,
          quantity: 1,
        }
      : null;
    delete wildCard?.__typename; // Make sure we don't try to save __typename

    const { startTimeUTC, returnTimeUTC, endTimeUTC } = setTimeZones(
      values.startTime,
      values.returnTime,
      values.endTime,
      company
    );

    // OR-394: Until 1.9. use this hack to sync tax rate from Vehicle, which should be up to date.
    const servicesWithBackupVAT = values.additionalServices.map((service) => {
      let tax = vehicles[0]?.tax;
      return { ...service, tax: tax };
    });

    const reservation = {
      ...customerCompanyData, // handleCustomerCompany values
      [reservationFields.id]: values.id,
      [reservationFields.channel]: reservationChannel,
      [reservationFields.pricingCategoryId]: values.pricingCategoryId,
      [reservationFields.completeBefore]:
        reservationPaymentData?.completeBefore,
      [reservationFields.language]: values.language,
      /* DEALER FIELDS */
      [reservationFields.group]: group ?? undefined,
      [reservationFields.organizationId]: values.organizationId,
      [reservationFields.orgBusinessId]: values.orgBusinessId,
      [reservationFields.orgExternalBusinessId]: values.orgExternalBusinessId,
      [reservationFields.companyId]: handleCompanyId(
        values?.startLocationID,
        values.companyId,
        company?.id
      ),
      [reservationFields.disablePricesInPrintouts]:
        values.disablePricesInPrintouts,
      [reservationFields.enableRegistrationPlate]:
        values.enableRegistrationPlate,

      /* RESERVATION FIELDS */
      [reservationFields.status]: reservationStatus,
      [reservationFields.type]: values.type,
      [reservationFields.rentalStatus]: values.rentalStatus,
      [reservationFields.reservationStatusInfo]: values.reservationStatusInfo,

      /* RESERVATION SCHEDULE */
      [reservationFields.startTime]: startTimeUTC,
      [reservationFields.returnTime]: returnTimeUTC,
      [reservationFields.endTime]: endTimeUTC,

      /* RESERVATION LOCATION */
      [reservationFields.returnLocation]: values.returnLocation,
      [reservationFields.returnLocationID]: values.returnLocationID,
      [reservationFields.startLocation]: values.startLocation,
      [reservationFields.startLocationID]: values.startLocationID,

      /* RESERVATION PRODUCTS */
      [reservationFields.reservationVehicles]: vehicles, // TODO set up reservationVehicles prices
      [reservationFields.wildCardVehicles]:
        values?.reservationVehicles?.length > 0
          ? null
          : values?.wildCardVehicles?.length > 0
          ? [wildCard]
          : null,
      [reservationFields.vehiclePrice]: values.vehiclePrice,
      [reservationFields.reservationVehicleId]: values.reservationVehicleId,
      [reservationFields.outOfCommission]: values.outOfCommission,

      /* RESERVATION ADDITIONAL SERVICES */
      [reservationFields.additionalServices]: handleServices(
        servicesWithBackupVAT // replace with values?.additionalServices after armageddon
      ), // TODO: set up prices

      /* RESERVATION PAYMENT */
      [reservationFields.paymentData]: reservationPaymentData?.paymentData,
      [reservationFields.paymentStatus]: getPaymentStatus(values),
      [reservationFields.billPayment]: values.billPayment,
      [reservationFields.innovoiceStatus]: values.innovoiceStatus,

      /* EXTERNAL RESERVATION */

      // INSURANCE
      [reservationFields.insuranceData]: values.insuranceData,
      [reservationFields.customerCarRegistrationPlate]:
        values.customerCarRegistrationPlate,

      /* HANSEL DATA */
      [reservationFields.hanselData]: hanselData,
      /* 
      TODO: Check that this is done correctly
       reservation.initialClassInfo = {
                categoryId: initialClassInfo?.categoryId,
                categoryName: initialClassInfo?.categoryName,
                hanselClass: initialClassInfo?.hanselClass,
              };
      */

      /* WILDCARD RESERVATIONS */
      [reservationFields.initialCategory]: values.initialCategory,
      [reservationFields.initialClassInfo]: values.initialClassInfo,

      /* CUSTOMER FIELDS */
      [reservationFields.email]: values?.email?.trim(),
      [reservationFields.name]: values.name ?? "",
      [reservationFields.address]: values.address,
      [reservationFields.ssn]: values.ssn,
      [reservationFields.phone]: values?.phone,
      [reservationFields.postalCode]: values.postalCode,
      [reservationFields.city]: values.city,
      [reservationFields.billingRef]: values.billingRef,
      [reservationFields.customerNotes]: values.customerNotes,

      /* DRIVER FIELDS */
      [reservationFields.differentDriver]: values.differentDriver,
      [reservationFields.driverAddress]: values.driverAddress,
      [reservationFields.driverCity]: values.driverCity,
      [reservationFields.driverEmail]: values.driverEmail,
      [reservationFields.driverName]: values.driverName,
      [reservationFields.driverPhone]: values?.driverPhone,
      [reservationFields.driverPostalCode]: values.driverPostalCode,
      [reservationFields.driverSSN]: values.driverSSN,

      /* ADDITIONAL DRIVER FIELDS */
      [reservationFields.additionalDriver]: values.additionalDriver,
      [reservationFields.additionalDriverName]: values.additionalDriverName,
      [reservationFields.additionalDriverPhone]: values?.additionalDriverPhone,
      [reservationFields.additionalDriverSSN]: values.additionalDriverSSN,

      /* CUSTOMER COMPANY FIELDS */
      [reservationFields.isCompany]: values.isCompany,

      [reservationFields.companyBillingAddress]:
        values?.brokerCustomer?.companyBillingAddress ??
        values.companyBillingAddress,
      [reservationFields.companyBillingRef]: values.companyBillingRef,
      [reservationFields.companyEBillingAddress]:
        values?.brokerCustomer?.companyEBillingAddress ??
        values.companyEBillingAddress,
      [reservationFields.companyEBillingOperator]:
        values?.brokerCustomer?.companyEBillingOperator ??
        values.companyEBillingOperator,

      /* BROKER */
      [reservationFields.customerBrokerId]: values.customerBrokerId,

      /* RESERVATION NOTES */
      [reservationFields.internalNotes]: values.internalNotes,
      [reservationFields.externalNotes]: values.externalNotes,
      [reservationFields.rentalNotes]: values.rentalNotes,

      /* RESERVATION AUTHENTICATION & SIGN */
      [reservationFields.authStatus]:
        values.authStatus ?? reservationPaymentData?.authStatus,
      [reservationFields.status]: reservationStatus,
    };

    delete reservation.insuranceData?.__typename; // Make sure we don't try to save __typename
    delete reservation.initialClassInfo?.__typename; // Make sure we don't try to save __typename
    delete reservation?.reservationStatusInfo?.__typename;
    delete reservation?.paymentData?.__typename; // __typename may be included in Reservation queries, but does not exist on PaymentDataInputAdmin

    try {
      const response = await API.graphql(
        graphqlOperation(upsertReservationAdmin, {
          reservation: reservation,
          sendStatusToCustomer: values.sendStatusToCustomer,
          disableConfirmationEmail: values.disableConfirmationEmail,
        })
      );

      return {
        success: true,
        data: response?.data?.upsertReservationAdmin,
      };
    } catch (err) {
      captureError(
        "Upsert reservation failed",
        "UPSERT_RESERVATION_FAILED",
        err
      );
      //   notification.show("Varauksen luonti epäonnistui", "error");
      console.log("UPSERT", err);
      return { success: false, error: err };
    }
  };

  return { submit };
};

import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { getReview } from "../../../graphql/queries";
import { captureError } from "../../../utilities";
import { useAtomValue } from "jotai";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";

export function useGetReview() {
  const reservationData = useAtomValue(reservationDataAtom);
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState(null);

  const upsertReview = async (review) => {
    setLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(upsertReview, {
          review: {
            id: review.id,
            reservationId: review.reservationId,
            rating: review.rating,
            comment: review.comment,
            explanation: review.explanation,
          },
        })
      );
      if (response) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      captureError("Upsert review failed", "UPSERT_REVIEW_FAILED", err);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchReview() {
      setLoading(true);
      try {
        const response = await API.graphql(
          graphqlOperation(getReview, {
            id: reservationData?.reviewId,
          })
        );
        const review = response.data.getReview;

        if (review) setReview(review);
        setLoading(false);
      } catch (e) {
        console.error("error fetching review", e);
        setLoading(false);
        throw new Error("Failed to fetch review.");
      }
    }

    if (reservationData?.reviewId) fetchReview();
  }, [reservationData?.reviewId, setReview]);

  return { loading, review, setReview, upsertReview };
}

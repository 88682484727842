import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormikContext } from "formik";
import { vehicleClasses } from "../../../utils/enums";

export default function SelectVehicleClass() {
  const { handleChange, touched, errors, values } = useFormikContext();

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="replacement-vehicle-class">
          Korvattava autoluokka
        </InputLabel>

        <Select
          value={
            values?.insuranceData?.replacementVehicleClass
              ? values?.insuranceData?.replacementVehicleClass
              : ""
          }
          onChange={handleChange}
          label="Korvattava autoluokka"
          name="insuranceData.replacementVehicleClass"
          id="insuranceData.replacementVehicleClass"
          error={
            touched?.insuranceData?.replacementVehicleClass &&
            !!errors?.replacementVehicleClass
          }
          data-cy="reservationDialogVehicleClassSelect"
        >
          {vehicleClasses.map((c) => (
            <MenuItem
              key={c.type}
              value={c.type}
              data-cy="reservationDialogVehicleClassOption"
            >
              {c.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

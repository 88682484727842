export const additionalServiceScandia = [
  {
    key: "f4b1e1a2-eb35-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Alennus",
    price: 0,
    unit: "kpl",
  },
  {
    key: "fc47404c-eb35-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "extra",
    price: 0,
    unit: "kpl",
  },
  {
    key: "ff0e237c-eb35-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "hyvitys",
    price: 0,
    unit: "kpl",
  },
  {
    key: "02159f28-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Istuinkoroke",
    price: 0,
    unit: "kpl",
  },
  {
    key: "050c36b0-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "jättömaksu",
    price: 0,
    unit: "kpl",
  },
  {
    key: "08565576-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "kilometrit",
    price: 0,
    unit: "km",
  },
  {
    key: "0b8a8032-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Kuljettaja",
    price: 0,
    unit: "kpl",
  },
  {
    key: "0f072210-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Laskutuslisä",
    price: 0,
    unit: "kpl",
  },
  {
    key: "scandia-rent-Lisäkuljettaja",
    category: "scandiaRent",
    quantity: 1,
    description: "Lisäkuljettaja",
    price: 0,
    unit: "kpl",
  },
  {
    key: "1395df06-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "lisäturva",
    price: 0,
    unit: "kpl",
  },
  {
    key: "1ab8e490-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Navigaattori",
    price: 0,
    unit: "kpl",
  },
  {
    key: "16f2486a-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Nuoren kuljettajan l",
    price: 0,
    unit: "kpl",
  },
  {
    key: "1e3ae5e6-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "omavastuun pienennys",
    price: 0,
    unit: "kpl",
  },
  {
    key: "22a99ff0-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Palvelumaksu",
    price: 0,
    unit: "kpl",
  },
  {
    key: "25f5da16-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Polttoaine",
    price: 0,
    unit: "l",
  },
  {
    key: "2bc7e65a-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Provisio",
    price: 0,
    unit: "kpl",
  },
  {
    key: "300c4f3a-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Selite",
    price: 0,
    unit: "kpl",
  },
  {
    key: "33f404da-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Suksiboksi",
    price: 0,
    unit: "kpl",
  },
  {
    key: "37e495e6-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Suksiteline",
    price: 0,
    unit: "kpl",
  },
  {
    key: "3c3d6ef6-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Suoritus",
    price: 0,
    unit: "kpl",
  },
  {
    key: "3d6720b0-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Tankkauslisä",
    price: 0,
    unit: "kpl",
  },
  {
    key: "46082c96-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Toimitus hotellille",
    price: 0,
    unit: "kpl",
  },
  {
    key: "4aa16c9a-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Toimituslisä",
    price: 0,
    unit: "kpl",
  },
  {
    key: "4c2dda12-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Turvaistuin",
    price: 0,
    unit: "kpl",
  },
  {
    key: "scandia-rent-Turvakaukalo",
    category: "scandiaRent",
    quantity: 1,
    description: "Turvakaukalo",
    price: 0,
    unit: "kpl",
  },
  {
    key: "505adaea-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Vakuutusyhtiö korvaa",
    price: 0,
    unit: "kpl",
  },
  {
    key: "59558f8c-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Vakuutusyhtiön osuus",
    price: 0,
    unit: "kpl",
  },
  {
    key: "5ab708ba-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "Vetokoukku",
    price: 0,
    unit: "kpl",
  },
  {
    key: "5efc03bc-eb36-11ee-a951-0242ac120002",
    category: "scandiaRent",
    quantity: 1,
    description: "ylikilometrit",
    price: 0,
    unit: "km",
  },
];

export const externalServices = [
  {
    key: "f27cc7c2-eb34-11ee-a951-0242ac120002",
    description: "Toimitusmaksu O - 20 km",
    price: null,
    category: "insurance",
  },
  {
    key: "383c145c-eb35-11ee-a951-0242ac120002",
    description: "Toimitusmaksu 20 - 80 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "3c868b0a-eb35-11ee-a951-0242ac120002",
    description: "Toimitusmaksu 81- 120 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "3fe92cf8-eb35-11ee-a951-0242ac120002",
    description: "Toimitusmaksu 121- 250 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "91241b1e-eb49-11ee-a951-0242ac120002",
    description: "Toimitusmaksu Yli 250 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "44a243ba-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus O - 20 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "4761d6c4-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus 20-80 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "49ba4eb0-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus 81-150 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "4bfb90a8-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus 151-250 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "4e398050-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus 251-400 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "50e3c4c8-eb35-11ee-a951-0242ac120002",
    description: "Kotitoimitus Yli 400 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "5358ee04-eb35-11ee-a951-0242ac120002",
    description: "Liikeajan ulkopuolinen palvelumaksu",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },

  {
    key: "55fda3ac-eb35-11ee-a951-0242ac120002",
    description: "Liikeajan ulkopuolinen palautusmaksu",
    category: "insurance",
    price: null,
  },

  {
    key: "5847a900-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu 20-80 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "60b91d9e-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu 81-200 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "6913d4c0-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu 201-400 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "6c17d086-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu 401- 600 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "6e6f814e-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu 601- 800 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
  {
    key: "70dfb430-eb35-11ee-a951-0242ac120002",
    description: "Jättömaksu Yli 800 km",
    category: "insurance",
    price: null,
    paymentType: "INSURANCE_COMPANY",
  },
];

import { useCallback, useContext } from "react";
import {
  useFetchExternalReservations,
  useFetchReservations,
} from "../../../services/fetchReservations";
import { AppContext } from "../../../AppContext";
import { reservationDataAtom } from "../../../atoms/reservationDataAtom";
import { useAtomValue } from "jotai";

// Update local state instantly
export const useMutateLocalReservations = () => {
  const reservationData = useAtomValue(reservationDataAtom);

  const { selectedView } = useContext(AppContext);
  const queryMode =
    selectedView === "daily" ? "startOrEndBetween" : "existBetween";

  const { reservations, reservationsMutate } = useFetchReservations(queryMode);
  const { externalReservations, externalReservationsMutate } =
    useFetchExternalReservations();
  const existsInReservations =
    typeof reservationData === "object" && reservationData?.id;
  const mutate = async (updatedReservation) => {
    const reservation = {
      ...updatedReservation,
      startTime: updatedReservation?.startTime
        ? new Date(updatedReservation.startTime)
        : undefined,
      returnTime: updatedReservation?.returnTime
        ? new Date(updatedReservation.returnTime)
        : undefined,
      endTime: updatedReservation?.endTime
        ? new Date(updatedReservation.endTime)
        : undefined,
      createdAt: new Date(updatedReservation.createdAt),
    };
    // mutate external reservations  depending on the status & type
    if (reservation?.type === "INSURANCE") {
      if (
        reservation?.status === "EXTERNAL_PENDING_DEALER" &&
        reservationData?.status === "EXTERNAL_PENDING_DEALER"
      ) {
        await externalReservationsMutate(
          externalReservations.map((r) =>
            r.id === reservation.id ? reservation : r
          ),
          {
            revalidate: false,
            optimisticData: (data) =>
              data.map((item) =>
                item.id === reservation.id ? reservation : item
              ),
            populateCache: false,
          }
        );
        return;
      }
      if (
        reservation?.status !== "EXTERNAL_PENDING_DEALER" &&
        reservationData?.status === "EXTERNAL_PENDING_DEALER"
      ) {
        await externalReservationsMutate(
          externalReservations.filter((r) => r.id !== reservation.id),
          {
            revalidate: false,
            optimisticData: (data) =>
              data.filter((item) => item.id !== reservation.id),
            populateCache: false,
          }
        );
        return;
      }
      await reservationsMutate(
        reservations.map((r) => (r.id === reservation.id ? reservation : r)),
        {
          revalidate: false,
          optimisticData: (data) =>
            data.map((item) =>
              item.id === reservation.id ? reservation : item
            ),
          populateCache: false,
        }
      );
      return;
    } else {
      if (existsInReservations && reservation) {
        await reservationsMutate(
          reservations.map((r) => (r.id === reservation.id ? reservation : r)),
          {
            revalidate: false,
            optimisticData: (data) =>
              data.map((item) =>
                item.id === reservation.id ? reservation : item
              ),
            populateCache: false,
          }
        );
        return;
      }
      if (!existsInReservations && reservation) {
        await reservationsMutate([reservation, ...reservations], {
          optimisticData: (data) => [reservation, ...data],
          populateCache: false,
          revalidate: false,
        });
        return;
      }
    }
  };

  const mutateLocalReservations = useCallback(
    (reservationData, updatedReservation) =>
      mutate(reservationData, updatedReservation),
    []
  );
  const remove = async (reservationData) => {
    if (reservationData?.status === "EXTERNAL_PENDING_DEALER") {
      await externalReservationsMutate(
        externalReservations.filter((r) => r.id !== reservationData.id),
        {
          revalidate: false,
          optimisticData: (data) =>
            data.filter((item) => item.id !== reservationData.id),
          populateCache: false,
        }
      );
      return;
    } else {
      if (reservationData?.id) {
        await reservationsMutate(
          reservations.filter((r) => r.id !== reservationData.id),
          {
            revalidate: false,
            optimisticData: (data) =>
              data.filter((item) => item.id !== reservationData.id),
            populateCache: false,
          }
        );
      }
    }
  };
  const removeLocalReservation = useCallback((reservationData) => {
    remove(reservationData);
  }, []);
  return { mutateLocalReservations, removeLocalReservation };
};

import { Box, Stack, Typography } from "@mui/material";
import { FormInputField } from "../../../components/ReservationForm/utils/FormInputField";
import PhoneNumberField from "../../../components/PhoneNumberField";

export default function CustomerInformation() {
  return (
    <Stack spacing={1}>
      <Box>
        <FormInputField name="name" label="Nimi" />
      </Box>
      <Box>
        <PhoneNumberField fieldName={"phone"} required />
      </Box>
      <Box>
        <FormInputField name="email" label="Sähköposti" />
      </Box>
      <Box sx={{ display: "flex", height: "42px", alignItems: "center" }}>
        <Typography>Ajoneuvon tiedot</Typography>
      </Box>
      <FormInputField
        name="customerCarRegistrationPlate"
        label="Rekisterinumero"
      />
      <FormInputField name="insuranceData.customerCarMaker" label="Merkki" />
      <FormInputField name="insuranceData.customerCarModel" label="Malli" />
    </Stack>
  );
}

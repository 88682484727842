import React, { useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { useFormikContext } from "formik";
import AdditionalServiceList from "./components/AdditionalServiceList";
import { useReservationContext } from "../../ReservationContext";
import { useGetOfferPriceByIds } from "../../useGetOfferPriceByIds";
import { useFetchAdditionalServices } from "../../../../services/fetchAdditionalServices";
import { FormContent, FormGrid } from "../../../common/FormComponents";
import { getPriceMultiplier } from "../../common/utils";
import AdditionalServiceDeductibleList from "./components/AdditionalServiceDeductibleList";
import {
  additionalServiceScandia,
  externalServices,
} from "./components/ScandiaRentServices";
import {
  enableCustomAdditionalService,
  enableExternalServices,
} from "../../../../features";
import { useContext } from "react";
import { AppContext } from "../../../../AppContext";
import AddCustomService from "./components/AddCustomService";

export default function AdditionalServicePage() {
  const { values } = useFormikContext();
  const { user } = useContext(AppContext);
  const {
    selectedServiceOffers,
    vehicleIds,
    reservationData,
    startTime,
    returnTime,
    queryResource,
  } = useReservationContext();
  const { offerPrices, offerPricesLoading } = useGetOfferPriceByIds(
    vehicleIds,
    startTime,
    returnTime,
    queryResource
  );

  const { additionalServices } = useFetchAdditionalServices();

  const availableServices = useMemo(() => {
    const services = [];

    // add services from previously created reservation
    if (selectedServiceOffers.length > 0) {
      selectedServiceOffers.forEach((selectedService) => {
        const offerWithDetails = additionalServices?.find(
          (service) => service.id === selectedService.key
        );
        if (offerWithDetails) {
          services.push({
            ...selectedService,
            ...offerWithDetails,
            description:
              selectedService?.description ?? offerWithDetails?.description,
          });
        } else {
          services.push(selectedService);
        }
      });
    }

    if (offerPrices?.serviceOfferPrices && additionalServices) {
      offerPrices?.serviceOfferPrices.forEach((offer) => {
        const isPopulated = services.find(
          (service) => service.key === offer.key
        );
        if (!isPopulated) {
          const offerWithDetails = additionalServices?.find(
            (service) => service.id === offer.key
          );
          services.push({
            ...offer,
            ...offerWithDetails,
            price: offer.offerPrice,
            quantity: 1, // add quantity here, to ensure that the total price is calculated correctly
          });
        }
      });
    }

    return services.sort(function (a, b) {
      if (a.description < b.description) {
        return -1;
      }
      if (a.description > b.description) {
        return 1;
      }
      return 0;
    });
  }, [
    offerPrices?.serviceOfferPrices,
    additionalServices,
    selectedServiceOffers,
  ]);

  const FEATURE_ENABLE_CUSTOM_SERVICES = enableCustomAdditionalService(
    user?.organizationId
  );

  const FEATURE_ENABLE_EXTERNAL_SERVICES =
    enableExternalServices(user?.organizationId) &&
    reservationData?.type === "INSURANCE";

  if (offerPricesLoading)
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  return (
    <Grid
      item
      container
      md={12}
      sm={12}
      xs={12}
      lg={12}
      sx={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12}>
        <FormContent title={"Lisäpalvelut"} visibility={true} expanded={true}>
          <AdditionalServiceList
            categories={["normal"]}
            additionalServiceOffers={availableServices}
          />
          <FormGrid sx={{ m: "10px" }}>
            <AddCustomService category={"normal"} />
          </FormGrid>
        </FormContent>
      </Grid>

      {FEATURE_ENABLE_CUSTOM_SERVICES && (
        <Grid item xs={12}>
          <ScandiaRentCustomServices availableServices={availableServices} />
        </Grid>
      )}
      {FEATURE_ENABLE_EXTERNAL_SERVICES && (
        <Grid item xs={12}>
          <ExternalBusinessServices availableServices={availableServices} />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormContent title={"Omavastuu"} visibility={true} expanded={true}>
          <AdditionalServiceDeductibleList
            categories={["deductible"]}
            vehicleQuantity={getPriceMultiplier("deductible", values)}
            additionalServiceOffers={availableServices}
          />
        </FormContent>
      </Grid>

      <Grid item xs={12}>
        <FormContent title={"Kilometrit"} visibility={true} expanded={true}>
          <AdditionalServiceList
            categories={["distance"]}
            vehicleQuantity={getPriceMultiplier("distance", values)}
            additionalServiceOffers={availableServices}
          />
        </FormContent>
      </Grid>
    </Grid>
  );
}

function ExternalBusinessServices(props) {
  const { availableServices } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <Grid item xs={12}>
      <FormContent
        title={"Vakuutusyhtiön lisäpalvelut"}
        visibility={true}
        expanded={true}
      >
        <AdditionalServiceList
          categories={["insurance"]}
          additionalServiceOffers={availableServices}
        />
        <FormGrid sx={{ m: "10px" }}>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="contained"
          >
            Lisää palvelu
          </Button>
          <ExternalServiceMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </FormGrid>
      </FormContent>
    </Grid>
  );
}

function ScandiaRentCustomServices(props) {
  const { availableServices } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <Grid item xs={12}>
      <FormContent
        title={"Scandia Rent lisäpalvelut"}
        visibility={true}
        expanded={true}
      >
        <AdditionalServiceList
          categories={["scandiaRent"]}
          additionalServiceOffers={availableServices}
        />
        <FormGrid sx={{ m: "10px" }}>
          <Button
            onClick={(e) => setAnchorEl(e.currentTarget)}
            variant="contained"
          >
            Lisää palvelu
          </Button>
          <ScandiaRentServiceMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
        </FormGrid>
      </FormContent>
    </Grid>
  );
}

function ExternalServiceMenu(props) {
  const { anchorEl, setAnchorEl } = props;
  const { addNewServiceOffer } = useReservationContext();
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      PaperProps={{ style: { maxHeight: "40%" } }}
      onClose={() => setAnchorEl(null)}
    >
      <MenuList>
        {externalServices.map((service) => (
          <MenuItem
            key={service.key}
            onClick={() => {
              addNewServiceOffer(service);
              setAnchorEl(null);
            }}
          >
            {service.description}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

function ScandiaRentServiceMenu(props) {
  const { anchorEl, setAnchorEl } = props;
  const { addNewServiceOffer } = useReservationContext();
  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      PaperProps={{ style: { maxHeight: "40%" } }}
      onClose={() => setAnchorEl(null)}
    >
      <MenuList>
        {additionalServiceScandia.map((service) => (
          <MenuItem
            key={service.key}
            onClick={() => {
              addNewServiceOffer(service);
              setAnchorEl(null);
            }}
          >
            {service.description}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

/* function ExternalBusinessServices({
  data,
  dialogView,
  values,
  additionalServiceOffers,
}) {
  if (
    !data?.orgExternalBusinessId ||
    !additionalServiceOffers.some(
      (serviceOffer) => serviceOffer.category === "insurance"
    )
  ) {
    // No customer insurance services
    return null;
  }
  return (
    <Grid item xs={12}>
      <FormContent
        title={"Vakuutusyhtiölisäpalvelut"}
        dialogView={dialogView}
        visibility={true}
        expanded={true}
      >
        <AdditionalServiceList
          categories={["insurance"]}
          header="Vakuutusyhtiön lisäpalvelut"
          vehicleQuantity={getPriceMultiplier("insurance", values)}
          additionalServiceOffers={additionalServiceOffers}
          data={data}
        />
      </FormContent>
    </Grid>
  );
}
 */

import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import captureError from "./capture-error";
import listAll from "./list-all";
import useSWR from "swr";
import {
  listNotificationsByBusinessStatusCreatedAt,
  listNotificationsByGroupByStatus,
  listNotificationsByGroupStatusCreatedAt,
  notificationsByBusinessByStatus,
} from "../graphql/queries";
import { updateNotification } from "../graphql/mutations";

const notificationsFetcher = async (group, status) => {
  if (!group) return;
  try {
    const notifications = await listAll(
      graphqlOperation(listNotificationsByGroupByStatus, {
        group: group,
        status: { eq: status },
      }),
      "listNotificationsByGroupByStatus"
    );
    const sortedNotifications = notifications.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    return sortedNotifications;
  } catch (e) {
    console.error("Fetching Notifications failed", e);
    captureError("Get notifications failed", "GET_NOTIFICATIONS_FAILED", e);
  }
};

const notificationsByBusinessFetcher = async (businessId, status) => {
  console.log("notificationsByBusinessFetcher", businessId, status);
  try {
    const notifications = await listAll(
      graphqlOperation(notificationsByBusinessByStatus, {
        orgBusinessId: businessId,
        status: { eq: status },
      }),
      "notificationsByBusinessByStatus"
    );
    const sortedNotifications = notifications.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    return sortedNotifications;
  } catch (e) {
    console.error("Fetching Notifications failed", e);
    captureError("Get notifications failed", "GET_NOTIFICATIONS_FAILED", e);
  }
};

export function useUnreadNotifications(company, user) {
  let key = null;
  let fetcher = null;
  const { group, orgBusinessId } = company || {};

  if (group) {
    key = group ? "newNotifications" : null;
    fetcher = () => notificationsFetcher(group, "UNREAD");
  }
  if (user?.role === "BUSINESS_ADMIN") {
    key = orgBusinessId ? ["newNotifications", orgBusinessId] : null;
    fetcher = () => notificationsByBusinessFetcher(orgBusinessId, "UNREAD");
  }

  const newNotificationsSwr = useSWR(
    key,
    fetcher,
    { refreshInterval: 60000 * 5 } // Refresh every 5 minutes
  );

  return newNotificationsSwr;
}

// Fetch READ notifications from the last 30 days
export function useReadNotifications(company, user) {
  const { group, orgBusinessId } = company || {};
  const tomorrow = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000); // Get tomorrow Date
  const thirtyDaysAgo = new Date(tomorrow.getTime() - 30 * 24 * 60 * 60 * 1000); // Subtract 30 days (in milliseconds)
  const startISODate = thirtyDaysAgo.toISOString().slice(0, 10); // Extract only the date portion (first 10 characters) of the ISO string
  const endISODate = tomorrow.toISOString().slice(0, 10); // Extract only the date portion (first 10 characters) of the ISO string

  const byGroupFetcher = async () => {
    if (!group) return;
    try {
      const notifications = await listAll(
        graphqlOperation(listNotificationsByGroupStatusCreatedAt, {
          group: group,
          statusCreatedAt: {
            between: [
              { status: "READ", createdAt: startISODate },
              { status: "READ", createdAt: endISODate },
            ],
          },
        }),
        "listNotificationsByGroupStatusCreatedAt"
      );
      const sortedNotifications = notifications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      return sortedNotifications;
    } catch (e) {
      console.error("Fetching Notifications failed", e);
      captureError("Get notifications failed", "GET_NOTIFICATIONS_FAILED", e);
    }
  };

  const byBusinessFetcher = async () => {
    if (orgBusinessId) return;
    try {
      const notifications = await listAll(
        graphqlOperation(listNotificationsByBusinessStatusCreatedAt, {
          orgBusinessId: orgBusinessId,
          statusCreatedAt: {
            between: [
              { status: "READ", createdAt: startISODate },
              { status: "READ", createdAt: endISODate },
            ],
          },
        }),
        "listNotificationsByBusinessStatusCreatedAt"
      );
      const sortedNotifications = notifications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      return sortedNotifications;
    } catch (e) {
      console.error("Fetching Notifications failed", e);
      captureError("Get notifications failed", "GET_NOTIFICATIONS_FAILED", e);
    }
  };

  let key = null;
  let fetcher = null;

  if (group) {
    key = group ? ["oldNotifications", group, startISODate, endISODate] : null;
    fetcher = ([startISODate, endISODate]) => byGroupFetcher();
  }
  if (user?.role === "BUSINESS_ADMIN") {
    key = orgBusinessId ? ["oldNotifications", startISODate, endISODate] : null;
    fetcher = ([startISODate, endISODate]) => byBusinessFetcher();
  }

  const oldNotificationsSwr = useSWR(
    key,
    fetcher,

    { revalidateOnFocus: false }
  );

  return oldNotificationsSwr;
}

export function useNotifications(company, setOpened, user) {
  const newNotificationsSwr = useUnreadNotifications(company, user);
  const oldNotificationsSwr = useReadNotifications(company, user);
  const [notificationsReadOnClient, setNotificationsReadOnClient] =
    React.useState(() => new Set());

  const markAsRead = (notification) => {
    setOpened(false);
    const markNotificationAsRead = API.graphql(
      graphqlOperation(updateNotification, {
        input: {
          id: notification.id,
          status: "READ",
          createdAt: notification.createdAt,
        },
      })
    );
    return newNotificationsSwr.mutate(() => markNotificationAsRead, {
      populateCache: (result, notifications) => {
        setNotificationsReadOnClient(
          (nrc) => new Set([...Array.from(nrc), notification.id])
        );
        return notifications || [];
      },
      revalidate: false,
    });
  };
  const serverNewNotifications = newNotificationsSwr.data;
  const serverOldNotifications = oldNotificationsSwr.data;
  const [newNotifications, oldNotifications] = React.useMemo(() => {
    // New notifications are those we know to be new,
    // which haven't been marked old.
    const newNotifications = (serverNewNotifications || []).filter(
      (n) => !notificationsReadOnClient.has(n.id)
    );
    // Old notifications are those we know to be old,
    // as well as those that are new but we've seen here.
    const oldNotifications = [
      ...(serverOldNotifications || []).filter(
        (n) => !notificationsReadOnClient.has(n.id)
      ),
      ...(serverNewNotifications || []).filter((n) =>
        notificationsReadOnClient.has(n.id)
      ),
    ];
    return [newNotifications, oldNotifications];
  }, [
    notificationsReadOnClient,
    serverNewNotifications,
    serverOldNotifications,
  ]);
  return {
    newNotifications,
    oldNotifications,
    markAsRead,
    newNotificationsSwr,
    oldNotificationsSwr,
  };
}

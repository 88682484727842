import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFetchExternalBusinessPricings } from "../../../../../../../../services/fetchPricings";
import { useFormikContext } from "formik";

export default function SelectContractVehicleClass(props) {
  const { visibility } = props;

  const { values, setFieldValue } = useFormikContext();
  const { externalBusinessPricings } = useFetchExternalBusinessPricings(
    values?.orgExternalBusinessId
  );

  if (visibility)
    return (
      <FormControl fullWidth>
        <InputLabel id="select-franchising-label">
          Valitse sopimushinta-autoluokka
        </InputLabel>
        <Select
          // disabled={!enableContractCustomer}
          labelId="select-contract-vehicle-class-label"
          id="select-contract-vehicle"
          label=" Valitse sopimushinta-autoluokka"
          value={values?.pricingCategoryId || ""}
          onChange={(e) => setFieldValue("pricingCategoryId", e.target.value)}
          MenuProps={{
            PaperProps: { sx: { maxHeight: { xs: "40vh", sm: "60%" } } },
          }}
        >
          <MenuItem value={null}>
            <b>Ei valittua autoluokkaa</b>
          </MenuItem>
          {externalBusinessPricings &&
            externalBusinessPricings.map((c) => (
              <MenuItem
                key={`vehicle-class-type-${c.id}`}
                value={c.externalBusinessCategoryAssociation} // pick only the first letter from the type, there is few exeptions
                data-cy="reservationDialogVehicleClassOption"
                selected={c?.id === values.pricingCategoryId}
              >
                {c.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}

import React, { useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import InsuranceCompanySelect from "./InsuranceCompanySelect";
import { reservationDataAtom } from "~/atoms/reservationDataAtom";
import { useAtomValue } from "jotai";

const FormikTextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  md,
  xs,
  ...props
}) => {
  return (
    <Grid item xs={xs} md={md}>
      <TextField
        {...field}
        variant="outlined"
        error={touched[field.name] && !!errors[field.name]}
        helperText={touched[field.name] && errors[field.name]}
        size="small"
        fullWidth
        {...props}
      />
    </Grid>
  );
};

function InsuranceBillingSettings() {
  const { values, handleChange, handleBlur } = useFormikContext();
  const reservationData = useAtomValue(reservationDataAtom);
  if (reservationData?.type !== "INSURANCE") return null;

  return (
    <Paper elevation={2} sx={{ padding: 2, marginY: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Vakuutuslaskutuksen asetukset
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                name="insuranceData.vatDeductible"
                checked={values?.insuranceData?.vatDeductible ?? false}
                color="primary"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            }
            label="ALV-vähennys"
          />
        </Grid>

        {/* Second column, first row (empty) */}
        <Grid item xs={0} sm={9} />

        <Grid item xs={12} sm={3}>
          <Field
            name="insuranceData.coveragePercentage"
            label="Korvausjako %"
            component={FormikTextField}
          />
        </Grid>

        <Grid item xs={12} sm={9}>
          <InsuranceCompanySelect size="small" />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Field
            name="insuranceData.billingPermitCode"
            label="Laskutuslupatunnus"
            component={FormikTextField}
          />
        </Grid>

        {/* Second column, third row (empty) */}
        <Grid item xs={0} sm={9} />
      </Grid>
    </Paper>
  );
}

export default InsuranceBillingSettings;

export const timelineContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { ease: "easeInOut", duration: 0.2 },
  },
};

export const timelineItem = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,

    transition: { staggerChildren: 0.1, ease: "easeInOut", duration: 0.25 },
  },
};

export const tableHeadVariant = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.3,
    },
  },
};

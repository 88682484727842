//Map one to option (nullish), drop nullish values
export function mapMaybe(arr, func) {
  return arr.map(func).filter((i) => i != null);
}

export function init(count, func) {
  const arr = new Array(count);
  for (let i = 0; i < count; i++) {
    arr[i] = func(i);
  }
  return arr;
}

export function distinctBy(arr, func) {
  const set = new Set();
  return arr.filter((element) => {
    const value = func(element);
    if (set.has(value)) {
      return false;
    } else {
      set.add(value);
      return true;
    }
  });
}

import { useEffect } from "react";
import { API } from "aws-amplify";
import * as Sentry from "@sentry/react";

export function useEffectAsync(asyncFunc, dependencies) {
  useEffect(() => {
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

export function captureError(defaultMessage, code, e) {
  let json;
  try {
    json = JSON.stringify(e);
  } catch {
    json = "Error serialization failed";
  }

  if (typeof e === "object" && e.errors != null) {
    //Amplify AppSync error
    Sentry.withScope((scope) => {
      scope.setExtra("errors", e.errors);
      scope.setExtra("json", json);
      Sentry.captureMessage(defaultMessage, {
        tags: {
          code: code,
        },
      });
    });
  } else if (e instanceof Error) {
    Sentry.withScope((scope) => {
      scope.setExtra("contextMessage", defaultMessage);
      scope.setExtra("json", json);
      Sentry.captureException(e, {
        tags: {
          code: code,
        },
      });
    });
  } else if (typeof e === "string") {
    Sentry.withScope((scope) => {
      scope.setExtra("contextMessage", defaultMessage);
      Sentry.captureMessage(e, {
        tags: {
          code: code,
        },
      });
    });
  } else {
    Sentry.withScope((scope) => {
      scope.setExtra("json", json);
      Sentry.captureMessage(defaultMessage, {
        tags: {
          code: code,
        },
      });
    });
  }
}

/*
  Amplify GraphQL API may return results as paginated.
  This helper does multiple requests until really all results are loaded or fail.
*/
export async function listAll(query, setName) {
  const itemsList = [];
  let gqlOperation = query;
  let lastNextToken;
  while (true) {
    const response = await API.graphql(gqlOperation);
    const result = response.data[setName];
    itemsList.push(result.items);
    if (result.nextToken) {
      if (itemsList.length > 50) {
        //Arbitary maxium count. If this hits, something is done wrong.
        throw new Error("Maxium query count exceeded");
      } else if (result.nextToken === lastNextToken) {
        //Should not never happen
        throw new Error("Next token repeated error");
      }
      lastNextToken = result.nextToken;

      gqlOperation = {
        ...gqlOperation,
        variables: {
          ...gqlOperation.variables,
          nextToken: result.nextToken,
        },
      };
    } else {
      break;
    }
  }

  return [].concat(...itemsList);
}

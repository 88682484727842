import { Grid, Box, Typography, Divider, Stack } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
// Main container with title and styles
export function FormContent({ children, title, visibility }) {
  const { mobileViewPort } = useContext(AppContext);
  if (visibility)
    return (
      <>
        <Stack
          sx={{
            position: "relative",
            borderRadius: "10px",
            //  border: "1px solid rgba(0,0,0,0.07)",
            zIndex: 2,
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
          direction={"column"}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              marginBottom: "15px",
            }}
          >
            <Typography
              sx={{
                fontSize: mobileViewPort ? "1.2rem" : "1.4rem",
                fontFamily: "Sofia Pro",
              }}
            >
              {title}
            </Typography>
          </Box>
          {children}
        </Stack>
        <Divider sx={{ marginY: "15px" }} />
      </>
    );
}

// Create a consistent sized customer information textfields
export function FormGrid(props) {
  return (
    <Grid
      item
      xs={props?.xs ? props?.xs : 11}
      md={props?.md ? props.md : 10}
      {...props}
      container
    >
      {props.children}
    </Grid>
  );
}
// Container to wrap the Content
export function FormContainer(props) {
  return (
    <Grid
      item
      xs={11}
      sm={11}
      md={11}
      lg={props?.lg ? props.lg : 5}
      xl={props?.xl ? props.xl : 3}
      {...props}
    >
      {props.children}
    </Grid>
  );
}
/* 
export function FormContent({ children, title }) {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        position: "relative",
        bgcolor: "rgba(255,255,255,1)",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid rgba(0,0,0,0.15)",
        paddingTop: "36px",
        zIndex: 2,
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "350px",
          height: "55px",
          top: -30,
          left: "20px",
          background:
            "linear-gradient(195deg, rgb(71, 136, 191), rgb(73, 114, 150))",
          boxShadow: theme.shadows[4],
          borderRadius: "16px",
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            style={{
              fontSize: "40px",
              color: "white",
              opacity: 0.2,
              position: "absolute",
              top: 0,
              left: 4,

              overflow: "hidden",
            }}
          >
            {title === "Asiakkaan tiedot" && <FaAddressCard />}
            {title === "Varauksen maksu" && <FaMoneyCheckAlt />}
            {title === "Lisäpalvelut" && <FaPuzzlePiece />}
            {title === "Varauksen ajankohta" && <FaCalendarAlt />}
            {title === "Varauksen tuotteet" && <FaGhost />}
          </Box>
          <Typography
            sx={{
              color: "white",
              fontSize: "1.2rem",
              fontFamily: "Sofia Pro",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>

      {children}
    </Grid>
  );
}
 */

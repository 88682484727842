import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { centsToLocalString } from "../../../../shared/money";
import FilterResults from "../FilterResults";
import React, { useState } from "react";
import VehicleImage from "~/components/VehicleImage";

export default function MenuComponent(props) {
  const {
    // setDisableCategoryDefaultsStacking,
    anchorEl,
    disableCategoryDefaultsStacking,
    handleChange,
    imageSource,
    loading,
    menuOptions,
    primaryText,
    secondaryText,
    setAnchorEl,
  } = props;
  // const [showSpecs, setShowSpecs] = useState(null);

  const [filteredOptions, setFilteredOptions] = useState(menuOptions);
  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Popper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sx={{ zIndex: 9999 }}
        placement={"bottom-start"}
      >
        <Paper sx={{ padding: "16px", borderRadius: "14px" }}>
          <Stack direction={"row"} flex={1} alignItems={"center"} spacing={1}>
            <FilterResults meta={menuOptions} setMeta={setFilteredOptions} />
          </Stack>
          {/* TODO: add support for stacking */}
          {/*  <FormControlLabel
            control={
              <Switch
                value={disableCategoryDefaultsStacking}
                onChange={(e) =>
                  setDisableCategoryDefaultsStacking((prevState) => !prevState)
                }
              />
            }
            label="Käytä tuotteiden niputusta"
          /> */}

          <Stack
            direction={"row"}
            flex={1}
            sx={{ maxHeight: "50vh", overflow: "auto" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <List dense>
                {filteredOptions?.map((item, index) => (
                  <Item
                    //  setShowSpecs={setShowSpecs}
                    category={item?.category}
                    disableCategoryDefaultsStacking={
                      disableCategoryDefaultsStacking
                    }
                    handleChange={handleChange}
                    imageSource={imageSource}
                    key={item?.category?.id}
                    offers={item?.offers}
                    primaryText={primaryText}
                    secondaryText={secondaryText}
                  />
                ))}
              </List>
            )}
            {/*   {showSpecs && (
              <>
                <Divider flexItem orientation="vertical" variant="middle" />
                <Box
                  sx={{
                    padding: "10px",
                    width: "300px",
                  }}
                >
                  <Typography>Speksit</Typography>
                </Box>
              </>
            )} */}
          </Stack>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
}

function Loader() {
  return (
    <List sx={{ width: { xs: "90vw", sm: "500px" }, padding: 0 }}>
      <LoaderItem />
      <LoaderItem />
      <LoaderItem />
    </List>
  );
}

function LoaderItem(props) {
  return (
    <Stack flex={1} spacing={2} sx={{ marginY: "10px" }}>
      <Stack direction={"row"} flex={1} spacing={2} alignItems={"center"}>
        <Skeleton variant="rounded" width={50} height={50} />
        <Stack spacing={1} flex={1}>
          <Skeleton variant="rounded" width={300} height={20} />
          <Skeleton variant="rounded" width={100} height={20} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function Item(props) {
  const {
    category,
    disableCategoryDefaultsStacking,
    handleChange,
    imageSource,
    offers,
    primaryText,
    secondaryText,
    setShowSpecs,
    showSpecs,
  } = props;
  return (
    <React.Fragment>
      <ListSubheader>
        <ListItemText> {category?.name} </ListItemText>
      </ListSubheader>
      <CategoryOffers
        disableCategoryDefaultsStacking={disableCategoryDefaultsStacking}
        handleChange={handleChange}
        imageSource={imageSource}
        offers={offers}
        primaryText={primaryText}
        secondaryText={secondaryText}
        setShowSpecs={setShowSpecs}
        showSpecs={showSpecs}
      />
    </React.Fragment>
  );
}

function CategoryOffers(props) {
  const {
    disableCategoryDefaultsStacking,
    handleChange,
    offers,
    primaryText,
    secondaryText,
    setShowSpecs,
  } = props;
  return offers.map(({ vehicleOfferPrice, vehicle, tax }) => (
    <ListItem
      key={vehicle.id}
      disablePadding
      disableGutters
      //  onMouseEnter={() => setShowSpecs(vehicle)}
      //  onMouseLeave={() => setShowSpecs(null)}
      secondaryAction={
        <Typography
          sx={{
            fontFamily: "Sofia Pro",
            fontSize: "16px",
            fontWeight: 550,
            margin: "10px",
          }}
        >
          {centsToLocalString(vehicleOfferPrice)} €
        </Typography>
      }
    >
      <ListItemButton
        key={vehicle.id}
        value={vehicle.id}
        onClick={() => handleChange({ ...vehicle, tax: tax })}
        sx={{ width: { xs: "90vw", sm: "500px" }, padding: 0 }}
      >
        <ListItemAvatar>
          <VehicleImage vehicle={vehicle} variant="rounded" />
        </ListItemAvatar>

        <ListItemText
          primary={vehicle[primaryText]}
          secondary={
            disableCategoryDefaultsStacking
              ? vehicle[secondaryText] ?? null
              : "Tai vastaava"
          }
        />
      </ListItemButton>
    </ListItem>
  ));
}

export const EInvoiceType = {
  FULL: "FULL",
  EXTRA: "EXTRA",
  COMPENSATION: "COMPENSATION",
  INSURANCE_COMPANY: "INSURANCE_COMPANY",
  INSURANCE_CUSTOMER: "INSURANCE_CUSTOMER",
  AUTOLIITTO: "AUTOLIITTO",
} as const;

export type EInvoiceType = keyof typeof EInvoiceType;

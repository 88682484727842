export const getFixedLanguage = (language: string) => {
  if (language === "fi") {
    return "FIN";
  } else if (language === "en") {
    return "ENG";
  } else if (language === "sv") {
    return "SWE";
  } else {
    return "FIN";
  }
};

import { Box, Divider, Link, Typography } from "@mui/material";
import React from "react";

export default function NewsItem({ title, description, date, link }) {
  const openLink = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: "2em",
        padding: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "90%",
          padding: "3px",
          marginBottom: "1em",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            width: "100%",
            textAlign: "left",
            margin: "5px",
            fontFamily: "Sofia Pro",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ width: "100%", textAlign: "left", margin: "5px" }}
        >
          {description}
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="subtitle1"
          sx={{
            width: "40%",
            textAlign: "left",
          }}
        >
          Julkaistu: {date}
        </Typography>{" "}
        <Link
          sx={{
            width: "30%",
            cursor: "pointer",
          }}
          onClick={() => openLink(link)}
        >
          Lue lisää
        </Link>
      </Box>
      <Divider />
    </Box>
  );
}
